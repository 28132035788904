import { format, getTime, formatDistanceToNow } from 'date-fns'
import { map, join } from 'lodash'

// ----------------------------------------------------------------------

export function fDate(date, newFormat) {
  const fm = newFormat || 'dd MMM yyyy';

  return date ? format(new Date(date), fm) : '';
}

export function fDateTime(date, newFormat) {
  const fm = newFormat || 'dd MMM yyyy p';

  return date ? format(new Date(date), fm) : '';
}

export function fTimestamp(date) {
  return date ? getTime(new Date(date)) : '';
}

export function fToNow(date) {
  return date
    ? formatDistanceToNow(new Date(date), {
        addSuffix: true,
      })
    : '';
}

export function formatDate(dateString, includeTime = false) {
  const options = {
    dateStyle: 'full',
  };
  
  const formattedDate = new Date(dateString).toLocaleDateString(undefined, options);
  const formattedTime = new Date(dateString).toLocaleTimeString();
  if(includeTime) {
    return `${ formattedDate } at ${ formattedTime }`
  } else {
    return formattedDate
  }
}

export function formatTime(dateString, includeSeconds = false) {
  const date = new Date(dateString)

  let options = {
    hour: '2-digit',
    minute: '2-digit'
  };

  if (includeSeconds) {
    options.second = '2-digit'
  }

  const formattedTime = date.toLocaleTimeString(undefined, options)

  return formattedTime
}

export function getTimeAgo(timestamp) {
  const currentTime = new Date();
  const targetTime = new Date(timestamp);
  const timeDiff = Math.abs(currentTime - targetTime);

  const minute = 60 * 1000;
  const hour = 60 * minute;
  const day = 24 * hour;
  const month = 30 * day;
  const year = 365 * day;

  if (timeDiff < minute) {
    return "just now";
  } else if (timeDiff < hour) {
    const minutes = Math.floor(timeDiff / minute);
    return minutes + " minute" + (minutes !== 1 ? "s" : "") + " ago";
  } else if (timeDiff < day) {
    const hours = Math.floor(timeDiff / hour);
    return hours + " hour" + (hours !== 1 ? "s" : "") + " ago";
  } else if (timeDiff < month) {
    const days = Math.floor(timeDiff / day);
    return days + " day" + (days !== 1 ? "s" : "") + " ago";
  } else if (timeDiff < year) {
    const months = Math.floor(timeDiff / month);
    return months + " month" + (months !== 1 ? "s" : "") + " ago";
  } else {
    const years = Math.floor(timeDiff / year);
    return years + " year" + (years !== 1 ? "s" : "") + " ago";
  }
}

export const daysOfWeek = (numbers) => {
  const days = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];
  return join(map(numbers, n => days[n - 1]), ', ');
}

export function formatDateForBackend( date ) {
  // Pad the month and day with leading zeros if needed
  const pad = ( num ) => String( num ).padStart( 2, '0' )

  // Format the date and time in 'YYYY-MM-DD HH:MM' format
  const year = date.getFullYear()
  const month = pad( date.getMonth() + 1 )  // JavaScript months are 0-based
  const day = pad( date.getDate() )
  // const hours = pad( date.getHours() )
  // const minutes = pad( date.getMinutes() )

  return `${ year }-${ month }-${ day }`
}

