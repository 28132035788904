import { ItemList } from 'orm'

class AdminDynamicReportGenerator extends ItemList {
  getEntityKey() {
    return "admin/dynamic_report/generate"
  }

  generate(values) {
    return this.saveNewObject(values)
  }
}

export const adminDynamicReportGenerator = new AdminDynamicReportGenerator("admin_dynamic_report_generator__default")
