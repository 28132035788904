import React, { useEffect, useRef } from 'react'
import { get } from 'lodash'
import { FormikDropdownField } from 'components/form/Dropdown'
import { FormikInputField } from 'components/form/InputField'
import { FormikTextareaField } from 'components/form/TextareaField'
import { FormikCheckboxField } from 'components/form/CheckboxField'
import { useDispatch } from 'react-redux'
import { countryList } from 'actions/country'
import { provinceList } from 'actions/province'
import { CommonTable } from 'components/CommonTable'
import AdminMainLayout from 'admin/components/layout/AdminMainLayout'
import AdminTableFilter from 'admin/components/common/AdminTableFilter'
import { Separator } from 'components/layout/Separator'
import { Button, Paper, Grid, makeStyles } from '@material-ui/core'
import SearchBar from 'components/layout/SearchBar'
import { AddButton } from 'components/form/Buttons'

const useStyles = makeStyles((theme) => ({
  root: {
    [theme.breakpoints.down('sm')]: {
      padding: 0
    }
  },
  labelRoot: {
    paddingLeft: 15,
    fontSize: 24,
  },
  labelShinked: {
    marginLeft: 5,
    fontSize: 16
  },
  addLabel: {
    marginRight: 10
  },
  actions: {
    [theme.breakpoints.down('sm')]: {
      padding: 0,
      marginTop: 0,
      marginBottom: 0,
      marginLeft: 0,
      '& button': {
        fontSize: 12
      },
    }
  },
  inTabs: {
    marginLeft: -24,
    marginRight: -24
  },
  noPadding: {
    margin: '12px 10px 0 10px',
    [theme.breakpoints.down('sm')]: {
      margin: '10px',
    }
  },
  actionButtons: {
    marginLeft: -7,
    marginBottom: 10 
  }
}))

const AdminCommonListTable = ({
  title,
  active_key,
  add_label,
  use_plus,
  canDelete,
  onAddRow,
  showNumbers,
  noHeadings,
  useColumnsSelector,
  storeTableName,
  renderAdditionalFilter,
  enableAnyFieldFilter,
  renderAdditionalActions,
  onEditRow,
  is_loading,
  breadcrumbs,
  columns,
  item_list,
  inTabs,
  dummyData,
  noPadding,
  extra_content_top
}) => {
    const classes = useStyles()
    const rows = item_list.getVisibleObjects()
    const dispatch = useDispatch()
    const componentRef = useRef(null)
    const style = inTabs ? classes.inTabs : classes.noTabs
    let button_style
    button_style = noPadding ? classes.noPadding : button_style

    const onFilterChanged = (filter_values) => {
        dispatch(item_list.updateListFilter(filter_values))
        dispatch(item_list.fetchListIfNeeded())
    }

    const renderFilter = (formik_props) => {
        return (
            <div>
              { renderAdditionalFilter && <div style={{marginLeft: 15, marginRight: 15}}>{ renderAdditionalFilter(formik_props) }</div> }
              { enableAnyFieldFilter &&
                <SearchBar formik_props={formik_props} />
              }
            </div>
        )
    }

    return (
        <div className={classes.root}>

          <Grid container justifyContent="space-between" className={classes.actions}>
            { onAddRow &&
              <Grid item xs={12} md={3}>
                <div className={ classes.actionButtons}>
                  <AddButton
                    onClick={onAddRow}
                    label={add_label}
                    use_plus={use_plus}
                    color="primary"
                  />
                  { renderAdditionalActions && renderAdditionalActions() }
                </div>
              </Grid>
            }
            <Grid item xs={12} md={9}>
              <AdminTableFilter
                  label=""
                  placeholder="Search"
                  renderFilter={ renderFilter }
                  updateOnChange={ onFilterChanged }
              />
            </Grid>
          </Grid>



            <div className={style}>

                <CommonTable
                    is_loading={ is_loading }
                    rows={rows}
                    columns={ columns }
                    item_list={item_list}
                    onEditRow={onEditRow}
                    canDelete={canDelete}
                    render_additional_filter={renderAdditionalFilter}
                    useColumnsSelector={useColumnsSelector}
                    storeTableName={storeTableName}
                    showNumbers={showNumbers}
                    noHeadings={noHeadings}
                    dummyData={dummyData}
                />

            </div>

        </div>
    )
}

export default AdminCommonListTable
