import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { head, get, map } from 'lodash'
import { Link } from 'react-router-dom'
import DonorMainLayout from '../layout/DonorMainLayout'
import BusyMask from 'components/BusyMask'
import Timestamp from 'components/Timestamp'
import { contactParcelList } from 'actions/contact/parcel'
import DonorCommonListLayout from '../layout/DonorCommonListLayout'
import { ParcelStatus } from 'components/orders/ParcelStatus'
import NumberFormat from 'react-number-format'
import { makeStyles, Typography, Table, TableHead, TableBody, TableRow, TableCell, Button, Tooltip, useMediaQuery, useTheme } from '@material-ui/core'
import { Modal } from 'components/layout/Modal'
import { GeneratedReportDownload} from 'admin/components/reports/AdminDownloadReport'
import PdfView from 'components/PdfView'
import { getActiveCompanyName, getActiveCompanyId } from 'actions/active_company'
import PageviewOutlinedIcon from '@material-ui/icons/PageviewOutlined'
import PublishIcon from '@material-ui/icons/Publish'
import DonationWidgets from '../donations/widgets/DonationWidgets'

const useStyles = makeStyles((theme) => ({
  viewProductsIcon: {
    marginRight: 5,
    color: '#999'
  }
}))

export default function Donations({...props}) {
    const classes = useStyles()
    const dispatch = useDispatch()
    const history = useHistory()
    let direction = 'donor_pickup'
    const company_id = getActiveCompanyId()
    const contact_parcels = useSelector(() => contactParcelList.getVisibleObjects({direction: direction, from_company: company_id}))
    const is_loading = useSelector(() => contactParcelList.isLoading())
    const not_set = (title) => {
      return <Typography variant="caption" color="secondary">{title ? <>{title} not set</> : "Not set"}</Typography>
    }
    const [showProducts, setShowProducts] = useState(false)
    const [products, setProducts] = useState([])
    const [showPdf, setShowPdf] = useState(false)
    const theme = useTheme()
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'))

    //console.log(contact_parcels)

    useEffect(() => {
      dispatch(contactParcelList.updateListFilter({direction: direction, from_company: company_id}))
      dispatch(contactParcelList.fetchListIfNeeded({direction: direction, from_company: company_id}))
    }, [])

    const onEditContactParcel = (contact_parcel_id) => {
        history.push({
            pathname: '/donor/donate/order/'+ contact_parcel_id
        })
    }

    const onViewContactParcel = (contact_parcel_id) => {
        history.push({
            pathname: '/donor/donate/order/'+ contact_parcel_id
        })
    }

    const onAddContactParcel = () => {
      history.push({
          pathname: '/donor/donate/food/new'
      })
    }

    const onImportContactParcel = () => {
      history.push({
          pathname: '/donor/donate/food/import'
      })
    }

    const onUpdateListOrdering = (field) => {
        dispatch(contactParcelList.updateListOrdering(field))
    }

    const filterContactParcels = (filter_values) => {
        dispatch(contactParcelList.updateListFilter(filter_values))
    }

    const renderProducts = (products) => {
      setProducts(products)
      setShowProducts(true)
    }

    const closeProducts = (products) => {
      setProducts(null)
      setShowProducts(false)
    }

    const generatedReportDownload = (report_id) => {
      return(
        <GeneratedReportDownload generated_report_id={report_id} />
      )
    }

    const renderSection18Link = (item) => {
      return (
        <>
          { item.section18_certificate_file_info_details ?
            <>
              <Tooltip title="Click to view Section 18A Receipt PDF" arrow>
                <a onClick={() => setShowPdf(true)}><PageviewIcon fontSize="large" /></a>
              </Tooltip>
              {showPdf &&
                <Modal
                  onClose={() => setShowPdf(false)}
                  title={<a href={get(item, ["section18_certificate_file_info_details", "original_download_url"])} download target="_blank">
                          <Button variant="outlined">Download PDF</Button>
                         </a>
                       }
                  fullWidth={true}
                  maxWidth={'lg'}
                  fullScreen={fullScreen}
                >
                  <PdfView
                    file={get(item, ["section18_certificate_file_info_details", "original_download_url"])}
                    showPageNumbers={false}
                    align={'center'}
                  />
                </Modal>
              }
            </>
          :
            <Link to={`/donor/donations/order/${item.id}`}>
              <Tooltip title="Click to Upload Section 18A Invoice" arrow>
                <PublishIcon fontSize="large" />
              </Tooltip>
            </Link>
          }
        </>
      )
    }

    //console.log(contact_parcels)

    const columns = [
        { field: 'short_ref',
          title: 'Ref',
          width: 115,
          visible: true,
          render: (item) => <Link to={`/donor/donations/order/${item.id}`}>{item.short_ref}</Link>
        },
        { field: 'from_company_name',
          title: 'Branch',
          visible: true,
          sort: (direction) => onUpdateListOrdering(direction === 'asc' ? company_field : `-${company_field}`),
        },

        { field: 'depart_at',
          title: 'Date',
          width: 180,
          render: (item) => <Timestamp format={"datetime"} value={item.depart_at} />,
          sort: (direction) => onUpdateListOrdering(direction === 'asc' ? 'depart_at' : '-depart_at'),
        },
        { field: 'total_weight_kg',
          title: 'Weight',
          width: 150,
          render: (item) => get(item,["total_weight_kg"]) > 0 ? <NumberFormat value={get(item,["total_weight_kg"])} suffix={"kg"} displayType={'text'} thousandSeparator={true} decimalScale={2} /> : not_set(),
          sort: (direction) => onUpdateListOrdering(direction === 'asc' ? 'total_weight_kg' : '-total_weight_kg'),
        },
        { field: 'num_meals',
          title: 'Meals',
          width: 150,
          render: (item) => get(item,["parcel_products_meals"]) > 0 ? <NumberFormat value={get(item,["num_meals"])} displayType={'text'} thousandSeparator={true} decimalScale={0} /> : not_set(),
          sort: (direction) => onUpdateListOrdering(direction === 'asc' ? 'num_meals' : '-num_meals'),
        },
        { field: 'total_value_rands',
          title: 'Rand Value',
          width: 150,
          render: (item) => get(item,["total_value_rands"]) > 0 ? <NumberFormat value={get(item,["total_weight_kg"])} prefix={"R"} displayType={'text'} thousandSeparator={true} decimalScale={2} /> : not_set(),
          sort: (direction) => onUpdateListOrdering(direction === 'asc' ? 'total_value_rands' : '-total_value_rands'),
        },
        {
          field: 'section18_certificate_report_generation',
          title: 'Section18A',
          align: 'center',
            render: (item) => renderSection18Link(item)
        },
        {
          field: 'delivery_notes',
          title: 'Delivery Note',
          align: 'center',
        },
        { field: '',
          title: 'Products',
          visible: true,
          align: 'center',
          width: 100,
          render: (item) => get(item, "parcel_products") ?
              <Button
                onClick={ () => renderProducts(get(item, "parcel_products")) }
                size="small"
                variant="outlined"
              >
                <PageviewOutlinedIcon className={classes.viewProductsIcon} /> Products
              </Button> :
              "---"
        },
        { field: 'transport_by_warehouse_company',
          title: 'Transport Co.',
          render: (item) => get(item, "transport_by_warehouse_company") ? 'SA Harvest' : `${getActiveCompanyName()}`,
          sort: (direction) => onUpdateListOrdering(direction === 'asc' ? 'transport_by_warehouse_company' : '-transport_by_warehouse_company'),
        },
        { field: 'status',
          title: 'Status',
          width: 100,
          visible: true,
          render: (item) => <ParcelStatus status={item.status} />,
          sort: (direction) => onUpdateListOrdering(direction === 'asc' ? 'status' : '-status'),
        },
    ]


    return (
          <>
          <DonorCommonListLayout active_key="donations"
                                 breadcrumbs={[{name: 'donor_home'},
                                               {name: 'email', label: "Donations", url: '/donations'}]}
                                 add_label="New Donation"
                                 onAddRow={onAddContactParcel}
                                 onEditRow={onEditContactParcel}
                                 onViewRow={onViewContactParcel}
                                 canDelete={false}
                                 widgets={<DonationWidgets isDonations />}
                                 title="Donations"
                                 is_loading={is_loading}
                                 columns={columns}
                                 item_list={contactParcelList}
                                 enableAnyFieldFilter
                                 useColumnsSelector
                                 storeTableName={"contactDonationsList"}
          />

        { showProducts &&
          <Modal onClose={() => closeProducts()} title={"Products Donated"} noPadding>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Product</TableCell>
                  <TableCell>Prov Qty</TableCell>
                  <TableCell>Actual Qty</TableCell>
                  <TableCell>Weight</TableCell>
                  <TableCell>Value</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
              { map(products, (product) => {
                return(
                  <TableRow>
                    <TableCell>{product.product_name}</TableCell>
                    <TableCell>{product.provisional_quantity}</TableCell>
                    <TableCell>{product.actual_quantity}</TableCell>
                    <TableCell>{product.total_weight_kg} kg</TableCell>
                    <TableCell>R{product.total_value_rands}</TableCell>
                  </TableRow>
                )
              })}
              </TableBody>
            </Table>
          </Modal> }
      </>
    )
}
