import { ItemList } from 'orm'
import { values, get, map, head, filter } from 'lodash'

class ContactMoneyDonationReportList extends ItemList {
    getEntityKey() {
        return "contact/money_donation_report"
    }

}
export const contactMoneyDonationReportList = new ContactMoneyDonationReportList("contact_money_donation_report__default")
