import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useParams } from 'react-router-dom'
import { get, map } from 'lodash'
import { adminFoodItemList } from './actions/admin_food_item'
import { adminNutritionCardList } from './actions/admin_nutrition_card'
import { adminNutrientList } from './actions/admin_nutrient'
import {showSuccess, showError} from 'actions/Error'
import { handleSubmitResult } from 'actions/form'
import AdminCommonFormLayout from '../layout/AdminCommonFormLayout'
import { AdminFoodItemForm, validationSchema} from './form/AdminFoodItemForm'
import { Grid, Table, TableRow, TableCell } from "@material-ui/core"
import { NutritionFactsFoodItem } from './NutritionFactsFoodItem'
import Loading from 'components/Loading'
import { Card } from 'components/layout/Card'
import { Modal } from 'components/layout/Modal'
import AdminNutritionCard from './AdminNutritionCard'
import { AdminNutritionCardTable } from './AdminNutritionCardTable'

export default function AdminFoodItem() {
  const dispatch = useDispatch()
  const history = useHistory()
  const { food_item_id } = useParams()
  const food_item = useSelector(() => adminFoodItemList.getObject(food_item_id))
  const initial_values = food_item
  const is_loading = useSelector(() => adminFoodItemList.isLoading()) || (food_item_id && !get(food_item, "id"))
  const is_busy = useSelector(() => adminFoodItemList.getIsSavingObject())
  const title = `${get(food_item, ["name"], 'New Food Item')}`
  const [editingNutrientValue, setEditingNutrientValue] = useState(false)
  const [nutritionCardId, setNutritionCardId] = useState(null)
  const nutrition_card = useSelector(() => adminFoodItemList.getObject(nutritionCardId))
  const is_loading_nc = useSelector(() => adminNutritionCardList.isLoading())

  useEffect(() => {
    dispatch(adminFoodItemList.ensureObjectLoaded(food_item_id))
    dispatch(adminNutrientList.updatePaginationNumItemsPerPage(1000))
    dispatch(adminNutrientList.fetchListIfNeeded())
  },[])

  useEffect(() => {
    dispatch(adminFoodItemList.ensureObjectLoaded(nutritionCardId))
  },[nutritionCardId])

  const onSave = (values, formik_funcs) => {
    const on_ok = function(json) {
      dispatch(adminFoodItemList.invalidateList())
      showSuccess("Saved", "Food Item saved")

      if ( ! food_item_id ) {
        history.push(`/admin/food_item/${json.id}`)
      }
    }
    if ( food_item_id ) {
      values.id = food_item_id
      return dispatch(adminFoodItemList.saveObject(values)).then((res) => handleSubmitResult({res, formik_funcs, on_ok}))
    } else {
      return dispatch(adminFoodItemList.saveNewObject(values)).then((res) => handleSubmitResult({res, formik_funcs, on_ok}))
    }
  }

  const onSaved = () => {
    setEditingNutrientValue(false)
    dispatch(adminNutritionCardList.invalidateList())
    dispatch(adminNutritionCardList.fetchListIfNeeded())
  }

  const getCellValue = (header_key, item, index) => {
    switch( header_key ) {
      default:
      return undefined
    }
  }

  const renderNutrientValues = () => {
    return(
      <AdminNutritionCardTable
        food_item_id={ food_item_id }
        onAddNutritionCard={ onAddNutritionCard }
        onEditNutritionCard={ onEditNutritionCard }
      />
    )
  }

  const onAddNutritionCard = () => {
    setEditingNutrientValue(true)
  }

  const onEditNutritionCard = (nutrition_card_id) => {
    setEditingNutrientValue(true)
    setNutritionCardId(nutrition_card_id)
  }

  const onClose = () => {
    setEditingNutrientValue(false)
    dispatch(adminNutritionCardList.invalidateList())
    dispatch(adminNutritionCardList.fetchListIfNeeded())
  }

  return (
    <>
    <AdminCommonFormLayout
      breadcrumbs={[
        {name: 'admin_home'},
        {name: 'food_items', label: 'Food Items', url: '/admin/food_items'},
        {name: 'food_item', label: title, url: `/admin/food_item/${food_item_id}`}
      ]}
      is_busy={is_busy}
      is_loading={is_loading}
      title={title}
      initial_values={initial_values}
      validationSchema={validationSchema}
      onSave={onSave}
    >
      {
        ({formik_props}) =>
        <>
        <Grid container spacing={2}>
          <Grid item xs={12} md={4}>
            <Card
              title="Food Item Form"
              content={ is_loading ? <Loading /> : <AdminFoodItemForm formik_props={formik_props} /> }
              />
          </Grid>
          { food_item_id &&
            <>
            <Grid item xs={12} md={4}>
              <Card
                title="Nutrition Information"
                content={
                  <AdminNutritionCardTable
                    food_item_id={ food_item_id }
                    onAddNutritionCard={ onAddNutritionCard }
                    onEditNutritionCard={ onEditNutritionCard }
                  />
                }
                noPadding
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <NutritionFactsFoodItem food_item_id={ food_item_id } />
            </Grid>
            </>
          }
        </Grid>
        </>
      }
    </AdminCommonFormLayout>

    { editingNutrientValue &&
      <Modal
        title="Editing Nutrient Value"
        onClose={onClose}
      >
        { is_loading_nc ? <Loading />:
          <AdminNutritionCard
            food_item_id={ food_item_id }
            nutritionCardId={ nutritionCardId }
            in_modal
            onSaved={onSaved}
          />
        }
      </Modal>
    }

    </>
  )
}
