import { useEffect, useState, useCallback } from 'react'
import axios from 'axios'
import { getHeaders } from 'lib/http'

axios.defaults.headers.common = getHeaders()

export const useAxios = ({ url, method = 'get', params = {}, data = null }) => {
  const baseURL = window.LOCAL_SETTINGS.API_BASE
  const query = new URLSearchParams(params).toString()
  url = `${baseURL}${url}?${query}`

  const apiCall = useCallback(() => axios[method](url, data), [url, method, data])
  const [response, setResponse] = useState(null)
  const [error, setError] = useState('')
  const [loading, setLoading] = useState(true)

  const fetchData = async(params) => {
    try {
      const response = await apiCall()
      setResponse(response.data)
    } catch (error) {
      if (error.response) {
        // The request was made and the server responded with a status code
        setResponse(null)
        setError(error.response.data) // Clear the error state
      } else if (error.request) {
        // The request was made but no response was received
        // console.log(error.request)
        setError('No response received from the server.')
      } else {
        // Something happened in setting up the request that triggered an Error
        // console.log('Error', error.message)
        setError('Error occurred while making the request.')
      }
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    fetchData(apiCall)
  }, [apiCall, url]) // watch for changes

  const refetch = () => {
    setLoading(true)
    fetchData()
  }

  return { response, error, loading, refetch }
}