import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { get } from 'lodash'
import { adminKpiCalculationMomentTypesForDropdownList } from 'actions/admin_kpi_calculation_moment_types'
import { Grid } from "@material-ui/core"
import { FormikDropdownField } from 'components/form/Dropdown'
import { FormikDateTimePicker } from 'components/form/DatetimePicker'

export const Frequency = ({formik_props, name, label, onChanged}) => {
    const dispatch = useDispatch()
    const frequency_options = useSelector(() => adminKpiCalculationMomentTypesForDropdownList.getAsOptions())
    const [momentType, setMomentType] = useState(null)

    name = name ? name : "frequency"

    useEffect(() => {
        dispatch(adminKpiCalculationMomentTypesForDropdownList.fetchListIfNeeded())
    },[])

    useEffect(() => {
        onChanged(momentType)
    },[momentType])


    useEffect(() => {
        console.log(get(formik_props.values, name))
        setMomentType(get(formik_props.values, name))
    },[get(formik_props.values, name)])

    return (
        <FormikDropdownField
            name={name}
            label={label ? label : "Frequency"}
            options={frequency_options}
            formik_props={formik_props}
            fullWidth={false}
        />
    )
}

export const MomentAsFrequency = ({ moment_type }) => {
    let moment_as_readable_frequency
    switch (moment_type) {
        case 'day_of_week':
            moment_as_readable_frequency = 'Weekly'
            break
        case 'day_of_month':
            moment_as_readable_frequency = 'Monthly'
            break
        case 'today':
            moment_as_readable_frequency = 'Daily'
            break
    }

    return moment_as_readable_frequency || null
}

export const weekDayValueToString = (value) => {
    var weekday = Array(7)
    weekday[0]="Monday"
    weekday[1]="Tuesday"
    weekday[2]="Wednesday"
    weekday[3]="Thursday"
    weekday[4]="Friday"
    weekday[5]="Saturday"
    weekday[6]="Sunday"

    return weekday[value]
}

export const WeekDayOptions = [
    {value: 0, label: "Monday"},
    {value: 1, label: "Tuesday"},
    {value: 2, label: "Wednesday"},
    {value: 3, label: "Thursday"},
    {value: 4, label: "Friday"},
    {value: 5, label: "Saturday"},
    {value: 6, label: "Sunday"},
]

export const FrequencyValues = ({formik_props, name, label, moment_type="day_of_week", onChanged}) => {

    const [rangeOptions, setRangeOptions] = useState(null)
    name = name ? name : "Value"

    useEffect(() => {
        updateRangeOptions(moment_type)
    },[moment_type])

    const updateRangeOptions = (moment_type) => {
        var options = []
        if(moment_type == "day_of_week") { options = WeekDayOptions }
        if(moment_type == "day_of_month") {
            options.push({value: 'first', label: 'First'})
            options.push({value: 'first_business', label: 'First Business'})
            for (var i = 1; i <= 31; ++i) {
                options.push({value: i, label: i})
            }
            options.push({value: 'last', label: 'Last'})
            options.push({value: 'last_business', label: 'Last Business'})
        }
        setRangeOptions(options)
    }

    return(
        <FormikDropdownField
            name={name}
            label={label}
            options={rangeOptions}
            formik_props={formik_props}
            fullWidth={false}
        />
    )
}

export const AdvancedFilterOptions = () => {

    const filter_based_on_options = [
        {value: 'is', label: "is"},
        {value: 'is_not', label: "is not"},
        {value: 'is_after', label: "is after"},
        {value: 'is_on_or_after', label: "is on or after"},
        {value: 'is_before', label: "is before"},
        {value: 'is_on_or_before', label: "is on or before"},
        {value: 'is_blank', label: "is blank"},
        {value: 'is_not_blank', label: "is not blank"},
    ]

    return(
        <>
            <FormikDropdownField
                name={field_name_value}
                label={value_label ? value_label : "Advanced filter options"}
                options={filter_based_on_options}
                formik_props={formik_props}
                fullWidth={false}
            />
            <FormikDateTimePicker
                label="dd/MM/yyyy"
                name="advanced_filter_date_time"
                formik_props
                include_time
            />
        </>
    )

}
