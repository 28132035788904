import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Separator } from 'components/layout/Separator'
import { Formik, Form, Field } from 'formik'
import * as Yup from 'yup'
import {showSuccess, showError} from 'actions/Error'
import { handleSubmitResult } from 'actions/form'
import { FormikInputField } from 'components/form/InputField'
import { FormikDateTimePicker } from 'components/form/DatetimePicker'
import { FormikTextareaField } from 'components/form/TextareaField'
import { CitySelectField } from 'components/form/CitySelectField'
import { FormikFileSetField } from 'components/form/FileSetField'
import { FormikCheckboxField } from 'components/form/CheckboxField'
import { FormikBringgStatus } from 'admin/components/integrations/bringg/BringgStatus'
import Timestamp from 'components/Timestamp'
import { Grid } from '@material-ui/core'
import { Card } from 'components/layout/Card'
import Alert from '@material-ui/lab/Alert'

export const validationSchema = Yup.object().shape({
    'name': Yup.string().required("Required")
})

class AdminVehicleForm extends Component {

    render() {
        const { formik_props } = this.props
        const vehicle_status_severity = formik_props.values.status ? 'success' : 'warning'
        const vehicle_status_label = formik_props.values.status ? 'Vehicle is operating' : 'Vehicle currently not operating'

        return (
            <Grid container spacing={2}>
              <Grid item xs={12} lg={4}>
                <Card title="Details"
                      content={
                          <>
                          <FormikInputField name="name"
                                            type="text"
                                            label="Vehicle Name"
                                            helperText="eg.CPT Bakkie 1"
                                            formik_props={formik_props}
                          />
                          <FormikInputField name="model"
                                            type="text"
                                            label="Model"
                                            helperText="eg.Nissan UD40"
                                            formik_props={formik_props}
                          />
                          <FormikInputField name="registration_number"
                                            type="text"
                                            label="Registration number"
                                            helperText="eg. CAA 134 568"
                                            formik_props={formik_props}
                          />
                          <FormikTextareaField name="description"
                                               type="text"
                                               label="Description"
                                               rows={5}
                                               formik_props={formik_props}
                          />
                        </>
                      }
                  />
                </Grid>
                <Grid item xs={12} lg={4}>
                  <Card title="License"
                        content={<>
                                  <FormikDateTimePicker name="registration_expiration_date"
                                                        label="License expiry date"
                                                        formik_props={formik_props}
                                                        format={'yyyy-MM-dd'}
                                                        include_time={false}
                                  />

                                  <FormikFileSetField max_num_files={1}
                                                      name="current_license_disc_image"
                                                      label={"picture of the current valid license disc"}
                                                      formik_props={formik_props}
                                  />
                                </>
                        }
                  />
                  <Card title="Capacity"
                        content={<>
                                  <FormikInputField name="capacity_kg"
                                    type="text"
                                    label="Capacity (kg)"
                                    helperText="The weight in kilograms that the vehicle can carry"
                                    formik_props={formik_props}
                                  />
                                  <FormikInputField name="owning_company"
                                                    type="text"
                                                    label="Owning company"
                                                    helperText="If other than SA Harvest"
                                                    formik_props={formik_props}
                                  />
                                </>
                        }
                  />
                <Card title="Branch"
                          content={<>
                                    <CitySelectField name="operating_city"
                                         label="Operating city"
                                         formik_props={formik_props}
                                   />
                                   <FormikInputField name="owning_company"
                                                     type="text"
                                                     label="Owning company"
                                                     formik_props={formik_props}
                                    />
                                  </>
                       }
                 />
               </Grid>
               <Grid item xs={12} lg={4}>
                 <Card title="Status"
                       content={
                         <Alert severity={vehicle_status_severity}>
                           <FormikCheckboxField
                                    name="status"
                                    label={vehicle_status_label}
                                    formik_props={formik_props}
                                    color="primary"
                                 />
                        </Alert>
                       }
                 />
                 <Card title="Vehicle Image"
                       content={<FormikFileSetField max_num_files={1}
                                                     name="vehicle_image"
                                                     label={"picture of the vehicle"}
                                                     formik_props={formik_props}
                                 />
                       }
                 />
                 <FormikBringgStatus formik_props={formik_props} />
                </Grid>
            </Grid>
        )
    }

}

export default AdminVehicleForm
