import React, { useEffect, useState} from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { get, lowerCase } from 'lodash'
import { makeStyles, Card, CardContent, Typography, Grid } from '@material-ui/core'
import NumberFormat from 'react-number-format'
import logo from 'assets/images/logo.svg'
import { marketingMoneyDonationReport } from '../actions/marketing_money_donation_report'
import { Separator } from 'components/layout/Separator'

const useStyles = makeStyles((theme) => ({
  sourceAttribution: {
    fontWeight: 500,
    textAlign: 'center',
    fontSize: 48,
    color: 'black',
    '& img': {
      marginLeft: 15,
      marginRight: 15
    },
    '& span': {
      marginLeft: 15,
      marginRight: 15
    }
  },
  sourceTotals: {
    background: 'transparent',
    width: 200,
    textAlign: 'center',
    fontWeight: '700',
    border: 'none',
    fontSize: 24
  },
  total_source_money_donations: {
    border: 'none'
  },
  total_source_meals_equivalent: {
    float: 'right'
  },
  logo: {
    height: 60
  },
  addSign: {
    [theme.breakpoints.down("sm")]: {
      display: 'block',
      marginTop: -25,
      marginBottom: -15
    },
  },
}))

export default function SourceAttribution({source_slug, title, show_source_totals=false, source_logo}) {
  const classes = useStyles()
  const dispatch = useDispatch()
  const report = useSelector(() => marketingMoneyDonationReport.getData())
  const is_loading = useSelector(() => marketingMoneyDonationReport.isLoading())
  const source_img_src = source_logo ? source_logo : "/static/images/donations/source/" + source_slug + ".png"

  useEffect(() => {
        async function fetchStaticObjects() {
            dispatch(marketingMoneyDonationReport.loadData())
            dispatch(marketingMoneyDonationReport.updateListFilter({source_slug: source_slug, is_paid: true}))
        }
        fetchStaticObjects()
    }, [])

  const this_source_money_donations_total = get(report, ['amount_rands']) ? get(report, ['amount_rands']) : null // total_source_money_donations
  const this_source_equivalent_total_meals = this_source_money_donations_total ? this_source_money_donations_total/window.LOCAL_SETTINGS.COST_PER_MEAL : null // this_source_money_donations_total

  return(
        <>
          <div className={classes.sourceAttribution}>
              {source_slug != 'challenge' &&
                <>
                  <img src={source_img_src} alt={source_slug} title={source_slug} className={classes.logo}  />
                  <span className={classes.addSign}>+</span>
                </>
              }
              <img src={logo} className={classes.logo} />
          </div>
          <Typography variant="h5" align="center" style={{color: 'black'}}>{ title }</Typography>
          <Separator height={15} />
          {show_source_totals && total_source_money_donations &&
            <Grid container justify="center" alignItems="center">
              <Card variant="outlined" className={[classes.sourceTotals, classes.total_source_money_donations]}>
                <CardContent>
                  <NumberFormat value={this_source_money_donations_total} displayType={'text'} thousandSeparator={true} prefix={'R'} decimalScale={0} />
                  <Typography variant="h6">RAISED</Typography>
                </CardContent>
              </Card>
              <Card variant="outlined" className={[classes.sourceTotals, classes.total_source_meals_equivalent]}>
                <CardContent>
                  <NumberFormat value={this_source_equivalent_total_meals} displayType={'text'} thousandSeparator={true} decimalScale={0} />
                  <Typography variant="h6">MEALS</Typography>
                </CardContent>
              </Card>
            </Grid>
          }
        </>
  )
}
