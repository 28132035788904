import { ItemList } from 'orm'
import { values, get, map, head, filter, startCase } from 'lodash'
import { download } from 'actions/lib'
 
class FeedingFrequencyList extends ItemList {
    getEntityKey() {
        return "admin/feeding_frequency"
    }

    getAsOptions() {
        const items = this.getVisibleObjects()
        return map(items, function(item) { return {label: `${startCase(item.frequency)} x${item.number_of_times}`, value: item.id}})
    }

    getForCompany({company_id}) {
        // Assumes the matching beneficiary is already loaded
        return head(filter(values(this.getAllObjects()), (x) => get(x, ["company", "id"]) === company_id))
    }

}

export const adminFeedingFrequencyList = new FeedingFrequencyList("admin_feeding_frequency__default")