import React from 'react'
import { get } from 'lodash'
import { showSuccess } from 'actions/Error'
import { FormikAddressDropdownWithFormBasedAddNew } from 'admin/components/addresses/form/AddressDropdown'
import { handleSubmitResult } from 'actions/form'
import { adminBeneficiaryList } from '../actions/admin_beneficiary'
import { adminAddressList } from 'admin/components/addresses/actions/admin_address'
import { useDispatch } from 'react-redux'

export function FormikBeneficiaryAddressDropdownWithFormBasedAddNew( { beneficiary, name, label, address_ids, formik_props } ) {

	const dispatch = useDispatch()

	const onSave = ( values, formik_funcs, onAdded ) => {
		const on_ok = function( json ) {
			dispatch( adminBeneficiaryList.invalidateObject( beneficiary.id ) )
			dispatch( adminBeneficiaryList.ensureObjectLoaded( beneficiary.id ) )
			showSuccess( "Saved", "Address added to beneficiary" )
			onAdded( json.id )
		}

		values.company = beneficiary.company.id
		return dispatch( adminAddressList.saveNewObject( values ) ).then( ( res ) => handleSubmitResult( { res, formik_funcs, on_ok } ) )
	}

	return (
		<FormikAddressDropdownWithFormBasedAddNew name={ name }
			label={ label }
			address_ids={ address_ids }
			onSave={ onSave }
			newAddressInitialValues={ { company: get( beneficiary, [ "company", "id" ] ) } }
			formik_props={ formik_props }
		/>
	)

}
