/** @jsxImportSource @emotion/react */
import React, { useEffect, useRef } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from "react-router-dom";
import { isUndefined } from "lodash";
import { css } from "@emotion/react";
import { useReactToPrint } from "react-to-print";
import Report from "./DynamicReport";
import { adminDynamicReportGenerator } from '../../../actions/dynamic_reporting'
import { head } from 'lodash'


const PrintableReport = () => {
  const componentRef = useRef();
  const dispatch = useDispatch();
  const history = useHistory();
  const dynamicReport = useSelector(() => adminDynamicReportGenerator.getObject(0))
  const dynamicReportDataset = dynamicReport.data
  if (isUndefined(dynamicReportDataset)) {
    history.push({
      pathname: "/admin/reports/dynamic_reporting",
    })
  }
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: 'saharvest dynamic report',
    onAfterPrint: () => console.log('Printed PDF successfully!'),
  });

  return (
    <>
      <div css={ report_page }>
        { !isUndefined(dynamicReportDataset) &&
          <Report ref={ componentRef } props={ dynamicReportDataset }/>
        }
        <div>
          <button css={ print_button } onClick={ handlePrint }>Generate pdf</button>
        </div>
      </div>
    </>
  );
}

export default PrintableReport;

const report_page = css`
  text-align: center;
  width: 100%;
  scrollbar-width: none;
  display: flex;
  flex-direction: column;
`

const print_button = css`
  width: 8rem;
  height: 2rem;
  font-size: 1rem;
  color: snow;
  margin: 1rem 0rem 1rem 0rem;
  border-radius: 0.2rem;
  border: none;
  background-color: rgb(67, 106, 196);
  cursor: pointer;
  outline: none;
`
