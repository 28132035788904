import PropTypes from 'prop-types';
import { forwardRef } from 'react';
import { Box } from '@material-ui/core';
import { Icon } from '@iconify/react';

const Iconify = forwardRef(({ icon, width = 20, className, ...other }, ref) => (
  <Box ref={ref} className={className} {...other}>
    <Icon icon={icon} style={{ width, height: width }} />
  </Box>
));

Iconify.propTypes = {
  className: PropTypes.string,
  icon: PropTypes.oneOfType([PropTypes.element, PropTypes.string]).isRequired,
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

export default Iconify;