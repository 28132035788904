import React, { useEffect, useState } from 'react'
import { get } from 'lodash'
import { useParams, useHistory } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { CommonTable } from 'components/CommonTable'
import Timestamp from 'components/Timestamp'
import {showSuccess, showError} from 'actions/Error'
import { handleSubmitResult } from 'actions/form'
import AdminCommonFormLayout from '../layout/AdminCommonFormLayout'
import { adminCompanyReportGeneration } from './actions/admin_company_report_generation'
import { adminCompanyReportGenerator, adminCompanyReport } from './actions/admin_company_report'
import { Card } from 'components/layout/Card'
import CheckIcon from '@material-ui/icons/Check'
import ClearIcon from '@material-ui/icons/Clear'
import CheckCircleIcon from '@material-ui/icons/CheckCircle'
import CancelIcon from '@material-ui/icons/Cancel'
import { GeneratedReportDownload } from './AdminDownloadReport'
import { makeStyles, CircularProgress, Button, Grid } from '@material-ui/core'
import { Formik, Form, Field } from 'formik'
import { FormikDateTimePicker } from 'components/form/DatetimePicker'
import * as Yup from 'yup'

const useStyles = makeStyles((theme) => ({
  generateNowButton: {
    paddingTop: 6,
    paddingBottom: 6,
  },
  actionGrid: {
    marginLeft: 10,
    marginBottom:  10
  },
  loadingIndicator: {
    marginLeft: -10,
    marginRight:  10
  }
}))

export const validationSchema = Yup.object().shape({
    start_date: Yup.string().required("Required").nullable(),
    end_date: Yup.string().required("Required").nullable()
})

export const AdminCompanyReportGenerations = ({company_report_id}) => {
    const classes=useStyles()
    const dispatch = useDispatch()
    const history = useHistory()
    const is_loading = company_report_id && adminCompanyReportGeneration.isLoading()
    const reports = useSelector(() => adminCompanyReportGeneration.getVisibleObjects())
    const companyReport = useSelector(() => adminCompanyReport.getObject(company_report_id))
    const [generatingReport, setGeneratingReport] = useState(false)
    const isAdhocReport = get(companyReport, "frequency") == "adhoc" ? true : null
    const initial_values = {}

    useEffect(() => {
        if ( company_report_id ) {
            dispatch(adminCompanyReportGeneration.updateListFilter({company_report: company_report_id}))
            dispatch(adminCompanyReport.ensureObjectLoaded(company_report_id))
        }
    }, [company_report_id])

    useEffect(() => {
        dispatch(adminCompanyReportGeneration.fetchListIfNeeded())
    }, [])

    const forceGenerateNow = (values, formik_funcs) => {
        let can_continue = true
        if(isAdhocReport) {
           if(!values.end_date || !values.start_date) {
             can_continue = false
             return false
           }
        }
        if(can_continue === true) {
          setGeneratingReport(true)
          const on_ok = (json) => {
              if ( json.status === 'success' ) {
                  setGeneratingReport(false)
                  showSuccess("Report generated")
              } else {
                  showError("Report failed")
              }
              dispatch(adminCompanyReportGeneration.invalidateList())
              dispatch(adminCompanyReportGeneration.fetchListIfNeeded())
          }
          if(isAdhocReport) {
            const company_id = get(companyReport, "company")
            const report_name = get(companyReport, "report_name")
            const start_date = values.start_date
            const end_date = values.end_date
            dispatch(adminCompanyReportGenerator.forceGenerateNowAdhoc({company_id, report_name, start_date, end_date})).then(on_ok)
          }
          else {
            dispatch(adminCompanyReportGenerator.forceGenerateNow(company_report_id)).then(on_ok)
          }
      }
    }

    const renderGenerateNowButton = (type) => {
      return(
        <Button
          onClick={forceGenerateNow}
          variant="outlined"
          size="large"
          color="primary"
          className={classes.generateNowButton}
          type={type}
        >
          { generatingReport ? <><CircularProgress className={classes.loadingIndicator} size={24} /> Generating Report...</> : <>Generate a Report Now</> }
        </Button>
      )
    }

    const renderDateRangeForm = () => {
      return (
        <Formik
          initialValues={initial_values}
          onSubmit={forceGenerateNow}
          enableReinitialize={true}
          validationSchema={validationSchema}
        >
          {formik_props => {
              const { values } = formik_props
              return (
                <Form>
                  <Grid container spacing={3} className={classes.actionGrid} style={{ marginTop: -70 }}>

                    <Grid item>
                      <FormikDateTimePicker name="start_date"
                                            label="Start Date"
                                            size="small"
                                            formik_props={formik_props}
                                            include_time={false}
                                            format={'yyyy-MM-dd'}
                      />
                    </Grid>

                    <Grid item>
                      <FormikDateTimePicker name="end_date"
                                            label="End date"
                                            size="small"
                                            formik_props={formik_props}
                                            include_time={false}
                                            format={'yyyy-MM-dd'}
                      />
                    </Grid>

                    <Grid item>
                      {renderGenerateNowButton("submit")}
                    </Grid>

                  </Grid>

                </Form>
              )}}
        </Formik>
      )
    }

    const renderButtonOnly = () => {
      return(
        <Grid container spacing={1} className={classes.actionGrid} style={{marginTop: 10}}>

          <Grid item xs={12} md={6} lg={3}>
            {renderGenerateNowButton()}
          </Grid>

        </Grid>
      )
    }

    const columns = [
        { field: 'generated_at',
          title: 'Generated at',
          render: (item) => <Timestamp value={item.generated_at} format='datetime' />,
          sort: (direction) => this.onUpdateListOrdering(direction === 'asc' ? 'generated_at' : '-generated_at'),
        },
        { field: 'status',
          title: 'Status',
          render: (item) => item.status == 'success' ? <CheckCircleIcon style={{color: 'green'}} /> : <CancelIcon style={{color: 'red'}} />,
          sort: (direction) => this.onUpdateListOrdering(direction === 'asc' ? 'status' : '-status'),
        },
        { field: 'error',
          title: 'Message',
        },
        { field: 'emailed_to',
          title: 'Emailed to',
        },
        { field: 'report_file_info',
          render: function(item) {
              if ( get(item, "report_file_info", "original_download_url") ) {
                  return <GeneratedReportDownload generated_report_id={get(item, "id")} />
              } else {
                  return "---"
              }
          },
          title: 'File'
        }
    ]

    return (
        <>
          { adminCompanyReport.supporteGenerateNow(companyReport) &&
            <>{isAdhocReport ? renderDateRangeForm() : renderButtonOnly() }</>
          }
          <CommonTable is_loading={ is_loading }
                       rows={ reports }
                       columns={columns}
                       canDelete={true}
                       item_list={adminCompanyReportGeneration}
          />
        </>
    )

}
