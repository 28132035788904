import React from 'react'
import TotalsWidget from './TotalsWidget'
import { remove } from 'lodash'
export default function TotalsWidgetParcels( { data } ) {

  if ( !data ) {
    return null
  }

  const actions = [
    { label: 'View Orders', href: '/admin/parcels' },
    { label: 'View Reports', href: '/admin/reports/beneficiary_parcels' }
  ]

    
  const totalObject = data.find( item => item.hasOwnProperty( 'total' ) )

  // Access the total value
  const [ grandTotal, setGrandTotal ] = React.useState( totalObject ? totalObject.total : 0 )

  React.useEffect( () => {
    setGrandTotal( totalObject ? totalObject.total : 0 )
  }, [ data ] )

  // Remove the total object from the data array cos we don't want to print it in the row details
  remove( data, item => item.hasOwnProperty( 'total' ) )

  function getLabel( direction, transportByWarehouseCompany ) {
    if ( direction === "beneficiary_dropoff" && !transportByWarehouseCompany ) {
      return "Beneficiary Collection"
    } else if ( direction === "beneficiary_dropoff" && transportByWarehouseCompany ) {
      return "Beneficiary Dropoff"
    } else if ( direction === "donor_pickup" && !transportByWarehouseCompany ) {
      return "Donor Dropoff"
    } else if ( direction === "donor_pickup" && transportByWarehouseCompany ) {
      return "Donor Pickup"
    }
  }

  const rows = data.map( item => ( {
    name: getLabel( item.direction, item.transport_by_warehouse_company ),
    value: item.count
  } ) )



  return (
      <TotalsWidget title="Parcels"
        grand_total={ grandTotal }
        detail_rows={ rows }
        actions={ actions }
        color={ '#000' }
      />
  )
}
