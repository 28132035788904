import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { get, size } from 'lodash'
import AdminCommonListLayout from '../layout/AdminCommonListLayout'
import { adminChecklistList } from './actions/admin_checklist'
import { ShortId } from 'components/ShortId'
import { AddButton } from 'components/form/Buttons'
import { KpiCategoryName } from 'admin/components/kpis/KpiAttributes'
import { Grid } from "@material-ui/core"
import AddIcon from '@material-ui/icons/Add'

export default function AdminChecklists({}) {
    const dispatch = useDispatch()
    const history = useHistory()
    const checklists = useSelector(() => adminChecklistList.getVisibleObjects())
    const is_loading = useSelector(() => adminChecklistList.isLoading())
    const filter = useSelector(() => adminChecklistList.getFilter())

    useEffect(() => {
        dispatch(adminChecklistList.fetchListIfNeeded())
    },[])

    const onEditChecklist = (checklist_id) => {
        history.push({
            pathname: '/admin/checklist/' + checklist_id
        })
    }

    const onAddChecklist = () => {
        history.push('/admin/checklist')
    }

    const onUpdateListOrdering = (field) => {
      dispatch(adminChecklistList.updateListOrdering(field))
    }

    const renderActions = () => {
        return(
            <Grid container spacing={2}>
                <Grid item>
                    <AddButton label="Results" color="primary" variant="outlined" onClick={() => history.push('/admin/checklists/results')} />
                </Grid>
                <Grid item>
                    <AddButton label="Schedule" color="primary" variant="outlined" onClick={() => history.push('/admin/checklists/schedules')} />
                </Grid>
            </Grid>
        )
    }

    const columns = [

        {
            field: 'name',
            title: 'Checklist',
            render: (item) => <a onClick={() => onEditChecklist(get(item, "id"))}>{ get(item, "name") }</a>,
            sort: (direction) => onUpdateListOrdering(direction === 'asc' ? 'name' : '-name')
        },
        {
            field: 'category',
            title: 'Category',
            render: (item) => <KpiCategoryName kpi_category_id={get(item, "category")} />,
            sort: (direction) => onUpdateListOrdering(direction === 'asc' ? 'category' : '-category')
        },
        {
            field: 'questions',
            title: 'Questions',
            align: 'center',
            render: (item) => size(checklists, 'questions'),
            sort: (direction) => onUpdateListOrdering(direction === 'asc' ? 'questions' : '-questions')
        },
        {
            field: 'pass_percentage',
            title: 'Pass mark',
            align: 'center',
            render: (item) => get(item, 'pass_percentage') + '%',
            sort: (direction) => onUpdateListOrdering(direction === 'asc' ? 'checklist_checks_count' : '-checklist_checks_count')
        },
    ]

    return (
        <AdminCommonListLayout
            active_key="checklists"
            breadcrumbs={[
                {name: 'admin_home'},
                {name: 'checklists', label: "Checklists", url: '/admin/kpis/checklists'}
            ]}
            add_label={<AddIcon />}
            onAddRow={onAddChecklist}
            onEditRow={onEditChecklist}
            is_loading={is_loading}
            columns={columns}
            item_list={adminChecklistList}
            rows={checklists}
            renderAdditionalActions={renderActions}
            title="Checklists"
        />
    )

}
