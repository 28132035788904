import { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { get, map, size } from 'lodash'
import { makeStyles, Typography, CardContent, CardActions, Tooltip, Divider, Badge } from '@material-ui/core'
import MuiCard from '@material-ui/core/Card'
import ChatBubbleOutlineIcon from '@material-ui/icons/ChatBubbleOutline'
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined'
import HistoryOutlinedIcon from '@material-ui/icons/HistoryOutlined'
import SentimentVeryDissatisfiedIcon from '@material-ui/icons/SentimentVeryDissatisfied'
import SentimentSatisfiedAltIcon from '@material-ui/icons/SentimentSatisfiedAlt'
import { Modal, AlertModal } from 'components/layout/Modal'
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import ReportProblemOutlinedIcon from '@material-ui/icons/ReportProblemOutlined'
import { AddButton } from 'components/form/Buttons'
import { adminKpiTemplateList } from 'admin/components/kpis/actions/admin_kpi_template'
import { capitalCamel } from 'components/CapitalCamel'
import { MomentAsFrequency } from 'components/form/Frequency'
import Timestamp from 'components/Timestamp'
import AdminKpiCommentForm from 'admin/components/kpis/form/AdminKpiCommentForm'
import AdminResolveKpiResultForm from 'admin/components/kpis/form/AdminResolveKpiResultForm'
import { adminKpiResultList } from 'admin/components/kpis/actions/admin_kpi_result'
import ChatBubbleOutlineOutlinedIcon from '@material-ui/icons/ChatBubbleOutlineOutlined'
import ChatOutlinedIcon from '@material-ui/icons/ChatOutlined'

const useStyles = makeStyles((theme) => ({
    root: {
        position: 'relative',
        textAlign: 'center'
    },
    pass: {
        background: "#00c853",
    },
    fail: {
        background: "#ef5350"
    },
    title: {
        textTransform: 'capitalize',
        fontWeight: 500
    },
    icon: {
        color: 'white',
        cursor: 'pointer',
        margin: '0 5px'
    },
    dark_icon: {
        color: '#777',
    },
    sentimentIcon: {
        fontSize: 32,
    },
}))

export function KpiCard({ kpi, kpi_result, category, onShowHistory, onAddComment, onResolveKpi }) {
    const dispatch = useDispatch()
    const classes = useStyles()
    const kpi_template = useSelector(() => adminKpiTemplateList.getObject(get(kpi, 'kpi_template')))
    const statusClass = get(kpi_result, 'passed') === true ? classes.pass : classes.fail
    const sentimentIcon = get(kpi_result, 'passed') === true ? <SentimentSatisfiedAltIcon className={classes.sentimentIcon} /> : <SentimentVeryDissatisfiedIcon className={classes.sentimentIcon} />

    useEffect(() => {
        dispatch(adminKpiTemplateList.ensureObjectLoaded(get(kpi, 'kpi_template')))
    },[])

    const onEditComments = (item) => {
        setKpiResult(item)
        setComments(get(item, "comments"))
        setEditingComments(true)
    }

    const renderResolveKpiForm = (item) => {
        setEditingResolveKpi(true)
        setKpiResult(item)
    }

    const onClose = () => {
        setKpiResult(null)
        setEditingResolveKpi(null)
        setEditingComments(null)
        setComments(null)
        dispatch(adminKpiResultList.invalidateList())
        dispatch(adminKpiResultList.fetchListIfNeeded())
    }

    return(
        <>
            <MuiCard variant="outlined" className={[classes.root, statusClass]} style={{ borderRadius: 12 }}>
                <CardContent>
                    {/* <Typography>{ category }</Typography> */}
                    <Typography className={classes.title}>{ capitalCamel(get(kpi_template, 'name')) }</Typography>

                    { get(kpi, ['calculation_start_at', 'moment_type']) &&
                        <>
                            <Typography variant="caption">
                                Frequency: <MomentAsFrequency moment_type={ get(kpi, ['calculation_start_at', 'moment_type'])} />
                            </Typography><br />
                        </>
                    }
                    { get(kpi_result, 'calculated_at') &&
                        <>
                        <Typography variant="caption">Calculated on: <Timestamp value={ get(kpi_result, 'calculated_at') } format="at" /></Typography><br />
                        </>
                    }
                    { sentimentIcon }<br />
                    { get(kpi_result, 'calculation_description') &&
                        <>
                        { get(kpi_result, 'calculation_description')}
                        <br />
                        </>
                    }

                    <Timestamp value={ get(kpi_result, 'calculated_from') } format="at" /> to <br /><Timestamp value={ get(kpi_result, 'calculated_to') } format="at" />

                    { get(kpi_result, 'failure_description') && <Typography>{ get(kpi_result, 'failure_description') }</Typography> }
                </CardContent>
                <CardActions>
                    <KpiActions
                        kpi_result={ kpi_result }
                        kpi={ kpi }
                        onShowHistory={ onShowHistory }
                        onAddComment={ onAddComment }
                        onResolveKpi={ onResolveKpi }
                        onClose={onClose}
                    />
                </CardActions>
            </MuiCard>
        </>
    )
}

export function KpiActions({ kpi_result, kpi, onShowHistory, onAddComment, onResolveKpi, darkIcons }) {
    const classes = useStyles()
    const dispatch = useDispatch()
    const [showComments, setShowComments] = useState(null)
    const [showResolveKpi, setShowResolveKpi] = useState(null)
    const [showFailureMessage, setShowFailureMessage] = useState(null)
    const iconClass = darkIcons ? [classes.icon, classes.dark_icon] : classes.icon
    const [editingResolveKpi, setEditingResolveKpi] = useState(null)
    const [editingComments, setEditingComments] = useState(null)
    const comments = get(kpi_result, "comments")

    const renderResolveKpi = () => {
        return(
            <>
                { get(kpi_result, 'closed') ? 'Resolved' :
                    <div style={{textAlign: 'center'}}>
                        <AddButton
                            onClick= {() => onResolveKpi(get(kpi_result, 'id'), get(kpi_result, 'name'))}
                            size="small"
                            label="Resolve KPI"
                            color="secondary"
                        />
                    </div>
                }
            </>
        )
    }

    const renderKpiResultComments = (item) => {
        if(size(comments) > 0) {
            return(
                <Badge badgeContent={size(comments)} color="primary">
                    <ChatOutlinedIcon onClick={() => setEditingComments(true)} style={{color: 'white', cursor: 'pointer'}} />
                </Badge>
            )
        }
        else {
            return(
                <ChatBubbleOutlineOutlinedIcon onClick={() => setEditingComments(true)} style={{color: 'white', cursor: 'pointer'}} />
            )
        }
    }

    const onClose = () => {
        dispatch(adminKpiResultList.invalidateList())
        dispatch(adminKpiResultList.fetchListIfNeeded())
    }

    return(
        <>
            { renderKpiResultComments(get(kpi_result, "comments")) }

            { get(kpi_result, 'closed') == true ?
                <Tooltip title="Passed" arrow>
                    <CheckBoxIcon className={iconClass} />
                </Tooltip> :
                <Tooltip title="Click to resolve KPI" arrow>
                    <ReportProblemOutlinedIcon className={iconClass} onClick={() => setEditingResolveKpi(true)} />
                </Tooltip>
            }

            { editingResolveKpi &&
                <Modal onClose={() => setEditingResolveKpi(false)} title="Resolve KPI Result">
                    <AdminResolveKpiResultForm kpi_result={ kpi_result } onSaved={onClose} />
                </Modal>
            }
            { editingComments &&
                <Modal onClose={() => setEditingComments(false)} title="Comments on KPI Result">
                    <AdminKpiCommentForm comments={ comments } kpi_result={ kpi_result} onSaved={onClose} />
                </Modal>
            }
        </>
    )
}
