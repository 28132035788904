import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams, useHistory } from 'react-router-dom'
import { get } from 'lodash'
import { adminUserList } from './actions/admin_user'
import * as Yup from 'yup'
import { showSuccess, showError } from 'actions/Error'
import { handleSubmitResult } from 'actions/form'
import AdminCommonFormLayout from '../layout/AdminCommonFormLayout'
import { AdminUserForm } from './form/AdminUserForm'
import Loading from 'components/Loading'

const yup_shape = {
  email: Yup.string().required( 'Required' ),
}

const validationSchema = Yup.object().shape( yup_shape )

const AdminUser = () => {
  const dispatch = useDispatch()
  const history = useHistory()
  const { user_id } = useParams()
  const user = useSelector( () => adminUserList.getObject( user_id ) )
  const is_loading = useSelector( () => adminUserList.isLoading() ) || ( user_id && !get( user, 'id' ) )
  const is_busy = useSelector( () => adminUserList.getIsSavingObject() )
  const initial_values = Object.assign(
    {},
    {
      employee: { roles: [] },
      user_filter: { cities: [], user: user_id },
    },
    user
  )

  useEffect( () => {
    dispatch( adminUserList.ensureObjectLoaded( user_id ) )
  }, [ dispatch, user_id ] )

  const onSave = ( values, formik_funcs ) => {
    values.language_code = 'en'

    const on_ok = function( json ) {
      dispatch( adminUserList.invalidateList() )
      showSuccess( 'Saved', 'User saved' )

      if ( !user_id ) {
        history.push( `/admin/user/${ json.id }` )
      }
    }

    if ( user_id ) {
      values.id = user_id
      return dispatch( adminUserList.saveObject( values ) ).then( ( res ) =>
        handleSubmitResult( { res, formik_funcs, on_ok } )
      )
    } else {
      return dispatch( adminUserList.saveNewObject( values ) ).then( ( res ) =>
        handleSubmitResult( { res, formik_funcs, on_ok } )
      )
    }
  }

  const that = this
  const title = `${ get( user, [ 'display_name' ], 'New user' ) }`

  return (
    <AdminCommonFormLayout
      breadcrumbs={ [
        { name: 'admin_home' },
        { name: 'users', label: 'Users', url: '/admin/users' },
        {
          name: 'user',
          label: get( user, [ 'display_name' ], 'New user' ),
          url: `/admin/user/${ user_id }`,
        },
      ] }
      is_busy={ is_busy }
      is_loading={ is_loading }
      title={ title }
      noCard={ true }
      initial_values={ initial_values }
      validationSchema={ validationSchema }
      onSave={ onSave }
    >
      { ( { formik_props } ) => (
        is_loading ? <Loading /> : <AdminUserForm formik_props={ formik_props } user_is_admin={ get(user, "is_superuser")} />
      ) }
    </AdminCommonFormLayout>
  )
}


export default AdminUser
