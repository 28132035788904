import React, { Component } from 'react'
import { connect } from 'react-redux'
import { head, get } from 'lodash'
import { Trans, Translation } from 'react-i18next'
import AdminCommonListLayout from '../layout/AdminCommonListLayout'
import Timestamp from 'components/Timestamp'
import { FormikInputField } from 'components/form/InputField'
import { adminCoreBusinessList } from './actions/admin_core_business'
import { Separator } from 'components/layout/Separator'
import { ShortId } from 'components/ShortId'
import { Button } from 'components/layout/Button'

class CoreBusinesses extends Component {

    componentDidMount() {
        const { dispatch } = this.props
        dispatch(adminCoreBusinessList.fetchListIfNeeded())
    }

    componentDidUpdate() {
        const { dispatch, filter } = this.props
        dispatch(adminCoreBusinessList.fetchListIfNeeded())
    }

    onEditCoreBusiness = (core_business_id) => {
        const { history } = this.props
        history.push({
            pathname: '/admin/core_business/'+core_business_id
        })
    }

    onAddCoreBusiness = () => {
        const { history } = this.props
        history.push('/admin/core_business')
    }

    onUpdateListOrdering = (field) => {
      const { dispatch } = this.props
      dispatch(adminCoreBusinessList.updateListOrdering(field))
    }

    render() {
        const { is_loading, core_businesses } = this.props
        const that = this

        const columns = [
            { field: 'Name', title: 'Name',
              render: (item) => get(item, "name"),
              sort: (direction) => this.onUpdateListOrdering(direction === 'asc' ? 'name' : '-name')
            },
            {
              field: 'category',
              title: 'Category'
            },
            { field: 'Description', title: 'Description',
              render: (item) => get(item, ["description"]),
            },
            { field: 'Slug', title: 'Slug',
              render: (item) => get(item, ["slug"]),
            }
        ]

        return (
            <AdminCommonListLayout active_key="core_businesses"
                                   breadcrumbs={[{name: 'admin_home'},
                                                 {name: 'core_businesses', label: "Core Businesses", url: '/admin/core_businesses'}]}
                                   add_label="Add Core Business"
                                   title="Core Business Options"
                                   onAddRow={that.onAddCoreBusiness}
                                   onEditRow={that.onEditCoreBusiness}
                                   is_loading={is_loading}
                                   columns={columns}
                                   item_list={adminCoreBusinessList}
            />
        )
    }
}

function mapStateToProps(state, props) {
    const core_businesses = adminCoreBusinessList.getVisibleObjects()

    return {
        core_businesses,
        is_loading: adminCoreBusinessList.isLoading(),
        filter: adminCoreBusinessList.getFilter()
    }
}

export default connect(mapStateToProps)(CoreBusinesses)
