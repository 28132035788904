import { ItemList } from 'orm'

class ChecklistScheduleList extends ItemList {
    getEntityKey() {
        return "admin/checklist_schedule"
    }

}

export const adminChecklistScheduleList = new ChecklistScheduleList("admin_checklist_schedule__default")
