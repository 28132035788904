import React from 'react'
import {
  makeStyles,
  TextField,
  Checkbox,
  Divider,
  Typography,
  Button,
  FormControlLabel,
  MenuItem
} from '@material-ui/core'
import { marketingCoreBusinessList } from 'actions/marketing_core_business'
import { company_core_business, cities, provinces, companies, salutations } from 'sampleData/sampleData'
import { Autocomplete } from '@material-ui/lab'

export function BeneficiaryDetails({formik_props}) {
  return(
    <>
    <TextField label="Organisation Name" variant="outlined" margin="normal" fullWidth name='company.beneficiary.name'/>
    <Autocomplete
      id="combo-box-demo"
      options={companies}
      getOptionLabel={(option) => option.name}
      name='company.beneficiary.parent'
      renderInput={(params) => <TextField {...params} label="Parent organisation" variant="outlined" fullWidth margin="normal" />}
    />
    <TextField label="Description..." multiline rows={5} fullWidth variant="outlined" margin="normal" name='company.beneficiary.description' />
    <CoreBusiness beneficiaryOptions={beneficiary_core_business} allOptions={false} />
    <TextField label="Number of people served" type="number" variant="outlined" margin="normal"/>
    </>
  )
}

export function BeneficiaryContactDetails({formik_props}) {
  return(
    <>
      <TextField label="Telephone" fullWidth variant="outlined" margin="normal" type="tel" />
      <TextField label="Email" fullWidth variant="outlined" margin="normal" type="email" />
      <TextField label="Website" fullWidth variant="outlined" margin="normal" type="url" />
     </>
  )
}

export function BeneficiaryAddress({formik_props}) {
  return(
    <>
      <TextField label="Google autocomplete address" fullWidth variant="outlined" margin="normal"/>
      <Autocomplete
        id="cities"
        options={cities}
        getOptionLabel={(option) => option.name}
        renderInput={(params) => <TextField {...params} label="City" variant="outlined" fullWidth margin="normal" />}
      />
      <Autocomplete
        id="provinces"
        options={provinces}
        getOptionLabel={(option) => option.name}
        renderInput={(params) => <TextField {...params} label="Province" variant="outlined" fullWidth margin="normal" />}
      />
    </>
  )
}

export function BeneficiaryPrimaryContact({formik_props}) {
  return(
    <>
    <TextField label="Salutation" fullWidth variant="outlined" margin="normal" name="contact.primary.salutation" select>{salutations.map(s => (<MenuItem value={s.name}>{s.name}</MenuItem>))}</TextField>
    <TextField label="First Name" fullWidth variant="outlined" margin="normal" name="contact.primary.first_name" />
    <TextField label="Last Name" fullWidth variant="outlined" margin="normal" name="contact.primary.first_name" />
    <TextField label="Position" fullWidth variant="outlined" margin="normal" name="contact.primary.position" helperText="eg. Manager" />
    <TextField label="Email" fullWidth variant="outlined" margin="normal" name="contact.primary.email" type="email" />
    <TextField label="Mobile phone number" fullWidth variant="outlined" margin="normal" name="contact.primary.mobile" type="tel" />
    </>
  )
}

export function BeneficiaryNpoStatus({formik_props}) {
  return(
    <>
      <div>
        <FormControlLabel control={<Checkbox checked={state.registeredNpo} onChange={handleFormChange} name="registeredNpo" color="primary"/>} label="Are you a registered NPO?" />
      </div>
      {state.registeredNpo &&
        <>
          <TextField label="NPO number" fullWidth type="number" variant="outlined" margin="normal"/>
          <UploadFiles title={'NPO Registration Certificate'} />
        </>
      }

      <div>
        <FormControlLabel control={<Checkbox checked={state.registeredDsd} onChange={handleFormChange} name="registeredDsd" color="primary"/>} label="Are you registered with the Department of Social Services?" />
      </div>
      {state.registeredDsd &&
        <>
          <TextField label="DSD number" fullWidth type="number" variant="outlined" margin="normal"/>
          <UploadFiles title={'DSD Registration Document'} />
        </>
      }
    </>
  )
}

export function BeneficiaryFoodRequirements({formik_props}) {
  return(
    <>
      <div>
          <div><i>What are your food requirements?</i></div>
          <CheckboxGroup options={foodRequirements} />
      </div>
    </>
  )
}

export function BeneficiaryVehicles({formik_props}) {
  return(
    <>
      <div>
        <FormControlLabel control={<Checkbox checked={state.hasVehicles} onChange={handleFormChange} name="hasVehicles" color="primary"/>} label="Do you have any vehicles with which you can collect food?" />
      </div>
      {state.hasVehicles &&
        <>
          <TextField multiline rows={5} fullWidth variant="outlined" margin="normal" placeholder={"Please provide details of the kind of vehicles you have available"} helperText={"eg. Toyota Hilux single cab bakkie or Nissan UD40 4 tonne truck"}/>
          <UploadFiles title={"Vehicle Pictures"} />
      </>
      }
    </>
  )
}

export function BeneficiaryRefrigeration() {
  return(
    <>
      <div>
        <FormControlLabel control={<Checkbox checked={state.hasRefrigeration} onChange={handleFormChange} name="hasRefrigeration" color="primary"/>} label="Do you have refigeration?" />
      </div>
      {state.hasRefrigeration &&
        <>
          <TextField multiline rows={5} fullWidth variant="outlined" margin="normal" placeholder={"Please provide details of the refrigerators and freezers that you have."} helperText={"eg. Double chest freezer 600 litres"}/>
          <UploadFiles title={"Refrigerator/Freezer Pictures"} />
        </>
      }
    </>
  )
}

export function BeneficiaryCookingFacilities({formik_props}) {
  return(
    <>
      <div>
        <FormControlLabel control={<Checkbox checked={state.hasCookingFacilities} onChange={handleFormChange} name="hasCookingFacilities" color="primary"/>} label="Do you have cooking facilities?" />
      </div>
      {state.hasCookingFacilities &&
        <>
          <TextField multiline rows={5} fullWidth variant="outlined" margin="normal" placeholder={"Please provide details of the cooking facilities that you have."} helperText={"eg. 4 plate gas cooker"}/>
          <UploadFiles title={"Cooking Facilities Pictures"} />
      </>
      }
   </>
  )
}

export function BeneficiaryReferences({formik_props}) {
  return(
    <>
      <p>Please upload at least 2 supporting references</p>
      <p>The references should include:
        <ul>
          <li>Name of Organisation Providing Reference</li>
          <li>Name of Person at Organisation Providing Reference</li>
          <li>Email of reference</li>
          <li>Telephone number of reference</li>
        </ul>
      </p>
      <UploadFiles title={'References'} />
      <p><i>or</i></p>
      <TextField multiline rows={5} placeholder={"Provide the requested information here..."} fullWidth variant="outlined"/>
    </>
  )
}
