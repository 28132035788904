import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, Link } from 'react-router-dom'
import { head, get, filter, capitalize, replace } from 'lodash'
import moment from "moment"
import NumberFormat from 'react-number-format'
import { adminParcelList } from './actions/admin_parcel'
import { CommonTable, CommonPaperTable } from 'components/CommonTable'
import { ParcelStatus } from 'components/orders/ParcelStatus'
import { ShortId } from 'components/ShortId'
import { Hidden, makeStyles, withStyles, Tooltip, Typography, Button, Badge } from '@material-ui/core'
import { StatusIndicator } from 'components/layout/StatusIndicator'
import Icon from '@mdi/react'
import { mdiWarehouse, mdiTruckCheckOutline, mdiTruckFastOutline, mdiTruckDeliveryOutline } from '@mdi/js'
import Timestamp from 'components/Timestamp'
import { GeneratedReportDownload } from '../reports/AdminDownloadReport'
import { AdminParcelStateDropDown } from './AdminParcelStateDropDown'

const useStyles = makeStyles( ( theme ) => ( {
	companyName: {
		fontWeight: 500,
		[ theme.breakpoints.down( 'sm' ) ]: {
			fontSize: 16
		}
	},
	capitalize: {
		textTransform: 'capitalize'
	},
} ) )

const FILTERS = [
	{ name: 'cities' },
	{ name: 'date_range' },
	{ name: 'parcel_states' },
	{ name: 'parcel_company_types' }
]

export default function AdminParcelsTable( { parcelItemList, is_today = false, showFilters = true, showViewSelect = false, noPaper = false, company, inTabs, contains_products_donated_by } ) {
	const classes = useStyles()
	const dispatch = useDispatch()
	const history = useHistory()
	const inTabsStyle = inTabs ? classes.inTabs : classes.noTabs
	const not_set = <StatusIndicator status="error" tooltip="Not set" />
	parcelItemList = parcelItemList || adminParcelList
	const parcels = useSelector( () => parcelItemList.getVisibleObjects() )
	moment.updateLocale( moment.locale(), { invalidDate: <Typography variant="caption" color="secondary">Not set</Typography> } )
	let today = moment()
	let tomorrow = moment().add( 1, 'days' )
	let yesterday = moment().add( -1, 'days' )
	let todayTomorrowYesterday = ''
	let warehouse_column_prefix = ""
	let customer_variation = "Customer"
	const dateFormat = "dddd, Do MMMM YYYY"
	const parcel_filter = useSelector( () => parcelItemList.getFilter() )
	if ( parcel_filter.direction == ( 'beneficiary_dropoff' || 'beneficiary_collection' ) ) { customer_variation = "Beneficiary" }
	if ( parcel_filter.direction == ( 'donor_pickup' || 'donor_delivery' ) ) { customer_variation = "Donor" }
	const is_loading = useSelector( () => parcelItemList.isLoading() )
	const [ editStatusInline, setEditStatusInline ] = useState( true )

	const item_not_set = ( the_item ) => {
		return <Typography style={ { color: 'red' } }>{ the_item } is NOT set<br /></Typography>
	}

	useEffect( () => {
		dispatch( parcelItemList.updateListFilter( {
			companies: company,
			contains_products_donated_by: contains_products_donated_by,
		} ) )
		if ( is_today ) {
			dispatch( parcelItemList.updateListFilter( { scheduled_at: today } ) )
		}
		async function fetchStaticObjects() {
			dispatch( parcelItemList.fetchListIfNeeded() )
		}
		fetchStaticObjects()
	}, [] )

	useEffect( () => {
		dispatch( parcelItemList.updateListFilter( { companies: company } ) )
		dispatch( parcelItemList.fetchListIfNeeded() )
	}, [ company ] )

	const isTodayTomorrowYesterday = ( theDate ) => {

		if ( moment( theDate ).isSame( today, 'day' ) ) {
			todayTomorrowYesterday = 'Today'
			return true
		}
		else if ( moment( theDate ).isSame( tomorrow, 'day' ) ) {
			todayTomorrowYesterday = 'Tomorrow'
			return true
		}
		else if ( moment( theDate ).isSame( yesterday, 'day' ) ) {
			todayTomorrowYesterday = 'Yesterday'
			return true
		}
		else {
			return false
		}
	}

	const getOrderCompanyName = ( item, mobile ) => {
		return (
			<>
				<span className={ classes.companyName }>
					{ item.direction === ( 'donor_pickup' || 'donor_delivery' ) && item.from_company_name }
					{ item.direction === ( 'beneficiary_dropoff' || 'beneficiary_collection' ) && item.to_company_name }
				</span>
			</>
		)
	}

	const getOrderDirection = ( item, text ) => {

		switch ( item.direction ) {

			case 'donor_pickup':

				if ( item.transport_by_warehouse_company === true ) {
					if ( text === true ) {

						return ( "Donor Pickup" )
					}
					else {
						return (
							<Tooltip title={ "Donor Pickup" } arrow>
								<Icon size={ 0.9 } horizontal path={ mdiTruckDeliveryOutline } color={ '#999999' } className={ classes.orderTypeIcon } />
							</Tooltip>
						)
					}
				}
				else {
					if ( text ) {
						return "Donor Delivery"
					}
					else {
						return (
							<Tooltip title={ "Donor Delivery" } arrow>
								<Icon size={ 0.9 } horizontal path={ mdiWarehouse } color={ '#999999' } className={ classes.orderTypeIcon } />
							</Tooltip>
						)
					}
				}
				break

			case 'beneficiary_dropoff':
				if ( item.transport_by_warehouse_company === true ) {
					if ( text ) {
						return "Beneficiary Dropoff"
					}
					else {
						return (
							<Tooltip title={ "Beneficiary Dropoff" } arrow>
								<Icon size={ 0.9 } horizontal path={ mdiTruckFastOutline } color={ '#999999' } className={ classes.orderTypeIcon } />
							</Tooltip>
						)
					}
				}
				else {
					if ( text ) {
						return "Beneficiary Collection"
					}
					else {
						return (
							<Tooltip title={ "Beneficiary Collection" } arrow>
								<Icon size={ 0.9 } horizontal path={ mdiWarehouse } color={ '#999999' } className={ classes.orderTypeIcon } />
							</Tooltip>
						)
					}
				}
				break

		}
	}

	const getReadableOrderDirection = ( direction ) => {
		switch ( direction ) {
			case 'donor_pickup':
				return "Donor Pickup"
				break
			case 'donor_delivery':
				return "Donor Delivery"
				break
			case 'beneficiary_dropoff':
				return "Beneficiary Dropoff"
				break
			case 'beneficiary_collection':
				return "Beneficiary Collection"
				break
		}
	}

	const getOrderScheduled = ( item ) => {
		return (
			<>
				{ item.direction === 'donor_pickup' && <Timestamp value={ item.depart_at } /> }
				{ item.direction === 'beneficiary_dropoff' && <Timestamp value={ item.arrive_at } /> }
			</>
		)
	}

	const getCompanyType = ( direction, noTag = false ) => {
		let companyType = direction == 'beneficiary_dropoff' ? 'beneficiary' : 'donor'
		if ( noTag ) { return companyType }
		else { return <span>{ capitalize( companyType ) }</span> }
	}

	const getOrderID = ( item ) => {
		return (
			<Link to={ `/admin/orders/${ getCompanyType( item.direction, true ) }/${ item.id }` }>{ item.short_ref.toUpperCase() }</Link>
		)
	}

	const generatedReportDownload = ( report_id ) => {
		return (
			<GeneratedReportDownload generated_report_id={ report_id } />
		)
	}

	const getDriverNameIfNeeded = ( item ) => {
		if ( item.direction === 'beneficiary_dropoff' && item.transport_by_warehouse_company ) {
			var driver = get( item, [ "driver_name" ] ) ? get( item, [ "driver_name" ] ) : not_set
			return driver
		}
		if ( item.direction === 'donor_pickup' && item.transport_by_warehouse_company ) {
			var driver = get( item, [ "driver_name" ] ) ? get( item, [ "driver_name" ] ) : not_set
			return driver
		}
	}

	const onStatusChanged = ( status_changed ) => {
		if ( status_changed ) {
			dispatch( parcelItemList.invalidateList() )
		}
	}

	const getWarehouse = ( item ) => {
		let direction = item.direction == 'beneficiary_dropoff' ? 'beneficiary' : 'donor'
		return direction == 'beneficiary' ? replace( item.depart_warehouse_name, ' Warehouse', '' ) : replace( item.arrive_warehouse_name, ' Warehouse', '' )
	}

	const getOrderStatus = ( item ) => {
		return (
			<>
				{ item.status != 'complete' && item.status != 'order_cancelled' && editStatusInline ?
					<AdminParcelStateDropDown parcel_id={ item.id } initial_state={ item.status } onStatusChanged={ onStatusChanged } /> :
					<ParcelStatus status={ item.status } />
				}
			</>
		)
	}

	const columnsDesktop = [
		{
			field: 'short_ref',
			title: 'Ref',
			width: 115,
			visible: true,
			render: ( item ) => getOrderID( item ),
			sort: ( direction ) => onUpdateListOrdering( direction === 'asc' ? 'short_ref' : '-short_ref' ),
		},
		{
			field: 'warehouse',
			title: 'Region',
			width: 115,
			visible: true,
			render: ( item ) => getWarehouse( item ),
		},
		{
			field: 'scheduled_at',
			title: 'Scheduled',
			render: ( item ) => getOrderScheduled( item ),
			visible: true,
			sort: ( direction ) => onUpdateListOrdering( direction === 'asc' ? 'scheduled_at' : '-scheduled_at' ),
		},
		{
			field: 'direction',
			title: 'Type',
			visible: true,
			width: 180,
			render: ( item ) => getOrderDirection( item, true ),
			sort: ( direction ) => onUpdateListOrdering( direction === 'asc' ? 'direction' : '-direction' ),
		},
		{
			field: 'company',
			title: 'Company',
			render: ( item ) => getOrderCompanyName( item ),
			visible: true,
			sort: function( direction ) {
				if ( parcel_filter.direction === 'beneficiary_dropoff' ) {
					onUpdateListOrdering( direction === 'asc' ? 'to_company__name' : '-to_company__name' )
				} else {
					onUpdateListOrdering( direction === 'asc' ? 'from_company__name' : '-from_company__name' )
				}
			}
		},
		{
			field: 'total_value_rands',
			title: 'Rand Value',
			align: 'right',
			visible: true,
			render: ( item ) => get( item, [ "total_value_rands" ] ) > 0 ? <NumberFormat value={ get( item, [ "total_value_rands" ] ) } prefix={ "R" } displayType={ 'text' } thousandSeparator={ true } decimalScale={ 2 } /> : not_set,
			sort: ( direction ) => onUpdateListOrdering( direction === 'asc' ? 'total_value_rands' : '-total_value_rands' ),
		},
		{
			field: 'driver_name',
			title: 'Driver',
			visible: true,
			render: ( item ) => getDriverNameIfNeeded( item ),
			sort: ( direction ) => onUpdateListOrdering( direction === 'asc' ? 'driver_name' : '-driver_name' ),
		},
		{
			field: 'bringg_ref',
			title: 'Bringg',
			align: 'center',
			render: ( item ) => item.transport_by_warehouse_company ? <StatusIndicator status={ item.bringg_ref ? "success" : "error" } /> : ""
		},
		{
			field: 'delivery_notes',
			title: 'Delivery Note',
			visible: true,
			align: 'center',
			render: ( item ) => <StatusIndicator status={ get( item, [ 'delivery_notes', 'files', 0 ] ) ? "success" : "error" } />,
			sort: ( direction ) => onUpdateListOrdering( direction === 'asc' ? 'status' : '-status' ),
		},
		{
			field: 'nutrition_score',
			title: 'Nutrition Score',
			visible: true,
			align: 'center',
			render: ( item ) => get( item, [ 'nutrition_score' ] ),
			sort: ( direction ) => onUpdateListOrdering( direction === 'asc' ? 'nutrition_score' : '-nutrition_score' ),
		},
		{
			field: 'total_weight_kg',
			title: 'Weight',
			align: 'right',
			visible: true,
			render: ( item ) => get( item, [ "total_weight_kg" ] ) > 0 ? <Typography style={ { fontSize: 18 } }><NumberFormat value={ get( item, [ "total_weight_kg" ] ) } suffix={ "kg" } displayType={ 'text' } thousandSeparator={ true } decimalScale={ 2 } /></Typography> : not_set,
			sort: ( direction ) => onUpdateListOrdering( direction === 'asc' ? 'total_weight_kg' : '-total_weight_kg' ),
		},
		{
			field: 'status',
			title: 'Status',
			visible: true,
			render: ( item ) => getOrderStatus( item ),
			sort: ( direction ) => onUpdateListOrdering( direction === 'asc' ? 'status' : '-status' ),
		}
	]

	const columnsMobile = [

		{
			field: 'company',
			title: customer_variation,
			render: ( item ) => <>{ getOrderCompanyName( item, true ) }<br /></>,
		},
		{
			field: 'scheduled_at',
			title: 'Scheduled',
			render: ( item ) => <>
				<Typography variant="h6" style={ { marginBottom: -20 } }>
					{ getOrderScheduled( item ) }
				</Typography>
				<br />
			</>,
		},
		{
			field: 'direction',
			title: 'Order Type',
			render: ( item ) => <Typography style={ { color: '#777', fontWeight: 500 } }>{ getReadableOrderDirection( item.direction ) }</Typography>,
			sort: ( direction ) => onUpdateListOrdering( direction === 'asc' ? 'scheduled_at' : '-scheduled_at' ),
		},
		{
			field: 'driver_name',
			title: 'Driver',
			render: ( item ) => get( item, [ "driver" ] ) ? <><Typography variant="caption">Driver: { get( item, [ "driver_name" ] ) }</Typography><br /></> : item_not_set( "Driver" ),
			sort: ( direction ) => onUpdateListOrdering( direction === 'asc' ? 'driver_name' : '-driver_name' ),
		},
		{
			field: 'total_weight_kg',
			title: 'Weight',
			render: ( item ) => get( item, [ "total_weight_kg" ] ) > 0 ? <Typography>Weight: <NumberFormat value={ get( item, [ "total_weight_kg" ] ) } suffix={ "kg" } displayType={ 'text' } thousandSeparator={ true } decimalScale={ 2 } /><br /></Typography> : item_not_set( "Weight" ),
		},
		{
			field: 'delivery_notes',
			title: 'Delivery Note',
			visible: true,
			render: ( item ) => get( item, [ 'delivery_notes', 0 ] ) ? <>Delivery Note Uploaded<br /></> : item_not_set( "Delivery Note" ),
			sort: ( direction ) => onUpdateListOrdering( direction === 'asc' ? 'status' : '-status' ),
		},
		{
			field: 'status',
			title: 'Status',
			visible: true,
			render: ( item ) => <div style={ { marginTop: 10, marginBottom: 5 } }><ParcelStatus status={ item.status } /></div>,
			sort: ( direction ) => onUpdateListOrdering( direction === 'asc' ? 'status' : '-status' ),
		},
	]

	const onEditParcel = ( parcel_id ) => {
		const parcel = head( filter( parcels, ( parcel ) => parcel.id === parcel_id ) )

		if ( parcel.direction === 'donor_pickup' ) {
			history.push( { pathname: '/admin/orders/donor/' + parcel_id } )
		} else if ( parcel.direction === 'beneficiary_dropoff' ) {
			history.push( { pathname: '/admin/orders/beneficiary/' + parcel_id } )
		} else {
			history.push( { pathname: '/admin/parcel/' + parcel_id } )
		}
	}

	const onUpdateListOrdering = ( field ) => {
		dispatch( parcelItemList.updateListOrdering( field ) )
	}

	return (
		<>
			<Hidden smDown>
				<CommonTable
					is_loading={ is_loading }
					rows={ parcels }
					columns={ columnsDesktop }
					item_list={ parcelItemList }
					onEditRow={ onEditParcel }
					showFilters={ showFilters }
					showViewSelect={ showViewSelect }
					filters={ FILTERS }
					useColumnsSelector={ true }
					storeTableName={ "parcelItemList" }
				/>
			</Hidden>
			<Hidden mdUp>
				<CommonTable
					is_loading={ is_loading }
					rows={ parcels }
					columns={ columnsMobile }
					item_list={ parcelItemList }
					onEditRow={ onEditParcel }
					showFilters={ showFilters }
					filters={ FILTERS }
					showViewSelect={ showViewSelect }
					mobile={ true }
					variant={ "outlined" }
					square={ true }
				/>
			</Hidden>
		</>
	)
}
