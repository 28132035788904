import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { capitalize, get, map } from 'lodash'
import clsx from 'clsx'
import { makeStyles, Grid, Typography, IconButton, Button, Tooltip, Card, CardContent, Collapse } from '@material-ui/core'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import Timestamp from 'components/Timestamp'
import FavoriteIcon from '@material-ui/icons/Favorite'
import VisibilityIcon from '@material-ui/icons/Visibility'
import ReceiptIcon from '@material-ui/icons/Receipt'
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos'
import { Link } from 'react-router-dom'
import NumberFormat from 'react-number-format'
import Switch from '@material-ui/core/Switch'
import { getActiveCompanyId } from 'actions/active_company'

const useStyles = makeStyles((theme) => ({
    root: {
      paddingLeft: 15,
      paddingRight: 15
    },
    widgetSwitch: {
      position: 'absolute',
      top: 150,
      right: 20,
    },
    cardRoot: {
        //boxShadow: '0 0.2rem 0.5rem rgb(33 78 130 / 20%) !important',
        minHeight: 130,
        position: 'relative',
        [theme.breakpoints.down('sm')]: {
            minHeight: 120,
        },
    },
    redBorder: {
        borderLeft: '4px solid red'
    },
    redKPI: {
        color: 'red'
    },
    yellowBorder: {
        borderLeft: '4px solid #fbdf06'
    },
    yellowKPI: {
        color: '#fbdf06'
    },
    widgetKpi: {
        marginTop: 5,
        marginBottom: -15,
        fontWeight: 500,
        fontSize: 24,
        textAlign: 'center',
        [theme.breakpoints.down('sm')]: {
            fontSize: 18,
            marginTop: 10,
        },
    },
    iconsBottomRight: {
        [theme.breakpoints.down('sm')]: {
            marginTop: -5,
            marginBottom: -10,
        },
    },
    expandMoreIcon: {
        [theme.breakpoints.down('sm')]: {
            marginBottom: -15,
            marginTop: -5

        },
    },
    expand: {
      transform: 'rotate(0deg)',
      marginLeft: 'auto',
      transition: theme.transitions.create('transform', {
        duration: theme.transitions.duration.shortest,
      }),
    },
    expandOpen: {
      transform: 'rotate(180deg)',
    },
    title: {
      fontWeight: 500,
      color: '#637382',
      [theme.breakpoints.down('sm')]: {
          fontSize: 14,
      },
    },
    iconButton: {
      marginTop: -30,
      [theme.breakpoints.down('sm')]: {
          padding: 0,
          paddingLeft: 8,
          paddingRight: 8
      },
    },
    viewMoreButton: {
      fontWeight: 400,
      fontSize: 10,
      textTransform: 'capitalize'
    },
    expandIcon: {
      position: 'absolute',
      top: 0,
      right: 0
    }
}))

export default function MoneyDonationWidgets({hideRedirect, donationsPageClass, hide, isMoneyDonationsPage, data}) {
    const classes = useStyles()
    const dispatch = useDispatch()
    let initialWidgetState = true
    if(hide) {initialWidgetState = false}
    const [showWidgets, setShowWidgets] = React.useState(initialWidgetState)
    const company_id = getActiveCompanyId()
    const summary_all_days_back = get(data, ['report', 'summary_all_days_back'])
    const summary_30_days_back = get(data, ['report', 'summary_30_days_back'])
    const summary_7_days_back = get(data, ['report', 'summary_7_days_back'])
    const [expanded, setExpanded] = React.useState(false)

    const handleShowWidgets = (event) => {
        setShowWidgets(event.target.checked)
    }

    const handleExpandClick = () => {
        setExpanded(!expanded)
    }


    const widget = ({title, date, kpi, collapsedContent, actions, color}) => {
        return(
            <Card className={classes.cardRoot} variant="outlined">
              <CardContent>
                <Typography className={classes.title} align="center">{title}</Typography>
                {date &&
                 <Typography style={{color: color}} className={[classes.widgetKpi]}>{date}</Typography>
                }
                {kpi &&
                 <Typography className={classes.widgetKpi} style={{color: color}}>{kpi}</Typography>
                }

              </CardContent>
            </Card>
        )
    }

    const summary_days_back = (kpi, days, type) => {
        type = type == 'count' ? type : 'rand'
        let title = type == 'count' ? "Unique Donations" : "Money Raised"
        let color
        kpi = kpi ? kpi.toLocaleString() : kpi
        kpi = kpi ? kpi : "0"
        kpi = type == 'count' ? kpi : 'R' + kpi
        switch (days) {
            case 7:
                title = title + " Last 7 Days "
                color = type == 'count' ? "#e91e63" : "#43a047"
                break
            case 30:
                title =  title + " Last 30 Days "
                color = type == 'count' ? "#e91e63" : "#43a047"
                break
            case 'all':
                title = "Total " + title
                color = type == 'count' ? "#e91e63" : "#43a047"
                break
        }
        return(
            widget({title, kpi, color})
        )
    }

    return (
        <div className={classes.root}>
          <Grid container justify="flex-end" className={classes.widgetSwitch}>
              <Switch onChange={handleShowWidgets} checked={showWidgets} name="showWidgets" size="small" color="primary"/> {showWidgets ? "Hide" : "Show"} widgets
          </Grid>

          <Collapse in={showWidgets}>

            <Grid container spacing={2}>

              <Grid item xs={6} md={4} lg={2}>
                {summary_days_back(get(data, ['report', 'summary_all_days_back', "num_completed"]), 'all', 'count')}
              </Grid>

              <Grid item xs={6} md={4} lg={2}>
                {summary_days_back(get(data, ['report', 'summary_all_days_back', "completed_total_rands"]), 'all', 'rand')}
              </Grid>

              <Grid item xs={6} md={4} lg={2}>
                {summary_days_back(get(data, ['report', 'summary_7_days_back', "num_completed"]), 7, 'count')}
              </Grid>

              <Grid item xs={6} md={4} lg={2}>
                {summary_days_back(get(data, ['report', 'summary_7_days_back', "completed_total_rands"]), 7, 'rand')}
              </Grid>

              <Grid item xs={6} md={4} lg={2}>
                {summary_days_back(get(data, ['report', 'summary_30_days_back', "num_completed"]), 30, 'count')}
              </Grid>

              <Grid item xs={6} md={4} lg={2}>
                {summary_days_back(get(data, ['report', 'summary_30_days_back', "completed_total_rands"]), 30,  'rand')}
              </Grid>

            </Grid>

          </Collapse>
        </div>
    )

}
