import React, {Component, useState} from 'react'
import {connect} from 'react-redux'
import { get, capitalize } from 'lodash'
import { MuiFileUploader } from 'components/form/MuiFileUploader'
import { Typography, Button, IconButton, Tooltip, Table, TableRow, TableCell } from '@material-ui/core'
import DeleteIcon from '@material-ui/icons/Delete'
import SwapHorizontalCircleOutlinedIcon from '@material-ui/icons/SwapHorizontalCircleOutlined'
import CloudUploadOutlinedIcon from '@material-ui/icons/CloudUploadOutlined'

export const SingleFileUploadField = ({name, acceptFileType, acceptedFiles, dropzonePlaceholder, buttonPlaceholder, formik_props, ...props }) => {

    const [dropzoneVisible, setDropzoneVisible] = useState(false)

    const file_info = get(formik_props.values, name)
    if(!acceptedFiles) {
      if(acceptFileType) {
        if(acceptFileType == 'image') {
          acceptedFiles = "image/jpeg,image/png,image/gif"
        }
        else if(acceptFileType == 'spreadsheet') {
          acceptedFiles =  ".csv, .xlsx, .xls, text/csv, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/csv, text/x-csv, application/x-csv, text/comma-separated-values, text/x-comma-separated-values"
        }
      }
    }
    else {
      acceptedFiles =  ".csv, .xlsx, .xls, text/csv, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/csv, text/x-csv, application/x-csv, text/comma-separated-values, text/x-comma-separated-values"
    }

    acceptedFiles = [acceptedFiles]

    const onDelete = () => {
        formik_props.setFieldValue(name, null)
    }

    const onUploaded = () => {
        setDropzoneVisible(false)
    }

    return (
        <div>
          { file_info &&
            <Table>
              <TableRow>
                <TableCell>{capitalize(buttonPlaceholder)}</TableCell>
                <TableCell>
                  <a href={file_info.original_download_url}>{file_info.visible_filename}</a>
                </TableCell>
                <TableCell width={150}>

                  { ! dropzoneVisible && get(file_info, "id") &&
                    <IconButton variant="outlined" onClick={() => setDropzoneVisible(true)} color="secondary">
                      <Tooltip arrow title={`Change ${buttonPlaceholder}`}><SwapHorizontalCircleOutlinedIcon /></Tooltip>
                    </IconButton>
                  }

                  <Tooltip arrow title="Delete File">
                    <IconButton variant="contained" size="large" onClick={onDelete} color="secondary">
                      <DeleteIcon />
                    </IconButton>
                  </Tooltip>

                </TableCell>
              </TableRow>
            </Table>
          }

          { ! dropzoneVisible && !get(file_info, "id") &&
            <Button variant="outlined" onClick={() => setDropzoneVisible(true)} color="primary">
              <CloudUploadOutlinedIcon fontSize="large" style={{marginRight: 10}} /> Upload {buttonPlaceholder}
            </Button>
          }

          { dropzoneVisible &&
            <>
              <MuiFileUploader name={name}
                               maxNumFiles={1}
                               acceptedFiles={acceptedFiles}
                               maxFileSize={5000000}
                               dropzoneText = {<Typography><p>{dropzonePlaceholder}</p></Typography>}
                               onFilesUploaded={onUploaded}
                               formik_props={formik_props}
              />
            <Button style={{marginTop: 10}} onClick={() => setDropzoneVisible(false)}>
                Cancel
              </Button>
            </>
          }
        </div>
    )

}
