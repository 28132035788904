import { ItemList } from 'orm'
import { map, head, values } from 'lodash'

class ContactDashboardList extends ItemList {
    getEntityKey() {
        return "contact/dashboard"
    }

    getForCompany() {
        return head(values(this.getAllObjects()))
    }

}

export const contactDashboardList = new ContactDashboardList("contact_dashboard__default")
