import { ItemList } from 'orm'
import { map } from 'lodash'

class NutrientList extends ItemList {
    getEntityKey() {
        return "admin/nutrient"
    }

    getAsOptions() {
        const items = this.getVisibleObjects()
        return map(items, function(item) {
          return { label: item.name, value: item.id }
        }
      )
    }
}

export const adminNutrientList = new NutrientList("admin_nutrient__default")
export const adminNutrientAutoCompleteList = new NutrientList("admin_nutrient_auto_complete__default")
