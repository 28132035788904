import { ItemList } from 'orm'
import { values, get, map, head, filter } from 'lodash'

class MarketingMoneyDonationReport extends ItemList {

    getEntityKey() {
        return "marketing/money_donation_report"
    }

    loadData() {
        return this.fetchListIfNeeded()
    }

    getData() {
        return head(this.getVisibleObjects())
    }

    getEnhancedFilter() {
        if ( ! this.enhanced_filter ) {
            this.enhanced_filter = new EnhancedFilter(this)
        }
        return this.enhanced_filter
    }

}

export const marketingMoneyDonationReport = new MarketingMoneyDonationReport("marketing_money_donation_report__default")
