import React, { useEffect, useState } from 'react'
import { capitalize, includes, get, map, filter, values, forEach } from 'lodash'
import { useDispatch, useSelector } from 'react-redux'
import { useField } from 'formik'
import AdminTableFilter from 'admin/components/common/AdminTableFilter'
import { Modal } from 'components/layout/Modal'
import { adminProductCategoryList } from '../actions/admin_product_category'
import ProductCategoryInlineForm from './ProductCategoryInlineForm'
import TabularTree from 'components/form/TabularTree'
import { makeStyles, Button, FormHelperText, Typography, LinearProgress } from '@material-ui/core'
import { HierarchyCrumbs } from 'components/layout/HierarchyCrumbs'
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown'
import SearchBar from 'components/layout/SearchBar'
import CloseIcon from '@material-ui/icons/Close'
import Loading from 'components/Loading'

const useStyles = makeStyles( ( muiTheme ) => ( {
	modalContainer: {
		margin: "20px"
	},
	labelRoot: {
		fontSize: 24,
		paddingLeft: 15
	},
	labelShinked: {
		fontSize: 16,
		marginLeft: 5
	},
	label: {
		color: '#7a7a7a',
		fontSize: 12
	},
	clearIcon: {
		fontSize: 16,
		cursor: 'pointer',
		position: 'absolute',
		top: 0,
		right: 0,
		color: '#000',
		"&:hover": {
			color: 'red'
		}
	}
} ) )

export const ProductCategorySelectField = ( { label, empty_label, can_add, can_edit, onClearFilter, onSelected, size, fullWidth, product_category_id, formik_props, ...props } ) => {
	const [ field, meta, helpers ] = useField( props )
	const classes = useStyles()
	const dispatch = useDispatch()
	const [ productCategorySelectorOpen, setProductCategorySelectorOpen ] = useState( false )
	const [ expandedIds, setExpandedIds ] = useState( [] )
	const [ adding_child_of_product_category, setAddingChildOfProductCategory ] = useState( false )
	const [ editing_product_category, setEditingProductCategory ] = useState( null )
	const is_loading = useSelector( () => adminProductCategoryList.isLoading() )
	const touched = get( meta, "touched" )
	const errors = get( meta, "error" )
	const name = field.name
	const all_product_categories_by_id = useSelector( () => adminProductCategoryList.getFlattenedObjectsById() )
	const product_category_list_filter = adminProductCategoryList.getFilter()
	const padding = fullWidth ? '15px' : '8px 15px 6px 15px'
	const product_categories_as_tree = adminProductCategoryList.getVisibleObjectsAsTree()

	useEffect( () => {
		async function fetchStaticObjects() {
			dispatch( adminProductCategoryList.updatePaginationNumItemsPerPage( 1000 ) )
			dispatch( adminProductCategoryList.fetchListIfNeeded() )
		}
		fetchStaticObjects()
	}, [] )

	useEffect( () => {
		if ( product_category_id ) {
			helpers.setValue( product_category_id )
		}
	}, [ product_category_id ] )

	const onSelectItem = ( product_category ) => {
		helpers.setValue( get( product_category, "id" ) )
		onSelected && onSelected( product_category, "name" ) // we pass the label back
		setProductCategorySelectorOpen( false )
	}

	const selected_product_category = adminProductCategoryList.getObject( get( formik_props, [ "values", field.name ], null ) )

	const filterItem = ( item ) => {
		return includes( capitalize( item.name ), capitalize( product_category_list_filter.any_field ) )
	}

	const onFilterChanged = ( filter_values ) => {
		dispatch( adminProductCategoryList.updateListFilter( filter_values ) )
		dispatch( adminProductCategoryList.invalidateList() )
		dispatch( adminProductCategoryList.fetchListIfNeeded() )
		updateExpandedIds( filter_values )
	}

	const renderFilter = ( formik_props ) => {
		return (
			<SearchBar formik_props={ formik_props } />
		)
	}

	const updateExpandedIds = ( filter_values ) => {
		const newExpandedIds = []
		const recursivelyExpandParent = ( item ) => {
			newExpandedIds.push( item.id )
			if ( item.parent ) {
				recursivelyExpandParent( all_product_categories_by_id[ item.parent ] )
			}
		}
		const filtered_items = filter( values( all_product_categories_by_id ), function( x ) {
			return filterItem( x )
		} )
		forEach( filtered_items, ( x ) => recursivelyExpandParent( x ) )
		setExpandedIds( newExpandedIds )
	}

	const onToggle = ( evt, item_ids ) => {
		setExpandedIds( item_ids )
	}

	const onQuickAddTreeItem = ( { parent_item } ) => {
		setAddingChildOfProductCategory( parent_item )
	}

	const onDoneQuickAddTreeItem = () => {
		setAddingChildOfProductCategory( false )
		dispatch( adminProductCategoryList.invalidateList() )
		dispatch( adminProductCategoryList.fetchListIfNeeded() )
	}

	const onQuickEditTreeItem = ( { item } ) => {
		setEditingProductCategory( item )
	}

	const onDoneQuickEditTreeItem = () => {
		setEditingProductCategory( null )
		dispatch( adminProductCategoryList.invalidateList() )
		dispatch( adminProductCategoryList.fetchListIfNeeded() )
	}

	const renderQuickAddTreeItemForm = () => {
		return (
			<Modal
				onClose={ onDoneQuickAddTreeItem }
				title={ adding_child_of_product_category ? `Add new child of ${ get( adding_child_of_product_category, 'name' ) }` : `Add a new category` }
				noPadding
			>
				<ProductCategoryInlineForm
					product_category={ null }
					adding_child_of_parent_product_category={ adding_child_of_product_category }
					onSaved={ onDoneQuickAddTreeItem }
					onCancel={ onDoneQuickAddTreeItem }
				/>
			</Modal>
		)
	}

	const renderQuickEditTreeItemForm = () => {
		return (
			<Modal
				onClose={ onDoneQuickEditTreeItem }
				title={ `Edit ${ get( editing_product_category, 'name' ) }` }
				noPadding
			>
				<ProductCategoryInlineForm
					product_category={ editing_product_category }
					onSaved={ onDoneQuickEditTreeItem }
					onCancel={ onDoneQuickEditTreeItem }
				/>
			</Modal>
		)
	}

	const onClearFilterClick = () => {
		formik_props.setFieldValue( field.name, null )
	}

	const renderClosed = () => {
		const crumbs = get( selected_product_category, "crumbs" )
		const divFloat = empty_label ? "none" : "none"
		const divMarginTop = empty_label ? 0 : 0
		const divMarginBottom = empty_label ? 12 : 0
		const errors = formik_props.errors.product_category

		return (
			<div style={ { paddingTop: 15, marginBottom: divMarginBottom, marginTop: divMarginTop, float: divFloat, position: 'relative' } }>
				<Button
					variant="outlined"
					size={ size }
					onClick={ () => setProductCategorySelectorOpen( true ) }
					fullWidth={ fullWidth }
					style={ {
						padding: padding,
						justifyContent: "flex-start",
						minWidth: '100%',
						borderColor: errors ? 'red' : ''
					} }
				>
					{ is_loading ? <Typography variant="caption">Loading...</Typography> :
						<HierarchyCrumbs
							crumbs={ crumbs }
							empty_label={
								<Typography style={ { textTransform: 'capitalize', color: errors ? 'red' : '#757575' } }>
									{ empty_label || <>{ label }</> }
								</Typography>
							}
						/>
					}
					<ArrowDropDownIcon style={ { marginLeft: 'auto' } } />
				</Button>
				{ is_loading && <LinearProgress style={ { height: 1 } } /> }
				{ crumbs && <CloseIcon onClick={ onClearFilterClick } className={ classes.clearIcon } /> }
				{ formik_props.isSubmitting && errors && <FormHelperText style={ { color: 'red', marginLeft: 15 } }>Required</FormHelperText> }
			</div>
		)
	}

	const renderOpen = () => {
		return (
			<Modal
				onClose={ () => setProductCategorySelectorOpen( false ) }
				fullWidth={ true }
				title="Filter by product category"
				noPadding
			>
				<div className={ classes.modalContainer }>
					<>
						<div style={ { margin: -20, marginTop: -30, marginBottom: 10, borderBottom: '1px solid #EFEFEF' } }>
							<AdminTableFilter
								label=""
								placeholder="Search"
								renderFilter={ renderFilter }
								updateOnChange={ onFilterChanged }
							/>
						</div>
						{ is_loading && <LinearProgress style={ { height: 1 } } /> }
						<TabularTree
							root_nodes={ product_categories_as_tree }
							onSelectItem={ onSelectItem }
							onAddItem={ can_add && onQuickAddTreeItem }
							onEditItem={ can_edit && onQuickEditTreeItem }
							forceExpandedIds={ expandedIds }
							filterFunc={ filterItem }
							onNodeToggle={ onToggle }
							label_field_name="name"
						/>
					</>
				</div>
			</Modal>
		)
	}

	return (
		<>
			{ !adding_child_of_product_category && !editing_product_category && renderClosed() }
			{ productCategorySelectorOpen && renderOpen() }
			{ adding_child_of_product_category !== false && renderQuickAddTreeItemForm() }
			{ editing_product_category && renderQuickEditTreeItemForm() }
		</>
	)
}