import React, { Component } from 'react'
import { connect } from 'react-redux'
import { head, get } from 'lodash'
import { Trans, Translation } from 'react-i18next'
import { FormikDropdownField } from 'components/form/Dropdown'
import AdminCommonListLayout from '../layout/AdminCommonListLayout'
import Timestamp from 'components/Timestamp'
import { FormikInputField } from 'components/form/InputField'
import { adminTagList } from './actions/admin_tag'
import { Separator } from 'components/layout/Separator'
import { ShortId } from 'components/ShortId'
import { Button } from 'components/layout/Button'
import { provinceList } from 'actions/province'
import CheckCircleIcon from '@material-ui/icons/CheckCircle'
import CancelIcon from '@material-ui/icons/Cancel'

class Tags extends Component {

    componentDidMount() {
        const { dispatch } = this.props
        dispatch(adminTagList.fetchListIfNeeded())
        dispatch(provinceList.updatePaginationNumItemsPerPage(1000))
        dispatch(provinceList.fetchListIfNeeded())
    }

    componentDidUpdate() {
        const { dispatch, filter } = this.props
        dispatch(adminTagList.fetchListIfNeeded())
        dispatch(provinceList.fetchListIfNeeded())
    }

    onEditTag = (tag_id) => {
        const { history } = this.props
        history.push({
            pathname: '/admin/tag/'+tag_id
        })
    }

    onAddTag = () => {
        const { history } = this.props
        history.push('/admin/tag')
    }

    onUpdateListOrdering = (field) => {
      const { dispatch } = this.props
      dispatch(adminTagList.updateListOrdering(field))
    }

    render() {
        const { is_loading, tags } = this.props
        const that = this

        const columns = [
            { field: 'name', title: 'Tag',
            },
            { field: 'description', title: 'Description',
            },
            { field: 'published', title: 'Published',
              render: (item) => item.published ? <CheckCircleIcon style={{color: 'green'}} /> : <CancelIcon style={{color: 'red'}} />
            },
        ]

        return (
            <AdminCommonListLayout active_key="tags"
                                   breadcrumbs={[{name: 'admin_home'},
                                                 {name: 'tags', label: "Tags", url: '/admin/tags'}]}
                                   add_label="Add Tag"
                                   onAddRow={that.onAddTag}
                                   onEditRow={that.onEditTag}
                                   is_loading={is_loading}
                                   columns={columns}
                                   item_list={adminTagList}
            />
        )
    }
}

function mapStateToProps(state, props) {
    const tags = adminTagList.getVisibleObjects()

    return {
        tags,
        is_loading: adminTagList.isLoading(),
        filter: adminTagList.getFilter()
    }
}

export default connect(mapStateToProps)(Tags)
