import React, { useEffect, useState, useRef } from 'react'
import { get, cloneDeep } from 'lodash'
import { useDispatch, useSelector } from 'react-redux'
import { handleSubmitResult } from 'actions/form'
import { Button, Switch, FormControlLabel, makeStyles, Grid } from '@material-ui/core'
import { FormikGeneralFormErrors } from 'components/form/GeneralFormErrors'
import {showSuccess, showError} from 'actions/Error'
import { Formik, Form, FieldArray, Field } from 'formik'
import { FormikDropdownField } from 'components/form/Dropdown'
import { FormikInputField } from 'components/form/InputField'
import { FormikTextareaField } from 'components/form/TextareaField'
import { FormikCheckboxField } from 'components/form/CheckboxField'
import { CountrySelectField } from 'components/form/CountrySelectField'
import { ProvinceSelectField } from 'components/form/ProvinceSelectField'
import { CitySelectField } from 'components/form/CitySelectField'
import { contactAddressTypes } from 'actions/contact/dropdown_options'
import { AddressAutoComplete } from 'components/map/AddressAutoComplete'
import { countryList } from 'actions/country'
import { provinceList } from 'actions/province'
import { cityList } from 'actions/city'
import { contactAddressList, invalidateAllAddressLists } from 'actions/contact/address'
import * as Yup from 'yup'
import { BasicMapWithMarker } from 'components/map/MapMarkers'
import { Separator } from 'components/layout/Separator'
import Alert from '@material-ui/lab/Alert'

export const validationSchema = Yup.object().shape({
    // street1: Yup.string().required("Required")
})

const useStyles = makeStyles((theme) => ({
  container: {
      width: "100%"
  },
  auto_complete_container: {
      width: "100%"
  },
  input_field: {
      width: "100%"
  }
}))

const ContactAddressForm = ({formik_props, newAddressInitialValues, address_id, fullWidth, onDone}) => {
    const classes = useStyles()
    const dispatch = useDispatch()
    const [showDetailedFields, setShowDetailedFields] = useState(true)
    const [showAutoComplete, setShowAutoComplete] = useState(true)
    const address = address_id ? useSelector(() => contactAddressList.getObject(address_id)) : cloneDeep(newAddressInitialValues)
    const initialValues = address

    const toggleShowDetailedFields = () => {
      setShowDetailedFields(showDetailedFields => !showDetailedFields)
    }

    useEffect(() => {
        if ( address_id ) {
            dispatch(contactAddressList.ensureObjectLoaded(address_id))
        }
    }, [address_id])

    useEffect(() => {
        async function fetchStaticObjects() {
            dispatch(contactAddressTypes.fetchListIfNeeded())
            dispatch(countryList.fetchListIfNeeded())
            dispatch(provinceList.fetchListIfNeeded())
            dispatch(cityList.fetchListIfNeeded())
        }
        fetchStaticObjects()
    }, [])

    const address_type_options = contactAddressTypes.getAsOptions()

    const onSave = (values, formik_funcs) => {
        const on_ok = function(json) {
            showSuccess("Saved", "Address saved")
            dispatch(invalidateAllAddressLists())
            onDone()
        }
        if ( address_id ) {
            values.id = address_id
            return dispatch(contactAddressList.saveObject(values)).then((res) => handleSubmitResult({res, formik_funcs, on_ok}))
        } else {
            return dispatch(contactAddressList.saveNewObject(values)).then((res) => handleSubmitResult({res, formik_funcs, on_ok}))
        }
    }

    const renderMapAutoComplete = (formik_props, formatted_address) => {

        const current_formatted_address = get(formik_props, ["values", "formatted_address"], '')

        const onChanged = ({formatted_address, latitude, longitude, street1, street2, province_name,
                            city_name, country_name, postal_code}) => {

                                formik_props.setFieldValue(`formatted_address`, formatted_address)
                                formik_props.setFieldValue(`street1`, street1)
                                formik_props.setFieldValue(`street2`, street2)
                                formik_props.setFieldValue(`country`, countryList.getOptionValueForLabel(country_name))
                                formik_props.setFieldValue(`city`, cityList.getOptionValueForLabel(city_name))
                                formik_props.setFieldValue(`province`, provinceList.getOptionValueForLabel(province_name))
                                formik_props.setFieldValue(`postal_code`, postal_code)
                                formik_props.setFieldValue(`latitude`, latitude)
                                formik_props.setFieldValue(`longitude`, longitude)

        }
        return (
            <AddressAutoComplete formatted_address={current_formatted_address} onChanged={onChanged}/>
        )
    }

    const renderForm = (formik_props, values, alert_severity, is_default_label) => {
      return(
        <>
          <FormikDropdownField name={`address_type`}
                               formik_props={formik_props}
                               options={address_type_options}
                               placeholder="Type"
                               helperText={"What kind of address is this?"}
          />

          <Alert severity={alert_severity} style={{marginTop: 10}}>
              <FormikCheckboxField name={`is_default`}
                                   label={`${is_default_label} default address for pickups and deliveries.`}
                                   formik_props={formik_props}
                                   color="primary"
              />
          </Alert>



            { renderMapAutoComplete(formik_props) }

            <FormikInputField className={classes.input_field}
                              placeholder={"Google formatted address"}
                              name={`formatted_address`}
                              type="text"
                              formik_props={formik_props}
            />

            <FormControlLabel
              control={
                  <Switch
                    checked={showDetailedFields}
                    onChange={toggleShowDetailedFields}
                    name="show_details"
                    color="primary"
                  />
              }
              label="Show detailed fields"
            />

            {showDetailedFields &&
             <>
               <FormikInputField name={`latitude`}
                                 type="text"
                                 placeholder="Latitude"
                                 formik_props={formik_props}
               />

               <FormikInputField name={`longitude`}
                                 type="text"
                                 placeholder="Longitude"
                                 formik_props={formik_props}
               />

               <FormikInputField name={`street1`}
                                 type="text"
                                 placeholder="Street Address"
                                 formik_props={formik_props}
               />

               <FormikInputField name={`street2`}
                                 type="text"
                                 placeholder="Suburb"
                                 formik_props={formik_props}
               />

               <CitySelectField name={`city`}
                                placeholder="City"
                                formik_props={formik_props}
                                country_id={get(values, ["country"], null)}
               />

               <ProvinceSelectField name={`province`}
                                    placeholder="Province/Region"
                                    formik_props={formik_props}
                                    country_id={get(values, ["country"], null)}
               />

               <CountrySelectField name={`country`}
                                   placeholder="Country"
                                   formik_props={formik_props}
               />

               <FormikInputField name={`postal_code`}
                                 type="text"
                                 placeholder="Postal code"
                                 formik_props={formik_props}
               />

               <FormikTextareaField name={`notes`}
                                    placeholder="Notes"
                                    formik_props={formik_props}
                                    rows={5}
               />
            </>
          }
        </>
      )
    }

    if(formik_props) {
      const { values } = formik_props
      const alert_severity = values.is_default ? "success" : "warning"
      const is_default_label = values.is_default ? "This is the" : "Check to make"
      return renderForm(formik_props, values, alert_severity, is_default_label)
    }

    else {

      return (

        <Formik initialValues={initialValues}
                onSubmit={onSave}
                enableReinitialize={true}
        >
          {formik_props => {
              const { values } = formik_props
              const alert_severity = get(values,"is_default") ? "success" : "warning"
              const is_default_label = get(values,"is_default") ? "This is the" : "Check to make"
              return (
                  <Form>
                    <FormikGeneralFormErrors render={(msg) => msg} />

                      { renderForm(formik_props, values, alert_severity, is_default_label) }

                      <Button variant="contained" type="submit" size="large" color="primary" style={{margin: "10px 0"}}>
                        Save Address
                      </Button>

                  </Form>
              )
          }}
        </Formik>

      )

    }

}

export default ContactAddressForm
