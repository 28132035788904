import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useParams } from 'react-router-dom'
import { forEach, head, get, map, keys, split, size } from 'lodash'
import { adminWarehouseList } from './actions/admin_warehouse'
import { Separator } from 'components/layout/Separator'
import { Formik, Form, Field } from 'formik'
import * as Yup from 'yup'
import {showSuccess, showError} from 'actions/Error'
import { handleSubmitResult } from 'actions/form'
import AdminCommonFormLayout from '../layout/AdminCommonFormLayout'
import { FormikInputField } from 'components/form/InputField'
import AdminWarehouseForm from './form/AdminWarehouseForm'
import { validationSchema } from './form/AdminWarehouseForm'

export default function AdminWarehouse({in_drawer}) {

    const dispatch = useDispatch()
    const history = useHistory()
    const { warehouse_id } = useParams()
    const warehouse = useSelector(() => adminWarehouseList.getObject(warehouse_id))
    const initial_values = warehouse
    const is_loading = useSelector(() => adminWarehouseList.isLoading())
    const is_busy = useSelector(() => adminWarehouseList.getIsSavingObject())
    const title = `${get(warehouse, ["name"], 'New warehouse')}`

    useEffect(() => {
        dispatch(adminWarehouseList.ensureObjectLoaded(warehouse_id))
    },[])

    const onSave = (values, formik_funcs) => {
        const on_ok = function(json) {
            dispatch(adminWarehouseList.invalidateList())
            showSuccess("Saved", "Warehouse saved")

            if ( ! warehouse_id ) {
                history.push(`/admin/warehouse/${json.id}`)
            }
        }
        if ( warehouse_id ) {
            values.id = warehouse_id
            if(!get(values, ['address', 'warehouse'])) {
                values.address.warehouse = warehouse_id
            }
            return dispatch(adminWarehouseList.saveObject(values)).then((res) => handleSubmitResult({res, formik_funcs, on_ok}))
        } else {
            return dispatch(adminWarehouseList.saveNewObject(values)).then((res) => handleSubmitResult({res, formik_funcs, on_ok}))
        }
    }

    const getCellValue = (header_key, item, index) => {
        switch( header_key ) {
            default:
                return undefined
        }
    }

    const renderForm = (formik_props) => {
        return (
            <div>
                <FormikInputField
                    name="name"
                    label="Name"
                    formik_props={formik_props}
                />
                <AdminWarehouseForm
                    formik_props={formik_props}
                    renderExtraFieldsPosition1={this.renderExtraFields}
                />
            </div>

        )
    }

    return (
        <AdminCommonFormLayout
            breadcrumbs={[
                {name: 'admin_home'},
                {name: 'warehouses', label: 'Warehouses', url: '/admin/warehouses'},
                {name: 'warehouse', label: get(warehouse, ["name"], 'New warehouse'), url: `/admin/warehouse/${warehouse_id}`}
            ]}
            is_busy={is_busy}
            is_loading={is_loading}
            title={title}
            initial_values={initial_values}
            validationSchema={validationSchema}
            onSave={onSave}>

          {
              ({formik_props}) =>
                                <AdminWarehouseForm
                                    warehouse_id={warehouse_id}
                                    formik_props={formik_props}
                                    in_drawer={in_drawer}
                                />
          }
        </AdminCommonFormLayout>
    )

}
