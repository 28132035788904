import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useHistory } from 'react-router-dom'
import { forEach, head, get, map, keys, split, size } from 'lodash'
import { adminBeneficiaryParcelProductList } from './actions/admin_beneficiary_parcel_product'
import { adminParcelList } from './actions/admin_parcel'
import WrappingBusyMask from 'components/WrappingBusyMask'
import FormCard from 'components/layout/FormCard'
import { FormLabelValue } from 'components/form/FormLabelValue'
import { FormikDropdownField } from 'components/form/Dropdown'
import { FormikWarehouseProductAutoCompleteDropdown } from 'admin/components/products/form/WarehouseProductAutoCompleteDropdown'
import { adminWarehouseProductAutoCompleteList } from 'admin/components/inventory/actions/admin_warehouse_product'
import { FormikDonorAutoCompleteDropdown } from 'admin/components/donors/form/DonorAutoCompleteDropdown'
import { FormikDateTimePicker } from 'components/form/DatetimePicker'
import { CardHeader } from 'components/layout/CardHeader'
import { FormikCheckboxField } from 'components/form/CheckboxField'
import { Separator } from 'components/layout/Separator'
import { Formik, Form, FieldArray, Field } from 'formik'
import Loading from 'components/Loading'
import { FormikInputField } from 'components/form/InputField'
import { FormikTextareaField } from 'components/form/TextareaField'
import AdminMainLayout from '../layout/AdminMainLayout'
import BusyMask from 'components/BusyMask'
import Timestamp from 'components/Timestamp'
import Card from 'components/layout/Card'
import * as Yup from 'yup'
import {showSuccess, showError} from 'actions/Error'
import { adminBeneficiaryList } from 'admin/components/beneficiaries/actions/admin_beneficiary'
import CardInfoText from 'components/layout/CardInfoText'
import { handleSubmitResult } from 'actions/form'
import { Select } from 'formik-material-ui'
import { Grid, Checkbox, FormControlLabel, Divider, Typography } from '@material-ui/core'

const yup_shape = {
    'from_warehouse_product': Yup.string().required("Required"),
    'actual_quantity': Yup.number().required("Required"),
}

const validationSchema = Yup.object().shape(yup_shape)

export default function AdminParcelProduct({parcel_product_id, parcel_id, onCancel, onSaved}) {
    const dispatch = useDispatch()
    const history = useHistory()
    const parcel_product = useSelector(() => adminBeneficiaryParcelProductList.getObject(parcel_product_id))
    const parcel = useSelector(() => adminParcelList.getObject(parcel_id))
    const is_loading = useSelector(() => adminBeneficiaryParcelProductList.isLoading()) || (parcel_product_id && !get(parcel_product, "id"))
    const is_busy = useSelector(() => adminBeneficiaryParcelProductList.getIsSavingObject() || adminBeneficiaryParcelProductList.getIsUpdatingObject(parcel_id))
    const initial_values = Object.assign({}, parcel_product)
    const title = `${get(parcel_product, ["product_name"], 'Add Product to Order')}`
    const is_new = !parcel_product.from_warehouse_product
    const [overrideTotalWeight, setOverrideTotalWeight] = useState(null)

    useEffect(() => {
        dispatch(adminBeneficiaryParcelProductList.ensureObjectLoaded(parcel_product_id))
        dispatch(adminParcelList.ensureObjectLoaded(parcel_id))
    },[])

    const onSave = (values, formik_funcs) => {
        const on_ok = function(json) {
            dispatch(adminBeneficiaryParcelProductList.invalidateList())
            dispatch(adminWarehouseProductAutoCompleteList.invalidateObject(get(values, "from_warehouse_product")))
            dispatch(adminWarehouseProductAutoCompleteList.invalidateObject(get(values, "from_warehouse_product")))
            dispatch(adminWarehouseProductAutoCompleteList.ensureObjectLoaded(get(values, "from_warehouse_product")))

            showSuccess("Saved", "Products saved")

            if ( onSaved ) {
                onSaved(json)
            }
            else if ( ! parcel_product_id ) {
                history.push(`/admin/parcel/${parcel_id}/product/${json.id}`)
            }
        }
        values.parcel = parcel_id
        if ( parcel_product_id ) {
            values.id = parcel_product_id
            return dispatch(adminBeneficiaryParcelProductList.saveObject(values)).then((res) => handleSubmitResult({res, formik_funcs, on_ok}))
        } else {
            return dispatch(adminBeneficiaryParcelProductList.saveNewObject(values)).then((res) => handleSubmitResult({res, formik_funcs, on_ok}))
        }
    }

    const getCellValue = (header_key, item, index) => {
        switch( header_key ) {
            default:
                return undefined
        }
    }
    const handleOverrideTotalWeightCheckboxChange = (e) => {
        setOverrideTotalWeight(event.target.checked)
    }

    const renderForm = (formik_props, is_new) => {
        formik_props.values.total_weight_kg = ""
        return (
            <div>

                <FormikWarehouseProductAutoCompleteDropdown
                    name="from_warehouse_product"
                    can_edit={false}
                    label="Product"
                    formik_props={formik_props}
                />

                <Typography variant="h6">Quantity</Typography>

                <FormikInputField
                    name="actual_quantity"
                    label="Quantity"
                    fullWidth={false}
                    formik_props={formik_props}
                    helperText={`Total weight: ${formik_props.values.product_weight_kg * formik_props.values.actual_quantity}kg`}
                />
                <Divider />
                

                <FormControlLabel
                    control={
                        <Checkbox
                            checked={overrideTotalWeight}
                            onChange={handleOverrideTotalWeightCheckboxChange}
                            name="override_total_weight"
                            color="primary"
                        />
                    }
                    label="Override Total Weight"
                />

                { overrideTotalWeight &&

                    <FormikInputField
                        name="total_weight_kg"
                        label="Total Weight (kg)"
                        fullWidth={false}
                        placeholder={formik_props.values.actual_quantity * formik_props.values.product_weight_kg}
                        helperText="Leave blank to auto calculate"
                        formik_props={formik_props}
                    />
                }

            </div>
        )
    }

    return (
        <WrappingBusyMask is_loading={is_loading || is_busy}>

            <Formik
                initialValues={initial_values}
                onSubmit={onSave}
                enableReinitialize={true}
                validationSchema={validationSchema}
            >
                {formik_props => {
                    const { values } = formik_props
                    return (
                        <Form>
                            <FormCard
                                isSubmitting={formik_props.isSubmitting}
                                onCancel={onCancel}
                            >
                                { renderForm(formik_props, is_new) }
                            </FormCard>
                        </Form>
                    )}
                }
            </Formik>

        </WrappingBusyMask>
    )

}
