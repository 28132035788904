import React from 'react'
import PropTypes from 'prop-types'
import { useHistory } from 'react-router-dom'
import {
  AppBar,
  makeStyles,
  Tabs,
  Tab,
  Typography,
  Box
} from '@material-ui/core'
import AdminParcelsTable from 'admin/components/orders/AdminParcelsTable'
import { AdminCompanyReports } from 'admin/components/reports/AdminCompanyReports'
import { AdminAddresses } from 'admin/components/addresses/AdminAddresses'
import { AdminContacts } from 'admin/components/contacts/AdminContacts'
import AdminDonorForm from '../donors/form/AdminDonorForm'
import AdminBeneficiaryForm from '../beneficiaries/form/AdminBeneficiaryForm'
import Alert from '@material-ui/lab/Alert'
import AdminDemographics from '../demographics/AdminDemographics'
import AdminFeedingGrid from '../feeding/AdminFeedingGrid'
import Loading from 'components/Loading'

function TabPanel( props ) {
  const { children, value, index, ...other } = props

  return (
    <div
      role="tabpanel"
      hidden={ value !== index }
      id={ `scrollable-auto-tabpanel-${ index }` }
      aria-labelledby={ `scrollable-auto-tab-${ index }` }
      { ...other }
    >
      { value === index && (
        <Box p={ 3 }>
          <Typography>{ children }</Typography>
        </Box>
      ) }
    </div>
  )
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
}

function a11yProps( index ) {
  return {
    id: `scrollable-auto-tab-${ index }`,
    'aria-controls': `scrollable-auto-tabpanel-${ index }`,
  }
}

const useStyles = makeStyles( ( theme ) => ( {
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
    marginLeft: -15,
    marginRight: -15,
  },
  appBar: {
    background: '#FFFFFF',
  },
  removeTabsPadding: {
    margin: -24
  }
} ) )

export default function AdminCommonCompany( { formik_props, company_id, form_variation, state_history, is_loading } ) {
  const classes = useStyles()
  const history = useHistory()
  const [ tabValue, setTabValue ] = React.useState( 0 )
  const [ showTabs, setShowTabs ] = React.useState( false )
  const [ showAlert, setShowAlert ] = React.useState( true )
  const companyExists = company_id ? true : false

  const handleTabChange = ( event, newValue ) => {
    setTabValue( newValue )
  }

  const closeAlert = () => {
    setShowAlert( false )
  }

  React.useEffect( () => {
    if ( companyExists ) {
      setShowTabs( true )
    }
  }, [ companyExists ] )

  const renderTabs = ( form_variation ) => {

    switch ( form_variation ) {
      case 'donor':
        return renderDonorTabs()
        break
      case 'beneficiary':
        return renderBeneficiaryTabs()
        break
      case 'logistics_company':
        return renderLogisticsCompanyTabs()
        break
      default:
        return renderGeneralCompanyTabs()
    }
  }

  const renderGeneralCompanyTabs = () => {
    return (
      <Tabs
        value={ tabValue }
        onChange={ handleTabChange }
        variant="scrollable"
        scrollButtons="auto"
        indicatorColor="primary"
        textColor="primary"
        aria-label="donor tabs"
      >
        <Tab label="Details" { ...a11yProps( 0 ) } />
        <Tab label="Addresses" { ...a11yProps( 1 ) } />
        <Tab label="Contacts" { ...a11yProps( 2 ) } />
      </Tabs>
    )
  }

  const renderDonorTabs = () => {
    return (
      <Tabs
        value={ tabValue }
        onChange={ handleTabChange }
        variant="scrollable"
        scrollButtons="auto"
        indicatorColor="primary"
        textColor="primary"
        aria-label="donor tabs"
      >
        <Tab label="Details" { ...a11yProps( 0 ) } />
        <Tab label="Addresses" { ...a11yProps( 1 ) } />
        <Tab label="Contacts" { ...a11yProps( 2 ) } />
        <Tab label="Donations" { ...a11yProps( 3 ) } />
        <Tab label="Distribution" { ...a11yProps( 4 ) } />
        <Tab label="Reports" { ...a11yProps( 5 ) } />
      </Tabs>
    )
  }

  const renderBeneficiaryTabs = () => {
    return (
      <Tabs
        value={ tabValue }
        onChange={ handleTabChange }
        variant="scrollable"
        scrollButtons="auto"
        indicatorColor="primary"
        textColor="primary"
        aria-label="beneficiary tabs"
      >
        <Tab label="Details" { ...a11yProps( 0 ) } />
        <Tab label="Addresses" { ...a11yProps( 1 ) } />
        <Tab label="Contacts" { ...a11yProps( 2 ) } />
        <Tab label="Parcels" { ...a11yProps( 3 ) } />
        <Tab label="Demographics" { ...a11yProps( 4 ) } />
        <Tab label="Feeding" { ...a11yProps( 5 ) } />
      </Tabs>
    )
  }

  const renderLogisticsCompanyTabs = () => {
    return (
      <Tabs
        value={ tabValue }
        onChange={ handleTabChange }
        variant="scrollable"
        scrollButtons="auto"
        indicatorColor="primary"
        textColor="primary"
        aria-label="logistics company tabs"
      >
        <Tab label="Details" { ...a11yProps( 0 ) } />
        <Tab label="Addresses" { ...a11yProps( 1 ) } />
        <Tab label="Contacts" { ...a11yProps( 2 ) } />
        <Tab label="Trips" { ...a11yProps( 3 ) } />
      </Tabs>
    )
  }

  const renderCreateCompanyFirstAlert = () => {
    return (
      <Alert severity="info" onClose={ closeAlert }>
        First, complete the company details and SAVE (blue button at bottom right hand corner).<br />
        After the company is created you will be able to add contacts and addresses, view orders and configure reports.
      </Alert>
    )
  }

  const renderTabPanels = ( form_variation ) => {
    switch ( form_variation ) {
      case 'donor':
        return renderDonorTabPanels()
        break
      case 'beneficiary':
        return renderBeneficiaryTabPanels()
        break
      case 'logistics_company':
        return renderLogisticsCompanyTabPanels()
        break
      default:
        return renderGeneralCompanyTabPanels()
    }
  }

  const renderDonorTabPanels = () => {
    return (
      <>
        {/*  Donor form */ }
        <TabPanel value={ tabValue } index={ 0 }>
          { is_loading ? <Loading message="Loading donor details..." /> :
            <AdminDonorForm formik_props={ formik_props } company_id={ company_id } state_history={ state_history } />
          }
        </TabPanel>
        {/*  Addresses */ }
        <TabPanel value={ tabValue } index={ 1 }>
          <AdminAddresses formik_props={ formik_props } name="company.addresses" newAddressInitialValues={ { company: company_id } } inTabs />
        </TabPanel>
        {/*  Contacts */ }
        <TabPanel value={ tabValue } index={ 2 }>
          <AdminContacts company_id={ company_id } formik_props={ formik_props } name="company.contacts" inTabs />
        </TabPanel>
        {/*  Donations */ }
        <TabPanel value={ tabValue } index={ 3 }>
          <div className={ classes.removeTabsPadding }>
            <AdminParcelsTable company={ company_id } noPaper inTabs />
          </div>
        </TabPanel>
        {/*  Beneficiary orders that contain products from this donor */ }
        <TabPanel value={ tabValue } index={ 4 }>
          <div className={ classes.removeTabsPadding }>
            <AdminParcelsTable contains_products_donated_by={ company_id } noPaper inTabs />
          </div>
        </TabPanel>
        {/*  Reports */ }
        <TabPanel value={ tabValue } index={ 5 }>
          <AdminCompanyReports asTable={ true } company_id={ company_id } noPaper />
        </TabPanel>
      </>
    )
  }

  const renderBeneficiaryTabPanels = () => {
    return (
      <>
        <TabPanel value={ tabValue } index={ 0 }>
          { is_loading ? <Loading message="Loading beneficiary details..." /> :
            <AdminBeneficiaryForm formik_props={ formik_props } company_id={ company_id } state_history={ state_history } />
          }
        </TabPanel>
        <TabPanel value={ tabValue } index={ 1 }>
          <AdminAddresses formik_props={ formik_props } name="company.addresses" newAddressInitialValues={ { company: company_id } } inTabs />
        </TabPanel>
        <TabPanel value={ tabValue } index={ 2 }>
          <AdminContacts company_id={ company_id } formik_props={ formik_props } name="company.contacts" inTabs />
        </TabPanel>
        <TabPanel value={ tabValue } index={ 3 }>
          <div className={ classes.removeTabsPadding }>
            <AdminParcelsTable company={ company_id } noPaper inTabs />
          </div>
        </TabPanel>
        <TabPanel value={ tabValue } index={ 4 }>
          <div className={ classes.removeTabsPadding }>
            <AdminDemographics company_id={ company_id } inTabs />
          </div>
        </TabPanel>
        <TabPanel value={ tabValue } index={ 5 }>
          <div className={ classes.removeTabsPadding }>
            <AdminFeedingGrid company_id={ company_id } inTabs />
          </div>
        </TabPanel>
      </>
    )
  }

  const renderLogisticsCompanyTabPanels = () => {
    return (
      <>
        <TabPanel value={ tabValue } index={ 0 }>
          <AdminBeneficiaryForm formik_props={ formik_props } company={ company_id } state_history={ state_history } />
        </TabPanel>
        <TabPanel value={ tabValue } index={ 1 }>
          <AdminAddresses formik_props={ formik_props } name="company.addresses" newAddressInitialValues={ { company: company_id } } inTabs />
        </TabPanel>
        <TabPanel value={ tabValue } index={ 2 }>
          <AdminContacts company_id={ company_id } formik_props={ formik_props } name="company.contacts" inTabs />
        </TabPanel>
        <TabPanel value={ tabValue } index={ 3 }>
          <div className={ classes.removeTabsPadding }>
            {/* <AdminLogisticsTripsTable company={company_id} noPaper inTabs /> */ }
          </div>
        </TabPanel>
      </>
    )
  }

  const renderGeneralCompanyTabPanels = () => {
    return (
      <>
        <TabPanel value={ tabValue } index={ 0 }>
          <AdminDonorForm formik_props={ formik_props } company_id={ company_id } state_history={ state_history } />
        </TabPanel>
        <TabPanel value={ tabValue } index={ 1 }>
          <AdminAddresses formik_props={ formik_props } name="company.addresses" newAddressInitialValues={ { company: company_id } } inTabs />
        </TabPanel>
        <TabPanel value={ tabValue } index={ 2 }>
          <AdminContacts company_id={ company_id } formik_props={ formik_props } name="company.contacts" inTabs />
        </TabPanel>
      </>
    )
  }

  return (
    <div className={ classes.root }>
      { !showTabs && !is_loading && showAlert && renderCreateCompanyFirstAlert() }
      <AppBar position="static" elevation={ 1 } className={ classes.appBar }>
        { renderTabs( form_variation ) }
      </AppBar>
      { renderTabPanels( form_variation ) }

    </div>
  )
}
