import React, { Component } from 'react'
import { FormikLogisticsCompanyAutoCompleteDropdown } from './LogisticsCompanyAutoCompleteDropdown'
import AdminCommonCompanyForm from 'admin/components/companies/form/AdminCommonCompanyForm'
import Skeleton from '@material-ui/lab/Skeleton'
import * as Yup from 'yup'

export const validationSchema = Yup.object().shape({
    'company': Yup.object().shape({
        'name': Yup.string().required("Required")
    })
})


export default function AdminLogisticsCompanyForm({ formik_props, inDrawer, company, state_history }) {

    const renderParentField = (formik_props) => {
        return (
            <>
            <FormikLogisticsCompanyAutoCompleteDropdown name="company.parent" label="Parent company" formik_props={formik_props} />
            </>
        )
    }

    // Gtp added, because this was broke in a recent Manu commit
    const props = []
    // end of hack

    return (
        <AdminCommonCompanyForm
            formik_props={formik_props}
            renderParentField={renderParentField}
            inDrawer={inDrawer}
            company={company}
            state_history={state_history}
        />
    )

}
