import React, { Component } from 'react'
import { connect } from 'react-redux'
import { lowerCase, values, forEach, map, filter, head, get, includes } from 'lodash'
import { confirmModal } from 'actions/ui'
import AdminTableFilter from 'admin/components/common/AdminTableFilter'
import AdminMainLayout from '../layout/AdminMainLayout'
import Timestamp from 'components/Timestamp'
import { adminProductCategoryList } from './actions/admin_product_category'
import { ShortId } from 'components/ShortId'
import { showSuccess, showError } from 'actions/Error'
import TabularTree from 'components/form/TabularTree'
import ProductCategoryInlineForm from './form/ProductCategoryInlineForm'
import { Modal } from 'components/layout/Modal'
import { Card, CardContent, createStyles, LinearProgress } from '@material-ui/core'
import SearchBar from 'components/layout/SearchBar'

const classes = createStyles( ( theme ) => ( {
	labelRoot: {
		paddingLeft: 15,
		fontSize: 24,
	},
	labelShinked: {
		marginLeft: 5,
		fontSize: 16
	},
	tableFilter: {

	}
} ) )

class ProductCategories extends Component {

	constructor( props ) {
		super( props )
		this.state = {
			adding_child_of_product_category: false,
			xpanded_ids: [],
			editing_product_category: null
		}
	}

	componentDidMount() {
		const { dispatch } = this.props
		dispatch( adminProductCategoryList.updateListFilter( { top_level_only: true } ) )
		dispatch( adminProductCategoryList.updatePaginationNumItemsPerPage( 1000 ) )

	}

	componentDidUpdate() {
		const { dispatch, filter } = this.props
		dispatch( adminProductCategoryList.fetchListIfNeeded() )
	}

	onEditProductCategory = ( product_category_id ) => {
		const { history } = this.props
		history.push( {
			pathname: '/admin/product_category/' + product_category_id
		} )
	}

	onAddProductCategory = () => {
		const { history } = this.props
		history.push( '/admin/product_category' )
	}

	onQuickAddTreeItem = ( { parent_item } ) => {
		this.setState( { adding_child_of_product_category: parent_item } )
	}

	onDoneQuickAddTreeItem = () => {
		this.setState( { adding_child_of_product_category: false } )
	}

	onQuickEditTreeItem = ( { item } ) => {
		this.setState( { editing_product_category: item } )
	}

	onDeleteTreeItem = ( { item } ) => {
		const { dispatch } = this.props

		const onDone = ( res ) => {
			if ( res.errors ) {
				console.log( res.errors )
				showError( "Failed", "Category is in use and could not be deleted" )
			} else {
				showSuccess( "Deleted", "Category deleted" )
				dispatch( adminProductCategoryList.invalidateList() )
				dispatch( adminProductCategoryList.fetchListIfNeeded() )
			}
		}

		const onConfirmed = () => {
			dispatch( adminProductCategoryList.deleteObject( item.id ) ).then( onDone )
		}

		dispatch( confirmModal( {
			text: "Are you sure you want to delete this category?",
			onConfirmed,
			can_cancel: true
		} ) )

	}

	onDoneQuickEditTreeItem = () => {
		this.setState( { editing_product_category: null } )
	}

	onFilterChanged = ( filter_values ) => {
		const { dispatch } = this.props
		dispatch( adminProductCategoryList.updateListFilter( filter_values ) )
		dispatch( adminProductCategoryList.invalidateList() )
		dispatch( adminProductCategoryList.fetchListIfNeeded() )
		this.updateExpandedIds( filter_values )
	}

	updateExpandedIds = ( filter_values ) => {
		const { all_product_categories_by_id } = this.props
		const that = this
		const expanded_ids = []

		const recursivelyExpandParent = ( item ) => {
			expanded_ids.push( item.id )
			if ( item.parent ) {
				recursivelyExpandParent( all_product_categories_by_id[ item.parent ] )
			}
		}

		const filtered_items = filter( values( all_product_categories_by_id ), function( x ) {
			return that.filterItem( x )
		} )
		forEach( filtered_items, ( x ) => recursivelyExpandParent( x ) )
		this.setState( { expanded_ids: expanded_ids } )
	}

	renderQuickAddTreeItemForm() {
		const { adding_child_of_product_category } = this.state
		const that = this
		return (
			<Modal
				onClose={ that.onDoneQuickAddTreeItem }
				fullWidth={ true }
				width='xl'
				title="Create new product category"
				noPadding
			>
				<ProductCategoryInlineForm
					product_category={ null }
					is_new
					adding_child_of_parent_product_category={ adding_child_of_product_category }
					onSaved={ that.onDoneQuickAddTreeItem }
					onCancel={ that.onDoneQuickAddTreeItem }
				/>
			</Modal>
		)
	}

	renderQuickEditTreeItemForm() {
		const { editing_product_category } = this.state
		const that = this
		return (
			<Modal
				onClose={ that.onDoneQuickEditTreeItem }
				fullWidth={ true }
				width='xl'
				title={ `Editing category ${ get( editing_product_category, "name" ) }` }
				noPadding
			>
				<ProductCategoryInlineForm
					product_category={ editing_product_category }
					onSaved={ that.onDoneQuickEditTreeItem }
					onCancel={ that.onDoneQuickEditTreeItem }
				/>
			</Modal>
		)
	}

	renderFilter = ( formik_props ) => {
		return (
			<div className={ classes.searchBar }>
				<SearchBar formik_props={ formik_props } />
			</div>
		)
	}

	filterItem = ( item ) => {
		const { filter } = this.props
		if ( filter.any_field == "" ) { return false }
		else { return includes( lowerCase( item.name ), lowerCase( filter.any_field ) ) }
	}

	onToggle = ( evt, items ) => {
		this.setState( { expanded_ids: items } )
	}

	render() {
		const { is_loading, product_categories_as_tree } = this.props
		const { adding_child_of_product_category, editing_product_category, expanded_ids } = this.state
		const that = this

		const columns = [
			{
				field: 'id', title: 'Id',
				render: ( item ) => <ShortId value={ item.id } />
			},
			{ field: 'name', title: 'Product Category' },
			{
				field: 'created_at',
				title: 'Created at',
				render: ( item ) => <Timestamp value={ item.created_at } format='datetime' />
			},
		]

		return (
			<AdminMainLayout
				active_key="product_categories"
				title={ "Product Categories" }
				breadcrumbs={ [
					{ name: 'admin_home' },
					{ name: 'product_categories', label: "Product Categories", url: '/admin/product_categories' }
				] }
			>
				<Card variant="outlined">
					<CardContent>

						<div style={ { marginLeft: -15, marginRight: -15, paddingTop: 50 } }>
							<AdminTableFilter
								label="Search product categories..."
								placeholder="Search product categories..."
								renderFilter={ this.renderFilter }
								updateOnChange={ this.onFilterChanged }
							/>
						</div>
						{ is_loading && <LinearProgress /> }
						<TabularTree root_nodes={ product_categories_as_tree }
							onAddItem={ that.onQuickAddTreeItem }
							onEditItem={ that.onQuickEditTreeItem }
							onDeleteItem={ that.onDeleteTreeItem }
							filterFunc={ that.filterItem }
							forceExpandedIds={ expanded_ids }
							onNodeToggle={ that.onToggle }
							label_field_name="name"
							label_field_extra="nutrition_food_item"

						/>
						{ adding_child_of_product_category !== false && that.renderQuickAddTreeItemForm() }
						{ editing_product_category && that.renderQuickEditTreeItemForm() }
					</CardContent>
				</Card>

			</AdminMainLayout>
		)
	}
}

function mapStateToProps( state, props ) {
	const product_categories_as_tree = adminProductCategoryList.getVisibleObjectsAsTree()

	return {
		product_categories_as_tree,
		all_product_categories_by_id: adminProductCategoryList.getFlattenedObjectsById(),
		is_loading: adminProductCategoryList.isLoading(),
		filter: adminProductCategoryList.getFilter()
	}
}

export default connect( mapStateToProps )( ProductCategories )