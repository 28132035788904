import React, { Component } from 'react'
import { connect } from 'react-redux'
import { get } from 'lodash'
import { withRouter } from 'react-router-dom'
import { Formik, Form, FieldArray, Field } from 'formik'
import { userList } from 'actions/user'
import { FormikInputField } from './form/InputField'
import { FormikDropdownField } from './form/Dropdown'
import BusyMask from './BusyMask'
import { countryList } from 'actions/country'
import { cityList } from 'actions/city'
import * as Yup from 'yup'
import FieldInfoText from './layout/FieldInfoText'
import { FormLabelValue } from './form/FormLabelValue'
import { ButtonBar } from './layout/ButtonBar'
import { Separator } from './layout/Separator'
import { FormikGeneralFormErrors } from './form/GeneralFormErrors'
import { Trans, Translation } from 'react-i18next'
import { handleSubmitResult } from 'actions/form'
import { Paper, Button } from '@material-ui/core';

const validationSchema = Yup.object().shape({
    email: Yup.string().email('Email must be valid').required("Email is required").nullable()
})

class ProfileForm extends Component {

    render() {
        const { user, initial_values, onSave, onCancel, error } = this.props

        const that = this
        const left_width = 3
        const right_width = 6

        return (

            <Formik
              initialValues={initial_values}
              onSubmit={onSave}
              enableReinitialize={true}
              validationSchema={validationSchema}
            >
            {formik_props => {
                const { values } = formik_props
                return (
                  <Paper square variant="outlined">
                    <Form>
                            <FormikGeneralFormErrors render={(msg) => {msg}} />
                            <FormikInputField
                              name="first_name"
                              type="text"
                              placeholder={"First name"}
                            />
                            <FormikInputField
                              name="last_name"
                              type="text"
                              placeholder={"Surname"}
                            />


                            <FormikInputField
                              name="email"
                              type="email"
                              placeholder={"Contact email"}
                            />

                            <FormikInputField
                              name="phone_number"
                              type="text"
                              placeholder={"Contact phone"}
                            />

                            <FormikInputField
                              name="password"
                              type="password"
                              placeholder={"Password (leave blank to keep your existing password"}
                            />
                            <Separator variant="h50"/>


                            <ButtonBar>
                              <Button type="submit" variant="contained" color="primary"><Trans>Save</Trans></Button>
                              <Button onClick={onCancel}><Trans>Cancel</Trans></Button>
                            </ButtonBar>
                          </Form>
                        </Paper>
                  )}
              }
            </Formik>
        )
    }
}

function mapStateToProps(state, props) {
    const { onSave, onCancel, user, renderAdditionalFields } = props

    const initial_values = Object.assign({email: ''}, user)
    return {
        onSave,
        onCancel,
        initial_values: initial_values
    }
}
export default withRouter(connect(mapStateToProps)(ProfileForm))
