import PropTypes from 'prop-types'
import { Card, CardHeader, Box } from '@material-ui/core'
import Chart, { useChart } from 'components/chart'
import { map, omit, startCase, replace } from 'lodash'

// ----------------------------------------------------------------------

AdminDemographicsChart.propTypes = {
  data: PropTypes.array,
  title: PropTypes.string,
  subheader: PropTypes.string,
  total: PropTypes.number,
}

export default function AdminDemographicsChart( { title, subheader, data, total, ...other } ) {

  const getFilterNames = ( filters ) => {
    const filterNames = map( omit( filters, 'company' ), ( value, key ) => (
      `${ startCase( replace( value, /_/g, ' ' ) ) }`
    ) ).join( ', ' )
    return filterNames
  }

  const labels = map(data, (item) => getFilterNames(item.filters)) // Adjust based on your data structure
  const series = map(data, (item) => {
    const percentage = ((item.totalPeople / total) * 100).toFixed(2);
    return {
      name: getFilterNames(item.filters),
      data: [item.totalPeople],
    }
  })

  const chartOptions = useChart( {
    plotOptions: {
      bar: {
        columnWidth: '100%',
      },
    },
    fill: {
      type: map( series, 'fill' ),
    },
    labels,
    yaxis: {
      min: 0,
      forceNiceScale: false,
      decimalsInFloat: 0,
    },
    xaxis: {
      type: 'string',
    },
    tooltip: {
      shared: true,
      intersect: false,
      y: {
        formatter: ( value ) => {
          if ( typeof value !== 'undefined' ) {
            return `${ value.toFixed( 0 ) }`
          }
          return value
        },
      },
    },
  } )

  return (
    <Card { ...other }>
      <CardHeader title={ title } subheader={ subheader } />
      <Box sx={ { p: 3, pb: 1 } } dir="ltr">
        <Chart type="bar" series={ series } options={ chartOptions } height={ 364 } />
      </Box>
    </Card>
  )
}
