import React from 'react'
import { withStyles, makeStyles } from '@material-ui/core/styles'
import { Dialog, DialogContent, Grid, AppBar, Toolbar, Typography, Hidden, CircularProgress } from '@material-ui/core'
import MuiDialogTitle from '@material-ui/core/DialogTitle'
import IconButton from '@material-ui/core/IconButton'
import CloseIcon from '@material-ui/icons/Close'
import Alert from '@material-ui/lab/Alert'
import { AddButton } from 'components/form/Buttons'

const styles = (theme) => ({
  root: {
    padding: '10px 16px',
    borderBottom: '1px solid #cccccc',
  },
  loading: {
    color: '#ffffff'
  },
})

const useStyles = makeStyles((theme) => ({
  save_close_button: {
    marginTop: 10,
    [theme.breakpoints.down('sm')]: {
      marginRight: -5
    },
  },
  appBar: {
    position: 'relative',
    background: '#ffffff',
    borderBottom: '1px solid #efefef'
  },
  close_button: {
    marginRight: 7,
    marginLeft: -15
  },
}))

function FullScreenAppBar({title, onClose, onSave, extraAppBarIcons}) {
  const classes = useStyles()
  return(
    <AppBar className={classes.appBar} elevation={0}>
      <Toolbar>
        <Grid container direction="row" justifyContent="space-between" alignItems="center">
          <Grid item>
            <Typography style={{color: 'black', fontSize: 24}}>
              <IconButton onClick={onClose} className={classes.close_button}>
                <CloseIcon />
              </IconButton>
              <Hidden smDown>
                {title}
              </Hidden>
            </Typography>
          </Grid>
          <Grid item>
            { onSave &&
              <div className={classes.save_close_button}>
                { extraAppBarIcons && extraAppBarIcons }
                <AddButton
                  onClick={onSave}
                  color="secondary"
                  label={"Save & Close"}
                  />
              </div>
            }
          </Grid>
        </Grid>
      </Toolbar>
    </AppBar>
  )
}

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props
  const close_button = <IconButton aria-label="close" style={{float: 'right', marginRight: -15, marginTop: -10, marginBottom: -10}} onClick={onClose}>
    <CloseIcon />
  </IconButton>
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">
        { onClose ? close_button : null }
        {children}
      </Typography>
    </MuiDialogTitle>
  )
})

export const Modal = ({children, title, noPadding, fullScreen=false, fullWidth=false, maxWidth, onClose, onSave, extraAppBarIcons, ...props}) => {

  const showAppBar = fullScreen === true

  return (
    <Dialog open={true}
      onClose={onClose}
      fullScreen={fullScreen}
      fullWidth={fullWidth}
      maxWidth={maxWidth}
      aria-labelledby="customized-dialog-title"
      {...props}
      >
      { showAppBar && FullScreenAppBar({title, onClose, onSave, extraAppBarIcons}) }
      { ! fullScreen &&
        <>
        {title &&
          <DialogTitle id="customized-dialog-title" onClose={onClose}>
            {title}
          </DialogTitle>
        }
        </>
    }
    { !noPadding ?
      <DialogContent>
        { showAppBar && <div style={{height:80}}></div> }
        {children}
      </DialogContent>
      :
      <>
      { showAppBar && <div style={{height:80}}></div> }
      {children}
      </>
  }
</Dialog>
)
}

export const AlertModal = ({ severity, alert_text, onClose, children, ...props }) => {
  severity = severity ? severity : 'error'
  return(
    <Dialog open={true}
      onClose={onClose}
      aria-labelledby={severity}
      {...props}
      >
      <Alert severity={severity}>
        { alert_text }
      </Alert>

      <DialogContent>
        { children }
      </DialogContent>

    </Dialog>
  )
}

export const LoadingModal = ({ message }) => {
  return(
    <Dialog
      open={true}
      PaperProps={{
        style: {
          backgroundColor: 'transparent',
          boxShadow: 'none',
        },
      }}
      >
      <div align="center" style={{width: 100, height: 100}}>
        <CircularProgress style={{color: 'white'}} />
        { message && <Typography style={{color: 'white', marginTop: 10}}>{ message }</Typography> }
      </div>
    </Dialog>
  )
}
