import React, { useState } from 'react'
import { capitalize } from 'lodash'
import clsx from 'clsx'
import DonorMainLayout from '../layout/DonorMainLayout'
import { makeStyles } from '@material-ui/core/styles'
import {
  Grid,
  Paper,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Card,
  CardContent,
  Chip,
  Collapse,
  Typography,
  TextField,
  Select,
  MenuItem,
  ListItemText,
  InputLabel,
  InputAdornment,
  Button,
  FormControlLabel,
  FormControl,
  Checkbox,
  RadioGroup,
  Radio,
  Stepper,
  Step,
  StepLabel,
} from '@material-ui/core'
import { Separator } from 'components/layout/Separator'
import _without from "lodash/without"
import { payment_options, projects, recurring_payment_periods } from 'sampleData/sampleData'
import CancelIcon from '@material-ui/icons/Cancel'
import PageTitle from '../layout/PageTitle'

const useStyles = makeStyles((theme) => ({
  root: {
    padding: 15,
    marginTop: 5,
    '& .MuiInputLabel-root': {
      marginLeft: 15,
      marginTop: -5
    }
  },
  chip: {
    marginRight: 3
  },
  button: {
    marginTop: 20
  }
}))

export default function DonateMoney() {
  const classes = useStyles()
  const [openPaymentDialog, setOpenPaymentDialog] = React.useState(false)

  const handleClickOpen = () => {
    setOpenPaymentDialog(true)
  };

  const handleClosePaymentDialog = () => {
    setOpenPaymentDialog(false)
  }
  const [paymentOptionValue, setPaymentOptionValue] = React.useState()
  const [recurringPaymentOptionValue, setRecurringPaymentOptionValue] = React.useState('monthly')
  const [projectName, setProjectName] = React.useState([])
  const [recurringPayment, setRecurringPayment] = useState(true)
  const [activeStep, setActiveStep] = React.useState(0);
  const [skipped, setSkipped] = React.useState(new Set());
  const steps = getSteps()

  const isStepOptional = (step) => {
    //return step === 1;
  }

  const isStepSkipped = (step) => {
    return skipped.has(step);
  }

  const handleNext = () => {
    let newSkipped = skipped;
    if (isStepSkipped(activeStep)) {
      newSkipped = new Set(newSkipped.values())
      newSkipped.delete(activeStep)
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1)
    setSkipped(newSkipped)
  }

  const handleFinish = () => {
    setOpenPaymentDialog(true)
  }

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1)
  }

  const handleSkip = () => {
    if (!isStepOptional(activeStep)) {
      // You probably want to guard against something like this,
      // it should never occur unless someone's actively trying to break something.
      throw new Error("You can't skip a step that isn't optional.")
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1)
    setSkipped((prevSkipped) => {
      const newSkipped = new Set(prevSkipped.values())
      newSkipped.add(activeStep)
      return newSkipped
    })
  }

  const handleReset = () => {
    setActiveStep(0)
  }

  const handleRecurringPaymentChange = () => {
    setRecurringPayment(!recurringPayment)
  }
  const handlePaymentOptionChange = (event) => {
    setPaymentOptionValue(event.target.value)
  }
  const handleRecurringPaymentOptionChange = (event) => {
    setRecurringPaymentOptionValue(event.target.value)
  }
  const handleProjectChange = (event) => {
    setProjectName(event.target.value);
  }
  const handleChangeMultiple = (event) => {
    const { options } = event.target
    const value = []
    for (let i = 0, l = options.length; i < l; i += 1) {
      if (options[i].selected) {
        value.push(options[i].value)
      }
    }
    setProjectName(value)
  }
  const handleDeleteProject = (e: React.MouseEvent, value: string) => {
    e.preventDefault();
    setProjectName((current) => _without(current, value))
  }

  function getSteps() {
    return ['Donation', 'Recurring?', 'Payment']
  }

  function getStepContent(step) {
    switch (step) {
      case 0:
        return(
          <Grid container spacing={3}>
            <Grid item xs={12} lg={2}>
                <TextField
                  InputProps={{
                    startAdornment: <InputAdornment position="start">R</InputAdornment>,
                  }}
                  placeholder="how much?"
                  type="number"
                  variant="outlined"
                  fullWidth
                />
            </Grid>
            <Grid item xs={12} lg={12}>
              <FormControl className={classes.formControl} fullWidth style={{padding: 0}}>
                <InputLabel htmlFor="grouped-native-select">Select projects</InputLabel>
                <Select
                  id="project-select"
                  multiple
                  variant="outlined"
                  className={classes.multipleSelect}
                  fullWidth
                  value={projectName}
                  label="Select Projects"
                  onChange={handleProjectChange}
                  renderValue={(selected) => (
                    <div className={classes.chips}>
                      {selected.map((value) => (
                        <Chip
                          key={value}
                          label={value}
                          className={classes.chip}
                          deleteIcon={
                            <CancelIcon
                              onMouseDown={(event) => event.stopPropagation()}
                            />
                          }
                          onDelete={(e) => handleDeleteProject(e, value)}
                          variant="outlined"
                          clickable
                        />
                      ))}
                    </div>
                  )}
                  >
                  {projects.map((project) => (
                    <MenuItem key={project.name} value={project.name}>
                      <Checkbox checked={projectName.indexOf(project.name) > -1} />
                      <ListItemText primary={project.label} />
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} lg={12}>
              <TextField placeholder="Reference" fullWidth variant="outlined"/>
            </Grid>
          </Grid>
        )
      case 1:
        return(
          <Grid container xs={12}>
              <Grid item xs={12}>
                <FormControlLabel
                  control={
                    <Checkbox
                      onChange={handleRecurringPaymentChange}
                      name="recurring_payment"
                      color="primary"
                      checked={recurringPayment}
                    />
                  }
                  label="Recurring Payment?"
                />
              </Grid>
            <Collapse in={recurringPayment}>
              <FormControl component="fieldset">
                <RadioGroup aria-label="recurring_payment_options" name="recurring_payment_options" value={recurringPaymentOptionValue} onChange={handleRecurringPaymentOptionChange}>
                  {recurring_payment_periods.map(option => (
                    <FormControlLabel value={option.name} control={<Radio />} label={option.label} />
                  ))}
                </RadioGroup>
              </FormControl>
            </Collapse>
          </Grid>
        )
      case 2:
        return(
          <Grid container xs={12}>
            <FormControl component="fieldset">
              <RadioGroup aria-label="payment_options" name="payment_options" value={paymentOptionValue} onChange={handlePaymentOptionChange}>
                {payment_options.map(option => {
                  if(option.name === 'eft') {
                    let showingBankDetails = false
                    if(paymentOptionValue === 'eft') {showingBankDetails = true}
                    return (
                      <>
                      <FormControlLabel value={option.name} control={<Radio />} label={option.label} />
                      <Collapse in={showingBankDetails}>
                        <Card variant="outlined">
                          <CardContent>Banking details go here</CardContent>
                        </Card>
                      </Collapse>
                      </>
                    )
                  } else {
                    return(
                      <FormControlLabel value={option.name} control={<Radio />} label={option.label} />

                    )
                 }
                })}
              </RadioGroup>
            </FormControl>
          </Grid>
        )
      default:
        return 'Unknown step'
    }
  }

  return(
    <DonorMainLayout
       active_key="donate_food"
       breadcrumbs={[
          {name: 'donor_home'},
          {name: 'donations', label: "Donations", url: '/donor/donations'},
          {name: 'donate_money', label: "Donate Money", url: '/donor/donate/money'}
      ]}
    >

    <PageTitle title={"Donate Money"} />
    <Paper className={classes.root}>
      <div>
      <Stepper activeStep={activeStep} style={{padding: 0, marginBottom: 39}}>
        {steps.map((label, index) => {
          const stepProps = {};
          const labelProps = {};
          if (isStepOptional(index)) {
            labelProps.optional = <Typography variant="caption">Optional</Typography>;
          }
          if (isStepSkipped(index)) {
            stepProps.completed = false;
          }
          return (
            <Step key={label} {...stepProps}>
              <StepLabel {...labelProps}>{label}</StepLabel>
            </Step>
          );
        })}
      </Stepper>
      <div>
        {activeStep === steps.length ? (
          <div>
            <Typography className={classes.instructions}>
              All steps completed - you&apos;re finished
            </Typography>
            <Button onClick={handleReset} className={classes.button} variant="contained">
              Reset
            </Button>
          </div>
        ) : (
          <div>
            <Typography className={classes.instructions}>{getStepContent(activeStep)}</Typography>
            <div>
              <Button disabled={activeStep === 0} onClick={handleBack} className={classes.button}>
                Back
              </Button>
              {isStepOptional(activeStep) && (
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleSkip}
                  className={classes.button}
                >
                  Skip
                </Button>
              )}


                {activeStep === steps.length - 1 ?
                  <Button variant="contained" color="primary" onClick={handleFinish} className={classes.button}>Donate</Button> :
                  <Button variant="contained" color="primary" onClick={handleNext} className={classes.button}>Next</Button>
                }

            </div>
          </div>
        )}
      </div>
    </div>

    <Dialog
        open={openPaymentDialog}
        onClose={handleClosePaymentDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Payment"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            It would be nice to process the payment in a dialog and then return
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClosePaymentDialog} color="primary">
            Cancel
          </Button>
        </DialogActions>
      </Dialog>

    </Paper>

    </ DonorMainLayout>
  )
}
