import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import ReactTimeout from 'react-timeout'
import { Button } from 'components/layout/Button'
import { Formik, Form } from 'formik'
import { FormikInputField } from 'components/form/InputField'
import { FormikGeneralFormErrors } from 'components/form/GeneralFormErrors'
import FormikOnChange from 'components/form/FormikAutoSave'


const AdminTableFilter = ( { handleSubmit, is_loading, label, placeholder, updateOnChange, renderFilter, initial_values, style } ) => {
  const onFilterChanged = ( filter_values, formik_props ) => {
    updateOnChange( filter_values )
  }

  return (
    <>

      <Formik
        initialValues={ initial_values }
        onSubmit={ onFilterChanged }
        enableReinitialize={ true }
      >
        { formik_props => {
          const { values } = formik_props
          return (
            <Form>
              <FormikOnChange onChange={ ( values ) => onFilterChanged( values, formik_props ) } />
              <FormikGeneralFormErrors render={ ( msg ) => msg } />

              { renderFilter && renderFilter( formik_props ) }
              { !renderFilter &&
                <FormikInputField
                  name="any_field"
                  label={ label || "Search..." }
                  placeholder={ placeholder || "Search..." }
                  show_search_icon={ true }
                  fullWidth
                />
              }

              { !updateOnChange &&
                <Button type="submit">Apply</Button>
              }
            </Form>
          )
        } }
      </Formik>
    </>
  )
}

function mapStateToProps( state, props ) {
  const { onSubmit, label, updateOnChange, filter } = props
  return {
    onSubmit,
    label,
    updateOnChange,
    initial_values: filter || {}
  }
}
export default withRouter( connect( mapStateToProps )( ReactTimeout( AdminTableFilter ) ) )
