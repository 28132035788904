import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useParams } from 'react-router-dom'
import { get } from 'lodash'
import { adminUOMList } from './actions/admin_uom'
import { Separator } from 'components/layout/Separator'
import { Formik, Form, Field } from 'formik'
import * as Yup from 'yup'
import {showSuccess, showError} from 'actions/Error'
import { handleSubmitResult } from 'actions/form'
import AdminCommonFormLayout from '../layout/AdminCommonFormLayout'
import { AdminUOMForm, validationSchema} from './form/AdminUOMForm'

export default function AdminUOM() {
    const dispatch = useDispatch()
    const history = useHistory()
    const { uom_id } = useParams()
    const uom = useSelector(() => adminUOMList.getObject(uom_id))
    const initial_values = uom
    const is_loading = useSelector(() => adminUOMList.isLoading()) || (uom_id && !get(uom, "id"))
    const is_busy = useSelector(() => adminUOMList.getIsSavingObject())
    const title = `${get(uom, ["name"], 'New UOM')}`

    useEffect(() => {
        dispatch(adminUOMList.ensureObjectLoaded(uom_id))
    },[])

    const onSave = (values, formik_funcs) => {
        const on_ok = function(json) {
            dispatch(adminUOMList.invalidateList())
            showSuccess("Saved", "UOM saved")

            if ( ! uom_id ) {
                history.push(`/admin/uom/${json.id}`)
            }
        }
        if ( uom_id ) {
            values.id = uom_id
            return dispatch(adminUOMList.saveObject(values)).then((res) => handleSubmitResult({res, formik_funcs, on_ok}))
        } else {
            return dispatch(adminUOMList.saveNewObject(values)).then((res) => handleSubmitResult({res, formik_funcs, on_ok}))
        }
    }

    const getCellValue = (header_key, item, index) => {
        switch( header_key ) {
            default:
                return undefined
        }
    }

    return (
        <AdminCommonFormLayout
            breadcrumbs={[
                {name: 'admin_home'},
                {name: 'uoms', label: 'UOMs', url: '/admin/uoms'},
                {name: 'uom', label: get(uom, ["display_name"], 'New Unit of Measurement'), url: `/admin/uom/${uom_id}`}
            ]}
            is_busy={is_busy}
            is_loading={is_loading}
            title={title}
            initial_values={initial_values}
            validationSchema={validationSchema}
            onSave={onSave}
        >
          {
              ({formik_props}) => <AdminUOMForm formik_props={formik_props} />
          }
        </AdminCommonFormLayout>
    )
}
