import React, { Component, useState } from 'react'
import { BaseButton } from './BaseButton'

export const Button = ({ onClick, color, variant, children, ...props }) => {

    //extra_css = extra_css || null

    return (
        <BaseButton onClick={onClick} color={props.color} variant={props.variant} {...props}>
          {children}
        </BaseButton>
    )

}
