import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import AdminCommonListLayout from '../layout/AdminCommonListLayout'
import { adminNotificationModelList } from './actions/admin_notification_model'
import { FormikGeneralFormErrors } from 'components/form/GeneralFormErrors'
import { FormikSwitchField } from 'components/form/SwitchField'
import { Formik, Form, useField } from 'formik'
import { handleSubmitResult } from 'actions/form'
import { showSuccess, showError } from 'actions/Error'
import { ShortId } from 'components/ShortId'
import { capitalize, get } from 'lodash'

export default function AdminNotificationModels() {

  const dispatch = useDispatch()
  const history = useHistory()
  const notification_models = useSelector( () => adminNotificationModelList.getVisibleObjects() )
  const is_loading = useSelector( () => adminNotificationModelList.isLoading() )
  const [ notification_model_id, set_notification_model_id ] = useState()

  useEffect( () => {
    dispatch( adminNotificationModelList.invalidateList() )
    dispatch( adminNotificationModelList.fetchListIfNeeded() )
  }, [] )

  const onEditNotificationModel = ( notification_model_id ) => {
    history.push( {
      pathname: '/admin/notification_model/' + notification_model_id
    } )
  }

  const onSave = ( values, formik_funcs ) => {
    const on_ok = function( json ) {
      dispatch( adminNotificationModelList.invalidateList() )
      showSuccess( "Saved" )
    }
    if ( notification_model_id ) {
      values.id = notification_model_id
      set_notification_model_id( null )
      return dispatch( adminNotificationModelList.saveObject( values ) ).then( ( res ) => handleSubmitResult( { res, formik_funcs, on_ok } ) )
    } else {
      return false
    }
  }

  const renderForm = ( item ) => {
    const initial_values = { ...item }
    return (
      <Formik
        initialValues={ initial_values }
        onSubmit={ ( values, formik_funcs ) => { onSave( values, formik_funcs ) } }
      >
        { formik_props => {
          const { values } = formik_props
          React.useEffect( () => {
            if ( values !== initial_values ) {
              formik_props.submitForm( values )
            }
          }, [ values ] )

          const handleSwitchChange = ( event ) => {
            set_notification_model_id( item.id )
            formik_props.setFieldValue( 'create_notification', event.target.checked )
            formik_props.submitForm( values )
          }

          const handleSwitchBlur = () => {
            set_notification_model_id( item.id )
            if ( !formik_props.values.create_notification ) {
              formik_props.submitForm( values )
            }
          }

          return (
            <Form>
              <FormikGeneralFormErrors render={ ( msg ) => msg } />
              <FormikSwitchField
                name="create_notification"
                formik_props={ formik_props }
                color="primary"
                onChange={ handleSwitchChange }
                onBlur={ handleSwitchBlur }
              />
            </Form>
          )
        } }
      </Formik>

    )
  }

  const columns = [
    // {
    //   field: 'id',
    //   title: 'ID',
    //   sort: ( direction ) => onUpdateListOrdering( direction === 'asc' ? 'id' : '-id' ),
    // },
    {
      field: 'name',
      title: 'Name',
      render: ( item ) => capitalize(get( item, "name" )),
      sort: ( direction ) => onUpdateListOrdering( direction === 'asc' ? 'name' : '-name' ),
    },
    {
      field: 'create_notification',
      title: 'Notification',
      render: ( item ) => renderForm( item ),
      align: 'right',
      sort: ( direction ) => onUpdateListOrdering( direction === 'asc' ? 'create_notification' : '-create_notification' )
    },
  ]

  const onUpdateListOrdering = ( field ) => {
    dispatch( adminNotificationModelList.updateListOrdering( field ) )
  }

  return (
    <AdminCommonListLayout
      active_key="notification_models"
      breadcrumbs={ [
        { name: 'admin_home' },
        { name: 'notification_models', label: "Notification Model", url: '/admin/notification_models' }
      ] }
      title={ "Notification Models" }
      onEditRow={ onEditNotificationModel }
      is_loading={ is_loading }
      columns={ columns }
      item_list={ adminNotificationModelList }
      enableAnyFieldFilter={ true }
    />
  )
}
