import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useParams } from 'react-router-dom'
import { get } from 'lodash'
import { adminMealtimeList } from './actions/admin_mealtime'
import {showSuccess, showError} from 'actions/Error'
import { handleSubmitResult } from 'actions/form'
import AdminCommonFormLayout from '../layout/AdminCommonFormLayout'
import AdminCommonForm from '../layout/AdminCommonForm'
import { AdminMealtimeForm, validationSchema} from './form/AdminMealtimeForm'

export default function AdminMealtime({ in_modal = false, onClose = () => { }, passed_mealtime_id = null, company_id = null }) {
    const dispatch = useDispatch()
    const history = useHistory()
    const { mealtime_id: params_mealtime_id } = useParams()
    const mealtime_id = passed_mealtime_id || params_mealtime_id
    const mealtime = useSelector(() => adminMealtimeList.getObject(mealtime_id))
    const initial_values = mealtime
    const is_loading = useSelector(() => adminMealtimeList.isLoading()) || (mealtime_id && !get(mealtime, "id"))
    const is_busy = useSelector(() => adminMealtimeList.getIsSavingObject())
    const title = `${get(mealtime, ["meal_name"], 'New Mealtime')}`

    useEffect(() => {
        dispatch(adminMealtimeList.ensureObjectLoaded(mealtime_id))
    },[])

    const onSave = (values, formik_funcs) => {
        if(company_id) {
            values.company = company_id
          }
        const on_ok = function(json) {
            dispatch(adminMealtimeList.invalidateList())
            showSuccess("Saved", "Mealtime saved")

            if (!mealtime_id) {
                if (in_modal) {
                    onClose()
                } else {
                    history.push(`/admin/mealtime/${json.id}`)
                }
            } else if (in_modal) {
                onClose()
            }
        }
        if ( mealtime_id ) {
            values.id = mealtime_id
            return dispatch(adminMealtimeList.saveObject(values)).then((res) => handleSubmitResult({res, formik_funcs, on_ok}))
        } else {
            return dispatch(adminMealtimeList.saveNewObject(values)).then((res) => handleSubmitResult({res, formik_funcs, on_ok}))
        }
    }

    if(in_modal) {
      return (
        <AdminCommonForm
        is_loading={is_loading}
        is_busy={is_busy}
        initial_values={initial_values}
        inModal={true}
        onSave={onSave}>
        {
          ({formik_props}) => {
            return <AdminMealtimeForm formik_props={formik_props} company_id={company_id} in_modal={ in_modal } />
          }
        }
      </AdminCommonForm>
      )
    }

    return (
        <AdminCommonFormLayout
            breadcrumbs={[
                {name: 'admin_home'},
                {name: 'mealtimes', label: 'Mealtimes', url: '/admin/mealtimes'},
                {name: 'mealtime', label: get(mealtime, ["name"], 'New Mealtime'), url: `/admin/mealtime/${mealtime_id ? mealtime_id : null}`}
            ]}
            is_busy={is_busy}
            is_loading={is_loading}
            title={title}
            initial_values={initial_values}
            validationSchema={validationSchema}
            onSave={onSave}
        >
          {
              ({formik_props}) => <AdminMealtimeForm formik_props={formik_props} />
          }
        </AdminCommonFormLayout>
    )
}
