import React from 'react'
import { reverse, clone, size, map, capitalize } from 'lodash'
import { makeStyles, Typography, Chip } from '@material-ui/core'

const useStyles = makeStyles((muiTheme) => ({
    crumbRow: {
        display: "flex"
    },
}))

export function HierarchyCrumbs({ crumbs, extra_crumb, empty_label, top_level, font_size, in_chip }) {

    const classes = useStyles()
    const ordered_crumbs = reverse(clone(crumbs))
    const num_crumbs = size(crumbs)
    font_size = font_size ? font_size : "caption"
    return (
        <div className={classes.crumbRow}>
            <Typography variant={font_size}>
                { size(ordered_crumbs) === 0 && <span>{empty_label}</span> }
                { top_level ? in_chip ?
                    <Chip
                        label={ ordered_crumbs[0] }
                        size="small"
                        variant="outlined"
                    /> : ordered_crumbs[0] :
                    <>
                        { map(ordered_crumbs, (crumb, index) => (
                            <>
                                {capitalize(crumb)}
                                { (index < num_crumbs-1) && <> / </> }
                            </>
                        ))}
                        { extra_crumb && <><> / </>{capitalize(extra_crumb)}</> }
                    </>
                }
            </Typography>
        </div>
    )
}
