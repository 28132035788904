import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useHistory } from 'react-router-dom'
import { forEach, head, get, map, keys, split, size } from 'lodash'
import { contactDonorParcelProductList } from 'actions/contact/donor/parcel_product'
import { contactParcelList } from './actions/contact_parcel'
import FormCard from 'components/layout/FormCard'
import { FormikInputField } from 'components/form/InputField'
import { FormikProductAutoCompleteDropdown } from 'admin/components/products/form/ProductAutoCompleteDropdown'
import { FormikDonorAutoCompleteDropdown } from 'admin/components/donors/form/DonorAutoCompleteDropdown'
import { FormikDateTimePicker } from 'components/form/DatetimePicker'
import { Formik, Form } from 'formik'
import * as Yup from 'yup'
import {showSuccess, showError} from 'actions/Error'
import { Button, Typography, Grid, FormHelperText, InputAdornment } from '@material-ui/core'
import { handleSubmitResult } from 'actions/form'
import { Modal, LoadingModal } from 'components/layout/Modal'

const yup_shape = {
    'product': Yup.string().required("Required"),
    'provisional_quantity': Yup.number(),
    'actual_quantity': Yup.number().nullable(),
    'total_weight_kg': Yup.number()
}

const validationSchema = Yup.object().shape(yup_shape)

export default function DonationParcelProduct({parcel_id, parcel_product_id, onSaved, onSubmit, onCancel}) {
    const dispatch = useDispatch()
    const history = useHistory()
    const parcel_product = contactDonorParcelProductList.getObject(parcel_product_id)
    const parcel = useSelector(() => contactParcelList.getObject(parcel_id))
    const is_loading = contactDonorParcelProductList.isLoading() || (parcel_product_id && !get(parcel_product, "id"))
    const is_busy = contactDonorParcelProductList.getIsSavingObject() || contactDonorParcelProductList.getIsUpdatingObject(parcel_id)
    const initial_values = Object.assign({}, parcel_product)
    const title = `${get(parcel_product, ["product_name"], 'Add Product to Order')}`
    const is_new = !parcel_product.product
    const [productWeight, setProductWeight] = useState(null)

    useEffect(() => {
        dispatch(contactDonorParcelProductList.ensureObjectLoaded(parcel_product_id))
        dispatch(contactParcelList.ensureObjectLoaded(parcel_id))
    },[])

    const onSelectProduct = (product_weight) => {
        setProductWeight(product_weight)
    }

    const onSave = (values, formik_funcs) => {
        const on_ok = function(json) {
            dispatch(contactDonorParcelProductList.invalidateList())
            showSuccess("Saved", "Product saved")
            if ( onSaved ) {
                onSaved(json)
            } else if ( ! parcel_product_id ) {
                history.push(`/donor/parcel/${parcel_id}/product/${json.id}`)
            }
        }
        values.parcel = parcel_id
        if ( parcel_product_id ) {
            values.id = parcel_product_id
            return dispatch(contactDonorParcelProductList.saveObject(values)).then((res) => handleSubmitResult({res, formik_funcs, on_ok}))
        } else {
            return dispatch(contactDonorParcelProductList.saveNewObject(values)).then((res) => handleSubmitResult({res, formik_funcs, on_ok}))
        }
    }

    const getCellValue = (header_key, item, index) => {
        switch( header_key ) {
            default:
                return undefined
        }
    }

    const renderForm = (formik_props, is_new) => {
        const product_qty = get(formik_props, ['values', 'actual_quantity']) ? get(formik_props, ['values', 'actual_quantity']) : get(formik_props, ['values', 'provisional_quantity'])
        let total_weight_kg = product_qty * productWeight
        if(isNaN(total_weight_kg)) {
            total_weight_kg = null
        }
        return (
            <>
                { is_new &&
                    <FormikProductAutoCompleteDropdown
                        name="product"
                        label="Product"
                        helperText={"Click into the input to select a product"}
                        onSelectProduct={onSelectProduct}
                        formik_props={formik_props}
                    />
                }

                <Grid container spacing={2}>

                    <Grid item xs={6} lg={6}>

                        <FormikDateTimePicker
                            label='Best before date'
                            helperText="Important information - provide if you can"
                            name='best_before_date'
                            include={false}
                            formik_props={formik_props}
                            include_time={false}
                        />

                    </Grid>

                    <Grid item xs={6} lg={6}>

                        <FormikDateTimePicker
                            label='Expiry date'
                            helperText="Important information - provide if you can"
                            name='expiry_date'
                            include={false}
                            formik_props={formik_props}
                            include_time={false}
                        />

                    </Grid>

                    <Grid item xs={6} lg={6}>

                        <FormikInputField
                            name="provisional_quantity"
                            label="Quantity"
                            formik_props={formik_props}
                        />
                        <FormHelperText>
                            How many of this item are you donating?
                        </FormHelperText>

                    </Grid>

                    <Grid item xs={6} lg={6}>

                        <FormikInputField
                            name="total_weight_kg"
                            value={total_weight_kg}
                            label="Total Weight"
                            formik_props={formik_props}
                            InputProps={{
                              endAdornment: <InputAdornment position="end">kg</InputAdornment>
                            }}
                        />
                        <FormHelperText>
                            Leave blank to autocalculate
                        </FormHelperText>

                    </Grid>

                </Grid>

            </>
        )
    }

    return (

        <>

          { is_loading ? <LoadingModal /> :

            <Formik
                initialValues={initial_values}
                onSubmit={onSave}
                enableReinitialize={true}
                validationSchema={validationSchema}
            >
                {formik_props => {
                    const { values } = formik_props
                    return (
                        <Form>
                            <FormCard
                                isSubmitting={formik_props.isSubmitting}
                                title={title}
                            >
                                { renderForm(formik_props, is_new) }
                            </FormCard>
                        </Form>
                    )}
                }
            </Formik>

          }

        </>

    )

}
