import React from 'react'
import { get, split } from 'lodash'
import FormError from './FormError'
import FormWarning from './FormWarning'
import { TextField } from 'formik-material-ui'
import { Formik, Form, FieldArray, Field, useField } from 'formik'
import { unpackFormikPropsForField } from 'actions/form'
import ReactDOM from 'react-dom';
import DraftEditorField from './DraftEditorField'

export const FormikEditorField = ({ label, placeholder, fullWidth=true, name, formik_props, touch_on_edit, ...props }) => {

    const { errors, touched } = unpackFormikPropsForField({formik_props, name})

    const setTouched = () => {
        formik_props.setFieldTouched(name)
    }

    return (
        <Field
          component={DraftEditorField}
          name={name}
          label={label || placeholder}
          placeholder={placeholder || label}
        />
    )
}
