import * as Yup from 'yup'
import { FormikInputField } from 'components/form/InputField'
import FormikKpiCategoryDropdownField from 'admin/components/kpis/form/KpiCategoryDropdown'

export const validationSchema = Yup.object().shape({
    'name': Yup.string().required("Required"),
})

const AdminChecklistForm = ({ formik_props }) => {

    return (
        <>
            <FormikKpiCategoryDropdownField
                name="category"
                formik_props={formik_props}
            />

            <FormikInputField
                name="name"
                label="Name"
                formik_props={formik_props}
            />
            <FormikInputField
                name="description"
                label="Description"
                multiline
                rows={5}
                formik_props={formik_props}
            />
        </>
    )
}

export default AdminChecklistForm
