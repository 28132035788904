import { createTheme }  from '@material-ui/core/styles'
const donorTheme = createTheme({
  overrides: {
    MuiCard: {
      root: {
        marginBottom: '10px'
      },
      elevation: 0
    },
  },
  palette: {
    primary: { main: '#1565c0' },
    info: { main: '#2196f3' },
    success: { main: '#00AB55' },
    warning: { main: '#FFC107' },
    error: { main: '#FF4842' },
    grey: { main: '#CFD8DD'}
  }
})
export default donorTheme

export const PARCEL_STATUS_COLOURS = {
    ready_to_go: "#1565c0",
    picking: "#eceff1",
    in_transit: "#bbdefb",
    complete: "#00AB55",
    cancelled: "#FF4842",
    pending: "#1890FF",
}

export const TICKET_STATUS_COLOURS = {
    new: '#ffeb3b',
    unsolved: '#d50000',
    pending: '#64b5f6',
    solved: '#32cb00',
    suspended: '#ec407a',
    deleted: '#cccccc',
}
