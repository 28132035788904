import React from 'react'
import { withStyles, makeStyles } from '@material-ui/core/styles'
import MuiAccordion from '@material-ui/core/Accordion'
import MuiAccordionSummary from '@material-ui/core/AccordionSummary'
import MuiAccordionDetails from '@material-ui/core/AccordionDetails'
import { Typography, Divider } from '@material-ui/core'

const useStyles = makeStyles({
  root: {
    width: '100%'
  }
})

const Accordion = withStyles({
  root: {
    border: 'none',
    boxShadow: 'none',
    '&:not(:last-child)': {
      borderBottom: 0,
    },
    '&:before': {
      display: 'none',
    },
    '&$expanded': {
      margin: 'auto',
    },
  },
  expanded: {},
})(MuiAccordion)

const AccordionSummary = withStyles({
  root: {
    width: '100%',
    padding: 0,
    borderBottom: '1px solid #000000',
    background: '#fafafa',
    paddingLeft: 15,
    '&:hover': {
      background: '#efefef',
    }
  },
  expanded: {
    background: '#ffffff',
    borderBottom: 'none',
    '&:hover': {
      background: 'transparent',
    }
  }
})(MuiAccordionSummary)

const AccordionDetails = withStyles((theme) => ({
  root: {
    padding: 15,
  },
}))(MuiAccordionDetails);

export default function CustomizedAccordions({panels, defaultPanel}) {
  const classes = useStyles()
  const [expanded, setExpanded] = React.useState(defaultPanel);

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  }

  const getPanel = (panel, i) => {
    const panelID = panel.name + i
    return(
      <Accordion square expanded={expanded === panelID} onChange={handleChange(panelID)}>
        <AccordionSummary aria-controls={panelID + "-content"} id={panelID + "-header"}>
          <Typography variant="h6">{panel.title}</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <div className={classes.root}>{panel.content}</div>
        </AccordionDetails>
      </Accordion>
    )
  }

  return (
    <div>
      {panels.map((panel, i) => (getPanel(panel, i)))}
    </div>
  )
}
