import { ItemList } from 'orm'

class DropdownOptions extends ItemList {
    constructor(dropdown_name) {
        super(`contact_options__${dropdown_name}`)
        this.url = `contact/dropdown_options/${dropdown_name}`
    }

    getEntityKey() {
        return this.url
    }

}

export const contactParcelStates = new DropdownOptions('parcel_states')
export const contactParcelCompanyTypes = new DropdownOptions('parcel_company_types')
export const contactEmployeeRoles = new DropdownOptions('employee_roles')
export const contactAddressTypes = new DropdownOptions('address_types')
export const contactContactRoles = new DropdownOptions('contact_roles')
//export const contactDriverStates = new DropdownOptions('driver_states')
export const contactCompanyReportNames = new DropdownOptions('company_report_names')
export const contactCompanyReportFrequencies = new DropdownOptions('company_report_frequencies')
