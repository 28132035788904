import { ItemList } from 'orm'
import { head, get, map, size } from 'lodash'

class ProductCategoryLeafNodesList extends ItemList {

	getEntityKey() {
		return "admin/product_category_leaf_node"
	}

	getAsOptions() {
		const items = this.getVisibleObjects()
		return map( items, function( item ) {
			return {
				label: `${ item.name } (${ item.parent_category_name })`,
				value: item.id
			}
		} )
	}
}

export const adminProductCategoryLeafNodesList = new ProductCategoryLeafNodesList( "admin_product_category_leaf_nodes__default" )
export const adminProductCategoryLeafNodeAutoCompleteList = new ProductCategoryLeafNodesList( "admin_product_category_leaf_node_auto_complete__default" )

