import React, { Component } from 'react'
import { CheckboxWithLabel } from 'formik-material-ui'
import { FormikInputField } from './InputField'
import { unpackFormikPropsForField } from 'actions/form'

export const FormikCheckboxField = ({ label, name, formik_props, ...props }) => {
    const { errors, touched } = unpackFormikPropsForField({formik_props, name})

    const setTouched = () => {
        formik_props.setFieldTouched(name)
    }

    return <FormikInputField type="checkbox"
                             component={CheckboxWithLabel}
                             Label={{label: label}}
                             helperText={touched ? errors : ""}
                             error={Boolean(errors)}
                             name={name}
                             color="primary"
                             formik_props={formik_props}
                             {...props}
           />
}
