import { ItemList } from 'orm'
import { values, get, map, head, filter } from 'lodash'

class MarketingMoneyDonationList extends ItemList {
    getEntityKey() {
        return "marketing/money_donation"
    }

}
export const marketingMoneyDonationList = new MarketingMoneyDonationList("marketing_money_donation__default")

class MarketingMoneyDonationPayfastOnsitePaymentList extends ItemList {
    getEntityKey() {
        return 'marketing/money_donation/payfast_onsite_payment'
    }

    getUniquePaymentIdentifier(payment, on_done) {
        return this.saveNewObject(payment, on_done)
    }
}

export const marketingMoneyDonationPayfastOnsitePaymentList = new MarketingMoneyDonationPayfastOnsitePaymentList('marketing/money_donation/payfast_onsite_payment')
