import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import { map, get, capitalize } from 'lodash'
import { withStyles } from '@material-ui/core/styles'
import { LayoutContext } from './Root'

export const getAddressOptions = (addresses) => {
    return map(addresses, function(x) {
        return {
            value: x.id,
            label: <InlineAddress address={x} />
        }
    })
}

export const InlineAddress = ({address}) => {
    return (
        <div>
            <span>{ capitalize(get(address, "address_type")) }</span>:&nbsp;
            { get(address, "formatted_address") ?
                <span>{ get(address, "formatted_address") }</span> :
                <>
                    <span>{ get(address, "street1") }</span>&nbsp;
                    <span>{ get(address, "street2") }</span>&nbsp;
                    <span>{ get(address, "city_name") }</span>&nbsp;
                    <span>{ get(address, "province_name") }</span>
                </>
            }
        </div>
    )
}
