import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { get, map, capitalize, reduce, join, reverse, tail, clone, startCase } from 'lodash'
import Timestamp from 'components/Timestamp'
import Alert from '@material-ui/lab/Alert'
import { adminDonorParcelProductList } from './actions/admin_donor_parcel_product'
import { adminProductList } from 'admin/components/products/actions/admin_product'
import { adminParcelList } from './actions/admin_parcel'
import { CommonTable } from 'components/CommonTable'
import AdminDonorParcelProduct from './AdminDonorParcelProduct'
import { HierarchyCrumbs } from 'components/layout/HierarchyCrumbs'
import { Hidden } from '@material-ui/core'
import { Modal } from 'components/layout/Modal'
import NumberFormat from 'react-number-format'
import { AddButton } from 'components/form/Buttons'
import Loading from 'components/Loading'

const NEW_PARCEL_PRODUCT_ID = '__new__'

export default function AdminDonorParcelProducts( { parcel_id, updateCartTotalWeight } ) {
	const dispatch = useDispatch()
	const initial_filter_values = {}
	const parcel_products = useSelector( () => adminDonorParcelProductList.getVisibleObjects() )
	const product_ids = map( parcel_products, ( parcel_product ) => parcel_product.id )
	const parcel = useSelector( () => adminParcelList.getObject( parcel_id ) )
	const is_loading = useSelector( () => adminDonorParcelProductList.isLoading() )
	const parcel_product_filter = useSelector( () => adminDonorParcelProductList.getFilter() )
	const not_specified = <span style={ { color: 'red' } }>not specified</span>
	const [ editingParcelProductID, setEditingParcelProductID ] = useState( null )
	const cart_total_weight_kg = reduce( parcel_products, function( total_weight, parcel_product ) {
		return total_weight + parcel_product.total_weight_kg
	}, 0 )

	useEffect( () => {
		if ( parcel_id ) {
			if ( parcel_id != parcel_product_filter.parcel ) {
				dispatch( adminDonorParcelProductList.updateListFilter( { parcel: parcel_id } ) )
			}
			dispatch( adminDonorParcelProductList.fetchListIfNeeded() )
			dispatch( adminProductList.ensureObjectsLoaded( product_ids ) )
			dispatch( adminParcelList.ensureObjectLoaded( parcel_id ) )
			updateCartTotalWeight( cart_total_weight_kg )
		}
	}, [ cart_total_weight_kg, parcel_products, parcel_id ] )

	const onEditParcelProduct = ( parcel_product_id ) => {
		setEditingParcelProductID( parcel_product_id )
	}

	const onAddParcelProduct = () => {
		setEditingParcelProductID( NEW_PARCEL_PRODUCT_ID )
	}

	const onStopEditingParcelProduct = () => {
		dispatch( adminDonorParcelProductList.invalidateList() )
		dispatch( adminParcelList.invalidateObject( parcel_id ) )
		setEditingParcelProductID( null )
	}

	const renderEditParcelProduct = ( parcel_product_id ) => {
		return (
			<Modal
				onClose={ onStopEditingParcelProduct }
				fullWidth={ true }
				width='lg'
				title={ parcel_product_id != '__new__' ? "Edit Donor Parcel Product" : "Add Donor Parcel Product" }
				noPadding
			>
				<AdminDonorParcelProduct
					parcel_id={ parcel_id }
					parcel_product_id={ parcel_product_id === NEW_PARCEL_PRODUCT_ID ? null : parcel_product_id }
					onSaved={ onStopEditingParcelProduct }
					onCancel={ onStopEditingParcelProduct }
				/>
			</Modal>
		)
	}

	const onUpdateListOrdering = ( field ) => {
		dispatch( adminDonorParcelProductList.updateListOrdering( field ) )
	}

	const columns = [
		{
			field: 'product_category_name',
			title: 'Product',
			visible: true,
			render: ( item ) => startCase( get( item, "product_category_crumbs", [ 0 ] )[ 0 ] ),
			sort: ( direction ) => onUpdateListOrdering( direction === 'asc' ? 'product_category_name' : '-product_category_name' ),
		},
		{
			field: 'product_name',
			title: 'Old Product Name',
			visible: false,
			render: ( item ) => get( item, "product_name" ),
			sort: ( direction ) => onUpdateListOrdering( direction === 'asc' ? 'product' : '-product' ),
		},
		{
			field: 'product_category_crumbs',
			title: 'Category',
			render: ( item ) => join( reverse( tail( clone( get( item, "product_category_crumbs" ) ) ) ), ' / ' ),
			sort: ( direction ) => onUpdateListOrdering( direction === 'asc' ? 'product__product_category__name' : '-product__product_category__name' ),
		},
		{
			field: 'best_before_date',
			title: 'BB',
			visible: true,
			render: ( item ) => <Timestamp value={ item.best_before_date } format='date' />,
			sort: ( direction ) => onUpdateListOrdering( direction === 'asc' ? 'best_before_date' : '-best_before_date' ),
		},
		{
			field: 'expiry_date',
			title: 'Expiry',
			visible: true,
			render: ( item ) => <Timestamp value={ item.expiry_date } format='date' />,
			sort: ( direction ) => onUpdateListOrdering( direction === 'asc' ? 'expiry_date' : '-expiry_date' ),
		},
		{
			field: 'product_weight_kg',
			title: 'Item Weight',
			visible: true,
			render: ( item ) => <NumberFormat value={ get( item, [ "product_weight_kg" ] ) } suffix={ "kg" } displayType={ 'text' } thousandSeparator={ true } decimalScale={ 2 } />,
			sort: ( direction ) => onUpdateListOrdering( direction === 'asc' ? 'weight_kg' : '-weight_kg' ),
		},
		{
			field: 'provisional_quantity',
			title: 'Prov Qty',
			align: 'right',
			visible: true,
			sort: ( direction ) => onUpdateListOrdering( direction === 'asc' ? 'provisional_quantity' : '-provisional_quantity' )
		},
		{
			field: 'actual_quantity',
			title: 'Actual Qty',
			align: 'right',
			visible: true,
			sort: ( direction ) => onUpdateListOrdering( direction === 'asc' ? 'actual_quantity' : '-actual_quantity' )
		},
		{
			field: 'total_provisional_weight_kg',
			title: 'Total Prov Weight',
			align: 'right',
			visible: true,
			render: ( item ) => <NumberFormat value={ get( item, [ "total_provisional_weight_kg" ] ) } suffix={ "kg" } displayType={ 'text' } thousandSeparator={ true } decimalScale={ 2 } />,
			sort: ( direction ) => onUpdateListOrdering( direction === 'asc' ? 'total_provisional_weight_kg' : '-total_provisional_weight_kg' ),
		},
		{
			field: 'total_weight_kg',
			title: 'Total Actual Weight',
			align: 'right',
			visible: true,
			render: ( item ) => <NumberFormat value={ get( item, [ "total_weight_kg" ] ) } suffix={ "kg" } displayType={ 'text' } thousandSeparator={ true } decimalScale={ 2 } />,
			sort: ( direction ) => onUpdateListOrdering( direction === 'asc' ? 'total_weight_kg' : '-total_weight_kg' ),
		},
	]

	const columnsMobile = [
		{
			field: 'product_category_name',
			title: 'Product',
			visible: true,
			render: ( item ) => get( item, "product_category_crumbs", [ 0 ] )[ 0 ],
			sort: ( direction ) => onUpdateListOrdering( direction === 'asc' ? 'product_category_name' : '-product_category_name' ),
		},
		{
			field: 'product_name',
			title: 'Old Product Name',
			visible: false,
			render: ( item ) => get( item, "product_name" ),
			sort: ( direction ) => onUpdateListOrdering( direction === 'asc' ? 'product' : '-product' ),
		},
		{
			field: 'product_category_crumbs', title: 'Category',
			render: ( item ) => <>Category: { get( item, "product_category_crumbs", [] )[ get( item, "product_category_crumbs", [] ).length - 1 ] }<br /></>,
			sort: ( direction ) => onUpdateListOrdering( direction === 'asc' ? 'product__product_category__name' : '-product__product_category__name' ),
		},
		{
			field: 'original_from_company_name',
			title: 'Donor',
			render: ( item ) => <>Donor: { get( item, "original_from_company_name" ) ? capitalize( get( item, "original_from_company_name" ) ) : not_specified }<br /></>,
			sort: ( direction ) => onUpdateListOrdering( direction === 'asc' ? 'original_from_company_name' : '-original_from_company_name' ),
		},
		{
			field: 'best_before_date',
			title: 'Best Before',
			render: ( item ) => <>Best Before: { item.best_before_date ? <Timestamp value={ item.best_before_date } format='date' /> : not_specified }<br /></>,
			sort: ( direction ) => onUpdateListOrdering( direction === 'asc' ? 'best_before_date' : '-best_before_date' ),
		},
		{
			field: 'expiry_date',
			title: 'Expiry',
			render: ( item ) => <>Expiry: { item.expiry_date ? <Timestamp value={ item.expiry_date } format='date' /> : not_specified }<br /></>,
			sort: ( direction ) => onUpdateListOrdering( direction === 'asc' ? 'expiry_date' : '-expiry_date' ),
		},
		{
			field: 'product_weight_kg',
			title: 'Item Weight',
			render: ( item ) => <>Item Weight: <NumberFormat value={ get( item, [ "product_weight_kg" ] ) } suffix={ "kg" } displayType={ 'text' } thousandSeparator={ true } decimalScale={ 2 } /><br /></>,
			sort: ( direction ) => onUpdateListOrdering( direction === 'asc' ? 'weight_kg' : '-weight_kg' ),
		},
		{
			field: 'provisional_quantity',
			title: 'Provisional Quantity',
			render: ( item ) => <>Provisional Quantity: { item.provisional_quantity }<br /></>,
			sort: ( direction ) => onUpdateListOrdering( direction === 'asc' ? 'provisional_quantity' : '-provisional_quantity' ),
		},
		{
			field: 'total_provisional_weight_kg',
			title: 'Total Provisional Weight',
			visible: true,
			render: ( item ) => <>Total Provisional Weight: <NumberFormat value={ get( item, [ "total_provisional_weight_kg" ] ) } suffix={ "kg" } displayType={ 'text' } thousandSeparator={ true } decimalScale={ 2 } /><br /></>,
			sort: ( direction ) => onUpdateListOrdering( direction === 'asc' ? 'total_provisional_weight_kg' : '-total_provisional_weight_kg' ),
		},
		{
			field: 'actual_quantity',
			title: 'Quantity',
			render: ( item ) => <>Actual Quantity: { item.actual_quantity }<br /></>,
			sort: ( direction ) => onUpdateListOrdering( direction === 'asc' ? 'actual_quantity' : '-actual_quantity' ),
		},
		{
			field: 'total_weight_kg',
			title: 'Total Actual Weight',
			render: ( item ) => <>Total Actual Weight: { item.total_weight_kg != 0 ? <>{ item.total_weight_kg }</> : not_specified }</>,
			sort: ( direction ) => onUpdateListOrdering( direction === 'asc' ? 'total_weight_kg' : '-total_weight_kg' )
		},
	]

	return (
		<div>
			{ !parcel_id &&
				<Alert severity="warning">Create order before adding products</Alert>
			}
			{ !editingParcelProductID &&
				<div style={ { padding: '7px' } }>
					<AddButton
						label="Add Products"
						onClick={ onAddParcelProduct }
						color="secondary"
					/>
				</div>
			}
			{ is_loading && <Loading /> }
			{ !is_loading && parcel_products.length > 0 ?
				<>
					{ parcel_id &&
						<>
							<Hidden smDown>
								<CommonTable
									is_loading={ is_loading }
									rows={ parcel_products }
									columns={ columns }
									item_list={ adminDonorParcelProductList }
									onEditRow={ onEditParcelProduct }
									useColumnsSelector={ true }
									storeTableName={ "adminDonorParcelProductList" }
								/>
							</Hidden>
							<Hidden mdUp>
								<CommonTable
									is_loading={ is_loading }
									rows={ parcel_products }
									columns={ columnsMobile }
									item_list={ adminDonorParcelProductList }
									onEditRow={ onEditParcelProduct }
									mobile={ true }
								/>
							</Hidden>
						</>
					}
				</> :
				<div align="center">
					<img src="/static/images/empty-box.jpg" alt="Your cart is empty" title="Your cart is empty" width={ 300 } />
				</div>
			}
			{ editingParcelProductID && renderEditParcelProduct( editingParcelProductID ) }
		</div>
	)
}
