import React, { useEffect } from "react"
import { adminLogisticsCompanyStatusHistoryList } from './actions/admin_logistics_company_status_history'
import AdminStateHistoryList from '../layout/AdminStateHistoryList'
import { useDispatch } from 'react-redux'

export const AdminLogisticsCompanyStateHistory = ({logistics_company_id}) => {

    const dispatch = useDispatch()
    const item_list = adminLogisticsCompanyStatusHistoryList

    useEffect(() => {
        async function fetchStaticObjects() {
            dispatch(item_list.updateListFilter({logistics_company: logistics_company_id}))
        }
        fetchStaticObjects()
    }, [])

    return (
        <AdminStateHistoryList item_list={item_list}
                               active_key={'Logistics Company state history'} />
    )
}
