import React from 'react'
import cookie from 'react-cookies'

export function getShowTourCookie(name) {
  const cookieExists = cookie.load(name) ? false : true
  return cookieExists
}

export function setTourCookie(name) {
  const cookieSaved = cookie.save(name) ? true : false
  return cookieSaved
}
