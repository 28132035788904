import { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { get } from 'lodash'
import { adminChecklistQuestionList } from './actions/admin_checklist_question'
import { CommonTable } from 'components/CommonTable'
import { AddButton } from 'components/form/Buttons'
import { Modal } from 'components/layout/Modal'
import AdminChecklistQuestion from './AdminChecklistQuestion'
import { ShortId } from 'components/ShortId'

export default function  AdminChecklistQuestions({checklist_id, checklist_name}) {
    const dispatch = useDispatch()
    const [editingCheckList, setEditingCheckList] = useState(null)
    const [questionId, setQuestionId] = useState(null)
    const questions = useSelector(() => adminChecklistQuestionList.getVisibleObjects())
    const title = checklist_name ? `New Question for ${checklist_name}` : "New Check"

    useEffect(() => {
        dispatch(adminChecklistQuestionList.updateListFilter({ checklist: checklist_id }))
        dispatch(adminChecklistQuestionList.fetchListIfNeeded())
    },[])

    const columns = [
        {
            field: 'text',
            title: 'Question',
            sort: (direction) => onUpdateListOrdering(direction === 'asc' ? 'name' : '-name')
        },
    ]

    const onAddCheck = () => {
        setEditingCheckList(true)
    }

    const onEditQuestion = (question_id) => {
        setQuestionId(question_id)
        setEditingCheckList(true)
    }

    const onSaved = () => {
        dispatch(adminChecklistQuestionList.invalidateList())
        dispatch(adminChecklistQuestionList.fetchListIfNeeded())
        setEditingCheckList(false)
        setQuestionId(null)
    }

    return(
        <>
            <AddButton label="New Question" onClick={onAddCheck} color="secondary" />
            <CommonTable
                item_list={adminChecklistQuestionList}
                rows={questions}
                columns={columns}
                onEditRow={onEditQuestion}
            />
            {editingCheckList &&
                <Modal onClose={() => setEditingCheckList(false)} fullWidth maxWidth="md" title={title}>
                    <AdminChecklistQuestion
                        checklist_id={checklist_id}
                        question_id={questionId}
                        onSaved={onSaved}
                    />
                </Modal>
            }
        </>
    )
}
