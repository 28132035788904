import React, { Component } from 'react'
import { Switch } from 'formik-material-ui'
import { Field } from 'formik';
import { FormControlLabel, Tooltip } from '@material-ui/core'
import InfoIcon from '@material-ui/icons/Info'
import { unpackFormikPropsForField } from 'actions/form'

export const FormikSwitchField = ({ label, name, helperText, tooltip, formik_props, color, ...props }) => {
  
  const { errors, touched } = unpackFormikPropsForField({
    formik_props,
    name,
  })

  const setTouched = () => {
    formik_props.setFieldTouched(name);
  }

  return (
    <>
      <FormControlLabel
        control={
          <Field
            component={Switch}
            name={name}
            color={color}
            checked={formik_props.values[name]}
            {...props}
          />
        }
        label={label}
      />
      {tooltip && (
        <Tooltip title={tooltip} arrow>
          <InfoIcon fontSize="small" color="primary" />
        </Tooltip>
      )}
    </>
  );
};
