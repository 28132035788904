import React from 'react'
import MarketingMainLayout from '../layout/MarketingMainLayout'
import BeneficiaryRegister from './forms/BeneficiaryRegister'
import DonorRegister from './forms/DonorRegister'
import LogisticsRegister from './forms/LogisticsRegister'
import {
  makeStyles,
  Paper,
  Grid
} from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
  root: {
    padding: 15,
    margin: 15,
    [theme.breakpoints.down("sm")]: {
      margin: 0,
      border: 0
    },
    maxWidth: 800
  }
}))

export default function CompanyRegister(props) {
  const companyType = props.companyType
  const classes = useStyles()
  let registerForm = <BeneficiaryRegister />
  if(companyType == 'donor') {
    registerForm = <DonorRegister />
  }
  else if(companyType == 'logistics') {
    registerForm = <LogisticsRegister />
  }

  return(
    <MarketingMainLayout>
      <Grid container justify="center">
        <Paper className={classes.root} elevation={0} variant="outlined">
          {registerForm}
        </Paper>
      </Grid>
    </MarketingMainLayout>
  )
}
