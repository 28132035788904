import React from 'react'
import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles((theme) =>({
  no_border: {

  },
  with_border: {

  },
  bottom_border: {

  },
  no_border: {

  },
  top_border: {

  },
}))

export const Separator = ({ height, width, border, borderTop, borderBottom, borderColor, ...props }) => {
    const classes = useStyles()
    let borderClass = classes.no_border
    if(border) { borderClass = classes.with_border }
    else if(borderTop) { borderClass = classes.top_border }
    else if(borderBottom) { borderClass = classes.bottom_border }

    return (
        <div style={{height: height, width: width, borderColor: borderColor}} className={borderClass}>
        </div>
    )
}
