import { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { FormikDropdownField } from 'components/form/Dropdown'
import { ParcelStatus } from 'components/orders/ParcelStatus'
import { shouldShowOnDemandSaveButton } from 'actions/form'
import { adminParcelStates } from 'admin/actions/admin_dropdown_options'
import { SaveButton } from 'components/form/Buttons'

export default function OrderStateForm({ formik_props, parcel }) {
    const dispatch = useDispatch()
    const parcel_state_options =  useSelector(() => adminParcelStates.getAsOptions())

    useEffect(() => {
        dispatch(adminParcelStates.fetchListIfNeeded())
    },[])

    if((parcel.status != 'complete') && (parcel.status != 'order_cancelled')) {
        return (
            <>
                <FormikDropdownField
                    name="status"
                    label="Status"
                    validate_on_select={true}
                    options={parcel_state_options}
                    formik_props={formik_props}
                />
               { shouldShowOnDemandSaveButton(formik_props, ["status"]) &&
                   <SaveButton isSubmitting={formik_props.isSubmitting} />
               }
            </>
        )
    } else {
        return <ParcelStatus status={ formik_props.values.status } />
    }
}
