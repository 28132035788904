import React, { useEffect, useState } from "react"
import 'react-date-range/dist/styles.css'
import 'react-date-range/dist/theme/default.css'
import { FormikDateRange } from 'components/form/DateRange'
import { addDays } from 'date-fns'
import { Typography, Button, Chip, InputAdornment } from "@material-ui/core"
import { Separator } from 'components/layout/Separator'
import { EnhancedTable } from 'components/SimpleTable'
import {Formik, Form, Field} from 'formik'
import { SingleFileUploadField } from 'components/form/SingleFileUploadField'
import { FormikInputField } from 'components/form/InputField'
import Alert from "@material-ui/lab/Alert"
import * as yup from 'yup'

const validationSchema = yup.object().shape({ })

const getNumDays = (startDate, endDate) => {
  var difference_in_time = endDate.getTime() - startDate.getTime()
  var difference_in_days = difference_in_time / (1000 * 3600 * 24)

  return difference_in_days
}

export default function Section18ARequestForm({active_company_id, onComplete}) {
  const [step, setStep] = useState(1)
  const initial_values = {}
  const [selectionStartDate, setSelectionStartDate] = useState(formatDate(addDays(new Date(), -30)))
  const [selectionEndDate, setSelectionEndDate] = useState(formatDate(new Date()))
  const [selectionNumDays, setSelectionNumDays] = useState(getNumDays(new Date(), addDays(new Date(), -30)))
  const [state, setState] = useState([
      {
        startDate: new Date(),
        endDate: addDays(new Date(), -30),
        key: 'selection'
      }
  ])

  const columns = [
    {
      id: 'id',
      label: 'Order ID'
    },
    {
      id: 'date',
      label: 'Date'
    },
    {
      id: 'weight_kg',
      label: 'Weight'
    },
    ,
    {
      id: 'rand_value',
      label: 'Rand Value'
    }
  ]
  const rows = [
    {id: 123, date: '12/07/2021', weight_kg: 5300, rand_value: 'R6,000'},
    {id: 124, date: '15/07/2021', weight_kg: 6300, rand_value: 'R7,000'},
    {id: 125, date: '19/07/2021', weight_kg: 7300, rand_value: 'R8,000'}
  ]

  const onSave = (values, formik_funcs) => {

  }

  const goToStep = (number) => {
    setStep(number)
  }

  function formatDate(date) {
      let year = date.getFullYear();
      let month = (1 + date.getMonth()).toString().padStart(2, '0');
      let day = date.getDate().toString().padStart(2, '0');

      return day + '/' + month + '/' + year;
  }

  const handleSelect = (ranges) => {
    setSelectionStartDate(formatDate(ranges.selection.startDate))
    setSelectionEndDate(formatDate(ranges.selection.endDate))

    setSelectionNumDays(getNumDays(ranges.selection.startDate, ranges.selection.endDate))
    // {
    //   selection: {
    //     startDate: [native Date Object],
    //     endDate: [native Date Object],
    //   }
    // }
  }

  const StepButtons = ({stepNumber, backOnly, nextOnly, label}) => {
    return (
      <div style={{marginTop: 20}}>
          {!nextOnly &&
            <Button onClick={() => goToStep(stepNumber-1)}>
              Back
            </Button>
          }
          {!backOnly &&
            <Button
              variant="contained"
              disableElevation
              color="primary"
              size="large"
              onClick={() => goToStep(stepNumber+1)}
              style={{float: 'right'}}
            >
              { label || "Next Step"}
            </Button>
          }
      </div>
    )
  }

  const renderStep1 = (formik_props) => {
    return (
      <>

      <FormikDateRange formik_props={formik_props}/>

        <StepButtons stepNumber={1} nextOnly />
      </>
    )
  }

  const renderStep2 = () => {
    return (
      <>
        <EnhancedTable
          filters={false}
          columns={columns}
          rows={rows}
          enhancedTable={false}

        />
        <StepButtons stepNumber={2} />
      </>
    )
  }

  const renderStep3 = (formik_props) => {
    return (
      <>
      <div style={{textAlign: 'center', marginTop: 20}}>
        <FormikInputField name="amount"
                          type="text"
                          placeholder="Amount"
                          formik_props={formik_props}
                          style={{width: 300}}
                          InputProps={{
                            startAdornment: <InputAdornment position="start">R</InputAdornment>,
                          }}
        />
        <SingleFileUploadField name="company_invoice_file_info"
                               dropzonePlaceholder = "Upload the company invoice for these orders"
                               buttonPlaceholder = "company invoice for these orders"
                               formik_props={formik_props}
        />
      </div>
      <StepButtons stepNumber={3} />
      </>
    )
  }

  const renderStep4 = () => {
    return (
      <>
      <div style={{textAlign: 'center', marginTop: 20}}>
        <Typography variant="h1" style={{color: 'green'}}>Success!</Typography>
        <Alert severity="info" style={{marginBottom: 20}}>
          You will be notified as soon as we have verified the invoice against the donations and your Section 18A receipt has been generated.
        </Alert>
        <Button onClick={() => onComplete()} variant="outlined">Close</Button>
      </div>
      <StepButtons stepNumber={4} backOnly />
      </>
    )
  }

  return (
    <Formik
      initialValues={initial_values}
      onSubmit={onSave}
      enableReinitialize={true}
      validationSchema={validationSchema}
    >

      {formik_props => {
          const { values } = formik_props
          return (
              <Form>

                  <Typography>
                    <Chip label="1" color={step === 1 ? 'secondary' : 'default'} style={{marginRight: 10, fontSize: 14, fontWeight: 500}} /> Select Date Range
                    <Chip label="2" color={step === 2 ? 'secondary' : 'default'} style={{marginRight: 10, marginLeft: 10, fontSize: 14, fontWeight: 500}} /> Select Donations
                    <Chip label="3" color={step === 3 ? 'secondary' : 'default'} style={{marginRight: 10, marginLeft: 10, fontSize: 14, fontWeight: 500}} /> Upload Invoice
                    <Chip label="4" color={step === 4 ? 'secondary' : 'default'} style={{marginRight: 10, marginLeft: 10, fontSize: 14, fontWeight: 500}} /> Complete
                  </Typography>
                  <Separator height={20} />
                  {step == 1 && renderStep1(formik_props) }
                  {step == 2 && renderStep2(formik_props) }
                  {step == 3 && renderStep3(formik_props) }
                  {step == 4 && renderStep4(formik_props) }

              </Form>
          )}
      }
    </Formik>
  )


}
