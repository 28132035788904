import React, { useEffect, useState } from "react"
import { Link, useHistory } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import DonorCommonListLayout from '../layout/DonorCommonListLayout'
import Section18ARequestForm from './Section18ARequestForm'
import { Modal } from 'components/layout/Modal'
import { contactSection18AList } from './actions/contact_section18a'
import { getActiveCompanyId } from 'actions/active_company'

export default function Section18A({}) {
  const dispatch = useDispatch()
  const parent_id = getActiveCompanyId()
  const is_loading = useSelector(() => contactSection18AList.isLoading())
  const section18a_list = useSelector(() => contactSection18AList.getVisibleObjects({parent: parent_id}))

  useEffect(() => {
    dispatch(contactSection18AList.updateListFilter({parent: parent_id}))
    dispatch(contactSection18AList.fetchListIfNeeded({parent: parent_id}))
  },[])

  const [showSection18AModal, setShowSection18AModal] = useState(false)

  const onAddSection18A = () => {
    setShowSection18AModal(true)
  }

  const onEditSection18A = () => {
    setShowSection18AModal(true)
  }

  const onComplete = () => {
    setShowSection18AModal(false)
  }

  const columns = []

  return (
      <>
        <DonorCommonListLayout active_key="branches"
                               breadcrumbs={[{name: 'donor_home'},
                                             {name: 'section18a', label: "Section 18A", url: '/donor/section18a'}]}
                               add_label="Request Section 18A Receipt"
                               onAddRow={onAddSection18A}
                               onEditRow={onEditSection18A}
                               title="Section 18A Receipts"
                               is_loading={is_loading}
                               columns={columns}
                               item_list={contactSection18AList}
                               handleRequestSort={columns}
                               enableAnyFieldFilter={true}
                               useColumnsSelector={true}
                               storeTableName={"contactSection18AList"}
        />
      {showSection18AModal &&
        <Modal fullWidth={true} maxWidth={'md'} onClose={onComplete} title="Request Section 18A Receipt">
          <Section18ARequestForm onComplete={onComplete} />
        </Modal>
      }
      </>
  )

}
