import React, { useState, useMemo, useRef } from "react"
import { get } from 'lodash'
import TinderCard from 'react-tinder-card'
import '../swipe-card.css'
import { Button } from "@material-ui/core"
import { KpiCard } from 'components/layout/KpiCard'

const SwipeCard = ({ kpi_results, kpi, category, onAddComment, onShowHistory, onResolveKpi }) => {
    const [currentIndex, setCurrentIndex] = useState(kpi_results.length - 1)
    const [lastDirection, setLastDirection] = useState()
    // used for outOfFrame closure
    const currentIndexRef = useRef(currentIndex)

    const childRefs = useMemo(
        () =>
        Array(kpi_results.length)
        .fill(0)
        .map((i) => React.createRef()),
        []
    )

    const updateCurrentIndex = (val) => {
        setCurrentIndex(val)
        currentIndexRef.current = val
    }

    const canGoBack = currentIndex < kpi_results.length - 1

    const canSwipe = currentIndex >= 0

    // set last direction and decrease current index
    const swiped = (direction, nameToDelete, index) => {
        setLastDirection(direction)
        updateCurrentIndex(index - 1)
    }

    const outOfFrame = (name, idx) => {
        console.log(`${name} (${idx}) left the screen!`, currentIndexRef.current)
        // handle the case in which go back is pressed before card goes outOfFrame
        currentIndexRef.current >= idx && childRefs[idx].current.restoreCard()
        // TODO: when quickly swipe and restore multiple times the same card,
        // it happens multiple outOfFrame events are queued and the card disappear
        // during latest swipes. Only the last outOfFrame event should be considered valid
    }

    const swipe = async(dir) => {
        if (canSwipe && currentIndex < kpi_results.length) {
            await childRefs[currentIndex].current.swipe(dir) // Swipe the card!
        }
    }

    // increase current index and show card
    const goBack = async() => {
        if (!canGoBack) return
        const newIndex = currentIndex + 1
        updateCurrentIndex(newIndex)
        await childRefs[newIndex].current.restoreCard()
    }

    return (
        <div align="center">
            <div className='cardContainer'>
            {kpi_results.map((kpi_result, index) => (

                    <TinderCard
                        ref={childRefs[index]}
                        className='swipe'
                        key={index}
                        onSwipe={(dir) => swiped(dir, kpi_result, index)}
                        onCardLeftScreen={() => outOfFrame(kpi_result, index)}
                    >
                        <div className='card'>
                            <KpiCard
                                 kpi={ kpi }
                                 kpi_result={ kpi_result }
                                 category={ category }
                                 onAddComment={ onAddComment }
                                 onShowHistory={ onShowHistory }
                                 onResolveKpi={ onResolveKpi }
                            />
                        </div>
                    </TinderCard>
                ))}
            </div>
            <div>
                {/*<Button disabled={canSwipe ? false : true}  onClick={() => swipe('left')}>Swipe left</Button>*/}
                <Button disabled={canGoBack ? false : true} onClick={() => goBack()}>Back</Button>
                <Button disabled={canSwipe ? false : true} onClick={() => swipe('right')}>Next</Button>
            </div>

        </div>
    )
}

export default SwipeCard
