import { ItemList } from 'orm'
import { map } from 'lodash'

class NutritionCardList extends ItemList {
    getEntityKey() {
        return "admin/nutrition_card"
    }
}

export const adminNutritionCardList = new NutritionCardList("admin_nutrition_card__default")
