import React from 'react'
import { replace, capitalize } from 'lodash'
import { useDispatch, useSelector } from 'react-redux'
import { adminParcelStates } from 'admin/actions/admin_dropdown_options'
import { adminParcelList } from 'admin/components/orders/actions/admin_parcel'
import { FormikGeneralFormErrors } from 'components/form/GeneralFormErrors'
import { FormikDropdownField } from 'components/form/Dropdown'
import { Formik, Form, useField } from 'formik'
import { handleSubmitResult } from 'actions/form'
import { showSuccess, showError } from 'actions/Error'

export function AdminParcelStateDropDown({ parcel_id, initial_state, onStatusChanged, size }) {
    const dispatch = useDispatch()
    const initial_values = {status: initial_state}
    const parcel_state_options =  useSelector(() => adminParcelStates.getAsOptions())
    const parcel =  useSelector(() => adminParcelList.getObject(parcel_id))
    const is_loading = useSelector(() => adminParcelList.isLoading())
    const is_busy =  useSelector(() => adminParcelList.getIsSavingObject())
    const width = size == 'default' ? '' : 120
    const fontSize = size == 'default' ? '' : 13
    const margin = size == 'default' ? 'normal' : 'dense'

    React.useEffect(() => {
        async function fetchStaticObjects() {
            dispatch(adminParcelList.ensureObjectLoaded(parcel_id))
            dispatch(adminParcelStates.fetchListIfNeeded())
        }
        fetchStaticObjects()
    }, [parcel_id])

    const StatusSelect = ({ name, options, formik_props }) => {
        const [field, meta, helpers] = useField(name);
        return (
            <>
                <FormikDropdownField
                    name={name}
                    value={field.value}
                    margin={margin}
                    validate_on_select={true}
                    options={parcel_state_options}
                    formik_props={formik_props}
                    fullWidth
                    width={width}
                    fontSize={fontSize}
                    options={options}
                    onChange={(value) => helpers.setValue(value)}
                    onBlur={() => helpers.setTouched(true)}
                />
            </>
        )
    }

    const onSave = (values, formik_funcs) => {
        const on_ok = function(json) {
            dispatch(adminParcelList.invalidateList())
            onStatusChanged(true)
            showSuccess("Saved", `Parcel status changed from ${capitalize(replace(initial_state, /_/g, ' '))} to ${capitalize(replace(values.status, /_/g, ' '))}`)
        }
        if ( parcel_id ) {
            values.id = parcel_id
            return dispatch(adminParcelList.saveObject(values)).then((res) => handleSubmitResult({ res, formik_funcs, on_ok }))
        } else {
             return false
        }
    }

    return(
        <Formik
            initialValues={initial_values}
            onSubmit={(values, formik_funcs) => { onSave(values, formik_funcs) }}
        >
            { formik_props => {
                const { values } = formik_props
                React.useEffect(() => {
                    if (values !== initial_values) {
                        formik_props.submitForm(values)
                    }
                }, [values])

                return(
                    <Form>
                        <FormikGeneralFormErrors render={(msg) => msg} />
                        <StatusSelect name="status" options={parcel_state_options} formik_props={formik_props} />
                    </Form>
                )
            }}
        </Formik>
    )
}
