import React from 'react'

export default function ResponsiveVideoIframe({url, width, height, title}) {
  return(
    <iframe
      width={width}
      height={height}
      src={url}
      title={title}
      frameborder="0"
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
      allowfullscreen
    >
    </iframe>
  )
}
