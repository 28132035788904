import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useParams } from 'react-router-dom'
import { get } from 'lodash'
import { adminNotificationList } from './actions/admin_notification'
import { Separator } from 'components/layout/Separator'
import { Formik, Form, Field } from 'formik'
import * as Yup from 'yup'
import { showSuccess, showError } from 'actions/Error'
import { handleSubmitResult } from 'actions/form'
import AdminCommonFormLayout from '../layout/AdminCommonFormLayout'
import AdminCommonForm from '../layout/AdminCommonForm'
import { AdminNotificationForm, validationSchema } from './form/AdminNotificationForm'
import { FormikGeneralFormErrors } from 'components/form/GeneralFormErrors'

export default function AdminNotification( { passed_notification_id, in_modal = false, onClose = () => { } } ) {
  const dispatch = useDispatch()
  const history = useHistory()
  const { notification_id: params_notification_id } = useParams()
  const notification_id = passed_notification_id || params_notification_id
  // Get the query parameters from the current browser URL
  const searchParams = new URLSearchParams( window.location.search )
  // Get the value of the 'redirect' parameter
  const redirectParam = searchParams.get( 'redirect' )
  const notification = useSelector( () => adminNotificationList.getObject( notification_id ) )
  const initial_values = notification
  const is_loading = useSelector( () => adminNotificationList.isLoading() ) || ( notification_id && !get( notification, "id" ) )
  const is_busy = useSelector( () => adminNotificationList.getIsSavingObject() )
  const title = `${ get( notification, [ "name" ], 'New Notification' ) }`

  useEffect( () => {
    dispatch( adminNotificationList.ensureObjectLoaded( notification_id ) )
  }, [] )

  const onSave = ( values, formik_funcs ) => {

    const on_ok = function( json ) {
      dispatch( adminNotificationList.invalidateList() )
      dispatch( adminNotificationList.fetchListIfNeeded() )
      showSuccess( "Saved", "Notification marked as read" )
      onClose()
    }
    if ( notification_id ) {
      values.id = notification_id
      values.is_read = true
      return dispatch( adminNotificationList.saveObject( values ) ).then( ( res ) => handleSubmitResult( { res, on_ok, formik_funcs } ) )
    }
    else {
      return dispatch( adminNotificationList.saveNewObject( values ) ).then( ( res ) => handleSubmitResult( { res, on_ok, formik_funcs } ) )
    }
  }
  
  if ( in_modal ) {
    return (
      <AdminCommonForm
        is_loading={ is_loading }
        is_busy={ is_busy }
        initial_values={ initial_values }
        inModal={ true }
        validationSchema={ validationSchema }
        onSave={ onSave }>
        {
          ( { formik_props } ) => {
            return (
              <>
                <FormikGeneralFormErrors render={ ( msg ) => msg } name={ undefined } />
                <AdminNotificationForm formik_props={ formik_props } />
              </>
            )
          }
        }
      </AdminCommonForm>
    )
  } else {

    return (
      <AdminCommonFormLayout
        breadcrumbs={ [
          { name: 'admin_home' },
          { name: 'notifications', label: 'Notifications', url: '/admin/notifications' },
          { name: 'notification', label: get( notification, [ "display_name" ], 'New Notification' ), url: `/admin/notification/${ notification_id }` }
        ] }
        is_busy={ is_busy }
        is_loading={ is_loading }
        title={ title }
        initial_values={ initial_values }
        validationSchema={ validationSchema }
        onSave={ onSave }
      >
        {
          ( { formik_props } ) => <AdminNotificationForm formik_props={ formik_props } />
        }
      </AdminCommonFormLayout>
    )
  }
}
