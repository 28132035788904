import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from 'react-redux'
import { get, map, startCase, toLower } from 'lodash'
import Timestamp from 'components/Timestamp'
import Alert from '@material-ui/lab/Alert'
import { contactDonorParcelProductList } from 'actions/contact/donor/parcel_product'
import { contactProductList } from 'actions/contact/product'
import { contactParcelList } from 'actions/contact/parcel'
import { CommonTable } from 'components/CommonTable'
import DonationParcelProduct from './DonationParcelProduct'
import { HierarchyCrumbs } from 'components/layout/HierarchyCrumbs'
import { ShortId } from 'components/ShortId'
import { Button, Hidden, Grid, Typography, makeStyles, CircularProgress } from '@material-ui/core'
import { Modal } from 'components/layout/Modal'
import { Separator } from 'components/layout/Separator'
import NumberFormat from 'react-number-format'
import { AddButton } from 'components/form/Buttons'
import DonateFoodImport from './DonateFoodImport'
import { fileInfoList } from 'actions/file_info'
import { LoadingItems } from 'components/layout/loading/LoadingItems'
import { handleSubmitResult } from 'actions/form'

const NEW_PARCEL_PRODUCT_ID = '__new__'

const useStyles = makeStyles((theme) => ({
  root: {}
}))

export default function DonationParcelProducts({parcel_id, noAddProducts}) {
    const dispatch = useDispatch()
    const classes = useStyles()
    const [orderSheetFileInfoId, setOrderSheetFileInfoId] = useState(null)
    const order_sheet_file_info = useSelector(() => fileInfoList.getObject(orderSheetFileInfoId))
    const parcel_products = useSelector(() => contactDonorParcelProductList.getVisibleObjects())
    const parcel_product_filter = useSelector(() => contactDonorParcelProductList.getFilter())
    const parcel = useSelector(() => contactParcelList.getObject(parcel_id))
    const product_ids = map(parcel_products, (parcel_product) => parcel_product.id)
    const is_loading = useSelector(() => contactDonorParcelProductList.isLoading())
    const [editingParcelProductId, setEditingParcelProductId] = useState(null)
    const [loadingImportedProducts, setLoadingImportedProducts] = useState(false)
    const [showImportProducts, setShowImportProducts] = useState(false)
    const initial_filter_values = {}
    const not_specified = <span style={{color: 'red'}}>not specified</span>

    useEffect(() => {
        dispatch(contactDonorParcelProductList.updateListFilter({parcel: parcel_id}))
        dispatch(contactDonorParcelProductList.fetchListIfNeeded())
        dispatch(contactProductList.ensureObjectsLoaded(product_ids))
        dispatch(contactParcelList.ensureObjectLoaded(parcel_id))
    },[parcel_id])

    const onSaveImport = (orderSheetFileInfoId) => {
        console.log(orderSheetFileInfoId)
        setLoadingImportedProducts(orderSheetFileInfoId)
        const on_ok = function(json) {
            dispatch(contactDonorParcelList.invalidateList())
            dispatch(fileInfoList.invalidateObject(orderSheetFileInfoId))
            setLoadingImportedProducts(false)
            showSuccess("Saved", "Order saved")
            onSaved(json)
        }

        if ( parcel_id && orderSheetFileInfoId ) {
            const values = {id: parcel_id, order_sheet_file_info: orderSheetFileInfoId}
            dispatch(contactParcelList.saveObject(values))
        } else {
            return false
        }
    }

    const onEditParcelProduct = (parcel_product_id) => {
        setEditingParcelProductId(parcel_product_id)
    }

    const onAddParcelProduct = () => {
        setEditingParcelProductId(NEW_PARCEL_PRODUCT_ID)
    }

    const onStopEditingParcelProduct = () => {
        dispatch(contactDonorParcelProductList.invalidateList())
        dispatch(contactParcelList.invalidateObject(parcel_id))
        dispatch(contactDonorParcelProductList.fetchListIfNeeded())
        dispatch(contactParcelList.fetchListIfNeeded())
        setEditingParcelProductId(null)
    }

    const renderEditParcelProduct = (parcel_product_id) => {
        return (
            <Modal
                onClose={onStopEditingParcelProduct}
                fullWidth={true}
                width='xl'
                title=""
            >
                <DonationParcelProduct
                  parcel_id={parcel_id}
                  parcel_product_id={parcel_product_id === NEW_PARCEL_PRODUCT_ID ? null : parcel_product_id}
                  onSaved={onStopEditingParcelProduct}
                  onCancel={onStopEditingParcelProduct}
                />
            </Modal>
        )
    }

    const onUpdateListOrdering = (field) => {
        dispatch(contactDonorParcelProductList.updateListOrdering(field))
    }

    const columns = [
        {
            field: 'product_name',
            title: 'Product',
            visible: true,
            render: (item) => startCase(toLower(get(item, "product_name"))),
            sort: (direction) => onUpdateListOrdering(direction === 'asc' ? 'product' : '-product'),
        },
        {
            field: 'product_category_crumbs',
            title: 'Category',
            render: (item) => get(item, "product_category_crumbs", [])[get(item, "product_category_crumbs", []).length-1],
            sort: (direction) => onUpdateListOrdering(direction === 'asc' ? 'product__product_category__name' : '-product__product_category__name'),
        },
        {
            field: 'best_before_date',
            title: 'Best Before',
            visible: true,
            render: (item) => <Timestamp value={item.best_before_date} format='date' />,
            sort: (direction) => onUpdateListOrdering(direction === 'asc' ? 'best_before_date' : '-best_before_date'),
        },
        {
            field: 'expiry_date',
            title: 'Expiry',
            visible: true,
            render: (item) => <Timestamp value={item.expiry_date} format='date' />,
            sort: (direction) => onUpdateListOrdering(direction === 'asc' ? 'expiry_date' : '-expiry_date'),
        },
        {
            field: 'product_weight_kg',
            title: 'Item Weight',
            visible: true,
            render: (item) => <NumberFormat value={get(item,["product_weight_kg"])} suffix={"kg"} displayType={'text'} thousandSeparator={true} decimalScale={2} />,
            sort: (direction) => onUpdateListOrdering(direction === 'asc' ? 'weight_kg' : '-weight_kg'),
        },
        {
            field: 'provisional_quantity',
            title: 'Prov Qty',
            visible: true,
            sort: (direction) => onUpdateListOrdering(direction === 'asc' ? 'provisional_quantity' : '-provisional_quantity')
        },
        {
            field: 'actual_quantity',
            title: 'Qty Received',
            visible: true,
            sort: (direction) => onUpdateListOrdering(direction === 'asc' ? 'actual_quantity' : '-actual_quantity')
        },
        {
            field: 'total_provisional_weight_kg',
            title: 'Weight',
            visible: true,
            render: (item) => <NumberFormat value={get(item,["product_weight_kg"]) * get(item,["provisional_quantity"])} suffix={"kg"} displayType={'text'} thousandSeparator={true} decimalScale={2} />,
            sort: (direction) => onUpdateListOrdering(direction === 'asc' ? 'total_provisional_weight_kg' : '-total_provisional_weight_kg'),
        },
    ]

    const cancelImport = () => {
        setShowImportProducts(false)
    }

    const onImport = (order_sheet_file_info_id) => {
        setOrderSheetFileInfoId(order_sheet_file_info_id)
        onSaveImport(orderSheetFileInfoId)
        setShowImportProducts(false)
    }

    return (
        <div>
            { ! parcel_id && <Alert severity="warning">Create order before adding products</Alert> }
            { loadingImportedProducts  && <LoadingItems label={`Save parcel_products imported from file ${loadingImportedProducts}`} /> }
            <Grid container spacing={2} style={{marginBottom: 0}} alignItems="center">
                { ! noAddProducts &&
                <Grid item>
                    <AddButton onClick={ onAddParcelProduct } label="Add Products" variant="icon" buttonGroup position="left" color="yellow" />
                    <AddButton onClick={() => setShowImportProducts(true) } label="Import Products" variant="icon" type="import" buttonGroup position="right" color="yellow" />
                </Grid>
                }
                <Grid item>
                    <Typography>
                        { get(parcel, "total_weight_kg") ?
                            <>
                                <span style={{color: '#999999'}}>
                                    Actual Weight:
                                </span>
                                <span style={{fontWeight: 500}}>
                                    <NumberFormat value={parcel.total_weight_kg} suffix={"kg"} displayType={'text'} thousandSeparator={true} decimalScale={2} />
                                </span>
                            </> :
                            <>
                                <span style={{color: '#999999'}}>
                                    Provisional Weight:
                                </span>
                                <span style={{fontWeight: 500}}>
                                    <NumberFormat value={parcel.total_provisional_weight_kg} suffix={"kg"} displayType={'text'} thousandSeparator={true} decimalScale={2} />
                                </span>
                            </>
                        }
                    </Typography>
                </Grid>

            </Grid>
            { parcel_id &&
                <div style={{marginLeft: -24, marginRight: -24}}>

                    <Hidden smDown>
                        <CommonTable
                            is_loading={ is_loading }
                            rows={ parcel_products }
                            columns={ columns }
                            item_list={contactDonorParcelProductList}
                            onEditRow={onEditParcelProduct}
                            useColumnsSelector={true}
                            storeTableName={"contactDonorParcelProductList"}
                            statusComplete={noAddProducts}
                        />
                    </Hidden>

                    <Hidden mdUp>
                        <CommonTable
                            is_loading={ is_loading }
                            rows={ parcel_products }
                            columns={ columns }
                            item_list={contactDonorParcelProductList}
                            onEditRow={onEditParcelProduct}
                            mobile={true}
                            statusComplete={noAddProducts}
                        />
                    </Hidden>

                    { editingParcelProductId && renderEditParcelProduct(editingParcelProductId) }

                </div>
            }

            { showImportProducts &&
              <Modal
                  onClose={cancelImport}
                  title="Import Donation Products"
                  fullWidth
                  maxWidth="sm"
              >
                <DonateFoodImport
                    order_sheet_file_info_id={orderSheetFileInfoId}
                    parcel_id={parcel_id}
                    onImport={onImport}
                />
              </Modal>
            }

        </div>
    )
}
