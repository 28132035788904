import React from 'react'
import { get } from 'lodash'
import LinkOffIcon from '@material-ui/icons/LinkOff'
import LinkIcon from '@material-ui/icons/Link'
import { Tooltip, IconButton, Chip } from '@material-ui/core';

export const getContentStatus = (item, company_type) => {
  let contentStatus = 'none'
  let microsite_enabled = get(item, ["company", "microsite_enabled"])
  const website = get(item, ["company", "website"]) ? "Website field completed. \n" : ''
  const logo = get(item, ["company", "logo_images", "files"]) && get(item, ["company", "logo_images", "files"]).length > 0   ? "Logo uploaded. \n" : ''
  const banner = get(item, ["company", "banner_images", "files"]) && get(item, ["company", "banner_images", "files"]).length > 0  ? "Banner uploaded. \n" : ''
  const description = get(item, ["company", "description"])  ? "Description completed. \n" : ''
  const gallery = get(item, ["company", "images", "files"]) && get(item, ["company", "images", "files"]).length > 0 ? ` ${get(item, ["company", "images", "files"]).length} Gallery images uploaded \n` : ''
  if(website && logo && banner && description && gallery) { contentStatus = 'complete' }
  else if(website || logo || banner || description || gallery) { contentStatus = 'partial' }
  const tooltipWithContent = <span>
                                {logo && <p>{logo}</p>}
                                {description && <p>{description}</p>}
                                {website && <p>{website}</p>}
                                {banner && <p>{banner}</p>}
                                {gallery && <p>{gallery}</p>}
                              </span>
  var contentStatusTooltip
  var status_color
  switch(contentStatus) {
    case 'none':
      contentStatusTooltip = 'No content'
      status_color = 'red'
      break
    case 'complete':
      status_color = 'green'
      contentStatusTooltip = tooltipWithContent
      break
    case 'partial':
      status_color = 'orange'
      contentStatusTooltip = tooltipWithContent
      break
  }

  const linkIcon = microsite_enabled ? <LinkIcon color="primary" /> : <LinkOffIcon />
  const micrositeStatusLabel =  microsite_enabled ? 'published' : 'disabled'
  const micrositeTooltipTitle = <span>
                                  <p align="center">Microsite {micrositeStatusLabel}.</p>
                                  <p align="center">Click to view {item.company.name} microsite</p>
                                </span>

  return(
    <>
      <Tooltip title={contentStatusTooltip} arrow>
          <Chip style={{background: status_color, color: 'white'}} size="small" label={contentStatus} />
      </Tooltip>
      <Tooltip title={micrositeTooltipTitle} arrow>
        <a href={`/impact/${company_type}/${get(item, ["company", "id"])}`} target="_blank">
          <IconButton>
            { linkIcon }
          </IconButton>
        </a>
      </Tooltip>
    </>
  )
}
