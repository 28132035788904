import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import AdminCommonListLayout from '../layout/AdminCommonListLayout'
import AdminCommonListTable from '../layout/AdminCommonListTable'
import { adminFeedingFrequencyList } from './actions/admin_feeding_frequency'
import { get, capitalize } from 'lodash'
import AdminFeedingFrequency from './AdminFeedingFrequency'
import { Modal } from 'components/layout/Modal'
import { Separator } from 'components/layout/Separator'

export default function AdminFeedingFrequencies( { company_id, inTabs = false } ) {

  const dispatch = useDispatch()
  const history = useHistory()

  const feeding_frequencies = useSelector( () => adminFeedingFrequencyList.getVisibleObjects() )
  const is_loading = useSelector( () => adminFeedingFrequencyList.isLoading() )
  const as_table = inTabs ? true : false
  const [ openModal, setOpenModal ] = useState( false )
  const [ editingFeedingFrequencyId, setEditingFeedingFrequencyId ] = useState( null )

  const openEditModal = ( feeding_frequency_id ) => {
    setEditingFeedingFrequencyId( feeding_frequency_id )
    setOpenModal( true )
  }

  const closeEditModal = () => {
    setEditingFeedingFrequencyId( null )
    dispatch( adminFeedingFrequencyList.invalidateList() )
    dispatch( adminFeedingFrequencyList.fetchListIfNeeded() )
    setOpenModal( false )
  }

  const onEditFeedingFrequency = ( feeding_frequency_id ) => {
    openEditModal( feeding_frequency_id )
  }

  const onAddFeedingFrequency = () => {
    openEditModal( null ) // pass null to indicate we're creating a new demographic
  }

  useEffect( () => {
    if ( company_id ) {
      dispatch( adminFeedingFrequencyList.updateListFilter( { company: company_id } ) )
    }
    dispatch( adminFeedingFrequencyList.fetchListIfNeeded() )
  }, [] )

  const columns = []

  if ( !company_id ) {
    columns.push(
      {
        field: 'company_name',
        title: 'Company',
        sort: ( direction ) => onUpdateListOrdering( direction === 'asc' ? 'company__name' : '-company__name' ),
      }
    )
  }

  columns.push(
    {
      field: 'frequency',
      title: 'Frequency',
      render: ( item ) => capitalize( get( item, 'frequency', '' ) ),
      sort: ( direction ) => onUpdateListOrdering( direction === 'asc' ? 'frequency' : '-frequency' ),
    },
    {
      field: 'number_of_times',
      title: '# Times',
      sort: ( direction ) => onUpdateListOrdering( direction === 'asc' ? 'number_of_times' : '-number_of_times' )
    },
  )

  const onUpdateListOrdering = ( field ) => {
    dispatch( adminFeedingFrequencyList.updateListOrdering( field ) )
  }

  const renderModal = () => {
    return (
      <Modal title="Add/Edit Feeding Frequency" onClose={ closeEditModal }>
        <Separator height={ 15 } />
        <AdminFeedingFrequency in_modal={ true } onClose={ closeEditModal } passed_feeding_frequency_id={ editingFeedingFrequencyId } company_id={ company_id } />
      </Modal>
    )
  }

  if ( inTabs ) {
    return (
      <>
      <AdminCommonListTable
        add_label="Add"
        title="Feeding Frequency"
        onAddRow={ onAddFeedingFrequency }
        onEditRow={ onEditFeedingFrequency }
        is_loading={ is_loading }
        handleRequestSort={ columns }
        columns={ columns }
        item_list={ adminFeedingFrequencyList }
        inTabs
      />
      { openModal && renderModal() }
      </>
    )
  }

  return (
    <AdminCommonListLayout
      active_key="feeding_frequencies"
      breadcrumbs={ [
        { name: 'admin_home' },
        { name: 'feeding_frequencies', label: "Feeding Frequency", url: '/admin/feeding_frequencys' }
      ] }
      add_label={ inTabs ? "Add" : "Add Frequency" }
      title={ "Feeding Frequency" }
      onAddRow={ onAddFeedingFrequency }
      onEditRow={ onEditFeedingFrequency }
      is_loading={ is_loading }
      columns={ columns }
      item_list={ adminFeedingFrequencyList }
      enableAnyFieldFilter={ true }
      as_table={ as_table }
    >
      { openModal && renderModal() }
    </AdminCommonListLayout>
  )
}
