import React from 'react'
import { capitalize } from 'lodash'
import { makeStyles, Card, FormLabel, FormControl, FormGroup, FormControlLabel, FormHelperText, Switch, Typography, Grid } from '@material-ui/core'

const useStyles = makeStyles({
  notificationSection: {
    padding: 10,
    borderRadius: 0,
    border: '1px solid #eeeeee',
    margin: 5
  }
})

export default function Notifications({type}) {
  const classes = useStyles()
  const [state, setState] = React.useState({
    all: true,
    order_created: true,
    order_cancelled: true,
    driver_en_route: true,
    driver_arrived: true,
    ticket_created: true,
    ticket_resolved: true,
    agent_responded: true,
    newsletter: true,
    tax_certificate_generated: true
  })
  let title = 'Application'
  if(type == 'sms') { title = 'SMS'}
  if(type == 'email') { title = 'Email'}
  const handleChange = (event) => {
    setState({ ...state, [event.target.name]: event.target.checked });
  }

  return (
    <FormControl component="fieldset">
    <Grid container>
      <Grid item xs={12}>

        <Typography variant="h6">{title}</Typography>
        <FormGroup>
          <FormControlLabel
            control={<Switch checked={state.all} onChange={handleChange} name="all" />}
            label={`All ${capitalize(type)} Notifications`}
          />
        </FormGroup>

      </Grid>
      <Grid item xs={12} className={classes.notificationSection}>
          <Typography><b>Donations</b></Typography>
          <FormGroup>
            <FormControlLabel
            control={<Switch checked={state.order_created} onChange={handleChange} color="primary" name="order_created" />}
            label="Order Created"
          />
          <FormControlLabel
            control={<Switch checked={state.order_cancelled} onChange={handleChange} color="primary" name="order_cancelled" />}
            label="Order Cancelled"
          />
          <FormControlLabel
            control={<Switch checked={state.driver_en_route} onChange={handleChange} color="primary" name="driver_en_route" />}
            label="Driver en Route"
          />
          <FormControlLabel
            control={<Switch checked={state.driver_arrived} onChange={handleChange} color="primary" name="driver_arrived" />}
            label="Driver Arrived"
          />
      </FormGroup>
      </Grid>


        {type != 'sms' &&
        <>
        <Grid item xs={12} className={classes.notificationSection}>
          <Typography><b>Tickets</b></Typography>
          <FormGroup>
            <FormControlLabel
              control={<Switch checked={state.ticket_created} onChange={handleChange} color="primary" name="ticket_created" />}
              label="Ticket Created"
            />
            <FormControlLabel
              control={<Switch checked={state.agent_responded} onChange={handleChange} color="primary" name="agent_responded" />}
              label="Agent Responded"
            />
            <FormControlLabel
              control={<Switch checked={state.ticket_resolved} onChange={handleChange} color="primary" name="ticket_resolved" />}
              label="Ticket Resolved"
            />
        </FormGroup>
        </Grid>
        <Grid item xs={12} className={classes.notificationSection}>
          <Typography><b>Section18A Tax Certificates</b></Typography>
          <FormControlLabel
            control={<Switch checked={state.tax_certificate_generated} onChange={handleChange} color="primary" name="tax_certificate_generated" />}
            label="New Certificate Generated"
          />
        </Grid>
        <Grid item xs={12} className={classes.notificationSection}>
          <Typography><b>Newsletters</b></Typography>
          <FormControlLabel
            control={<Switch checked={state.newsletter} onChange={handleChange} color="primary" name="newsletter" />}
            label="Monthly Newsletter"
          />
        </Grid>
        </>
        }


        {/* <FormHelperText>Be careful</FormHelperText> */}

    </Grid>
    </FormControl>
  )
}
