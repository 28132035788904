import React, { useEffect } from "react"
import { useDispatch, useSelector } from 'react-redux'
import { get, filter, head } from 'lodash'
import { FormikGeneralFormErrors } from 'components/form/GeneralFormErrors'
import {showSuccess, showError} from 'actions/Error'
import { handleSubmitResult } from 'actions/form'
import { Formik, Form, useFormikContext } from 'formik'
import * as yup from "yup"
import { adminChecklistAnswerList } from '../actions/admin_checklist_answer'
import { adminChecklistQuestionList } from '../actions/admin_checklist_question'
import { FormikCheckboxField } from 'components/form/CheckboxField'

const validationSchema = yup.object({})

const AdminChecklistAnswerForm = ({ question, checklist_result_id, onSaved, is_loading_questions, checklist_completed }) => {
    const dispatch = useDispatch()

    const answers = useSelector(() => adminChecklistAnswerList.getVisibleObjects())
    const is_loading_answers = useSelector(() => adminChecklistAnswerList.isLoading())

    const answer = head(filter(answers, function(x) {
        if(x.checklist_question === get(question, "id") ) {
            return x
        }
    }))

    let initial_values = Object.assign({ 'is_correct': get(answer, "is_correct") })
    // const answer = useSelector(() => adminChecklistAnswerList.getObject(get(question, "id")))
    // We need to get the answer if it exists in order to populate the form
    // The checklist result needs to contain the question with the answer as a child
    // There should only ever be one answer for one question and one checklist result
    // There should also only be one checklist_result for one checklist_schedule one-to-one relationship
    const answer_id = get(answer, "id")
    const question_id = get(question, "id")

    useEffect(() => {
        dispatch(adminChecklistQuestionList.ensureObjectLoaded())
        dispatch(adminChecklistAnswerList.fetchListIfNeeded())
        dispatch(adminChecklistAnswerList.ensureObjectLoaded(answer_id))
    },[])

    useEffect(() => {
      initial_values = Object.assign({ 'is_correct': get(answer, "is_correct") })
    },[answers, answer])

    const onSave = (values, formik_funcs) => {
        values.checklist_question = question_id
        values.checklist_result = checklist_result_id
        const on_ok = function(json) {
            dispatch(adminChecklistAnswerList.invalidateList())
            showSuccess("Saved", "Answer saved")
        }
        if ( answer_id ) {
            values.id = answer_id
            return dispatch(adminChecklistAnswerList.saveObject(values)).then((res) => handleSubmitResult({res, formik_funcs, on_ok}))
        } else {
            return dispatch(adminChecklistAnswerList.saveNewObject(values)).then((res) => handleSubmitResult({res, formik_funcs, on_ok}))
        }
    }

    return(is_loading_answers ? 'Loading answers' :
        <>
            <Formik
                initialValues={initial_values}
                validationSchema={validationSchema}
                onSubmit={onSave}
                validateOnBlur={false}
                validateOnChange={false}
            >
                { formik_props => {
                    const { values, isSubmitting, handleChange, errors } = formik_props
                    React.useEffect(() => {
                        if(values.is_correct != get(answer, "is_correct")) {
                            formik_props.submitForm(values)
                        }
                    }, [values])

                    return (
                        <>
                            <FormikCheckboxField
                                name="is_correct"
                                label={get(question, "text")}
                                formik_props={formik_props}
                                onChange={handleChange}
                                disabled={checklist_completed}
                            />
                            <br />
                        </>
                    )
                }}
            </Formik>
        </>
    )
}

export default AdminChecklistAnswerForm
