import { FormikFileSetField } from 'components/form/FileSetField'

const OrderDeliveryNoteForm = ({ formik_props }) => {
    return(
        <FormikFileSetField
            name="delivery_notes"
            label={"delivery note"}
            formik_props={formik_props}
            preview_only_one_file_per_row
        />
    )
}

export default OrderDeliveryNoteForm
