import { ItemList } from 'orm'
import { map } from 'lodash'

class NotificationModelList extends ItemList {
    getEntityKey() {
        return "admin/notification_model"
    }

    getAsOptions() {
        const items = this.getVisibleObjects()
        return map(items, function(item) {
          return { label: item.name, value: item.id }
        }
      )
    }
}

export const adminNotificationModelList = new NotificationModelList("admin_notification_model__default")
