import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { CommonTable } from 'components/CommonTable'
import { adminActiveNotificationModelList } from 'admin/components/notifications/actions/admin_active_notification_model'
import { adminUserNotificationSubscriptionList } from '../actions/admin_user_notification_subscription'
import { FormikGeneralFormErrors } from 'components/form/GeneralFormErrors'
import { FormikSwitchField } from 'components/form/SwitchField'
import { Formik, Form } from 'formik'
import { showSuccess, showError } from 'actions/Error'
import { handleSubmitResult } from 'actions/form'
import { capitalize, get, find } from 'lodash'
import Loading from 'components/Loading'

export function UserNotificationSubscriptionsForm( { user_id } ) {

  const dispatch = useDispatch()
  const user_notification_subscriptions = useSelector( () => adminUserNotificationSubscriptionList.getVisibleObjects() )
  const active_notification_models = useSelector( () => adminActiveNotificationModelList.getVisibleObjects() )
  const is_loading_notification_models = useSelector( () => adminActiveNotificationModelList.isLoading() )
  const is_loading_user_notification_subscriptions = useSelector( () => adminUserNotificationSubscriptionList.isLoading() )

  useEffect( () => {
    dispatch( adminUserNotificationSubscriptionList.updateListFilter( { user: user_id } ) )
    dispatch( adminUserNotificationSubscriptionList.fetchListIfNeeded() )
    dispatch( adminActiveNotificationModelList.fetchListIfNeeded() )
  }, [ user_id ] )

  if ( is_loading_user_notification_subscriptions ) {
    return <Loading />
  }

  const onSave = ( values, formik_funcs, modelId, modelName ) => {
    values.user = user_id
    values.notification_model = modelId
    const subscriptionExists = find( user_notification_subscriptions, { notification_model: modelId } )

    const on_ok = function( json , message ) {
      showSuccess( "Saved", message ) // Show the custom message
      dispatch( adminUserNotificationSubscriptionList.invalidateList() )
      dispatch( adminUserNotificationSubscriptionList.fetchListIfNeeded() )
    }

    if ( values[ `model-${ modelId }` ] ) {
      // User wants to subscribe
      if ( !subscriptionExists ) {
        // Create new subscription
        console.log(values)
        return dispatch( adminUserNotificationSubscriptionList.saveNewObject( values ) )
          .then( ( res ) => handleSubmitResult( { res, formik_funcs, on_ok, message: `You are now subscribed to ${modelName} notifications` } ) ) // Pass the custom message to handleSubmitResult
          .catch( ( error ) => {
            console.error( error )
          } )
      } else {
        // Set ID for existing subscription
        values.id = subscriptionExists.id
        values.notification_model = modelId
        // Update existing subscription
        return dispatch( adminUserNotificationSubscriptionList.saveObject( values ) )
          .then( ( res ) => handleSubmitResult( { res, formik_funcs, on_ok, message: `Your ${modelName} notification preferences have been updated` } ) ) // Pass the custom message to handleSubmitResult
          .catch( ( error ) => {
            console.error( error )
          } )
      }
    } else {
      // User wants to unsubscribe
      if ( subscriptionExists ) {
        // Delete existing subscription
        return dispatch( adminUserNotificationSubscriptionList.saveObject( values ) )
          .then( ( res ) => handleSubmitResult( { res, formik_funcs, on_ok, message: `Successfully deleted` } ) ) // Pass the custom message to handleSubmitResult
          .catch( ( error ) => {
            console.error( error )
          } )
      }
    }
  }

  const renderForm = ( item ) => {
    // Find the subscription that corresponds to this item.
    const subscription = user_notification_subscriptions.find( ( subscription ) => subscription.notification_model === item.id )

    // Check if the subscription exists and its notify attributes.
    const isCheckedModel = !!subscription
    const isCheckedNotifyInApp = subscription ? subscription.notify_in_app : false
    const isCheckedNotifyEmail = subscription ? subscription.notify_email : false

    const initial_values = { [ `model-${ item.id }` ]: isCheckedModel, [ 'notify_in_app' ]: isCheckedNotifyInApp, [ 'notify_email' ]: isCheckedNotifyEmail }

    return (
      <Formik
        initialValues={ initial_values }
        onSubmit={ ( values, formik_funcs ) => { onSave( values, formik_funcs, item.id, item.name ) } }
      >
        { formik_props => {
          const { values } = formik_props

          useEffect( () => {
            if ( values !== initial_values ) {
              formik_props.submitForm( values )
            }
          }, [ values ] )

          const handleChange = ( event ) => {
            const { name, checked } = event.target
            formik_props.setFieldValue( name, checked )
          }

          return (
            <Form>
              <FormikGeneralFormErrors render={ ( msg ) => msg } />
              <FormikSwitchField
                name={ `model-${ item.id }` }
                formik_props={ formik_props }
                color="primary"
                label="Subscribe"
                onChange={ handleChange }
              // onBlur={handleSwitchBlur}
              />
              <FormikSwitchField
                name="notify_in_app"
                formik_props={ formik_props }
                color="primary"
                label="In app"
                onChange={ handleChange }
              // onBlur={handleSwitchBlur}
              />
              <FormikSwitchField
                name="notify_email"
                formik_props={ formik_props }
                label="Email"
                color="primary"
                onChange={ handleChange }
              // onBlur={handleSwitchBlur}
              />
            </Form>
          )
        } }
      </Formik>

    )
  }

  const columns = [
    {
      field: 'name',
      title: 'Model',
      render: ( item ) => capitalize( get( item, "name" ) ),
      sort: ( direction ) => onUpdateListOrdering( direction === 'asc' ? 'name' : '-name' ),
    },
    {
      field: 'id',
      title: 'Subscribed',
      render: ( item ) => renderForm( item ),
      sort: ( direction ) => onUpdateListOrdering( direction === 'asc' ? 'create_notification' : '-create_notification' )
    },

  ]

  const onUpdateListOrdering = ( field ) => {
    dispatch( adminActiveNotificationModelList.updateListOrdering( field ) )
  }

  return (
    <CommonTable
      active_key="active_notification_models"
      rows={ active_notification_models }
      columns={ columns }
      item_list={ adminActiveNotificationModelList }
      canDelete={ false }
      is_loading={ is_loading_user_notification_subscriptions }
    />
  )
}
