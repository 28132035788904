import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { get } from 'lodash'
import * as Yup from 'yup'
import { FormikInputField } from 'components/form/InputField'
import { FormikDropdownField } from 'components/form/Dropdown'
import { ProductCategorySelectField } from 'admin/components/product_categories/form/ProductCategorySelectField'
import { FormikFileSetField } from 'components/form/FileSetField'
import { Grid, Typography, Button, Container } from '@material-ui/core'
import { Card } from 'components/layout/Card'
import { adminUOMList } from 'admin/components/uom/actions/admin_uom'
import { Separator } from 'components/layout/Separator'
import { ErrorMessage } from 'components/SuccessError'


export const validationSchema = Yup.object().shape( {
  // 'product_category': Yup.string().required( "Product category is required" ),
  'weight_kg': Yup.string().required(),
  'uom': Yup.string().required(),
} )

export default function AdminProductForm( { formik_props, inDrawer, product_category } ) {

  const dispatch = useDispatch()
  const uom_options = useSelector( () => adminUOMList.getAsOptions() )
  const [ showProductCategory, setShowProductCategory ] = React.useState( true )

  useEffect( () => {
    dispatch( adminUOMList.fetchListIfNeeded() )
  }, [] )

  useEffect( () => {
    if ( product_category ) {
      formik_props.setFieldValue( 'product_category', product_category )
    }
  }, [ product_category ] )

  const renderProductDetails = () => {
    return (
      <>
        { !inDrawer && !product_category ?
          <>
            <Typography variant="h6" gutterBottom>{ get( formik_props, [ "values", "product_category_name" ] ) }</Typography>
            { !showProductCategory && <Button onClick={ () => setShowProductCategory( true ) } size="small" color="primary">Show Product Category</Button> }
            { showProductCategory &&
              <>
                { renderProductCategory() }
                <Button onClick={ () => setShowProductCategory( false ) } size="small" color="primary">Hide Product Category</Button>
              </>
            }
          </> : null
        }
        <FormikInputField
          name="weight_kg"
          label="Weight (kg)"
          formik_props={ formik_props }
        />
        <Separator height={ 16 } />
        <FormikDropdownField
          name="uom"
          label="Unit of Measurement"
          options={ uom_options }
          formik_props={ formik_props }
        />
      </>
    )
  }

  const renderProductCategory = () => {
    return (
      <div style={ { marginBottom: -4 } }>
        <ProductCategorySelectField
          name="product_category"
          can_add={ true }
          can_edit={ true }
          formik_props={ formik_props }
          product_category_id={ get( product_category, 'id' ) }
          empty_label="Select Product Category"
          size="large"
          fullWidth
          required
        />
        { formik_props.isSubmitting && formik_props.errors.product_category && <ErrorMessage error={ formik_props.errors.product_category } /> }
      </div>
    )
  }

  const renderImageField = () => {
    return (
      <>
        <FormikFileSetField
          name="images"
          formik_props={ formik_props }
          label="Image"
          max_num_files={ 1 }
        />
      </>
    )
  }

  // const renderPriceFields = () => {
  //   return(
  //     <>
  //       <FormikInputField
  //           name="donated_value"
  //           label="Donated Value"
  //           formik_props={formik_props}
  //       />
  //       <FormikInputField
  //           name="price"
  //           label="Retail Price"
  //           formik_props={formik_props}
  //       />
  //     </>
  //   )
  // }

  // const renderProductVariations = () => {
  //   return (
  //     <Typography>Product Variations go here</Typography>
  //   )
  // }

  const renderGridForm = () => {
    return (
      <Grid container spacing={ 2 }>
        <Grid item xs={ 12 } md={ inDrawer ? 12 : 7 }>
          { renderProductDetails() }
        </Grid>
      </Grid>
    )
  }

  return (
    <>
      { inDrawer ? renderGridForm() :
        <Container maxWidth="xs">
          <Card
            title="Details"
            content={ renderProductDetails() }
          />
        </Container>
      }
    </>
  )

}