import React, { useState, useEffect } from 'react'
import { head, filter, map, get } from 'lodash'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useParams } from 'react-router-dom'
import DonorMainLayout from '../layout/DonorMainLayout'
import { handleSubmitResult } from 'actions/form'
import { MuiFileUploader } from 'components/form/MuiFileUploader'
import { Paper, Typography, Button, TextField } from '@material-ui/core'
import { FormikGeneralFormErrors } from 'components/form/GeneralFormErrors'
import DatePicker from "react-datepicker"
import { makeStyles } from '@material-ui/core/styles'
import { Grid } from '@material-ui/core'
import {Formik, Form, Field} from 'formik'
import { contactParcelList } from './actions/contact_parcel'
import { DonateFoodOrderForm } from './DonateFoodOrderForm'
import { contactCompanyReportGeneration } from '../reports/actions/contact_company_report_generation'
import { renderSection18Section, renderTotalOverrides } from 'components/section18a/Section18A'
import { Card } from 'components/layout/Card'

const useStyles = makeStyles((theme) => ({
    root: {
        padding: 15,
        [theme.breakpoints.down('sm')]: {
          padding: 0
        }
    }
}))

export function DonateFoodOrder() {
    const classes = useStyles()
    const dispatch = useDispatch()
    const history = useHistory()

    // Slightly hacky, only one of these fields will be valid at a time,
    // either we're creating a new order from an imported file, or we're editing an existing order.
    // Better to split this into two separate components which share the OrderForm part (maybe)
    const { order_id } = useParams()

    const parcel = useSelector(() => contactParcelList.getObject(order_id))
    const is_loading = useSelector(() => contactParcelList.isLoading())
    const is_busy = useSelector(() => contactParcelList.getIsSavingObject())

    useEffect(() => {
        dispatch(contactParcelList.ensureObjectLoaded(order_id))
    }, [])

    const onSaved = (order) => {
        if ( ! order_id ) {
            history.push(`/donor/donations/order/${order.id}`)
        }
    }

    return(
        <DonorMainLayout
          active_key="donate_food_order"
          breadcrumbs={[
              {name: 'donor_home'},
              {name: 'donations', label: "Donations", url: '/donor/donations/food'},
              {name: 'donate_food', label: "Donate Food", url: '/donor/donate/food'}
          ]}
          title={"Donate Food"}
        >
          <div className={classes.root}>
            <DonateFoodOrderForm
              order_id={order_id}
              onSaved={onSaved}
              parcel={parcel}
              is_loading={is_loading}
              is_busy={is_busy}
            />
          </div>

        </ DonorMainLayout>
    )
}
