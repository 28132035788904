import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { get, map, size } from 'lodash'
import AdminCommonListLayout from '../layout/AdminCommonListLayout'
import { adminKpiResultList } from './actions/admin_kpi_result'
import { kpi_users } from 'sampleData/kpi_users'
import { ShortId } from 'components/ShortId'
import { Timestamp } from 'components/Timestamp'
import { StatusIndicator } from 'components/layout/StatusIndicator'
import { UserName } from 'admin/components/users/UserAttributes'
import { Modal } from 'components/layout/Modal'
import { KpiTemplateName, KpiCategoryName } from 'admin/components/kpis/KpiAttributes'
import AdminResolveKpiResultForm from './form/AdminResolveKpiResultForm'
import AdminKpiCommentForm from './form/AdminKpiCommentForm'
import ChatBubbleOutlineOutlinedIcon from '@material-ui/icons/ChatBubbleOutlineOutlined'
import ChatOutlinedIcon from '@material-ui/icons/ChatOutlined'
import { Badge } from "@material-ui/core"

export default function AdminKpiResults({user_id}) {
    const dispatch = useDispatch()
    const history = useHistory()
    const kpi_results = useSelector(() => adminKpiResultList.getVisibleObjects())
    //console.log(kpi_results)
    const is_loading = useSelector(() => adminKpiResultList.isLoading())
    const filter = useSelector(() => adminKpiResultList.getFilter())
    const [editingResolveKpi, setEditingResolveKpi] = useState(null)
    const [kpiResult, setKpiResult] = useState(null)
    const [editingComments, setEditingComments] = useState(null)
    const [comments, setComments] = useState(null)

    useEffect(() => {
        dispatch(adminKpiResultList.fetchListIfNeeded())
    },[])

    const onEditKpiResult = (kpi_result_id) => {
        history.push({
            pathname: '/admin/kpis/result/' + kpi_result_id
        })
    }

    const onUpdateListOrdering = (field) => {
      dispatch(adminKpiResultList.updateListOrdering(field))
    }

    const getUserDashboards = (item) => {
        const dashboards = map(item.dashboards, 'name').join(", ")
        return dashboards
    }

    const onEditComments = (item) => {
        setKpiResult(item)
        setComments(get(item, "comments"))
        setEditingComments(true)
    }

    const renderKpiResultComments = (item) => {
        if(size(get(item, "comments")) > 0) {
            return(
                <Badge badgeContent={size(get(item, "comments"))} color="primary">
                    <ChatOutlinedIcon onClick={() => onEditComments(item)} style={{cursor: 'pointer'}} />
                </Badge>
            )

        }
        else {
            return <ChatBubbleOutlineOutlinedIcon onClick={() => onEditComments(item)} style={{cursor: 'pointer'}} />
        }
    }

    const renderResolveKpiForm = (item) => {
        setEditingResolveKpi(true)
        setKpiResult(item)
    }

    const onClose = () => {
        setKpiResult(null)
        setEditingResolveKpi(null)
        setEditingComments(null)
        setComments(null)
        dispatch(adminKpiResultList.invalidateList())
        dispatch(adminKpiResultList.fetchListIfNeeded())
    }

    const columns = [
        {
            field: 'passed',
            title: 'Status',
            width: 50,
            render: (item) => <StatusIndicator status={ get(item, "passed") } style={{ fontSize: 48 }} />,
            sort: (direction) => onUpdateListOrdering(direction === 'asc' ? 'passed' : '-passed')
        },
        {
            field: 'id',
            title: 'ID',
            width: 50,
            render: (item) => <ShortId value={get(item, "id")} />
        },
        {
            field: 'category',
            title: 'Category',
            render: (item) => <KpiCategoryName kpi_id={ get(item, "kpi") } />,
            sort: (direction) => onUpdateListOrdering(direction === 'asc' ? 'category' : '-category')
        },
        {
            field: 'kpi',
            title: 'KPI',
            render: (item) => <KpiTemplateName kpi_id={ get(item, "kpi") } />,
            sort: (direction) => onUpdateListOrdering(direction === 'asc' ? 'kpi' : '-kpi')
        },
        {
            field: 'user',
            title: 'User',
            render: (item) => <UserName user_id={ get(item, "user") } />,
        sort: (direction) => onUpdateListOrdering(direction === 'aseditingCommentsc' ? 'user' : '-user')
        },
        {
            field: 'closed',
            title: 'Resolved',
            render: (item) => <StatusIndicator type="check" status={ get(item, "closed") } style={{ fontSize: 24, cursor: 'pointer' }} onClick={() => renderResolveKpiForm(item)} />
        },
        {
            field: 'comments',
            title: 'Comments',
            render: (item) => renderKpiResultComments(item)
        },
        {
            field: 'calculated_at',
            title: 'Calculated at',
            render: (item) => <Timestamp value={item.calculated_at} format="at" />,
            sort: (direction) => onUpdateListOrdering(direction === 'asc' ? 'calculated_at' : '-calculated_at')
        },
    ]

    return (
        <>
        <AdminCommonListLayout
            active_key="kpi_results"
            breadcrumbs={[
                {name: 'admin_home'},
                {name: 'kpi_results', label: "KPI Results", url: '/admin/kpis/resuonSavedResolveKpiResultlts'}
            ]}
            title="KPI Results"
            can_delete={false}
            onEditRow={onEditKpiResult}
            is_loading={is_loading}
            rows={kpi_results}
            columns={columns}
            item_list={adminKpiResultList}
        />
        { editingResolveKpi &&
            <Modal onClose={onClose} title="Resolve KPI Result">
                <AdminResolveKpiResultForm kpi_result={ kpiResult } onSaved={onClose} />
            </Modal>
        }
        { editingComments &&
            <Modal onClose={onClose} title="Comments on KPI Result">
                <AdminKpiCommentForm comments={ comments } kpi_result={ kpiResult} onSaved={onClose} />
            </Modal>
        }
        </>
    )

}
