import React from 'react'
import {makeStyles, Typography} from '@material-ui/core'

const useStyles =makeStyles((theme) => ({
  title: {
    color: '#455a64',
    fontWeight: 500,
    marginBottom: 10,
    marginTop: 10,
    [theme.breakpoints.down('sm')]: {
      fontSize: '1.5em'
    },
  }
}))

export default function PageTitle({title}) {
  const classes = useStyles()

  return(
    <Typography variant="h4" className={classes.title}>{title}</Typography>
  )
}
