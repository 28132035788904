import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { FormikDropdownField } from 'components/form/Dropdown'
import { adminKpiDashboardCategoryList } from 'admin/components/kpis/actions/admin_kpi_dashboard_category'

export default function FormikKpiCategoryDropdownField({formik_props, name, label}) {
    const dispatch = useDispatch()
    const categories = useSelector(() => adminKpiDashboardCategoryList.getAsOptions())
    name = name ? name : "category"
    label = label ? label : "Select a category"

    useEffect(() => {
        dispatch(adminKpiDashboardCategoryList.fetchListIfNeeded())
    },[])

    return(
        <FormikDropdownField
            name="category"
            options={categories}
            formik_props={formik_props}
            label={label}
        />
    )
}
