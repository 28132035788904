import { ItemList } from 'orm'

class KpiDashboardList extends ItemList {
    getEntityKey() {
        return "admin/kpi_dashboard"
    }

}

export const adminKpiDashboardList = new KpiDashboardList("admin_kpi_dashboard__default")
