import React, {Component} from 'react'
import {connect} from 'react-redux'
import classNames from 'classnames'
import { padStart } from 'lodash'

class CurrencyValue extends Component {

    render() {
        const { value, align_right, align_center, prefix, enable_inline_spacing, use_span, className, showCents } = this.props

        var formatter = new Intl.NumberFormat('en-Za', {
            style: 'decimal',
            minimumFractionDigits: showCents !== false ? 2 : 0,
            maximumFractionDigits: showCents !== false ? 2 : 0,
            // style: 'currency',
            // currency: 'ZAR',
            // currencyDisplay: 'symbol',
        })

        const sign = value < 0 ? "-" : ""
        const formatted_currency = formatter.format(Math.abs(value) || 0)

        const content = [ enable_inline_spacing === true && <span key="cv1">&nbsp;</span>,
                          <span key="cv2">{ prefix || ""}{sign}R{formatted_currency}</span>,
                          enable_inline_spacing === true && <span key="cv3">&nbsp;</span>
                        ]
        if ( use_span === true ) {
            return (
                <span className={className}>
                  {content}
                </span>
            )
        } else {
            return (
                <div>
                  {content}
                </div>
            )
        }
    }
}

function mapStateToProps(state, props) {
    const { value, prefix, align_right } = props
    return {
        value: value,
        align_right,
        prefix
    }
}

export default connect(mapStateToProps)(CurrencyValue)
