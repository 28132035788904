/** @jsxImportSource @emotion/react */
import React from "react";
import { css } from "@emotion/react";

const SummaryTable = React.forwardRef(({ tableData }, ref) => {
  return (
    <table css={ table } ref={ ref }>
      <thead id="summaryTableHeader" css={ summary_table_header }>
        <tr id="header-row">
          { tableData.headers.length > 0 ? (
            tableData.headers.map((header, index) => (
              <th id={ index + header } css={ table_header_cell }>{ header }</th>
            ))
          ) : (
            <th />
          )}
        </tr>
      </thead>
      <tbody id="data-rows" css={ summary_table_data }>
        { tableData.rows.length > 0 ? (
          tableData.rows.map((row, index) => (
            <tr id={ index }>
              { row.map((cell, index) => (
                <td id={ index } css={ table_data_cell }>{ cell }</td>
              ))}
            </tr>
          ))
        ) : (
          <td />
        )}
      </tbody>
    </table>
  );
});

export default SummaryTable;


const table = css`
  border-collapse: collapse;
  background-color: unset;
  width: 100%;

  & th:not([align]) {
    color: rgb(244, 224, 57);
  }

  & th {
    padding: 0.2rem 0.2rem;
    border: 1px black solid;
  }

  & td {
    padding: 0.2rem 0.2rem;
    border: 1px black solid;
  }  
`

const summary_table_header = css`
  border: 1px solid rgb(0, 0, 0);
  padding-inline: 0.5rem;
  text-align: left;
  font-size: 0.7em;
  font-family: "Frankfurter Std Regular";  
  background-color: rgb(0, 0, 0);
  color: rgb(255, 255, 255);

  & :not(:last-child) {
  border-right: 1px solid white;
`

const summary_table_data = css`
  border: 1px solid rgb(0, 0, 0);
  padding-inline: 0.5rem;
  text-align: left;
  font-size: 0.7em;
  font-family: "Frankfurter Std Regular";

  & tr:nth-child(even) {
    background-color: rgb(244, 224, 57);
    border: 1px black solid;    
  }

  & tr:nth-child(odd) {
    background-color: rgb(255, 255, 255);
    border: 1px black solid;    
  }
`

const table_header_label = css`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 45%;
  padding-inline: 2rem;
  padding-block: 0;
  align-items: flex-start;

  @media print {
    font-size: 0.7rem;
  }
`

const table_header_cell = css`
  background-color: rgb(0, 0, 0);
  color: rgb(255, 255, 255);
`

const table_data_cell = css`
  color: rgb(0, 0, 0);

  @media print {
    font-size: 0.7rem;
  }
`
