import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { adminWarehouseAutoCompleteList } from '../actions/admin_warehouse'
import { FormikAutoCompleteDropdownWithFormBasedAddNew } from 'components/form/AutoCompleteDropdownWithFormBasedAddNew'
import AdminWarehouseForm from './AdminWarehouseForm'
import { adminWarehouseList } from '../actions/admin_warehouse'
import { showSuccess, showError } from 'actions/Error'
import * as Yup from 'yup'
import { handleSubmitResult } from 'actions/form'
import { validationSchema } from './AdminWarehouseForm'
import { useDispatch } from 'react-redux'
import { FormikDropdownField } from '../../../../components/form/Dropdown'

export function FormikWarehouseAutoCompleteDropdown( { name, label, formik_props, addNew, ...props } ) {

	const warehouse_options = useSelector( () => adminWarehouseList.getAsOptions() )
	const dispatch = useDispatch()

	useEffect( () => {
		dispatch( adminWarehouseList.fetchListIfNeeded() )
	}, [] )

	const WarehouseAutocompleteWithAddNewForm = (
		<FormikAutoCompleteDropdownWithFormBasedAddNew
			item_list={ adminWarehouseAutoCompleteList }
			success_message="Warehouse added"
			form_title="Add Warehouse"
			name={ name }
			validationSchema={ validationSchema }
			initial_values={ {} }
			label={ label }
			renderForm={ ( { formik_props } ) =>
				<AdminWarehouseForm formik_props={ formik_props } in_drawer />
			}
			formik_props={ formik_props }
			{ ...props }
		/>
	)

	const WarehouseDropdown = (
		<FormikDropdownField
			name={ name }
			label={ label }
			options={ warehouse_options }
			formik_props={ formik_props }
			{ ...props }
		/>
	)

	return addNew ? WarehouseAutocompleteWithAddNewForm : WarehouseDropdown
}
