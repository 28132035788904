import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { adminFoodItemList } from './actions/admin_food_item'
import { adminNutrientList } from './actions/admin_nutrient'
import { get } from 'lodash'
import Loading from 'components/Loading'

export const FoodItemField = ({ food_item_id, field }) => {
    const dispatch = useDispatch()
    const food_item = useSelector(() => adminFoodItemList.getObject(food_item_id))
    const is_loading = useSelector(() => adminFoodItemList.isLoading())

    useEffect(() => {
        dispatch(adminFoodItemList.ensureObjectLoaded(food_item_id))
    },[])

    return is_loading ? <Loading size={20} /> : get(food_item, field, "Food item not loading...")
}

export const NutrientField = ({ nutrient_id, field }) => {
    const dispatch = useDispatch()
    const nutrient = useSelector(() => adminNutrientList.getObject(nutrient_id))
    const is_loading = useSelector(() => adminNutrientList.isLoading())

    useEffect(() => {
        dispatch(adminNutrientList.ensureObjectLoaded(nutrient_id))
    },[])

    return is_loading ? <Loading size={20} /> : get(nutrient, field, "Nutrient not loading...")
}
