import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { get } from 'lodash'
import { Separator } from 'components/layout/Separator'
import { Formik, Form, Field } from 'formik'
import * as Yup from 'yup'
import {showSuccess, showError} from 'actions/Error'
import { FormikInputField } from 'components/form/InputField'
import { FormikCheckboxField } from 'components/form/CheckboxField'
import { FormikMultiSelectDropdownField } from 'components/form/Dropdown'
import { adminEmployeeRoles } from 'admin/actions/admin_dropdown_options'
import Timestamp from 'components/Timestamp'
import { FormikFileSetField } from 'components/form/FileSetField'
import { Grid } from '@material-ui/core'
import { Card } from 'components/layout/Card'

export const validationSchema = Yup.object().shape({
    'email': Yup.string().required("Required"),
    'first_name': Yup.string().required("Required"),
})

export default function BeneficiaryUserForm({formik_props}) {
    const dispatch = useDispatch()
    const employee_role_options = useSelector(() => adminEmployeeRoles.getAsOptions())

    //const employee_role_options = useSelector(() => beneficiaryEmployeeRoles.getAsOptions())

    useEffect(() => {
        dispatch(adminEmployeeRoles.fetchListIfNeeded())
    },[])

    return (
            <Grid container spacing={2}>

              <Grid item xs={12} lg={8}>

                <Card title="Details"
                      content={
                        <>
                        <FormikInputField name="first_name"
                                  type="text"
                                  label="First name"
                                  formik_props={formik_props}
                                  />
                        <FormikInputField name="last_name"
                                          type="text"
                                          label="Last name"
                                          formik_props={formik_props}
                                          />
                        <FormikInputField name="email"
                                          type="email"
                                          label="Email"
                                          formik_props={formik_props}
                                          />

                        <FormikInputField name="phone_number"
                                          label="Mobile phone number"
                                          formik_props={formik_props}
                                          />
                        </>
                    }
                />

              </Grid>

              {/*
                <Grid item xs={12} lg={3}>

                { get(formik_props, ["values", "employee"]) &&
                  <Card title="Roles"
                        content={<FormikMultiSelectDropdownField name="employee.roles"
                                                  label="Roles"
                                                  options={employee_role_options}
                                                  formik_props={formik_props}
                                                  />
                                }
                  />
                }

                <Card title="Permissions"
                      content={
                        <>
                          <FormikCheckboxField name="is_active"
                                       label="Can Login"
                                       formik_props={formik_props}
                          />
                          <br />
                          <FormikCheckboxField name="is_staff"
                                       label="Is Staff"
                                       formik_props={formik_props}
                          />
                          <br />
                          <FormikCheckboxField name="is_superuser"
                                       label="Is Admin"
                                       formik_props={formik_props}
                          />
                      </>
                    }
              />

            </Grid>
            */}

            <Grid item xs={12} lg={4}>

              <Card title="Profile image"
                    content={<FormikFileSetField max_num_files={1}
                                    name="profile_images"
                                    formik_props={formik_props} />
                            }
              />

            </Grid>

        </Grid>

      )

}
