import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useParams } from 'react-router-dom'
import { get } from 'lodash'
import { adminBrandList } from './actions/admin_brand'
import { Separator } from 'components/layout/Separator'
import { Formik, Form, Field } from 'formik'
import * as Yup from 'yup'
import {showSuccess, showError} from 'actions/Error'
import { handleSubmitResult } from 'actions/form'
import AdminCommonFormLayout from '../layout/AdminCommonFormLayout'
import { AdminBrandForm, validationSchema} from './form/AdminBrandForm'

export default function AdminBrand() {
    const dispatch = useDispatch()
    const history = useHistory()
    const { brand_id } = useParams()
    const brand = useSelector(() => adminBrandList.getObject(brand_id))
    console.log(brand_id)
    const initial_values = brand
    const is_loading = useSelector(() => adminBrandList.isLoading()) || (brand_id && !get(brand, "id"))
    const is_busy = useSelector(() => adminBrandList.getIsSavingObject())
    const title = `${get(brand, ["display_name"], 'New Brand')}`

    useEffect(() => {
        dispatch(adminBrandList.ensureObjectLoaded(brand_id))
    },[])

    const onSave = (values, formik_funcs) => {
        const on_ok = function(json) {
            dispatch(adminBrandList.invalidateList())
            showSuccess("Saved", "Brand saved")

            if ( ! brand_id ) {
                history.push(`/admin/brand/${json.id}`)
            }
        }
        if ( brand_id ) {
            values.id = brand_id
            return dispatch(adminBrandList.saveObject(values)).then((res) => handleSubmitResult({res, formik_funcs, on_ok}))
        } else {
            return dispatch(adminBrandList.saveNewObject(values)).then((res) => handleSubmitResult({res, formik_funcs, on_ok}))
        }
    }

    const getCellValue = (header_key, item, index) => {
        switch( header_key ) {
            default:
                return undefined
        }
    }

    return (
        <AdminCommonFormLayout
            breadcrumbs={[
                {name: 'admin_home'},
                {name: 'brands', label: 'Brands', url: '/admin/brands'},
                {name: 'brand', label: get(brand, ["display_name"], 'New Brand'), url: `/admin/brand/${brand_id}`}
            ]}
            is_busy={is_busy}
            is_loading={is_loading}
            title={title}
            initial_values={initial_values}
            validationSchema={validationSchema}
            onSave={onSave}
            noCard
        >
          {
              ({formik_props}) => <AdminBrandForm formik_props={formik_props} />
          }
        </AdminCommonFormLayout>
    )
}
