import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useParams } from 'react-router-dom'
import { get } from 'lodash'
import { adminProductCategoryList } from './actions/admin_product_category'
import { Separator } from 'components/layout/Separator'
import * as Yup from 'yup'
import { showSuccess, showError } from 'actions/Error'
import { handleSubmitResult } from 'actions/form'
import AdminCommonFormLayout from '../layout/AdminCommonFormLayout'
import { FormikInputField } from 'components/form/InputField'
import { ProductCategorySelectField } from './form/ProductCategorySelectField'
import { NutritionFactsFoodItem } from '../nutrition/NutritionFactsFoodItem'
import { FormikNutritionFoodItemAutoCompleteDropdown } from '../nutrition/form/NutritionFoodItemAutoCompleteDropdown'
import { Grid } from '@material-ui/core'
import { Card } from 'components/layout/Card'
import AdminProductVariations from '../products/AdminProductVariations'
import { Alert } from '@material-ui/lab'
import Loading from 'components/Loading'
import { FormikAutoCompleteDropdown } from 'components/form/AutoCompleteDropdown'
import { useGetProductCategoryById } from '../../api/product_category'

const yup_shape = {
	'name': Yup.string().required( "Required" ),
}

const validationSchema = Yup.object().shape( yup_shape )

const AdminProductCategory = () => {
	const history = useHistory()
	const dispatch = useDispatch()
	const { product_category_id } = useParams()
	const { product_category, is_loading, error } = useGetProductCategoryById( product_category_id )

	const initial_values = product_category || {}
	const title = get( product_category, [ "name" ], "New product category" )

	const onSave = ( values, formik_funcs ) => {
		const on_ok = function( json ) {
			showSuccess( "Saved", "Product Category saved" )
			if ( json.id ) {
				history.push( `/admin/product_category/${ json.id }` )
			}
		}

		if ( product_category_id ) {
			values.id = product_category_id
			return dispatch( adminProductCategoryList.saveObject( values ) ).then( ( res ) => handleSubmitResult( { res, formik_funcs, on_ok } ) )
		} else {
			return dispatch( adminProductCategoryList.saveNewObject( values ) ).then( ( res ) => handleSubmitResult( { res, formik_funcs, on_ok } ) )
		}
	}

	const renderNoProductCategory = () => {
		return (
			<Alert severity="info">To add product variations first create the product category.</Alert>
		)
	}

	const renderForm = ( formik_props ) => {
		const { values } = formik_props

		return (
			<Grid
				container
				direction="row"
				justifyContent="center"
				alignItems="flex-start"
				spacing={ 2 }
			>
				<Grid item xs={ 12 } md={ 6 }>
					<Card
						title="Product Details"
						content={
							<>
								{ is_loading ? <Loading message={ product_category_id ? 'Loading product details...' : 'Loading...' } /> :
									<>
										<ProductCategorySelectField
											name="parent"
											can_add={ false }
											formik_props={ formik_props }
											product_category_id={ product_category_id }
											empty_label="Select Parent category"
											fullWidth
										/>

										{ values?.name ?
											<FormikInputField
												name="name"
												label="Name"
												formik_props={ formik_props }
												spellCheck={ true }
											/>
											:
											<FormikAutoCompleteDropdown
												item_list={ adminProductCategoryList }
												name="name"
												label="Name"
												freeSolo={ true }
												formik_props={ formik_props }
											/>
										}
										<FormikInputField
											name="alternative_names"
											label="Alternative names"
											helperText="Comma separated list of alternative names"
											formik_props={ formik_props }
											spellCheck={ true }
										/>
									</>
								}
							</>
						}
					/>
					{ product_category_id && product_category &&
						<Card
							title="Nutrition information"
							content={
								<>
									{ is_loading ? <Loading message="Loading nutrition details..." /> :
										<>
											<Separator height={ 15 } />
											<FormikNutritionFoodItemAutoCompleteDropdown
												name="nutrition_food_item"
												label="Nutrition Food Item"
												formik_props={ formik_props }
											/>
											<Separator height={ 23 } />
											{ values?.nutrition_food_item && (
												<NutritionFactsFoodItem food_item_id={ values?.nutrition_food_item } />
											) }
										</>
									}
								</>
							}
						/>
					}
				</Grid>
				{ product_category_id &&
					<Grid item xs={ 12 } md={ 6 }>
						<Card
							title="Product Variations"
							content={
								<div style={ { paddingTop: 10 } }>
									{
										is_loading ? <Loading message="Loading product variations..." /> : product_category_id && product_category ? <AdminProductVariations product_category_id={ product_category_id } /> : renderNoProductCategory()
									}
								</div>
							}
							noPadding
						/>
					</Grid>
				}
			</Grid>
		)
	}

	return (
		<AdminCommonFormLayout
			breadcrumbs={ [
				{ name: "admin_home" },
				{
					name: "product_categoriess",
					label: "Products",
					url: "/admin/products"
				},
				{
					name: "product_category",
					label: get( product_category, [ "name" ], "New product category" ),
					url: `/admin/product_category/${ product_category_id }`
				}
			] }
			is_busy={ is_loading }
			is_loading={ is_loading }
			title={ title }
			initial_values={ initial_values }
			validationSchema={ validationSchema }
			noCard
			onSave={ onSave }
		>
			{
				( { formik_props } ) => renderForm( formik_props )
			}
		</AdminCommonFormLayout>
	)
}

export default AdminProductCategory

