import React from 'react'
import { IconButton, InputAdornment, TextField } from "@material-ui/core"
import { makeStyles } from '@material-ui/core/styles'
import SearchIcon from "@material-ui/icons/Search"

const useStyles = makeStyles((theme) => ({
  root: {
    padding: '0px 12px',
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    border: '1px solid #dddddd',
    borderRadius: 15
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
    fontSize: 28
  },
  iconButton: {
    padding: '0 12px',
  },
  divider: {
    height: 68,
    margin: '0 12px',
  },
  searchInput: {
    background: 'white',
  }
}))

export default function SearchFilter({title}) {
  const classes = useStyles()

  return (
    <TextField
    label={'Search ' + title + '...'}
    fullWidth
    variant="outlined"
    className={classes.searchInput}
    InputProps={{
      endAdornment: (
        <InputAdornment>
          <IconButton>
            <SearchIcon />
          </IconButton>
        </InputAdornment>
      )
    }}
  />
  )
}
