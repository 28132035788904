import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { get, values, union, map, filter } from 'lodash'
import { useParams } from 'react-router-dom'
import BeneficiaryMainLayout from '../layout/BeneficiaryMainLayout'
import {
  makeStyles,
  Grid,
  Typography,
  Card,
  CardContent,
  CardActions,
  CardActionArea,
  Button,
  Collapse,
  IconButton,
  Tooltip,
  Link,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  FormLabel
} from '@material-ui/core'
// import { MapWithMarkers } from 'components/map/MapMarkers'
import { CovidNotice } from '../notices/Notices'
import { contactDashboardList } from './actions/contact_dashboard'
import { contactCompanyList } from 'actions/contact/company'
import { BeneficiaryDashboardWidgets } from './widgets/BeneficiaryDashboardWidgets'
import Alert from '@material-ui/lab/Alert'
import { getActiveCompanyId, getActiveCompanyDefaultAddress } from 'actions/active_company'

const useStyles = makeStyles((theme) => ({
    root: {
      padding: 15
    },
    title: {
        fontSize: 14,
    },
    pos: {
        marginBottom: 12,
    },
    cardActionButton: {
        marginBottom: -25,
    },
    expand: {
        transform: 'rotate(0deg)',
        marginLeft: 'auto',
        transition: theme.transitions.create('transform', {
            duration: theme.transitions.duration.shortest,
        }),
    },
    expandOpen: {
        transform: 'rotate(180deg)',
    },
    welcomeHeading: {
        fontSize: 26,
        marginTop: 10,
        marginBottom: 10,
        marginLeft: 15,
        [theme.breakpoints.down('sm')]: {
            fontSize: 21,
        },
    },
    dashName: {
        fontSize: 18
    }
}))

export default function BeneficiaryDashboard({...props}) {
    const classes = useStyles()
    const dispatch = useDispatch()
    const company_id = getActiveCompanyId()
    const dashboardData = useSelector(() => contactDashboardList.getForCompany(company_id))
    const companies = useSelector(() => contactCompanyList.getVisibleObjects())
    const company = useSelector(() => contactCompanyList.getObject(company_id))

    map(values(companies), (company) => company.marker_type=company.company_type)
    const active_company_default_address = getActiveCompanyDefaultAddress()
    const center = { lat: get(active_company_default_address, "latitude"), lng: get(active_company_default_address, "longitude") }

    React.useEffect(() => {
        dispatch(contactDashboardList.ensureObjectLoaded())
        dispatch(contactCompanyList.updatePaginationNumItemsPerPage(1000))
        dispatch(contactCompanyList.fetchListIfNeeded())
        localStorage.setItem("showBeneficiaryWelcome", false)
    }, [])

    let showBeneficiaryWelcome = localStorage.getItem('showBeneficiaryWelcome') ? localStorage.getItem('showBeneficiaryWelcome') : localStorage.setItem("showBeneficiaryWelcome", true)

    const onCloseWelcome = () => {
      showBeneficiaryWelcome = false
    }

    return (
        <BeneficiaryMainLayout active_key="donor"
                         title="Dashboard"
                         breadcrumbs={[{name: 'donor_home'},
                                       {name: 'dashboard', label: "Dashboard", url: '/donor'}]}
        >
        <CovidNotice />

        { showBeneficiaryWelcome == true &&
          <Alert onClose={() => onCloseWelcome()}>
            <Typography variant="h5" className={classes.welcomeHeading}>Welcome to your Dashboard</Typography>
          </Alert>
        }

        <BeneficiaryDashboardWidgets data={dashboardData} />

        <Typography variant="h5" className={classes.welcomeHeading}>Impact Map</Typography>

        {/* <MapWithMarkers markers={markers} width={"100%"} height={700} center={center} /> */}

        </BeneficiaryMainLayout>
    )
}
