import React from 'react'
import { get } from 'lodash'
import { FormikDropdownField } from 'components/form/Dropdown'
import { FormikInputField } from 'components/form/InputField'
import { FormikTextareaField } from 'components/form/TextareaField'
import { FormikCheckboxField } from 'components/form/CheckboxField'
import { FormikGeneralFormErrors } from 'components/form/GeneralFormErrors'
import { useDispatch } from 'react-redux'
import { countryList } from 'actions/country'
import { provinceList } from 'actions/province'
import { useEffect } from 'react'
import { CommonTable } from 'components/CommonTable'
import BeneficiaryMainLayout from './BeneficiaryMainLayout'
import BeneficiaryTableFilter from './BeneficiaryTableFilter'
import { Separator } from 'components/layout/Separator'
import { CircularProgress, Button, Typography, Paper } from '@material-ui/core'
import BusyMask from 'components/BusyMask'
import Loading from 'components/Loading'
import { Formik, Form, FieldArray, Field } from 'formik'
import { makeStyles } from '@material-ui/core/styles'
import Title from 'components/layout/Title'
import { FormSkeleton } from 'components/layout/loading/Skeleton'

const useStyles = makeStyles((theme) => ({
  saveBar: {
    position: 'fixed',
    bottom: 0,
    right: 0,
    width: '100%',
    padding: '10px 15px',
    zIndex: 99,
    background: '#ffffff',
    boxShadow: '0px -4px 3px rgba(239, 239, 239, 0.5)',
  },
  buttonProgress: {
    color: '#ffffff',
    marginRight: 10,
  },
  formPaper: {
    padding: 15,
    [theme.breakpoints.down('sm')]: {
      padding: 0
    }
  }
}))

const BeneficiaryCommonFormLayout = ({
  breadcrumbs,
  actions,
  is_busy,
  is_loading,
  isSubmitting,
  title,
  initial_values,
  onSave,
  validationSchema,
  save_label,
  children,
  noCard,
  afterFormChildren
}) => {

    const classes = useStyles()

    return (

        <BeneficiaryMainLayout
          breadcrumbs={breadcrumbs}
          actions={actions}
          title={title}
        >
          <Paper className={classes.formPaper} square variant="outlined">

            <Formik
              initialValues={initial_values}
              onSubmit={onSave}
              enableReinitialize={true}
              validationSchema={validationSchema}
            >
              {formik_props => {
                  const { values } = formik_props
                  return (
                    <>
                      <Form>
                        <FormikGeneralFormErrors render={(msg) => msg} />
                          { is_loading ? <FormSkeleton /> : <>{ children({formik_props: formik_props}) }</> }
                          <Separator height={40} />
                          <div className={classes.saveBar}>
                            <Button variant="contained" size="large" type="submit" color="primary" disabled={isSubmitting} style={{float: "right"}}>
                              { is_busy ?
                                <><CircularProgress size={28}  className={classes.buttonProgress} /> Saving...</> :
                                <>{ save_label || "SAVE" }</>
                              }
                            </Button>
                          </div>
                      </Form>
                    </>
                  )}
              }
            </Formik>
          {afterFormChildren}
          </Paper>
        </BeneficiaryMainLayout>
    )

}

export default BeneficiaryCommonFormLayout
