import React, {useState} from 'react'
import { includes, isEmpty } from 'lodash'
import ImagePreview from './ImagePreview'
import PdfPreview from './PdfPreview'
import { Grid, Button, Typography } from '@material-ui/core'
import placeholder from 'assets/images/placeholder_dark.png'
import { Modal } from 'components/layout/Modal'
import { formatBytes } from '../lib/converters'
import { Separator } from './layout/Separator'

export const MediaPreviewPopup = ({file, render_preview_trigger}) => {

    const [isPreviewVisible, setIsPreviewVisible] = useState(false)
    const [currentPageNumber, setCurrentPageNumber] = useState(1)
    const supports_navigation = file.content_type === "application/pdf"

    const showPreview = () => {
        setIsPreviewVisible(true)
    }

    const hidePreview = () => {
        setIsPreviewVisible(false)
    }

    const onNextPage = () => {
        let new_page_number = currentPageNumber+1
        if ( new_page_number > file.page_count ) {
            new_page_number = file.page_count
        }
        setCurrentPageNumber(new_page_number)
    }

    const onPrevPage = () => {
        let new_page_number = currentPageNumber-1
        if ( new_page_number < 1 ) {
            new_page_number = 1
        }
        setCurrentPageNumber(new_page_number)
    }

    const renderTitle = ({supports_navigation}) => {
        return (
            <Grid container justifyContent="space-between">
              <Grid item>
                <Typography>{file.visible_filename}</Typography>
              </Grid>
              <Grid item>
                <div style={{marginRight: 30, marginTop: -5}}><Typography variant="caption">{formatBytes(file.size_bytes)}</Typography></div>
              </Grid>
            </Grid>
        )
    }

    return (
        <div>
        { !isEmpty(file) && render_preview_trigger && render_preview_trigger(showPreview) }
        { !isEmpty(file) && !render_preview_trigger && (
            <Button variant="contained" color="primary" onClick={ showPreview } >
              Preview
            </Button>
        )}

        { isPreviewVisible &&

          <Modal title="Preview"
                 onClose={hidePreview}
                 title={renderTitle({supports_navigation})}
          >

            <div className="render-preview" style={{padding: 15, textAlign: 'center'}}>
              { includes(file.content_type, "image/") &&
                <ImagePreview imageFile={file} />
              }
              { file.content_type === "application/pdf" &&
                <PdfPreview pdf_file={file} page_number={currentPageNumber} renderNavigation={CustomNavigation} />
              }
            </div>

          </Modal>
        }
        </div>
    )
}

const CustomNavigation = props => {
    const { page, pages, handlePrevClick, handleNextClick } = props
    if ( pages <= 1 ) {
        return null
    }
    return (
        <div>
          { page > 1 && <div onClick={handlePrevClick}>Prev</div> }
          <Separator variant="w10" />
          {page}
          <Separator variant="w14" />
          { page < pages && <div onClick={handleNextClick}>Next</div> }
        </div>
    )
}
