import React from 'react'
import donorTheme from 'themes/donorTheme'
import { ThemeProvider } from '@material-ui/styles'
import { useMailchimp, Status } from 'integrations/mailchimp/MailchimpHooks'
import Loading from 'components/Loading'
import { SuccessMessage, ErrorMessage} from 'components/SuccessError'
import { TextField, Button } from '@material-ui/core'

const SubscribeForm = () => {
  const [state, setState] = React.useState({
    firstName: '',
    lastName: '',
    email: ''
  })
  const mailchimp_url = window.LOCAL_SETTINGS.MAILCHIMP_URL
  const {subscribe, status, error, msgValue} = useMailchimp(mailchimp_url)

  function handleInputChange(evt) {
    const value = evt.target.type === "checkbox" ? evt.target.checked : evt.target.value;

    setState({
      ...state,
      [evt.target.name]: value
    })
  }

  const handleSubmit = () => {
    // We are performing some simple validation here.
    // I highly recommend checking a fully-fledged solution
    // for forms like https://react-hook-form.com/
    if (state.firstName === '' || state.email === '') {
      alert('Please fill out the form')
      return
    }

    subscribe({
      FNAME: state.firstName,
      LNAME: state.lastName,
      EMAIL: state.email,
    })
  }

  if (status === Status.loading) {
    return <Loading />
  }

  if (status === Status.error) {
    return <ErrorMessage error={error}/>
  }

  if (msgValue.includes('Already subscribed')) {
    return <SuccessMessage subscribed />
  }

  if (msgValue) {
    return <SuccessMessage />
  }

  return (
    <ThemeProvider theme={donorTheme}>
      <form onSubmit={handleSubmit}>
        <TextField name="firstName" value={state.firstName} onChange={handleInputChange} label="First name" variant="outlined" />
        <TextField name="lastName" value={state.lastName} onChange={handleInputChange} label="Last name" variant="outlined" />
        <TextField name="email" type="email" value={state.email} onChange={handleInputChange} label="Email" variant="outlined" />

        <Button onClick={handleSubmit} variant="contained" size="large" color="primary">Subscribe 📨</Button>
      </form>
    </ThemeProvider>
  )
}

export default SubscribeForm
