import { Grid, Typography } from "@material-ui/core"
import KpiWidgetSummary from 'components/KpiWidgetSummary'
import { get, toString } from 'lodash'
import Skeleton from '@material-ui/lab/Skeleton'

export const getNutritionScore = (score) => {
  let nutrition_score_alert
  let nutrition_score_color

  if (score < 2) {
    nutrition_score_alert = 'error'
    nutrition_score_color = '#d32f2f'
  }
  else if (score >= 2 && score <= 3) {
    nutrition_score_alert = 'warning'
    nutrition_score_color = '#ed6c02'
  }

  else if (score > 3) {
    nutrition_score_alert = 'success'
    nutrition_score_color = '#2e7d32'
  }

  return { nutrition_score_alert, nutrition_score_color }
}

export default function NutritionDashboardKpis({ data, is_loading, filters, days_in_range }) {
  const nutrition_score = get(data, "nutrition_score")
  const parcel_count = get(data, "parcel_count")
  const beneficiary_count = get(data, "beneficiary_count")
  const nutrition_score_trend = get(data, "nutrition_score_trend")
  const parcel_count_trend = get(data, "parcel_count_trend")
  const beneficiary_count_trend = get(data, "beneficiary_count_trend")
  const { nutrition_score_alert, nutrition_score_color } = getNutritionScore(nutrition_score)

  const { cities, end_at, start_at, to_company } = filters

  return (
    <Grid container spacing={3} style={{ marginTop: 10 }}>
      <Grid item xs={12}>
        <Typography variant="h6">Showing { days_in_range } days | Start at: { start_at } | End at: { end_at }</Typography> 
      </Grid>

        <Grid item xs={12} md={4}>
          { is_loading ? <Skeleton variant="rect" height={120} /> :
          <KpiWidgetSummary
            title="Nutrition score"
            total={nutrition_score}
            trend={nutrition_score_trend}
            color={nutrition_score_alert}
            // icon="ant-design:apple-filled"
          />
          }
        </Grid>

        <Grid item xs={12} md={4}>
          { is_loading ? <Skeleton variant="rect" height={120} /> :
          <KpiWidgetSummary
            title="Parcels"
            total={parcel_count}
            trend={parcel_count_trend}
            color="general"
            // icon="ant-design:apple-filled"
          />
          }
        </Grid>

        <Grid item xs={12} md={4}>
          { is_loading ? <Skeleton variant="rect" height={120} /> :
          <KpiWidgetSummary
            title="Beneficiaries"
            total={beneficiary_count}
            trend={beneficiary_count_trend}
            color="general"
            // icon="ant-design:windows-filled"
          />
          }
        </Grid>

        {/* <Grid item xs={12} sm={6} md={3}>
          { is_loading ? <Skeleton variant="rect" height={120} /> :
          <KpiWidgetSummary
            title="Region"
            total={cities}
            format={'string'}
            color="general"
            icon="ant-design:windows-filled"
          />
          }
        </Grid> */}

    </Grid>
  )
}