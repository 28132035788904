import React from "react";
import {Chart} from 'chart.js';
import { Doughnut } from "react-chartjs-2";
import { KpiChartOptions } from "./ChartOptions";
import ChartDataLabels from 'chartjs-plugin-datalabels';

const doughnutCenterLabel = {
  id: "doughnutCenterLabel",
  beforeDatasetsDraw(chart, args, pluginOptions){
    const {ctx, data} = chart;
    ctx.save();
    const xCoor = chart.getDatasetMeta(0).data[0].x
    const yCoor = chart.getDatasetMeta(0).data[0].y
    ctx.font = "bold 1.7rem 'Frankfurter Std Regular'";
    ctx.fillStyle = 'rgb(0,0,0)';
    ctx.textAlign='center';
    ctx.baseline = 'middle';
    ctx.lineCap = 2;
    ctx.lineWidth = 'round';
    ctx.fillText('PRODUCTS DONATED', xCoor, yCoor-15);
    ctx.fillText('AS % OF TOTAL', xCoor, yCoor+15);
  }
}
Chart.register(ChartDataLabels);

const KpiChart = React.forwardRef(({ data }, ref) => {
  return <Doughnut options={KpiChartOptions}
  data={data}
  plugins={[ChartDataLabels, doughnutCenterLabel]} 
  ref={ref}/>;
});

export default KpiChart;
