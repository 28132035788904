import { useEffect, useState } from 'react'
import { get, map, mapValues, isEmpty, range } from 'lodash'
import { useDispatch, useSelector } from 'react-redux'
import { adminKpiList } from '../actions/admin_kpi'
import { FormikInputField } from 'components/form/InputField'
import { FormikGeneralFormErrors } from 'components/form/GeneralFormErrors'
import { Formik, Form, FieldArray } from 'formik'
import FormCard from 'components/layout/FormCard'
import * as Yup from 'yup'
import { capitalCamel } from 'components/CapitalCamel'
import { Typography, Divider, Grid, Radio, RadioGroup, FormControlLabel, FormControl, FormLabel } from "@material-ui/core"
import { Alert } from '@material-ui/lab'
import { showSuccess, showError } from 'actions/Error'
import { handleSubmitResult } from 'actions/form'
import { Frequency, FrequencyValues } from 'components/form/Frequency'
import FormikKpiCategoryDropdownField from 'admin/components/kpis/form/KpiCategoryDropdown'

export const validationSchema = Yup.object().shape({
    'category': Yup.string().required("Required"),
})

const AdminKpiSettingsForm = ({ kpi_id, kpi_dashboard_id, kpi_template, onSaved }) => {
    const dispatch = useDispatch()
    const kpi = useSelector(() => adminKpiList.getObject(kpi_id))

    const initial_values = kpi ? kpi : {}
    let kpi_template_id = get(kpi_template, "id")
    let kpi_thresholds = get(kpi_template, "kpi_threshold_templates")
    let kpi_template_name = get(kpi_template, "name")
    if(!isEmpty(kpi)) {
        kpi_dashboard_id = kpi.kpi_dashboard
        kpi_template_id = kpi.kpi_template
        kpi_thresholds = kpi.kpi_thresholds
        kpi_template_name = get(kpi, ["kpi_template", "name"])
    }
    kpi_template_name = capitalCamel(kpi_template_name)
    const [momentType, setMomentType] = useState(null)
    console.log(kpi_template_name);

    useEffect(() => {
        dispatch(adminKpiList.ensureObjectLoaded(kpi_id))
    },[])

    const onSave = (values, formik_funcs) => {
        values.kpi_dashboard = kpi_dashboard_id
        values.kpi_template = kpi_template_id
        values.calculation_end_at.moment_type = values.calculation_start_at.moment_type
        const on_ok = function(json) {
            dispatch(adminKpiList.invalidateList())
            showSuccess("Saved", kpi_template_name + " Settings Saved")
            onSaved()
        }
        if ( kpi_id ) {
            values.id = kpi_id
            return dispatch(adminKpiList.saveObject(values)).then((res) => handleSubmitResult({res, formik_funcs, on_ok}))
        }
        else {
           return dispatch(adminKpiList.saveNewObject(values)).then((res) => handleSubmitResult({res, formik_funcs, on_ok}))
       }
    }

    const setThreshHoldFieldMeta = (formik_props, index, kpitt) => {
        formik_props.setFieldValue(`kpi_thresholds.${index}.kpi_threshold_template.id`, kpitt.id)
        formik_props.setFieldValue(`kpi_thresholds.${index}.kpi_threshold_template.name`, kpitt.name)
    }

    const getThresholdLabel = (kpitt) => {
        var label = !isEmpty(kpi) ? get(kpitt, ['kpi_threshold_template', 'name']) : get(kpitt, ['name'])
        return label
    }

    return(
        <Formik
            initialValues={initial_values}
            onSubmit={onSave}
            enableReinitialize={true}
            validationSchema={validationSchema}
        >
            { formik_props => {
                const { values } = formik_props

                const onChanged = (moment_type) => {
                    setMomentType(moment_type)
                }

                return (
                    <Form>
                        <FormCard isSubmitting={formik_props.isSubmitting}>
                            <Typography variant="h6">Select a category</Typography>
                            <FormikKpiCategoryDropdownField
                                name="category"
                                formik_props={formik_props}
                            />
                            <Typography variant="h6">Set a threshold</Typography>
                            <Alert severity="info">
                                A theshold determines when a kpi or checklist will pass or fail.
                            </Alert>
                            <FieldArray
                             name="kpi_thresholds"
                             render={arrayHelpers => (
                               <Grid container spacing={2}>
                                   { map(kpi_thresholds, (kpitt, index) => {
                                       return(
                                           <Grid item>
                                               <FormikInputField
                                                   name={ `kpi_thresholds.${index}.threshold` }
                                                   formik_props={formik_props}
                                                   label={ capitalCamel(getThresholdLabel(kpitt)) }
                                                   fullWidth={false}
                                                   onBlur={() => setThreshHoldFieldMeta(formik_props, index, kpitt)}
                                               />
                                            </Grid>
                                       )
                                   })}
                               </Grid>
                             )}
                           />
                           <Divider style={{margin: "15px 0"}}/>

                           <Typography variant="h6">When should this be calculated?</Typography>

                           <Grid container spacing={2}>
                               <Grid item xs={6} lg={6}>
                                   <Frequency
                                       formik_props={formik_props}
                                       name={"perform_calculation_on.moment_type"}
                                       onChanged={onChanged}
                                    />
                               </Grid>
                               <Grid item xs={6} lg={6}>
                                   <FrequencyValues
                                       formik_props={formik_props}
                                       name={"perform_calculation_on.value"}
                                       label="Calculation Day"
                                       moment_type={momentType}
                                    />
                               </Grid>
                           </Grid>

                           <Divider style={{margin: "15px 0"}}/>

                           <Typography variant="h6">Over what range should this be calculated?</Typography>

                           <Grid container spacing={2}>
                               <Grid item xs={12} md={6}>
                                   <Frequency
                                       formik_props={formik_props}
                                       name={"calculation_start_at.moment_type"}
                                       label="Frequency"
                                       onChanged={onChanged}
                                       />
                               </Grid>
                               <Grid item xs={6} md={3}>
                                   <FrequencyValues
                                       formik_props={formik_props}
                                       name={"calculation_start_at.value"}
                                       label="Start day"
                                       moment_type={momentType}
                                    />
                               </Grid>
                               <Grid item xs={6} md={3}>
                                   <FrequencyValues
                                       formik_props={formik_props}
                                       name={"calculation_end_at.value"}
                                       label="End day"
                                       moment_type={momentType}
                                    />
                               </Grid>
                           </Grid>
                        </FormCard>
                    </Form>
                )
            }}
        </Formik>
    )
}

export default AdminKpiSettingsForm
