import React, { useEffect } from 'react'
import { useParams, useHistory } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { get } from 'lodash'
import { adminProductList } from './actions/admin_product'
import { adminProductCategoryList } from '../product_categories/actions/admin_product_category'
import { showSuccess, showError } from 'actions/Error'
import { handleSubmitResult } from 'actions/form'
import AdminCommonFormLayout from '../layout/AdminCommonFormLayout'
import AdminProductForm from './form/AdminProductForm'
import { validationSchema } from './form/AdminProductForm'
import { Alert } from '@material-ui/lab'

export default function AdminProduct() {
  const dispatch = useDispatch()
  const history = useHistory()
  const { product_id } = useParams()
  const product = useSelector( () => adminProductList.getObject( product_id ) )
  const is_loading = useSelector( () => adminProductList.isLoading() ) || ( product_id && !get( product, "id" ) )
  const is_busy = useSelector( () => adminProductList.getIsSavingObject() )
  const initial_values = product
  const urlParams = new URLSearchParams( window.location.search )
  const product_category_id = urlParams.get( 'product_category' )
  const product_category = useSelector( () => adminProductCategoryList.getObject( product_category_id ) )
  const title = `${ get( product, [ "product_category_name" ], `New Product Variation for ${ get( product_category, "name" ) }` ) }`

  useEffect( () => {
    dispatch( adminProductCategoryList.ensureObjectLoaded( product_category_id ) )
    dispatch( adminProductList.ensureObjectLoaded( product_id ) )
  }, [] )


  const onSave = ( values, formik_funcs ) => {
    values.product_category = get( product_category, "id" )
    const on_ok = function( json ) {
      dispatch( adminProductList.invalidateList() )
      showSuccess( "Saved", "Product variation saved" )
      history.push( `/admin/product_category/${ json.product_category }` )
    }
    if ( product_id ) {
      values.id = product_id
      return dispatch( adminProductList.saveObject( values ) ).then( ( res ) => handleSubmitResult( { res, formik_funcs, on_ok } ) )
    } else {
      values.name = get( product_category, "name" )

      return dispatch( adminProductList.saveNewObject( values ) ).then( ( res ) => handleSubmitResult( { res, formik_funcs, on_ok } ) )
    }
  }

  return (
    <AdminCommonFormLayout
      breadcrumbs={ [
        { name: 'admin_home' },
        { name: 'products', label: 'Products', url: '/admin/products' },
        { name: 'product', label: get( product, [ "product_category_name" ], title ), url: `/admin/product/${ product_id }` }
      ] }
      is_busy={ is_busy }
      is_loading={ is_loading }
      title={ title }
      initial_values={ initial_values }
      validationSchema={ validationSchema }
      onSave={ onSave }>

      {
        ( { formik_props } ) => <>
          { product_category ?
            <AdminProductForm formik_props={ formik_props } product_category={ product_category } /> :
            !is_loading && <Alert severity="error">Product category must be passed</Alert>
          }
        </>
      }


    </AdminCommonFormLayout>
  )

}