import React, { useEffect } from 'react'
import { get, capitalize } from 'lodash'
import { Link, useHistory, useParams } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { CommonTable } from 'components/CommonTable'
import Button from '@material-ui/core/Button'
import { handleSubmitResult } from 'actions/form'
import {Formik, Form, Field} from 'formik'
import {FormikInputField} from "components/form/InputField"
import {FormikTextareaField} from "components/form/TextareaField"
import {FormikDropdownField} from "components/form/Dropdown"
import { FormikProductAutoCompleteDropdown } from 'admin/components/products/form/ProductAutoCompleteDropdown'
import {showSuccess, showError} from 'actions/Error'
import DonorCommonFormLayout from '../layout/DonorCommonFormLayout'
import { makeStyles, Grid, InputAdornment } from '@material-ui/core'
import { contactCompanyProductList } from './actions/company_products'
import { storage_options } from 'components/products/form/storage_options'
import * as Yup from 'yup'

const validationSchema = Yup.object().shape({

})

export const CompanyProduct = () => {

    const history = useHistory()
    const dispatch = useDispatch()
    const is_loading = useSelector(() => contactCompanyProductList.isLoading())
    const { company_product_id } = useParams()
    const company_product = useSelector(() => contactCompanyProductList.getObject(company_product_id))
    let initial_values = company_product
    const title = get(company_product, "product_name") ? `Edit ${get(company_product, "product_name")}` : 'Add product'
    const initialValues = company_product || {
      product: null,
      custom_product_code: null
    }

    useEffect(() => {
        dispatch(contactCompanyProductList.ensureObjectLoaded(company_product_id))
    }, [company_product_id])

    const onSave = (values, formik_funcs) => {
        const on_ok = function(json) {
            dispatch(contactCompanyProductList.invalidateList())
            showSuccess("Saved", "Product saved")
            history.push(`/donor/settings/company_products`)
        }
        if ( company_product_id ) {
            values.id = company_product_id
            return dispatch(contactCompanyProductList.saveObject(values)).then((res) => handleSubmitResult({res, formik_funcs, on_ok}))
        } else {
            return dispatch(contactCompanyProductList.saveNewObject(values)).then((res) => handleSubmitResult({res, formik_funcs, on_ok}))
        }
    }

    const renderForm = (formik_props) => {

        return (

            <Grid container spacing={2}>

              <Grid item xs={12} md={8}>

                <Grid container spacing={2}>

                  <Grid item xs={12} md={8}>

                    <FormikInputField
                      name="product_name"
                      label="Product name"
                      helperText="* Required"
                      formik_props={formik_props}
                    />

                  </Grid>

                  <Grid item xs={12} md={4}>

                    <FormikInputField
                      name="custom_product_code"
                      label="Product code"
                      formik_props={formik_props}
                    />

                  </Grid>

                </Grid>

                <Grid container spacing={1}>

                  <Grid item xs={12} md={4}>

                    <FormikInputField
                      name="weight_kg"
                      label="Weight"
                      helperText="* Required"
                      InputProps={{
                        endAdornment: <InputAdornment position="end">kg</InputAdornment>,
                      }}
                      formik_props={formik_props}
                    />

                  </Grid>

                  <Grid item xs={12} md={4}>

                    <FormikInputField
                      name="donated_value"
                      label="Donated Value"
                      InputProps={{
                        startAdornment: <InputAdornment position="start">R</InputAdornment>,
                      }}
                      formik_props={formik_props}
                    />

                  </Grid>

                  <Grid item xs={12} md={4}>

                    <FormikInputField
                      name="price"
                      label="Price"
                      InputProps={{
                        startAdornment: <InputAdornment position="start">R</InputAdornment>,
                      }}
                      formik_props={formik_props}
                    />



                  </Grid>

                </Grid>

                <FormikTextareaField
                    name="description"
                    label="Description"
                    rows={5}
                    formik_props={formik_props}
                />

              </Grid>

              <Grid item xs={12} md={4}>

                <FormikDropdownField
                    name="storage"
                    label="Storage Requirements"
                    formik_props={formik_props}
                    options={storage_options}
                />

              </Grid>

            </Grid>

        )

    }

    return (

      <DonorCommonFormLayout
        breadcrumbs={[
          {name: 'donor_home'},
          {name: 'products', label: 'Products', url: '/donor/settings/company_products'},
          {name: 'products', label: get(company_product, ["product_name"], 'New Product'), url: `/donor/products/${company_product_id}`}
        ]}
        is_loading={is_loading}
        title={title}
        initial_values={initial_values}
        validationSchema={validationSchema}
        onSave={onSave}
      >

        { ({formik_props}) => renderForm(formik_props) }

      </DonorCommonFormLayout>
      
    )

}
