import React, { useState, useEffect, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams, useHistory } from 'react-router-dom'
import { get } from 'lodash'
import { makeStyles, withStyles } from '@material-ui/core/styles'
import {TableContainer, Table, TableRow, TableCell, Paper, Grid, Typography, Slider, Input, OutlinedInput, Button, TextField, FormControl, FormControlLabel, InputLabel, InputAdornment, Checkbox, CircularProgress} from '@material-ui/core'
import { FormikGeneralFormErrors } from 'components/form/GeneralFormErrors'
import { Formik, Form, FieldArray, Field } from 'formik'
import { FormikInputField } from 'components/form/InputField'
import { FormikCheckboxField } from 'components/form/CheckboxField'
import * as Yup from 'yup'
import { adminMoneyDonationList } from './actions/admin_money_donation'
import { handleSubmitResult, shouldShowOnDemandSaveButton } from 'actions/form'
import {showSuccess, showError} from 'actions/Error'
import AdminCommonFormLayout from '../layout/AdminCommonFormLayout'
import { AdminMoneyDonationForm, validationSchema} from './form/AdminMoneyDonationForm'

const useStyles = makeStyles((theme) => ({

}))

export default function AdminMoneydonation() {
  const classes = useStyles()
  const dispatch = useDispatch()
  const history = useHistory()
  let { money_donation_id } = useParams()
  const [edit, setEdit] = useState(false)

  const is_busy =  useSelector(() => adminMoneyDonationList.getIsSavingObject())
  const money_donation = useSelector(() => adminMoneyDonationList.getObject(money_donation_id))
  const initial_values = money_donation
  const title = money_donation ? get(money_donation, "unique_payment_ref") : "New Money Donation"

  let is_loading = money_donation_id && !get(money_donation, "id")

  useEffect(() => {
      async function fetchStaticObjects() {
          is_loading = dispatch(adminMoneyDonationList.ensureObjectLoaded(money_donation_id))
          dispatch(adminMoneyDonationList.fetchListIfNeeded())
      }
      fetchStaticObjects()
  }, [money_donation_id])

  const onSave = (values, formik_funcs) => {

      const on_ok = function(json) {
          setEdit(false)
          dispatch(adminMoneyDonationList.invalidateList())
          showSuccess("Saved", "Money Donation saved")

          if ( ! money_donation_id ) {
              history.push(`/donate/${json.id}`)
          }
      }
      if ( money_donation_id ) {
          values.id = money_donation_id
          return dispatch(adminMoneyDonationList.saveObject(values)).then((res) => handleSubmitResult({res, formik_funcs, on_ok}))
      } else {
          return dispatch(adminMoneyDonationList.saveNewObject(values)).then((res) => handleSubmitResult({res, formik_funcs, on_ok}))
      }
  }

  return(
      <AdminCommonFormLayout breadcrumbs={[{name: 'admin_home'},
                                           {name: 'money_donations', label: 'Money Donations', url: '/admin/money_donations'},
                                           {name: 'money_donations', label: get(money_donation, ["unique_payment_ref"], 'New Money Donation'), url: `/admin/money_donation/${money_donation_id}`}
                                          ]}
                             is_busy={is_busy}
                             is_loading={is_loading}
                             title={title}
                             initial_values={initial_values}
                             validationSchema={validationSchema}
                             onSave={onSave}>

        {
            ({formik_props}) => <AdminMoneyDonationForm formik_props={formik_props} />
        }
      </AdminCommonFormLayout>
  )

}
