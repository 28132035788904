import React, { useEffect, useState } from 'react'
import { Field, useField, FieldArray } from 'formik'
import { useDispatch, useSelector } from 'react-redux'
import { map, get, size, split, startCase, toLower } from 'lodash'
import CommonListTable from '../layout/CommonListTable'
import ContactForm from './form/ContactForm'
import { Separator } from 'components/layout/Separator'
import {showSuccess, showError} from 'actions/Error'
import { handleSubmitResult } from 'actions/form'
import {makeStyles, Avatar, Grid, Typography, Tooltip } from '@material-ui/core'
import { contactContactList } from 'actions/contact/contact'
import { Modal } from 'components/layout/Modal'
import Alert from "@material-ui/lab/Alert"

const useStyles = makeStyles((theme) => ({
  addButton: {
    marginBottom: 15,
  },
  noContactsButton: {
    marginTop: 10
  },
  deleteButton: {
    float:'right',
  },
  addEditContact: {
    color: '#999999'
  }
}))

const ContactContacts = ({formik_props, company_id, name, is_new}) => {
    const classes = useStyles()
    const dispatch = useDispatch()
    const contacts = is_new ? null : useSelector(() => contactContactList.getVisibleObjects())
    const is_loading = is_new ? null : useSelector(() => !contactContactList.isReady() && contactContactList.isLoading())

    const [editingContact, setEditingContact] = useState(null)
    const [contactId, setContactId] = useState(null)
    const [addEditLabel, setAddEditLabel] = useState(null)

    useEffect(() => {
        if(!is_new) {
          dispatch(contactContactList.updateListFilter({company: company_id}))
          dispatch(contactContactList.fetchListIfNeeded({company: company_id}))
        }
    }, [company_id])

    const onAddContact = () => {
        setContactId(false)
        setEditingContact({company: company_id})
    }

    const onEditContact = (contact_id) => {
        setContactId(contact_id)
        setAddEditLabel(`Editing ${contact_id}`)
        setEditingContact({company: company_id})
    }

    const onStopEditingContact = () => {
        dispatch(contactContactList.invalidateList())
        dispatch(contactContactList.fetchListIfNeeded())
        setEditingContact(null)
    }

    const onRemoveContact = (contact) => {

        if (! window.confirm("Are you sure you want to delete this contact?") ) {
            return
        }

        const onDone = (res) => {
            if ( res.errors ) {
                showError("Failed", "Contact is in use and could not be deleted")
            } else {
                showSuccess("Deleted", "Contact deleted")
                dispatch(contactContactList.invalidateList())
                dispatch(contactContactList.fetchListIfNeeded())
            }
        }

        dispatch(contactContactList.deleteObject(contact.id)).then(onDone)
    }

    const columns = [
      {
        field: 'last_name',
        title: '',
        render: (item) =>
                          <Grid container spacing={2} style={{marginBottom: 7}}>

                            <Grid style={{padding: 7}}>
                              <Avatar
                                alt={startCase(toLower(get(item, 'first_name') + " " + get(item, 'last_name')))}
                                src={get(item, ["profile_images", "files", 0, "original_download_url"])}
                                style={{cursor: 'pointer', width: 120, height: 120}}
                                onClick={() => onEditContact(get(item, 'id'))}
                                variant="square"
                              />
                            </Grid>

                            <Grid item xs={12} lg={7}>
                              <Typography variant="h5">
                                <a onClick={() => onEditContact(get(item, 'id'))}>{startCase(toLower(get(item, 'first_name') + " " + get(item, 'last_name')))}</a>
                              </Typography>
                              <Typography>
                                Role: {item.role}<br />
                                Email: <Tooltip title="Click to email" arrow><a href={`mailto:${item.email}`} target="_blank">{item.email}</a></Tooltip><br />
                                Mobile: <Tooltip title="Click to phone" arrow><a href={`tel: ${item.mobile}`} target="_blank">{item.mobile}</a></Tooltip>
                              </Typography>
                            </Grid>

                          </Grid>
     },

    ]

    //console.log(contacts)

    return (
      <>
      {!is_new ?
        <CommonListTable
          add_label="Add Contact"
          title="Contact"
          onAddRow={onAddContact}
          button_variation="icon"
          button_align="right"
          button_color="yellow"
          button_size="small"
          onEditRow={onEditContact}
          is_loading={is_loading}
          handleRequestSort={columns}
          columns={columns}
          item_list={contactContactList}
          inTabs
          noPagination
          noHeadings
        /> :
        <Alert severity="info">First create the company then you can add contacts</Alert>
      }

      { editingContact &&
        <Modal title={addEditLabel} onClose={() => setEditingContact(false)}>
          <ContactForm company_id={company_id} contact_id={contactId} setEditingContact={setEditingContact} />
        </Modal>
      }

      </>
    )

}

export default ContactContacts
