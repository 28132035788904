import { ItemList } from 'orm'

class ContactDropdownOptions extends ItemList {
    constructor(dropdown_name) {
        super(`options__${dropdown_name}`)
        this.url = `contact/dropdown_options/${dropdown_name}`
    }

    getEntityKey() {
        return this.url
    }

}

export const donorParcelFieldMappingList  = new ContactDropdownOptions('donor_parcel_field_mapping_types')
export const donorProductFieldMappingList = new ContactDropdownOptions('donor_product_field_mapping_types')
export const companyBranchFieldMappingList = new ContactDropdownOptions('company_branch_field_mapping_types')
