import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useParams } from 'react-router-dom'
import { get } from 'lodash'
import { adminFeedingFrequencyList } from './actions/admin_feeding_frequency'
import { showSuccess, showError } from 'actions/Error'
import { handleSubmitResult } from 'actions/form'
import AdminCommonFormLayout from '../layout/AdminCommonFormLayout'
import AdminCommonForm from '../layout/AdminCommonForm'
import { AdminFeedingFrequencyForm, validationSchema } from './form/AdminFeedingFrequencyForm'

export default function AdminFeedingFrequency( { in_modal = false, onClose = () => { }, passed_feeding_frequency_id = null, company_id = null } ) {
  const dispatch = useDispatch()
  const history = useHistory()
  const { feeding_frequency_id: params_feeding_frequency_id } = useParams()
  const feeding_frequency_id = passed_feeding_frequency_id || params_feeding_frequency_id
  const feeding_frequency = useSelector( () => adminFeedingFrequencyList.getObject( feeding_frequency_id ) )
  const initial_values = feeding_frequency
  const is_loading = useSelector( () => adminFeedingFrequencyList.isLoading() ) || ( feeding_frequency_id && !get( feeding_frequency, "id" ) )
  const is_busy = useSelector( () => adminFeedingFrequencyList.getIsSavingObject() )
  const title = `${ get( feeding_frequency, [ "frequency" ], 'New Feeding Frequency' ) }`

  useEffect( () => {
    dispatch( adminFeedingFrequencyList.ensureObjectLoaded( feeding_frequency_id ) )
  }, [] )

  const onSave = ( values, formik_funcs ) => {
    if ( company_id ) {
      values.company = company_id
    }
    const on_ok = function( json ) {
      dispatch( adminFeedingFrequencyList.invalidateList() )
      showSuccess( "Saved", "FeedingFrequency saved" )

      if ( !feeding_frequency_id ) {
        if ( in_modal ) {
          onClose()
        } else {
          history.push( `/admin/feeding_frequency/${ json.id }` )
        }
      } else if ( in_modal ) {
        onClose()
      }
    }
    if ( feeding_frequency_id ) {
      values.id = feeding_frequency_id
      return dispatch( adminFeedingFrequencyList.saveObject( values ) ).then( ( res ) => handleSubmitResult( { res, formik_funcs, on_ok } ) )
    } else {
      return dispatch( adminFeedingFrequencyList.saveNewObject( values ) ).then( ( res ) => handleSubmitResult( { res, formik_funcs, on_ok } ) )
    }
  }

  if ( in_modal ) {
    return (
      <AdminCommonForm
        is_loading={ is_loading }
        is_busy={ is_busy }
        initial_values={ initial_values }
        inModal={ true }
        onSave={ onSave }>
        {
          ( { formik_props } ) => {
            return <AdminFeedingFrequencyForm formik_props={ formik_props } company_id={ company_id } in_modal={in_modal} />
          }
        }
      </AdminCommonForm>
    )
  }

  return (
    <AdminCommonFormLayout
      breadcrumbs={ [
        { name: 'admin_home' },
        { name: 'feeding_frequencies', label: 'Feeding Frequencies', url: '/admin/feeding_frequencies' },
        { name: 'feeding_frequency', label: get( feeding_frequency, [ "name" ], 'New Feeding Frequency' ), url: `/admin/feeding_frequency/${ feeding_frequency_id }` }
      ] }
      is_busy={ is_busy }
      is_loading={ is_loading }
      title={ title }
      initial_values={ initial_values }
      validationSchema={ validationSchema }
      onSave={ onSave }
    >
      {
        ( { formik_props } ) => <AdminFeedingFrequencyForm formik_props={ formik_props } />
      }
    </AdminCommonFormLayout>
  )
}
