import { ItemList } from 'orm'
import { map } from 'lodash'

class ContactWarehouseList extends ItemList {
    getEntityKey() {
        return "contact/warehouse"
    }

}

export const contactWarehouseList = new ContactWarehouseList("contact_warehouse__default")
