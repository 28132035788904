import React from 'react'

export const payment_status_colours = {
  prepared: "#2196f3",
  pending: "#0069c0",
  paid: "#1faa00",
  cancelled: "#ef9a9a",
  denied: "#e91e63",
  reversed: "#9c27b0",
}

export const payment_status_options = [
  {label: "Pending", value: "pending"},
  {label: "Cancelled", value: "cancelled"},
  {label: "Paid", value: "paid"},
]

export const payment_gateway_options = [
  {label: "None", value: "none"},
  {label: "Payfast", value: "payfast"},
  {label: "SnapScan", value: "snapscan"},
  {label: "Bank Transfer", value: "eft"},
]
