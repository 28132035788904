import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { map } from 'lodash'
import { adminReport } from './actions/admin_report'
import { Formik, Form, Field } from 'formik'
import * as Yup from 'yup'
import { showSuccess, showError } from 'actions/Error'
import { handleSubmitResult } from 'actions/form'
import AdminMainLayout from '../layout/AdminMainLayout'
import { FormikDateTimePicker } from 'components/form/DatetimePicker'
import { FormikInputField } from 'components/form/InputField'
import { FormikDropdownField } from 'components/form/Dropdown'
import { capitalCamel } from 'components/CapitalCamel'
import { Typography, Grid, Button, Card, CardContent, Radio, RadioGroup, FormControlLabel, FormControl, FormLabel } from '@material-ui/core'

export const validationSchema = Yup.object().shape({
  // from: Yup.string().required("Required"),
  // to: Yup.string().required("Required")
})

export default function AdminBeneficiaryParcelReport(props) {

  const history = useHistory()
  const dispatch = useDispatch()
  const initial_values = {}
  const { is_loading, is_busy, warehouse_id } = props
  const report_type_options = ['weekly', 'monthly', 'date_range']
  const [reportType, setReportType] = useState('weekly')

  const onGenerateDateRangeBeneficiaryParcelReport = (values, formik_funcs) => {
    const on_ok = function(json) {
      showSuccess("Complete", "Report generated")
    }
    return dispatch(adminReport.generateDateRangeBeneficiaryParcelReport(values)).then((res) => handleSubmitResult({res, formik_funcs, on_ok}))
  }

  const onGenerateWeeklyBeneficiaryParcelReport = (values, formik_funcs) => {
    const on_ok = function(json) {
      showSuccess("Complete", "Report generated")
    }
    return dispatch(adminReport.generateWeeklyBeneficiaryParcelReport(values)).then((res) => handleSubmitResult({res, formik_funcs, on_ok}))
  }

  const onGenerateMonthlyBeneficiaryParcelReport = (values, formik_funcs) => {
    const on_ok = function(json) {
      showSuccess("Complete", "Report generated")
    }
    return dispatch(adminReport.generateMonthlyBeneficiaryParcelReport(values)).then((res) => handleSubmitResult({res, formik_funcs, on_ok}))
  }

  const handleChange = (event) => {
    setReportType(event.target.value)
  };

  const renderCheckboxes = () => {
    return (
      <FormControl component="fieldset">
        <FormLabel component="legend">Report Type</FormLabel>
        <RadioGroup row aria-label="report type" name="reportType" value={reportType} onChange={handleChange}>
          {map(report_type_options, (r) => {
            return <FormControlLabel value={r} control={<Radio color="primary" />} label={capitalCamel(r)} />
          })}
        </RadioGroup>
      </FormControl>
    )
  }

  const renderDateRangeBeneficiaryParcelReport = () => {
    return (
      <Formik initialValues={{type: 'date_range'}}
        onSubmit={onGenerateDateRangeBeneficiaryParcelReport}
        enableReinitialize={true}
        validationSchema={validationSchema}
        >
        {formik_props =>
          <Form>
            <Typography variant="h6" >Date range report</Typography>

            <FormikDateTimePicker name="arrive_at_from"
              label="From"
              include_time={false}
              formik_props={formik_props}
              />
            <FormikDateTimePicker name="arrive_at_to"
              label="To"
              include_time={false}
              formik_props={formik_props}
              />

            <Button type="submit" variant="contained" color="primary">Generate</Button>
          </Form>
        }
      </Formik>
    )
  }

  const renderMonthlyBeneficiaryParcelReport = () => {
    return (
      <Formik initialValues={{type:'monthly'}}
        onSubmit={onGenerateMonthlyBeneficiaryParcelReport}
        enableReinitialize={true}
        validationSchema={validationSchema}
        >
        {formik_props =>
          <Form>
            <Typography variant="h6" >Monthly report</Typography>

            <FormikDateTimePicker name="arrive_at_any_date_in_month"
              label="Select date..."
              helperText="Select any date in the reporting month"
              include_time={false}
              formik_props={formik_props}
              />

            <Button type="submit" variant="contained" color="primary">Generate</Button>

          </Form>
        }
      </Formik>
    )
  }

  const renderWeeklyBeneficiaryParcelReport = () => {
    return (
      <Formik initialValues={{type:'weekly'}}
        onSubmit={onGenerateWeeklyBeneficiaryParcelReport}
        enableReinitialize={true}
        validationSchema={validationSchema}
        >
        {formik_props =>
          <Form>
            <Typography variant="h6" >Weekly report</Typography>

            <FormikDateTimePicker name="arrive_at_any_date_in_week"
              label="Select date..."
              helperText="Select any date in the reporting week"
              include_time={false}
              formik_props={formik_props}
              />
            <Button type="submit" variant="contained" color="primary">Generate</Button>
          </Form>
        }
      </Formik>
    )
  }

  return (
    <AdminMainLayout breadcrumbs={[
        {name: 'admin_home'},
        {name: 'admin_reports report', label: 'Reports', url: '/admin/reports'},
        {name: 'parcel report', label: 'Monthly Order Report', url: '/admin/reports/monthly_parcel'},
      ]}
      is_busy={is_busy}
      is_loading={is_loading}
      title="Beneficiary Order Reports"
      >
      {renderCheckboxes()}
      <Card variant="outlined">
        <CardContent>
          <Grid container justifyContent="space-between">
            {reportType == 'weekly' &&
              <Grid item xs={12} md={4}>{ renderWeeklyBeneficiaryParcelReport() }</Grid>
            }
            {reportType == 'monthly' &&
              <Grid item xs={12} md={4}>{ renderMonthlyBeneficiaryParcelReport() }</Grid>
            }
            { reportType == 'date_range' &&
              <Grid item xs={12} md={4}>{ renderDateRangeBeneficiaryParcelReport() }</Grid>
            }
          </Grid>
        </CardContent>
      </Card>
    </AdminMainLayout>
  )

}
