import React, { Component } from 'react'

export default class CardInfoText extends Component {

    render() {
        return (
            <div>
              {this.props.children}
            </div>
        )

    }

}
