import { ItemList } from 'orm'
import { get, map, head, filter } from 'lodash'
import { download } from 'actions/lib'

class ContactList extends ItemList {
    getEntityKey() {
        return "admin/contact"
    }

    getAsOptions() {
        const items = this.getVisibleObjects()
        return map(items, function(item) { return {label: item.company.name,
                                                   value: item.company.id}})
    }

    downloadContactReport(contact_id) {
        return download(`admin/contact/${contact_id}/download/`)
    }


}

export const adminContactList = new ContactList("admin_contact__default")
export const adminContactForDropdownList = new ContactList("admin_contact_for_dropdown__default")


export const invalidateAllContactLists = () => {
    return (dispatch, getState) => {
        dispatch(adminContactList.invalidateList())
        dispatch(adminContactList.fetchListIfNeeded())
        dispatch(adminContactForDropdownList.invalidateList())
        dispatch(adminContactForDropdownList.fetchListIfNeeded())
    }
}
