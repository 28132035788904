import React, { useState, useEffect, useRef } from 'react'
import Timestamp from 'components/Timestamp'
import NumberFormat from 'react-number-format'
import { Link } from 'react-router-dom'
import { map, get, upperFirst, startCase, camelCase, union } from 'lodash'
import { GoogleMap, MarkerClusterer, Marker, InfoWindow, InfoBox } from '@react-google-maps/api'
import { loadGoogleApi } from './GoogleLoader'
import { Grid, Divider, Typography, Tooltip, LinearProgress } from '@material-ui/core'
import './css/infowindow.css'
import { LoadingModal } from 'components/layout/Modal'
import { Button } from "@material-ui/core"
import { Separator } from 'components/layout/Separator'

let mapContainerStyle = {
    height: '100vh',
    width: '100vw',
}

const defaultMapOptions = {
    styles: [
        {
            "featureType": "all",
            "elementType": "labels.icon",
            "stylers": [
                {
                    "visibility": "off"
                }
            ]
        },
        {
            "featureType": "administrative",
            "elementType": "labels",
            "stylers": [
                {
                    "visibility": "on"
                }
            ]
        },
        {
            "featureType": "administrative",
            "elementType": "labels.icon",
            "stylers": [
                {
                    "visibility": "off"
                }
            ]
        }
    ]
}

// Middle of South Africa
const default_center = { lat: -30.0114836, lng: 22.6504673 }
const default_zoom = 6

const LoadingMarkers = () => {
    return <LinearProgress style={{height: 6}} color="primary" />
}

const svgMarker = (google, color, scale, notForMap=false) => {
    color = color ? color : "#00bcd4"
    scale = scale ? scale : 0.08
    let path = "M182.9,551.7c0,0.1,0.2,0.3,0.2,0.3S358.3,283,358.3,194.6c0-130.1-88.8-186.7-175.4-186.9   C96.3,7.9,7.5,64.5,7.5,194.6c0,88.4,175.3,357.4,175.3,357.4S182.9,551.7,182.9,551.7z M122.2,187.2c0-33.6,27.2-60.8,60.8-60.8   c33.6,0,60.8,27.2,60.8,60.8S216.5,248,182.9,248C149.4,248,122.2,220.8,122.2,187.2z"
    let svg = !notForMap ? {
        path: path,
        fillColor: color,
        fillOpacity: 1,
        strokeWeight: 0.25,
        strokeColor: 'black',
        rotation: 0,
        scale: scale,
        anchor: new google.maps.Point(180, 900),
    } :
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="-325 0 1024 600">
        <g fill={color}>
  	         <path fill={color} d={path} />
         </g>
    </svg>

    return svg
}

// Custom cluster images
const options = {
  imagePath: 'https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/m', // so you must have m1.png, m2.png, m3.png, m4.png, m5.png and m6.png in that folder
}

const getIconColor = (marker_type) => {
    let color = ''
    switch( marker_type ) {
        case "donor":
            color = '#E91E63'
            break

        case "beneficiary":
            color = '#00bcd4'
            break

        case "logistics":
            color = '#009688'
            break

        case "warehouse":
            color = '#FADF01'
            break
    }
    return color
}

function createLocationKey(location) {
  return location.lat + location.lng
}

const getInfoWindowContent = (marker, i) => {
    const micrositeURL = "/impact/" + get(marker, ["company_type"]) + "/" + get(marker, ["id"])
    let imgPath = ''
    let placeholder_img = "/static/images/companies/logo-placeholder.jpg"
    if(get(marker, ["company_type"]) == 'donor') {
        placeholder_img = "/static/images/companies/donor/default/map-infowindow-placeholder.jpg"
    }
    if(get(marker, ["company_type"]) == 'beneficiary') {
        placeholder_img = "/static/images/companies/beneficiary/default/map-infowindow-placeholder.jpg"
    }
    let logo_url = get(marker, ["logo_image"]) ? get(marker, ["logo_image", "original_download_url"]) : placeholder_img
    imgPath = imgPath ? imgPath : logo_url
    var infoWindowTitle = <>
                                  <Typography variant="h5">{startCase(get(marker, ["name"]))}</Typography>
                                  { get(marker, ["marker_type"]) != 'warehouse' &&
                                      <Typography>{startCase(get(marker, ["marker_type"]))}</Typography>
                                  }
                                  <div align="center">
                                      <img src={imgPath} />
                                  </div>
                          </>
    var infoWindowTitleWithLink = <Tooltip title={`Click for more information, images and statistics for ${startCase(get(marker, ["name"]))}`} arrow>
                                        <Link to={micrositeURL} target="_blank">
                                            {infoWindowTitle}
                                        </Link>
                                    </Tooltip>

    switch( get(marker, ["marker_type"]) ) {
        case "donor":
            imgPath = imgPath != '' ? imgPath : svgMarker(window.google, getIconColor('donor'), 0.2, true)
            break

        case "beneficiary":
            imgPath = imgPath != '' ? imgPath : svgMarker(window.google, getIconColor('beneficiary'), 0.2, true)
            break

        case "logistics":
            imgPath = imgPath != '' ? imgPath : svgMarker(window.google, getIconColor('logistics'), 0.2, true)
            break

        case "warehouse":
            imgPath = imgPath != '' ? imgPath : svgMarker(window.google, getIconColor('warehouse'), 0.2, true)
            break
    }

    return (
        <div align="center">

            { get(marker, ["marker_type"]) == 'beneficiary' ? infoWindowTitleWithLink : infoWindowTitle}


            { get(marker, ["marker_type"]) != 'warehouse' && get(marker, ["last_parcel_date"]) !== 0 && get(marker, ["last_parcel_date"]) !== null &&
                <>
                    <Typography>Last Donation{get(marker, ["company_type"]) == 'beneficiary' ? ' Received' : ''}</Typography>
                    <Divider style={{marginTop: 5, marginBottom: 5 }} />
                    <Grid container>
                        <Grid item xs={6} align="left">
                            <Typography variant="caption">Date</Typography>
                        </Grid>
                        <Grid item xs={6} align="right">
                            <Typography variant="caption">
                                <Timestamp value={get(marker, ["last_parcel_date"])} format="date" />
                            </Typography>
                        </Grid>
                    </Grid>

                    { get(marker, ["last_parcel_weight"]) > 0 &&
                        <Grid container>
                            <Grid item xs={6} align="left">
                                <Typography variant="caption">Weight</Typography>
                            </Grid>
                            <Grid item xs={6} align="right">
                                <Typography variant="caption">
                                    <NumberFormat value={get(marker, ["last_parcel_weight"])} thousandSeparator={true} displayType={'text'} decimalScale={2} suffix={' kg'}/>
                                </Typography>
                            </Grid>
                        </Grid>
                    }

                    { get(marker, ["last_donation_value"]) > 0 &&
                        <Grid container>
                            <Grid item xs={6}>Value</Grid>
                            <Grid item xs={6} align="right">
                                <Typography variant="caption">
                                    <NumberFormat value={get(marker, ["last_donation_value"])} thousandSeparator={true} displayType={'text'} decimalScale={2} prefix={'R'}/>
                                </Typography>
                            </Grid>
                        </Grid>
                    }

                    { get(marker, ["total_meals_donated"]) > 0  &&
                        <>
                            <Divider style={{marginTop: 10, marginBottom: 10 }} />
                            <Grid container>
                                <Grid item xs={12}><Typography>Total Meals Donated</Typography></Grid>
                                <Grid item xs={12}>
                                    <Typography variant="h5">
                                        <NumberFormat value={get(marker, ["total_meals_donated"])} thousandSeparator={true} displayType={'text'} decimalScale={2} />
                                    </Typography>
                                </Grid>
                            </Grid>
                        </>
                    }

                    { get(marker, ["total_meals_received"]) > 0  &&
                        <>
                            <Divider style={{marginTop: 10, marginBottom: 10 }} />
                            <Grid container>
                                <Grid item xs={12}><Typography>Total Meals Received</Typography></Grid>
                                <Grid item xs={12}>
                                    <Typography variant="h5">
                                        <NumberFormat value={get(marker, ["total_meals_received"])} thousandSeparator={true} displayType={'text'} decimalScale={2} />
                                    </Typography>
                                </Grid>
                            </Grid>
                        </>
                    }
                </>
            }
            { get( marker, [ "company_type" ] ) === 'beneficiary' &&
                <Button
                    component={Link}
                    variant="outlined"
                    to={micrositeURL}
                    size="small"
                    style={{ marginTop: 15}}
                >
                    Read more
                </Button>
            }

        </div>
    )
}

export const MapWithMarkerClusterer = ({markers, height, width, zoom, is_loading, center}) => {
    const [selectedMarker, setselectedMarker] = useState()
    const { isLoaded, loadError } = loadGoogleApi()

    if(!center || !get(center, "lat") || !get(center, "lng")) {
        center = default_center
    }

    if(!zoom) {zoom = default_zoom}

    if(width && height) {
        mapContainerStyle = {
            height: height,
            width: width,
        }
    }

    // Fit bounds on mount, and when the markers change
    const onLoad = React.useCallback(function callback(gmap) {
        const bounds = new window.google.maps.LatLngBounds();
        gmap.fitBounds(bounds);
        setGmap(gmap)
    }, [])

    const renderMap = () => {
        return (
            <GoogleMap
                id='map-with-markers-clustered'
                mapContainerStyle={mapContainerStyle}
                options={defaultMapOptions}
                zoom={zoom}
                center={center}
                tilt={45}
                //onLoad={onLoad}
                //onUnmount={onUnmount}
            >
                { is_loading ? <LoadingMarkers /> :
                    <MarkerClusterer enableRetinaIcons>
                        {(clusterer) =>
                            map(markers, (marker, i) => {
                                if(!marker.company_type) {
                                    marker.company_type = marker.marker_type
                                }
                                if(get(marker, ["lat"]) && get(marker, ["lng"])) {
                                    const infoWindowContent = getInfoWindowContent(marker, i)
                                    return (
                                        <>
                                            <Tooltip title={marker.name} arrow>
                                                <Marker icon={svgMarker(window.google, getIconColor(marker.company_type))} key={i} position={marker} clusterer={clusterer} onClick={() => setselectedMarker(marker)} animation={"DROP"} pixelOffset={20} />
                                            </Tooltip>
                                            { selectedMarker === marker &&
                                                <InfoWindow position={{ lat: marker.lat, lng: marker.lng }} options={{pixelOffset: new window.google.maps.Size(0,-60)}}>
                                                    { infoWindowContent }
                                                </InfoWindow>
                                            }
                                        </>
                                )
                            }
                        })}
                    </MarkerClusterer>
                }
            </GoogleMap>
        )
    }

    if (loadError) {
        return <div>Map cannot be loaded right now, sorry.</div>
    }

    return isLoaded ? renderMap() : <LoadingMarkers />
}

export const MapWithMarkers = ({markers, height, width, zoom, is_loading, center}) => {
    if(!center || !get(center, "lat") || !get(center, "lng")) {
        center = default_center
    }
    if(!zoom) {zoom = default_zoom}
    if(width && height) {
        mapContainerStyle = {
            height: height,
            width: width,
        }
    }

    const [gmap, setGmap] = React.useState(null)

    const [selectedMarker, setselectedMarker] = useState()
    const { isLoaded, loadError } = loadGoogleApi()

    const onLoad = React.useCallback(function callback(gmap) {
        const bounds = new window.google.maps.LatLngBounds();
        gmap.fitBounds(bounds);
        setGmap(gmap)
    }, [])

    const onUnmount = React.useCallback(function callback(gmap) {
        setGmap(null)
    }, [])

    const renderMap = () => {
        const bounds = new window.google.maps.LatLngBounds()

        return (
            <>
                {is_loading && <LoadingMarkers /> }
                    <GoogleMap
                        id='map-with-markers'
                        mapContainerStyle={mapContainerStyle}
                        options={defaultMapOptions}
                        zoom={zoom}
                        center={center}
                        tilt={45}
                      //onLoad={onLoad}
                      //onUnmount={onUnmount}
                    >
                        { map(markers, (marker, i) => {
                            if(!marker.company_type) {
                                marker.company_type = marker.marker_type
                            }

                            if(get(marker, ["lat"]) && get(marker, ["lng"])) {
                                const infoWindowContent = getInfoWindowContent(marker, i)
                                return(
                                    <>
                                        <Tooltip title={marker.name}>
                                            <Marker icon={svgMarker(window.google, getIconColor(marker.company_type))} key={i} position={marker} onClick={() => setselectedMarker(marker)} animation={"DROP"} pixelOffset={20} />
                                        </Tooltip>
                                        {selectedMarker === marker &&
                                            <InfoWindow
                                                position={{ lat: marker.lat, lng: marker.lng }}
                                                options={{pixelOffset: new window.google.maps.Size(0,-74)}}
                                            >
                                                {infoWindowContent}
                                            </InfoWindow>
                                        }
                                    </>
                                )
                            }
                        }
                    )}}
                </GoogleMap>
            </>
        )
    }

  if (loadError) {
    return <div>Map cannot be loaded right now, sorry.</div>
  }

  return isLoaded ? renderMap() : <LoadingMarkers />
}

export const BasicMapWithMarker = ({lat, lng, height, width, showDefaultUI, zoom}) => {
  const { isLoaded, loadError } = loadGoogleApi()
  const marker = {lat: lat, lng: lng}
  width = width ? width : '100%'
  let disableDefaultUI = showDefaultUI ? false : true
  const mapContainerStyle = {
    height: height,
    width: width
  }
  const options = {
    disableDefaultUI: disableDefaultUI,
    fullscreenControl: true
  }
  const combinedMapOptions = {...options, ...defaultMapOptions}

  const renderMap = () => {
    return(
        <GoogleMap
          id="marker-map"
          mapContainerStyle={mapContainerStyle}
          zoom={14}
          center={marker}
          options = {combinedMapOptions}
        >
          <Marker position={marker} />
        </GoogleMap>
    )
  }

  if (loadError) {
    return <div>Map cannot be loaded right now, sorry.</div>
  }

  return isLoaded ? renderMap() : <LoadingMarkers />
}
