import { ItemList } from 'orm'
import { map } from 'lodash'
import { onEventUpdated } from 'admin/actions/admin_calendar'

class LogisticsCompanyStatusHistoryList extends ItemList {
    getEntityKey() {
        return "admin/logistics_company_status_history"
    }

}

export const adminLogisticsCompanyStatusHistoryList = new LogisticsCompanyStatusHistoryList("admin_logistics_company_status_history__default")
