import React, { useEffect } from 'react'
import { useParams, useHistory } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { forEach, head, get, map, keys, split, size } from 'lodash'
import { CommonTable } from 'components/CommonTable'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import { Separator } from 'components/layout/Separator'
import { Card } from 'components/layout/Card'
import { Modal } from 'components/layout/Modal'
import { Formik, Form, Field } from 'formik'
import * as Yup from 'yup'
import {showSuccess, showError} from 'actions/Error'
import { handleSubmitResult } from 'actions/form'
import DonorCommonFormLayout from '../layout/DonorCommonFormLayout'
import DonorCommonForm from '../layout/DonorCommonForm'
import { FormikDateTimePicker } from 'components/form/DatetimePicker'
import { FormikInputField } from 'components/form/InputField'
import { FormikDropdownField } from 'components/form/Dropdown'
import { Typography, Grid, Button } from '@material-ui/core'
import { contactCompanyReport } from './actions/contact_company_report'
import { contactCompanyReportNames, contactCompanyReportFrequencies } from 'actions/contact/dropdown_options'
import { ContactCompanyReportGenerations } from './ContactCompanyReportGenerations'
import { FormikSwitchField } from 'components/form/SwitchField'

export const validationSchema = Yup.object().shape({
    report_name: Yup.string().required("Required"),
    frequency: Yup.string().required("Required")
})

export const ContactCompanyReport = ({inModal, setShowConfigureReportModal, setNewReport, company_id, company_report_id}) => {
    const dispatch = useDispatch()
    const history = useHistory()
    const is_loading = company_report_id && contactCompanyReport.isLoading()
    const is_busy = contactCompanyReport.getIsSavingObject()

    const companyReport = useSelector(() => contactCompanyReport.getObject(company_report_id))
    const initialValues = Object.assign({}, companyReport)
    const companyReportNameOptions = contactCompanyReportNames.getAsOptions()
    const companyReportFrequencyOptions = contactCompanyReportFrequencies.getAsOptions()

    useEffect(() => {
        dispatch(contactCompanyReportNames.fetchListIfNeeded())
        dispatch(contactCompanyReportFrequencies.fetchListIfNeeded())
    }, [])

    useEffect(() => {
        if ( company_report_id ) {
            dispatch(contactCompanyReport.ensureObjectLoaded(company_report_id))
        }
    }, [company_report_id])

    const onSave = (values, formik_funcs) => {
        const on_ok = function(json) {
            dispatch(contactCompanyReport.invalidateList())
            showSuccess("Saved", "Report saved")
            {inModal ? setShowConfigureReportModal(false) : history.push(`/contact/${company_id}/company_reports`) }
            setNewReport(values.report_name + "_" + values.frequency)
        }
        values.company = company_id
        if ( company_report_id ) {
            values.id = company_report_id
            return dispatch(contactCompanyReport.saveObject(values)).then((res) => handleSubmitResult({res, formik_funcs, on_ok}))
        } else {
            return dispatch(contactCompanyReport.saveNewObject(values)).then((res) => handleSubmitResult({res, formik_funcs, on_ok}))
        }
    }

    const renderForm = (formik_props) => {
        const enabledText = formik_props.values.enabled ? 'enabled': 'disabled'
        const enabledTextColor = formik_props.values.enabled ? 'green' : 'red'
        return (
            <>
              <div style={{marginLeft: 20}}>
                <FormControlLabel
                  control={
                      <FormikSwitchField name="enabled"
                                           formik_props={formik_props}
                                           color="primary"
                      />
                  }
                  label={`Report sending ${enabledText}`}
                  style={{ color: enabledTextColor }}
                />

              </div>

              <FormikDropdownField name="report_name"
                                   label="Report Type"
                                   options={companyReportNameOptions}
                                   formik_props={formik_props}
                                   helperText={"What type of report do you want to generate?"}
              />


              <FormikDropdownField name="frequency"
                                   label="Frequency"
                                   options={companyReportFrequencyOptions}
                                   formik_props={formik_props}
                                   helperText={"How often do you want to generate this report?"}
              />
          </>
        )
    }

    const renderHistory = () => {
        return <ContactCompanyReportGenerations company_report_id={company_report_id} />
    }

    return (
        <>
        { !inModal ?
            <DonorCommonFormLayout breadcrumbs={[{name: 'donor_home'},
                                                 {name: 'company_reports', label: 'Reports', url: `/contact/${company_id}/company_reports/`},
                                                 {name: 'contact_company_report', label: `${get(contactCompanyReportNames.getObjectsById(), [get(companyReport, "report_name", "New"), "name"], "New")}`, url: `/contact/${company_id}/company_reports/${company_report_id || ""}`},
                                                ]}
                                   is_loading={is_loading}
                                   is_busy={is_busy}
                                   title={`Report : ${get(contactCompanyReportNames.getObjectsById(), [get(companyReport, "report_name", "New"), "name"], "New")}`}
                                   initial_values={initialValues}
                                   validationSchema={validationSchema}
                                   onSave={onSave}>
              {
                  ({formik_props}) =>
                      <Grid container spacing={2}>
                        <Grid item xs={12} lg={3}>{renderForm(formik_props)}</Grid>
                        <Grid item xs={12} lg={9}>{renderHistory()}</Grid>
                      </Grid>
              }
            </DonorCommonFormLayout>

            :

            <Modal
              title={`Report : ${get(contactCompanyReportNames.getObjectsById(), [get(companyReport, "report_name", "New"), "name"], "New")}`}
              onClose={() => setShowConfigureReportModal(false)}
              >
              <DonorCommonForm
                is_loading={is_loading}
                is_busy={is_busy}
                initial_values={initialValues}
                validationSchema={validationSchema}
                inModal={true}
                onSave={onSave}>
                {
                ({formik_props}) =>
                 <Grid container spacing={2}>
                   <Grid item xs={12}>{renderForm(formik_props)}</Grid>
                 </Grid>
                }
              </DonorCommonForm>
            </Modal>

          }
        </>
    )

}
