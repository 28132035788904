import React, { useEffect, useState, useRef, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useParams } from 'react-router-dom'
import { capitalize, get, map, split, size, replace } from 'lodash'
import { adminEditableEmailList } from './actions/admin_editable_email'
import { Separator } from 'components/layout/Separator'
import { Formik, Form } from 'formik'
import Loading from 'components/Loading'
import { FormikTextareaField } from 'components/form/TextareaField'
import AdminMainLayout from 'admin/components/layout/AdminMainLayout'
import BusyMask from 'components/BusyMask'
import { Card } from 'components/layout/Card'
import * as Yup from 'yup'
import {showSuccess, showError} from 'actions/Error'
import { Button, Grid, Typography, Hidden, makeStyles, CircularProgress } from '@material-ui/core'
import CardInfoText from 'components/layout/CardInfoText'
import { handleSubmitResult } from 'actions/form'
import { FormikGeneralFormErrors } from 'components/form/GeneralFormErrors'
import { globalSettingsList } from 'actions/settings'
import { Error } from 'components/layout/Error'
import EmailEditor from 'react-email-editor'
import defaultEmailTemplate from './templates/json/defaultEmailTemplate.json'
import Alert from '@material-ui/lab/Alert'

const validationSchema = Yup.object().shape({
    'subject': Yup.string().required("Required"),
    'html_content': Yup.string().required("Required"),
    'text_content': Yup.string().required("Required")
})

const useStyles = makeStyles((theme) => ({
  saveBar: {
    position: 'fixed',
    bottom: 0,
    right: 0,
    width: '100%',
    padding: 15,
    zIndex: 99,
    background: '#ffffff',
    boxShadow: '0px -4px 3px rgba(239, 239, 239, 0.5)'
  },
  buttonProgress: {
    color: '#ffffff',
    marginRight: 10,
  }
}))

const AdminEditableEmail = ({ ...props }) => {
    const classes = useStyles()
    const dispatch = useDispatch()
    const history = useHistory()
    const {editable_email_id} = useParams()
    const editor = useRef()
    const [htmlContent, setHtmlContent] = useState(false)
    const [jsonContent, setJsonContent] = useState(false)
    const [loadEditorDesign, setLoadEditorDesign] = useState(false)
    const global_settings = useSelector(() => globalSettingsList.getGlobalSettings())

    const is_loading = useSelector(() => adminEditableEmailList.isLoading())
    const is_busy = useSelector(() => adminEditableEmailList.getIsSavingObject())
    const editable_email = adminEditableEmailList.getObject(editable_email_id)
    const initial_values = editable_email
    const can_edit = true
    const title = `${get(editable_email, ["description"])} - ${get(editable_email, ["language_code"])}`

    const designTemplate = get(editable_email, "json_content") ? JSON.parse(get(editable_email, "json_content")): defaultEmailTemplate

    useEffect(() => {
      dispatch(adminEditableEmailList.ensureObjectLoaded(editable_email_id))
      dispatch(globalSettingsList.ensureGlobalSettingsLoaded())
    },[])

    useEffect(() => {
        dispatch(adminEditableEmailList.ensureObjectLoaded(editable_email_id))

        if(get(editor, ["current", "editor"])) {
          get(editor, ["current"]).loadDesign(designTemplate)
        }
    }, [loadEditorDesign, editor])

    const onSave = (values, formik_funcs) => {
        const on_ok = function(json) {
            dispatch(adminEditableEmailList.invalidateList())
            showSuccess("Saved", "Email saved")
        }
        editor.current.editor.exportHtml((data) => {
            values.html_content = data.html
            editor.current.editor.saveDesign(design => {
              values.json_content = JSON.stringify(design)
              if ( editable_email_id ) {
                  values.id = editable_email_id
                  return dispatch(adminEditableEmailList.saveObject(values)).then((res) => handleSubmitResult({res, formik_funcs, on_ok}))
              } else {
                  return dispatch(adminEditableEmailList.saveNewObject(values)).then((res) => handleSubmitResult({res, formik_funcs, on_ok}))
              }
            })
        })
    }

    const onPreview = () => {
        window.open(`/admin/editable_email/preview/${editable_email_id}`, "_blank")
    }

    const getCellValue = (header_key, item, index) => {
        switch( header_key ) {
        default:
            return undefined
        }
    }

    const renderParameterTips = () => {
        return (
            <Card
              title="Parameter tips"
              content={
                <>
                  <Separator variant="h10" />
                  <div>Time: {"{{parameter|date:'H:i'}}"}
                    <CardInfoText>10:30</CardInfoText>
                  </div>
                  <hr/>
                  <div>Date: {"{{parameter|date:'Y-m-d'}}"}
                    <CardInfoText>2020-06-10</CardInfoText>
                  </div>
                  <hr/>
                  <div>Date (alternative): {"{{parameter|date:'d-M-Y'}}"}
                    <CardInfoText>10 June 2020</CardInfoText>
                  </div>
                  <div>Date + Time: {"{{parameter|date:'d-M-Y H:i'}}"}
                    <CardInfoText>10 June 2020 10:30</CardInfoText>
                  </div>
                  <hr/>
                  <div>Currency: {"R{{parameter|floatformat:2}}"}
                    <CardInfoText>R1.23</CardInfoText>
                  </div>
                  <hr/>
                  <div>Condition: {"{% if include_payment_link %}Please click {{include_payment_link}}{% endif %}"}
                    <CardInfoText>If include_payment_link is true, then: Please click http://click.me</CardInfoText>
                    <CardInfoText>If include_payment_link is false, then: (displays nothing)</CardInfoText>
                    <CardInfoText>(more conditions are possible, see <a target="_blank" href="https://docs.djangoproject.com/en/3.0/ref/templates/language/?">django docs</a>)</CardInfoText>
                  </div>
                  <hr/>
                </>
              }
            />
        )
    }

    const renderAvailableParameters = () => {
        const available_parameters_list = split(editable_email.available_parameters, ",")
        const static_parameter_list = split(editable_email.static_parameters, ",")

        return (
            <Card
              title="Available parameters"
              content={
                <>
                  { size(available_parameters_list) === 0 &&
                    <div>
                      No parameters are available for this email.
                    </div>
                  }
                  { size(available_parameters_list) > 0 &&
                    <div>
                      <CardInfoText>
                        To use a parameter, put it anywhere in the email between two curly braces, for example:
                        <hr/>
                        Dear {"{{customer.user.first_name}}"},
                        <hr/>

                      </CardInfoText>

                      { map(available_parameters_list, function(parameter, index) { if ( parameter ) { return <div key={index}>{`{{${parameter}}}`}</div> } })}
                      { map(static_parameter_list, function(parameter, index) { if ( parameter ) { return <div key={index}>{`{{${parameter}}}`}</div> } })}
                    </div>
                  }
                  </>
              }
            />
        )
    }

    const exportHtml = () => {
      editor.current.editor.exportHtml((data) => {
        const { design, html } = data
        setHtmlContent(html)
      })
    }

    const saveDesign = () => {
      editor.current.editor.saveDesign(design => {
        setJsonContent(design)
      })
      return jsonContent
    }

    const renderForm = (formik_props) => {
        return (
            <>

              <FormikTextareaField name="subject"
                                   label="Subject"
                                   formik_props={formik_props}
              />

              <FormikTextareaField name="html_content"
                                   label="Html Content"
                                   formik_props={formik_props}
                                   rows={30}
              />
              <Button variant="contained" onClick={onPreview} auto_disable={false}>
                Preview
              </Button>

              <FormikTextareaField name="text_content"
                                   label="Text Content"
                                   formik_props={formik_props}
                                   rows={10}
              />


            {/*
              <CardInfoText>
                DEPRECATED, just put anything you want here.
                After processing, becomes the header_name parameter in the header editable email.
              </CardInfoText>
              <FormikTextareaField name="param_header_name"
                                   label="Header name"
                                   formik_props={formik_props}
                                   rows={5}
              />
              */}

            <div className={classes.saveBar}>
              { can_edit &&
                <Button variant="contained" size="large" type="submit" color="primary" style={{float: "right"}}>
                { is_busy ?
                  <><CircularProgress size={28}  className={classes.buttonProgress} /> Saving...</> :
                  <>SAVE</>
                }
                </Button>
              }
              { ! can_edit && <Error>Editing custom emails is disabled</Error> }
            </div>
          </>
        )
    }

    const loadDesign = useCallback(() => {
      if (editor.current) {
        setLoadEditorDesign(true)
      }
    }, [editor])

    return (
        <AdminMainLayout
        title={ capitalize( replace( replace( get( editable_email, "name" ), /__/g, " - " ), /_/g, " " )) || "New editable email"}
          breadcrumbs={[{name: 'admin_home'},
                        {name: 'editable_emails', label: 'Editable Emails', url: '/admin/editable_emails'},
                        {name: 'editable_email', label: get(editable_email, ["name"], ''), url: `/admin/editable_email/${editable_email_id}`}
                      ]}>

          { is_busy && <BusyMask /> }
          { is_loading && <Loading /> }
          <Typography variant="h6">{title}</Typography>
          { editable_email && editable_email.id &&

            <Formik
              initialValues={initial_values}
              onSubmit={onSave}
              enableReinitialize={true}
              validationSchema={validationSchema}
            >
              {formik_props => {
                  const { values } = formik_props
                  return (
                      <Form>
                        <FormikGeneralFormErrors />
                        <Hidden smDown>
                          <React.StrictMode>
                            <EmailEditor ref={editor} onLoad={loadDesign} />
                          </React.StrictMode>
                        </Hidden>
                        <Hidden smUp>
                          <Alert severity="info">The drag and drop email editor is only available on bigger screens</Alert>
                        </Hidden>
                        <Grid container spacing={1}>
                          <Grid item xs={12} lg={9}>
                          <Card
                            content={
                                      <>
                                        { is_loading && <Loading /> }
                                        { renderForm(formik_props) }
                                      </>
                                    }
                          />
                          </Grid>
                          <Grid item xs={12} lg={3}>
                            { ! is_loading && renderAvailableParameters() }
                            { renderParameterTips() }
                          </Grid>
                        </Grid>
                      </Form>
                  )}
              }
            </Formik>
          }
        </AdminMainLayout>
    )
}


export default AdminEditableEmail
