import { ItemList } from 'orm'
import { get, map, head, filter } from 'lodash'
import { download } from 'actions/lib'

class ContactList extends ItemList {
    getEntityKey() {
        return "contact/contact"
    }

    getAsOptions() {
        const items = this.getVisibleObjects()
        return map(items, function(item) { return {label: item.company.name,
                                                   value: item.company.id}})
    }

    downloadContactReport(contact_id) {
        return download(`contact/contact/${contact_id}/download/`)
    }
}

export const contactContactList = new ContactList("contact_contact__default")
export const contactContactForDropdownList = new ContactList("contact_contact_for_dropdown__default")

export const invalidateAllContactLists = () => {
  return (dispatch, getState) => {
    dispatch(contactContactList.invalidateList())
    dispatch(contactContactList.fetchListIfNeeded())
    dispatch(contactContactForDropdownList.invalidateList())
    dispatch(contactContactForDropdownList.fetchListIfNeeded())
  }
}
