import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { Field, useField, FieldArray } from 'formik'
import { head, compact, last, filter, get, map, values, size } from 'lodash'
import { FormikFileUploadField } from './FileUploader'
import { Trans } from 'react-i18next'
import { fileInfoSetList } from '../../actions/file_info_set'
import { MediaPreviewPopup } from '../MediaPreviewPopup'
import ImagePreview from '../ImagePreview'
import { handleSubmitResult } from 'actions/form'
import { makeStyles, Grid, Link, Typography, Button } from '@material-ui/core'
import DeleteIcon from '@material-ui/icons/DeleteForever'

const useStyles = makeStyles((theme) => ({
  uploadArea: {
    padding: 50,
  },
  uploadedFile: {
    marginBottom: 10,
  },
  imagePreview: {
    textAlign: 'center',
    border: '1px solid #efefef',
    width: '100%',
    padding: 5
  },
  uploadLabel: {
    fontWeight: 500,
    color: '#888'
  }
}))

export const FormikFileSetField = ({ file_types, onChange, formik_props, file_set_id, name, label, type, checked, showPreview, max_num_files, preview_only_one_file_per_row, ...props }) => {
    const classes = useStyles()
    let value = get(formik_props.values, name)
    let files = get(value, "files")
    let filesExist = false
    if(files) {
      files.map(file => {
        if(file.id) {
          filesExist = true
        }
      })
    }
    const dispatch = useDispatch()
    const oneFileOnly = max_num_files === 1 ? true : false
    const initialDropZoneVisibility = filesExist && oneFileOnly ? true : false
    const [hideDropZone, setHideDropZone] = useState(initialDropZoneVisibility)
    const imageGridCols = oneFileOnly || preview_only_one_file_per_row ? 12 : 3
    const gridSpacing = oneFileOnly ? 0 : 2

    const toggleShowDropZone = (evt) => {
      setHideDropZone(hideDropZone => !hideDropZone)
    }

    useEffect(() => {
        if ( ! value ) {
            value = {}
        }
        if ( size(files) === 0 ) {
            files = [{}]
            value.files = files
            formik_props.setFieldValue(name, value)
        }

        if ( ! max_num_files || size(files) < max_num_files ) {
            if ( get(last(files), "id") ) {
                files.push({})
                value.files = files
                formik_props.setFieldValue(name, value)
            }
        }
        if ( size(files) > 1 && ! get(head(files), "id") ) {
            files = files.slice(1)
            value.files = files
            formik_props.setFieldValue(name, value)
        }

    }, [value])

    const new_index = size(files)
    const supported_file_types = file_types || ".jpg, .png, .jpeg, .pdf"
    const upload_relative_url = 'file_info/'

    const onRemoveFile = (evt, index, arrayHelpers) => {
        evt.preventDefault()
        arrayHelpers.remove(index)
        if ( onChange ) {
            onChange()
        }
    }

    const onFileUploaded = (file_info, index, arrayHelpers) => {
        const on_ok = (file_info_set) => {
            formik_props.setFieldValue(name, file_info_set)
            arrayHelpers.insert(size(get(formik_props, ["values", name, "files"])), {})
            if ( onChange ) {
                onChange()
            }
        }
        files[index] = file_info
        dispatch(fileInfoSetList.saveNewObject({files: files})).then((res) => handleSubmitResult({res, on_ok}))
        if(oneFileOnly) {
          setHideDropZone(true)
        }
    }

    const renderUploadedFile = (file_number, file, arrayHelpers) => {
        return (
            <>
            { !showPreview &&
              <MediaPreviewPopup
                file={file}
                render_preview_trigger={(onShowPreview) => (
                   <>

                     <div className={classes.imagePreview}>
                       <Link onClick={onShowPreview}><ImagePreview imageFile={file} /></Link>
                     </div>

                     <Grid container justifyContent="space-between">
                        <Grid item>
                          <Link onClick={onShowPreview}>
                            <Typography variant="caption">[{file_number}] {file.visible_filename}</Typography>
                          </Link>
                        </Grid>
                        <Grid item>
                          <Button item xs={6} lg={1} onClick={(evt) => {onRemoveFile(evt, file_number-1, arrayHelpers); toggleShowDropZone(evt)}}><DeleteIcon style={{color: 'red'}} /> Delete</Button>
                        </Grid>
                     </Grid>

                   </>
                )}
            />
            }
          </>
        )
    }

    const renderPlaceHolder = (file_number, file) => {
        if(label) {
          label = label == 'images' ? label : 'a ' + label
        }
        else {
          label = 'a file'
        }
        return (
          <div>
            <Typography className={classes.uploadLabel}>Click or drag here to upload {label}</Typography>
          </div>
        )
    }

    const renderFileUploadComponent = (name, index, upload_relative_url, formik_props, file, arrayHelpers) => {
      return(
        <FormikFileUploadField
          name={`${name}.files.${index}`}
          upload_relative_url={upload_relative_url}
          file_types={supported_file_types}
          formik_props={formik_props}
          file_number={index+1}
          renderPlaceHolder={() => renderPlaceHolder(index+1, file)}
          onFileUploaded={(file_info) => onFileUploaded(file_info, index, arrayHelpers)}
        />
      )
    }

    return (

          <FieldArray
            name={`${name}.files`}
            render={arrayHelpers => (
                <>
                <Grid container spacing={gridSpacing}>
                  { map(files, function(file, index) {
                      return (
                         <>
                          <Grid item xs={12} lg={imageGridCols} key={index}>
                            { file && file.id && renderUploadedFile(index+1, file, arrayHelpers) }
                          </Grid>
                          { (!file || !file.id) &&
                            <Grid item xs={12}>
                              {!hideDropZone && oneFileOnly &&
                                renderFileUploadComponent(name, index, upload_relative_url, formik_props, file, arrayHelpers)
                              }
                              {!oneFileOnly &&
                                renderFileUploadComponent(name, index, upload_relative_url, formik_props, file, arrayHelpers)
                              }
                            </Grid>
                          }
                        </>
                    )}
                  )}
                </Grid>
              </>
            )}
          />

    )
}
