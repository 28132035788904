import React from 'react'
import { adminDashboardList } from 'admin/components/dashboard/actions/admin_dashboard'
import PopupFilter from 'admin/components/filters/PopupFilter'
import FilterChips from 'admin/components/filters/FilterChips'
import { makeStyles} from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
    filters: {
        float: 'right',
        marginTop: -40,
        [theme.breakpoints.down('sm')]: {
          top: 45,
          right: 0
        },
    },
    filterChips: {
      marginTop: -10,
      float: 'right',
      [theme.breakpoints.down('sm')]: {
        marginTop: -20,
      },
    }
}))

const FILTERS = [{name: 'cities'}, {name: 'date_range'}, {name: 'parcel_states'}, {name: 'parcel_company_types'}]

export default function DashboardPopupFilter({enabled_filters}) {
    const classes = useStyles()

    return (
        <>
        <div className={classes.filters} >
          <PopupFilter enhanced_filter={adminDashboardList.getEnhancedFilter()}
                       enabled_filters={FILTERS}
          />
        </div>
        <div className={classes.filterChips}>
          <FilterChips enhanced_filter={adminDashboardList.getEnhancedFilter()} />
        </div>
      </>
    )

}
