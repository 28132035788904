import React from 'react'
import { get } from 'lodash'
import { Link } from "react-router-dom"
import BeneficiaryMainLayout from '../layout/BeneficiaryMainLayout'
import { EnhancedTable } from 'components/SimpleTable'
import { tickets } from 'sampleData/sampleData'
import { Grid, Hidden, Button, Paper, CardContent, Tooltip, MenuList, MenuItem, Avatar, Fab , ListItemIcon, Typography} from '@material-ui/core'
import { makeStyles, withStyles } from '@material-ui/core/styles'
import { TICKET_STATUS_COLOURS } from 'themes/donorTheme'
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord'
import AddIcon from '@material-ui/icons/Add'
import PageTitle from '../layout/PageTitle'

const useStyles = makeStyles(theme => ({
  filterButton: {
    borderRadius: 20,
    background: '#dfdfdf'
  },
  br: {
    marginRight: 7
  },
  small: {
    width: 20,
    height: 20,
    fontSize: 13,
  },
  fab: {
    position: 'absolute',
    bottom: theme.spacing(2),
    right: theme.spacing(2),
    boxShadow: 0
  },
  fabGreen: {
    color: theme.palette.common.white,
    backgroundColor: '#1faa00',
    '&:hover': {
      backgroundColor: '#64dd17',
    },
  },
  ticketCount: {
    width: 20,
    color: '#999999',
    fontSize: 13,
    border: '1px solid #999999',
    textAlign: 'center',
    marginRight: 7,
    marginLeft: -7,
    borderRadius: '50%',
  }
}))

const TICKET_STATES = [
  {id: "new", color: '#ffeb3b'},
  {id: "unsolved", color: '#d50000'},
  {id: "pending", color: '#64b5f6'},
  {id: "solved", color: '#32cb00'},
  {id: "suspended", color: '#ec407a'},
  {id: "deleted", color: '#cccccc'},
]

const getTicketStatusLabel = (status) => {
  var result = TICKET_STATES.find(({id}) => id === status)
  const label = get(result, "id", "")
  const color = get(result, "color", "")
  return (
    <>
      <Tooltip title={label} arrow><FiberManualRecordIcon style={{color: color}} /></Tooltip>
    </>
  )
}

const ticketsWithStatusIcon = tickets.map(ticket =>{
      return {
        statusIcon: ticket.status ? getTicketStatusLabel(ticket.status) : <div />,
        ...ticket
      }
  })

let mappedTickets = ticketsWithStatusIcon.map(t => {
  return {
      statusIcon: t.statusIcon,
      subject: t.subject,
      requester: t.requester,
      branch: t.branch,
      requested: t.requested,
      type: t.type,
      priority: t.priority,

    }
})

const columns = [

  { id: 'statusIcon',
    label: '',
    width: 50,
  },
  { id: 'subject',
    label: 'Subject',
  },
  { id: 'requester',
    label: 'Requester',
  },
  { id: 'branch',
    label: 'Branch',
  },
  { id: 'requested',
    label: 'Requested',
    width: 150,
  },
  { id: 'type',
    label: 'Type',
    width: 100,
  },
  { id: 'priority',
    label: 'Priority',
    width: 100,
  },
]

const columnsMobile = [
  { id: 'status',
    label: '',
    width: 50
  },
  { id: 'subject',
    label: 'Subject',
  },
  { id: 'requester',
    label: 'Requester',
    width: 100,
  },
  { id: 'type',
    label: 'Type',
    width: 100,
  },
  { id: 'priority',
    label: 'Priority',
    width: 100,
  }
]

export default function BeneficiaryTickets() {
  const classes = useStyles()

  return(
    <BeneficiaryMainLayout
       active_key="tickets"
       breadcrumbs={[
          {name: 'donor_home'},
          {name: 'tickets', label: "Tickets", url: '/donor/tickets'}
      ]}
    >
    <>
    <PageTitle title={"Tickets"} />
    <Grid container spacing={1}>
    <Fab
      aria-label={"NewTicket"}
      component={Link}
      to={'/donor/tickets/new'}
      className={[classes.fab, classes.fabGreen]}
    >
      <AddIcon />
    </Fab>
      <Grid item xs={12} lg={2}>
        <Paper elevation={0} style={{border: '1px solid #efefef'}}>
        <MenuList>
        <MenuItem selected>
          <div className={classes.ticketCount}>3</div>
          <Typography variant="inherit">Your unsolved tickets</Typography>
        </MenuItem>
        <MenuItem>
          <div className={classes.ticketCount}>0</div>
          <Typography variant="inherit">Unassigned tickets</Typography>
        </MenuItem>
        <MenuItem>
          <div className={classes.ticketCount}>0</div>
          <Typography variant="inherit">All unsolved tickets</Typography>
        </MenuItem>
        <MenuItem><div className={classes.ticketCount}>0</div>Recently updated tickets</MenuItem>
        <MenuItem><div className={classes.ticketCount}>0</div>Pending Tickets</MenuItem>
        <MenuItem><div className={classes.ticketCount}>0</div>Recently solved tickets</MenuItem>
        <MenuItem><div className={classes.ticketCount}>0</div>Suspended tickets</MenuItem>
        <MenuItem><div className={classes.ticketCount}>0</div>Deleted tickets</MenuItem>
        </MenuList>
        </Paper>
      </Grid>
      <Grid item xs={12} lg={10}>
        <Hidden smDown>
          <EnhancedTable
            rows = { mappedTickets }
            columns = { columns }
            search = {true}
            title = {"Tickets"}
            filters
          />
        </Hidden>
        <Hidden mdUp>
          <EnhancedTable
            rows={ mappedTickets }
            columns={ columnsMobile }
            search={true}
            filters
            title={"Tickets"}
          />
        </Hidden>
      </Grid>
    </Grid>
    </>
    </ BeneficiaryMainLayout>
  )
}
