import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { adminUserList } from 'admin/components/users/actions/admin_user'
import { FormikMultiSelectDropdownField } from 'components/form/Dropdown'
import Loading from 'components/Loading'
import { FormikGeneralFormErrors } from 'components/form/GeneralFormErrors'
import { Formik, Form } from 'formik'

export const AdminUsersDropdownForm = ({onUpdateUsers, dashboard_users}) => {

    const dispatch = useDispatch()
    const user_options = useSelector(() => adminUserList.getAsOptions())
    const initial_values = dashboard_users
    const is_loading = useSelector(() => adminUserList.isLoading())

    useEffect(() => {
        dispatch(adminUserList.fetchListIfNeeded())
    },[])

    const renderForm = () => {
        return(
            <Formik
                initialValues={initial_values}
                enableReinitialize={true}
            >
                { formik_props => {
                    const { values } = formik_props

                    useEffect(() => {
                        onUpdateUsers(values.users)
                    },[values.users])

                    return(
                        <Form>
                            <FormikMultiSelectDropdownField
                                name="users"
                                label="Users"
                                options={user_options}
                                formik_props={formik_props}
                            />
                        </Form>
                    )
                }}
            </Formik>
        )
    }

    return is_loading ? <Loading /> : renderForm()
}
