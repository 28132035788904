import React, { Component } from 'react'
import { connect } from 'react-redux'
import { forEach, head, get, map, keys, split, size } from 'lodash'
import { adminBringgConfiguration } from './actions/admin_bringg_configuration'
import { adminBringgHistory } from './actions/admin_bringg_history'
import Timestamp from 'components/Timestamp'
import { CommonPaperTable } from 'components/CommonTable'
import { Separator } from 'components/layout/Separator'
import { Formik, Form, Field } from 'formik'
import * as Yup from 'yup'
import {showSuccess, showError} from 'actions/Error'
import AdminMainLayout from 'admin/components/layout/AdminMainLayout'
import { Typography, Card, Grid } from '@material-ui/core'
import Alert from '@material-ui/lab/Alert'

class AdminBringgConfiguration extends Component {

    componentDidMount() {
        const { dispatch } = this.props
        dispatch(adminBringgConfiguration.ensureConfigLoaded())
        dispatch(adminBringgHistory.fetchListIfNeeded())
    }

    componentDidUpdate(prev_props) {
        const { dispatch } = this.props
        dispatch(adminBringgConfiguration.ensureConfigLoaded())
        dispatch(adminBringgHistory.fetchListIfNeeded())
    }

    getCellValue = (header_key, item, index) => {
        switch( header_key ) {
            default:
                return undefined
        }
    }

    onUpdateListOrdering = (field) => {
        const { dispatch } = this.props
        dispatch(adminBringgHistory.updateListOrdering(field))
    }

    renderHistory() {
        const { history_items, is_loading } = this.props

        const columns = [
            { field: 'created_at',
              title: 'Change',
              render: (item) => <div>
                                    <b><Timestamp value={item.created_at} format='datetime' /></b>
                                    <p>{item.description}</p>
                                </div>,
              sort: (direction) => this.onUpdateListOrdering(direction === 'asc' ? 'created_at' : '-created_at'),
            },

        ]

        return (
            <>
              <Typography variant="h6">Bringg Change History</Typography>
              <CommonPaperTable
                is_loading={ is_loading }
                rows={history_items}
                columns={columns}
                item_list={adminBringgHistory}
                canDelete={false}
              />
            </>
        )
    }

    renderUrls() {
        const { configuration } = this.props
        return (
            <>

              <Typography variant="h6">Required Webhook URLs</Typography>

              <Alert severity="info">
                These urls must be configured in the bringg app, eg on the page &nbsp;
                <a target="_blank" href="https://app.bringg.com/#/merchant/webhooks/">https://app.bringg.com/#/merchant/webhooks/</a>
              </Alert>


              <Card variant="outlined" style={{marginTop: 10}}>
                <ul>
                  {map(get(configuration, 'webhook_urls', []), (url) => (
                      <li>
                        <pre>{url}</pre>
                      </li>
                  ))}
                </ul>
              </Card>

            </>
        )
    }

    render() {
        const { initial_values, is_loading, is_busy } = this.props
        const that = this
        const title = 'Bringg Config & Change Log'

        return (
            <AdminMainLayout breadcrumbs={[{name: 'admin_home'},
                                                 {name: 'bringg',
                                                  label: 'Bringg Config & Change Log',
                                                  url: '/admin/bringg_configuration'},
                                                ]}
                                   is_busy={is_busy}
                                   is_loading={is_loading}
                                   title={title}
            >
              <Grid container spacing={2}>
                <Grid item xs={12} lg={7}>
                  { this.renderUrls() }
                </Grid>
                <Grid  item xs={12} lg={5}>
                  { this.renderHistory() }
                </Grid>
              </Grid>
            </AdminMainLayout>
        )
    }

}

function mapStateToProps(state, props) {
    return {
        is_loading: adminBringgConfiguration.isLoading() || adminBringgHistory.isLoading(),
        is_busy: adminBringgConfiguration.getIsSavingObject(),
        configuration: adminBringgConfiguration.getConfiguration(),
        history_items: adminBringgHistory.getVisibleObjects(),
    }
}

export default connect(mapStateToProps)(AdminBringgConfiguration)
