import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { useParams } from 'react-router-dom'
import { head, get, map, keys, split, size } from 'lodash'
import { adminEditableEmailList } from './actions/admin_editable_email'
import { Separator } from 'components/layout/Separator'
import { Formik, Form, FieldArray, Field } from 'formik'
import Loading from 'components/Loading'
import { FormikTextarea } from 'components/form/TextareaField'
import { FormikInputField } from 'components/form/InputField'
import AdminMainLayout from 'admin/components/layout/AdminMainLayout'
import BusyMask from 'components/BusyMask'
import Timestamp from 'components/Timestamp'
import { Card } from 'components/layout/Card'
import * as Yup from 'yup'
import {showSuccess, showError} from 'actions/Error'
import CardInfoText from 'components/layout/CardInfoText'
import { handleSubmitResult } from 'actions/form'
import { FormikGeneralFormErrors } from 'components/form/GeneralFormErrors'
import WrappingBusyMask from 'components/WrappingBusyMask'
import { makeStyles, Grid, Typography, Button } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
  root: {
    width: 600,
    border: '1px solid #efefef',
    margin: 'auto',
    padding: 15
  },
  displayTypeHeading: {
    fontWeight: 500,
    margin: '15px 0'
  }
}))

export default function AdminEditableEmail({ ...props }) {
    const classes = useStyles()
    const dispatch = useDispatch()
    const { editable_email_id } = useParams()
    const editable_email = adminEditableEmailList.getObject(editable_email_id)
    const title = `${get(editable_email, ["description"])} (lang: ${get(editable_email, ["language_code"])})`
    const is_loading = adminEditableEmailList.isLoading()
    const is_ready = adminEditableEmailList.isReady()

    useEffect(() => {
        dispatch(adminEditableEmailList.ensureObjectLoaded(editable_email_id))
    })

    const onRefresh = () => {
        dispatch(adminEditableEmailList.invalidateObject(editable_email_id))
        dispatch(adminEditableEmailList.ensureObjectLoaded(editable_email_id))
    }

    const renderHtmlPreviewMarkup = () => {
        return { __html: editable_email.preview_info.html_content }
    }

    return (
        <div className={classes.root}>
          { is_loading && <Loading /> }


          <WrappingBusyMask is_loading={is_loading || !is_ready}>
            { editable_email && editable_email.id &&
              <div>
                <Typography variant="h6">{editable_email.preview_info.subject}</Typography>
                <Typography variant="p">{title}</Typography>
                <p className={classes.displayTypeHeading}>HTML Preview</p>
                <div  dangerouslySetInnerHTML={renderHtmlPreviewMarkup()} />
                <p className={classes.displayTypeHeading}>Text Preview</p>
                <div>
                  <pre>{editable_email.preview_info.text_content}</pre>
                </div>
              </div>
            }

            <Button onClick={window.close}>Close</Button>
            <Button onClick={onRefresh}>Refresh</Button>
          </WrappingBusyMask>
        </div>
    )
}
