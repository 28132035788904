import React, { useState } from 'react'
import { Grid, Card, CardContent, Typography, Table, TableRow, TableCell, Button, IconButton, Collapse, Divider } from '@material-ui/core'
import { map, replace, startCase, reduce, get, find, isEmpty, filter, sumBy, omit, findIndex, isEqual } from 'lodash'
import Loading from 'components/Loading'
import AdminDemographicsChart from './AdminDemographicsChart'
import DeleteIcon from '@material-ui/icons/Delete'

function DemographicsTotal( { data, is_loading, filters } ) {

  const [ comparedData, setComparedData ] = useState( [] )
  const existingIndex = findIndex( comparedData, ( compData ) => isEqual( compData.filters, filters ) )

  const filteredData = filter( data, filters )

  const totalPeople = sumBy( filteredData, 'number_of_people' )

  const totalComparedPeople = sumBy( comparedData, 'totalPeople' )

  const [ showChart, setShowChart ] = useState( false )

  const addToCompare = () => {
    let companyName = ''
    if ( 'company' in filters ) {
      const company = find( data, ( item ) => get( item, "company" ) === get( filters, "company" ) )
      companyName = company ? get( company, "company_name" ) : filters.company
    }

    // Check if a similar filter set already exists

    if ( existingIndex !== -1 ) {
      alert( 'A similar comparison already exists.' )
      return
    }

    setComparedData( [
      ...comparedData,
      {
        filters,
        companyName,
        totalPeople,
        data: filteredData,
      }
    ] )
  }

  const removeFromCompare = ( index ) => {
    const newData = [ ...comparedData ]
    newData.splice( index, 1 )
    setComparedData( newData )
  }

  const clearComparison = () => {
    setComparedData( [] )
  }

  // assuming 'data' is the demographics data
  // const totalPeople = reduce( data, ( sum, demographic ) => {
  //   return sum + demographic.number_of_people
  // }, 0 )

  let filtersArray = []

  // Check if 'company' key is in filters and handle it separately
  if ( 'company' in filters ) {
    const company = find( data, ( item ) => get( item, "company" ) === get( filters, "company" ) )
    const companyName = company ? get( company, "company_name" ) : filters.company
    filtersArray.push(
      <>
        <TableCell style={ { width: '30%' } }>
          Company
        </TableCell>
        <TableCell>
          { companyName }
        </TableCell>
      </> )
  }

  // Map over the rest of the filter object
  map( filters, ( value, key ) => {
    if ( key !== 'company' ) { // Skip 'company' since we've already handled it
      const formattedKey = startCase( replace( key, /_/g, ' ' ) )
      const formattedValue = startCase( replace( value, /_/g, ' ' ) )
      filtersArray.push(
        <>
          <TableCell style={ { width: '30%' } }>
            { formattedKey }
          </TableCell>
          <TableCell>
            { formattedValue }
          </TableCell>
        </>
      )
    }
  } )

  return (
    <div style={ { padding: 20, marginBottom: 10, background: '#546E7A' } }>
      <Grid container spacing={ 3 } justifyContent="center" alignItems="center" alignContent="center">
        { data &&
          <Grid item xs={ 12 }>
            <Card style={ { borderRadius: 8 } }>
              <CardContent>
                <Grid container spacing={ 3 }>
                  { !isEmpty( filters ) &&
                    <Grid item xs={ 12 } md={ 6 }>
                      <Typography style={ { color: '#455A64', textTransform: 'uppercase' } }>
                        <Table>
                          { filtersArray && map( filtersArray, ( filter, index ) => (
                            <TableRow>
                              { filter }
                            </TableRow>
                          ) ) }
                        </Table>
                      </Typography>
                      { existingIndex === -1 &&
                        <Button onClick={ addToCompare } variant="contained" size="small" color="secondary" style={ { marginTop: 20 } }>
                          Add to Compare
                        </Button>
                      }
                    </Grid>
                  }
                  <Grid item xs={ 12 } md={ isEmpty( filters ) ? 12 : 6 }>
                    <Typography variant="h1" style={ { color: '#37474F', textAlign: 'center' } }>
                      { is_loading ? <Loading /> : totalPeople }
                    </Typography>
                    <Typography style={ { textAlign: 'center', fontSize: 24, color: '#607D8B' } }>TOTAL</Typography>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>
        }

      </Grid>
      { comparedData.length > 0 &&
        <div>
          <Typography variant="h4" style={ { marginBottom: 20, color: '#F5F5F5' } }>Comparison Area</Typography>
          { comparedData.length > 1 &&
            <Button onClick={ () => setShowChart( prevShowChart => !prevShowChart ) } variant="outlined" style={ { color: '#F5F5F5', borderColor: '#F5F5F5', marginBottom: 20 } }>
              { showChart ? 'Hide Chart' : 'Show Chart' }
            </Button>
          }
          <Collapse in={ showChart && comparedData.length > 1 }>
            <div style={ { paddingBottom: 15 } }>
              <AdminDemographicsChart
                title="Demographics Comparison"
                subheader="Comparison of demographics based on filters"
                data={ comparedData }
                total={ totalComparedPeople }
              />
            </div>
          </Collapse>
          <Grid container spacing={ 3 } alignContent="center">
            { comparedData.map( ( compData, index ) => (
              <Grid item xs={ 12 } lg={ 3 } key={ index } style={ { position: 'relative' } }>
                <Card variant='outlined' style={ { borderRadius: 8 } }>
                  <CardContent>
                    <Typography variant="h5" style={{ color: '#263238'}}>
                      { map( omit( compData.filters, 'company' ), ( value, key ) => (
                        `${ startCase( replace( value, /_/g, ' ' ) ) }`
                      ) ).join( ', ' ) }
                    </Typography>
                    { compData.companyName &&
                      <Typography variant="body2">Company: { compData.companyName }</Typography>
                    }
                    <Typography style={ { fontSize: 24, color: '#455A64' } }>{ compData.totalPeople } People</Typography>
                    <Typography style={ { fontSize: 18, color: '#546E7A' } }>
                      { ( compData.totalPeople / totalComparedPeople * 100 ).toFixed( 2 ) }%
                    </Typography>


                  </CardContent>
                  <IconButton onClick={ () => removeFromCompare( index ) } style={ { color: 'red', position: 'absolute', top: 10, right: 10 } }>
                    <DeleteIcon />
                  </IconButton>
                </Card>
              </Grid>
            ) )
            }
          </Grid>
          { comparedData.length > 0 &&
            <Button onClick={ clearComparison } variant="outlined" style={ { color: '#F5F5F5', borderColor: '#F5F5F5', marginTop: 15 } }>
              Reset Comparison
            </Button>
          }
        </div>
      }
    </div>
  )
}

export default DemographicsTotal