import React from 'react'
import { get, size } from 'lodash'
import { makeStyles } from '@material-ui/core/styles'
import IconButton from '@material-ui/core/IconButton'
import { Link } from 'react-router-dom'
import MenuItem from '@material-ui/core/MenuItem'
import Popover from '@material-ui/core/Popover'
import Avatar from '@material-ui/core/Avatar'
import { clearAuthentication } from 'actions/auth'
import { userList } from 'actions/user'
import AdminUserNotifications from 'admin/components/notifications/AdminUserNotifications'

const useStyles = makeStyles((theme) => ({
    toolbarButtons: {
        marginLeft: 'auto',
    },
    greeting: {
      padding: '7px 15px 7px 15px',
      borderBottom: '1px solid #efefef',
      fontWeight: 500,
      fontSize: 16
    },
    menuLinks: {
      textDecoration: 'none',
      color: '#000000',
    }
}))

export default function LoggedInUser() {
    const classes = useStyles()
    const logged_in_user = userList.getUser()
    const [auth, setAuth] = React.useState(true)
    const [anchorEl, setAnchorEl] = React.useState(null)
    const open = Boolean(anchorEl)
    const user_popover_id = open ? 'profile-popover' : undefined

    const handleMenu = (event) => {
        setAnchorEl(event.currentTarget)
    }

    const handleClose = () => {
        setAnchorEl(null)
    }

    const onLogout = () => {
        clearAuthentication()
        window.location="/"
    }

    return (
        <div className={classes.toolbarButtons}>

          <IconButton
            aria-label="account of current user"
            aria-controls="menu-appbar"
            aria-haspopup="true"
            onClick={handleMenu}
            color="inherit"
          >
            <Avatar
              alt={`${get(logged_in_user, 'first_name')} ${get(logged_in_user, 'last_name')}`}
              src={get(logged_in_user, ['profile_image', 'original_download_url'])}
              style={{color: '#37474f', background: '#b0bec5'}}
              onClick={handleMenu}
            />
          </IconButton>
          <Popover
            id={user_popover_id}
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'center',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'center',
            }}
          >
            <div className={classes.greeting}>Hi {get(logged_in_user, ["first_name"])}</div>
            <MenuItem><Link to={`/admin/profile/${get(logged_in_user, "id")}`} className={classes.menuLinks}>Profile</Link></MenuItem>
            <MenuItem className={classes.menuLinks} onClick={onLogout}>Logout</MenuItem>
          </Popover>
          <AdminUserNotifications user_id={ get(logged_in_user, 'id')} />
        </div>
    )
}
