import { useState, useEffect, useRef } from 'react'
import { Field } from 'formik'
import { TextField } from 'formik-material-ui'
import { SketchPicker } from 'react-color'
import {
    FormControl,
    InputLabel
} from '@material-ui/core'
import { IconButton, makeStyles } from '@material-ui/core'
import { unpackFormikPropsForField } from 'actions/form'
import { get } from 'lodash'
import { FiberManualRecord } from '@material-ui/icons'

const useStyles = makeStyles({
    root: {
        display: 'flex',
        alignItems: 'center',
        '& > *': {
            marginRight: '8px',
        },
    },
    colorPickerContainer: {
        position: 'relative',
    },
    colorPicker: {
        position: 'absolute',
        top: '100%',
        left: 0,
        zIndex: 1000,
    },
})


export const FormikColorPicker = ({ label, name, placeholder, formik_props, ...props }) => {
    const classes = useStyles()
    const [color, setColor] = useState('#333')
    const [showColorPicker, setShowColorPicker] = useState(false)
    const { errors, touched } = unpackFormikPropsForField({ formik_props, name })
    const colorPickerContainerRef = useRef(null)

    useEffect(() => {
        const handleDocumentClick = (event) => {
          if (
            colorPickerContainerRef.current &&
            !colorPickerContainerRef.current.contains(event.target)
          ) {
            setShowColorPicker(false)
          }
        }
    
        document.addEventListener('mousedown', handleDocumentClick)
    
        return () => {
          document.removeEventListener('mousedown', handleDocumentClick)
        }
      }, [colorPickerContainerRef])

    const handleClick = () => {
        setShowColorPicker(!showColorPicker)
    }

    const localOnChange = (color) => {
        setColor(color)
        formik_props.setFieldValue(name, color)
        formik_props.setFieldTouched(name)
    }

    return (
        <div className={classes.colorPickerContainer} ref={colorPickerContainerRef} >
            <FormControl variant="outlined" fullWidth>
                <Field
                    component={TextField}
                    name={name}
                    label={label || placeholder}
                    placeholder={label || placeholder}
                    id={name}
                    multiple
                    helperText={touched ? get(formik_props, ["errors", name]) : ""}
                    error={touched && Boolean(get(formik_props, ["errors", name]))}
                    margin="normal"
                    variant="outlined"
                    onClick={handleClick}
                    fullWidth
                    InputProps={{
                        className: classes.root,
                        startAdornment: (
                          <FiberManualRecord
                            style={{ color: get(formik_props, ["values", name]), marginRight: '8px', backgroundColor: get(formik_props, ["values", name]) }}
                          />
                        ),
                        onClick: handleClick,
                      }}
                    {...props}
                />
            </FormControl>
            { showColorPicker &&
                <div className={classes.colorPicker}>
                    <SketchPicker color={color} onChange={color => localOnChange(color.hex)} onChangeComplete={color => localOnChange(color.hex)} />
                </div>
            }
        </div>
    )
}
