import { ItemList } from 'orm'
import { map, keys, sortBy, head } from 'lodash'
import { EnhancedFilter } from 'admin/actions/enhanced_filter'

class AdminDashboardList extends ItemList {
    getEntityKey() {
        return 'admin/dashboard'
    }

    loadData() {
        return this.fetchListIfNeeded()
    }

    getData() {
        return head(this.getVisibleObjects())
    }

    getEnhancedFilter() {
        if ( ! this.enhanced_filter ) {
            this.enhanced_filter = new EnhancedFilter(this)
        }
        return this.enhanced_filter
    }

}

export const adminDashboardList = new AdminDashboardList('admin_dashboard__default')
export const adminDashboardListForToday = new AdminDashboardList('admin_dashboard_for_today__default')

export const unpackCityData = (totals_by_city) => {
    const rows = sortBy(map(keys(totals_by_city),
                            function(city_name) { return {name: city_name,
                                                          value: totals_by_city[city_name]}
                                                }),
                        'name')
    return rows
}
