import { IconButton, Tooltip } from '@material-ui/core'
import GetAppIcon from '@material-ui/icons/GetApp'
import Loading from 'components/Loading'

export const ReportDownloadLink = ( { tooltip, onClick, is_creating_report } ) => {
  return (
    <Tooltip title={ tooltip } arrow>
      <IconButton
        style={ {
          color: '#777777'
        } }
        onClick={ onClick }
        size="large"
      >
        <GetAppIcon />
        { is_creating_report && <Loading msg="Creating Report..." /> }
      </IconButton>
    </Tooltip>
  )
}
