import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useParams } from 'react-router-dom'
import { get } from 'lodash'
import { adminCityList } from './actions/admin_city'
import {showSuccess, showError} from 'actions/Error'
import { handleSubmitResult } from 'actions/form'
import AdminCommonFormLayout from '../layout/AdminCommonFormLayout'
import AdminCityForm from './form/AdminCityForm'
import { validationSchema } from './form/AdminCityForm'

export default function AdminCity() {
    const dispatch = useDispatch()
    const history = useHistory()
    const { city_id } = useParams()
    const city = useSelector(() => adminCityList.getObject(city_id))
    const is_loading = useSelector(() => adminCityList.isLoading())  || (city_id && !get(city, "id"))
    const is_busy = useSelector(() => adminCityList.getIsSavingObject())
    const initial_values = city
    const title = `${get(city, "name", 'New city')}`

    useEffect(() => {
        dispatch(adminCityList.ensureObjectLoaded(city_id))
    },[])

    const onSave = (values, formik_funcs) => {
        const on_ok = function(json) {
            dispatch(adminCityList.invalidateList())
            showSuccess("Saved", "City saved")

            if ( ! city_id ) {
                history.push(`/admin/city/${json.id}`)
            }
        }
        if ( city_id ) {
            values.id = city_id
            return dispatch(adminCityList.saveObject(values)).then((res) => handleSubmitResult({res, formik_funcs, on_ok}))
        } else {
            return dispatch(adminCityList.saveNewObject(values)).then((res) => handleSubmitResult({res, formik_funcs, on_ok}))
        }
    }

    const getCellValue = (header_key, item, index) => {
        switch( header_key ) {
            default:
                return undefined
        }
    }


    return (
        <AdminCommonFormLayout
            breadcrumbs={[
                {name: 'admin_home'},
                {name: 'cities', label: 'Cities', url: '/admin/cities'},
                {name: 'city',  label: get(city, "name", 'New city'), url: `/admin/city/${city_id}`}
            ]}
            is_busy={is_busy}
            is_loading={is_loading}
            title={title}
            initial_values={initial_values}
            validationSchema={validationSchema}
            onSave={onSave}
        >
          {
              ({formik_props}) => <AdminCityForm formik_props={formik_props} />
          }
        </AdminCommonFormLayout>
    )
}
