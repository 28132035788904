import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import { Card, Typography, alpha } from '@material-ui/core'
import Iconify from 'components/iconify'
import { bgGradient } from 'utils/cssStyles'
import { fShortenNumber } from 'utils/formatNumber'
import { getTrend } from './layout/Trend'

const styles = theme => ({
  card: {
    padding: theme.spacing(3),
    boxShadow: '0px 1px 2px 0px rgba(0,0,0,0.3)',
    textAlign: 'center',
    color: '#000',
  },
  icon: {
    marginBottom: theme.spacing(0),
    padding: theme.spacing(0),
    width: 64,
    height: 64,
    color: theme.palette.primary.dark,
    background: bgGradient({
      direction: '135deg',
      startColor: `${alpha(theme.palette.primary.dark, 0)} 0%`,
      endColor: `${alpha(theme.palette.primary.dark, 0.24)} 100%`,
    }),
  },
  total: {
    fontWeight: 'bold',
    fontSize: 42
  },
  subtitle: {
    opacity: 0.8,
  },
  success: {
    color: '#2e7d32'
  },
  warning: {
    color: '#ed6c02'
  },
  error: {
    color: '#d32f2f'
  },
  general: {
    color: '#001E3C'
  }
})

function KpiWidgetSummary(props) {
  const { classes, title, total, icon, color, format, trend } = props
  const { trendColor, trendIcon, trendDirection } = getTrend(trend)
  
  let cardBgColorClass = ''

  return (
    <Card className={classes.card}>
      {/* <Iconify icon={icon} className={classes.icon} /> */}

      <Typography variant="h3" className={`${classes.total} ${classes[color]}`}>
        {format != 'string' ? total > 0 ? fShortenNumber(total): total : total}
      </Typography>

      <Typography variant="subtitle2" className={classes.subtitle}>
        {title}
      </Typography>
      { trend && 
        <Typography variant="caption" style={{ fontWeight: 500, color: trendColor }}>
          {trendIcon}{trendDirection}{trend}%
        </Typography>
      }

    </Card>
  )
}

KpiWidgetSummary.propTypes = {
  classes: PropTypes.object.isRequired,
  title: PropTypes.string.isRequired,
  total: PropTypes.number.isRequired,
  icon: PropTypes.string.isRequired,
  color: PropTypes.string,
}

export default withStyles(styles)(KpiWidgetSummary)