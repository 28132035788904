import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useParams } from 'react-router-dom'
import { forEach, head, get, map, keys, split, size } from 'lodash'
import { adminCompanyReport } from './actions/admin_company_report'
import { Separator } from 'components/layout/Separator'
import { Modal } from 'components/layout/Modal'
import Title from 'components/layout/Title'
import { Formik, Form, Field } from 'formik'
import * as Yup from 'yup'
import {showSuccess, showError} from 'actions/Error'
import { handleSubmitResult } from 'actions/form'
import AdminCommonListLayout from '../layout/AdminCommonListLayout'
import AdminCommonListTable from '../layout/AdminCommonListTable'
import { FormikDateTimePicker } from 'components/form/DatetimePicker'
import { FormikInputField } from 'components/form/InputField'
import { FormikDropdownField } from 'components/form/Dropdown'
import { Typography, Grid, Button, Card, CardContent, IconButton, Tooltip, makeStyles } from '@material-ui/core'
import { adminCompanyReportNames, adminCompanyReportFrequencies } from 'admin/actions/admin_dropdown_options'
import CheckCircleIcon from '@material-ui/icons/CheckCircle'
import CancelIcon from '@material-ui/icons/Cancel'
import { AdminCompanyReport } from './AdminCompanyReport'
import HistoryIcon from '@material-ui/icons/History'
import { AdminCompanyReportGenerations } from './AdminCompanyReportGenerations'
import DateRangeIcon from '@material-ui/icons/DateRange'
import ScheduleIcon from '@material-ui/icons/Schedule'

export const validationSchema = Yup.object().shape({
})

const useStyles = makeStyles((theme) => ({
  buttonIcon: {
    marginLeft: -10,
    marginRight: 10,
  },
}))

export const AdminCompanyReports = ({asTable, company_id}) => {
    const classes = useStyles()
    const history = useHistory()
    const dispatch = useDispatch()
    if(!company_id) {
      company_id = useParams()
    }
    const reports = useSelector(() => adminCompanyReport.getVisibleObjects())
    const [itemId, setItemId] = useState()
    const [reportName, setReportName] = useState()
    const [reportFrequency, setReportFrequency] = useState()
    const [showConfigureReportModal, setShowConfigureReportModal] = useState(false)
    const [showReportHistory, setShowReportHistory] = useState(false)
    const [newReport, setNewReport] = useState(false)
    const add_label = <><ScheduleIcon className={classes.buttonIcon} /> Configure Report</>

    useEffect(() => {
        dispatch(adminCompanyReport.updateListFilter({company: company_id}))
        dispatch(adminCompanyReport.fetchListIfNeeded())
    }, [company_id, newReport])

    useEffect(() => {
        dispatch(adminCompanyReportNames.fetchListIfNeeded())
        dispatch(adminCompanyReportFrequencies.fetchListIfNeeded())
        dispatch(adminCompanyReport.fetchListIfNeeded())
    }, [])

    const onAddReport = () => {
        //history.push(`/admin/${company_id}/company_report/`)
        setShowConfigureReportModal(true)
    }

    const onEditReport = (item_id) => {
        //history.push(`/admin/${company_id}/company_report/${item_id}/`)
        setItemId(item_id)
        setShowConfigureReportModal(true)
    }

    const onHistoryReport = (item_id, reportName, reportFrequency) => {
        //history.push(`/admin/${company_id}/company_report/${item_id}/`)
        setItemId(item_id)
        setReportName(reportName)
        setReportFrequency(reportFrequency)
        setShowReportHistory(true)
    }

    const columns = [
        { field: 'report_name',
          title: 'Report',
          render: (item) => get(adminCompanyReportNames.getObjectsById(), [item.report_name, "name"]),
          sort: (direction) => this.onUpdateListOrdering(direction === 'asc' ? 'report_name' : '-report_name'),
        },
        { field: 'frequency',
          title: 'Frequency',
          render: (item) => get(adminCompanyReportFrequencies.getObjectsById(), [item.frequency, "name"]),
          sort: (direction) => this.onUpdateListOrdering(direction === 'asc' ? 'frequency' : '-frequency'),
        },
        { field: 'enabled',
          title: 'Email Sending',
          align: 'center',
          render: (item) => item.enabled ? <CheckCircleIcon style={{color: 'green'}} />: <CancelIcon style={{color: 'red'}} />,
          sort: (direction) => this.onUpdateListOrdering(direction === 'asc' ? 'enabled' : '-enabled'),
        },
        { field: 'number_of_reports_generated',
          title: 'Count',
          sort: (direction) => this.onUpdateListOrdering(direction === 'asc' ? 'number_of_reports_generated' : '-number_of_reports_generated'),
        },
        { field: '',
          title: 'History',
          render: (item) =>
            <Tooltip title="Click to view history">
              <IconButton
                onClick={() => onHistoryReport(
                  item.id,
                  get(adminCompanyReportNames.getObjectsById(), [item.report_name, "name"]),
                  get(adminCompanyReportFrequencies.getObjectsById(), [item.frequency, "name"])
                )}>
                <HistoryIcon />
              </IconButton>
            </Tooltip>,
        },

    ]

    return (
      <>

        {!asTable ?

          <AdminCommonListLayout breadcrumbs={[{name: 'admin_home'},
                                               {name: 'company_reports',
                                                label: 'Reports',
                                                url: `/admin/${company_id}/company_reports/`}]}
                                 title="Reports"
                                 add_label={add_label}
                                 onAddRow={onAddReport}
                                 onEditRow={onEditReport}
                                 items={reports}
                                 columns={columns}
                                 item_list={adminCompanyReport}
          />

          :

          <AdminCommonListTable add_label={add_label}
                                 onAddRow={onAddReport}
                                 onEditRow={onEditReport}
                                 onHistoryRow={onHistoryReport}
                                 items={reports}
                                 columns={columns}
                                 item_list={adminCompanyReport}
                                 inTabs={true}
          />

      }

      { showConfigureReportModal &&
        <AdminCompanyReport
          inModal={true}
          company_id={company_id}
          company_report_id={itemId}
          setShowConfigureReportModal={setShowConfigureReportModal}
          setNewReport={setNewReport}
        />
      }
      { showReportHistory &&
        <Modal
          title={`History: ${reportName}`}
          fullScreen
          onClose={() => setShowReportHistory(false)}
          company_report_id={itemId}
          noPadding
        >
          <Title style={{paddingLeft: 40, marginTop: -15, marginBottom: 20, fontSize: 18, color: 'white'}}>Frequency: {reportFrequency}</Title>
          <AdminCompanyReportGenerations company_report_id={itemId} />
        </Modal>
      }

      </>

    )
}
