import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import IconButton from '@material-ui/core/IconButton'
import MenuIcon from '@material-ui/icons/Menu'
import AccountCircle from '@material-ui/icons/AccountCircle'
import { Link, useHistory } from 'react-router-dom'
import MenuItem from '@material-ui/core/MenuItem'
import Popover from '@material-ui/core/Popover'
import { clearAuthentication } from 'actions/auth'
import { useDispatch } from 'react-redux'
import Notifications from './Notifications'
import { userList } from 'actions/user'

const useStyles = makeStyles((theme) => ({
    toolbarButtons: {
        marginLeft: 'auto',
    },
}))

export default function LoggedInUser() {
    const classes = useStyles()
    const [auth, setAuth] = React.useState(true)
    const [anchorEl, setAnchorEl] = React.useState(null)
    const open = Boolean(anchorEl)
    const id = open ? 'profile-popover' : undefined
    const dispatch = useDispatch()
    const history = useHistory()
    const hideNotifications = true
    const loggedInUser = userList.getUser()

    const handleChange = (event) => {
        setAuth(event.target.checked)
    }

    const handleMenu = (event) => {
        setAnchorEl(event.currentTarget)
    }

    const handleClose = () => {
        setAnchorEl(null)
    }

    const onLogout = () => {
        clearAuthentication()
        window.location="/impact"
    }

    return (
        <div className={classes.toolbarButtons}>
          {!hideNotifications && <IconButton><Notifications /></IconButton> }
          {loggedInUser.first_name && <>Hi {loggedInUser.first_name}</>}
          <IconButton
            aria-label="account of current user"
            aria-controls="menu-appbar"
            aria-haspopup="true"
            onClick={handleMenu}
            color="inherit"
          >
            <AccountCircle fontSize="large"/>
          </IconButton>
          <Popover
            id={id}
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'center',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'center',
            }}
          >
            {(loggedInUser.contact_companies > 0) && <MenuItem component={Link} to={"/"}>Logged in Home</MenuItem> }
            {(loggedInUser.is_staff || loggedInUser.is_superuser) && <MenuItem component={Link} to={"/admin"}>Admin Portal</MenuItem> }
            <MenuItem onClick={onLogout}>Profile</MenuItem>
            <MenuItem onClick={onLogout}>Logout</MenuItem>
          </Popover>
        </div>
    )
}
