import FullCalendar from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid'
import timeGridPlugin from '@fullcalendar/timegrid'
import interactionPlugin from '@fullcalendar/interaction'
import { get, map, startCase } from 'lodash'
import moment from 'moment'
import { getRandomColor, getContrastColor } from 'utils/utils'

const schedulesToEvents = (schedules) => {
  return map(schedules, schedule => {
    const mealtime = startCase(get(schedule, 'mealtime_name'))
    const mealtimeStart = moment(get(schedule, 'mealtime_time')).format('HH:mm:ss')

    const duration = '01:00:00'  // 1 hour duration

    const daysOfWeek = get(schedule, 'days_of_week', [])
    const daysOfMonth = get(schedule, 'days_of_month', [])

    let days
    if (daysOfWeek.length > 0) {
      days = daysOfWeek  // for weekly frequency
    } else if (daysOfMonth.length > 0) {
      days = daysOfMonth  // for monthly frequency
    }

    // const color = getRandomColor();
    // const textColor = getContrastColor(color);
    
    return {
      title: mealtime,
      daysOfWeek: days,
      startTime: mealtimeStart,
      duration: duration,
      // color: color,
      // textColor: textColor
    }
  })
}


export const AdminFeedingCalendar = ( { schedules } ) => {
  const events = schedulesToEvents( schedules )

  return (
    <FullCalendar
      plugins={ [ timeGridPlugin, dayGridPlugin ] }
      headerToolbar={{
        left: 'prev,next today',
        center: 'title',
        right: 'timeGridDay,timeGridWeek,dayGridMonth'
    }}
      initialView="timeGridWeek"
      events={ events }
      slotMinTime="06:00:00"
      slotMaxTime="22:00:00"
      timeZone="local"
    />
  )
}
