import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { get, round } from 'lodash'
import AdminCommonListLayout from 'admin/components/layout/AdminCommonListLayout'
import { adminBeneficiaryList } from './actions/admin_beneficiary'
import { countryList } from 'actions/country'
import { provinceList } from 'actions/province'
import { statusList } from 'actions/status'
import { ReportDownloadLink } from 'components/layout/ReportDownloadLink'
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord'
import { Tooltip } from '@material-ui/core'
import { getContentStatus } from 'components/companies/RenderTableCellContent'
import { ShortId } from 'components/ShortId'
import Timestamp from 'components/Timestamp'
import Avatar from '@material-ui/core/Avatar'

export default function Beneficiaries(props) {

    const dispatch = useDispatch()
    const history = useHistory()
    const is_loading = useSelector(() => adminBeneficiaryList.isLoading())
    const beneficiaries = useSelector(() => adminBeneficiaryList.getVisibleObjects())
    const country_options = useSelector(() => countryList.getAsOptions())
    const province_options = useSelector(() => provinceList.getAsOptions())
    const statuses = useSelector(() => statusList.getAsOptions())

    useEffect(() => {
        dispatch(countryList.updatePaginationNumItemsPerPage(1000))
        dispatch(provinceList.updatePaginationNumItemsPerPage(1000))
        dispatch(statusList.updatePaginationNumItemsPerPage(1000))
        dispatch(adminBeneficiaryList.fetchListIfNeeded())
        dispatch(countryList.fetchListIfNeeded())
        dispatch(provinceList.fetchListIfNeeded())
        dispatch(statusList.fetchListIfNeeded())
    }, [])

    const onUpdateListOrdering = (field) => {
      dispatch(adminBeneficiaryList.updateListOrdering(field))
    }

    const onCountryChanged = (country_id) => {
        const { dispatch } = props
        dispatch(provinceList.filterOnCountry(country_id))
    }

    const onEditBeneficiary = (beneficiary_id) => {
        history.push({
            pathname: '/admin/beneficiary/'+beneficiary_id
        })
    }

    const onAddBeneficiary = () => {
        const { history } = props
        history.push('/admin/beneficiary')
    }

    const onDownload = () => {
        const { dispatch } = props
        adminBeneficiaryList.download()
    }

    const renderActions = () => {
        const { is_creating_report } = props
        return <ReportDownloadLink onClick={onDownload}
                                   tooltip="Download beneficiary list"
                                   is_creating_report={is_creating_report} />
    }

    const renderBeneficiaryStatus = (status) => {
      var status_color = ''
      switch(status) {
        case 'suspended':
          status_color = 'red'
          break
        case 'application_received':
          status_color = 'orange'
          break
        case 'active':
          status_color = 'green'
          break
      }
      return(<Tooltip arrow title={status}><FiberManualRecordIcon style={{color: status_color}} /></Tooltip>)
    }

    const columnsDesktop = [
        { field: 'logo',
          title: 'Logo',
          render: (item) => <Avatar
                              alt={`${get(item, ['company', 'name'])}`}
                              src={get(item, ['company', 'logo_image', 'original_download_url'])}
                              style={{width: 100, cursor: 'pointer'}}
                              variant="square"
                              onClick={() => onEditBeneficiary(get(item, 'id'))}
                            >
                              {get(item, ['company', 'name']).split(/\s/).reduce((response,word)=> response+=word.slice(0,1),'')}
                            </Avatar>,
          visible: false
        },
        { field: 'id',
          title: 'ID',
          render: (item) => <ShortId value={item.id} />,
          visible: false
        },
        { field: 'name',
          title: 'Beneficiary',
          render: (item) => <a onClick={() => onEditBeneficiary(item.id)}>{get(item, ["company", "name"])}</a>,
          sort: (direction) => onUpdateListOrdering(direction === 'asc' ? 'company__name' : '-company__name'),
          visible: true

        },
        { field: 'parent',
          title: 'Parent Organisation',
          render: (item) => get(item, ["company", "parent_name"]),
          sort: (direction) => onUpdateListOrdering(direction === 'asc' ? 'company__parent__name' : '-company__parent__name'),
        },
        { field: 'company.city_name',
          title: 'City',
          sort:(direction) => onUpdateListOrdering(direction === 'asc' ? 'company__addresses__city__name' : '-company__addresses__city__name'),
          visible: true
        },
        { field: 'total_parcels_received_count',
          title: 'Total Parcels Received',
          align: 'center',
          render: (item) => get(item, ["company", "total_parcels_received_count"]) ? get(item, ["company", "total_parcels_received_count"]) : "---",
          sort:(direction) => onUpdateListOrdering(direction === 'asc' ? 'company__total_parcels_received_count' : '-company__total_parcels_received_count'),
          visible: true
        },
        { field: 'total_parcels_received_weight_kg',
          title: 'Total Weight (kg)',
          align: 'right',
          render: (item) => get(item, ["company", "total_parcels_received_weight_kg"]) ? `${round(get(item, ["company", "total_parcels_received_weight_kg"]) , 2).toLocaleString()} kg` : "---",
          sort:(direction) => onUpdateListOrdering(direction === 'asc' ? 'company__total_parcels_received_weight_kg' : '-company__total_parcels_received_weight_kg'),
          visible: true
        },
        { field: 'total_parcels_received_meals',
          title: 'Total Meals',
          align: 'right',
          render: (item) => get(item, ["company", "total_parcels_received_meals"]) ? round(get(item, ["company", "total_parcels_received_meals"]), 0).toLocaleString() : "---",
          sort: (direction) => onUpdateListOrdering(direction === 'asc' ? 'company__total_parcels_received_meals' : '-company__total_parcels_received_meals'),
          visible: true
        },
        { field: 'total_parcels_received_value_rands',
          title: 'Total Parcels Value',
          align: 'right',
          render: (item) => get(item, ["company", "total_parcels_value_rands"])? `R ${round(get(item, ["company", "total_parcels_value_rands"]), 0).toLocaleString()}` : "---",
          sort: (direction) => onUpdateListOrdering(direction === 'asc' ? 'company__total_parcels_value_rands' : '-company__total_parcels_value_rands')
        },
        { field: 'last_parcel_received_date',
          title: 'Last Parcel Received Date',
          align: 'center',
          render: (item) => get(item, ["company", "last_parcel_received_date"])? <Timestamp value={get(item, ["company", "last_parcel_received_date"])} format='datetime' /> : "---",
          sort:(direction) => onUpdateListOrdering(direction === 'asc' ? 'company__last_parcel_received_date' : '-company__last_parcel_received_date')
        },
        { field: 'last_parcel_received_weight_kg',
          title: 'Last Parcel Received Weight',
          align: 'right',
          render: (item) => get(item, ["company", "last_parcel_received_weight_kg"]) ? `${round(get(item, ["company", "last_parcel_received_weight_kg"]), 2).toLocaleString()} kg`: "---",
          sort:(direction) => onUpdateListOrdering(direction === 'asc' ? 'company__last_parcel_received_weight_kg' : '-company__last_parcel_received_weight_kg')
        },
        { field: 'last_parcel_received_meals',
          title: 'Last Parcel Received Meals',
          align: 'right',
          render: (item) => get(item, ["company", "last_parcel_received_meals"])? round(get(item, ["company", "last_parcel_received_meals"]), 0).toLocaleString() : "---",
          sort: (direction) => onUpdateListOrdering(direction === 'asc' ? 'company__last_parcel_received_meals' : '-company__last_parcel_received_meals')
        },
        { field: 'last_parcel_received_value_rands',
          title: 'Last Parcel Received Value',
          align: 'right',
          render: (item) => get(item, ["company", "last_parcel_received_value_rands"])? `R ${round(get(item, ["company", "last_parcel_received_value_rands"]), 0).toLocaleString()}` : "---",
          sort: (direction) => onUpdateListOrdering(direction === 'asc' ? 'company__last_parcel_received_value_rands' : '-company__last_parcel_received_value_rands')
        },
        { field: 'reported_number_of_people_served',
          title: 'People Served',
          align: 'right',
          sort: (direction) => onUpdateListOrdering(direction === 'asc' ? 'reported_number_of_people_served' : '-reported_number_of_people_served'),
          visible: true
        },
        { field: 'content_status',
          title: 'Microsite',
          render: (item) => getContentStatus(item, 'beneficiary'),
          width: 180,
          //sort:(direction) =>  onUpdateListOrdering(direction === 'asc' ? 'content_status' : '-content_status'),
        },
        { field: 'status',
          title: 'Status',
          width: 50,
          render: (item) => renderBeneficiaryStatus(item.status),
          sort: (direction) => onUpdateListOrdering(direction === 'asc' ? 'status' : '-status')
        },
        { field: 'created_at',
          title: 'Created at',
          render: (item) => <Timestamp value={item.created_at} format='datetime' />
        },
    ]

    return (
        <AdminCommonListLayout
           active_key="beneficiaries"
           breadcrumbs={[
             {name: 'home'},
             {name: 'beneficiaries', label: "Beneficiaries", url: '/beneficiaries'}
           ]}
           title={"Beneficiaries"}
           add_label="New Beneficiary"
           onAddRow={onAddBeneficiary}
           onEditRow={onEditBeneficiary}
           is_loading={is_loading}
           columns={columnsDesktop}
           enableAnyFieldFilter={true}
           renderAdditionalActions={renderActions}
           item_list={adminBeneficiaryList}
           useColumnsSelector={true}
           storeTableName={"adminBeneficiaryList"}
        />
    )
}
