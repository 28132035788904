import {
    mdiWarehouse,
    mdiTruckCheckOutline,
    mdiTruckFastOutline,
    mdiTruckDeliveryOutline
} from '@mdi/js'

export const DIRECTION_OPTIONS = [
    {
        label: 'Beneficiary Dropoffs',
        direction: 'beneficiary_dropoff',
        transport_by_warehouse_company: true,
        icon: mdiTruckFastOutline
    },
    {
        label: 'Beneficiary Collections',
        direction: 'beneficiary_dropoff',
        transport_by_warehouse_company: false,
        icon: mdiWarehouse
    },
    {
        label: 'Donor Pickups',
        direction: 'donor_pickup',
        transport_by_warehouse_company: true,
        icon: mdiTruckDeliveryOutline
    },
    {
        label: 'Donor Deliveries',
        direction: 'donor_pickup',
        transport_by_warehouse_company: false,
        icon: mdiTruckCheckOutline
    }
]
