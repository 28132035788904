import React, { useEffect, useState } from "react"
import { Card } from "@material-ui/core"

export const BeneficiaryDashboardWidgets = ({ data }) => {

  return (
    <Card></Card>
  )

}
