import * as Yup from 'yup'
import { FormikInputField } from 'components/form/InputField'
import { FormikFileSetField } from 'components/form/FileSetField'
import { FormikManufacturerAutoCompleteDropdown } from 'admin/components/product_manufacturers/form/ManufacturerAutoCompleteDropdown'
import { Grid } from '@material-ui/core'
import { Card } from 'components/layout/Card'

export const validationSchema = Yup.object().shape({
  'name': Yup.string().required("Required"),
})

export const AdminBrandForm = ({ in_drawer, formik_props }) => {

  return (
    <Grid container spacing={2}>

      <Grid item xs={12} md={in_drawer ? 12 : 9}>
        <Card
          title="Brand Details"
          content={
            <>
            <FormikInputField
              name="name"
              label="Name"
              formik_props={formik_props}
            />
            <FormikManufacturerAutoCompleteDropdown
              name="manufacturer"
              label="Manufacturer"
              formik_props={formik_props}
            />
            <FormikInputField
              name="url"
              label="URL"
              formik_props={formik_props}
            />
            </>
          }
        />
      </Grid>

      <Grid item xs={12} md={in_drawer ? 12 : 3}>
        <Card
          title="Brand Image"
          content={
            <FormikFileSetField
              max_num_files={1}
              name="logos"
              formik_props={formik_props}
            />
          }
        />
      </Grid>

    </Grid>
  )
}
