import { ItemList } from 'orm'
import { map } from 'lodash'

class UserNotificationSubscriptionList extends ItemList {
    getEntityKey() {
        return "admin/user_notification_subscription"
    }

}

export const adminUserNotificationSubscriptionList = new UserNotificationSubscriptionList("admin_user_notification_subscription__default")