import React, { useState, useEffect, useRef } from 'react'
import { Link, useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { get, size, capitalize, map } from 'lodash'
import MarketingMainLayout from './layout/MarketingMainLayout'
import useResize from './layout/useResize'
import NumberFormat from 'react-number-format'
import {
  makeStyles, Paper, Grid, TextField, MenuItem, Button, Typography,
  CardContent, IconButton, CardActionArea, Tooltip, List, ListItem,
  Table, TableBody, TableCell, TableContainer, TableHead, TableRow
} from '@material-ui/core'
import MuiCard from '@material-ui/core/Card'
import { Card } from 'components/layout/Card'
import { BasicMapWithMarker } from 'components/map/MapMarkers'
import { contacts } from 'sampleData/sampleData'
import MasonryGallery from 'components/layout/MasonryGallery'
import ResponsiveVideoIframe from './layout/ResponsiveVideoIframe'
import CompanyContacts from './CompanyContacts'
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline'
import VerifiedUserIcon from '@material-ui/icons/VerifiedUser'
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos'
import Breadcrumbs from 'components/layout/Breadcrumbs'
import { SizeMe } from 'react-sizeme'
import { marketingCompanyList } from 'portals/marketing/actions/marketing_companies'
import { GenericCompanyDesciption } from './generic/GenericCompanyData'
import { marketingCoreBusinessList } from 'actions/marketing_core_business'
import { Timestamp } from 'components/Timestamp'
import { CommonTable } from 'components/CommonTable'

const useStyles = makeStyles((theme) => ({
  root: {
    minWidth: 1170,
    maxWidth: 1170,
    [theme.breakpoints.down('sm')]: {
      minWidth: '100%',
      maxWidth: '100%'
    }
  },
  bannerImage: {
    height: 600,
    width: '100%',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    position: 'relative',
    [theme.breakpoints.down('sm')]: {
      height: 400
    }
  },
  title: {
    backgroundColor: 'rgba(0, 0, 0, .5)',
    padding: 20,
    color: '#ffffff',
    position: 'absolute',
    bottom: 0,
    width: '100%'
  },
  content: {
    padding: 15,
    [theme.breakpoints.down('sm')]: {
      padding: 5,
      paddingTop: 15,
      paddingBottom: 15
    }
  },
  registeredNpoCheck: {
    fontSize: 48,
    color: '#43a047',
    marginBottom: -15
  },
  breadcrumbs: {
    marginTop: -15
  },
  logoCard: {
    textAlign: 'center',
  },
  logoImg: {
    maxHeight: 200,
    maxWidth: 300,
    padding: 15,
    marginBottom: -5
  },
  parcelBlock: {
    fontSize: 12,
    borderBottom: '1px solid #cccccc',
    paddingBottom: 5,
    paddingTop: 5,
    marginBottom: 5
  }
}))

export default function Company(props) {
  const companyType = props.companyType
  let companyTypePlural = 'beneficiaries'
  let { company_id } = useParams()
  let city = "South Africa"
  const dispatch = useDispatch()
  const is_loading = useSelector(() => !marketingCompanyList.isReady() && marketingCompanyList.isLoading())
  const company = useSelector(() => marketingCompanyList.getObject(company_id))
  const coreBusinessOptions = useSelector(() => marketingCoreBusinessList.getVisibleObjects())

  useEffect(() => {
      async function fetchStaticObjects() {
          dispatch(marketingCompanyList.ensureObjectLoaded(company_id))
      }
      fetchStaticObjects()
  }, [company_id])

  const videoRef = useRef()

  window.addEventListener('resize', resizeVideo)

  const resizeVideo = () => {
    setDimensions({
      width: videoRef.current.offsetWidth,
      height: videoRef.current.offsetHeight
    })
  }

  const companyName = get(company, ["name"])
  const contacts = get(company, ["contacts"])
  const sahVideo = "https://www.youtube.com/embed/stzZXDSMS-c"
  let bannerImage = get(company, ["banner_image", "original_download_url"]) ? get(company, ["banner_image", "original_download_url"]) : "/static/images/companies/beneficiary/default/banner-placeholder.jpg"
  let videoUrl = get(company, ["video_url"]) ? get(company, ["video_url"])  : sahVideo
  let donationAction = 'received'
  let donationDirection = 'from'
  let totalMeals = get (company, ["total_meals_received"])
  const parcels_received = get(company, ["recent_received_parcels"]) ? get(company, ["recent_received_parcels"]) : false
  const parcels_donated = get(company, ["recent_donated_parcels"]) ? get(company, ["recent_donated_parcels"]) : false
  const parcels = companyType == 'beneficiary' ? parcels_received : parcels_donated
  let companyLogoFallback = "http://localhost:3006/static/images/companies/beneficiary/default/logo-placeholder.png"

  if(companyType == 'donor') {
    totalMeals = get (company, ["total_meals_donated"])
    donationAction = 'donated'
    donationDirection = 'to'
    companyTypePlural = 'donors'
    companyLogoFallback = "http://localhost:3006/static/images/companies/donor/default/logo-placeholder.png"
    bannerImage = get(company, ["banner_image", "original_download_url"]) ? get(company, ["banner_image", "original_download_url"]): "/static/images/companies/donor/default/banner-placeholder.jpg"
  }

  if(companyType == 'logistics') {
    const totalMeals = get(company, ["total_meals_received"])
    donationAction = 'delivered'
    donationDirection = 'for'
    companyTypePlural = companyType
    companyLogoFallback = "http://localhost:3006/static/images/companies/logistics/default/logo-placeholder.png"
    bannerImage = get(company, ["banner_url"]) ? get(company, [".banner_url"]) : "/static/images/companies/logistics/default/banner-placeholder.jpg"
  }

  let logoUrl = get(company, ["logo_image"]) ? get(company, ["logo_image", 'original_download_url']) : companyLogoFallback
  let defaultGalleryImages = []
  for (var i = 0; i <= 13; i++) {
    defaultGalleryImages.push({original_download_url: `/static/images/companies/beneficiary/default/gallery/saharvest-beneficiary-gallery-image-${i}.jpg`, alt: 'SA Harvest beneficiary gallery image ' + i, title: 'SA Harvest beneficiary gallery image ' + i})
  }
  let defaultBanners = []
  var defaultBanner = defaultGalleryImages[Math.floor(Math.random() * defaultGalleryImages.length)]

  let companyImages = get(company, ["images", "files"])
  if(companyImages == [] || size(companyImages) < 1) {
    companyImages = defaultGalleryImages
  }

  const classes = useStyles()

  const showDonations = () => {
    // stuff goes here
  }

  const columns = [
    { field: 'arrive_at',
      title: `${capitalize(donationAction)} Date`,
      width: 150,
      render: (item) => <Timestamp value={get(item, ["arrive_at"])} format={"date"} />,
      sort: (direction) => onUpdateListOrdering(direction === 'asc' ? 'arrive_at' : '-arrive_at'),
    },
    { field: 'to_company_name',
      title: `${capitalize(donationAction)} by`,
      render: (item) => get(item, ["to_company_name"]),
      sort: (direction) => onUpdateListOrdering(direction === 'asc' ? 'to_company_name' : '-to_company_name'),
    },
    { field: 'from_company_name',
      title: `Delivered by`,
      render: (item) => get(item, ["from_company_name"]),
      sort: (direction) => onUpdateListOrdering(direction === 'asc' ? 'from_company_name' : '-from_company_name'),
    },
    { field: 'total_weight_kg',
      title: `Weight`,
      width: 100,
      render: (item) => <NumberFormat value={get(item, ["total_weight_kg"])} thousandSeparator={true} displayType={'text'} decimalScale={2} suffix={' kg'} />,
      sort: (direction) => onUpdateListOrdering(direction === 'asc' ? 'total_weight_kg' : '-total_weight_kg'),
    },
  ]

  const onUpdateListOrdering = (field) => {
      dispatch(marketingCompanyList.updateListOrdering(field))
  }

  const renderParcelsTable = (parcels) => {
    return(
      <>
      <CommonTable
        is_loading={ is_loading }
        rows={ parcels }
        columns={ columns }
        item_list={ marketingCompanyList }
        variant={"outlined"}
        showActions={false}
        square={true}
        topPagination={false}
        bottomPagination={false}
      />
    {false && <Button variant="contained" size="small" onClick={showDonations}>View All Donations</Button> }
    </>
    )
  }

  return(
    <MarketingMainLayout>
      <div className={classes.bannerImage} style={{backgroundImage: 'url(' + bannerImage + ')'}}>
        <div className={classes.title}><Typography variant="h4">{company.name}</Typography></div>
      </div>

      <Grid container justify="center">
        <Paper className={classes.root} variant="outlined" square>

          <div className={classes.content}>
          <div className={classes.breadcrumbs}>
            <Breadcrumbs
                crumbs={[
                    {name: 'marketing_home'},
                    {name: 'companyType', label: capitalize(companyTypePlural), url: '/impact/' + companyTypePlural},
                    {name: company.slug, label: company.name, url: '/impact/' + companyTypePlural + '/' + company.slug}
                ]}
            />
          </div>
          <Grid container spacing={1}>

            <Grid item xs={12} md={4} className={classes.content}>

              {logoUrl &&
              <MuiCard variant="outlined" square>
                <CardActionArea className={classes.logoCard}>
                {company.website
                  ? <Tooltip title={"Click to visit the " + company.name + " website"} arrow>
                      <a target="_blank" href={get(company, ["website"])}>
                        <img src={logoUrl} alt={get(company, ["name"])} title={get(company, ["name"])} className={classes.logoImg} />
                      </a>
                    </Tooltip>
                  : <img src={logoUrl} alt={get(company, ["name"])} title={get(company, ["name"])} className={classes.logoImg} />
                }
                </CardActionArea>
              </MuiCard>
              }

              {companyType == 'beneficiary' &&
              <Card
                title={"NPO Vetting Status"}
                content={
                          <div>

                            {get(company, ["registered_npo"]) && <p><VerifiedUserIcon className={classes.registeredNpoCheck} /> Registered NPO (# {get(company, ["registered_npo"])})</p>}
                            {get(company, ["status"]) === 'active' && <p><VerifiedUserIcon className={classes.registeredNpoCheck} /> Active beneficiary</p>}
                            {get(company, ["vetting_status"]) === 'complete' && ''}
                            <p><VerifiedUserIcon className={classes.registeredNpoCheck} /> SA Harvest Vetted</p>
                          </div>
                        }
                />
              }

              {get(company, ["core_business"]) &&
                <Card
                  title={"Core Business"}
                  content={
                            <div>
                              <List>
                                {map(get(company, ["core_business"]), cb => (
                                  <ListItem>{cb.name}</ListItem>
                                ))}
                              </List>
                            </div>
                          }
                />
              }

              {get(company, ["num_recipients"]) &&
                <Card
                  title={"Supports"}
                  content={
                            <p align="center">
                              <Typography variant="h1" style={{color: '#3949ab'}}>{get(company, ["num_recipients"])}</Typography>
                              <Typography variant="p">people on average</Typography>
                            </p>
                          }
                />
              }

              {totalMeals > 0  &&
                <Card
                  title={`Total Meals ${capitalize(donationAction)}`}
                  subtitle={`(${donationDirection} SA Harvest)`}
                  content={
                            <p align="center">
                              <Typography variant="h1" style={{color: '#d81b60'}}><NumberFormat value={totalMeals} thousandSeparator={true} displayType={'text'} decimalScale={0} /></Typography>
                            </p>
                          }
                />
              }

              {companyType == 'logistics' &&
                <Card
                  title={`Total Distance Driven`}
                  content={
                            <p align="center">
                              <Typography variant="h2" style={{color: '#3949ab'}}>
                              </Typography>
                            </p>
                          }
                  />
              }

              {videoUrl &&
                <SizeMe>
                  {({ size }) =>
                    <Grid item xs={12}>
                      <ResponsiveVideoIframe url={videoUrl} width={size.width-8} height={size.width/1.5} />
                    </Grid>
                  }
                </SizeMe>
                }

            </Grid>

            <Grid item xs={12} md={8}>
              <Grid container spacing={1}>
                <Grid item xs={12}>
                  <Card
                    title={"Description"}
                    content={
                              <>
                              {get(company, ["description"]) && (get(company, ["description"]) != 'Imported') ?
                                <div dangerouslySetInnerHTML={{__html: get(company, ["description"])}} /> :
                                <GenericCompanyDesciption company={company} />
                              }
                              </>
                            }
                  />
                </Grid>

                {parcels &&
                  <Grid item xs={12}>
                    <Card
                      title={`Recently ${donationAction}`}
                      noPadding={true}
                      content={renderParcelsTable(parcels)}
                    />
                  </Grid>
                }

              </Grid>
            </Grid>

          </Grid>
          </div>

        </Paper>
      </Grid>

      {company.lat && company.lng &&
            <BasicMapWithMarker lat={company.lat} lng={company.lng} width={'100%'} height={450} zoom={6} showDefaultUI />
      }

      {companyImages &&
        <MasonryGallery images={companyImages} />
      }

    </MarketingMainLayout>
  )
}
