import React, { Component, useState } from 'react'
import { connect } from 'react-redux'
import DropzoneComponent from 'react-dropzone-component'
import ReactDOMServer from 'react-dom/server'
import { Link } from 'react-router-dom'
import { head, size, isEmpty, includes, split } from 'lodash'
import { useField } from 'formik'
import FormError from './FormError'
import Loading from '../Loading'
import FormWarning from './FormWarning'
import { authToken } from '../../actions/auth'
import { showSuccess, showError } from '../../actions/Error'
import 'react-dropzone-component/styles/filepicker.css'
import 'dropzone/dist/min/dropzone.min.css'
import loading_icon from 'assets/images/loading.gif'
import { DropzoneArea } from 'material-ui-dropzone'
import * as Sentry from '@sentry/browser'
import { fileInfoList } from 'actions/file_info'

export const MuiFileUploader = ({
    onFilesUploaded,
    maxNumFiles,
    acceptedFiles,
    dropzoneText,
    maxFileSize,
    showPreviews,
    showPreviewsInDropzone,
    useChipsForPreview,
    previewGridProps,
    previewChipProps,
    Icon,
    formik_props,
    ...props
}) => {

    const [field, meta] = useField(props);
    const { value } = field
    const { setFieldValue } = formik_props
    const { name } = field

    const onSelectedFiles = (files) => {
        fileInfoList.uploadFiles(files).then(({ successes, failures }) => {
            if (size(failures) > 0) {
                showError("Server error, at least one file failed to upload")
            }
            if (maxNumFiles === 1) {
                setFieldValue(name, head(successes))
            } else {
                setFieldValue(name, successes)
            }
            if (onFilesUploaded) {
                onFilesUploaded(successes)
            }
        })
    }

    const onFailure = (ex) => {
        showError("Error", "Failed to upload file", ex)
    }

    return (
        <>
            <DropzoneArea
                acceptedFiles={acceptedFiles || [".csv, text/csv, application/vnd.ms-excel, application/csv, text/x-csv, application/x-csv, text/comma-separated-values, text/x-comma-separated-values"]}
                maxFileSize={maxFileSize || 5000000}
                dropzoneText={dropzoneText}
                onChange={onSelectedFiles}
                showPreviews={showPreviews}
                showPreviewsInDropzone={showPreviewsInDropzone}
                useChipsForPreview={useChipsForPreview}
                previewGridProps={previewGridProps}
                previewChipProps={previewChipProps}
                Icon={Icon}
            />
            { formik_props.errors && <FormError error={ formik_props.errors[name] } /> }
        </>
    )



}
