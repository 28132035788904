import { useEffect, useState } from 'react'
import { get, map, mapValues, isEmpty, range } from 'lodash'
import { useDispatch, useSelector } from 'react-redux'
import { adminChecklistScheduleList } from '../actions/admin_checklist_schedule'
import { adminChecklistList } from '../actions/admin_checklist'
import { adminUserList } from 'admin/components/users/actions/admin_user'
import { FormikInputField } from 'components/form/InputField'
import { FormikGeneralFormErrors } from 'components/form/GeneralFormErrors'
import { Formik, Form } from 'formik'
import FormCard from 'components/layout/FormCard'
import * as Yup from 'yup'
import { Typography, Divider, Grid } from "@material-ui/core"
import { Alert } from '@material-ui/lab'
import { showSuccess, showError } from 'actions/Error'
import { handleSubmitResult } from 'actions/form'
import { Frequency, FrequencyValues } from 'components/form/Frequency'
import { FormikDropdownField } from 'components/form/Dropdown'

const AdminChecklistScheduleForm = ({ checklist_schedule_id, onSaved, disabled }) => {
    const dispatch = useDispatch()
    const checklist_schedule = useSelector(() => adminChecklistScheduleList.getObject(checklist_schedule_id))
    const checklist_options = useSelector(() => adminChecklistList.getAsOptions())
    const user_options = useSelector(() => adminUserList.getAsOptions())
    const initial_values = checklist_schedule
    const [momentType, setMomentType] = useState(null)

    useEffect(() => {
        dispatch(adminChecklistScheduleList.ensureObjectLoaded(checklist_schedule_id))
        dispatch(adminChecklistList.fetchListIfNeeded())
        dispatch(adminUserList.fetchListIfNeeded())
    },[])

    const onSave = (values, formik_funcs) => {
        const on_ok = function(json) {
            showSuccess("Saved", "Checklist Schedule Saved")
            onSaved()
        }
        if ( checklist_schedule_id ) {
            values.id = get(checklist_schedule, "id")
            return dispatch(adminChecklistScheduleList.saveObject(values)).then((res) => handleSubmitResult({res, formik_funcs, on_ok}))
        }
        else {
            return dispatch(adminChecklistScheduleList.saveNewObject(values)).then((res) => handleSubmitResult({res, formik_funcs, on_ok}))
        }
    }

    return(
        <Formik
            initialValues={initial_values}
            onSubmit={onSave}
            enableReinitialize={true}
            >
            { formik_props => {
                const { values } = formik_props

                const onChanged = (moment_type) => {
                    setMomentType(moment_type)
                }

                return (
                    <Form>
                        <FormCard isSubmitting={formik_props.isSubmitting}>
                            <Grid container spacing={2}>
                                <Grid item xs={12} md={6}>
                                    <Typography variant="h6">Assign a checklist to a user</Typography>
                                    <Grid container spacing={2}>
                                        <Grid item xs={12} md={6}>
                                            <FormikDropdownField
                                                name="checklist"
                                                label="Checklist"
                                                options={checklist_options}
                                                formik_props={formik_props}
                                                disabled={disabled}
                                            />
                                        </Grid>
                                        <Grid item xs={12} md={6}>
                                            <FormikDropdownField
                                                name="user"
                                                label="User"
                                                options={user_options}
                                                formik_props={formik_props}
                                                disabled={disabled}
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <Typography variant="h6">When should this checklist be completed?</Typography>
                                    <Grid container spacing={2}>
                                        <Grid item xs={12} md={6}>
                                            <Frequency
                                                formik_props={formik_props}
                                                name={"complete_checklist_on.moment_type"}
                                                onChanged={onChanged}
                                            />
                                        </Grid>
                                        <Grid item xs={12} md={6}>
                                            <FrequencyValues
                                                formik_props={formik_props}
                                                name={"complete_checklist_on.value"}
                                                label="Completion Day"
                                                moment_type={momentType}
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>

                            </Grid>
                        </FormCard>
                    </Form>
                )
            }}
        </Formik>
    )
}

export default AdminChecklistScheduleForm
