import React, { useEffect, useState, useRef } from 'react'
import { get, cloneDeep } from 'lodash'
import { useDispatch, useSelector } from 'react-redux'
import { handleSubmitResult } from 'actions/form'
import { Button, Switch, FormControlLabel, Grid } from '@material-ui/core'
import { FormikGeneralFormErrors } from 'components/form/GeneralFormErrors'
import {showSuccess, showError} from 'actions/Error'
import { Formik, Form, FieldArray, Field } from 'formik'
import { FormikDropdownField } from 'components/form/Dropdown'
import { FormikInputField } from 'components/form/InputField'
import { FormikTextareaField } from 'components/form/TextareaField'
import { FormikCheckboxField } from 'components/form/CheckboxField'
import { CountrySelectField } from 'components/form/CountrySelectField'
import { ProvinceSelectField } from 'components/form/ProvinceSelectField'
import { CitySelectField } from 'components/form/CitySelectField'
import { adminAddressTypes } from 'admin/actions/admin_dropdown_options'
import { AddressAutoComplete } from 'components/map/AddressAutoComplete'
import { countryList } from 'actions/country'
import { provinceList } from 'actions/province'
import { cityList } from 'actions/city'
import { adminAddressList, invalidateAllAddressLists } from '../actions/admin_address'
import * as Yup from 'yup'
import { BasicMapWithMarker } from 'components/map/MapMarkers'
import { Separator } from 'components/layout/Separator'
import Alert from '@material-ui/lab/Alert'

export const validationSchema = Yup.object().shape({
    // street1: Yup.string().required("Required")
})

const AdminAddressForm = ({formik_props, newAddressInitialValues, address_id, classes, fullWidth, onDone, supports_single_address_only, is_warehouse, warehouse}) => {
    const dispatch = useDispatch()
    const [showDetailedFields, setShowDetailedFields] = useState(true)
    const [showAutoComplete, setShowAutoComplete] = useState(true)
    let address
    if(address_id) {
        address = adminAddressList.getObject(address_id)
    } else {
        address = cloneDeep(newAddressInitialValues)
    }
    let initialValues = address
    const supports_multiple_addresses = !is_warehouse
    const field_prefix = supports_multiple_addresses ? "" : 'address.'

    const toggleShowDetailedFields = () => {
      setShowDetailedFields(showDetailedFields => !showDetailedFields)
    }

    useEffect(() => {
        if ( address_id ) {
            dispatch(adminAddressList.ensureObjectLoaded(address_id))
        }
    }, [address_id])

    useEffect(() => {
        async function fetchStaticObjects() {
            dispatch(adminAddressTypes.fetchListIfNeeded())
            dispatch(countryList.fetchListIfNeeded())
            dispatch(provinceList.fetchListIfNeeded())
            dispatch(cityList.fetchListIfNeeded())
        }
        fetchStaticObjects()
    }, [])

    const address_type_options = adminAddressTypes.getAsOptions()

    const onSave = (values, formik_funcs) => {
        const on_ok = function(json) {
            showSuccess("Saved", "Address saved")
            dispatch(invalidateAllAddressLists())
            onDone()
        }
        if ( address_id ) {
            values.id = address_id
            return dispatch(adminAddressList.saveObject(values)).then((res) => handleSubmitResult({res, formik_funcs, on_ok}))
        } else {
            return dispatch(adminAddressList.saveNewObject(values)).then((res) => handleSubmitResult({res, formik_funcs, on_ok}))
        }
    }

    const renderMapAutoComplete = (formik_props, formatted_address) => {

        const current_formatted_address = get(formik_props, ["values", "formatted_address"], '')

        const onChanged = ({
            formatted_address,
            latitude,
            longitude,
            street1,
            street2,
            province_name,
            city_name,
            country_name,
            postal_code
        }) => {

            formik_props.setFieldValue(`${field_prefix}formatted_address`, formatted_address)
            formik_props.setFieldValue(`${field_prefix}street1`, street1)
            formik_props.setFieldValue(`${field_prefix}street2`, street2)
            formik_props.setFieldValue(`${field_prefix}country`, countryList.getOptionValueForLabel(country_name))
            formik_props.setFieldValue(`${field_prefix}city`, cityList.getOptionValueForLabel(city_name))
            formik_props.setFieldValue(`${field_prefix}province`, provinceList.getOptionValueForLabel(province_name))
            formik_props.setFieldValue(`${field_prefix}postal_code`, postal_code)
            formik_props.setFieldValue(`${field_prefix}latitude`, latitude)
            formik_props.setFieldValue(`${field_prefix}longitude`, longitude)

        }
        return (
            <AddressAutoComplete formatted_address={current_formatted_address} onChanged={onChanged}/>
        )
    }

    const renderForm = (formik_props, values, alert_severity, is_default_label) => {
        return(
            <>
                <FormikDropdownField
                    name={`${field_prefix}address_type`}
                    formik_props={formik_props}
                    options={address_type_options}
                    placeholder="Type"
                    helperText={"What kind of address is this?"}
                />

                <Alert severity={alert_severity} style={{marginTop: 10}}>
                    <FormikCheckboxField
                        name={`${field_prefix}is_default`}
                        label={`${is_default_label} default address for pickups and deliveries.`}
                        formik_props={formik_props}
                        color="primary"
                    />
                </Alert>

                { renderMapAutoComplete(formik_props) }

                <FormikInputField
                    name={`${field_prefix}formatted_address`}
                    placeholder={"Google formatted address"}
                    type="text"
                    formik_props={formik_props}
                />

                <FormControlLabel
                    control={
                        <Switch
                            name="show_details"
                            checked={showDetailedFields}
                            onChange={toggleShowDetailedFields}
                            color="primary"
                        />
                    }
                    label="Show detailed fields"
                />

                {showDetailedFields &&
                    <>
                        <FormikInputField
                           name={`${field_prefix}latitude`}
                           type="text"
                           placeholder="Latitude"
                           formik_props={formik_props}
                        />

                        <FormikInputField
                            name={`${field_prefix}longitude`}
                            type="text"
                            placeholder="Longitude"
                            formik_props={formik_props}
                        />

                        <FormikInputField
                            name={`${field_prefix}street1`}
                            type="text"
                            placeholder="Street Address"
                            formik_props={formik_props}
                        />

                        <FormikInputField
                            name={`${field_prefix}street2`}
                            type="text"
                            placeholder="Suburb"
                            formik_props={formik_props}
                        />

                        <CitySelectField
                            name={`${field_prefix}city`}
                            placeholder="City"
                            formik_props={formik_props}
                            country_id={get(values, ["country"], null)}
                        />

                       <ProvinceSelectField
                           name={`${field_prefix}province`}
                           placeholder="Province/Region"
                           formik_props={formik_props}
                           country_id={get(values, ["country"], null)}
                       />

                       <CountrySelectField
                           name={`${field_prefix}country`}
                           placeholder="Country"
                           formik_props={formik_props}
                       />

                       <FormikInputField
                           name={`${field_prefix}postal_code`}
                           type="text"
                           placeholder="Postal code"
                           formik_props={formik_props}
                       />

                       <FormikTextareaField
                           name={`${field_prefix}notes`}
                           placeholder="Notes"
                           formik_props={formik_props}
                           rows={5}
                       />
                   </>
                }
            </>
        )
    }

    if(formik_props) {
        const { values } = formik_props
        const alert_severity = values.is_default ? "success" : "warning"
        const is_default_label = values.is_default ? "This is the" : "Check to make"
        return renderForm(formik_props, values, alert_severity, is_default_label)
    }

    else {

      return (

        <Formik initialValues={initialValues}
                onSubmit={onSave}
                enableReinitialize={true}
        >
          {formik_props => {
              const { values } = formik_props
              const alert_severity = get(values,"is_default") ? "success" : "warning"
              const is_default_label = get(values,"is_default") ? "This is the" : "Check to make"
              return (
                  <Form>
                    <FormikGeneralFormErrors render={(msg) => msg} />

                      { renderForm(formik_props, values, alert_severity, is_default_label) }

                      <Button variant="contained" type="submit" size="large" color="primary" style={{margin: "10px 0"}}>
                        Save Address
                      </Button>

                  </Form>
              )
          }}
        </Formik>

      )

    }

}

export default AdminAddressForm
