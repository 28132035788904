import { ItemList } from 'orm'
import { head } from 'lodash'
import { EnhancedFilter } from 'admin/actions/enhanced_filter'

class DemographicTotalsMatrixList extends ItemList {
    getEntityKey() {
        return 'admin/demographic/totals_matrix'
    }

    loadData() {
        return this.fetchListIfNeeded()
    }

    getData() {
        return head(this.getVisibleObjects())
    }

    getEnhancedFilter() {
        if ( ! this.enhanced_filter ) {
            this.enhanced_filter = new EnhancedFilter(this)
        }
        return this.enhanced_filter
    }

}

export const adminDemographicTotalsMatrixList = new DemographicTotalsMatrixList("admin_demographic_matrix__default")