import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { adminChecklistList } from './actions/admin_checklist'
import { get } from 'lodash'
import Loading from 'components/Loading'

export const ChecklistName = ({ checklist_id }) => {
    const dispatch = useDispatch()
    const checklist = useSelector(() => adminChecklistList.getObject(checklist_id))
    const is_loading = useSelector(() => adminChecklistList.isLoading())

    useEffect(() => {
        dispatch(adminChecklistList.ensureObjectLoaded(checklist_id))
    },[])

    return is_loading ? <Loading size={20} /> : get(checklist, "name", "--")
}
