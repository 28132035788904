import React, {useState} from 'react'
import { Link } from 'react-router-dom'
import { DonorRegisterButton, BeneficiaryRegisterButton, LogisticsRegisterButton } from '../../register/RegisterButtons'
import { makeStyles, Popover,MenuList, MenuItem, Button } from '@material-ui/core'

const useStyles = makeStyles({
  registerButtons: {
    marginLeft: 'auto',
    marginTop: 15
  },
  headerButton: {
    borderRadius: 32,
    marginTop: 1, 
    padding: '5px 25px',
  },
  donateButton: {
    background: 'black',
    color: '#fadf00',
    marginRight: 12,
    border: "1px solid black",
    '&:hover': {
      color: 'white',
      border: "1px solid white"
    }
  },
  loginButton: {
    '&:hover': {
      color: '#000000',
      borderColor: "#000000"
    }
  }
})

export default function RegisterButtons({register=true}) {
  const classes = useStyles()
  const [anchorEl, setAnchorEl] = useState(false)

  const handleRegisterButtonClick = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleRegisterPopoverClose = () => {
    setAnchorEl(null);
  }

  const openRegisterPopover = Boolean(anchorEl);
  const idRegisterPopover = openRegisterPopover ? 'register-popover' : undefined

  const redirectDonatePage = () => {
    return window.location = "https://saharvest.org/donate"
    //return window.open('https://saharvest.org/donate', '_blank')
  }

  return(
    <div className={classes.registerButtons}>
      {register &&
        <><Button onClick={handleRegisterButtonClick} aria-describedby={idRegisterPopover}>Register</Button> | </>
      }
      <Button component={Link}  to={"/donate"} className={[classes.headerButton, classes.donateButton]}>Donate</Button>
      <Button component={Link} to={"/login"} variant="outlined" className={[classes.headerButton, classes.loginButton]}>Login</Button>
      <Popover
        id={idRegisterPopover}
        open={openRegisterPopover}
        anchorEl={anchorEl}
        onClose={handleRegisterPopoverClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <MenuList>
          <MenuItem><BeneficiaryRegisterButton size="small" /></MenuItem>
          <MenuItem><DonorRegisterButton size="small" /></MenuItem>
          <MenuItem><LogisticsRegisterButton size="small" /></MenuItem>
        </MenuList>
      </Popover>
  </div>
  )
}
