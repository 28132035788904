import React from 'react'
import { get } from 'lodash'
import TotalsWidget from './TotalsWidget'
import { unpackCityData } from './actions/admin_dashboard'

export default function TotalsWidgetWeightDelivered({data}) {

    if ( ! data ) {
        return null
    }

    const actions = [
      { label: 'View Orders', href: '/admin/parcels'},
      { label: 'View Reports', href: '/admin/reports/beneficiary_parcels' }
    ]

    const rows = unpackCityData(get(data, "totals_by_city"))
    const total_weight = get(data, "total")

    return (
        <TotalsWidget title="Weight"
                      grand_total={total_weight}
                      units="kg"
                      detail_rows={rows}
                      actions={actions}
                      color="#3f51b5"
        />
    )
}
