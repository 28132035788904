import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { Modal } from 'components/layout/Modal'
import { get } from 'lodash'
import { Separator } from 'components/layout/Separator'
import { Formik, Form } from 'formik'
import FormCard from 'components/layout/FormCard'
import { FormikBeneficiaryAddressDropdownWithFormBasedAddNew } from 'admin/components/beneficiaries/form/BeneficiaryAddressDropdown'
import {showSuccess, showError} from 'actions/Error'
import { handleSubmitResult } from 'actions/form'
import { adminParcelList } from 'admin/components/orders/actions/admin_parcel'
import { FormikDateTimePicker } from 'components/form/DatetimePicker'
import { FormikCheckboxField } from 'components/form/CheckboxField'
import { FormikBeneficiaryAutoCompleteDropdown } from 'admin/components/beneficiaries/form/BeneficiaryAutoCompleteDropdown'
import { Grid, Card, CardContent, Typography, Divider, Button, makeStyles } from '@material-ui/core'
import Container from '@material-ui/core/Container'
import { adminBeneficiaryList } from 'admin/components/beneficiaries/actions/admin_beneficiary'
import * as Yup from 'yup'

const validationSchema = Yup.object().shape({
    to_company: Yup.string().required("Required"),
    // arrive_address: Yup.string().required("Required"),  // Removed because this field isn't shown if transport_by_company isn't ticked
    arrive_at: Yup.string().required("Required")
})

const useStyles = makeStyles((theme) =>({
  button: {
    marginBottom: -10,
    marginTop: 20,
  },
  fullWidthButton: {
    width: '100%',
  },
  fullWidthButtonDivLeft: {
    paddingRight: 5,
    [theme.breakpoints.down('sm')]: {
      paddingRight: 0,
    },
  },
  fullWidthButtonDivRight: {
    paddingLeft: 5,
    [theme.breakpoints.down('sm')]: {
      paddingLeft: 0,
    },
  }
}))

export const AdminBeneficiaryParcelCopy = ({original_parcel_id, onClose}) => {
    const classes = useStyles()
    const dispatch = useDispatch()
    const history = useHistory()
    const original_parcel = adminParcelList.getObject(original_parcel_id)
    const [initialValues, setInitialValues] = useState(null)

    if ( initialValues === null ) {
        setInitialValues({'arrive_at': get(original_parcel, "arrive_at")})
    }

    const [postSaveAction, setPostSaveAction] = useState(null)
    const [selectedBeneficiary, setSelectedBeneficiary] = useState(null)

    const address_ids = selectedBeneficiary && get(selectedBeneficiary, ["company", "addresses"])

    useEffect(() => {
        async function fetchStaticObjects() {
            dispatch(adminParcelList.ensureObjectLoaded(original_parcel_id))
        }
        fetchStaticObjects()
    }, [original_parcel_id])

    const onSave = (values, formik_funcs) => {
        const on_ok = function(json) {
            dispatch(adminParcelList.invalidateList())
            showSuccess("Copied", "Order copy created")

            if ( postSaveAction === "view_cloned_order" ) {
                viewClonedOrder(json)
            } else if ( postSaveAction === "clone_another" ) {
                setPostSaveAction(null)
                setSelectedBeneficiary(null)
                setInitialValues(null)
                formik_funcs.setFieldValue("to_company", null)
                formik_funcs.setFieldValue("arrive_address", null)
            } else {
                onClose()
            }
        }
        return dispatch(adminParcelList.cloneParcel({original_parcel_id, values})).then((res) => handleSubmitResult({res, formik_funcs, on_ok}))
    }

    const viewClonedOrder = (cloned_order) => {
        history.push(`/admin/orders/beneficiary/${cloned_order.id}`)
        onClose()
    }

    const onSaveAndViewClonedOrder = (formik_props) => {
        setPostSaveAction("view_cloned_order")
        formik_props.submitForm()
    }

    const onSaveAndClonedAnotherOrder = (formik_props) => {
        setPostSaveAction("clone_another")
        formik_props.submitForm()
    }

    const onToCompanyChanged = (company_id) => {
        if ( company_id ) {
            const beneficiary = adminBeneficiaryList.getForCompany({company_id})
            setSelectedBeneficiary(beneficiary)
        }
    }

    const renderSaveButtons = (formik_props, classes) => {
        return (
            <Grid container justify="center">

              <Grid item xs={12}>
                <Separator height={7} />
                <Button variant="contained" color="primary" onClick={() => onSaveAndViewClonedOrder(formik_props)} fullWidth>
                  Save & view cloned order
                </Button>
                <Separator height={10} />
              </Grid>

              <Grid  item xs={12}>
                <Button variant="contained" onClick={() => onSaveAndClonedAnotherOrder(formik_props)} fullWidth>
                      Save & clone another
                </Button>
              </Grid>

              <Grid item xs={12}>
                <Separator height={7} />
                <Button onClick={onClose}>
                  Cancel
                </Button>
              </Grid>

            </Grid>

        )
    }

    const renderCopyForm = (formik_props, classes) => {
        const transport_by_warehouse_company = get(formik_props.values, "transport_by_warehouse_company")
        return (
            <FormCard
                isSubmitting={formik_props.isSubmitting}
                renderSaveButtons={() => renderSaveButtons(formik_props, classes)}
                elevation={0}
            >
              <FormikBeneficiaryAutoCompleteDropdown name="to_company"
                                                     label="Beneficiary"
                                                     onChange={onToCompanyChanged}
                                                     formik_props={formik_props} />

              { selectedBeneficiary &&
                <>
                <FormikCheckboxField name="transport_by_warehouse_company"
                       label="SA Harvest will transport?"
                       formik_props={formik_props}
                />
              { transport_by_warehouse_company &&
                <FormikBeneficiaryAddressDropdownWithFormBasedAddNew name="arrive_address"
                                                                     beneficiary={selectedBeneficiary}
                                                                     label="Dropoff Address"
                                                                     address_ids={address_ids}
                                                                     formik_props={formik_props} />
              }
              </>
             }
             <Separator height={12} />
              <FormikDateTimePicker name="arrive_at"
                                    label="Dropoff Date"
                                    formik_props={formik_props} />

                                  <Separator height={60} />

            </FormCard>
        )

    }

    return (


        <Modal onClose={onClose}
               title="Duplicate Beneficiary Order"
               noPadding
        >

            <Formik
              initialValues={initialValues}
              onSubmit={onSave}
              enableReinitialize={true}
              validationSchema={validationSchema}
            >
              {formik_props => {
                  const { values } = formik_props
                  return (
                      <Form>
                        { renderCopyForm(formik_props, classes)}
                      </Form>
                  )
              }}
            </Formik>
        </Modal>

    )

}
