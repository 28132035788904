import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useParams } from 'react-router-dom'
import { get } from 'lodash'
import { adminNutritionCardList } from './actions/admin_nutrition_card'
import { adminFoodItemList } from './actions/admin_food_item'
import {showSuccess, showError} from 'actions/Error'
import { handleSubmitResult } from 'actions/form'
import AdminCommonFormLayout from '../layout/AdminCommonFormLayout'
import AdminCommonForm from '../layout/AdminCommonForm'
import { AdminNutritionCardForm, validationSchema} from './form/AdminNutritionCardForm'
import { FoodItemField } from './NutritionAttributes'

export default function AdminNutritionCard({ food_item_id, nutrient_id, nutritionCardId, in_modal, onSaved} ) {
  const dispatch = useDispatch()
  const history = useHistory()
  let { nutrition_card_id } = useParams()
  nutrition_card_id = nutrition_card_id ? nutrition_card_id : nutritionCardId
  const nutrition_card = useSelector(() => adminNutritionCardList.getObject(nutrition_card_id))
  const food_item = useSelector(() => adminFoodItemList.getObject(food_item_id))
  let initial_values = nutrition_card
  const urlParams = new URLSearchParams(window.location.search)
  const is_loading = useSelector(() => adminNutritionCardList.isLoading()) || (nutrition_card_id && !get(nutrition_card, "id"))
  const is_busy = useSelector(() => adminNutritionCardList.getIsSavingObject())
  let title = "New Nutrition Card"

  useEffect(() => {
    dispatch(adminNutritionCardList.ensureObjectLoaded(nutrition_card_id))
    if(food_item_id) {
      dispatch(adminFoodItemList.ensureObjectLoaded(food_item_id))
    }
  },[])

  useEffect(() => {
    title = <FoodItemField food_item_id={ get(nutrition_card, "food_item") } field="name" />
  },[nutrition_card])

  const onSave = (values, formik_funcs) => {
    const on_ok = function(json) {
      dispatch(adminNutritionCardList.invalidateList())
      showSuccess("Saved", "Nutrition Card saved")

      if ( food_item_id && in_modal ) {
        onSaved()
      }

      else if ( ! nutrition_card_id ) {
        history.push(`/admin/nutrition_card/${json.id}`)
      }
    }
    if ( nutrition_card_id ) {
      values.id = nutrition_card_id
      return dispatch(adminNutritionCardList.saveObject(values)).then((res) => handleSubmitResult({res, formik_funcs, on_ok}))
    } else {
      return dispatch(adminNutritionCardList.saveNewObject(values)).then((res) => handleSubmitResult({res, formik_funcs, on_ok}))
    }
  }

  const getCellValue = (header_key, item, index) => {
    switch( header_key ) {
      default:
        return undefined
    }
  }

  const renderModalForm = () => {
    return(
      <AdminCommonForm
        is_loading={is_loading}
        is_busy={is_busy}
        initial_values={initial_values}
        inModal={true}
        onSave={onSave}>
        {
          ({formik_props}) => {
            if(food_item_id) {
              formik_props.values.food_item = food_item_id
            }
            return <AdminNutritionCardForm formik_props={formik_props} food_item={ food_item } />
          }
        }
      </AdminCommonForm>
    )
  }

  const renderLayoutForm = () => {
    return(
      <AdminCommonFormLayout
          breadcrumbs={[
              {name: 'admin_home'},
              {name: 'nutrition_cards', label: 'Nutrition Cards', url: '/admin/nutrition_cards'},
              {name: 'nutrition_card', label: get(nutrition_card, ["display_name"], 'New Nutrition Card'), url: `/admin/nutrition_card/${nutrition_card_id}`}
          ]}
          is_busy={is_busy}
          is_loading={is_loading}
          title={title}
          initial_values={initial_values}
          validationSchema={validationSchema}
          onSave={onSave}
      >
        {
          ({formik_props}) => {
            return <AdminNutritionCardForm formik_props={formik_props} food_item_id={ food_item_id } />
          }
        }
      </AdminCommonFormLayout>
    )
  }

  return in_modal ? renderModalForm() : renderLayoutForm()

}
