import React from 'react'
import { get } from 'lodash'
import { Field } from 'formik'
import { KeyboardDateTimePicker, KeyboardDatePicker, KeyboardTimePicker } from 'formik-material-ui-pickers'
import { makeStyles } from '@material-ui/core'
import { MuiPickersUtilsProvider } from '@material-ui/pickers'
import DateFnsUtils from '@date-io/date-fns'
import ClearIcon from '@material-ui/icons/Clear'

const useStyles = makeStyles((theme) => ({
  clearIcon: {
    cursor: "pointer",
    position: 'absolute',
    right: 60,
    top: '30%',
    color: '#000',
    "&:hover": {
      borderRadius: '50%',
      backgroundColor: '#efefef',
      color: 'red'
    },
  },
}))


export const FormikDateTimePicker = ({ label, name, formik_props, include_time, format, ...props }) => {
  const touched = get(formik_props, ["touched", name])

  format = format || (include_time !== false ? "dd MMM yyyy hh:mm a" : "dd MMM yyyy")

  return (
    <div>
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <Field component={createPicker({ include_time: include_time !== false })}
          label={label}
          format={format}
          error={touched && Boolean(get(formik_props, ["errors", name]))}
          helperText={touched ? get(formik_props, ["errors", name]) : ""}
          name={name}
          fullWidth
          {...props} />
      </MuiPickersUtilsProvider>
    </div>
  )
}

export const FormikTimePicker = ({ label, name, formik_props, include_time, format, ...props }) => {
  const touched = get(formik_props, ["touched", name])

  format = format ? format : "hh:mm a"

  return (
    <div>
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <Field component={KeyboardTimePicker}
          minutesStep={15}
          label={label}
          format={format}
          error={touched && Boolean(get(formik_props, ["errors", name]))}
          helperText={touched ? get(formik_props, ["errors", name]) : ""}
          name={name}
          fullWidth
          {...props} />
      </MuiPickersUtilsProvider>
    </div>
  )
}

const createPicker = ({ include_time }) => {
  if (include_time) {
    return WrappedDateTimePicker
  } else {
    return WrappedDatePicker
  }
}

const WrappedDateTimePicker = (props) => {
  const classes = useStyles()

  const { name } = props.field
  const { form } = props
  const { values } = form

  const onClear = () => {
    form.setFieldValue(name, null)
  }

  const value = get(values, name)
  const is_empty = !value

  if (value === undefined) {
    form.setFieldValue(name, null)
  }

  return (
    <div>
      <KeyboardDateTimePicker showTodayButton ampm={false} {...props} />
      {!is_empty &&
        <ClearIcon onClick={onClear} css={classes.clearIcon} />
      }
    </div>
  )
}

const WrappedDatePicker = (props) => {
  const classes = useStyles()
  const { name } = props.field
  const { form } = props
  const { values } = form

  const onClear = () => {
    form.setFieldValue(name, null)
  }

  const value = get(values, name)
  const is_empty = !value

  if (value === undefined) {
    form.setFieldValue(name, null)
  }

  return (
    <div style={{ position: 'relative' }}>
      <KeyboardDatePicker {...props} />
      {!is_empty &&
        <ClearIcon onClick={onClear} className={classes.clearIcon} />
      }
    </div>
  )
}
