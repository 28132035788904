import React from 'react'
import PropTypes from 'prop-types'
import { size, filter } from 'lodash'
import { makeStyles, createStyles } from '@material-ui/core/styles'
import List from '@material-ui/core/List'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import Divider from '@material-ui/core/Divider'
import Collapse from '@material-ui/core/Collapse'
import IconExpandLess from '@material-ui/icons/ExpandLess'
import IconExpandMore from '@material-ui/icons/ExpandMore'
import AppMenuItemComponent from './AppMenuItemComponent'

const AppMenuItem = ( { name, link, Icon, items = [], isAdmin, openMenu, setOpenMenu, parentName } ) => {
  const classes = useStyles()

  const filteredItems = filter( items, ( item ) => {
    // if admin_only is true, only include the item if the user is an admin
    if ( item.admin_only ) {
      return isAdmin
    }
    // otherwise, always include the item
    return true
  } )

  const isExpandable = size( filteredItems ) > 0

  function handleClick() {
    if ( openMenu === name ) {
      setOpenMenu( '' )
    } else {
      setOpenMenu( name )
    }
  }

  const MenuItemRoot = (
    <AppMenuItemComponent className={ classes.menuItem } link={ link } onClick={ handleClick } parentName={ parentName }>
      { !!Icon && (
        <ListItemIcon className={ classes.menuItemIcon }>
          <Icon />
        </ListItemIcon>
      ) }
      <ListItemText primary={ name } inset={ !Icon } />
      { isExpandable && ( openMenu === name ? <IconExpandLess /> : <IconExpandMore /> ) }
    </AppMenuItemComponent>
  )

  const MenuItemChildren = isExpandable ? (
    <Collapse in={ openMenu === parentName } timeout="auto" unmountOnExit>
      <Divider />
      <List component="div" disablePadding>
        { filteredItems.map( ( item, index ) => (
          <AppMenuItem
            { ...item }
            isAdmin={ isAdmin }
            key={ index }
            openMenu={ openMenu }
            setOpenMenu={ setOpenMenu }
            parentName={ parentName } // Pass parentName down to child items
          />
        ) ) }
      </List>
    </Collapse>
  ) : null


  return (
    <>
      { MenuItemRoot }
      { MenuItemChildren }
    </>
  )
}

const useStyles = makeStyles( theme =>
  createStyles( {
    menuItem: {
      '&.active': {
        background: 'rgba(0, 0, 0, 0.08)',
        '& .MuiListItemIcon-root': {
          color: '#fff',
        },
      },
    },
    menuItemIcon: {
      color: '#637382',
    },
  } ),
)

export default AppMenuItem
