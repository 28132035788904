import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useParams } from 'react-router-dom'
import { get, filter, map, orderBy, uniq, size } from 'lodash'
import { adminKpiDashboardUserList } from './actions/admin_kpi_dashboard_user'
import { adminKpiDashboardList } from './actions/admin_kpi_dashboard'
import { adminKpiResultList } from './actions/admin_kpi_result'
import { adminKpiList } from './actions/admin_kpi'
import { adminKpiDashboardCategoryList } from './actions/admin_kpi_dashboard_category'
import { adminUserList } from 'admin/components/users/actions/admin_user'
import {showSuccess, showError} from 'actions/Error'
import { handleSubmitResult } from 'actions/form'
import AdminMainLayout from '../layout/AdminMainLayout'
import { validationSchema } from './form/AdminKpiDashboardForm'
import { FormikGeneralFormErrors } from 'components/form/GeneralFormErrors'
import { Formik, Form }  from 'formik'
import FormCard from 'components/layout/FormCard'
import { SaveButton } from 'components/form/Buttons'
import AdminKpiTemplatesTable from './AdminKpiTemplatesTable'
import AdminKpiHistory from './AdminKpiHistory'
import AdminKpiCommentForm from './form/AdminKpiCommentForm'
import AdminResolveKpiResultForm from './form/AdminResolveKpiResultForm'
import { Card } from 'components/layout/Card'
import { Divider, Button, makeStyles, Typography, Grid } from "@material-ui/core"
import { Modal } from 'components/layout/Modal'
import Loading from 'components/Loading'
import SwipeCard from 'components/SwipeCard'
import { Alert } from '@material-ui/lab'

const useStyles = makeStyles((theme) => ({
    title: {
        textTransform: 'capitalize'
    },
}))

export default function AdminKpiDashboardUser() {
    const classes = useStyles()
    const dispatch = useDispatch()
    const history = useHistory()
    const { user_id } = useParams()
    const kpi_results = useSelector(() => adminKpiResultList.getVisibleObjects())
    const is_loading_kpi_results = useSelector(() => adminKpiResultList.isLoading())

    const kpi_user = useSelector(() => adminKpiDashboardUserList.getObject(user_id))
    const user = useSelector(() => adminUserList.getObject(user_id))
    const is_loading_kpi_users = useSelector(() => adminKpiDashboardUserList.isLoading())

    const kpi_categories = useSelector(() => adminKpiDashboardCategoryList.getVisibleObjects())
    const orderedKpiCategories = orderBy(kpi_categories, ["name"],[ "desc"])
    const kpi_ids = map(kpi_results, (x) => x.kpi)
    const unique_kpi_ids = uniq(kpi_ids)
    const kpis = useSelector(() => adminKpiList.getObjects(unique_kpi_ids))

    const [dashboardId, setDashboardId] = useState(null)
    const single_dashboard = useSelector(() => adminKpiDashboardList.getObject(dashboardId))

    const [kpiId, setKpiId] = useState(null)

    const is_busy = useSelector(() => adminKpiDashboardUserList.getIsSavingObject())
    const initial_values = kpi_user
    const title = `User KPI Dashboard`
    const [showHistory, setShowHistory] = useState(null)
    const [showCommentForm, setShowCommentForm] = useState(null)
    const [showResolveKpiForm, setShowResolveKpiForm] = useState(null)
    const [kpiCategories, setKpiCategories] = useState(null)

    useEffect(() => {
        dispatch(adminKpiResultList.updateListFilter({user: user_id}))
        dispatch(adminKpiResultList.fetchListIfNeeded())
        dispatch(adminKpiDashboardUserList.ensureObjectLoaded(user_id))
        dispatch(adminKpiDashboardCategoryList.fetchListIfNeeded())
        dispatch(adminUserList.ensureObjectLoaded(user_id))
    },[])

    useEffect(() => {
        dispatch(adminKpiList.ensureObjectsLoaded(unique_kpi_ids))
    },[unique_kpi_ids])

    const getCellValue = (header_key, item, index) => {
        switch( header_key ) {
            default:
            return undefined
        }
    }

    const onAddComment = (kpi_id, kpi_name) => {
        setKpiId(kpi_id)
        setKpiName(kpi_name)
        setShowCommentForm(true)
    }

    const onShowHistory = (kpi_id, kpi_name) => {
        setKpiId(kpi_id)
        setKpiName(kpi_name)
        setShowHistory(true)
    }

    const onResolveKpi = (kpi_id) => {
        setShowResolveKpiForm(true)
    }

    const renderKpiCards = (category) => {
        var kpisByCategory = orderBy(filter(kpi_results, {'category': category}), 'status')
        var orderedKpiResults =  orderBy(kpi_results, ['calculated_at'], ['desc'])
        let has_kpis = false
        let this_kpi = []
        let these_kpi_results = []
        var content = map(orderedKpiResults, (kpi_result, index) => {
                        return map(kpis, (kpi) => {
                            if(get(kpi_result, 'kpi') === get(kpi, 'id') && get(category, 'id') === get(kpi, 'category')) {
                                has_kpis = true
                                this_kpi = kpi
                                these_kpi_results.push(kpi_result)
                            }
                        })
                    })

        return(
            has_kpis &&
                <Grid item xs={12} md={6} lg={4}>
                    <Card
                        title={ get(category, 'name') }
                        content = {
                            <SwipeCard
                                kpi_results={these_kpi_results}
                                kpi ={this_kpi}
                                category={ get(category, 'name') }
                                onAddComment={ onAddComment }
                                onShowHistory={ onShowHistory }
                                onResolveKpi={ onResolveKpi }
                            />
                        }
                    />
                </Grid>
        )
    }

    return (
        <AdminMainLayout
            breadcrumbs={[
                {name: 'admin_home'},
                {name: 'user_kpis', label: 'KPI Dashboard Users', url: '/admin/kpis/dashboards/users'},
                {name: 'user_kpi_dashboard',  label: title, url: `/admin/kpis/dashboards/user/${user_id}`}
            ]}
            is_busy={is_busy}
            is_loading={is_loading_kpi_results}
            title={title}
        >

            { is_loading_kpi_users || is_loading_kpi_results && size(kpi_results) > 0 ? <Loading /> :
            <Grid container spacing={2}>
                { size(kpi_results) > 0 ?
                    map(orderedKpiCategories, (category) => {
                        return renderKpiCards(category)
                    }) :
                    <Grid item xs={12}>
                        <Alert severity="info">
                            <Typography variant="h5">No KPI Results for {user.display_name} yet.</Typography>
                            <Typography>Check back soon.</Typography>
                        </Alert>
                    </Grid>
                }
            </Grid>
            }

            { showCommentForm &&
                <Modal onClose={() => setShowCommentForm(false)} title={ kpiName }>
                    <AdminKpiCommentForm kpi_id={kpiId} user_id={ user_id } />
                </Modal>
            }

            { showResolveKpiForm &&
                <Modal onClose={() => setShowResolveKpiForm(false)} title={ kpiName }>
                    <AdminResolveKpiResultForm kpi_id={kpiId} />
                </Modal>
            }

        </AdminMainLayout>
    )
}
