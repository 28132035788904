import { ItemList } from 'orm'
import { get, map, head, filter } from 'lodash'

class AddressList extends ItemList {
    getEntityKey() {
        return "admin/address"
    }
    
}    

export const adminAddressList = new AddressList("admin_address__default")
export const adminAddressForDropdownList = new AddressList("admin_address_for_dropdown__default")


export const invalidateAllAddressLists = () => { 
    return (dispatch, getState) => {
        dispatch(adminAddressList.invalidateList())
        dispatch(adminAddressList.fetchListIfNeeded())
        dispatch(adminAddressForDropdownList.invalidateList())
        dispatch(adminAddressForDropdownList.fetchListIfNeeded())
    }
}
