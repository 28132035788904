import moment from 'moment'
import { size, compact, join, filter, keys, get, forEach, map, split, remove } from 'lodash'
import { adminCityList } from 'admin/components/cities/actions/admin_city'
import { adminParcelStates } from 'admin/actions/admin_dropdown_options'
import { adminParcelCompanyTypes } from 'admin/actions/admin_dropdown_options'

const DATE_RANGE_FILTER_ALL_TIME = "all_time"

export class EnhancedFilter {
    // Get an instance by calling getEnhancedFilter on the ItemList object, don't construct directly

    constructor(item_list) {
        this.item_list = item_list
        this._resetLocalCache()
    }

    getInitialValues() {
        if ( ! this._cache.initial_values ) {
            const res = this.getFilterAsForm()

            // cities
            const cities_for_form = {}
            const all_cities_by_id = adminCityList.getAllObjects()
            forEach(keys(all_cities_by_id), function(city_id) {
                cities_for_form[city_id] = false
            })
            if ( size(get(res, "cities")) > 0 ) {
                forEach(keys(res.cities), function(city_id) {
                    cities_for_form[city_id] = true
                })
            }
            res.cities = cities_for_form

            // parcel_states
            const parcel_states_for_form = {}
            const all_parcel_states_by_id = adminParcelStates.getAllObjects()
            forEach(keys(all_parcel_states_by_id), function(parcel_state_id) {
                parcel_states_for_form[parcel_state_id] = false
            })
            if ( size(get(res, "parcel_states")) > 0 ) {
                forEach(keys(res.parcel_states), function(parcel_state_id) {
                    parcel_states_for_form[parcel_state_id] = true
                })
            }
            res.parcel_states = parcel_states_for_form

            // parcel_company_types
            const parcel_company_types_for_form = {}
            const all_parcel_company_types_by_id = adminParcelCompanyTypes.getAllObjects()
            forEach(keys(all_parcel_company_types_by_id), function(company_type_id) {
                parcel_company_types_for_form[company_type_id] = false
            })
            if ( size(get(res, "parcel_company_types")) > 0 ) {
                forEach(keys(res.parcel_company_type), function(company_type_id) {
                    parcel_company_types_for_form[company_type_id] = true
                })
            }
            res.parcel_company_types = parcel_company_types_for_form

            // date range
            res.date_range_filter_type = res.date_range_filter_type || DATE_RANGE_FILTER_ALL_TIME

            this._cache.initial_values = res
        }
        return this._cache.initial_values
    }

    getNumFilters() {
        if ( ! this._cache.num_filters ) {
            const f = this.getFilterAsObjects()
            if ( ! f ) {
                this._cache.num_filters = 0
            } else {
                const is_date_range_filter_active = f.date_range_filter_type && f.date_range_filter_type != DATE_RANGE_FILTER_ALL_TIME

                this._cache.num_filters = size(f.cities) + size(f.parcel_states) + size(f.parcel_company_types) + (is_date_range_filter_active ? 1 : 0)

            }
        }
        return this._cache.num_filters
    }

    getFilterAsObjects() {
        if ( ! this._cache.filter_as_objects ) {
            this._cache.filter_as_objects = this._convertFilterToObjects(this.item_list.getFilter())
        }
        return this._cache.filter_as_objects
    }

    getFilterAsForm() {
        if ( ! this._cache.filter_as_form ) {
            this._cache.filter_as_form = this._convertFilterToFormValues(this.item_list.getFilter())
        }
        return this._cache.filter_as_form
    }

    getFilterAsPost() {
        if ( ! this._cache.filter_as_post ) {
            this._cache.filter_as_post = this.item_list.getFilter()
        }
        return this._cache.filter_as_post
    }

    updateFilter(values, is_in_post_data_format) {
        this._resetLocalCache()
        return (dispatch, getState) => {
            const post_values = (is_in_post_data_format === true) ? values : this._convertFormikDataToPostData(values)
            dispatch(this.item_list.updateListFilter(post_values))
            dispatch(this.item_list.fetchListIfNeeded())
        }
    }

    removeCityFilter(id_to_remove) {
        return this._removeItemFromFilter({field_name: 'cities', id_to_remove})
    }

    removeDateFilter() {
        const f = this.item_list.getFilter()
        f.date_range_filter_type = 'all_time'
        delete f.from_date
        delete f.to_date
        return this.updateFilter(f, true)
    }

    removeParcelStateFilter(id_to_remove) {
        return this._removeItemFromFilter({field_name: 'parcel_states', id_to_remove})
    }

    removeParcelCompanyTypeFilter(id_to_remove) {
        return this._removeItemFromFilter({field_name: 'parcel_company_types', id_to_remove})
    }

    _removeItemFromFilter({field_name, id_to_remove}) {
        const f = this.item_list.getFilter()
        const ids = split(get(f, field_name, ""), ",")
        remove(ids, (id) => id === id_to_remove)
        f[field_name] = {}
        forEach(ids, function(id) {f[field_name][id] = true})
        return this.updateFilter(f)
    }

    removeAnyFieldFilter() {
        const f = this.item_list.getFilter()
        delete f.any_field
        return this.updateFilter(f, true)
    }

    setAnyFieldFilter(any_field_value) {
        const f = this.item_list.getFilter()
        f.any_field = any_field_value
        return this.updateFilter(f, true)
    }

    _convertFormikDataToPostData(values) {
        const res = {}

        // cities
        const cities = get(values, "cities")
        const enabled_cities = compact(filter(keys(cities), (city_id) => cities[city_id] == true))
        const cities_as_comm_separated_string = join(enabled_cities)
        res.cities = cities_as_comm_separated_string

        // date range
        const date_range_filter_type = get(values, 'date_range_filter_type', 'all_time')
        if ( date_range_filter_type === 'single_date' ) {
            res.single_date = moment(get(values, 'single_date')).format()
            res.from_date = res.single_date
            res.to_date = res.single_date
        }
        if ( date_range_filter_type === 'date_range' ) {
            res.from_date = moment(get(values, 'from_date')).format()
            res.to_date = moment(get(values, 'to_date')).format()
        }
        res.date_range_filter_type = date_range_filter_type

        // parcel states
        const parcel_states = get(values, "parcel_states")
        const enabled_parcel_states = compact(filter(keys(parcel_states), (parcel_state) => parcel_states[parcel_state] == true))
        const parcel_states_as_comm_separated_string = join(enabled_parcel_states)
        res.parcel_states = parcel_states_as_comm_separated_string

        // parcel company types
        const parcel_company_types = get(values, "parcel_company_types")
        const enabled_parcel_company_types = compact(filter(keys(parcel_company_types), (parcel_company_type) => parcel_company_types[parcel_company_type] == true))
        const parcel_company_types_as_comm_separated_string = join(enabled_parcel_company_types)
        res.parcel_company_types = parcel_company_types_as_comm_separated_string

        // any_field
        res.any_field = get(values, "any_field")

        return res
    }

    _convertFilterToObjects(filter_in_post_data_format) {
        const res = {}

        // cities
        const city_ids = compact(split(get(filter_in_post_data_format, "cities"), ","))
        const all_cities_by_id = adminCityList.getObjectsById()
        res.cities = map(city_ids, (city_id) => get(all_cities_by_id, city_id))

        // date range
        const date_range_filter_type = get(filter_in_post_data_format, 'date_range_filter_type', 'all_time')
        if ( date_range_filter_type === 'single_date' ) {
            res.single_date = moment(get(filter_in_post_data_format, 'single_date'))
            res.from_date = res.single_date
            res.to_date = res.single_date
        }
        if ( date_range_filter_type === 'date_range' ) {
            res.from_date = moment(get(filter_in_post_data_format, 'from_date'))
            res.to_date = moment(get(filter_in_post_data_format, 'to_date'))
        }
        res.date_range_filter_type = date_range_filter_type

        // parcel states
        const parcel_states = compact(split(get(filter_in_post_data_format, "parcel_states"), ","))
        const all_parcel_states = adminParcelStates.getAllObjects()
        res.parcel_states = map(parcel_states, (parcel_state) => get(all_parcel_states, parcel_state))

        // parcel company types
        const parcel_company_types = compact(split(get(filter_in_post_data_format, "parcel_company_types"), ","))
        const all_parcel_company_types = adminParcelCompanyTypes.getAllObjects()
        res.parcel_company_types = map(parcel_company_types, (parcel_company_type) => get(all_parcel_company_types, parcel_company_type))

        // any field
        res.any_field = filter_in_post_data_format.any_field

        return res
    }

    _convertFilterToFormValues(filter_in_post_data_format) {
        const res = this._convertFilterToObjects(filter_in_post_data_format)

        // cities
        const enabled_cities = get(res, "cities", [])
        res.cities = {}
        map(enabled_cities, (city) => res.cities[city.id] = true)

        // parcel states
        const enabled_parcel_states = get(res, "parcel_states", [])
        res.parcel_states = {}
        map(enabled_parcel_states, (parcel_state) => res.parcel_states[parcel_state.id] = true)

        // parcel company types
        const enabled_parcel_company_types = get(res, "parcel_company_types", [])
        res.parcel_company_types = {}
        map(enabled_parcel_company_types, (parcel_company_type) => res.parcel_company_types[parcel_company_type.id] = true)


        return res
    }

    _resetLocalCache() {
        this._cache = {}
    }

}
