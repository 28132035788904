
import PropTypes from 'prop-types'
import { Card, CardHeader, Box } from '@material-ui/core'
import Chart, { useChart } from 'components/chart'
import { map } from 'lodash'

// ----------------------------------------------------------------------

NutrientBarChart.propTypes = {
  chart: PropTypes.object,
  title: PropTypes.string,
  subheader: PropTypes.string,
}

export default function NutrientBarChart( { title, subheader, chart, ...other } ) {
  const { labels, colors, series, options } = chart

  const chartOptions = useChart( {
    colors,
    plotOptions: {
      bar: {
        columnWidth: '50%',
      },
    },
    fill: {
      type: map( series, 'fill' ),
    },
    labels,
    yaxis: {
      min: 0,
      max: 100,
      forceNiceScale: false,
      decimalsInFloat: 0,
    },
    xaxis: {
      type: 'string',
    },
    tooltip: {
      shared: true,
      intersect: false,
      y: {
        formatter: ( value ) => {
          if ( typeof value !== 'undefined' ) {
            return `${ value?.toFixed( 0 ) }`
          }
          return value
        },
      },
    },
    ...options,
  } )

  return (
    <Card { ...other }>
      <CardHeader title={ title } subheader={ subheader } />

      <Box sx={ { p: 3, pb: 1 } } dir="ltr">
        <Chart type="line" series={ series } options={ chartOptions } height={ 364 } />
      </Box>
    </Card>
  )
}
