import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Route, useHistory, Link } from 'react-router-dom'
import { get } from 'lodash'
import Timestamp from 'components/Timestamp'
import CurrencyValue from 'components/CurrencyValue'
import DonorCommonListLayout from 'portals/donor/components/layout/DonorCommonListLayout'
import { FormikDropdownField } from 'components/form/Dropdown'
import { FormikInputField } from 'components/form/InputField'
import { contactMoneyDonationReportList } from 'actions/contact/money_donation_report'
import { contactMoneyDonationList } from 'actions/contact/money_donation'
import { Separator } from 'components/layout/Separator'
import { countryList } from 'actions/country'
import { provinceList } from 'actions/province'
import { statusList } from 'actions/status'
import { Hidden, Button } from '@material-ui/core'
import { ReportDownloadLink } from 'components/layout/ReportDownloadLink'
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord'
import { Chip, Tooltip } from '@material-ui/core'
import { ShortId } from 'components/ShortId'
import { payment_status_colours }  from 'components/payments/PaymentConfig'
import { getActiveCompanySlug, getActiveCompanyId } from 'actions/active_company'
import { PaymentStatus } from 'components/money_donations/PaymentStatus'
import MoneyDonationWidgets from './widgets/MoneyDonationWidgets'

export default function MoneyDonations(props) {

    const dispatch = useDispatch()
    const history = useHistory()
    const company_slug = getActiveCompanySlug()
    const company_id = getActiveCompanyId()
    const is_loading = useSelector(() => !contactMoneyDonationList.isReady() && contactMoneyDonationList.isLoading())
    const money_donations = useSelector(() => contactMoneyDonationList.getVisibleObjects())
    const money_donation_report = useSelector(() => contactMoneyDonationReportList.getVisibleObjects())

    useEffect(() => {
        dispatch(contactMoneyDonationList.updateListFilter({source: company_id}))
        dispatch(contactMoneyDonationList.fetchListIfNeeded())
        dispatch(contactMoneyDonationReportList.updateListFilter({source: company_id}))
        dispatch(contactMoneyDonationReportList.fetchListIfNeeded())
    }, [])

    const onUpdateListOrdering = (field) => {
      dispatch(contactMoneyDonationList.updateListOrdering(field))
    }

    const renderLayout = (columns) => {
        const { is_loading } = props
        return (
            <DonorCommonListLayout active_key="money_donations"
                                   breadcrumbs={[{name: 'home'},
                                                 {name: 'money_donations', label: "Money donations", url: '/donor/donations/money'}]}
                                   is_loading={is_loading}
                                   add_label="New Money Donation"
                                   columns={columns}
                                   enableAnyFieldFilter={true}
                                   item_list={contactMoneyDonationList}
                                   canDelete={false}
                                   widgets={<MoneyDonationWidgets data={money_donation_report[0]} />}
                                   title={"Money Donations"}
            />
        )
    }

    const renderDonatedBy = (item) => {
      if(get(item, ["donator", "first_name"])) {
        return (
          <span>{get(item, ["donator", "first_name"])} {get(item, ["donator", "last_name"])}</span>
        )
      }
      else  if(get(item, "gateway_donator")) {
        return (
          <span>{get(item, "gateway_donator")}</span>
        )
      }
    }

    const renderDateSetStatus = (date) => {
      if(date) {
        return(
          <Tooltip arrow title={<Timestamp value={date} />}>
            <FiberManualRecordIcon style={{color: 'green'}} />
          </Tooltip>
        )
      }
      else { return(<FiberManualRecordIcon style={{color: 'red'}} />) }
    }

    const renderPaymentStatus = (status) => {
       var color = payment_status_colours[status]
       return (
         <Chip label={status} style={{background: color}} />
       )
    }

    const columnsDesktop = [


      { field: 'unique_payment_ref',
          title: 'Ref',
          width: 100,
          render: (item) => <ShortId value={item.unique_payment_ref} />,
          sort: (direction) => onUpdateListOrdering(direction === 'asc' ? 'unique_payment_ref' : '-unique_payment_ref'),
        },
        { field: 'donator',
          title: 'Donated by',
          render: (item) => renderDonatedBy(item),
          sort: (direction) => onUpdateListOrdering(direction === 'asc' ? 'donator__email' : '-donator__email')
        },
        { field: 'source_name',
          title: 'Source',
          sort:(direction) => onUpdateListOrdering(direction === 'asc' ? 'source__name' : '-source__name')
        },
        { field: 'gateway',
          title: 'Gateway',
          sort: (direction) => onUpdateListOrdering(direction === 'asc' ? 'source' : '-source'),
        },
        { field: 'paid_at',
          title: 'Donation Date',
          render: (item) => <Timestamp value={item.paid_at} format='date' />,
          sort: (direction) => onUpdateListOrdering(direction === 'asc' ? 'paid_at' : '-paid_at'),
        },
        { field: 'amount_rands',
          title: 'Amount',
          render: (item) => <CurrencyValue value={item.amount_rands} />,
          sort:(direction) => onUpdateListOrdering(direction === 'asc' ? 'amount_rands' : '-amount_rands')
        },
        { field: 'status',
          title: 'Status',
          render: (item) => <PaymentStatus status={item.status} />,
          sort: (direction) => onUpdateListOrdering(direction === 'asc' ? 'status' : '-status')
        }
    ]

    const columnsMobile = columnsDesktop

    return (
        <>
            <Hidden smDown>
                {renderLayout(columnsDesktop)}
            </Hidden>
            <Hidden mdUp>
                {renderLayout(columnsMobile)}
            </Hidden>
        </>
    )
}
