import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { Document, Page } from 'react-pdf'
import { Button } from '@material-ui/core'

export default function PdfView({file, showPageNumbers=true, showDownloadLink=false, align}) {
  const [numPages, setNumPages] = useState(null)
  const [pageNumber, setPageNumber] = useState(1)

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages)
  }

  return (
    <div>
      {showDownloadLink &&
        <a href={file} download target="_blank">
          <Button variant="outlined">Download</Button>
        </a>
      }
      <Document
        file={file}
        onLoadSuccess={onDocumentLoadSuccess}
      >
        <Page pageNumber={pageNumber} scale={1.8} />
      </Document>
      {showPageNumbers && <p>Page {pageNumber} of {numPages}</p> }
    </div>
  )
}
