import React, { Component } from 'react';
import { jsx, css, Global } from '@emotion/core'
import LoadingOverlay from 'react-loading-overlay'
import Loading from './Loading'
import { Trans } from 'react-i18next'

class WrappingBusyMask extends Component {

    render() {
        const { children, is_loading } = this.props
        return (
            <LoadingOverlay
                active={is_loading}
                spinner
                text={<Trans>Please wait a moment...</Trans>}
            >
              <Global />
              { ! is_loading && children }
            </LoadingOverlay>
        )
    }
}
export default WrappingBusyMask
