import React, { Component, useState } from 'react'
import { makeStyles, Tooltip } from '@material-ui/core'
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord'
import CheckCircleOutlinedIcon from '@material-ui/icons/CheckCircleOutlined'
import CancelOutlinedIcon from '@material-ui/icons/CancelOutlined'

const useStyles = makeStyles((theme) => ({
    success: {
        color: "#00ab55"
    },
    error: {
        color: "red"
    },
}))

export const StatusIndicator = ({ status, type='default', onClick, tooltip, style, ...props }) => {
    const classes = useStyles()
    let status_indicator
    status = status === true ? 'success' : status
    status = status === false ? 'error' : status
    // type options are default or check
    if(type === 'default') {
        status_indicator = <FiberManualRecordIcon className={classes[status]} style={style} onClick={onClick} />
    }
    else if(type === 'check' && status === 'success') {
        status_indicator = <CheckCircleOutlinedIcon className={classes[status]} style={style} onClick={onClick} />
    }
    else if(type === 'check' && status === 'error') {
        status_indicator = <CancelOutlinedIcon className={classes[status]} style={style} onClick={onClick} />
    }
    const status_indicator_with_tooltip = <Tooltip title={tooltip} arrow>{status_indicator}</Tooltip>
    return (
        <>{tooltip ? status_indicator_with_tooltip : status_indicator }</>
    )

}
