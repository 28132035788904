import { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { adminPublishedTagList } from 'admin/components/tags/actions/admin_tag'
import { FormikMultiSelectDropdownField } from 'components/form/Dropdown'

const OrderTags = ({formik_props}) => {
    const dispatch = useDispatch()
    const tag_options = useSelector(() => adminPublishedTagList.getAsOptions())

    useEffect(() => {
        dispatch(adminPublishedTagList.updatePaginationNumItemsPerPage(100))
        dispatch(adminPublishedTagList.updateListFilter({published:true}))
        dispatch(adminPublishedTagList.fetchListIfNeeded())
    },[])

    return(
        <FormikMultiSelectDropdownField
            name="tags"
            label="Tags"
            options={tag_options}
            formik_props={formik_props}
        />
    )
}

export default OrderTags
