import React from "react"
import { Editor } from "react-draft-wysiwyg"
import htmlToDraft from "html-to-draftjs"
import draftToHtml from "draftjs-to-html"
import { EditorState, convertToRaw, ContentState } from "draft-js"
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css"
import { Error } from 'components/layout/Error'
import { makeStyles } from "@material-ui/core"

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: 7,
    borderRadius: '4px',
    border: '1px solid #ccc',
    '& div.DraftEditor-root': {
      height: 250,
      width: '100%',
      overflowY: 'auto',
      padding: 0
    },
    '& div.DraftEditor-editorContainer': {

    },
    '& div.DraftEditor-editorContainer': {
      height: '100%',
      marginBottom: -20,
    },
    '& div.public-DraftEditor-content': {
      padding: 7,
      marginTop: -20,

      height: '100%'
    },
  }
}))

const DraftEditorField = ({
  input,
  meta,
  field,
  form,
  label,
  placeholder,
}) => {
  const classes=useStyles()
  const [active, setActive] = React.useState();
  const [editorState, setEditorState] = React.useState(
    EditorState.createEmpty()
  );
  React.useEffect(() => {
    if (form.dirty) {
      return;
    }
    if (!field.value) {
      return;
    }
    const contentBlock = htmlToDraft(field.value);
    if (contentBlock) {
      const contentState = ContentState.createFromBlockArray(
        contentBlock.contentBlocks
      );
      const editorState = EditorState.createWithContent(contentState);
      setEditorState(editorState);
    }
  }, [field.value, form.dirty]);

  const onEditorStateChange = editorState => {
    changeValue(editorState);
  };

  const changeValue = editorState => {
    setEditorState(editorState);
    form.setFieldValue(
      field.name,
      draftToHtml(convertToRaw(editorState.getCurrentContent()))
    );
  };
  const hasError = form.touched[field.name] && form.errors[field.name];
  return (
      <div className={classes.root}>
        <Editor
          editorState={editorState}
          onEditorStateChange={editorState => onEditorStateChange(editorState)}
          placeholder={placeholder}
          toolbar={{
            options: [
              "inline",
              "blockType",
              "fontSize",
              "fontFamily",
              "list",
              "textAlign",
              "link",
              "embedded",
              "remove",
              "history"
            ]
          }}
          name={field.name}
          id={field.name}
          onFocus={() => setActive(true)}
          onBlur={e => {
            setActive(false);
            field.onBlur(e);
          }}
        />
        {!hasError && <Error>{hasError}</Error>}
    </div>
  )
}

export default DraftEditorField
