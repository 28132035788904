import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { get } from 'lodash'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import BeneficiaryCommonFormLayout from '../layout/BeneficiaryCommonFormLayout'
import { AppBar, Tabs, Tab, Box, makeStyles, Paper, Grid, Typography, Tooltip, FormControl, FormControlLabel, Radio, RadioGroup, Button, Chip, TextField, Dialog } from '@material-ui/core'
import AddIcon from '@material-ui/icons/Add'
import CheckIcon from '@material-ui/icons/Check'
import { BasicMapWithMarker } from 'components/map/MapMarkers'
import Notifications from '../notifications/Notifications'
import CompanyForm from './form/CompanyForm'
import ContactAddresses from 'components/addresses/ContactAddresses'
import ContactContacts from 'components/contacts/ContactContacts'
import { Card } from 'components/layout/Card'
import { getActiveCompanyId } from 'actions/active_company'
import { contactCompanyList } from 'actions/contact/company'
import { FormikGeneralFormErrors } from 'components/form/GeneralFormErrors'
import { Formik, Form, Field } from 'formik'
import { FormikFileSetField } from 'components/form/FileSetField'
import { handleSubmitResult } from 'actions/form'
import {showSuccess, showError} from 'actions/Error'
import * as Yup from 'yup'
import { AlertModal } from 'components/layout/Modal'
import Accordion from 'components/layout/Accordion'
import { BeneficiaryRaceAgeSexGrid } from 'components/companies/beneficiaries/BeneficiaryRaceAgeSexGrid'
import { BeneficiaryNpoDssFields } from 'components/companies/beneficiaries/BeneficiaryNpoDssFields'
import { BeneficiaryMealsFrequencyGrid } from 'components/companies/beneficiaries/BeneficiaryMealsFrequencyGrid'

const active_company_id = getActiveCompanyId()

const validationSchema = Yup.object().shape({
    'name': Yup.string().required("Required")
})

const useStyles = makeStyles({
  root: {
    padding : 15,
    position: 'relative',
  },
  contactName: {
    fontWeight: 500
  },
  cardHeading: {
    marginBottom: 15
  },
  cardRadio: {
    position: 'absolute',
    top: 5,
    right: 5
  }
})

function TabPanel(props) {
  const { children, value, index, ...other } = props

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`tabpanel-${index}`}
      aria-labelledby={`tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  )
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
}

function a11yProps(index) {
  return {
    id: `tab-${index}`,
    'aria-controls': `tabpanel-${index}`,
  }
}

export default function Company({is_new, company_id}) {
  const classes = useStyles()
  const dispatch = useDispatch()
  const history = useHistory()
  let initial_values = {}
  let title = "New Branch"
  let active_key = "new_branch"
  if(!is_new) {
    company_id = company_id ? company_id : active_company_id

    const company = useSelector(() => contactCompanyList.getObject(company_id))
    company_id = get(company, "id")
    active_key = company_id ? get(company, "slug") : "company_settings"
    initial_values = company
    title = `${get(company, ["name"], 'New donor')}`
  }
  const is_loading = useSelector(() => contactCompanyList.isLoading())
  const is_busy = useSelector(() => contactCompanyList.getIsSavingObject())
  const [companyLoaded, setCompanyLoaded] = useState(true)

  useEffect(() => {
    if(!is_new) {
      setCompanyLoaded(
        dispatch(contactCompanyList.ensureObjectLoaded(active_company_id))
      )
    }
  }, [])

  const [tabValue, setTabValue] = React.useState(0)

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue)
  }

  const onSave = (values, formik_funcs) => {
      const on_ok = function(json) {
          dispatch(contactCompanyList.invalidateList())
          showSuccess("Saved", "Company settings saved")
          if ( ! company_id ) {
              history.push(`/donor/settings/company/${json.id}`)
          }
      }
      if ( company_id ) {
          if(company_id != active_company_id) { values.parent = active_company_id }
          values.id = company_id
          return dispatch(contactCompanyList.saveObject(values)).then((res) => handleSubmitResult({res, formik_funcs, on_ok}))
      } else {
          values.parent = active_company_id
          alert(values.parent)
          return dispatch(contactCompanyList.saveNewObject(values)).then((res) => handleSubmitResult({res, formik_funcs, on_ok}))
      }

  }

  const renderNotifications = () => {
    return(
      <Grid container justifyContent="space-between">
        <Grid item xs={12} lg={4}>
          <Notifications type={"sms"}/>
        </Grid>
        <Grid item xs={12} lg={4}>
          <Notifications type={"app"}/>
        </Grid>
        <Grid item xs={12} lg={4}>
          <Notifications type={"email"} />
        </Grid>
      </Grid>
    )
  }

  const renderImagesForm = (formik_props) => {
    return (
      <>
        <Card title="Banner Image"
              content={<FormikFileSetField max_num_files={1} name="banner_images" formik_props={formik_props} label={"banner"} />}
        />

        <Card title="Gallery Images"
              content={<FormikFileSetField name="images" formik_props={formik_props} label={"images"} />}
        />
      </>
    )
  }

  const getPanels = (formik_props) => {
    const panels = [
      {
        name: 'npo_dss',
        title: 'Non Profit & Department of Social Services',
        content: <BeneficiaryNpoDssFields formik_props={formik_props} />
      },
      {
        name: 'meals_frequency',
        title: 'Meals Frequency',
        content: <BeneficiaryMealsFrequencyGrid formik_props={formik_props} />
      },
      {
        name: 'beneficiary_race_age_sex_rid',
        title: 'Number of People Served, Race, Age & Sex',
        content: <BeneficiaryRaceAgeSexGrid formik_props={formik_props} />
      },
    ]
    return panels
  }

  const breadcrumbs = is_new ?
      [{name: 'donor_home'}, {name: 'branches', label: "Branches", url: '/donor/branches'}, {name: 'branches', label: "New", url: '/donor/branches/new'}] :
      [{name: 'donor_home'}, {name: 'company_settings', label: "Company Settings", url: '/donor/company/settings'}]

  return (
    <>
    <BeneficiaryCommonFormLayout
      active_key="company_settings"
      title={title}
      breadcrumbs={breadcrumbs}
      is_busy={is_busy}
      is_loading={is_loading}
      initial_values={initial_values}
      validationSchema={validationSchema}
      onSave={onSave}>

      {
          ({formik_props}) =>

              <>

                <FormikGeneralFormErrors />
                <Grid container spacing={2} justify="center">

                  <Grid item xs={12} lg={6}>
                    <Paper elevation={0} variant="outlined" square>
                      <AppBar position="static" elevation={0} color="white">
                        <Tabs value={tabValue} onChange={handleTabChange} aria-label="simple tabs example">
                          <Tab label="Company Details" {...a11yProps(0)} />
                          <Tab label="Notifications" {...a11yProps(1)} />
                          <Tab label="Images" {...a11yProps(2)} />
                        </Tabs>
                      </AppBar>
                      <TabPanel value={tabValue} index={0}>
                        <CompanyForm formik_props={formik_props} />
                      </TabPanel>
                      <TabPanel value={tabValue} index={1}>
                        { renderNotifications() }
                      </TabPanel>
                      <TabPanel value={tabValue} index={2}>
                        { renderImagesForm(formik_props)}
                      </TabPanel>

                      <Accordion panels={getPanels(formik_props)} />

                    </Paper>
                  </Grid>

                  {!is_new &&

                    <Grid item xs={12}  lg={6}>

                      <Grid container spacing={2}>

                        <Grid item xs={12}>
                          <Card
                            title="Contacts"
                            content={<ContactContacts is_new={is_new} company_id={company_id} />}
                          />
                        </Grid>

                        <Grid item xs={12}>
                          <Card
                            title="Addresses"
                            content={<ContactAddresses is_new={is_new} company_id={company_id} />}
                          />
                        </Grid>

                      </Grid>

                  </Grid>

                  }

                </Grid>

              </>
      }
    </BeneficiaryCommonFormLayout>
    {!companyLoaded &&
      <AlertModal>
        <Typography style={{textAlign: 'center', fontSize: 18}}>
          Oh dear!<br />
          We cannot load information for the active company.<br /><br />
          Try and <a onClick={() => window.location.reload()} target="_blank">refresh the page</a> or<br />
          <a href="mailto:support@saharvest.org" target="_blank">Contact support</a>
        </Typography>
      </AlertModal>
    }
    </>
  )
}
