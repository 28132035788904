import { ItemList } from 'orm'
import { map, head, filter } from 'lodash'
import moment from 'moment'

class ContactCompanyReportGeneration extends ItemList {
    getEntityKey() {
        return "contact/company_report_generation"
    }

}

export const contactCompanyReportGeneration = new ContactCompanyReportGeneration("contact_company_report_generation__default")
