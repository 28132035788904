import { ItemList } from 'orm'

class ChecklistResultList extends ItemList {
    getEntityKey() {
        return "admin/checklist_result"
    }

}

export const adminChecklistResultList = new ChecklistResultList("admin_checklist_result__default")
