import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { adminEditableEmailCustomParameterList } from './actions/admin_editable_email_custom_parameter'
import AdminCommonListLayout from 'admin/components/layout/AdminCommonListLayout'

export default function AdminEditableEmailCustomParameters({ ...props }) {
    const dispatch = useDispatch()
    const history = useHistory()
    const params = adminEditableEmailCustomParameterList.getVisibleObjects()
    const is_loading = adminEditableEmailCustomParameterList.isLoading()

    useEffect(() => {
        dispatch(adminEditableEmailCustomParameterList.fetchListIfNeeded())
    })

    const onEdit = (editable_email_id) => {
        history.push({
            pathname: '/admin/editable_email_custom_parameter/'+ editable_email_id
        })
    }

    const onAdd = () => {
        history.push('/admin/editable_email_custom_parameter/')
    }

    const filterEditableEmailCustomParameters = (filter_values) => {
        dispatch(adminEditableEmailCustomParameterList.updateListFilter(filter_values))
    }

    const columns = [
        { field: 'name', title: 'Name',
          sort: (direction) => onUpdateListOrdering(direction === 'asc' ? 'name' : '-name'),
        },
        { field: 'description', title: 'Description',
          sort: (direction) => onUpdateListOrdering(direction === 'asc' ? 'description' : '-description'),
        },
        { field: 'tag', title: 'Tag',
          sort: (direction) => onUpdateListOrdering(direction === 'asc' ? 'tag' : '-tag'),
        },
    ]

    return (
        <AdminCommonListLayout active_key="emails"
                               breadcrumbs={[{name: 'admin_home'},
                                             {name: 'editable_email', label: 'Editable Emails', url: '/admin/editable_emails'},
                                             {name: 'editable_email_custom_parameters', label: 'Editable Email Custom Parameters', url: '/admin/editable_email_custom_parameters'}]}
                               onEditRow={onEdit}
                               title={"Custom Parameters"}
                               add_label={"Add Custom Param"}
                               onAddRow={onAdd}
                               is_loading={is_loading}
                               columns={columns}
                               item_list={adminEditableEmailCustomParameterList}
        />
    )

}
