import { ItemList } from 'orm'
import { map } from 'lodash'

class FoodItemList extends ItemList {
    getEntityKey() {
        return "admin/food_item"
    }

    getAsOptions() {
        const items = this.getVisibleObjects()
        return map(items, function(item) {
          return { label: item.name, value: item.id }
        }
      )
    }
}

export const adminFoodItemList = new FoodItemList("admin_food_item__default")
export const adminFoodItemAutoCompleteList = new FoodItemList("admin_food_item_auto_complete__default")
