import { ItemList } from 'orm'
import { values, get, map, head, filter } from 'lodash'

class ContactMoneyDonationList extends ItemList {
    getEntityKey() {
        return "contact/money_donation"
    }

}
export const contactMoneyDonationList = new ContactMoneyDonationList("contact_money_donations__default")
