import React, { useState } from 'react'
import { makeStyles, Link } from '@material-ui/core'
import Alert from '@material-ui/lab/Alert'

const useStyles = makeStyles({
  infoAlert: {
    borderRadius: 0,
    fontWeight: 500,
    background: '#2196f3',
    color: '#ffffff',
    '& .MuiAlert-icon': {
      color: '#ffffff'
    }
  },
})

export function CovidNotice() {
  const classes = useStyles()
  const [showCovidNotice, setShowCovidNotice] = useState(true)

  return (
    <>
    { showCovidNotice &&
      <Alert severity="info" className={classes.infoAlert} onClose={() => setShowCovidNotice(false)}>
        Important notice: For up to date information about the Covid-19 pandemic visit <Link href="https://www.sacoronavirus.co.za" target="_blank" className={classes.infoAlert} rel="noreferrer">www.sacoronavirus.co.za</Link>
      </Alert>
    }
    </>
  )
}
