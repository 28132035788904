import React, { useState } from 'react'
import { makeStyles, FormControl, FormControlLabel, FormLabel, FormGroup, FormHelperText, Checkbox } from '@material-ui/core'

const useStyles = makeStyles({

})
export default function ReferralSource({helpText, formLabel}) {
  const classes = useStyles()
  let source = 'google'
  const [state, setState] = useState(source)

  const handleChange = (event) => {
    setState({ ...state, [event.target.name]: event.target.checked })
  }

  const getSourceCheckbox = (source) => {
    return(
      <FormControlLabel
        control={<Checkbox checked={state.source} onChange={handleChange} name={source.name} />}
        label={source.label}
      />
    )
  }

  return(
    <FormControl component="fieldset" className={classes.formControl}>
      {formLabel && <FormLabel component="legend">{formLabel}</FormLabel>}
      <FormGroup>
        {sources.map(source => (
          getSourceCheckbox(source)
        ))}
      </FormGroup>
      {helpText && <FormHelperText>{helpText}</FormHelperText>}
    </FormControl>
  )
}
