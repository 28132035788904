import { Grid, Typography, makeStyles } from "@material-ui/core"
import { get, map, round } from 'lodash'
import { getNutritionScore } from "../dashboard_sections/NutritionDashboardKpis"

const useStyles = makeStyles((theme) => ({
  nutrition_facts_box: {
    border: '1px solid #000',
    padding: 10
  },
  divider: {
    borderBottom: '3px solid #000',
    marginBottom: 100
  },
  divider1: {
    borderBottom: '1px solid #000'
  },
  divider3: {
    borderBottom: '3px solid #000'
  },
  divider5: {
    borderBottom: '5px solid #000'
  },
  divider10: {
    borderBottom: '10px solid #000'
  },
  bold500: {
    fontWeight: 500,
  },
  errors_heading: {
    fontWeight: 500,
    marginBottom: 15
  }
}))


export const renderCardFooter = () => {
  return (
    <Grid item xs={12}>
      <Typography align="justify" style={{ fontSize: 13, marginTop: 10 }}>
        The % Daily Value tells you how much a nutrient in a serving of food contributes to a daily diet. 2,000 calories a day is used for general nutrition advice.
      </Typography>
    </Grid>
  )
}

export const renderDivider = () => {
  // const classes = useStyles()
  return (
    <Grid item xs={12}>
      <div style={{ borderBottom: '10px solid #000' }}></div>
    </Grid>
  )
}

export const renderCardHeader = (calories, serving_weight_grams, nutrition_score) => {
  let num_items
  let plural = ""
  const { nutrition_score_alert, nutrition_score_color } = getNutritionScore(nutrition_score)
  return (
    <>
      <Grid item xs={12}>
        <Grid container justifyContent="space-between" alignItems="center">
          <Grid item>
            <Typography variant="h3" style={{ fontWeight: 500 }}>Nutrition Facts</Typography>
          </Grid>
          <Grid item>
            <Typography variant="h3" style={{ color: nutrition_score_color }}>{nutrition_score}</Typography>
          </Grid>
        </Grid>
        <div style={{ borderBottom: '1px solid #000' }}></div>
      </Grid>
      <Grid item xs={6}>
        <Typography style={{ fontWeight: 500 }}>Serving size</Typography>
      </Grid>
      <Grid item xs={6}>
        <Typography align="right" style={{ fontWeight: 500 }}>{num_items} meal{plural} ({serving_weight_grams}g)</Typography>
      </Grid>
      <Grid item xs={12}>
        <div style={{ borderBottom: '10px solid #000' }}></div>
      </Grid>

      <Grid item xs={12}>
        <Typography style={{ fontWeight: 500 }}>Amount per serving</Typography>
      </Grid>
      <Grid item xs={6}>
        <Typography variant="h4" style={{ fontWeight: 500 }}>Calories</Typography>
      </Grid>
      <Grid item xs={6}>
        <Typography variant="h4" style={{ fontWeight: 500 }} align="right">{calories}</Typography>
      </Grid>
      <Grid item xs={12}>
        <div className={{ borderBottom: '5px solid #000' }}></div>
      </Grid>
      <Grid item xs={12}>
        <Typography style={{ fontWeight: 500 }} align="right">% Daily Value *</Typography>
      </Grid>
      <Grid item xs={12}>
        <div style={{ borderBottom: '1px solid #000' }}></div>
      </Grid>
    </>
  )
}

export const renderCardNutrients = (nutrients) => {
  // const classes = useStyles()

  return (
    <>
      {map(nutrients, (n) => {


        let nutrient_percentage_rda = get(n, "nutrient_percentage") ? get(n, "nutrient_percentage") : get(n, "total_proportion_rda_per_meal") * 100
        if (!nutrient_percentage_rda) {
          nutrient_percentage_rda = 0
        }

        if (Number.isInteger(nutrient_percentage_rda)) {
          nutrient_percentage_rda = nutrient_percentage_rda.toString();
        } else if (nutrient_percentage_rda.toFixed(1).slice(-1) === "0") {
          nutrient_percentage_rda = nutrient_percentage_rda.toFixed(1).slice(0, -2)
        } else {
          nutrient_percentage_rda = round(nutrient_percentage_rda, 2);
        }

        return (
          <>
            <Grid item xs={9}>
              <Typography>
                <span style={{ fontWeight: 500 }}>
                  {get(n, ["nutrient", "short_name"])}
                </span> {round(get(n, "total_nutrient_value_per_meal"), 2)}{get(n, ["nutrient", "unit"])} (RDA is {get(n, ["nutrient", "rda"])})
              </Typography>
            </Grid>
            <Grid item xs={3}>
              <Typography align="right">{nutrient_percentage_rda}%</Typography>
            </Grid>
            <Grid item xs={12}>
              <div style={{ borderBottom: '1px solid #000' }}></div>
            </Grid>
          </>
        )
      })}
    </>
  )
}