import { adminBeneficiaryAutoCompleteList } from '../actions/admin_beneficiary'
import { FormikAutoCompleteDropdownWithFormBasedAddNew } from 'components/form/AutoCompleteDropdownWithFormBasedAddNew'
import AdminBeneficiaryForm from './AdminBeneficiaryForm'
import { validationSchema } from './AdminBeneficiaryForm'

export function FormikBeneficiaryAutoCompleteDropdown( { name, label, formik_props, ...props } ) {

	return (
		<FormikAutoCompleteDropdownWithFormBasedAddNew
			item_list={ adminBeneficiaryAutoCompleteList }
			success_message="Beneficiary added"
			form_title="New Parent Beneficiary Company"
			name={ name }
			validationSchema={ validationSchema }
			initial_values={ { company: { core_businesses: [], tags: [] } } }
			label={ label }
			getSavedObjectId={ ( value ) => value.company.id }
			renderForm={ ( { formik_props } ) =>
				<AdminBeneficiaryForm formik_props={ formik_props } in_drawer />
			}
			formik_props={ formik_props }
			{ ...props }
		/>
	)
}
