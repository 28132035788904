import React from 'react'
import { makeStyles, AppBar, Toolbar, alpha } from '@material-ui/core'
import { FormikInputBase } from 'components/form/InputBase'
import SearchIcon from '@material-ui/icons/Search'

const useStyles = makeStyles( ( theme ) => ( {
    appBar: {
        background: 'white',
    },
    root: {
        width: '100%',
    },
    toolBar: {
        borderTop: '1px solid lightgrey',
        marginTop: 0,
        padding: 0,
        paddingLeft: 0,
        paddingRight: 0
    },
    menuButton: {
        marginRight: theme.spacing( 2 ),
    },
    title: {
        flexGrow: 1,
        display: 'none',
        [ theme.breakpoints.up( 'sm' ) ]: {
            display: 'block',
        },
    },
    search: {
        position: 'relative',
        marginLeft: 0,
        width: '100%',
    },
    searchIcon: {
        padding: theme.spacing( 0, 2 ),
        height: '100%',
        position: 'absolute',
        pointerEvents: 'none',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        color: '#BDBDBD',
    },
    inputRoot: {
        color: 'inherit',
    },
    inputInput: {
        // vertical padding + font size from searchIcon
        paddingLeft: `calc(1em + ${ theme.spacing( 4 ) }px)`,
        transition: theme.transitions.create( 'width' ),
        color: '#666',
        width: '100%',
        fontSize: 24,
        fontWeight: 300,
    },
} ) )

export default function SearchBar( { formik_props } ) {
    const classes = useStyles()

    return (
        <div className={ classes.root }>
            <AppBar position="static" className={ classes.appBar } elevation={ 0 }>
                <Toolbar className={ classes.toolBar }>
                    <div className={ classes.search }>
                        <div className={ classes.searchIcon }>
                            <SearchIcon style={ { fontSize: 28 } } />
                        </div>
                        <FormikInputBase
                            key="any_field"
                            name="any_field"
                            shrink={ true }
                            placeholder="Search..."
                            autoComplete="off"
                            classes={ {
                                root: classes.inputRoot,
                                input: classes.inputInput,
                            } }
                            inputProps={ { 'aria-label': 'search' } }
                            formik_props={ formik_props }
                        />
                    </div>
                </Toolbar>
            </AppBar>
        </div>
    )
}
