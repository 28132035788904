import React, { Component } from 'react';
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { Route, Switch } from 'react-router-dom'
import BeneficiaryDashboard from '../dashboard/BeneficiaryDashboard'
import BeneficiaryParcels from '../orders/BeneficiaryParcels'
import BeneficiaryTickets from '../tickets/BeneficiaryTickets'
import BeneficiaryTicket from '../tickets/BeneficiaryTicket'
import BeneficiaryBranches from '../branches/BeneficiaryBranches'
import BeneficiaryBranch from '../branches/BeneficiaryBranch'
import BeneficiaryHelp from '../help/BeneficiaryHelp'
import Company from '../company/Company'
import { CompanyBranchesImport } from 'portals/beneficiary/components/branches/CompanyBranchesImport'
import Profile from '../user/Profile'
import ContactCompanyReports from '../reports/ContactCompanyReports'

import { isLoggedIn, loggedInUser } from 'actions/auth'

class BeneficiaryRouter extends Component {

    componentDidUpdate(prevProps) {
        const { is_logged_in, history, has_usable_password, is_superuser } = this.props
        if (!prevProps.is_logged_in && is_logged_in) {
            if (is_superuser) {
                history.push(`/beneficiary/dashboard`)
            } else {
                history.push('/')
            }
        }
        if (prevProps.is_logged_in && !is_logged_in) {
            history.push('/')
        }
    }

    render() {
        const { is_logged_in, is_superuser } = this.props
        if ( ! is_logged_in ) {
            return (
                <div>
                  Not logged in
                </div>
            )
        }

        return (
            <div>
              <Switch>
                <Route path="/beneficiary/help" component={ BeneficiaryHelp } />
                <Route path="/beneficiary/reports" component={ ContactCompanyReports } />
                <Route exact path="/beneficiary/branch/new" render={(props) => <Company is_new={true} {...props} /> } />
                <Route path="/beneficiary/settings/company" component={ Company } />
                <Route path="/beneficiary/user/profile" component={ Profile } />
                <Route path="/beneficiary/branches/import" children={ <CompanyBranchesImport /> }  />
                <Route path="/beneficiary/branches" component={ BeneficiaryBranches } />
                <Route path="/beneficiary/tickets/new" component={ BeneficiaryTicket } />
                <Route path="/beneficiary/tickets" component={ BeneficiaryTickets } />
                <Route path="/beneficiary/parcels" component={ BeneficiaryParcels } />
                <Route path="/beneficiary/dashboard" children={ <BeneficiaryDashboard /> }  />
                <Route path="/beneficiary/:company_id" component={ BeneficiaryDashboard } />
                <Route path="/beneficiary" component={ BeneficiaryDashboard } />
              </Switch>
            </div>
        )
    }
}

function mapStateToProps(state) {
    const is_logged_in = isLoggedIn()
    let has_usable_password = true
    let is_superuser = false
    if (is_logged_in) {
        const user = loggedInUser()
        has_usable_password = user && user['has_usable_password']
        is_superuser = user && user['is_superuser']
    }
    return {
        is_logged_in: is_logged_in,
        has_usable_password: has_usable_password,
        is_superuser: is_superuser
    }
}
export default withRouter(connect(mapStateToProps)(BeneficiaryRouter))
