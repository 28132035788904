import React, { useEffect } from 'react'
import clsx from 'clsx'
import { get } from 'lodash'
import { Grid, Paper, Typography, makeStyles } from '@material-ui/core'
import { Link } from 'react-router-dom'
import BusyMask from 'components/BusyMask'
import moment from 'moment'
import Alert from '@material-ui/lab/Alert'
import MealsDeliveredChart from './MealsDeliveredChart'
import TotalsWidgetBeneficiaries from './TotalsWidgetBeneficiaries'
import TotalsWidgetDonors from './TotalsWidgetDonors'
import TotalsWidgetMealsDelivered from './TotalsWidgetMealsDelivered'
import TotalsWidgetWeightDelivered from './TotalsWidgetWeightDelivered'
import OrdersSummaryTable from './OrdersSummaryTable'
import AdminMainLayout from '../layout/AdminMainLayout'
import { useDispatch, useSelector } from 'react-redux'
import { adminDashboardList, adminDashboardListForToday } from './actions/admin_dashboard'
import OrderStatusWidgets from 'admin/components/orders/widgets/OrderStatusWidgets'
import DashboardPopupFilter from 'admin/components/dashboard/DashboardPopupFilter'
import Skeleton from '@material-ui/lab/Skeleton'
import TotalsWidgetParcels from './TotalsWidgetParcels'

const useStyles = makeStyles( {
  todaysOrdersHeading: {
    marginTop: 15,
    fontSize: 28,
    color: '#607d8b'
  }
} )

export default function Dashboard( props ) {
  const classes = useStyles()
  const dispatch = useDispatch()

  const data = useSelector( () => adminDashboardList.getData() )
  const data_for_today = useSelector( () => adminDashboardListForToday.getData( { from_date: moment().format(), to_date: moment().format() } ) )
  const is_loading = useSelector( () => adminDashboardList.isLoading() )
  const is_loading_today = useSelector( () => adminDashboardListForToday.isLoading() )

  useEffect( () => {
    dispatch( adminDashboardListForToday.updateListFilter(
      {
        from_date: moment().format(),
        to_date: moment().format()
      }
    ) )
    dispatch( adminDashboardList.loadData() )
    dispatch( adminDashboardListForToday.loadData() )
  }, [] )

  return (
    <AdminMainLayout active_key="donors"
      breadcrumbs={ [ { name: 'admin_home' }, { name: 'dashboard', label: "Dashboard", url: '/admin' } ] }
      title="Dashboard"
    >
      <DashboardPopupFilter />

      <Grid container spacing={ 1 }>

        <>
          <Grid item xs={ 12 } sm={ 6 } lg={ 4 }>
            { is_loading ? <Skeleton animation="wave" variant="rect" height={ 100 } /> : <TotalsWidgetParcels data={ get( data, "totals_by_transport" ) } /> }
          </Grid>
          <Grid item xs={ 12 } sm={ 6 } lg={ 4 }>
            { is_loading ? <Skeleton animation="wave" variant="rect" height={ 100 } /> : <TotalsWidgetWeightDelivered data={ get( data, "weight_delivered" ) } /> }
          </Grid>

          <Grid item xs={ 12 } sm={ 6 } lg={ 4 }>
            { is_loading ? <Skeleton animation="wave" variant="rect" height={ 100 } /> : <TotalsWidgetMealsDelivered data={ get( data, "meals_delivered" ) } /> }
          </Grid>

          <Grid item xs={ 12 } sm={ 6 } lg={ 4 }>
            { is_loading ? <Skeleton animation="wave" variant="rect" height={ 100 } /> : <TotalsWidgetDonors data={ get( data, "donor_counts" ) } /> }
          </Grid>

          <Grid item xs={ 12 } sm={ 6 } lg={ 4 }>
            { is_loading ? <Skeleton animation="wave" variant="rect" height={ 100 } /> : <TotalsWidgetBeneficiaries data={ get( data, "beneficiary_counts" ) } /> }
          </Grid>

          { get( data_for_today, "totals_by_state" ) &&
            <>
              <Grid item xs={ 12 }>
                <Typography variant="h6" className={ classes.todaysOrdersHeading }>Today's Orders</Typography>
                <OrderStatusWidgets data={ get( data_for_today, "totals_by_state" ) } is_loading={ is_loading_today } />
              </Grid>

              <Grid item xs={ 12 }>
                { is_loading_today ? <Skeleton animation="wave" variant="rect" height={ 300 } /> : <OrdersSummaryTable is_today /> }
              </Grid>
            </>
          }

          { false &&
            <Grid item xs={ 12 } md={ 12 } lg={ 6 }>
              <MealsDeliveredChart data={ get( data, "beneficiary_meals_per_month" ) } />
            </Grid>
          }
        </>

      </Grid>
    </AdminMainLayout>
  )
}
