import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useParams } from 'react-router-dom'
import { get } from 'lodash'
import { adminManufacturerList } from './actions/admin_manufacturer'
import { Separator } from 'components/layout/Separator'
import { Formik, Form, Field } from 'formik'
import * as Yup from 'yup'
import {showSuccess, showError} from 'actions/Error'
import { handleSubmitResult } from 'actions/form'
import AdminCommonFormLayout from '../layout/AdminCommonFormLayout'
import { AdminManufacturerForm, validationSchema} from './form/AdminManufacturerForm'

export default function AdminManufacturer({ in_drawer }) {
    const dispatch = useDispatch()
    const history = useHistory()
    const { manufacturer_id } = useParams()
    const manufacturer = useSelector(() => adminManufacturerList.getObject(manufacturer_id))
    const initial_values = manufacturer
    const is_loading = useSelector(() => adminManufacturerList.isLoading()) || (manufacturer_id && !get(manufacturer, "id"))
    const is_busy = useSelector(() => adminManufacturerList.getIsSavingObject())
    const title = `${get(manufacturer, ["name"], 'New manufacturer')}`

    useEffect(() => {
        dispatch(adminManufacturerList.ensureObjectLoaded(manufacturer_id))
    },[])

    const onSave = (values, formik_funcs) => {
        const on_ok = function(json) {
            dispatch(adminManufacturerList.invalidateList())
            showSuccess("Saved", "Manufacturer saved")

            if ( ! manufacturer_id  && !in_drawer ) {
                history.push(`/admin/manufacturer/${json.id}`)
            }
        }
        if ( manufacturer_id ) {
            values.id = manufacturer_id
            return dispatch(adminManufacturerList.saveObject(values)).then((res) => handleSubmitResult({res, formik_funcs, on_ok}))
        } else {
            return dispatch(adminManufacturerList.saveNewObject(values)).then((res) => handleSubmitResult({res, formik_funcs, on_ok}))
        }
    }

    const getCellValue = (header_key, item, index) => {
        switch( header_key ) {
            default:
                return undefined
        }
    }

    return (
        <AdminCommonFormLayout
            breadcrumbs={[
                {name: 'admin_home'},
                {name: 'manufacturers', label: 'Manufacturers', url: '/admin/manufacturers'},
                {name: 'manufacturer', label: get(manufacturer, ["display_name"], 'New Manufacturer'), url: `/admin/manufacturer/${manufacturer_id}`}
            ]}
            is_busy={is_busy}
            is_loading={is_loading}
            title={title}
            initial_values={initial_values}
            validationSchema={validationSchema}
            onSave={onSave}
            noCard
            in_drawer={in_drawer}
        >
          {
              ({formik_props}) => <AdminManufacturerForm formik_props={ formik_props } in_drawer={ in_drawer } />
          }
        </AdminCommonFormLayout>
    )
}
