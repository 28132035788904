import React, { useState, useEffect } from 'react'
import { includes, head, filter, map, get } from 'lodash'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, Link } from 'react-router-dom'
import BeneficiaryMainLayout from '../layout/BeneficiaryMainLayout'
import { Separator } from 'components/layout/Separator'
import { DropzoneArea } from 'material-ui-dropzone'
import { FormikDropdownField } from 'components/form/Dropdown'
import { Error } from 'components/layout/Error'
import {showSuccess, showError} from 'actions/Error'
import { handleSubmitResult } from 'actions/form'
import { MuiFileUploader } from 'components/form/MuiFileUploader'
import {
  makeStyles,
  MenuItem,
  Paper,
  Typography,
  Button,
  TextField,
  IconButton,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell
} from '@material-ui/core'
import { FormikGeneralFormErrors } from 'components/form/GeneralFormErrors'
import DatePicker from "react-datepicker"
import PageTitle from '../layout/PageTitle'
import {Formik, Form, Field} from 'formik'
import { companyFileFieldMappingList } from './actions/company_file_field_mapping'
import Alert from '@material-ui/lab/Alert'
import { AttachFile, HelpOutlineIcon } from '@material-ui/icons'
import CancelOutlinedIcon from '@material-ui/icons/CancelOutlined';
import { AlertModal } from 'components/layout/Modal'

const useStyles = makeStyles({
    root: {
        padding: 15
    },
    alert: {
      marginBottom: 10
    },
    saveButton: {
      marginTop: 10,
      marginBottom: 10,
      fontSize: 24
    },
    successButton: {
      marginTop: 20,
      color: 'green',
      border: '3px solid green'
    },
    tableHead: {
      fontSize: 18,
      background: '#546e7a',
      color: 'white'
    }
})

export function CompanyFileImporter({field_mapping_type, field_mapping_options, checkFile, importFile, sampleFileUrl, minimum_fields}) {
    const classes = useStyles()
    const dispatch = useDispatch()
    const history = useHistory()
    const [processFileResult, setProcessFileResult] = useState(false)
    const [showProcessFileResult, setShowProcessFileResult] = useState(false)

    const acceptedFiles = [".xlsx, .xls, application/vnd.ms-excel"]

    const file_field_mappings = useSelector(() => companyFileFieldMappingList.getVisibleObjects())

    useEffect(() => {
        dispatch(companyFileFieldMappingList.updatePaginationNumItemsPerPage(1000))
        dispatch(companyFileFieldMappingList.updateListFilter({mapping_type: field_mapping_type}))
        dispatch(companyFileFieldMappingList.invalidateList())
        dispatch(companyFileFieldMappingList.fetchListIfNeeded())
    }, [processFileResult, field_mapping_type])

    const processFile = (values, formik_funcs) => {
        const on_ok = (json) => {
            setProcessFileResult(json)
        }
        dispatch(checkFile(values)).then((res) => handleSubmitResult({res, formik_funcs, on_ok}))
    }

    const initialValues = {}

    const onChangeFieldMapping = (evt, new_value, mapping) => {
        const v = get(new_value, ["props", "value"])
        const on_ok = function(json) {
            showSuccess("Saved", `Saved ${mapping.foodspace_column_name}`)
        }
        const values = Object.assign({}, mapping, {import_column_name: v })
        return dispatch(companyFileFieldMappingList.saveObject(values)).then((res) => on_ok(res))
    }

    const onShowProcessFileResult = () => {
        setShowProcessFileResult(true)
    }

    const onHideProcessFileResult = () => {
        setShowProcessFileResult(false)
    }

    const renderDropzoneText = () => {
        return (
            <>
              <Typography variant="h5">Upload an Excel file</Typography>
              {minimum_fields &&
                <>
                <Typography variant="caption">Minimum information:</Typography><br />
                <Typography>{minimum_fields}</Typography>
                </>
              }
              <Typography variant="caption">We recommend that you download and use the EXCEL template (link below)</Typography>
            </>
        )
    }

    const renderFieldMappingForm = () => {
        return (
            <>
              <Separator height={10} />
              <Typography variant="h5">Column mappings</Typography>
              <Separator height={10} />
              <div style={{marginLeft: -24, marginRight: -24}}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell className={classes.tableHead}>Database Column</TableCell>
                    <TableCell className={classes.tableHead}>Column in imported excel file</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  { map(file_field_mappings, function(mapping) {
                      const field_mapping_option = head(filter(field_mapping_options, (option) => option.value === mapping.foodspace_column_name))
                      return (
                          <TableRow>
                            <TableCell>
                              {get(field_mapping_option, "label")}
                              {get(field_mapping_option, ["original_item", "required"]) === true && <span> (* required)</span>}
                            </TableCell>
                            <TableCell>
                              <TextField
                                select={true}
                                label={mapping.foodspace_column_name}
                                value={mapping.import_column_name}
                                onChange={(evt, new_value) => onChangeFieldMapping(evt, new_value, mapping)}
                              >
                                {map(processFileResult.headers, (header) => (
                                    <MenuItem key={header} value={header}>
                                      {header}
                                    </MenuItem>
                                ))}
                              </TextField>
                            </TableCell>
                          </TableRow>
                      )}
                       )}
                </TableBody>
              </Table>
              </div>
            </>
        )
    }

    const onImportFile = () => {
        importFile(processFileResult.file_info.id)
    }

    const renderExcelDownload = () => {
      return (
          <div style={{margin: '10px 0'}}><a href={sampleFileUrl}>Click here to download an Excel template.</a></div>
      )
    }

    const renderCheckFileButton = (label) => {
      label = label || "Check Uploaded File"
      return(
        <div style={{textAlign: 'center', marginBottom: 30}}>
          <Typography variant="h3">So far, so good!</Typography>
          <Typography>
            Now click that big pink button and let's take a look at that file.
          </Typography><br />
          <Button
            type="submit"
            variant="contained"
            color="secondary"
            size="large"
            className={classes.saveButton}
            onClick={onShowProcessFileResult}
           >
             { label }
           </Button>
           <Typography>{ get(processFileResult, ["file_info", "visible_filename"]) }</Typography>
         </div>
      )
    }

    const renderOrderUploadForm = () => {
        return (
            <Formik
              initialValues={initialValues}
              onSubmit={processFile}
              enableReinitialize={true}
            >
              {formik_props => {
                  const { values } = formik_props
                  return (
                      <Form>

                        <FormikGeneralFormErrors render={(msg) => msg} />

                        { !get(formik_props, ["values", "file_info"]) ?

                          <>
                            <MuiFileUploader name="file_info"
                                             acceptedFiles={acceptedFiles}
                                             filesLimit={1}
                                             showPreviews={true}
                                             showPreviewsInDropzone={false}
                                             useChipsForPreview
                                             previewGridProps={{container: { spacing: 1, direction: 'row' }}}
                                             previewChipProps={{classes: { root: classes.previewChip } }}
                                             previewText="Selected files"
                                             maxFileSize={5000000}
                                             dropzoneText = {renderDropzoneText()}
                                             formik_props={formik_props}
                                             Icon={AttachFile}
                            />
                            { renderExcelDownload() }
                          </> :
                          <>{ renderCheckFileButton() }</>
                        }
                      </Form>
                  )}}
            </Formik>
        )
    }

    const renderImportFileFileReadyToImport = () => {
        return (
            <AlertModal severity="success" onClose={onHideProcessFileResult}>
              <div style={{textAlign: 'center', marginBottom: 30}}>
                <Typography variant="h3">Amazing!</Typography>
              <Typography>
                <Typography variant="h6">{ get(processFileResult, ["file_info", "visible_filename"]) } looks good</Typography>
                Everything looks super sweet and we are ready to rumble. <br /><br />
                You know the drill,<br />
                Click the big pink button and let the good times roll!!<br />
              <Typography variant="caption">(last one, I promise)</Typography>
              </Typography><br />


                <Button
                  type="submit"
                  variant="contained"
                  color="secondary"
                  size="large"
                  className={classes.saveButton}
                  onClick={onImportFile}
                 >Import Now</Button>
              </div>
            </AlertModal>
        )
    }

    const renderImportFileColumnMappingErrors = () => {
        return (
            <AlertModal onClose={onHideProcessFileResult}>
              Some columns are not mapped.<br />
              Please map the columns.<br />
              Then click the "Check Uploaded File Button"
              {renderFieldMappingForm()}
              { renderCheckFileButton() }
            </AlertModal>
        )
    }

    const renderImportFileColumnMissingErrors = () => {
        return (
            <AlertModal
              onClose={onHideProcessFileResult}
              alert_text={
                  <Typography style={{fontSize: 18, marginBottom: 15}}>
                    Some columns weren't found in the Excel you are trying to import.<br />
                    Please check you uploaded the correct Excel file.<br /><br />
                  </Typography>
              }
            >
              <ul>
                {map(get(processFileResult, "missing_columns"), (missing_column) => (
                    <li>
                      <Typography style={{fontSize: 24}}>{missing_column}</Typography>
                    </li>
                ))}
              </ul>
              { renderFieldMappingForm() }<br />
              { renderCheckFileButton("Check File Again") }<br />
              { renderExcelDownload() }<br />
            </AlertModal>
        )
    }

    const renderImportFileRowErrors = () => {
        return (
            <AlertModal onClose={onHideProcessFileResult}>
              <ul>
                {map(get(processFileResult, "row_errors"), (row_error) => (
                    <li>
                      <ul>
                        {map(row_error, (cell_error) => (
                            <Typography>{JSON.stringify(cell_error)}</Typography>
                        ))}
                      </ul>
                    </li>
                ))}
              </ul>
              <div style={{textAlign: 'center', marginTop: 20}}>
                <Button
                  color="secondary"
                  variant="contained"
                  size="large"
                  onClick={() => history.push("/donor/settings/company_products")}
                >
                  Add Products
                </Button>
              </div>
            </AlertModal>
        )
    }

    const renderImportFileGeneralErrors = () => {
        return (
            <Error>{processFileResult.error}</Error>
        )
    }

    return (
        <div>

          { (!showProcessFileResult || !processFileResult) && renderOrderUploadForm() }
          { showProcessFileResult && get(processFileResult, "status") === "success" && renderImportFileFileReadyToImport() }
          { showProcessFileResult && get(processFileResult, "error") === 'column_mappings' && renderImportFileColumnMappingErrors() }
          { showProcessFileResult && get(processFileResult, "error") === 'missing_columns' && renderImportFileColumnMissingErrors() }
          { showProcessFileResult && get(processFileResult, "error") === 'rows' && renderImportFileRowErrors() }
          { showProcessFileResult && get(processFileResult, "error") === 'rows' && renderImportFileRowErrors() }
          { ! includes(["column_mappings", "missing_column", "rows"], get(processFileResult, "error")) && renderImportFileGeneralErrors() }

        </div>
    )
}
