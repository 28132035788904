import React, { useEffect, useState } from "react"
import { adminParcelLogList } from './actions/admin_parcel_log'
import { get } from 'lodash'
import AdminStateHistoryList from '../layout/AdminStateHistoryList'
import Timestamp from 'components/Timestamp'
import { useDispatch } from 'react-redux'
import { makeStyles} from '@material-ui/core/styles'
import { Modal } from 'components/layout/Modal'

const useStyles = makeStyles((theme) => ({
    image: { maxWidth: "100px",
             maxHeight: "100px" }
}))

export const AdminDriverAppLog = ({ parcel_id, show_as_table }) => {

    const classes = useStyles()
    const dispatch = useDispatch()
    const item_list = adminParcelLogList
    const [showImage, setShowImage] = useState(false)
    const [imgData, setImgData] = useState(false)

    const renderImageModal = (item) => {
        setImgData(item)
        setShowImage(true)
    }

    const handleCloseImage = () => {
        setImgData(null)
        setShowImage(false)
    }

    useEffect(() => {
        async function fetchStaticObjects() {
            dispatch(item_list.updateListFilter({parcel: parcel_id}))
        }
        fetchStaticObjects()
    }, [parcel_id])

    const columns = [
        { field: 'timestamp',
          title: 'Timestamp',
          render: (item) => <Timestamp value={item.timestamp} format='datetime' />
        },
        { field: 'image_file_info',
          title: 'Image',
          render: function(item) {
              if ( item.image_file_info ) {
                  return <img
                    className={classes.image}
                    src={item.image_file_info.original_download_url}
                    onClick={() => renderImageModal(item)}
                    style={{cursor: 'pointer'}}
                    alt={item.image_file_info.visible_filename} />
              } else {
                  return null
              }
          }
        },
        { field: 'comment', title: 'Comment'},
        { field: 'temperature_celcius', title: 'Temp °C'},
        { field: 'temperature_note', title: 'Temp Note'},
        { field: 'created_by_user',
          title: 'User',
          render: (item) => item.created_by_user_display_name
        },

    ]

    return (
        <>
            <AdminStateHistoryList
                item_list={item_list}
                columns={columns}
                title="Order Log"
                active_key={'Parcel log'}
                show_as_table={ show_as_table }
            />
            { showImage &&
                <Modal
                    onClose={handleCloseImage}
                    title={get(imgData, ["image_file_info", "visible_filename"])}
                >
                    <img
                        src={get(imgData, ["image_file_info", "original_download_url"])}
                        alt={get(imgData, ["image_file_info", "visible_filename"])}
                    />
                </Modal>
            }
        </>
    )
}
