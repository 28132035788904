import { ItemList } from 'orm'

class KpiDashboardUserList extends ItemList {
    getEntityKey() {
        return "admin/kpi_dashboard_user"
    }

}

export const adminKpiDashboardUserList = new KpiDashboardUserList("admin_kpi_dashboard_user__default")
