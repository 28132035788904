import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useParams } from 'react-router-dom'
import { forEach, head, get, map, keys, split, size } from 'lodash'
import { adminDonorList } from './actions/admin_donor'
import { FormikGeneralFormErrors } from 'components/form/GeneralFormErrors'
import { AdminDonorStateHistory } from './AdminDonorStateHistory'
import { ReportDownloadLink } from 'components/layout/ReportDownloadLink'
import { FormikDonorAutoCompleteDropdown } from 'admin/components/donors/form/DonorAutoCompleteDropdown'
import {showSuccess, showError} from 'actions/Error'
import { handleSubmitResult } from 'actions/form'
import AdminCommonFormLayout from '../layout/AdminCommonFormLayout'
import { validationSchema } from './form/AdminDonorForm'
import AdminCommonCompany from '../companies/AdminCommonCompany'
import Loading from 'components/Loading'

export default function AdminDonor() {
    const dispatch = useDispatch()
    const history = useHistory()
    const { donor_id } = useParams()
    const donor = useSelector(() => adminDonorList.getObject(donor_id))
    const is_loading = useSelector(() => adminDonorList.isLoading())

    const [initialValues, setInitialValues] = useState(Object.assign({}, {company: {core_businesses: [], tags: []}}, donor))

    const title = `${get(donor, ["company", "name"], 'New donor')}`

    useEffect(() => {
      dispatch(adminDonorList.ensureObjectLoaded(donor_id))
    }, [donor_id])

    useEffect(() => {
        setInitialValues(Object.assign({}, {company: {core_businesses: [], tags: []}}, donor))
    }, [get(donor, "id")])

    const is_busy = useSelector(() => adminDonorList.getIsSavingObject())

    const onSave = (values, formik_funcs) => {

        const on_ok = function(json) {
            dispatch(adminDonorList.invalidateList())
            showSuccess("Saved", "Donor saved")
            if ( ! donor_id ) {
                history.push(`/admin/donor/${json.id}`)
            }
        }
        if ( donor_id ) {
            values.id = donor_id
            return dispatch(adminDonorList.saveObject(values)).then((res) => handleSubmitResult({res, formik_funcs, on_ok}))
        } else {
            return dispatch(adminDonorList.saveNewObject(values)).then((res) => handleSubmitResult({res, formik_funcs, on_ok}))
        }

    }

    const onDownloadDonorReport = () => {
        adminDonorList.downloadDonorReport(donor_id)
    }

    return (
        <AdminCommonFormLayout breadcrumbs={[{name: 'admin_home'},
                                             {name: 'donors', label: 'Donors', url: '/admin/donors'},
                                             {name: 'donor', label: get(donor, ["company", "name"], 'New donor'), url: `/admin/donor/${donor_id}`}
                                            ]}
                               actions={[<ReportDownloadLink onClick={onDownloadDonorReport} tooltip="Download Impact Report" />]}
                               is_busy={is_busy}
                               title={title}
                               is_loading={is_loading}
                               initial_values={initialValues}
                               validationSchema={validationSchema}
                               onSave={onSave}>

          {
              ({formik_props}) =>
                  <>

                    <FormikGeneralFormErrors />
                    { is_loading ? <Loading message="Loading donor details..." /> :
                    <AdminCommonCompany
                      company_id={get(donor, ["company", "id"])}
                      formik_props={formik_props}
                      form_variation={'donor'}
                      state_history={<AdminDonorStateHistory donor_id={donor_id} />}
                    />
                    }
                  </>
          }
        </AdminCommonFormLayout>
    )

}
