import { ItemList } from 'orm'
import { map, filter, values } from 'lodash'

class AdminCoreBusinessList extends ItemList {
    getEntityKey() {
        return "admin/core_business"
    }

}

export const adminCoreBusinessList = new AdminCoreBusinessList("admin_core_business__default")
