import React, { useEffect, useState } from 'react'
import { Grid, Button } from '@material-ui/core'
import { FormikDateTimePicker } from 'components/form/DatetimePicker'
import { useDispatch, useSelector } from 'react-redux'
import { FormikMultiSelectDropdownField, FormikDropdownField } from 'components/form/Dropdown'
import { cityList } from 'actions/city'
import { adminBeneficiaryList } from 'admin/components/beneficiaries/actions/admin_beneficiary'
import UndoIcon from '@material-ui/icons/Undo'

export default function NutritionDashboardFilterForm({ formik_props }) {

    const dispatch = useDispatch()

    const city_options = useSelector(() => cityList.getAsOptions())
    const beneficiary_options = useSelector(() => adminBeneficiaryList.getAsOptions())

    useEffect(() => {
        async function fetchStaticObjects() {
            dispatch(cityList.fetchListIfNeeded())
            dispatch(adminBeneficiaryList.fetchListIfNeeded())
        }
        fetchStaticObjects()
    }, [])

    useEffect(() => {
        if(formik_props.values.cities) {
            dispatch(adminBeneficiaryList.invalidateList())
            dispatch(adminBeneficiaryList.updateListFilter({city: formik_props.values.cities}))
            dispatch(adminBeneficiaryList.fetchListIfNeeded())
        }
      }, [formik_props.values.cities])

    return (
        <>
            <Grid container spacing={2} justifyContent="space-between" direction="row" alignItems="baseline">

                <Grid item xs={12} sm={6} lg={3}>
                    <FormikMultiSelectDropdownField 
                        name="cities"
                        label="Region"
                        options={city_options}
                        formik_props={formik_props}
                     />
                </Grid>

                <Grid item xs={12} sm={6} lg={3}>
                    <FormikDropdownField
                        name="to_company"
                        label="Beneficiary"
                        options={beneficiary_options}
                        formik_props={formik_props}
                    />
                </Grid>

                <Grid item xs={12} sm={6} lg={3}>
                    <FormikDateTimePicker
                        name="start_at"
                        label="Start Date"
                        include_time={false}
                        format={'yyyy-MM-dd'}
                        formik_props={formik_props}
                    />
                </Grid>

                <Grid item xs={12} sm={6} lg={3}>
                    <FormikDateTimePicker
                        name="end_at"
                        label="End Date"
                        include_time={false}
                        format={'yyyy-MM-dd'}
                        formik_props={formik_props}
                    />
                </Grid>

            </Grid>

            <Button
                text="Cancel"
                startIcon={<UndoIcon />}
                variant="contained"
                color="default"
                onClick={() => {
                    formik_props.resetForm({ values: {} })
                }}
            />
        </>

    )
}