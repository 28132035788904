import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { FormikDropdownField } from 'components/form/Dropdown'
import { adminWarehouseProductFilterQuantityTypes } from 'admin/actions/admin_dropdown_options'

export const WarehouseProductQuantityTypeFilter = ({ name, formik_props, fullWidth, label = "Stock Availability" }) => {
    const quantity_type_options = useSelector(() => adminWarehouseProductFilterQuantityTypes.getAsOptions())
    const dispatch = useDispatch()

    useEffect(() => {
        async function fetchStaticObjects() {
            dispatch(adminWarehouseProductFilterQuantityTypes.fetchListIfNeeded())
        }
        fetchStaticObjects()
    }, [])

    return (
        <div style={{ marginTop: 15 }}>
            <FormikDropdownField
                name={name || "quantity_type"}
                options={quantity_type_options}
                formik_props={formik_props}
                label={ label }
                fullWidth={ fullWidth }
                size="small"
            />
        </div>
    )
}
