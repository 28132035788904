import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { handleSubmitResult } from 'actions/form'
import {showSuccess, showError} from 'actions/Error'
import { get, map } from 'lodash'
import * as Yup from 'yup'
import { adminKpiResultCommentList } from '../actions/admin_kpi_result_comment'
import { FormikInputField } from 'components/form/InputField'
import { FormikGeneralFormErrors } from 'components/form/GeneralFormErrors'
import { Formik, Form } from 'formik'
import FormCard from 'components/layout/FormCard'
import { KpiResultCommentSingle } from '../AdminKpiResultComment'
import { Separator } from 'components/layout/Separator'
import { adminKpiResultList } from '../actions/admin_kpi_result'

export const validationSchema = Yup.object().shape({
    'comment': Yup.string().required("Required"),
})

const AdminKpiCommentForm = ({ kpi_result, comment_id, onSaved }) => {
    const dispatch = useDispatch()
    const initial_values = {}
    const kpi_result_id = get(kpi_result, "id")
    kpi_result = useSelector(() => adminKpiResultList.getObject(kpi_result_id))
    const comments = get(kpi_result, "comments")

    useEffect(() => {
        dispatch(adminKpiResultList.ensureObjectLoaded())
    },[kpi_result])

    const renderForm = (formik_props) => {
        return(
            <>
            <FormikInputField
                name="comment"
                label="Comment"
                multiline
                rows={3}
                formik_props={formik_props}
            />
            <Separator height={15} />
            </>
        )
    }

    const onSave = (values, formik_funcs) => {
        values.kpi_result = kpi_result_id
        const on_ok = function(json) {
            dispatch(adminKpiResultCommentList.invalidateList())
            showSuccess("Saved", "Comment saved")
            formik_funcs.resetForm()
            onSaved()
            if ( ! kpi_id ) {
                alert('You must pass a valid KPI Result ID')
            }
        }
        if ( comment_id ) {
            return dispatch(adminKpiResultCommentList.saveObject(values)).then((res) => handleSubmitResult({res, formik_funcs, on_ok}))
        } else {
            return dispatch(adminKpiResultCommentList.saveNewObject(values)).then((res) => handleSubmitResult({res, formik_funcs, on_ok}))
        }
    }

    return (
        <>
        {map(comments, (comment) => {
            return <KpiResultCommentSingle comment_id={ comment } />
        })}
        <Formik
            initialValues={ initial_values }
            onSubmit={ onSave }
            enableReinitialize={true}
            validationSchema={ validationSchema }
            >
            { formik_props => {
                const { values } = formik_props
                return (
                    <Form>
                        <FormCard noPadding isSubmitting={formik_props.isSubmitting}>
                            { renderForm(formik_props) }
                        </FormCard>
                    </Form>
                )
            }}

        </Formik>
        </>

)
}

export default AdminKpiCommentForm
