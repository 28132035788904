import { Grid } from "@material-ui/core"
import { get } from 'lodash'
import { FormikSwitchField } from 'components/form/SwitchField'
import { FormikInputField } from 'components/form/InputField'
import { FormikFileSetField } from 'components/form/FileSetField'

export const BeneficiaryNpoDssFields = ({formik_props}) => {
  return (
    <Grid container spacing={2}>

      <Grid item xs={12}>
        <FormikSwitchField name="is_registered_npo"
                           label="Registered non profit organisation (CIPC)"
                           formik_props={formik_props}
        />
      </Grid>

      { get(formik_props, ["values", "is_registered_npo"], false) &&
          <Grid item xs={12} lg={6}>
            <FormikInputField name="npo_number"
                              type="text"
                              label="NPO Number"
                              formik_props={formik_props}
            />
            <FormikFileSetField max_num_files={1} name="npo_certificate_file" formik_props={formik_props} label={"NPO certificate"} />
          </Grid>
      }
      <Grid item xs={12}>
        <FormikSwitchField name="is_registered_dsd"
                           label="Registered withe the Department of Social Development?"
                           formik_props={formik_props}
        />
      <FormikFileSetField max_num_files={1} name="dsd_certificate_file" formik_props={formik_props} label={"DSD certificate"} />
      </Grid>

      { get(formik_props, ["values", "is_registered_dsd"], false) &&
        <Grid item xs={12} lg={6}>
          <FormikInputField name="dsd_registration_number"
                            type="text"
                            label="Department of Social Development Number"
                            formik_props={formik_props}
          />
        </Grid>
      }
    </Grid>
  )
}
