import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useParams } from 'react-router-dom'
import { get, map } from 'lodash'
import { adminFeedingScheduleList } from './actions/admin_feeding_schedule'
import { showSuccess, showError } from 'actions/Error'
import { handleSubmitResult } from 'actions/form'
import AdminCommonFormLayout from '../layout/AdminCommonFormLayout'
import AdminCommonForm from '../layout/AdminCommonForm'
import { AdminFeedingScheduleForm, validationSchema } from './form/AdminFeedingScheduleForm'

export default function AdminFeedingSchedule( { in_modal = false, onClose = () => { }, passed_feeding_schedule_id = null, company_id = null } ) {
  const dispatch = useDispatch()
  const history = useHistory()
  const { feeding_schedule_id: params_feeding_schedule_id } = useParams()
  const feeding_schedule_id = passed_feeding_schedule_id || params_feeding_schedule_id
  const feeding_schedule = useSelector( () => adminFeedingScheduleList.getObject( feeding_schedule_id ) )
  const initial_values = feeding_schedule
  const is_loading = useSelector( () => adminFeedingScheduleList.isLoading() ) || ( feeding_schedule_id && !get( feeding_schedule, "id" ) )
  const is_busy = useSelector( () => adminFeedingScheduleList.getIsSavingObject() )
  const title = `${ get( feeding_schedule, [ "frequency" ], 'New Feeding Schedule' ) }`

  useEffect( () => {
    dispatch( adminFeedingScheduleList.ensureObjectLoaded( feeding_schedule_id ) )
  }, [] )

  const onSave = ( values, formik_funcs ) => {

    if ( company_id ) {
      values.company = company_id
    }

    values.days_of_week =  values.days_of_week ? map(get(values, 'days_of_week', []), Number) : []
    values.days_of_month = values.days_of_month ? map(get(values, 'days_of_month', []), Number) : []

    const on_ok = function( json ) {
      dispatch( adminFeedingScheduleList.invalidateList() )
      showSuccess( "Saved", "Feeding Schedule saved" )

      if ( !feeding_schedule_id ) {
        if ( in_modal ) {
          onClose()
        } else {
          history.push( `/admin/feeding_schedule/${ json.id }` )
        }
      } else if ( in_modal ) {
        onClose()
      }

    }
    if ( feeding_schedule_id ) {
      values.id = feeding_schedule_id
      return dispatch( adminFeedingScheduleList.saveObject( values ) ).then( ( res ) => handleSubmitResult( { res, formik_funcs, on_ok } ) )
    } else {
      return dispatch( adminFeedingScheduleList.saveNewObject( values ) ).then( ( res ) => handleSubmitResult( { res, formik_funcs, on_ok } ) )
    }
  }

  if ( in_modal ) {
    return (
      <AdminCommonForm
        is_loading={ is_loading }
        is_busy={ is_busy }
        initial_values={ initial_values }
        inModal={ true }
        onSave={ onSave }>
        {
          ( { formik_props } ) => {
            return <AdminFeedingScheduleForm formik_props={ formik_props } company_id={ company_id } in_modal={ in_modal } />
          }
        }
      </AdminCommonForm>
    )
  }

  return (
    <AdminCommonFormLayout
      breadcrumbs={ [
        { name: 'admin_home' },
        { name: 'feeding_schedules', label: 'Feeding Schedules', url: '/admin/feeding_schedules' },
        { name: 'feeding_schedule', label: get( feeding_schedule, [ "name" ], 'New Feeding Schedule' ), url: `/admin/feeding_schedule/${ feeding_schedule_id }` }
      ] }
      is_busy={ is_busy }
      is_loading={ is_loading }
      title={ title }
      initial_values={ initial_values }
      validationSchema={ validationSchema }
      onSave={ onSave }
    >
      {
        ( { formik_props } ) => <AdminFeedingScheduleForm formik_props={ formik_props } />
      }
    </AdminCommonFormLayout>
  )
}
