import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { head, get ,sortBy, startCase, camelCase } from 'lodash'
import { Trans, Translation } from 'react-i18next'
import { FormikDropdownField } from 'components/form/Dropdown'
import AdminCommonListLayout from '../layout/AdminCommonListLayout'
import Timestamp from 'components/Timestamp'
import { FormikInputField } from 'components/form/InputField'
import { adminLogisticsCompanyList } from './actions/admin_logistics_company'
import { Separator } from 'components/layout/Separator'
import { ShortId } from 'components/ShortId'
import { countryList } from 'actions/country'
import { provinceList } from 'actions/province'
import { handleSubmitResult } from 'actions/form'
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord'
import { Chip, Tooltip, Button } from '@material-ui/core'
import { getContentStatus } from 'components/companies/RenderTableCellContent'

export default function AdminLogisticsCompanies() {
    const dispatch = useDispatch()
    const history = useHistory()

    const is_loading = useSelector(() => adminLogisticsCompanyList.isLoading())

    const donors = useSelector(() => adminLogisticsCompanyList.getVisibleObjects())
    const country_options = useSelector(() => countryList.getAsOptions())
    const province_options = useSelector(() => provinceList.getAsOptions())

    useEffect(() => {
        dispatch(adminLogisticsCompanyList.fetchListIfNeeded())
        dispatch(countryList.updatePaginationNumItemsPerPage(1000))
        dispatch(provinceList.updatePaginationNumItemsPerPage(1000))
        dispatch(countryList.fetchListIfNeeded())
        dispatch(provinceList.fetchListIfNeeded())
    }, [])

    const onCountryChanged = (country_id) => {
      dispatch(provinceList.filterOnCountry(country_id))
    }

    const onUpdateListOrdering = (field) => {
        dispatch(adminLogisticsCompanyList.updateListOrdering(field))
    }

    const onEditLogisticsCompany = (logistics_company_id) => {
        history.push({
            pathname: '/admin/logistics_company/'+logistics_company_id
        })
    }

    const onAddLogisticsCompany = () => {
        history.push('/admin/logistics_company')
    }

    const addTrip = (company_id) => {}

    const columns = [
        { field: 'id', title: 'Id',
          width: 80,
          render: (item) => <ShortId value={item.id} />,
          sort: (direction) => onUpdateListOrdering(direction === 'asc' ? 'id' : '-id'),
          visible: true
        },
        { field: 'name',
          title: 'Logistics Partner',
          render: (item) => <a onClick={() => onEditLogisticsCompany(item.id)}>{startCase(get(item, ["company", "name"]))}</a>,
          sort: (direction) => onUpdateListOrdering(direction === 'asc' ? 'company__name' : '-company__name'),
          visible: true
        },
        { field: 'parent', title: 'Parent Company',
          render: (item) => get(item, ["company", "parent_name"]),
          sort:(direction) => onUpdateListOrdering(direction === 'asc' ? 'company__parent__name' : '-company__parent__name')
        },
        { field: 'logistics_partner_type',
          title: 'Type',
          sort:(direction) => onUpdateListOrdering(direction === 'asc' ? 'logistics_partner_type' : '-logistics_partner_type'),
          visible: true
        },
        { field: 'company.city_name',
          title: 'City',
          sort:(direction) => onUpdateListOrdering(direction === 'asc' ? 'company__addresses__city__name' : '-company__addresses__city__name'),
          visible: true
        },
        {
          field: 'total_trips_count',
          title: 'Total Trips',
          sort:(direction) => onUpdateListOrdering(direction === 'asc' ? 'total_trips_count' : '-total_trips_count'),
          visible: true
        },
        {
          field: 'total_trips_distance_km',
          title: 'Total Distance (km)',
          sort:(direction) => onUpdateListOrdering(direction === 'asc' ? 'total_trips_distance_km' : '-total_trips_distance_km'),
          visible: true
        },
        {
          field: 'total_trips_value_rand',
          title: 'Total Value (R)',
          sort:(direction) => onUpdateListOrdering(direction === 'asc' ? 'total_trips_value_rand' : '-total_trips_value_rand'),
          visible: true
        },
        {
          field: 'last_trip_date',
          title: 'Last Trip',
          sort:(direction) => onUpdateListOrdering(direction === 'asc' ? 'last_trip_date' : '-last_trip_date'),
          visible: true
        },
        {
          field: 'last_trip_distance_km',
          title: 'Last Trip Distance (km)',
          sort:(direction) => onUpdateListOrdering(direction === 'asc' ? 'last_trip_distance_km' : '-last_trip_distance_km'),
        },
        {
          field: 'last_trip_value_rand',
          title: 'Last Trip Value (R)',
          sort:(direction) => onUpdateListOrdering(direction === 'asc' ? 'last_trip_value_rand' : '-last_trip_value_rand'),
        },
        { field: 'content_status',
          title: 'Content',
          render: (item) => getContentStatus(item),
          //sort:(direction) =>  onUpdateListOrdering(direction === 'asc' ? 'content_status' : '-content_status'),
        },
        { field: 'status',
          title: 'Status',
          width: 50,
          render: (item) => get(item, ["company", "status"]),
          sort:(direction) =>  onUpdateListOrdering(direction === 'asc' ? 'company__status' : '-company__status'),
          visible: true
        },
        { field: 'created_at',
          title: 'Created at',
          render: (item) => <Timestamp value={item.created_at} format='date'/>,
          sort: (direction) => onUpdateListOrdering(direction === 'asc' ? 'created_at' : '-created_at')
        },
        { field: '',
          title: 'New Trip',
          align: 'center',
          width: 100,
          render: (item) => <Button onClick={() => addTrip(item.id)} variant="outlined" size="small">Create</Button>,
          visible: true
        },
    ]

    return (
        <AdminCommonListLayout
            active_key="logistics_partners"
            breadcrumbs={[
                {name: 'admin_home'},
                {name: 'logistics_partners', label: "Logistics Partners", url: '/admin/logistics_partners'}
            ]}
            add_label="Add Logistics Partner"
            title="Logistics Partners"
            onAddRow={onAddLogisticsCompany}
            onEditRow={onEditLogisticsCompany}
            is_loading={is_loading}
            columns={columns}
            handleRequestSort={columns}
            enableAnyFieldFilter={true}
            item_list={adminLogisticsCompanyList}
            storeTableName={"adminLogisticsCompanyList"}
            useColumnsSelector={true}
        />
    )
}
