import { ItemList } from 'orm'

class KpiCalculatedList extends ItemList {
    getEntityKey() {
        return "admin/kpi_calculated"
    }

}

export const adminKpiCalculatedList = new KpiCalculatedList("admin_kpi_calculated__default")
