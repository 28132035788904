import React from 'react'
import { adminManufacturerAutoCompleteList } from '../actions/admin_manufacturer'
import { FormikAutoCompleteDropdownWithFormBasedAddNew } from 'components/form/AutoCompleteDropdownWithFormBasedAddNew'
import AdminManufacturer from '../AdminManufacturer'
import { validationSchema } from './AdminManufacturerForm'


export function FormikManufacturerAutoCompleteDropdown( { name, label, formik_props, ...props } ) {

	return (
		<FormikAutoCompleteDropdownWithFormBasedAddNew
			item_list={ adminManufacturerAutoCompleteList }
			success_message="Manufacturer added"
			form_title="New Manufacturer"
			name={ name }
			validationSchema={ validationSchema }
			label={ label }
			getSavedObjectId={ ( value ) => value.id }
			renderForm={ ( { formik_props } ) =>
				<AdminManufacturer in_drawer />
			}
			formik_props={ formik_props }
			{ ...props }
		/>
	)
}