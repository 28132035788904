import React from 'react'
import Timestamp from 'components/Timestamp'
import { makeStyles } from '@material-ui/core/styles'
import { get } from 'lodash'
import { FormikInputField } from 'components/form/InputField'
import { Error } from 'components/layout/Error'
import bringg_logo from 'assets/images/bringg_logo.svg'
import { Card } from 'components/layout/Card'
import { StatusIndicator } from 'components/layout/StatusIndicator'

const useStyles = makeStyles((theme) => ({
    logo: {
        marginLeft: -115,
        marginBottom: -5,
        width: 150
    },
    sync_datetime: {
        display: "flex",
        fontSize: "14px"
    },
    error: {
      color: 'red'
    },
    success: {
      color: 'green'
    },
    statusIcon: {
      marginBottom: -10
    },
    cardHeading: {
      padding: "5px 10px",
      textIndent: 115
    }
}))

export function FormikBringgStatus({formik_props, field_prefix, can_edit_bringg_ref}) {
    const classes = useStyles()
    let values = get(formik_props, "values")
    if ( field_prefix ) {
        values = get(values, field_prefix)
    }
    const bringg_ref = get(values, "bringg_ref")
    const bringg_updated_at = get(values, "bringg_updated_at")
    const bringg_last_status = get(values, "bringg_last_status")

    let field_name = "bringg_ref"
    if ( field_prefix ) {
        field_name = field_prefix + "." + field_name
    }

    const bringg_status = formik_props.values.driver_name ?'success':  'error'

    return (
        <Card
            title={ <><StatusIndicator status={bringg_ref ? 'success' : 'error'} style={{marginBottom: -4}} /> Bringg Status</> }
            content={
                <>
                    <div>
                        {bringg_ref ? `Bringg Ref ${bringg_ref}` : "Bringg ref not set yet" }
                    </div>
                    { can_edit_bringg_ref === true &&
                        <FormikInputField
                            name={field_name}
                            label="Bringg ref"
                            formik_props={formik_props}
                            touch_on_edit={true}
                        />
                    }

                    <div className={classes.sync_datetime}>
                        Last successful sync to Bringg &nbsp;<Timestamp format="from_now" value={bringg_updated_at} /> &nbsp;(<Timestamp value={bringg_updated_at} />)
                    </div>
                    { bringg_last_status &&
                        <Error>
                            Last Bringg Sync Message: {bringg_last_status}
                        </Error>
                    }
                </>
            }
        />
    )
}
