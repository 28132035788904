import React, { Component } from 'react'
import { connect } from 'react-redux'
import { head, get, join } from 'lodash'
import { Trans, Translation } from 'react-i18next'
import { FormikDropdownField } from 'components/form/Dropdown'
import AdminCommonListLayout from '../layout/AdminCommonListLayout'
import Timestamp from 'components/Timestamp'
import { FormikInputField } from 'components/form/InputField'
import { adminUserList } from './actions/admin_user'
import { Separator } from 'components/layout/Separator'
import { ShortId } from 'components/ShortId'
import { Button } from 'components/layout/Button'
import placeholder_profile_pic from 'assets/images/placeholder_profile_pic.jpg'
import Avatar from '@material-ui/core/Avatar'

class Users extends Component {

    componentDidMount() {
        const { dispatch } = this.props
        dispatch(adminUserList.fetchListIfNeeded())
    }

    componentDidUpdate() {
        const { dispatch, filter } = this.props
        dispatch(adminUserList.fetchListIfNeeded())
    }

    onEditUser = (user_id) => {
        const { history } = this.props
        history.push({
            pathname: '/admin/user/'+user_id
        })
    }

    onAddUser = () => {
        const { history } = this.props
        history.push('/admin/user')
    }

    onUpdateListOrdering = (field) => {
        const { dispatch } = this.props
        dispatch(adminUserList.updateListOrdering(field))
    }

    sendMail = (email) => {
        window.open(`mailto: ${email}`)
    }

    render() {
        const { is_loading, users } = this.props
        const that = this

        const columns = [
            { field: 'profile_image',
              title: 'Image',
              visible: true,
              render: (item) => <Avatar
                                  alt={`${get(item, 'first_name')} ${get(item, 'last_name')}`}
                                  src={get(item, ['profile_image', 'original_download_url'])}
                                  style={{width: 60, height: 60, cursor: 'pointer'}}
                                  onClick={() => this.onEditUser(get(item, 'id'))}
                                />,
              sort: (direction) => this.onUpdateListOrdering(direction === 'asc' ? 'id' : '-id'),
            },
            { field: 'first_name',
              title: 'Name',
              visible: true,
              render: (item) => <a onClick={() => this.onEditUser(get(item, 'id'))}>{get(item, 'first_name')} {get(item, 'last_name')}</a>,
              sort:(direction) => null
            },
            { field: 'City',
              title: 'Jurisdiction',
              visible: true,
              render: (item) => get(item, ["user_filter", "city_names"])
            },
            { field: 'email',
              title: 'Email',
              visible: true,
              render: (item) => get(item, ["email"]) ? <a target="_blank" onClick={() => this.sendMail(get(item, ["email"]))}>{get(item, ["email"])}</a> : '',
              sort:(direction) => null
            },
            { field: 'phone_number',
              title: 'Phone',
              visible: true,
              sort:(direction) => null
            },
            { field: 'roles',
              title: 'Roles',
              visible: true,
              render: (item) => join(get(item, ["employee", "roles"], []), ", "),
              sort:(direction) => null
            },
            { field: 'created_at',
              title: 'Created at',
              render: (item) => <Timestamp value={item.created_at} format='date' />,
              sort: (direction) => this.onUpdateListOrdering(direction === 'asc' ? 'created_at' : '-created_at')
            },
        ]

        return (
            <AdminCommonListLayout active_key="users"
                                   breadcrumbs={[{name: 'admin_home'},
                                                 {name: 'users', label: "Users", url: '/users'}]}
                                   add_label="Add User"
                                   title="Users"
                                   onAddRow={that.onAddUser}
                                   onEditRow={that.onEditUser}
                                   is_loading={is_loading}
                                   columns={columns}
                                   item_list={adminUserList}
                                   enableAnyFieldFilter={true}
                                   storeTableName={"adminUserList"}
                                   useColumnsSelector={true}
            />
        )
    }
}

function mapStateToProps(state, props) {
    const users = adminUserList.getVisibleObjects()

    return {
        users,
        is_loading: adminUserList.isLoading(),
        filter: adminUserList.getFilter()
    }
}

export default connect(mapStateToProps)(Users)
