import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useParams, Link } from 'react-router-dom'
import { get, capitalize, size, map } from 'lodash'
import { adminParcelList } from 'admin/components/orders/actions/admin_parcel'
import moment from 'moment'
import FormCard from 'components/layout/FormCard'
import { adminPublishedTagList } from 'admin/components/tags/actions/admin_tag'
import { AdminDriverAppLog } from './AdminDriverAppLog'
import { Formik, Form, Field } from 'formik'
import Loading from 'components/Loading'
import { FormikGeneralFormErrors } from 'components/form/GeneralFormErrors'
import AdminMainLayout from '../layout/AdminMainLayout'
import AdminBeneficiaryParcelProducts from './AdminBeneficiaryParcelProducts'
import { AdminBeneficiaryParcelCopy } from './AdminBeneficiaryParcelCopy'
import BusyMask from 'components/BusyMask'
import { AdminParcelStateHistory } from './AdminParcelStateHistory'
import { AdminParcelDriverHistory } from './AdminParcelDriverHistory'
import * as Yup from 'yup'
import { showSuccess, showError } from 'actions/Error'
import { handleSubmitResult, shouldShowOnDemandSaveButton } from 'actions/form'
import { makeStyles } from '@material-ui/core/styles'
import { Grid, Typography, Divider, Button, Tooltip, IconButton, Hidden } from '@material-ui/core'
import { provinceList } from 'actions/province'
import Icon from '@mdi/react'
import { mdiContentDuplicate } from '@mdi/js'
import { mdiTextBoxSearchOutline } from '@mdi/js'
import { BasicMapWithMarker } from 'components/map/MapMarkers'
import { Card } from 'components/layout/Card'
import Signature from 'components/Signature'
import {
  OrderStateForm,
  OrderDeliveryNoteForm,
  BeneficiaryGeneralOrderForm,
  OrderDriverForm,
  OrderBringgForm,
  OrderSection18A,
  OrderTotalOverrides
} from './form'
import AdminParcelTotalWeight from './AdminParcelTotalWeight'
import { NutritionFactsParcel } from 'admin/components/nutrition/NutritionFactsParcel'
import NutrientBarChart from 'admin/components/nutrition/dashboard_sections/NutrientBarChart'
import { useAxios } from 'hooks/api/useAxios'


const validationSchema = Yup.object().shape( {
  'to_company': Yup.string().required( "Required" ),
  'depart_warehouse': Yup.string().required( "Required" )
} )

const useStyles = makeStyles( ( theme ) => ( {
  root: {
    display: 'flex',
  },
  card: {
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
  },
  fixedHeight: {
    height: 240,
  },
  actionBar: {
    display: "flex",
    justifyContent: "space-between"

  },
  cardHeading: {
    padding: "5px 10px",
  },
  customerWillTransportHeading: {
    textAlign: 'center',
    color: theme.palette.secondary.main,
    padding: '30px 0',
    fontSize: 24,
    textTransform: 'capitalize'
  },
  titleSubHeading: {
    textTransform: 'capitalize'
  },
  alert: {
    marginBottom: 10
  },
  title_status_indicator: {
    paddingTop: 5
  }
} ) )

export default function AdminBeneficiaryParcel( { match, companyType, is_new } ) {
  const classes = useStyles()
  let { parcel_id } = useParams()
  const dispatch = useDispatch()
  const { response: nutrition_data, error: nutrition_errors, loading: nutrition_loading, refetch: refetchNutritionData } = useAxios( {
    url: `admin/parcel_nutrition_card/${ parcel_id }/`
  } )
  const url_filter = new window.URLSearchParams( window.location.search )
  let transport_action = window.location.href.indexOf( "collection" ) > -1 ? 'collection' : 'dropoff'
  const direction = companyType + '_' + transport_action
  const parcel = useSelector( () => adminParcelList.getObject( parcel_id ) )
  const province_options = useSelector( () => provinceList.getAsOptions() )
  let is_loading = parcel_id && !get( parcel, "id" )
  const is_busy = useSelector( () => adminParcelList.getIsSavingObject() )
  let customer = companyType
  if ( parcel ) {
    transport_action = get( parcel, [ "transport_by_warehouse_company" ] ) ? 'dropoff' : 'collection'
  }
  const initial_values = direction == 'beneficiary_dropoff' ? Object.assign( { status: 'pending', transport_by_warehouse_company: true }, parcel ) : Object.assign( { status: 'pending', transport_by_warehouse_company: false }, parcel )
  const history = useHistory()
  const [ copyParcelActive, setCopyParcelActive ] = useState( false )
  let parcel_weight = get( parcel, "total_weight_kg" )
  const [ totalParcelWeight, setTotalParcelWeight ] = useState( parcel_weight )
  const tag_options = useSelector( () => adminPublishedTagList.getAsOptions() )

  useEffect( () => {
    async function fetchStaticObjects() {
      is_loading = dispatch( adminParcelList.ensureObjectLoaded( parcel_id ) )
      dispatch( adminParcelList.fetchListIfNeeded() )
      dispatch( provinceList.updatePaginationNumItemsPerPage( 1000 ) )
      dispatch( provinceList.fetchListIfNeeded() )
      dispatch( adminPublishedTagList.updatePaginationNumItemsPerPage( 100 ) )
      dispatch( adminPublishedTagList.updateListFilter( { published: true } ) )
      dispatch( adminPublishedTagList.fetchListIfNeeded() )
    }
    fetchStaticObjects()
  }, [ parcel_id ] )


  useEffect( () => {
    setTotalParcelWeight( parcel_weight )
  }, [ parcel_weight ] )

  const onSave = ( values, formik_funcs ) => {
    const on_ok = function( json ) {
      dispatch( adminParcelList.invalidateList() )
      dispatch( adminParcelList.fetchListIfNeeded() )
      showSuccess( "Saved", `${ capitalize( customer ) } ${ capitalize( transport_action ) } saved` )

      if ( !parcel_id ) {
        history.push( `/admin/orders/beneficiary/${ json.id }` )
      }
    }
    values.direction = 'beneficiary_dropoff'
    if ( parcel_id ) {
      values.id = parcel_id
      return dispatch( adminParcelList.saveObject( values ) ).then( ( res ) => handleSubmitResult( { res, formik_funcs, on_ok } ) )
    } else {
      return dispatch( adminParcelList.saveNewObject( values ) ).then( ( res ) => handleSubmitResult( { res, formik_funcs, on_ok } ) )
    }
  }

  const onStartCopyParcel = () => {
    setCopyParcelActive( true )
  }

  const onStopCopyParcel = () => {
    setCopyParcelActive( false )
  }

  const handleTransportByWarehouseCompanyChange = ( event ) => {
    setTransportByWarehouseCompany( event.target.checked )
  }

  const renderOrderOverview = () => {
    return (
      <>
        <Typography variant="h6">{ get( parcel, [ "to_company_name" ], 'New' ) }</Typography>
        <Typography>Order ID: { get( parcel, [ "short_ref" ] ) }</Typography>
        <Typography>Customer Type: { capitalize( customer ) } { parcel.arrive_at && <> - { moment( parcel.arrive_at ).format( "dddd, MMMM Do YYYY, h:mm a" ) }</> }</Typography>
      </>
    )
  }

  const renderCopyParcel = () => {
    return (
      <AdminBeneficiaryParcelCopy
        original_parcel_id={ parcel_id }
        onClose={ onStopCopyParcel }
      />
    )
  }

  const updateCartTotalWeight = ( updated_total_parcel_weight ) => {
    setTotalParcelWeight( updated_total_parcel_weight )
  }

  const renderBeneficiaryGeneralForm = ( formik_props, transport_by_warehouse_company ) => {
    const is_open = !get( parcel, "id" )
    const parcel_title = parcel_id ? `${ formik_props.values.to_company_name }: ${ moment( formik_props.values.arrive_at ).format( 'dddd, D MMMM YYYY, h:mm a' ) }` : `New ${ capitalize( companyType ) } ${ transport_action }`
    return (
      <Card
        title={ parcel_title }
        is_open={ is_open }
        content={
          <BeneficiaryGeneralOrderForm formik_props={ formik_props } transport_action={ transport_action } />
        }
      />
    )
  }

  const renderTransportAction = ( formik_props ) => {
    return (
      <Card
        title="Transport"
        content={
          <Typography
            variant="h6"
            className={ classes.customerWillTransportHeading }
          >
            { customer } { transport_action }
          </Typography>
        }
        noPadding
      />
    )
  }

  const renderOrderStatus = ( formik_props ) => {
    let order_status
    if ( formik_props.values.status == 'complete' ) { order_status = 'success' }
    if ( formik_props.values.status == 'order_cancelled' ) { order_status = 'error' }
    return (
      <Card
        title="Status"
        status={ order_status }
        content={ <OrderStateForm formik_props={ formik_props } parcel={ parcel } /> }
        card_actions={ <AdminParcelStateHistory parcel_id={ parcel_id } title="Status History" /> }
      />
    )
  }

  const renderAddressMap = ( formik_props ) => {
    return (
      <>
        { false &&
          <Card
            title="Address on Map"
            content={ <BasicMapWithMarker lat={ mapLat } lng={ mapLng } height={ 300 } showDefaultUI /> }
          />
        }
      </>
    )
  }

  const renderDriverForm = ( formik_props ) => {
    const driver_status = formik_props.values.driver_name ? 'success' : 'error'
    return (
      <Card
        title="Driver"
        status={ driver_status }
        content={ <OrderDriverForm formik_props={ formik_props } /> }
        card_actions={ <><AdminParcelDriverHistory parcel_id={ parcel_id } title="Driver History" /> <AdminDriverAppLog parcel_id={ parcel_id } /></> }
      />
    )
  }

  const renderBringgForm = ( formik_props ) => {
    return (
      <OrderBringgForm formik_props={ formik_props } />
    )
  }

  const renderDriverAppLog = ( formik_props ) => {
    return (
      <Card
        title="Driver App Log"
        content={ <AdminDriverAppLog parcel_id={ parcel_id } show_as_table /> }
        noPadding
        is_open={ false }
      />
    )
  }

  const renderDeliveryNoteForm = ( formik_props ) => {
    const delivery_notes_status = get( formik_props.values.delivery_notes, [ 'id' ] ) ? 'success' : 'error'
    return (
      <Card
        title="Delivery Note"
        status={ delivery_notes_status }
        content={ <OrderDeliveryNoteForm formik_props={ formik_props } /> }
      />
    )
  }

  const renderBeneficiarySignature = ( formik_props ) => {
    const to_signature_status = formik_props.values.to_signature ? 'success' : 'error'
    return (
      <Card
        title="Beneficiary Signature"
        status={ to_signature_status }
        content={ <Signature name="to_signature" formik_props={ formik_props } /> }
      />
    )
  }

  const renderOrderDuplicateTrackButtons = () => {
    return (
      <Grid container justifyContent="space-between">
        <Grid item>
          <Button onClick={ onStartCopyParcel } size="small">
            <Icon path={ mdiContentDuplicate } size={ 1 } color="#777777" style={ { marginRight: 7 } } /> Duplicate Order
          </Button>
        </Grid>
        <Grid item>
          <Button component={ Link } to={ `/admin/orders/beneficiary/${ parcel_id }/tracking_report` } size="small">
            <Icon path={ mdiTextBoxSearchOutline } size={ 1 } color="#777777" style={ { marginRight: 7 } } /> Tracking Report
          </Button>
        </Grid>
      </Grid>
    )
  }

  const renderParcelProducts = () => {
    return (
      <Card
        title="Products"
        content={
          <>
            <AdminParcelTotalWeight total_parcel_weight={ totalParcelWeight } />
            <AdminBeneficiaryParcelProducts
              parcel_id={ parcel_id }
              onUpdate={ onUpdateParcelProducts }
              updateCartTotalWeight={ updateCartTotalWeight }
            />
          </>
        }
        noPadding
      />
    )
  }

  const onUpdateParcelProducts = () => {
    refetchNutritionData()
    // window.location.reload()
  }

  const renderNutritionCard = () => {
    return (
      <Card
        title="Nutrition Information"
        is_open
        noPadding
        content={ nutrition_loading ? <Loading /> :

          <>
            { ( nutrition_data || nutrition_errors ) &&
              <NutritionFactsParcel
                data={ nutrition_data }
                errors={ nutrition_errors }
                loading={ nutrition_loading }
                onUpdate={ onUpdateParcelProducts }
              />
            }

            { !nutrition_loading && nutrition_data &&
              <>
                <NutrientBarChart
                  title="Macro Nutrient Values per Meal"
                  subheader="Values are shown per meal"
                  chart={ {
                    labels: map( get( nutrition_data, [ "macro_nutrients" ] ), ( d ) => d.nutrient.short_name ),
                    series: [
                      {
                        name: '% of RDA',
                        type: 'bar',
                        data: map( get( nutrition_data, "macro_nutrients" ), ( d ) => d.nutrient_percentage ),
                      },
                    ],
                  } }
                />
                <NutrientBarChart
                  title="Micro Nutrient Values per Meal"
                  subheader="Values are shown per meal"
                  chart={ {
                    labels: map( get( nutrition_data, [ "micro_nutrients" ] ), ( d ) => d.nutrient.short_name ),
                    colors: [ 'black', 'black' ],
                    series: [
                      {
                        name: '% of RDA',
                        type: 'bar',
                        data: map( get( nutrition_data, "micro_nutrients" ), ( d ) => d.nutrient_percentage ),
                      },
                    ],
                  } }
                />
              </>
            }

          </>
        }
      />
    )
  }

  return (
    <AdminMainLayout
      title={ `${ capitalize( customer ) } ${ capitalize( transport_action ) } / Order ID: ${ get( parcel, [ "short_ref" ], 'New order' ) }` }
      breadcrumbs={ [
        { name: 'admin_home' },
        { name: 'parcels', label: 'Orders', url: '/admin/parcels' },
        { name: 'parcel', label: `${ capitalize( customer ) } Order: ${ get( parcel, [ "short_ref" ], 'New' ) }`, url: `/admin/orders/beneficiary/${ parcel_id }` }
      ] }
    >
      { parcel_id && copyParcelActive && renderCopyParcel() }

      <Formik
        initialValues={ initial_values }
        onSubmit={ onSave }
        enableReinitialize={ true }
        validationSchema={ validationSchema }
      >
        { formik_props => {
          const { values } = formik_props
          const transport_by_warehouse_company = get( formik_props.values, "transport_by_warehouse_company" )

          transport_action = transport_by_warehouse_company ? 'Dropoff' : 'Collection'

          return (
            <Form>
              <FormCard
                isSubmitting={ formik_props.isSubmitting }
                variant="outlined"
                positionFixed
                noCard
              >
                <FormikGeneralFormErrors render={ ( msg ) => msg } />

                <Grid container spacing={ 1 } justifyContent="center">

                  <Grid item xs={ 12 }>
                    { is_busy && <BusyMask /> }
                    { is_loading && <Loading /> }
                    { parcel_id && renderOrderDuplicateTrackButtons() }
                  </Grid>

                  <Grid item xs={ 12 } lg={ 8 }>
                    { !is_loading && renderBeneficiaryGeneralForm( formik_props, transport_by_warehouse_company ) }
                    { parcel_id && renderParcelProducts() }
                    { parcel_id && ( totalParcelWeight > 0 ) && ( nutrition_data || nutrition_errors ) && renderNutritionCard() }
                    { parcel_id && transport_by_warehouse_company && renderDriverAppLog( formik_props ) }
                  </Grid>

                  { parcel_id &&
                    <Grid item xs={ 12 } lg={ 4 }>
                      { renderOrderStatus( formik_props ) }
                      { !transport_by_warehouse_company && renderTransportAction( formik_props ) }
                      { renderAddressMap( formik_props ) }
                      { transport_by_warehouse_company &&
                        <>
                          { renderDriverForm( formik_props ) }
                          { renderBringgForm( formik_props ) }
                        </>
                      }
                      { renderDeliveryNoteForm( formik_props ) }
                      { renderBeneficiarySignature( formik_props ) }
                    </Grid>
                  }
                </Grid>
              </FormCard>
            </Form>
          )
        } }
      </Formik>
    </AdminMainLayout>
  )
}