import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import AdminCommonListLayout from '../layout/AdminCommonListLayout'
import AdminCommonListTable from '../layout/AdminCommonListTable'
import { adminFeedingScheduleList } from './actions/admin_feeding_schedule'
import { get, capitalize, join } from 'lodash'
import AdminFeedingSchedule from './AdminFeedingSchedule'
import { Modal } from 'components/layout/Modal'
import { Separator } from 'components/layout/Separator'
import { daysOfWeek } from 'utils/formatDateTime'
import { AdminFeedingCalendar } from './AdminFeedingCalendar'

export default function AdminFeedingSchedules({ company_id, inTabs = false }) {

  const dispatch = useDispatch()
  const history = useHistory()

  const feeding_schedules = useSelector(() => adminFeedingScheduleList.getVisibleObjects())
  const is_loading = useSelector(() => adminFeedingScheduleList.isLoading())
  const as_table = inTabs ? true : false
  const [ openModal, setOpenModal ] = useState( false )
  const [ editingFeedingScheduleId, setEditingFeedingScheduleId ] = useState( null )

  const openEditModal = ( feeding_schedule_id ) => {
    setEditingFeedingScheduleId( feeding_schedule_id )
    setOpenModal( true )
  }

  const closeEditModal = () => {
    setEditingFeedingScheduleId( null )
    dispatch( adminFeedingScheduleList.invalidateList() )
    dispatch( adminFeedingScheduleList.fetchListIfNeeded() )
    setOpenModal( false )
  }

  const onEditFeedingSchedule = ( feeding_schedule_id ) => {
    openEditModal( feeding_schedule_id )
  }

  const onAddFeedingSchedule = () => {
    openEditModal( null ) // pass null to indicate we're creating a new demographic
  }

  useEffect(() => {
    if (company_id) {
      dispatch(adminFeedingScheduleList.updateListFilter({ company: company_id }))
    }
    dispatch(adminFeedingScheduleList.fetchListIfNeeded())
  }, [])

  const columns = []

  if(!company_id) {
    columns.push(
      {
        field: 'company_name',
        title: 'Company',
        sort: (direction) => onUpdateListOrdering(direction === 'asc' ? 'company__name' : '-company__name'),
      }
    )
  }

  columns.push(
    {
      field: 'mealtime_name',
      title: 'Meal',
      render: (item) => capitalize(get(item, 'mealtime_name', '')),
      sort: (direction) => onUpdateListOrdering(direction === 'asc' ? 'mealtime' : '-mealtime')
    },
    {
      field: 'feeding_frequency_name',
      title: 'Frequency',
      render: (item) => capitalize(get(item, 'feeding_frequency_name', '')),
      sort: (direction) => onUpdateListOrdering(direction === 'asc' ? 'feeding_frequency_name' : '-feeding_frequency_name'),
    },
    {
      field: 'days_of_week',
      title: 'Days of Week',
      render: (item) => daysOfWeek(get(item, 'days_of_week', [])),
    },
    {
      field: 'days_of_month',
      title: 'Days of Month',
      render: (item) => join(get(item, 'days_of_month', []), ', '),
    },
  )

  const onUpdateListOrdering = (field) => {
    dispatch(adminFeedingScheduleList.updateListOrdering(field))
  }

  const renderModal = () => {
    return (
      <Modal title="Add/Edit Feeding Frequency" onClose={ closeEditModal }>
        <Separator height={ 15 } />
        <AdminFeedingSchedule in_modal={ true } onClose={ closeEditModal } passed_feeding_schedule_id={ editingFeedingScheduleId } company_id={ company_id } />
      </Modal>
    )
  }

  if (inTabs) {
    return (
      <>
      <AdminCommonListTable
        add_label="Add"
        title="Feeding Schedule"
        onAddRow={onAddFeedingSchedule}
        onEditRow={onEditFeedingSchedule}
        is_loading={is_loading}
        handleRequestSort={columns}
        columns={columns}
        item_list={adminFeedingScheduleList}
        inTabs
      />
      <AdminFeedingCalendar schedules={feeding_schedules} />
      { openModal && renderModal() }
      </>
    )
  } else {
    return (
      <AdminCommonListLayout
        active_key="feeding_schedule"
        breadcrumbs={[
          { name: 'admin_home' },
          { name: 'feeding_schedule', label: "Feeding Schedule", url: '/admin/feeding_frequencies' }
        ]}
        add_label="Add Schedule"
        title={"Feeding Schedule"}
        onAddRow={onAddFeedingSchedule}
        onEditRow={onEditFeedingSchedule}
        is_loading={is_loading}
        columns={columns}
        item_list={adminFeedingScheduleList}
        enableAnyFieldFilter={true}
      >
        { openModal && renderModal() }
      </AdminCommonListLayout>
    )
  }
}
