import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { forEach, head, get, map, keys, split, size } from 'lodash'
import { adminLogisticsCompanyList } from './actions/admin_logistics_company'
import { Separator } from 'components/layout/Separator'
import { Formik, Form, Field } from 'formik'
import { FormikGeneralFormErrors } from 'components/form/GeneralFormErrors'
import { AdminLogisticsCompanyStateHistory } from './AdminLogisticsCompanyStateHistory'
import { ReportDownloadLink } from 'components/layout/ReportDownloadLink'
import { FormikLogisticsCompanyAutoCompleteDropdown } from 'admin/components/logistics/form/LogisticsCompanyAutoCompleteDropdown'
import {showSuccess, showError} from 'actions/Error'
import { handleSubmitResult } from 'actions/form'
import AdminCommonFormLayout from '../layout/AdminCommonFormLayout'
import { validationSchema } from './form/AdminLogisticsCompanyForm'
import Loading from 'components/Loading'
import AdminCommonCompany from '../companies/AdminCommonCompany'

export default function AdminLogisticsCompany(props) {
    const dispatch = useDispatch()
    const history = useHistory()
    const logistics_company_id = get(props, ["match", "params", "logistics_company_id"], null)
    const logistics_company = useSelector(() => adminLogisticsCompanyList.getObject(logistics_company_id))
    const is_loading = useSelector(() => adminLogisticsCompanyList.isLoading())
    let initial_values = logistics_company
    initial_values = Object.assign({}, {company: {core_businesses: [], tags: []}}, initial_values)

    const title = `${get(logistics_company, ["company", "name"], 'New Logistics Partner')}`

    useEffect(() => {
      dispatch(adminLogisticsCompanyList.ensureObjectLoaded(logistics_company_id))
    }, [])

    const is_busy = useSelector(() => adminLogisticsCompanyList.getIsSavingObject())

    const onSave = (values, formik_funcs) => {

        const on_ok = function(json) {
            dispatch(adminLogisticsCompanyList.invalidateList())
            showSuccess("Saved", "Logistics Partner saved")
            if ( ! logistics_company_id ) {
                history.push(`/admin/logistics_company/${json.id}`)
            }
        }
        if ( logistics_company_id ) {
            values.id = logistics_company_id
            return dispatch(adminLogisticsCompanyList.saveObject(values)).then((res) => handleSubmitResult({res, formik_funcs, on_ok}))
        } else {
            return dispatch(adminLogisticsCompanyList.saveNewObject(values)).then((res) => handleSubmitResult({res, formik_funcs, on_ok}))
        }

    }

    const onDownloadLogisticsCompanyReport = () => {
        adminLogisticsCompanyList.downloadLogisticsCompanyReport(logistics_company_id)
    }

    return (
        <AdminCommonFormLayout breadcrumbs={[{name: 'admin_home'},
                                             {name: 'logistics_companies', label: 'Logistics Partners', url: '/admin/logistics_companies'},
                                             {name: 'logistics_company', label: get(logistics_company, ["company", "name"], 'New Logistics Partner'), url: `/admin/logistics_company/${logistics_company_id}`}
                                            ]}
                               actions={[<ReportDownloadLink onClick={onDownloadLogisticsCompanyReport} tooltip="Download beneficiary list" />]}
                               is_busy={is_busy}
                               title={title}
                               is_loading={is_loading}
                               initial_values={initial_values}
                               validationSchema={validationSchema}
                               onSave={onSave}>

          {
              ({formik_props}) =>
                  <>

                    <FormikGeneralFormErrors />

                    <AdminCommonCompany
                      company_id={get(logistics_company, ["company", "id"])}
                      formik_props={formik_props}
                      form_variation={'logistics_company'}
                      state_history={<AdminLogisticsCompanyStateHistory logistics_company_id={logistics_company_id} />}
                    />
                  </>
          }
        </AdminCommonFormLayout>
    )

}
