import React, { Component } from 'react'
import { connect } from 'react-redux'
import { forEach, head, get, map, keys, split, size } from 'lodash'
import { adminStartingValueList } from './actions/admin_starting_value'
import { Separator } from 'components/layout/Separator'
import { Formik, Form, Field } from 'formik'
import * as Yup from 'yup'
import {showSuccess, showError} from 'actions/Error'
import { handleSubmitResult } from 'actions/form'
import AdminCommonFormLayout from 'admin/components/layout/AdminCommonFormLayout'
import { FormikInputField } from 'components/form/InputField'
import AdminStartingValueForm from './form/AdminStartingValueForm'
import { validationSchema } from './form/AdminStartingValueForm'

class AdminStartingValue extends Component {

    componentDidMount() {
        const { dispatch, starting_value_id } = this.props
        dispatch(adminStartingValueList.ensureObjectLoaded(starting_value_id))
    }

    componentDidUpdate(prev_props) {
        const { dispatch, starting_value_id } = this.props
        dispatch(adminStartingValueList.ensureObjectLoaded(starting_value_id))
    }

    onSave = (values, formik_funcs) => {
        const { history, onSubmit, dispatch, starting_value_id } = this.props

        const on_ok = function(json) {
            dispatch(adminStartingValueList.invalidateList())
            showSuccess("Saved", "Starting Value saved")

            if ( ! starting_value_id ) {
                history.push(`/admin/starting_value/${json.id}`)
            }
        }
        if ( starting_value_id ) {
            values.id = starting_value_id
            return dispatch(adminStartingValueList.saveObject(values)).then((res) => handleSubmitResult({res, formik_funcs, on_ok}))
        } else {
            return dispatch(adminStartingValueList.saveNewObject(values)).then((res) => handleSubmitResult({res, formik_funcs, on_ok}))
        }
    }

    getCellValue = (header_key, item, index) => {
        switch( header_key ) {
            default:
                return undefined
        }
    }

    render() {
        const { initial_values, is_loading, is_busy, starting_value, starting_value_id } = this.props
        const that = this
        const title = `${get(starting_value, "name", 'New starting value')}`

        return (
            <AdminCommonFormLayout breadcrumbs={[{name: 'admin_home'},

                                                 {name: 'starting_values',
                                                  label: 'Starting values',
                                                  url: '/admin/starting_values'},

                                                 {name: 'starting_value',
                                                  label: get(starting_value, "name", 'New starting value'),
                                                  url: `/admin/starting_value/${starting_value_id}`
                                                 }
                                                ]}
                                   is_busy={is_busy}
                                   is_loading={is_loading}
                                   title={title}
                                   initial_values={initial_values}
                                   validationSchema={validationSchema}
                                   onSave={this.onSave}>

              {
                  ({formik_props}) => <AdminStartingValueForm formik_props={formik_props} />
              }
            </AdminCommonFormLayout>
        )
    }

}

function mapStateToProps(state, props) {
    const starting_value_id = get(props, ["match", "params", "starting_value_id"], null)
    const starting_value = adminStartingValueList.getObject(starting_value_id)

    return {
        starting_value_id,
        starting_value,
        is_loading: adminStartingValueList.isLoading() || (starting_value_id && !get(starting_value, "id")),
        is_busy: adminStartingValueList.getIsSavingObject(),
        initial_values: starting_value
    }
}

export default connect(mapStateToProps)(AdminStartingValue)
