import React from 'react'
import { get } from 'lodash'
import { CircularProgress, Button } from '@material-ui/core'
import { FormikGeneralFormErrors } from 'components/form/GeneralFormErrors'
import { Formik, Form } from 'formik'
import { makeStyles } from '@material-ui/core/styles'
import { Separator } from 'components/layout/Separator'

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: 15,
  },
  saveBar: {
    position: 'fixed',
    bottom: 0,
    right: 0,
    width: '100%',
    padding: 15,
    zIndex: 99,
    background: '#ffffff',
    boxShadow: '0px -4px 3px rgba(239, 239, 239, 0.5)'
  },
  saveBarModal: {
    position: 'absolute',
    bottom: 0,
    left: 0,
    width: '100%',
    boxShadow: '0px -4px 3px rgba(239, 239, 239, 0.5)',
    padding: '7px 15px',
  },
  buttonProgress: {
    color: '#ffffff',
    marginRight: 10,
  }
}))

const BeneficiaryCommonForm = ({
  breadcrumbs,
  actions,
  is_busy,
  is_loading,
  isSubmitting,
  title,
  initial_values,
  inModal,
  onSave,
  validationSchema,
  save_label,
  children,
  afterFormChildren
}) => {
    const classes = useStyles()
    const renderSaveButton =() => {
      return(
        <Button variant="contained" size="large" type="submit" color="primary" disabled={isSubmitting} style={{float: "right"}}>
          { is_busy ?
            <><CircularProgress size={28}  className={classes.buttonProgress} /> Saving...</> :
            <>{save_label || "SAVE"}</>
          }
        </Button>
      )
    }

    return (
            <>
            <Formik
              initialValues={initial_values}
              onSubmit={onSave}
              enableReinitialize={true}
              validationSchema={validationSchema}
            >
              {formik_props => {
                  const { values } = formik_props
                  return (
                    <>
                    {is_loading && <CircularProgress size={28} />}
                      <Form>
                        <FormikGeneralFormErrors render={(msg) => msg} />
                          { children({formik_props: formik_props}) }
                          { !inModal ?
                            <div className={classes.saveBar}>
                              { renderSaveButton() }
                            </div>
                            :
                            <>
                            <Separator height={60} />
                            <div className={classes.saveBarModal }>
                              { renderSaveButton() }
                            </div>
                            </>
                          }

                      </Form>
                    </>
                  )}
              }
            </Formik>
          {afterFormChildren}
          </>
    )

}

export default BeneficiaryCommonForm
