import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { size, get, values, union, map, filter } from 'lodash'
import { useParams } from 'react-router-dom'
import DonorMainLayout from '../layout/DonorMainLayout'
import {
  makeStyles,
  Grid,
  Typography,
  Card,
  CardContent,
  CardActions,
  CardActionArea,
  Button,
  Collapse,
  IconButton,
  Tooltip,
  Link,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  FormLabel
} from '@material-ui/core'
import DashboardPopupFilter from './DashboardPopupFilter'
import { MapWithMarkers, MapWithMarkerClusterer } from 'components/map/MapMarkers'
import DonateButtonsWidget from '../donations/widgets/DonateButtonsWidget'
import DonationWidgets from '../donations/widgets/DonationWidgets'
import { CovidNotice } from '../notices/Notices'
import { contactCompanyList } from '../actions/contact_company'
import { contactCompanyMapList } from '../actions/contact_company_map'
import { contactWarehouseList } from '../actions/contact_warehouse'
import Alert from '@material-ui/lab/Alert'
import { getActiveCompanyId, getActiveCompanyDefaultAddress } from 'actions/active_company'
import { Switch } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
    root: {
      padding: 15
    },
    title: {
        fontSize: 14,
    },
    pos: {
        marginBottom: 12,
    },
    cardActionButton: {
        marginBottom: -25,
    },
    expand: {
        transform: 'rotate(0deg)',
        marginLeft: 'auto',
        transition: theme.transitions.create('transform', {
            duration: theme.transitions.duration.shortest,
        }),
    },
    expandOpen: {
        transform: 'rotate(180deg)',
    },
    welcomeHeading: {
        fontSize: 26,
        marginTop: 10,
        marginBottom: 10,
        marginLeft: 15,
        [theme.breakpoints.down('sm')]: {
            fontSize: 21,
        },
    },
    dashName: {
        fontSize: 18
    }
}))

export default function DonorDashboard({...props}) {
    const classes = useStyles()
    const dispatch = useDispatch()
    const company_id = getActiveCompanyId()
    // const companies = useSelector(() => contactCompanyList.getVisibleObjects())
    const is_loading = useSelector(() => contactCompanyList.isLoading())
    const map_is_loading = useSelector(() => contactCompanyMapList.isLoading())
    const company = useSelector(() => contactCompanyList.getObject(company_id))
    const companies_for_map = useSelector(() => contactCompanyMapList.getVisibleObjects())
    const warehouses = useSelector(() => contactWarehouseList.getVisibleObjects())
    const [markers, setMarkers] = React.useState(null)
    const [clusterMarkers, setClusterMarkers] = React.useState(true)

    const active_company_default_address = getActiveCompanyDefaultAddress()
    const center = { lat: get(active_company_default_address, "latitude"), lng: get(active_company_default_address, "longitude") }

    React.useEffect(() => {
        dispatch(contactCompanyList.ensureObjectLoaded(company_id))
    }, [company_id])

    React.useEffect(() => {
        // dispatch(contactCompanyList.updatePaginationNumItemsPerPage(1000))
        // dispatch(contactCompanyList.is_loadingfetchListIfNeeded())
        dispatch(contactWarehouseList.updatePaginationNumItemsPerPage(1000))
        dispatch(contactWarehouseList.fetchListIfNeeded())
        dispatch(contactCompanyMapList.updatePaginationNumItemsPerPage(1000))
        dispatch(contactCompanyMapList.fetchListIfNeeded())
        window.localStorage.setItem("showDonorWelcome", false)
    }, [])

    React.useEffect(() => {
        setMarkers(union(
            map(values(companies_for_map), function(company) { return {marker_type: company.company_type,
                                                                       ...company}
                                                             }),
            map(values(warehouses), function(warehouse) { return {marker_type: "warehouse",
                                                                  ...warehouse}
                                                        })
        ))
    }, [size(companies_for_map), size(warehouses)])

    // @manu: this is a very weird line, I don't think it does what you think it does, it's the equivalent of:
    // :  let showDonorWelcome = window.localStorage.getItem('showDonorWelcome') || true
    // which you can see just always returns True. Maybe I'm missing something?
    let showDonorWelcome = window.localStorage.getItem('showDonorWelcome') ? window.localStorage.getItem('showDonorWelcome') : window.localStorage.setItem("showDonorWelcome", true)

    const onCloseWelcome = () => {
      showDonorWelcome = false
    }

    return (
        <DonorMainLayout active_key="donor"
                         title="Dashboard"
                         notice={<CovidNotice />}
                         breadcrumbs={[{name: 'donor_home'},
                                       {name: 'dashboard', label: "Dashboard", url: '/donor'}]}
        >
          {showDonorWelcome == true &&
            <Alert onClose={() => onCloseWelcome()}>
              <Typography variant="h5" className={classes.welcomeHeading}>Welcome to your Dashboard</Typography>
            </Alert>
          }

          <DonateButtonsWidget />

          <DonationWidgets is_loading={is_loading} />

          <Typography variant="h5" className={classes.welcomeHeading}>
              Impact Map
              <FormControlLabel
                  control={
                      <Switch
                          name="cluster_markers"
                          color="primary"
                          checked={clusterMarkers}
                          onClick={() => setClusterMarkers(!clusterMarkers)}
                      />
                  }
                  label={`Cluster Markers`}
                  style={{float: 'right'}}
              />
          </Typography>


          {clusterMarkers ?
              <MapWithMarkerClusterer markers={markers} width={"100%"} height={700} center={center} is_loading={map_is_loading} /> :
              <MapWithMarkers markers={markers} width={"100%"} height={700} center={center} is_loading={map_is_loading} />
          }

        </DonorMainLayout>
    )
}
