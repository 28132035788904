import React from 'react'
import { get } from 'lodash'

export function buildPayfastOffsiteUrl(props) {
  const return_url = window.LOCAL_SETTINGS.PAYMENT_RETURN_URL + get(props, 'id')
  const cancel_url = window.LOCAL_SETTINGS.PAYMENT_CANCEL_URL + get(props, 'id')
  const payfast_url = window.LOCAL_SETTINGS.PAYFAST_URL
  const payfast_params = new window.URLSearchParams()

  payfast_params.set('merchant_id', window.LOCAL_SETTINGS.PAYFAST_MERCHANT_ID)
  payfast_params.set('merchant_key', window.LOCAL_SETTINGS.PAYFAST_MERCHANT_KEY)
  payfast_params.set('return_url', return_url)
  payfast_params.set('cancel_url', cancel_url)
  payfast_params.set('confirmation_address', window.LOCAL_SETTINGS.PAYMENT_CONFIRMATION_EMAIL)
  payfast_params.set('amount', get(props, 'amount'))
  payfast_params.set('name_first', get(props, 'name_first'))
  payfast_params.set('name_last', get(props, 'name_last'))
  payfast_params.set('email_address', get(props, 'email'))
  //payfast_params.set('cell_number', get(props, 'cell_number'))
  //payfast_params.set('payment_method', get(props, 'payment_method'))
  payfast_params.set('item_name', get(props, 'item_name'))
  payfast_params.set('m_payment_id', get(props, 'unique_payment_ref'))

  const url = payfast_url + '?email_confirmation&' + payfast_params.toString()

  return url
}
