import React, { useState, useEffect, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams, useHistory } from 'react-router-dom'
import { get } from 'lodash'
import websiteTheme from 'themes/websiteTheme'
import { ThemeProvider } from '@material-ui/styles'
import { makeStyles, withStyles } from '@material-ui/core/styles'
import { TableContainer, Table, TableRow, TableCell, Paper, Grid, Typography, Slider, Input, OutlinedInput, Button, TextField, FormControl, FormControlLabel, InputLabel, InputAdornment, Checkbox, CircularProgress } from '@material-ui/core'
import ArrowForwardIcon from '@material-ui/icons/ArrowForward'
import CreditCardIcon from '@material-ui/icons/CreditCard'
import Icon from '@mdi/react'
import { mdiQrcode } from '@mdi/js'
import { mdiCreditCardOutline } from '@mdi/js'
import { mdiBank } from '@mdi/js'
import { useMailchimp, Status } from 'integrations/mailchimp/MailchimpHooks'
import { buildPayfastOffsiteUrl } from 'integrations/payment_gateways/payfast/PayfastOffsite'
import { payfastOnsitePayment } from 'integrations/payment_gateways/payfast/PayfastOnsite'
import { buildSnapScanUrl } from 'integrations/payment_gateways/SnapScan'
import FavoriteIcon from '@material-ui/icons/Favorite'
import { FormikGeneralFormErrors } from 'components/form/GeneralFormErrors'
import { Formik, Form, FieldArray, Field } from 'formik'
import { FormikInputField } from 'components/form/InputField'
import { FormikCheckboxField } from 'components/form/CheckboxField'
import * as Yup from 'yup'
import { marketingMoneyDonationList, marketingMoneyDonationPayfastOnsitePaymentList } from '../actions/marketing_money_donation'
import { marketingCompanyForMarketingList } from 'portals/marketing/actions/marketing_companies'
import { handleSubmitResult, shouldShowOnDemandSaveButton } from 'actions/form'
import { showSuccess, showError } from 'actions/Error'
import moment from 'moment'
import { Separator } from 'components/layout/Separator'
import NumberFormat from 'react-number-format'
import SourceAttribution from './SourceAttribution'
import DriveTruckSlider from './DriveTruckSlider'
import { ShortId } from 'components/ShortId'
import Helmet from 'react-helmet'
import style from 'portals/widgets/css/style.css'
import mealLoveBgImage from 'assets/images/heart-plate-knife-and-fork.svg'

const yup_shape = {
  'amount_rands': Yup.string().required( "Required" ),
  'donator': Yup.object().shape( {
    'first_name': Yup.string().required( "Required" ),
    'last_name': Yup.string().required( "Required" ),
    'email': Yup.string().required( "Required" ),
    //'cell_number': Yup.string().required("Required"),
  } )
}

const validationSchema = Yup.object().shape( yup_shape )

const useStyles = makeStyles( ( theme ) => ( {
  root: {
    width: '100%',
    background: '#fadf01',
    fontSize: 36,
    fontWeight: 100,
    minHeight: '100vh',
    padding: 15,
    fontFamily: 'Arial, Helvetica, sans-serif'
  },
  contentContainer: {
    width: 500,
    background: '#ffffff',
    borderRadius: 30,
    paddingTop: 40,
    paddingLeft: 30,
    paddingRight: 30,
    paddingBottom: 20,
    marginLeft: 'auto',
    marginRight: 'auto',
    [ theme.breakpoints.down( "sm" ) ]: {
      fontSize: 18,
      width: '100%',
      textAlign: 'left',
      marginTop: 10,
      width: '100%',
    },
  },
  input: {
    fontSize: 24,
    width: '100%',
    background: '#efefef',
    marginTop: 5,
    marginBottom: 5,
    borderRadius: 32,
    border: 'none',
    [ theme.breakpoints.down( "sm" ) ]: {
      fontSize: 18,
      width: 200,
      textAlign: 'center',
      marginTop: 10,
      width: '100%',
    },
  },
  payWith: {
    marginTop: 5,
    marginBottom: 15,
    fontSize: 28,
    color: '#93aaa0'
  },
  checkboxOptions: {
    textAlign: 'center',
    marginLeft: 15,
    marginTop: 15
  },
  tableCell: {
    border: 'none',
    fontSize: 21,
    color: 'black',
    [ theme.breakpoints.down( "sm" ) ]: {
      fontSize: 14,
    },
  },
  inputProps: {
    borderRadius: 32,
    border: '2px solid black'
  },
  donationID: {
    fontSize: 16,
    fontWeight: 500,
    marginBottom: 15
  },
  numMeals: {
    fontSize: 40
  },
  p50: {
    padding: 50,
    [ theme.breakpoints.down( "xs" ) ]: {
      padding: 20
    },
  },
  donateButton: {
    border: '1px solid #000000',
    background: '#ffffff',
    color: '#000000',
    marginTop: 10,
    borderRadius: 30,
    textTransform: 'capitalize',
    fontSize: 18,
    paddingLeft: 30,
    paddingRight: 30,
    marginRight: 7,
    marginLeft: 7,
    borderRadius: 30,
    '&:hover': {
      background: '#efefef'
    },
    [ theme.breakpoints.down( "xs" ) ]: {
      fontSize: 18,
      width: '90%',
      marginTop: 0,
    },
  },
  primaryDonateButton: {
    width: '90%',
  },
  iconMargin: {
    marginRight: 7
  },
  snapscanButton: {
    marginLeft: 5,
    marginRight: 5
  },
  mealLove: {
    width: 400,
  },
  cost_per_meal: {
    fontFamily: "Frankfurter Std Regular",
    fontSize: 60,
    color: 'white',
    backgroundImage: `url(${ mealLoveBgImage })`,
    backgroundRepeat: 'no-repeat',
    width: 400,
    height: 300,
    margin: 'auto',
    marginTop: 40,
    backgroundSize: 'contain',
    lineHeight: 1,
    paddingTop: 50,
    paddingLeft: 15,
    textAlign: 'center'
  },
  text_sahyellow: {
    color: '#FADF01'
  }
} ) )

export default function Donate( props ) {
  const classes = useStyles()
  const dispatch = useDispatch()
  const history = useHistory()
  let { id } = useParams()
  const donation_id = id
  const [ edit, setEdit ] = useState( false )
  const donation = useSelector( () => marketingMoneyDonationList.getObject( donation_id ) )
  const title = <>Thank you for making a difference.<br />Together, we can end hunger in South Africa.</>
  const is_busy = useSelector( () => marketingMoneyDonationList.getIsSavingObject() )
  const [ isPayingSnapScan, setIsPayingSnapScan ] = useState( false )
  const [ isPayingPayfast, setIsPayingPayfast ] = useState( false )
  const cost_per_meal = 0.9

  let is_loading = donation_id && !get( donation, "id" )
  const url_filter = new window.URLSearchParams( window.location.search )
  const url_params = {}
  let starting_amount = ''
  url_params.source = url_filter.get( 'source' ) || null
  let source_slug = url_params.source ? url_params.source : null
  if ( donation ) {
    source_slug = get( donation, 'source_slug' ) ? get( donation, 'source_slug' ) : source_slug
  }
  if ( source_slug === 'tashas' ) {
    starting_amount = 50
  }
  let initial_values = get( donation, 'id' ) ? donation : { newsletter_enabled: true, amount_rands: starting_amount }
  let filteredCompany = useSelector( () => marketingCompanyForMarketingList.getVisibleObjects( { source_slug } ) )
  const company = filteredCompany[ 0 ]

  const item_name = source_slug ? 'Donation via ' + source_slug : 'Donation'
  const mailchimp_url = window.LOCAL_SETTINGS.MAILCHIMP_URL
  const { subscribe, status, error, msgValue } = useMailchimp( mailchimp_url )
  const source_logo = get( company, [ "logo_image", "original_download_url" ] )

  useEffect( () => {
    async function fetchStaticObjects() {
      dispatch( marketingCompanyForMarketingList.updateListFilter( { slug: source_slug } ) )
      dispatch( marketingCompanyForMarketingList.fetchListIfNeeded() )
      is_loading = dispatch( marketingMoneyDonationList.ensureObjectLoaded( donation_id ) )
    }
    fetchStaticObjects()
  }, [ donation_id ] )


  const onSave = ( values, formik_funcs ) => {
    // Subrscibe user to newsletter if they have provided an email and checked the newsletter checkbox
    if ( values.newsletter_enabled && values.donor_email ) {
      subscribe( {
        FNAME: values.donor_first_name,
        LNAME: values.donor_last_name,
        EMAIL: values.donor_email,
      } )
    }
    const on_ok = function( json ) {
      setEdit( false )
      dispatch( marketingMoneyDonationList.invalidateList() )
      showSuccess( "Saved", "Donation saved" )

      if ( !donation_id ) {
        history.push( `/donate/${ json.id }` )
      }
    }
    if ( donation_id ) {
      values.id = donation_id
      return dispatch( marketingMoneyDonationList.saveObject( values ) ).then( ( res ) => handleSubmitResult( { res, formik_funcs, on_ok } ) )
    } else {
      values.gateway = 'none'
      values.paid_at = moment().format()
      values.acknowledgement_sent = false
      values.source_slug = source_slug
      values.status = 'pending'
      return dispatch( marketingMoneyDonationList.saveNewObject( values ) ).then( ( res ) => handleSubmitResult( { res, formik_funcs, on_ok } ) )
    }
  }

  const handleSwitchPaymentGateway = ( payment_method, values ) => {

    var url = ''
    const payment_params = {
      amount: values.amount_rands,
      name_first: values.donator.first_name,
      name_last: values.donator.last_name,
      cell_number: values.donator.cell_number,
      email: values.donator.email,
      item_name: 'SA Harvest Financial Donation',
      unique_payment_ref: values.unique_payment_ref,
      payment_method: payment_method,
      id: values.id
    }
    switch ( payment_method ) {
      case 'payfast':
        // Onsite Payment
        //return payfastOnsitePayment(dispatch, payment_params, successfulDonation, cancelDonation, paymentError)

        //Offsite Payment
        url = buildPayfastOffsiteUrl( payment_params )
        window.location.href = url
        break
      // case 'eft':
      //     url = buildPayfastUrl(payment_params)
      //     break;
      // case 'snapscan':
      //   setIsPayingSnapScan(true)
      //   url = buildSnapScanUrl(payment_params)
      //   break;
      // default:
      //url = window.LOCAL_SETTINGS.WEBSITE_DONATE_URL
    }

  }

  const renderDonateForm = ( formik_props ) => {
    return (
      <>
        <FormikInputField
          name="amount_rands"
          id="amountRands"
          label="Amount"
          placeholder="How much do you want to give?"
          formik_props={ formik_props }
          className={ classes.input }
          InputProps={ {
            classes: { notchedOutline: classes.inputProps },
            startAdornment: <InputAdornment position="start">R</InputAdornment>
          } }
        />
        <br />
        <FormikInputField
          name="donator.first_name"
          id="firstName"
          label="First Name"
          placeholder="First Name"
          className={ classes.input }
          InputProps={ { classes: { notchedOutline: classes.inputProps } } }
          formik_props={ formik_props } />
        <br />
        <FormikInputField
          name="donator.last_name"
          id="lastName"
          label="Last Name"
          className={ classes.input }
          InputProps={ { classes: { notchedOutline: classes.inputProps } } }
          formik_props={ formik_props }
        />
        <br />
        <FormikInputField
          name="donator.email"
          id="email"
          type="email"
          label="Email"
          className={ classes.input }
          InputProps={ { classes: { notchedOutline: classes.inputProps } } }
          formik_props={ formik_props }
        />
        <br />
        <FormikInputField
          name="donator.cell_number"
          id="cellNumber" type="tel"
          label="Mobile number"
          className={ classes.input }
          InputProps={ { classes: { notchedOutline: classes.inputProps } } }
          formik_props={ formik_props }
        />
        <br />
        <div className={ classes.checkboxOptions }>
          <FormControlLabel
            control={
              <FormikCheckboxField
                name="newsletter_enabled"
                color="primary"
                formik_props={ formik_props } />
            }
            label="Sign up for the SA Harvest Newsletter"
            style={ { marginLeft: 0 } } />
          {/*
          <br />
          <FormControlLabel
            control={
              <FormikCheckboxField
                name="anonymous_donation"
                color="primary"
                formik_props={formik_props} />
            }
            label="Anonymous donation?"
            style={{marginLeft: 0}} />
          <br />
            <FormControlLabel
              control={
                <FormikCheckboxField
                  name="tax_certificate_requested"
                  color="primary"
                  formik_props={formik_props} />
              }
              label="Receive a Section18A Tax Exemption Certificate?"
              style={{marginLeft: 0}} />
          */}
          <br />

        </div>
      </>
    )
  }

  const renderDonationGatewayForms = ( donation ) => {
    return (
      <>
        <TableContainer>
          <Table size="small">

            <TableRow>
              <TableCell className={ classes.tableCell }>Amount:</TableCell>
              <TableCell className={ classes.tableCell }>R{ get( donation, 'amount_rands' ) }</TableCell>
            </TableRow>

            <TableRow>
              <TableCell className={ classes.tableCell }>Reference:</TableCell>
              <TableCell className={ classes.tableCell }><ShortId value={ get( donation, 'unique_payment_ref' ) } /></TableCell>
            </TableRow>

            <TableRow>
              <TableCell className={ classes.tableCell }>Status:</TableCell>
              <TableCell className={ classes.tableCell }>{ get( donation, 'status' ) }</TableCell>
            </TableRow>

            <TableRow>
              <TableCell className={ classes.tableCell }>Name:</TableCell>
              <TableCell className={ classes.tableCell }>{ get( donation, 'donator.first_name' ) } { get( donation, 'donator.last_name' ) }</TableCell>
            </TableRow>

            <TableRow>
              <TableCell className={ classes.tableCell }>Email:</TableCell>
              <TableCell className={ classes.tableCell }>{ get( donation, 'donator.email' ) }</TableCell>
            </TableRow>

            <TableRow>
              <TableCell className={ classes.tableCell }>Phone:</TableCell>
              <TableCell className={ classes.tableCell }>{ get( donation, 'donator.cell_number' ) }</TableCell>
            </TableRow>

          </Table>
        </TableContainer>
        <Grid item align="center" xs={ 12 }>
          {/* <p className={classes.payWith}>Pay with</p> */ }
          <Button
            className={ classes.donateButton }
            style={ { marginTop: 30, paddingLeft: 20, paddingRight: 20 } }
            onClick={ () => handleSwitchPaymentGateway( 'payfast', donation ) }
          >
            <Icon path={ mdiCreditCardOutline } title="Pay with Payfast" size={ 1.5 } className={ classes.iconMargin } /> Pay Now
          </Button>

          {/*
          <Button className={[classes.donateButton, classes.snapscanButton]} onClick={() => handleSwitchPaymentGateway('snapscan', donation)}>
            <Icon path={mdiQrcode} title="Pay with SnapScan" size={1.5} className={classes.iconMargin} /> SnapScan { isPayingSnapScan && <CircularProgress /> }
          </Button>

          <Button className={classes.donateButton} onClick={() => handleSwitchPaymentGateway('eft', donation)}>
            <Icon path={mdiBank} title="Pay with Bank Transfer" size={1.5} className={classes.iconMargin} /> Bank Transfer
          </Button>
          */}
        </Grid>
        <div style={ { textAlign: 'center' } }>
          <Button onClick={ editDonation } size="small" style={ { marginLeft: 15 } }>Edit</Button>
          <Button onClick={ cancelDonation } size="small" style={ { marginLeft: 15 } }>Cancel</Button>
        </div>
      </>

    )
  }

  const editDonation = () => {
    setEdit( true )
  }

  const cancelDonation = () => {
    history.push( '/payment/cancelled/' + get( donation, "id" ) )
  }

  const successfulDonation = () => {
    showSuccess( "Successful", "Donation successfully made" )
    history.push( "/" )
  }

  const paymentError = () => {
    showError( "Failed", "We were unable to contact the payment provider" )
  }

  return (
    <ThemeProvider theme={ websiteTheme }>
      {/* Insert Payfast onsite script into index.html head using react-helmet */ }
      <Helmet>
        <script src="https://www.payfast.co.za/onsite/engine.js"></script>
      </Helmet>

      <div className={ classes.root }>

        { source_slug ? <SourceAttribution source_slug={ source_slug } title={ title } source_logo={ source_logo } /> : <DriveTruckSlider cost_per_meal={ cost_per_meal } /> }

        <div className={ classes.contentContainer }>
          { get( donation, 'unique_payment_ref' ) && !edit ? <>{ renderDonationGatewayForms( donation ) }</> :
            <Formik
              initialValues={ initial_values }
              onSubmit={ onSave }
              enableReinitialize={ true }
              validationSchema={ validationSchema }
            >
              { formik_props => {
                const { values } = formik_props

                return (
                  <>
                    { is_loading && <CircularProgress size={ 28 } /> }
                    <Form className={ classes.donateForm }>
                      <FormikGeneralFormErrors render={ ( msg ) => msg } />
                      { renderDonateForm( formik_props ) }
                      <Separator height={ 20 } />
                      <div align="center">
                        <Button size="large" type="submit" color="primary" className={ [ classes.donateButton ] }>
                          { is_busy ? <><CircularProgress size={ 28 } className={ classes.buttonProgress } /> Saving...</> : edit ? <>Update & Pay</> : <>Donate</> }
                        </Button>
                      </div>
                    </Form>
                  </>
                )
              }
              }
            </Formik>
          }

        </div>

        <div className={ classes.cost_per_meal }>
          R{ cost_per_meal.toFixed( 2 ) } =<br />
          <span className={ classes.text_sahyellow }>1 MEAL</span>
        </div>

      </div>
    </ThemeProvider>
  )
}


  // <Input
  //   id={"donation-value"}
  //   className={classes.input}
  //   value={scale(value)}
  //   onChange={handleInputChange}
  //   ref={textInput}
  //   min={0}
  //   step={1}
  //   max={200}
  //   valueLabelFormat={numFormatter}
  // />
