import { ItemList } from 'orm'
import { values, get, map, head, filter } from 'lodash'

class AdminMoneyDonationList extends ItemList {
    getEntityKey() {
        return "admin/money_donation"
    }

}
export const adminMoneyDonationList = new AdminMoneyDonationList("admin_money_donations__default")
