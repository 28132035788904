import React, { useState, useEffect, useRef } from 'react'
import { useDispatch } from 'react-redux'
import { makeStyles } from '@material-ui/core/styles'
import { Chip } from '@material-ui/core'
import moment from 'moment'
import { map, forEach, get } from 'lodash'

const useStyles = makeStyles( ( theme ) => ( {
	container: {
		display: "flex"
	},
	chip: {
		margin: theme.spacing( 0.5 ),
		marginTop: "12px"
	},
} ) )

export default function FilterChips( { enhanced_filter, marginBottom } ) {
	const classes = useStyles()
	const dispatch = useDispatch()
	const filter_as_objects = enhanced_filter.getFilterAsObjects()

	const addCityChipData = ( chip_data ) => {
		forEach( get( filter_as_objects, "cities" ), ( city ) => chip_data.push( {
			type: 'city',
			value: get( city, "id" ),
			label: `City: ${ get( city, "name" ) }`
		} ) )
	}

	const addDateRangeChipData = ( chip_data ) => {
		const date_format = 'D MMM Y'
		switch ( get( filter_as_objects, "date_range_filter_type" ) ) {
			case "single_date":
				chip_data.push( {
					type: 'date',
					value: { from_date: moment( filter_as_objects.single_date ) },
					label: `Date: ${ moment( filter_as_objects.single_date ).format( date_format ) }`
				} )
				break
			case "date_range":
				chip_data.push( {
					type: 'date',
					value: {
						from_date: moment( filter_as_objects.from_date ),
						to_date: moment( filter_as_objects.to_date ),
					},
					label: `Date Range: ${ moment( filter_as_objects.from_date ).format( date_format ) } - ${ moment( filter_as_objects.to_date ).format( date_format ) }`
				} )
				break
		}
	}

	const addOrderStateChipData = ( chip_data ) => {
		forEach( get( filter_as_objects, "parcel_states" ), ( parcel_state ) => chip_data.push( {
			type: 'parcel_state',
			value: get( parcel_state, "id" ),
			label: `Status: ${ get( parcel_state, "name" ) }`
		} ) )
	}

	const addParcelCompanyTypeChipData = ( chip_data ) => {
		forEach( get( filter_as_objects, "parcel_company_types" ), ( parcel_company_type ) => chip_data.push( {
			type: 'parcel_company_type',
			value: get( parcel_company_type, "id" ),
			label: `Type: ${ get( parcel_company_type, "name" ) }`
		} ) )
	}

	const addAnyFieldChipData = ( chip_data ) => {
		const any_field = get( filter_as_objects, "any_field" )
		if ( any_field ) {
			chip_data.push( {
				type: 'any_field',
				Qvalue: any_field,
				label: any_field
			} )
		}
	}

	const createChipData = () => {
		const chip_data = []
		addCityChipData( chip_data )
		addDateRangeChipData( chip_data )
		addOrderStateChipData( chip_data )
		addParcelCompanyTypeChipData( chip_data )
		addAnyFieldChipData( chip_data )
		return chip_data
	}

	const chipData = createChipData()

	const onDeleteChip = ( chip_data ) => {

		switch ( chip_data.type ) {
			case 'city':
				dispatch( enhanced_filter.removeCityFilter( chip_data.value ) )
				break
			case 'date':
				dispatch( enhanced_filter.removeDateFilter() )
				break
			case 'parcel_state':
				dispatch( enhanced_filter.removeParcelStateFilter( chip_data.value ) )
				break
			case 'parcel_company_type':
				dispatch( enhanced_filter.removeParcelCompanyTypeFilter( chip_data.value ) )
				break
			case 'any_field':
				dispatch( enhanced_filter.removeAnyFieldFilter() )
				break
			default:
				console.error( "Unknown chip type", chip_data )
		}
	}

	return (
		<div className={ classes.container }>
			{ map( chipData, ( data, index ) => {
				return (
					<Chip
						icon={ null }
						label={ data.label }
						onDelete={ () => onDeleteChip( data ) }
						className={ classes.chip }
						variant="outlined"
						style={ { marginBottom: marginBottom } }
					/>
				)
			} ) }
		</div>
	)

}
