import { get, map } from "lodash"
import { CircularProgress, Grid, Table, TableRow, Typography } from "@material-ui/core"
import { useGetWarehouseProductsSummary } from "../../api/warehouse_product"
import { Card } from "components/layout/Card"
import MuiTableCell from "@material-ui/core/TableCell"
import { withStyles } from "@material-ui/core/styles"
import { useEffect } from "react"
import { Skeleton } from "@material-ui/lab"

const TableCell = withStyles( {
  root: {
    borderBottom: "none"
  }
} )( MuiTableCell )

export default function AdminWarehouseProductsSummary( { filters, is_loading } ) {
  const { data, is_loading: is_loading_summary, error, mutate } = useGetWarehouseProductsSummary( filters )

  useEffect( () => {
    mutate()
  }, [ filters ] )

  const renderItems = ( data ) => {
    if ( is_loading ) return ( <Skeleton variant="rect" /> )
    return (
      <Table size="small">
        { map( get( data, "inventory_total_items" ), ( item ) => {
          return (
            <TableRow key={ item.warehouse__name }>
              <TableCell>
                <Typography>{ item.warehouse__name }</Typography>
              </TableCell>
              <TableCell>
                <Typography variant="h5" style={ { textAlign: 'right' } }>{ item.total_quantity }</Typography>
              </TableCell>
            </TableRow>
          )
        } ) }
      </Table>
    )
  }

  const renderWeight = ( data ) => (
    <Table size="small">
      { map( get( data, "inventory_total_weight" ), ( item ) => {
        return (
          <TableRow key={ item.warehouse__name }>
            <TableCell>
              <Typography>{ item.warehouse__name }</Typography>
            </TableCell>
            <TableCell>
              <Typography variant="h5" style={ { textAlign: 'right' } }>{ item.total_weight }kg</Typography>
            </TableCell>
          </TableRow>
        )
      } ) }
    </Table>
  )

  const CustomSkeleton = () => (
    <Skeleton variant="rect" height={ 200 } animation="wave" style={{ marginBottom: 10}} />
  )

  return (
    <>
      { error && JSON.stringify( error ) }
      <Grid container spacing={ 2 } style={ { marginBottom: 0 } }>
        <Grid item xs={ 12 } sm={ 6 }>
          { is_loading ? <CustomSkeleton /> :
            <Card
              title="Total Items"
              content={ renderItems( data ) }
            />
          }
        </Grid>
        <Grid item xs={ 12 } sm={ 6 }>
          { is_loading ?  <CustomSkeleton />:
            <Card
              title="Total Weight"
              content={ renderWeight( data ) }
            />
          }
        </Grid>
      </Grid>
    </>
  )
}