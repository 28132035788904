import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useParams } from 'react-router-dom'
import { get, filter } from 'lodash'
import { adminChecklistQuestionList } from './actions/admin_checklist_question'
import {showSuccess, showError} from 'actions/Error'
import { handleSubmitResult } from 'actions/form'
import AdminChecklistForm from './form/AdminChecklistForm'
import { FormikGeneralFormErrors } from 'components/form/GeneralFormErrors'
import { Formik, Form } from 'formik'
import FormCard from 'components/layout/FormCard'
import { SaveButton } from 'components/form/Buttons'
import AdminChecklistHistory from './AdminChecklistHistory'
import AdminChecklistQuestionForm from './form/AdminChecklistQuestionForm'
import { Card } from 'components/layout/Card'
import { Grid, Typography } from '@material-ui/core'
import * as Yup from 'yup'

const validationSchema = Yup.object().shape({
    'text': Yup.string().required("Required"),
})

export default function AdminChecklistQuestion({checklist_id, question_id, onSaved}) {
    const dispatch = useDispatch()
    const history = useHistory()
    const checklist_question = useSelector(() => adminChecklistQuestionList.getObject(question_id))
    const checklist_question_name = get(checklist_question, ["name"])
    const is_loading = useSelector(() => adminChecklistQuestionList.isLoading())  || (question_id && !get(checklist_question, "id"))
    const is_busy = useSelector(() => adminChecklistQuestionList.getIsSavingObject())
    const initial_values = checklist_question
    const title = `${get(checklist_question, "name", 'New checklist template')}`
    const justify = question_id ? '' : 'center'

    useEffect(() => {
        dispatch(adminChecklistQuestionList.ensureObjectLoaded(question_id))
    },[])

    const onSave = (values, formik_funcs) => {
        values.checklist = checklist_id
        const on_ok = function(json) {
            dispatch(adminChecklistQuestionList.invalidateList())
            showSuccess("Saved", "Checklist saved")
            onSaved()
        }
        if ( question_id ) {
            values.id = question_id
            return dispatch(adminChecklistQuestionList.saveObject(values)).then((res) => handleSubmitResult({res, formik_funcs, on_ok}))
        } else {
            return dispatch(adminChecklistQuestionList.saveNewObject(values)).then((res) => handleSubmitResult({res, formik_funcs, on_ok}))
        }
    }

    const getCellValue = (header_key, item, index) => {
        switch( header_key ) {
            default:
                return undefined
        }
    }

    return (
        <Formik
            initialValues={initial_values}
            onSubmit={onSave}
            enableReinitialize={true}
            validationSchema={validationSchema}
        >
            { formik_props => {
                const { values } = formik_props
                return (
                    <Form>
                        <FormCard noPadding isSubmitting={formik_props.isSubmitting}>
                            <AdminChecklistQuestionForm
                                formik_props={formik_props}
                            />
                        </FormCard>
                    </Form>
                )
            }}

        </Formik>
    )
}
