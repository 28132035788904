import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { get } from 'lodash'
import AdminCommonListLayout from '../layout/AdminCommonListLayout'
import { adminBrandList } from './actions/admin_brand'
import Avatar from '@material-ui/core/Avatar'
import { ManufacturerName } from 'admin/components/product_manufacturers/ManufacturerAttributes'

export default function Brands() {

  const dispatch = useDispatch()
  const history = useHistory()

  const brands = useSelector(() => adminBrandList.getVisibleObjects())
  const is_loading = useSelector(() => adminBrandList.isLoading())
  const filter = useSelector(() => adminBrandList.getFilter())

  useEffect(() => {
    dispatch(adminBrandList.fetchListIfNeeded())
  },[])

  const onEditBrand = (brand_id) => {
    history.push({
      pathname: '/admin/brand/'+brand_id
    })
  }

  const onAddBrand = () => {
    history.push({
      pathname: '/admin/brand/'
    })
  }

  const onUpdateListOrdering = (field) => {
    dispatch(adminBrandList.updateListOrdering(field))
  }

  const columns = [
    { field: 'logo',
      title: 'Logo',
      width: 120,
      render: (item) => <Avatar
                          alt={`${get(item, 'name')}`}
                          src={get(item, ['logos', 'files', 0, 'original_download_url'])}
                          style={{width: 100, height: 100}}
                          variant="square"
                        />,
    },
    { field: 'name',
      title: 'Name',
      sort:(direction) => onUpdateListOrdering(direction === 'asc' ? 'name' : '-name')
    },
    { field: 'manufacturer',
      title: 'Manufacturer',
      render: (item) => <ManufacturerName manufacturer_id={ get(item, 'manufacturer') } />,
      sort:(direction) => onUpdateListOrdering(direction === 'asc' ? 'manufacturer' : '-manufacturer')
    },
    { field: 'url',
      title: 'URL',
      sort:(direction) => onUpdateListOrdering(direction === 'asc' ? 'url' : '-url')
    },
  ]

  return (
    <AdminCommonListLayout
      active_key="brands"
      breadcrumbs={[
        {name: 'admin_home'},
        {name: 'brands', label: "Brands", url: '/admin/brands'}
      ]}
      add_label="Add Brand"
      title={"Brands"}
      onAddRow={onAddBrand}
      onEditRow={onEditBrand}
      is_loading={is_loading}
      columns={columns}
      item_list={adminBrandList}
      enableAnyFieldFilter={true}
    />
  )
}
