import * as Yup from 'yup'
import { FormikInputField } from 'components/form/InputField'
import { FormikTextareaField } from 'components/form/TextareaField'
import { FormikFileSetField } from 'components/form/FileSetField'
import { Grid } from "@material-ui/core"
import { Card } from 'components/layout/Card'

export const validationSchema = Yup.object().shape({
  'name': Yup.string().required("Required"),
})

export const AdminManufacturerForm = ({ in_drawer, formik_props }) => {

  const renderFormFields = () => {
    return(
      <>
      <FormikInputField
        name="name"
        label="Name"
        formik_props={formik_props}
      />
      <FormikInputField
        name="url"
        label="URL"
        formik_props={formik_props}
        />
      <FormikTextareaField
        name="description"
        label="Description"
        rows={5}
        formik_props={formik_props}
        />
      </>



    )
  }

  const renderGridForm = () => {
    return(
      <Grid container spacing={2}>

        <Grid item xs={12} md={9}>
          <Card
            title="Manufacturer Details"
            content={ renderFormFields() }
          />
        </Grid>

        <Grid item xs={12} md={3}>
          <Card
            title="Manufacturer Logo"
            content={
              <FormikFileSetField
                max_num_files={1}
                name="logos"
                formik_props={formik_props}
              />
          }renderGridForm
          />
        </Grid>

      </Grid>
    )

  }

  return in_drawer ? renderFormFields() : renderGridForm()
  
}
