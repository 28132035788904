import React from 'react'
import DonorMainLayout from '../layout/DonorMainLayout'
import SimpleTable from 'components/SimpleTable'

export default function DonorHelp() {
  return(
    <DonorMainLayout
       active_key="help"
       breadcrumbs={[
          {name: 'donor_home'},
          {name: 'dashboard', label: "Dashboard", url: '/donor'},
          {name: 'help', label: "Help", url: '/donor/help'}
      ]}
    >

    </ DonorMainLayout>
  )
}
