import { get, filter } from 'lodash'

const active_company = JSON.parse(localStorage.getItem("active_company"))

export const getActiveCompany = () => {
  return active_company
}

export const setActiveCompany = (company) => {
  localStorage.setItem("active_company", JSON.stringify(company))
}

export const setAdminLoggedInAlertRead = (admin_logged_alert_read) => {
  localStorage.setItem("admin_logged_in_alert_read", JSON.stringify(admin_logged_alert_read))
}

export const getAdminLoggedInAlertRead = () => {
  const adminLoggedInAlertRead = localStorage.getItem("admin_logged_in_alert_read")
  return adminLoggedInAlertRead ? JSON.parse(adminLoggedInAlertRead) : false
}

export const getActiveCompanyId = () => {
  return get(active_company, "id")
}

export const getActiveCompanySlug = () => {
  return get(active_company, "slug")
}

export const getActiveCompanyName = () => {
  return get(active_company, "name")
}

export const getActiveCompanyLogo = () => {
  return get(active_company, [ "logo_image", "original_download_url"])
}

export const getActiveCompanyDefaultAddress = () => {
  return filter(get(active_company, [ "addresses"]), {is_default: true})[0]
}
