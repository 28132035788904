import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Card, CardActionArea, Link, CardActions, CardContent, Button, Typography } from '@material-ui/core'
import { useHistory } from "react-router-dom"
import { Route } from 'react-router-dom'

const useStyles = makeStyles({
  root: {
    position: 'relative',
  },
  title: {
    color: '#777777'
  },
  kpi: {
    fontSize: 24,
    color: '#333333'
  },
  widgetLink: {
    '&:hover': {
      textDecoration: 'none'
    }
  },
  cardContent: {
    padding: '10px 0 0 0'
  }
})

export default function KpiWidget({title, kpi, color, link, variant, square}) {
  const classes = useStyles()
  const history = useHistory()
  if(!square) {square = false}

  return (
    <Card className={classes.root} style={{borderBottom: '10px solid ' + color}} variant={variant} square={square}>
      <CardActionArea>
        <Route render={({ history }) => (
            <Link onClick={() => { history.push(link) }} className={classes.widgetLink}>
              <CardContent className={classes.cardContent}>
                <Typography className={classes.title} color="textSecondary"  align="center">
                  {title}
                </Typography>
                <Typography className={classes.kpi} display="block" align="center">
                  {kpi}
                </Typography>
              </CardContent>
            </Link>
        )} />
      </CardActionArea>
    </Card>
  )
}
