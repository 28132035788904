import React from 'react'
import { Nav } from './Nav'
import CardInfoText from './CardInfoText'

export const CardHeader = ({ title, left_child, right_child, sub_text, ...props }) => {

    return (
        <div>
          <div>

            <Nav className="mr-auto">
              { title &&
                <div>
                  <h1>{title}</h1>
                </div>
              }
              { left_child }
            </Nav>

            <Nav className="ml-auto">
              { right_child }
            </Nav>

          </div>

          { sub_text &&
            <CardInfoText>
              {sub_text}
            </CardInfoText>
          }
        </div>
    )
}
