import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { adminUOMList } from './actions/admin_uom'
import { get } from 'lodash'
import Loading from 'components/Loading'

export const UOMName = ({ uom_id }) => {
    const dispatch = useDispatch()
    const uom = useSelector(() => adminUOMList.getObject(uom_id))
    const is_loading = useSelector(() => adminUOMList.isLoading())
    const uom_quantity = get(uom, "quantity") ? ` (${get(uom, "quantity")})` : false
    const uom_name = get(uom, "name") && uom_quantity ? get(uom, "name") + uom_quantity : '--'

    useEffect(() => {
        dispatch(adminUOMList.ensureObjectLoaded(uom_id))
    },[])

    return is_loading ? <Loading size={20} /> : uom_name
}
