import React, { useRef, useState } from 'react'
import { Button } from '@material-ui/core'
import EmailEditor from 'react-email-editor'
import defaultEmailTemplate from './templates/json/defaultEmailTemplate.json'

const AdminEmailEditor = (props) => {
  const emailEditorRef = useRef(null)
  const [htmlCode, setHtmlCode] = useState(false)

  const exportHtml = () => {
    emailEditorRef.current.editor.exportHtml((data) => {
      const { design, html } = data
      setHtmlCode(html)
    })
  }

  const saveDesign = () => {
    emailEditorRef.current.editor.saveDesign(design => {
      console.log(design)
    })
  }

  const onLoad = () => {
    // you can load your template here
    // const templateJson = {}
    emailEditorRef.current.editor.loadDesign(defaultEmailTemplate)
  }

  return (
    <>
    <div>
      <div>
        <Button onChange={exportHtml}>Export HTML</Button>
        <Button onClick={saveDesign}>Save Design</Button>
      </div>

      <EmailEditor ref={emailEditorRef} onLoad={onLoad} />
    </div>
    <div><pre>{htmlCode}</pre></div>
    </>
  )
}

export default AdminEmailEditor
