import React from "react";
import { Bar, Line } from "react-chartjs-2";
import { mealsPersonPeopleServeOptions } from "./ChartOptions";
const MealsPersonPeopleServerBarChart = React.forwardRef(({ data }, ref) => {
  return (
    <>
      <Bar
        data={data}
        options={mealsPersonPeopleServeOptions}
        className="chartjsbar"
        ref={ref}
      />
    </>
  );
});
export default MealsPersonPeopleServerBarChart;
