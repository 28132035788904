import React from 'react'
import { FormControl, FormLabel } from '@material-ui/core'
import { FormikRadioGroupField } from 'components/form/RadioGroupField'
import { FormikDropdownField } from 'components/form/Dropdown'
import { FormikInputField } from 'components/form/InputField'
import { FormikDateTimePicker } from 'components/form/DatetimePicker'
import { WeekPicker } from 'components/form/WeekPicker'
import { MonthPicker } from 'components/form/MonthPicker'
import { map } from 'lodash'

export default function DateRangeFilter( { formik_props } ) {
  const [ value, setValue ] = React.useState( 'all_time' )

  const handleChange = ( event ) => {
    setValue( event.target.value )
  }

  const getAllTimeOption = () => {
    return {
      label: 'All Time',
      value: 'all_time',
      children: null
    }
  }

  const getSingleTimeOption = () => {
    return {
      label: 'Select a date',
      value: 'single_date',
      children: [ FormikDateTimePicker( {
        label: 'Select a date',
        name: 'single_date',
        formik_props,
        include_time: false
      } ) ]
    }
  }

  const getDateRangeOption = () => {
    return {
      label: 'Date Range',
      value: 'date_range',
      children: [ FormikDateTimePicker( {
        label: 'Start Date',
        name: 'from_date',
        formik_props,
        include_time: false
      } ),
      FormikDateTimePicker( {
        label: 'End Date',
        name: 'to_date',
        formik_props,
        include_time: false
      } ) ]
    }
  }

  const getRelativeDateOptions = () => {

    const relative_date_options = [
      { label: 'is in the last', value: 'is_in_the_last' },
      { label: 'is in this', value: 'is_in_this' },
      { label: 'is in the next', value: 'is_in_the_next' },
    ]
    const date_parameter_options = [
      { label: 'days', value: 'days' },
      { label: 'weeks', value: 'weeks' },
      { label: 'calendar weeks', value: 'calendar_weeks' },
      { label: 'months', value: 'months' },
      { label: 'calendar months', value: 'calendar_months' },
      { label: 'years', value: 'years' },
      { label: 'calendar years', value: 'calendar_years' },
    ]
    return {
      label: 'Relative Date',
      value: 'relative_date',
      children: [
        FormikDropdownField( {
          label: 'Relative Date Type',
          name: 'relative_date_type',
          formik_props,
          options: relative_date_options,
        } ),
        FormikInputField( {
          label: '',
          name: 'from_date',
          formik_props,
        } ),
        FormikDropdownField( {
          label: 'Date Parameters',
          name: 'date_parameters',
          formik_props,
          options: date_parameter_options,
        } ),
      ]
    }
  }

  const options = [
    getAllTimeOption(),
    getSingleTimeOption(),
    getDateRangeOption(),
    // getRelativeDateOptions(),
  ]

  return (
    <div>
      <FormControl component="fieldset">
        <FormLabel component="legend">Date Filter</FormLabel>

        <FormikRadioGroupField aria-label="date-range"
          name="date_range_filter_type"
          options={ options }
          formik_props={ formik_props }
          value={ value }
        />
      </FormControl>

    </div>
  )
}
