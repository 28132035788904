import { ItemList } from 'orm'
import { map, filter, values } from 'lodash'

class ContactCoreBusinessList extends ItemList {
    getEntityKey() {
        return "contact/core_business"
    }
}

export const contactCoreBusinessList = new ContactCoreBusinessList("contact_core_business__default")
