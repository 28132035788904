import { ItemList } from 'orm'
import { values, get, map, head, filter } from 'lodash'
import { onEventUpdated } from 'admin/actions/admin_calendar'
import { download } from 'actions/lib'
 
class BeneficiaryList extends ItemList {
    getEntityKey() {
        return "admin/beneficiary"
    }

    getAsOptions() {
        const items = this.getVisibleObjects()
        return map(items, function(item) { return {label: item.company.name,
                                                   value: item.company.id}})
    }

    getForCompany({company_id}) {
        // Assumes the matching beneficiary is already loaded
        return head(filter(values(this.getAllObjects()), (x) => get(x, ["company", "id"]) === company_id))
    }

    download() {
        return download("admin/beneficiary/download/", this.getFilter())
    }

    filterOnCity({city_id}) {
        console.log(city_id)
        return this.updateListFilter({city:city_id})
    }

}

export const adminBeneficiaryList = new BeneficiaryList("admin_beneficiary__default")
export const adminBeneficiaryAutoCompleteList = new BeneficiaryList("admin_beneficiary_auto_complete__default")
