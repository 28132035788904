import React from 'react'
import * as Yup from 'yup'
import { ProvinceSelectField } from 'components/form/ProvinceSelectField'
import { FormikInputField } from 'components/form/InputField'
import { FormikColorPicker } from 'components/form/ColorPicker'

export const validationSchema = Yup.object().shape({
    'name': Yup.string().required("Required"),
    'province': Yup.string().required("Required")
})

const AdminCityForm = ({ formik_props }) => {

    return (
        <>
            <FormikInputField name="name"
                label="Name"
                formik_props={formik_props}
            />

            <FormikColorPicker
                name="color"
                label="Color"
                formik_props={formik_props}
            />

            <ProvinceSelectField name="province"
                label="Province"
                formik_props={formik_props}
            />

            <FormikInputField name="bringg_team_ref"
                label="Bringg team ref"
                formik_props={formik_props}
            />

        </>
    )
}

export default AdminCityForm
