import { ItemList } from 'orm'
import { map } from 'lodash'

class ManufacturerList extends ItemList {
    getEntityKey() {
        return "admin/manufacturer"
    }

    getAsOptions() {
        const items = this.getVisibleObjects()
        return map(items, function(item) {
          return { label: item.name, value: item.id }
        }
      )
    }
}

export const adminManufacturerList = new ManufacturerList("admin_manufacturer__default")
export const adminManufacturerAutoCompleteList = new ManufacturerList("admin_manufacturer_auto_complete__default")
