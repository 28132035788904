import React, { useState, useEffect } from 'react'
import { get, upperFirst, head, union, map, capitalize, find } from 'lodash'
import TextField from '@material-ui/core/TextField'
import Autocomplete from '@material-ui/lab/Autocomplete'
import CircularProgress from '@material-ui/core/CircularProgress'
import { Field } from 'formik'
import { useDispatch, useSelector } from 'react-redux'
import { makeStyles } from '@material-ui/core'

const ADD_NEW_VALUE = '__add_new__'

const useStyles = makeStyles( ( theme ) => ( {
  listbox: {
    border: '1px solid #EFEFEF',
    "& li:nth-child(even)": {
      backgroundColor: "#F5F5F5",
      borderBottom: '1px solid #EEEEEE',
    },
    "& li:nth-child(odd)": {
      backgroundColor: "#FAFAFA",
      borderBottom: '1px solid #EEEEEE',
    },
  },
} ) )

export function FormikAutoCompleteDropdown( { label, name, freeSolo = false, onChange, renderAddNew, formik_props, item_list } ) {

  const [ addingNew, setAddingNew ] = useState( false )
  const [ valueAlreadyInUse, setValueAlreadyInUse ] = useState( false )

  const localOnChange = ( value ) => {  // Note: freeSolo is now a parameter

    function setNewFieldValue( value ) {
      formik_props.setFieldValue( name, value )
      formik_props.setFieldTouched( name )
      if ( onChange ) {
        onChange( value )
      }
    }

    if ( value === ADD_NEW_VALUE ) {
      setAddingNew( true )
    } else {
      const existingFreeSoloOption = freeSolo ? find( get( item_list, "objects_by_id" ), ( option ) => option.name === value ) : false
      if ( !freeSolo ) {
        setNewFieldValue( value )
      } else {
        if ( existingFreeSoloOption ) {
          setValueAlreadyInUse( true )
        } else {
          setNewFieldValue( value )
        }
      }
    }
  }

  const onAdded = ( value ) => {
    localOnChange( value )
    setAddingNew( false )
  }

  const onAddNewCancelled = () => {
    setAddingNew( false )
    formik_props.setFieldValue( name, null )
    formik_props.setFieldTouched( name )
  }

  return (
    <>
      { addingNew && renderAddNew( { onAdded, onAddNewCancelled } ) }

      <Field name={ name }>
        { ( formik_props ) => (
          <AutoCompleteDropdown
            name={ name }
            freeSolo={ freeSolo }
            item_list={ item_list }
            formik_props={ formik_props }
            onChange={ localOnChange }
            canAdd={ renderAddNew !== undefined }
            label={ label }
          />
        ) }
      </Field>
      { freeSolo && valueAlreadyInUse ? <p style={ { color: 'red' } }>Already exists in the database!</p> : null }
    </>
  )
}

export function AutoCompleteDropdown( { item_list, name, label, onChange, canAdd, formik_props, freeSolo = false } ) {

  const dispatch = useDispatch()
  const [ filterValue, setFilterValue ] = useState( '' )
  const [ loading, setLoading ] = useState( false )
  const [ open, setOpen ] = useState( false )
  const { field, meta } = formik_props
  let touched = get( meta, "touched" )
  const errors = get( meta, "error" )
  const classes = useStyles()

  if ( get( formik_props, [ "meta", "initialValue" ] ) !== get( formik_props, [ "meta", "value" ] ) ) {
    touched = true
  }

  let item_list_options

  if ( freeSolo ) {
    item_list_options = item_list.objects_by_id
  }
  else {
    item_list_options = useSelector( () => item_list.getAsOptions() )
  }

  item_list_options = freeSolo ?
    // If freeSolo is true, then we want to return the value as is
    map( item_list_options, ( option ) => ( upperFirst( get( option, 'name' ) ) ) ) :
    // If freeSolo is false, then we want to return the value as an object with a value and label
    map( item_list_options, ( option ) => {
      return {
        value: get( option, 'value' ),
        label: upperFirst( get( option, 'label' ) )
      }
    } )

  if ( canAdd ) {
    item_list_options = union( item_list_options, [ { value: ADD_NEW_VALUE, label: '+ New ' + label } ] )
  }

  useEffect( () => {
    setLoading( true )
    dispatch( item_list.updatePaginationNumItemsPerPage( 10 ) )
    dispatch( item_list.updateListFilter( { auto_complete: null, any_field: filterValue } ) )
    dispatch( item_list.fetchListIfNeeded() )
    setLoading( false )
  }, [ filterValue ] )

  useEffect( () => {
    const initial_value = field.value
    if ( initial_value ) {
      setLoading( true )
      // This assumes that we can do a unique filter on the initial_value field.
      dispatch( item_list.updateListFilter( { auto_complete: initial_value, any_field: null } ) )
      dispatch( item_list.fetchListIfNeeded() ).then( () => {
        const obj = head( item_list.getAsOptions() )
        if ( obj ) {
          setFilterValue( upperFirst( get( obj, "label" ) ) )
        } else {
          console.error( "Failed to find object", label, initial_value )
        }
        setLoading( false )
      } )
    } else {
      setFilterValue( "" )
    }
  }, [ get( field, "value" ) ] )

  // if freeSolo is true, then we want to return an autocomplete with freeSolo enabled
  if ( freeSolo ) {
    return (
      <Autocomplete
        id={ `${ name }-autocomplete` }
        freeSolo
        autoSelect
        options={ map( item_list_options, ( option ) => option ) }
        getOptionLabel={ option => option || "" } // Explicitly specify how to get labels
        filterSelectedOptions
        classes={ { listbox: classes.listbox } }
        onChange={ ( evt, newValue ) => onChange( newValue ) }
        // onBlur={ ( evt, newValue ) => onChange( newValue ) }
        clearOnBlur={ false }
        renderInput={ ( params ) => (
          <TextField
            { ...params }
            label={ capitalize( name ) }
            margin="normal"
            variant="outlined"
            error={ touched && errors }
          />
        ) }
      />
    )
  }

  return (
    <Autocomplete
      open={ open }
      onOpen={ () => {
        setOpen( true )
      } }
      onClose={ () => {
        setOpen( false )
      } }
      clearOnBlur={ false }
      classes={ { listbox: classes.listbox } }
      getOptionSelected={ function( option, value ) {
        // setFilterValue( option.label )
        return option.value === get( field, "value" )
      } }
      getOptionLabel={ ( option ) => option ? option.label : "" }
      onInputChange={ ( event, newFilterValue ) => {
        setFilterValue( newFilterValue )
      } }
      value={ get( field, "value" ) || null }
      inputValue={ filterValue }
      onChange={ ( event, newValue ) => {
        onChange( get( newValue, "value" ) )
      }
      }
      options={ item_list_options }
      loading={ loading }
      renderInput={ function( params ) {
        return (
          <TextField
            { ...params }
            variant="outlined"
            label={ label }
            margin="normal"
            error={ touched && errors }
            helperText={ touched ? Boolean( errors ) : "" }
            style={ { marginTop: 0 } }
            InputProps={ {
              ...params.InputProps,
              endAdornment: (
                <>
                  { loading ? <CircularProgress color="inherit" size={ 20 } /> : null }
                  { params.InputProps.endAdornment }
                </>
              ),
            } }
          />
        )
      } }
    />
  )
}