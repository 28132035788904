import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { get, map, forEach, capitalize, tail, join, clone, reverse } from 'lodash'
import Timestamp from 'components/Timestamp'
import { showSuccess, showError } from 'actions/Error'
import Alert from '@material-ui/lab/Alert'
import { adminBeneficiaryParcelProductList, adminBeneficiaryParcelProductForCartList } from './actions/admin_beneficiary_parcel_product'
import { adminWarehouseProductAutoCompleteList } from 'admin/components/inventory/actions/admin_warehouse_product'
import { adminProductList } from 'admin/components/products/actions/admin_product'
import { CommonTable } from 'components/CommonTable'
import AdminBeneficiaryParcelProduct from './AdminBeneficiaryParcelProduct'
import { FormikWarehouseProductCart } from 'admin/components/products/form/WarehouseProductCart'
import { Hidden, Typography, LinearProgress } from '@material-ui/core'
import { Modal } from 'components/layout/Modal'
import NumberFormat from 'react-number-format'
import { getCartTotalWeightKg } from 'components/orders/Cart'
import { AddButton } from 'components/form/Buttons'
import Loading from 'components/Loading'

export default function AdminBeneficiaryParcelProducts( { parcel_id, onUpdate, updateCartTotalWeight } ) {
  const dispatch = useDispatch()
  const parcel_products = useSelector( () => adminBeneficiaryParcelProductList.getVisibleObjects() )
  const product_ids = map( parcel_products, ( parcel_product ) => parcel_product.id )
  const is_loading = useSelector( () => adminBeneficiaryParcelProductList.isLoading() || adminBeneficiaryParcelProductList.getIsSavingObject() )
  const parcel_product_filter = useSelector( () => adminBeneficiaryParcelProductList.getFilter() )
  const not_specified = <span style={ { color: 'red' } }>not specified</span>
  const cart_products = useSelector( () => adminBeneficiaryParcelProductForCartList.getVisibleObjects() )

  let all_parcel_products = {}

  forEach( parcel_products, function( parcel_product ) {
    all_parcel_products[ parcel_product.from_warehouse_product ] = {
      warehouse_product: {
        id: parcel_product.from_warehouse_product,
        total_weight_kg: parcel_product.total_weight_kg,
        product_weight_kg: parcel_product.product_weight_kg,
        product_name: parcel_product.product_category_crumbs[ 0 ]
      },
      quantity: parcel_product.actual_quantity
    }
  } )

  const [ state, setState ] = useState( {
    editing_parcel_product_id: null,
    adding_new_parcel_product: false,
    show_loading: false
  } )

  useEffect( () => {
    if ( parcel_id ) {
      if ( parcel_id != parcel_product_filter.parcel ) {
        dispatch( adminBeneficiaryParcelProductList.updateListFilter( { parcel: parcel_id } ) )
        dispatch( adminBeneficiaryParcelProductForCartList.updateListFilter( { parcel: parcel_id } ) )
      }
      dispatch( adminBeneficiaryParcelProductList.updateListFilter( { parcel: parcel_id } ) )
      dispatch( adminBeneficiaryParcelProductList.fetchListIfNeeded() )
      dispatch( adminBeneficiaryParcelProductForCartList.updatePaginationNumItemsPerPage( 1000 ) )
      dispatch( adminBeneficiaryParcelProductForCartList.updateListFilter( { parcel: parcel_id } ) )
      dispatch( adminBeneficiaryParcelProductForCartList.fetchListIfNeeded() )
      dispatch( adminProductList.ensureObjectsLoaded( product_ids ) )
    }
  }, [ parcel_id ] )

  useEffect( () => {
    updateCartTotalWeight( getCartTotalWeightKg( all_parcel_products ) )
  }, [ cart_products, all_parcel_products ] )

  const onEditParcelProduct = ( parcel_product_id ) => {
    setState( { editing_parcel_product_id: parcel_product_id, adding_new_parcel_product: false } )
  }

  const onAddParcelProduct = () => {
    setState( { adding_new_parcel_product: true, editing_parcel_product_id: null } )
  }

  const onStopEditingParcelProduct = () => {
    dispatch( adminBeneficiaryParcelProductList.invalidateList() )
    dispatch( adminBeneficiaryParcelProductForCartList.invalidateList() )
    setState( { editing_parcel_product_id: null, adding_new_parcel_product: false } )
  }

  const onStopAddingParcelProduct = () => {
    dispatch( adminBeneficiaryParcelProductList.invalidateList() )
    dispatch( adminBeneficiaryParcelProductList.fetchListIfNeeded() )
    dispatch( adminWarehouseProductAutoCompleteList.invalidateList() )
    dispatch( adminWarehouseProductAutoCompleteList.fetchListIfNeeded() )
    dispatch( adminBeneficiaryParcelProductForCartList.invalidateList() )
    dispatch( adminBeneficiaryParcelProductForCartList.fetchListIfNeeded() )
    return onStopEditingParcelProduct()
  }

  const renderEditParcelProduct = ( parcel_product_id ) => {
    return (
      <Modal
        onClose={ onStopEditingParcelProduct }
        fullWidth={ true }
        width='xl'
        title=""
        noPadding
      >
        <AdminBeneficiaryParcelProduct
          parcel_id={ parcel_id }
          parcel_product_id={ parcel_product_id }
          onSaved={ onStopEditingParcelProduct }
          onCancel={ onStopEditingParcelProduct }
        />
      </Modal>
    )
  }

  const saveWarehouseProductsToParcel = ( cartItems ) => {

    setState( { show_loading: true } )

    const on_ok = function( json ) {
      all_parcel_products = cartItems
      setState( { show_loading: false } )
      showSuccess( "Saved", "Products updated" )
      onStopAddingParcelProduct()
      updateCartTotalWeight( getCartTotalWeightKg( cartItems ) )
      onUpdate()
    }

    const values = map( cartItems, function( cartItem ) {
      return ( {
        parcel: parcel_id,
        from_warehouse_product: cartItem.warehouse_product.id,
        actual_quantity: cartItem.quantity
      } )
    } )
    dispatch( adminBeneficiaryParcelProductList.saveNewObject( values ) ).then( function( res ) {
      if ( res.errors ) {
        showError( get( res, [ "errors", "_error" ], "Failed to save" ) )
      } else {
        on_ok()
      }
    } )
  }

  const renderAddParcelProduct = () => {
    return (
      <Modal
        onClose={ onStopAddingParcelProduct }
        fullWidth={ true }
        width='xl'
        title=""
      >
        <FormikWarehouseProductCart
          parcel_id={ parcel_id }
          initialCartItems={ all_parcel_products }
          onSave={ saveWarehouseProductsToParcel }
          onCancel={ onStopAddingParcelProduct }
        />
      </Modal>
    )
  }

  const onUpdateListOrdering = ( field ) => {
    dispatch( adminBeneficiaryParcelProductList.updateListOrdering( field ) )
  }

  const columns = [
    {
      field: 'product_category_name',
      title: 'Product Name',
      render: ( item ) => get( item, "product_category_crumbs", [ 0 ] )[ 0 ],
      sort: ( direction ) => onUpdateListOrdering( direction === 'asc' ? 'product__product_category__name' : '-product__product_category__name' ),
      visible: true,
    },
    {
      field: 'product_category_crumbs',
      title: 'Category',
      render: ( item ) => join( reverse( tail( clone( get( item, "product_category_crumbs" ) ) ) ), ' / ' ),
      sort: ( direction ) => onUpdateListOrdering( direction === 'asc' ? 'product__product_category__name' : '-product__product_category__name' ),
      visible: false,
    },
    {
      field: 'product_name',
      title: 'Old Product Name',
      render: ( item ) => get( item, "product_name" ),
      sort: ( direction ) => onUpdateListOrdering( direction === 'asc' ? 'product' : '-product' ),
      visible: false,
    },
    {
      field: 'original_from_company_name',
      title: 'Donor',
    },
    {
      field: 'best_before_date',
      title: 'BB',
      render: ( item ) => <Timestamp value={ item.best_before_date } format='date' />,
      sort: ( direction ) => onUpdateListOrdering( direction === 'asc' ? 'best_before_date' : '-best_before_date' ),
    },
    {
      field: 'expiry_date',
      title: 'Expiry',
      render: ( item ) => <Timestamp value={ item.expiry_date } format='date' />,
      sort: ( direction ) => onUpdateListOrdering( direction === 'asc' ? 'expiry_date' : '-expiry_date' ),
    },
    {
      field: 'product_weight_kg',
      title: 'Item Weight',
      visible: true,
      width: 150,
      align: 'right',
      render: ( item ) => <NumberFormat value={ get( item, [ "product_weight_kg" ] ) } suffix={ "kg" } displayType={ 'text' } thousandSeparator={ true } decimalScale={ 2 } />,
      sort: ( direction ) => onUpdateListOrdering( direction === 'asc' ? 'weight_kg' : '-weight_kg' ),
    },
    {
      field: 'actual_quantity',
      visible: true,
      title: 'Qty',
      width: 100,
      align: 'right',
      sort: ( direction ) => onUpdateListOrdering( direction === 'asc' ? 'actual_quantity' : '-actual_quantity' ),
    },
    {
      field: 'total_weight_kg',
      title: 'Total Weight',
      render: ( item ) => <NumberFormat value={ get( item, [ "total_weight_kg" ] ) } suffix={ "kg" } displayType={ 'text' } thousandSeparator={ true } decimalScale={ 2 } />,
      sort: ( direction ) => onUpdateListOrdering( direction === 'asc' ? 'total_weight_kg' : '-total_weight_kg' ),
      visible: true,
      align: 'right',
      width: 150,
    },
  ]

  const columnsMobile = [
    {
      field: 'product_name',
      title: 'Product',
      visible: true,
      render: ( item ) => <><Typography style={ { marginBottom: -20 } }>{ capitalize( get( item, "product_name" ) ) }</Typography><br /></>,
      sort: ( direction ) => onUpdateListOrdering( direction === 'asc' ? 'product' : '-product' ),
    },
    {
      field: 'product_category_crumbs',
      title: 'Category',
      render: ( item ) => <><Typography variant="caption">{ get( item, "product_category_crumbs", [] )[ get( item, "product_category_crumbs", [] ).length - 1 ] }</Typography><br /></>,
      sort: ( direction ) => onUpdateListOrdering( direction === 'asc' ? 'product__product_category__name' : '-product__product_category__name' ),
    },
    {
      field: 'original_from_company_name',
      title: 'Donor',
      render: ( item ) => <>Donor: { get( item, "original_from_company_name" ) ? capitalize( get( item, "original_from_company_name" ) ) : not_specified }<br /></>,
      sort: ( direction ) => onUpdateListOrdering( direction === 'asc' ? 'original_from_company_name' : '-original_from_company_name' ),
    },
    {
      field: 'best_before_date',
      title: 'Best Before',
      render: ( item ) => <>BB Date: { item.best_before_date ? <Timestamp value={ item.best_before_date } format='date' /> : not_specified }<br /></>,
      sort: ( direction ) => onUpdateListOrdering( direction === 'asc' ? 'best_before_date' : '-best_before_date' ),
    },
    {
      field: 'expiry_date',
      title: 'Expiry',
      render: ( item ) => <>Expiry Date: { item.expiry_date ? <Timestamp value={ item.expiry_date } format='date' /> : not_specified }<br /></>,
      sort: ( direction ) => onUpdateListOrdering( direction === 'asc' ? 'expiry_date' : '-expiry_date' ),
    },
    {
      field: 'product_weight_kg',
      title: 'Item Weight',
      visible: true,
      render: ( item ) => <>Item Weight: <NumberFormat value={ get( item, [ "product_weight_kg" ] ) } suffix={ "kg" } displayType={ 'text' } thousandSeparator={ true } decimalScale={ 2 } /><br /></>,
      sort: ( direction ) => onUpdateListOrdering( direction === 'asc' ? 'weight_kg' : '-weight_kg' ),
    },
    {
      field: 'actual_quantity',
      title: 'Quantity',
      visible: true,
      render: ( item ) => <>Quantity: { item.actual_quantity }<br /></>,
      sort: ( direction ) => onUpdateListOrdering( direction === 'asc' ? 'actual_quantity' : '-actual_quantity' ),
    },
    {
      field: 'total_weight_kg',
      title: 'Total Weight',
      visible: true,
      render: ( item ) => <>Total Weight: { item.total_weight_kg != 0 ? <NumberFormat value={ get( item, [ "total_weight_kg" ] ) } suffix={ "kg" } displayType={ 'text' } thousandSeparator={ true } decimalScale={ 2 } /> : not_specified }</>,
      sort: ( direction ) => onUpdateListOrdering( direction === 'asc' ? 'total_weight_kg' : '-total_weight_kg' ),
    },
  ]

  return (
    <div>
      { state.show_loading &&
        <LinearProgress />
      }
      { !parcel_id &&
        <Alert severity="warning">You must create an order before you can add products</Alert>
      }

      { parcel_id &&
        <>
          { state.editing_parcel_product_id &&
            renderEditParcelProduct( state.editing_parcel_product_id )
          }
          { state.adding_new_parcel_product &&
            renderAddParcelProduct()
          }
          { !state.editing_parcel_product_id && !state.adding_new_parcel_product &&
            <div style={ { padding: '15px' } }>
              <AddButton
                label="Add Products"
                onClick={ onAddParcelProduct }
                color="secondary"
              />
            </div>
          }
          { !is_loading && parcel_products.length > 0 ?
            <>
              <Hidden smDown>
                <CommonTable
                  is_loading={ is_loading }
                  rows={ parcel_products }
                  columns={ columns }
                  item_list={ adminBeneficiaryParcelProductList }
                  onEditRow={ onEditParcelProduct }
                  useColumnsSelector={ true }
                  storeTableName={ "adminBeneficiaryParcelProductList" }
                  enableAnyFieldFilter
                  loadingVariant="linear"
                />
              </Hidden>
              <Hidden mdUp>
                <CommonTable
                  is_loading={ is_loading }
                  rows={ parcel_products }
                  columns={ columnsMobile }
                  item_list={ adminBeneficiaryParcelProductList }
                  onEditRow={ onEditParcelProduct }
                  enableAnyFieldFilter
                  loadingVariant="linear"
                  mobile
                />
              </Hidden>
            </> :
            <div align="center">
              { is_loading ? <Loading /> : <img src="/static/images/empty-box.jpg" alt="Your cart is empty" title="Your cart is empty" width={ 300 } onClick={ onAddParcelProduct } /> }
            </div>
          }
        </>
      }
    </div>
  )
}
