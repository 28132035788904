import React, { useState, useRef } from "react"
import { Modal } from "components/layout/Modal"
import { get } from 'lodash'
import {showSuccess, showError} from '../actions/Error'
import { useDispatch } from 'react-redux'
import SignaturePad from "react-signature-canvas"
import { makeStyles, Button, Grid } from '@material-ui/core'
import { fileInfoList } from '../actions/file_info'

const useStyles = makeStyles((theme) => ({
    sigContainer: {
        width: '100%',
        height: '100%',
        margin: '0 auto',
        backgroundColor: '#FFFFFF',
    },
    sigPad: {
        width: '100%',
        height: '100%',
    },
    sigImage: {
      backgroundSize: '100% 200px',
      width: '80%',
      backgroundColor: '#FFFFFF',
      marginBottom: 15
    }
}))

function Signature({name, formik_props, onSaveSignature}) {
    const dispatch = useDispatch()
    const classes = useStyles()
    const [openSignaturePad, setOpenSignaturePad] = useState(false)
    const [isUploading, setIsUploading] = useState(false)
    const sigCanvas = useRef({})

    const signatureFileInfo = get(formik_props.values, name)

    /* a function that uses the canvas ref to clear the canvas
    via a method given by react-signature-canvas */
    const handleClear = () => {
        sigCanvas.current.clear()
    }

    /* a function that usopenSignaturePades the canvas ref to trim the canvas
    from white spaces via a method given by react-signature-canvas
    then saves it in our state */
    const handleSave = () => {

        const imageURL = sigCanvas.current.getTrimmedCanvas().toDataURL("image/png")

        const onProgress = (file, status) => {
            if ( status === 'success' ) {
                setIsUploading(false)
                setOpenSignaturePad(false)

                formik_props.setFieldValue(name, file)

                if ( onSaveSignature ) {
                    onSaveSignature(imageURL, file)
                }


            } else if ( status === 'failed' ) {
                setIsUploading(false)
                setOpenSignaturePad(false)
                showError("Failed to save signature")
            }
        }

        setIsUploading(true)
        fileInfoList.uploadBase64Files([{ content: imageURL, name: 'signature.png', content_type: 'image/png' }], onProgress)
    }

    return (
        <>

            {openSignaturePad &&
                <Modal
                    onClose={() => setOpenSignaturePad(false)}
                    title="Please sign"
                    fullWidth
                    maxWidth="sm"
                >

                    {/* Button to trigger save canvas image */}

                    <Grid
                        container
                        spacing={2}
                        direction="row"
                        justifyContent="space-between"
                        alignItems="center"
                    >
                      <Grid item xs={12} style={{textAlign: 'center'}}>
                          <div className={classes.sigContainer}>
                              <SignaturePad
                                  ref={sigCanvas}
                                  canvasProps={{ className: classes.sigPad }}
                              />
                          </div>
                      </Grid>
                      <Grid item>
                          <Button onClick={handleClear} size="small">Clear</Button>
                      </Grid>
                      <Grid item>
                          <Button onClick={handleSave} variant="contained" color="primary">Save</Button>
                      </Grid>
                    </Grid>

                </Modal>
            }

          <div align="center">

            { signatureFileInfo && <img alt="Signature" className={classes.sigImage} src={signatureFileInfo.original_download_url} /> }

            { !signatureFileInfo ?
                <img
                    src="/static/images/sign-here.jpg"
                    onClick={() => setOpenSignaturePad(true)}
                    style={{ cursor: "pointer" }}
                /> :
                <Button
                    onClick={() => setOpenSignaturePad(true)}
                    variant="outlined"
                    size="small"
                >
                    Update Signature
                </Button>
            }

          </div>
        </>
    )
}

export default Signature
