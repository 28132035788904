import React from 'react'
import './css/masonryGallery.css'

export default function MasonryGallery({images}) {
  return (
    <ul class="masonryGallery">
      {images.map(image => (
        <li>
          <img src={image.original_download_url} alt={image.alt} title={image.title} loading="lazy" />
        </li>
      ))}
      {/* Adding an empty <li> here so the final photo doesn't stretch like crazy. Try removing it and see what happens!  */}
      <li></li>
    </ul>

  )
}
