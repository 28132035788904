import React, { useEffect, useState } from 'react'
import { Field, useField, FieldArray } from 'formik'
import { useDispatch, useSelector } from 'react-redux'
import { map, get, size, split } from 'lodash'
import CommonListTable from '../layout/CommonListTable'
import ContactAddressForm from './form/ContactAddressForm'
import { Separator } from 'components/layout/Separator'
import {showSuccess, showError} from 'actions/Error'
import { handleSubmitResult } from 'actions/form'
import { IconButton, Button, makeStyles, Tooltip, Grid, Typography, Chip } from '@material-ui/core'
import { GrayLinkButton } from 'components/layout/GrayLinkButton'
import DeleteIcon from '@material-ui/icons/Delete'
import AddIcon from '@material-ui/icons/Add'
import { contactAddressList } from 'actions/contact/address'
import { Modal } from 'components/layout/Modal'
import { BasicMapWithMarker } from  'components/map/MapMarkers'
import Alert from "@material-ui/lab/Alert"

const NEW_ADDRESS_ID = "__new_address__"

const useStyles = makeStyles((theme) => ({
  addButton: {
    marginBottom: 15,
  },
  noAddresssButton: {
    marginTop: 10
  },
  deleteButton: {
    float:'right',
  },
  addEditAddress: {
    color: '#999999'
  }
}))

const ContactAddresses = ({formik_props, newAddressInitialValues, name, inTabs, company_id, is_new}) => {
    const classes = useStyles()
    const dispatch = useDispatch()
    const addresses = is_new ? null : useSelector(() => contactAddressList.getVisibleObjects())
    const is_loading = is_new ? null : useSelector(() => !contactAddressList.isReady() && contactAddressList.isLoading())

    const [editingAddressId, setEditingAddressId] = useState(null)
    const [addEditLabel, setAddEditLabel] = useState("New Address")

    useEffect(() => {
        if(!is_new) {
          dispatch(contactAddressList.updateListFilter(company_id))
          dispatch(contactAddressList.fetchListIfNeeded())
        }
    }, [company_id])

    const onAddAddress = () => {
        setEditingAddressId(NEW_ADDRESS_ID)
    }

    const onEditAddress = (address_id) => {
        setAddEditLabel(`Editing ${address_id}`)
        setEditingAddressId(address_id)
    }

    const onStopEditingAddress = () => {
        dispatch(contactAddressList.invalidateList())
        dispatch(contactAddressList.fetchListIfNeeded())
        setEditingAddressId(null)
    }

    const onRemoveAddress = (address) => {

        if (! window.confirm("Are you sure you want to delete this address?") ) {
            return
        }

        const onDone = (res) => {
            if ( res.errors ) {
                showError("Failed", "Address is in use and could not be deleted")
            } else {
                showSuccess("Deleted", "Address deleted")
                dispatch(contactAddressList.invalidateList())
                dispatch(contactAddressList.fetchListIfNeeded())
            }
        }

        dispatch(contactAddressList.deleteObject(address.id)).then(onDone)
    }

    const onMakeDefaultAddress = (address_id) => {
      alert("This address must be saved as the only default addressAll other addresses should be saved as is_default: false")
    }

    const columns = [
      {
        field: 'formatted_address',
        title: 'Address',
        render: (item) =>
        <Grid container spacing={2}>
          <Grid item xs={6}>

            <BasicMapWithMarker lat={get(item, "latitude")} lng={get(item, "longitude")} width={'100%'} height={300} showDefaultUI />




          </Grid>
          <Grid item xs={6}>
            <Typography>{get(item, "formatted_address")}</Typography>
              <Typography variant="caption">
                City: {get(item, "city_name")}, Province: {get(item, "province_name")}, Lat: {get(item, "latitude")}, Lng: {get(item, "longitude")}<br />
             </Typography>
             <div>
             {get(item, "is_default") ?
               <Chip label="Default Address" style={{background: 'green', color: 'white', marginTop: 10}} /> :
               <Button
                 onClick={() => onMakeDefaultAddress(item.id)}
                 size="small"
                 variant="contained"
                 disableElevation
               >
                 Make Default Address
               </Button>
             }
             </div>
          </Grid>

        </Grid>

      },
    ]

    return (
      <>
      {company_id ?
        <CommonListTable
          add_label="Add Address"
          title="Address"
          onAddRow={onAddAddress}
          button_variation="icon"
          button_align="right"
          button_color="yellow"
          button_size="small"
          onEditRow={onEditAddress}
          is_loading={is_loading}
          handleRequestSort={columns}
          columns={columns}
          item_list={contactAddressList}
          noHeadings
          noPagination
          inTabs
        /> :
        <Alert severity="info">First create the company then you can add addresses</Alert>
      }
      { editingAddressId &&
        <Modal title={addEditLabel} onClose={onStopEditingAddress}>
          <ContactAddressForm newAddressInitialValues={newAddressInitialValues}
                            address_id={editingAddressId === NEW_ADDRESS_ID ? null : editingAddressId}
                            onDone={onStopEditingAddress} />
        </Modal>
      }

      </>
    )

}

export default ContactAddresses
