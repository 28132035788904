const scales = {
  x: {
    ticks: {
      font: {
        weight: "bold",
      },
    },
    grid: {
      offset: false,
      lineWidth: 0,
    },
  },
  y: {
    ticks: {
      font: {
        size: 12,
        weight: "bold",
      },
    },
    grid: {
      display: true,
      color: "rgba(0, 0, 0, 0.1)",
      borderWidth: 1,
      borderColor: "rgba(0, 0, 0, 0.1)",
      drawBorder: true,
      drawOnChartArea: false,
      drawTicks: true,
      tickLength: 16,
      tickWidth: 2,
      tickColor: "rgba(0, 0, 0, 0.1)",
    },
  },
  x2: {
    display: false,
  },
};

const setLabelsConfigs = (chart) => {
  const labelsConfigs = chart.data.datasets
                             .filter(data => data.label !== "undefined")
                             .map((data, index) => {
                               return {
                                 text: data.label,
                                 fillStyle: data.borderColor,
                                 hidden: false,
                                 lineCap: "round",
                                 lineDash: data.borderDash,
                                 lineWidth: data.type === 'line' ? 0 : data.borderWidth,
                                 strokeStyle: data.borderColor ?? data.backgroundColor,
                                 datasetIndex: index,
                                 borderRadius: 5,
                                 borderColor: data.borderColor,
                               }
                             });
  return labelsConfigs;
}
export const mealsDeliveredOptions = {
  plugins: {
    title: {
      display: true,
      text: "Total Meals Delivered Over Time",
      padding: {
        bottom: 30,
      },
    },
    legend: {
      title: {
        display: true,
        text: "Period",
        padding: {
          top: 10,
        },
        font: {
          size: 14,
          weight: "bold",
        },
      },
      display: true,
      position: "bottom",
      labels: {
        generateLabels: (chart) => setLabelsConfigs(chart),
      },
    },
  },
  categoryPercentage: 1,
  maintainAspectRatio: false,
  responsive: true,
  scales: scales,
};

export const mealsPersonPeopleServeOptions = {
  plugins: {
    title: {
      display: true,
      text: "People Served by Region",
      padding: {
        bottom: 30,
      },
    },
    legend: {
      title: {
        display: true,
        text: "Province",
        padding: {
          top: 10,
        },
        font: {
          size: 14,
          weight: "bold",
        },
      },
      display: true,
      position: "bottom",
      labels: {
        generateLabels: (chart) => {
          const mealDataset = chart.data.datasets[0];
          const peopleDataset = chart.data.datasets[1];
          return [
            {
              text: mealDataset.label,
              fillStyle: mealDataset.borderColor,
              hidden: false,
              lineCap: "round",
              lineDash: mealDataset.borderDash,
              lineWidth: mealDataset.borderWidth,
              strokeStyle: mealDataset.borderColor,
              datasetIndex: 0,
              borderRadius: 5,
              borderColor: mealDataset.borderColor,
            },
            {
              text: peopleDataset.label,
              fillStyle: peopleDataset.borderColor,
              hidden: false,
              lineCap: "round",
              lineDash: peopleDataset.borderDash,
              lineWidth: 0,
              datasetIndex: 1,
              borderRadius: 5,
              borderColor: mealDataset.borderColor,
            },
          ];
        },
      },
    },
    title: {
      display: true,
      text: "People Served by Region",
      padding:{
        bottom: 30
      }
    },
    legend: {
      title: {
        display: true,
        text: "Province",
        padding: {
          top: 10,
        },
        font:{
          size: 14,
          weight: "bold"                                        
        }
      },
      display: true,
      position: 'bottom',
      labels: {
        generateLabels: (chart) => setLabelsConfigs(chart)
      }
    },               
  },
  categoryPercentage: 1,
  maintainAspectRatio: false,
  responsive: true,
  scales: scales,
};

export const BranchMetricsChartOptions = {
  plugins: {
    title: {
      display: true,
      text: "Meals Delivered & People Served Breakdown",
      padding: {
        bottom: 30,
      },
    },

    legend: {
      title: {
        display: true,
        text: "Branch",
        padding: {
          top: 10,
        },
        font: {
          size: 14,
          weight: "bold",
        },
      },
      display: true,
      position: "bottom",
      labels: {
        generateLabels: (chart) => {
          let count = 0;
          const datasets = chart.data.datasets.map((e) => {
            return {
              text: e.label,
              fillStyle: e.backgroundColor,
              hidden: false,
              lineCap: "round",
              lineWidth: 1,
              strokeStyle: e.borderColor ?? e.backgroundColor,
              datasetIndex: count++,
              borderRadius: 5,
            };
          });
          return datasets;
        },
      },
    },
  },
  maintainAspectRatio: false,
  responsive: true,
  interaction: {
    mode: "index",
    intersect: false,
  },
  scales: scales,
  borderRadius: 50,
  barThickness: 10,
  borderSkipped: false,
};

export const KpiChartOptions = {
  plugins: {
    legend: {
      display: true,
      position: "bottom",
      align: "start",
      labels: {
        generateLabels: (chart) => {
          let count = 0;
          const datasets = chart.data.labels.map((e) => {
            const value = chart.data.datasets[0].data[count];
            const index = chart.data.datasets[0].backgroundColor.length;
            const color =
              chart.data.datasets[0].backgroundColor[count++ % index];
            return {
              text: e + ": " + value,
              label: e,
              fillStyle: color,
              hidden: false,
              lineCap: "round",
              strokeStyle: color,
              borderRadius: 5,
            };
          });
          return datasets;
        },
      },
    },
  },
};
