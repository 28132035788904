import * as Yup from 'yup'
import { FormikInputField } from 'components/form/InputField'
import { FormikTextareaField } from 'components/form/TextareaField'
import { FormikDropdownField } from 'components/form/Dropdown'
import { FormikCheckboxField } from 'components/form/CheckboxField'
import { FormControlLabel, Grid } from '@material-ui/core'

export const validationSchema = Yup.object().shape({
  'name': Yup.string().required("Required"),
})

export const AdminNutrientForm = ({ formik_props }) => {

  const nutrient_type_options = [
    { label: 'Macro', value: 'macro' },
    { label: 'Micro', value: 'micro' },
  ]

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md={8}>
        <FormikInputField
          name="name"
          label="Name"
          formik_props={formik_props}
        />

        <FormikInputField
          name="short_name"
          label="Short Name"
          formik_props={formik_props}
        />
        <FormikInputField
          name="infoods_tagname"
          label="Code (INFOODS TAGNAME)"
          formik_props={formik_props}
        />
        <FormikInputField
          name="denominator"
          label="Denominator"
          formik_props={formik_props}
        />
        <FormikInputField
          name="significant_figures"
          label="Significant Figures"
          formik_props={formik_props}
        />
        <FormikInputField
          name="analytical_method"
          label="Analytical Method"
          formik_props={formik_props}
        />
        <FormikTextareaField
          name="main_functions"
          label="Main Functions"
          formik_props={formik_props}
          rows={5}
        />
        <FormikTextareaField
          name="important_sources"
          label="Important Sources"
          formik_props={formik_props}
          rows={5}
        />
      </Grid>
      <Grid item xs={12} md={4} style={{ paddingTop: 25 }}>
      <FormikDropdownField
          name="nutrient_type"
          label="Nutrient Type"
          formik_props={formik_props}
          options={nutrient_type_options}
        />
        <FormikInputField
          name="unit"
          label="Unit"
          formik_props={formik_props}
        />
         <FormikInputField
          name="rda"
          label="RDA"
          formik_props={formik_props}
        />
        <FormControlLabel
          control={
            <FormikCheckboxField
              name="use_in_nutrition_facts_card"
              color="primary"
              formik_props={formik_props} />
          }
          label="Show on nutrition facts card?"
          style={{ marginLeft: 0 }}
        />
        { formik_props.values.use_in_nutrition_facts_card &&
        <FormikInputField
          name="calculation_weight"
          label="Calculation Weight"
          formik_props={formik_props}
        />
        }
        <FormControlLabel
          control={
            <FormikCheckboxField
              name="is_necessary_for_adequate_nutrition"
              color="primary"
              formik_props={formik_props} 
            />
          }
          label="Necessary for adequate nutrition?"
          style={{ marginLeft: 0 }}
        />
      </Grid>
    </Grid>
  )
}
