import React, { forwardRef } from 'react'
import ListItem from '@material-ui/core/ListItem'
import { NavLink, NavLinkProps } from 'react-router-dom'

const AppMenuItemComponent = ( { className, onClick, link, children, parentName } ) => {

  // If link is not set return the ordinary ListItem
  if ( !link || typeof link !== 'string' ) {
    return (
      <ListItem
        button
        className={ className }
        children={ children }
        onClick={ onClick }
      />
    )
  }

  // Create link with openMenu query parameter
  const linkWithOpenMenu = `${ link }?openMenu=${ parentName }`

  // Return a ListItem with a link component
  return (
    <ListItem
      button
      className={ className }
      children={ children }
      component={ forwardRef( ( props: NavLinkProps, ref: any ) => <NavLink exact { ...props } innerRef={ ref } /> ) }
      to={ linkWithOpenMenu }
    />
  )
}

export default AppMenuItemComponent
