import { FormikBringgStatus } from 'admin/components/integrations/bringg/BringgStatus'
import { shouldShowOnDemandSaveButton } from 'actions/form'
import { SaveButton } from 'components/form/Buttons'

const OrderBringgForm = ({ formik_props }) => {
    return (
        <>
            <FormikBringgStatus formik_props={formik_props} />
            { shouldShowOnDemandSaveButton(formik_props, ["bringg_ref"]) &&
                <SaveButton />
            }
        </>
    )
}

export default OrderBringgForm
