import React from 'react'
import { delay } from 'lodash'
import { makeStyles, Button, Grid, TextField } from '@material-ui/core'
import { FormikInputField } from './form/InputField'
import {showSuccess, showError} from 'actions/Error'
import AuthContainer from './AuthContainer'
import { useHistory, Link } from 'react-router-dom'
import Logo from './layout/Logo'
import { Formik, Form, FieldArray, Field } from 'formik'
import { useDispatch } from 'react-redux'
import { userList } from 'actions/user'
import { login } from 'actions/auth'
import * as Yup from 'yup'

const useStyles = makeStyles((theme) => ({
  input: {
    fontSize: 24,
    [theme.breakpoints.down('sm')]: {
      fontSize: 16
    }
  }
}))

const validationSchema = Yup.object().shape({
    'email': Yup.string().required()
})

export default function ForgotPassword() {
    const classes = useStyles()
    const dispatch = useDispatch()
    const history = useHistory()
    const url_filter = new window.URLSearchParams(window.location.search)
    const initialValues = {}
    initialValues.email = url_filter.get('email') || ''

    const onSendForgotPassword = (values) => {

        return dispatch(userList.forgotPassword({email:values.email})).then( () => {
            showSuccess("Password reset email sent.")
            _.delay(redirect, 3000, "/login")
        })

    }

    const redirect = (url) => {
      history.push(url)
    }

    const renderForm = ({formik_props, classes}) => {
        return (
            <>
              <FormikInputField name="email"
                                type="email"
                                label="Email Address"
                                autoFocus
                                autoComplete="email"
                                formik_props={formik_props}
                                inputProps={
                                  {className: classes.input}
                                }
              />
              <Button
                type="submit"
                variant="contained"
                color="primary"
                size="large"
                fullWidth
                className={classes.submit}
              >
                Send reminder
              </Button>
            </>
        )
    }

    const renderOutsideForm = () => {
      return(
        <Grid container>
          <Grid item xs>
            <Button component={Link} to="/login" variant="body2">Back to login</Button>
          </Grid>
        </Grid>
      )
    }

    return (
        <AuthContainer renderForm={renderForm}
                        validationSchema={validationSchema}
                        onSubmit={onSendForgotPassword}
                        initialValues={initialValues}
                        renderOutsideForm={renderOutsideForm()} />
    )
}
