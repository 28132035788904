import React from 'react'
import { useDispatch } from 'react-redux'
import { Link, useParams, useHistory } from 'react-router-dom'
import { Formik, Form, FieldArray, Field } from 'formik'
import { FormikInputField } from './form/InputField'
import { login } from 'actions/auth'
import * as Yup from 'yup'
import { makeStyles, Grid, Button, CssBaseline, TextField, FormControlLabel, CircularProgress } from '@material-ui/core'
import AuthContainer from './AuthContainer'

const useStyles = makeStyles((theme) => ({
  input: {
    fontSize: 24,
    [theme.breakpoints.down('sm')]: {
      fontSize: 16
    }
  }
}))
const validationSchema = Yup.object().shape({
    'email': Yup.string().required(),
    'password': Yup.string().required(),
})

export default function Login() {
    const classes = useStyles()
    const dispatch = useDispatch()
    const history = useHistory()
    const url_filter = new window.URLSearchParams(window.location.search)
    const [loggingIn, setLoggingIn] = React.useState(false)
    const initialValues = {}
    initialValues.email = url_filter.get('email') || ''
    initialValues.user_otp = url_filter.get('user_otp') || ''
    initialValues.password = url_filter.get('user_otp') || ''

    const onLogin = (values) => {
        return dispatch(login(values.email, values.password)).then(history.push("/"))
    }

    const renderForm = ({formik_props, classes}) => {
        return (
            <>
              <FormikInputField name="email"
                                type="email"
                                label="Email Address"
                                autoFocus
                                autoComplete="email"
                                formik_props={formik_props}
                                inputProps={
                                  {className: classes.input}
                                }
              />
              <FormikInputField name="password"
                                type="password"
                                label="Password or OTP"
                                autoComplete="current-password"
                                formik_props={formik_props}
                                inputProps={
                                  {className: classes.input}
                                }
              />
              <Button
                type="submit"
                variant="contained"
                color="primary"
                size="large"
                fullWidth
                onClick={() => setLoggingIn(true)}
                className={classes.submit}
              >
                { loggingIn ? <><CircularProgress style={{marginRight: 15}} />Signing In...</> : <>Sign In</> }
              </Button>


            </>
        )
    }

    const renderOutsideForm = () => {
      return(
        <Grid container justifyContent="space-between">
          <Grid item>
            <Button component={Link} to="/forgot_password" variant="body2">Forgot password? </Button>
          </Grid>
          <Grid item>
            <Button component={Link} to="/register" variant="body2">Register</Button>
          </Grid>
        </Grid>
      )
    }

    return (
        <AuthContainer action="login"
                       renderForm={renderForm}
                       initialValues={initialValues}
                       validationSchema={validationSchema}
                       onSubmit={onLogin}
                       renderOutsideForm={renderOutsideForm()} />
    )
}
