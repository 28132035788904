import { ItemList } from 'orm'
import { map } from 'lodash'

class ContactCompanyList extends ItemList {
    getEntityKey() {
        return "contact/company"
    }

}

export const contactCompanyList = new ContactCompanyList("contact_company__default")

class ContactCompanyCheckImportFile extends ItemList {
    getEntityKey() {
        return "contact/company/check_import_file"
    }

    checkFile = ({parent_company_id, file_info}) => {
        return this.saveNewObject({parent: parent_company_id, file_info: file_info})
    }

}

export const contactCompanyCheckImportFile = new ContactCompanyCheckImportFile("contact_company_check_import_file__default")

