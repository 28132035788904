import { Grid } from "@material-ui/core"
import AdminMealtimes from "./AdminMealtimes"
import AdminFeedingFrequencies from "./AdminFeedingFrequencies"
import AdminFeedingSchedules from "./AdminFeedingSchedules"
import { Card } from "components/layout/Card"

export default function AdminFeedingGrid( { company_id, inTabs = true } ) {
  return (
    <Grid container spacing={ 2 }>
      <Grid item xs={ 12 } lg={ 8 }>
        <Card title="Schedules" content={<AdminFeedingSchedules company_id={ company_id } inTabs={inTabs} />} />
        
      </Grid>
      <Grid item xs={ 12 } lg={ 4 }>
        <Grid container spacing={ 2 }>
          <Grid item xs={ 12 }>
            <Card title="Mealtimes" content={<AdminMealtimes company_id={ company_id } inTabs={inTabs} />} />
          </Grid>
          <Grid item xs={ 12 }>
            <Card title="Frequency" content={<AdminFeedingFrequencies company_id={ company_id } inTabs={inTabs} />} />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}