import React, { useState, useEffect } from 'react'
import { Link, useHistory } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { get, startCase, round } from 'lodash'
import DonorCommonListLayout from '../layout/DonorCommonListLayout'
import ViewSelect from 'components/layout/ViewSelect'
import { MapWithMarkerClusterer } from 'components/map/MapMarkers'
import { Calendar } from 'components/layout/Calendar'
import { Modal } from 'components/layout/Modal'
import DonationWidgets from '../donations/widgets/DonationWidgets'
import { branches } from 'sampleData/sampleData'
import { Grid, Hidden, Button, Card, CardContent } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { getActiveCompanyId } from 'actions/active_company'
import { contactBranchList } from './actions/contact_branch'
import { ShortId } from 'components/ShortId'
import { CompanyBranchesImport } from '../branches/CompanyBranchesImport'

export default function DonorBranches() {
  //const classes = useStyles()
  const history = useHistory()
  const dispatch = useDispatch()
  const parent_id = getActiveCompanyId()
  const is_loading = useSelector(() => contactBranchList.isLoading())
  const contact_branches = useSelector(() => contactBranchList.getVisibleObjects({parent: parent_id}))
  const [showContact, setShowContact] = useState(false)
  const [showAddress, setShowAddress] = useState(false)
  const [showBranchesImportModal,setShowBranchesImportModal] = useState(false)

  useEffect(() => {
    dispatch(contactBranchList.updateListFilter({parent: parent_id}))
    dispatch(contactBranchList.fetchListIfNeeded({parent: parent_id}))
  },[])

  const onAddBranch = () => {
    history.push('/donor/branch/new')
  }
  const onEditBranch = (branch_id) => {
    history.push('/donor/branch/' + branch_id)
  }

  const onImportBranches = (branch_id) => {
      setShowBranchesImportModal(true)
  }

  const onUpdateListOrdering = (field) => {
      dispatch(contactBranchList.updateListOrdering(field))
  }

  const onImport = () => {
    // do super cool stuff
    setShowBranchesImportModal(false)
  }

  const columns = [
      { field: 'id',
        title: 'ID',
        render: (item) => <ShortId value={item.id} />,
        visible: true
      },
      { field: 'name',
        title: 'Branch',
        visible: true,
        render: (item) => <a onClick={() => onEditBranch(item.id)}>{startCase(get(item, ["name"]))}</a>,
        sort: (direction) => onUpdateListOrdering(direction === 'asc' ? 'name' : '-name')
      },
      { field: 'branch_code',
        title: 'Branch Code',
        visible: true,
        sort: (direction) => onUpdateListOrdering(direction === 'asc' ? 'branch_code' : '-branch_code')
      },
      { field: 'city_name',
        title: 'City',
        sort:(direction) => onUpdateListOrdering(direction === 'asc' ? 'addresses__city__name' : '-addresses__city__name')
      },
      { field: 'company.total_parcels_donated_count',
        title: 'Total Donations',
        visible: true,
        align: 'center',
        render: (item) => get(item, ["total_parcels_donated_count"]) ? get(item, ["total_parcels_donated_count"]) : "---",
        sort:(direction) => onUpdateListOrdering(direction === 'asc' ? 'total_parcels_donated_count' : '-total_parcels_donated_counts')
      },
      { field: 'total_parcels_donated_weight_kg',
        title: 'Total Weight',
        visible: true,
        align: 'right',
        render: (item) => get(item, ["total_parcels_donated_weight_kg"]) ? `${round(get(item, ["total_parcels_donated_weight_kg"]), 2).toLocaleString()} kg` : "---",
        sort:(direction) => onUpdateListOrdering(direction === 'asc' ? 'total_parcels_donated_weight_kg' : '-total_parcels_donated_weight_kg')
      },
      { field: 'total_parcels_donated_meals',
        title: 'Total Meals',
        visible: true,
        align: 'right',
        render: (item) => get(item , ["total_parcels_donated_meals"]) ? round(get(item , ["total_parcels_donated_meals"]), 0).toLocaleString() : "---",
        sort: (direction) => onUpdateListOrdering(direction === 'asc' ? 'total_parcels_donated_meals' : '-total_parcels_donated_meals')
      },
      { field: 'total_parcels_donated_value_rands',
        title: 'Total Value',
        visible: true,
        align: 'right',
        render: (item) => get(item , ["total_parcels_donated_value_rands"]) ? `R ${round(get(item , ["total_parcels_donated_value_rands"]), 0).toLocaleString()}` : "---",
        sort: (direction) => onUpdateListOrdering(direction === 'asc' ? 'total_parcels_donated_value_rands' : '-total_parcels_donated_value_rands')
      },
      { field: 'last_parcel_donated_date',
        title: 'Last Donation Date',
        align: 'center',
        render: (item) => get(item , ["last_parcel_donated_date"])? <Timestamp value={get(item, ["last_parcel_donated_date"])} format='datetime' /> : "---",
        sort:(direction) => onUpdateListOrdering(direction === 'asc' ? 'last_parcel_donated_date' : '-last_parcel_donated_date')
      },
      { field: 'last_parcel_donated_weight_kg',
        title: 'Last Donation Weight',
        align: 'right',
        render: (item) => get(item,  ["last_parcel_donated_weight_kg"]) ? `${round(get(item,  ["last_parcel_donated_weight_kg"]), 2).toLocaleString()} kg`: "---",
        sort:(direction) => onUpdateListOrdering(direction === 'asc' ? 'last_parcel_donated_weight_kg' : '-last_parcel_donated_weight_kg')
      },
      { field: 'last_parcel_donated_meals',
        title: 'Last Donation Meals',
        align: 'right',
        render: (item) => get(item,  ["last_parcel_donated_meals"])? round(get(item,  ["last_parcel_donated_meals"]), 0).toLocaleString() : "---",
        sort: (direction) => onUpdateListOrdering(direction === 'asc' ? 'last_parcel_donated_meals' : '-last_parcel_donated_meals')
      },
      { field: 'last_parcel_donated_value_rands',
        title: 'Last Donation Value',
        align: 'right',
        render: (item) => get(item,  ["last_parcel_donated_value_rands"])? `R ${round(get(item,  ["last_parcel_donated_value_rands"]), 0).toLocaleString()}` : "---",
        sort: (direction) => onUpdateListOrdering(direction === 'asc' ? 'last_parcel_donated_value_rands' : '-last_parcel_donated_value_rands')
      },
      { field: 'status',
        title: 'Status',
        render: (item) => get(item, ["status"]),
        sort:(direction) =>  onUpdateListOrdering(direction === 'asc' ? 'status' : '-status'),
      },
  ]

  const views = ["list", "calendar", "map"]
  const activeView = "list"
  const [view, setView] = useState('list')
  const sendViewToParent = (view) => {
   setView(view);
  }

  const renderContact = () => {
    return(
      <Modal onClose={() => closeContacts()} title={"Primary Contact"} noPadding>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell>Position</TableCell>
              <TableCell>Mobile</TableCell>
              <TableCell>Email</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
              <TableRow>
                <TableCell>{contact.first_name} {contact.last_name}</TableCell>
                <TableCell>{contact.position}</TableCell>
                <TableCell>{contact.mobile}</TableCell>
                <TableCell>{contact.email}</TableCell>
              </TableRow>
          </TableBody>
        </Table>
      </Modal>
    )
  }

  return (
      <>
      <DonorCommonListLayout active_key="branches"
                             breadcrumbs={[{name: 'donor_home'},
                                           {name: 'branches', label: "Branches", url: '/donor/branches'}]}
                             add_label="Add Branch"
                             import_label="Import Branches"
                             onAddRow={onAddBranch}
                             onImportRows={onImportBranches}
                             onEditRow={onEditBranch}
                             canDelete={true}
                             title="Branches"
                             is_loading={is_loading}
                             columns={columns}
                             item_list={contactBranchList}
                             handleRequestSort={columns}
                             enableAnyFieldFilter={true}
                             useColumnsSelector={true}
                             storeTableName={"contactBranchList"}
      />
    {showBranchesImportModal &&
      <Modal fullWidth maxWidth="sm" onClose={onImport} title="Import Branches">
        <CompanyBranchesImport onImport={onImport} />
      </Modal>
    }
    </>
  )
}
