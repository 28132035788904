import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { get } from 'lodash'
import AdminCommonListLayout from '../layout/AdminCommonListLayout'
import { adminFoodItemList } from './actions/admin_food_item'
import { ShortId } from 'components/ShortId'
import { AddButton } from 'components/form/Buttons'
import { Grid } from "@material-ui/core"
import { Modal } from 'components/layout/Modal'
import ImportNewFoodItemsCsvForm from './form/ImportNewFoodItemsCsvForm'

export default function AdminFoodItems() {

  const dispatch = useDispatch()
  const history = useHistory()
  const [showImportModal, setshowImportModal] = useState(false)
  const food_items = useSelector(() => adminFoodItemList.getVisibleObjects())
  const is_loading = useSelector(() => adminFoodItemList.isLoading())

  useEffect(() => {
    dispatch(adminFoodItemList.fetchListIfNeeded())
  }, [])

  const onEditFoodItem = (food_item_id) => {
    history.push({
      pathname: '/admin/food_item/' + food_item_id
    })
  }

  const onAddFoodItem = () => {
    history.push({
      pathname: '/admin/food_item/'
    })
  }

  const onUpdateListOrdering = (field) => {
    dispatch(adminFoodItemList.updateListOrdering(field))
  }

  const renderImportForm = () => {
    setshowImportModal(true)
  }

  const renderActions = () => {
    return (
      <Grid container spacing={2}>
        <Grid item>
          <AddButton
            label="CSV Import"
            color="primary"
            variant="outlined"
            type="import"
            with_icon={true}
            onClick={() => renderImportForm()}
          />
        </Grid>
      </Grid>
    )
  }

  const columns = [
    {
      field: 'id',
      title: 'ID',
      render: (item) => <ShortId value={item.id} />,
      width: 100
    },
    {
      field: 'name',
      title: 'Name',
      sort: (direction) => onUpdateListOrdering(direction === 'asc' ? 'name' : '-name')
    },
    {
      field: 'category',
      title: 'Category',
      sort: (direction) => onUpdateListOrdering(direction === 'asc' ? 'category' : '-category')
    }
  ]

  return (
    <>
      <AdminCommonListLayout
        active_key="food_items"
        breadcrumbs={[
          { name: 'admin_home' },
          { name: 'food_items', label: "Food Items", url: '/admin/food_items' }
        ]}
        add_label="Add Food Item"
        title={"Food Items"}
        onAddRow={onAddFoodItem}
        onEditRow={onEditFoodItem}
        is_loading={is_loading}
        columns={columns}
        item_list={adminFoodItemList}
        enableAnyFieldFilter={true}
        renderAdditionalActions={renderActions}
      />
      {showImportModal &&
        <Modal onClose={() => setshowImportModal(false)} fullWidth maxWidth="md" title={"Import Food Items from CSV"}>
          <ImportNewFoodItemsCsvForm />
        </Modal>}
    </>
  )
}
