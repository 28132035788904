import { ItemList } from 'orm'
import { map } from 'lodash'

class ContactCompanyMapList extends ItemList {
    getEntityKey() {
        return "contact/company_map"
    }
}

export const contactCompanyMapList = new ContactCompanyMapList("contact_company_map__default")
