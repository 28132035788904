import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { get, map, sum } from 'lodash'
import AdminCommonListLayout from '../layout/AdminCommonListLayout'
import { adminKpiDashboardList } from './actions/admin_kpi_dashboard'
import { adminKpiDashboardUserList } from './actions/admin_kpi_dashboard_user'
import { ShortId } from 'components/ShortId'
import { UserName } from 'admin/components/users/UserAttributes'
import { KpiDashboardAttributes } from './KpiAttributes'

export default function AdminKpiDashboardUsers({user_id}) {
    const dispatch = useDispatch()
    const history = useHistory()
    const users = useSelector(() => adminKpiDashboardUserList.getVisibleObjects())
    const is_loading = useSelector(() => adminKpiDashboardUserList.isLoading())
    const filter = useSelector(() => adminKpiDashboardUserList.getFilter())

    useEffect(() => {
        dispatch(adminKpiDashboardUserList.fetchListIfNeeded())
    },[])

    const onViewUserDashboard = (user_id) => {
        history.push({
            pathname: '/admin/kpis/dashboards/user/' + user_id
        })
    }

    const onUpdateListOrdering = (field) => {
      dispatch(adminKpiDashboardUserList.updateListOrdering(field))
    }

    const getUserDashboards = (item) => {
        const dashboards = map(get(item, "dashboards"), (dashboard) => {
            return <KpiDashboardAttributes dashboard_id={dashboard} />
        })
        return dashboards
    }

    const getKpiCount = (item) => {
        let dashboard_kpi_count = []
        map(get(item, "dashboards"), (dashboard) => {
            dashboard_kpi_count.push(<KpiDashboardAttributes dashboard_id={dashboard} get_kpi_count />)
        })
        const sum_dashboard_kpis = sum(dashboard_kpi_count)
        return(sum_dashboard_kpis)
    }


    const columns = [
        {
            field: 'id',
            title: 'ID',
            render: (item) => <a onClick={() => onViewUserDashboard(get(item, "id"))}><ShortId value={get(item, "id")} /></a>,
        },
        {
            field: 'user',
            title: 'User',
            render: (item) => <UserName user_id={ item.id } />,
            sort: (direction) => onUpdateListOrdering(direction === 'asc' ? 'user' : '-user')
        },
        {
            field: 'email',
            title: 'Email',
            sort: (direction) => onUpdateListOrdering(direction === 'asc' ? 'user' : '-user')
        },
        {
            field: 'dashboards',
            title: 'Dashboards',
            render: (item) => getUserDashboards(item),
        },
        {
            field: 'total_kpi_count',
            title: '# KPIs',
            render: (item) => getKpiCount(item),
            sort: (direction) => onUpdateListOrdering(direction === 'asc' ? 'total_kpi_count' : '-total_kpi_count')
        },
    ]

    return (
        <AdminCommonListLayout
            active_key="kpi_users"
            breadcrumbs={[
                {name: 'admin_home'},
                {name: 'kpi_users', label: "KPI Users", url: '/admin/kpis/users'}
            ]}
            title="KPI Users"
            can_delete={false}
            onViewRow={onViewUserDashboard}
            is_loading={is_loading}
            columns={columns}
            item_list={adminKpiDashboardUserList}
            rows={users}
        />
    )

}
