import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { get } from 'lodash'
import { adminKpiResultCommentList } from './actions/admin_kpi_result_comment'
import Loading from 'components/Loading'
import Timestamp from 'components/Timestamp'
import { UserName } from 'admin/components/users/UserAttributes'
import { Typography } from "@material-ui/core"

export const KpiResultCommentSingle = ({comment_id}) => {
    const dispatch = useDispatch()
    const comment = useSelector(() => adminKpiResultCommentList.getObject(comment_id))
    const is_loading = useSelector(() => adminKpiResultCommentList.isLoading())
    useEffect(() => {
        dispatch(adminKpiResultCommentList.ensureObjectLoaded(comment_id))
    },[])

    return (
        is_loading ? <Loading /> :
        <div style={{ padding: 10, background: '#efefef', margin: "7px 0", borderRadius: 8 }}>
            <Typography variant="caption">
                <span style={{ color: '#999' }}>
                    <UserName user_id={ get(comment, "user") } /> on <Timestamp value={ get(comment, "created_at") } format="at" /><br />
                </span>
                { get(comment, "comment") }
            </Typography>

        </div>

    )
}
