/** @jsxImportSource @emotion/react */
import React from "react";
import { css } from "@emotion/react";

const ReportBreakdown = React.forwardRef(({ data }, ref) =>{
  return (
    <>
      <table css={ table } ref={ ref }>
        <thead>
          <tr css={ report_table_header }>
            {data.headers.length > 0 ? (
              data.headers.map((header, index) => (
                <th css={ th } id={ index + header }>{ header }</th>
              ))
            ) : (
              <th />
            )}
          </tr>
        </thead>
        <tbody css={ report_table_data }>
          {data.rows.length > 0 ? (
            data.rows.map((row, index) => (
              <tr css={ tr } id={index}>
                {row.map((cell, index) => (
                  <td css={ td } id={ index + cell }>{ cell }</td>
                ))}
              </tr>
            ))
          ) : (
            <td />
          )}
        </tbody>
      </table>
    </>
  );
});
export default ReportBreakdown;


const table = css`
  border-collapse: collapse;
  background-color: unset;
  width: 100%;
`

const report_table_header = css`
  & th:not(:last-child) {
    border-right: 1px solid rgb(244, 224, 57);
  }
`

const th = css`
  padding: 8px !important;
  text-align: left;
  color: rgb(244, 224, 57) !important;
  font-size: 11px;
  border-bottom: 1px solid rgb(244, 224, 57) !important;
  background-color: rgb(0, 0, 0);
`

const report_table_data = css`
  & td:first-child {
    color: rgb(244, 224, 57) !important;
    font-weight: 800;
  }
`

const tr = css`
  & td:not(:last-child) {
    border-right: 1px solid rgb(244, 224, 57);
  }
`

const td = css`
  padding: 8px !important;
  text-align: left;
  color: rgb(255, 255, 255) !important;
  font-size: 11px;
`
