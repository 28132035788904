import React, { useEffect, useState } from "react"
import { CircularProgress } from "@material-ui/core"

export function LoadingItems({label}) {
  return(
    <div style={{display: 'inlineBlock', height: 50}}>
      <CircularProgress style={{color: "#ccc"}} />
      <div style={{marginLeft: 55, marginTop: -25, color: '#777'}}>{label}</div>
    </div>
  )
}
