import { ItemList } from 'orm'
import { map } from 'lodash'

class ActiveNotificationModelList extends ItemList {
    getEntityKey() {
        return "admin/notification_model/get_active_notification_models"
    }

    getAsOptions() {
        const items = this.getVisibleObjects()
        return map(items, function(item) {
          return { label: item.name, value: item.id }
        }
      )
    }
}

export const adminActiveNotificationModelList = new ActiveNotificationModelList("admin_active_notification_model__default")
