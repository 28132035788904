import React, { useEffect, useRef } from 'react'
import { get } from 'lodash'
import { FormikDropdownField } from 'components/form/Dropdown'
import { FormikInputField } from 'components/form/InputField'
import { FormikTextareaField } from 'components/form/TextareaField'
import { FormikCheckboxField } from 'components/form/CheckboxField'
import { useDispatch } from 'react-redux'
import { countryList } from 'actions/country'
import { provinceList } from 'actions/province'
import { CommonTable } from 'components/CommonTable'
import BeneficiaryMainLayout from 'portals/beneficiary/components/layout/BeneficiaryMainLayout'
import BeneficiaryTableFilter from 'portals/beneficiary/components/layout/BeneficiaryTableFilter'
import { Separator } from 'components/layout/Separator'
import { Button, Paper, Grid, makeStyles } from '@material-ui/core'
import SearchIcon from '@material-ui/icons/Search'
import { AddButton } from 'components/form/Buttons'

const useStyles = makeStyles((theme) => ({
  root: {
    [theme.breakpoints.down('sm')]: {
      padding: 15
    }
  },
  labelRoot: {
    paddingLeft: 15,
    fontSize: 24,
  },
  labelShinked: {
    marginLeft: 5,
    fontSize: 16
  },
  addLabel: {
    marginRight: 10
  },
  actions: {
    [theme.breakpoints.down('sm')]: {
      padding: 0,
      marginTop: -20,
      marginBottom: -15,
      marginLeft: -10,
      '& button': {
        width: 280,
        fontSize: 12
      },
    }
  },
  inTabs: {
    marginLeft: -24,
    marginRight: -24
  }
}))

const BeneficiaryCommonListTable = ({
  title,
  active_key,
  add_label,
  canDelete,
  onAddRow,
  showNumbers,
  noHeadings,
  useColumnsSelector,
  storeTableName,
  renderAdditionalFilter,
  enableAnyFieldFilter,
  renderAdditionalActions,
  onEditRow,
  is_loading,
  breadcrumbs,
  columns,
  item_list,
  inTabs
}) => {
    const classes = useStyles()
    const rows = item_list.getVisibleObjects()
    const dispatch = useDispatch()
    const componentRef = useRef(null)
    const style = inTabs ? classes.inTabs : classes.noTabs

    const onFilterChanged = (filter_values) => {
        dispatch(item_list.updateListFilter(filter_values))
        dispatch(item_list.fetchListIfNeeded())
    }

    const renderFilter = (formik_props) => {
        return (
            <div>
              { renderAdditionalFilter && renderAdditionalFilter(formik_props) }

              { enableAnyFieldFilter &&
                <FormikInputField name="any_field"
                                  formik_props={formik_props}
                                  show_search_icon={true}
                                  placeholder="Search..."
                                  InputLabelProps={{
                                    classes: {
                                      root: classes.labelRoot,
                                      shrink: classes.labelShinked
                                    }
                                  }}
                                  InputProps={
                                    {style:{
                                      borderRadius: '40px',
                                      paddingLeft: 15,
                                      fontSize: 24
                                    }
                                  }} />
              }
              <Separator variant="w5" />
            </div>
        )
    }

    return (
        <div className={classes.root}>
          <Grid container justifyContent="space-between" className={classes.actions}>

              { onAddRow &&
                <Grid>
                  <AddButton onClick={onAddRow} label={add_label} />
                  <Separator height={10} />
                </Grid>
              }
              { renderAdditionalActions &&
                <Grid>
                  { renderAdditionalActions() }
                 <Separator height={10} />
                </Grid>
              }

          </Grid>

          <BeneficiaryTableFilter
            label=""
            placeholder="Search"
            renderFilter={ renderFilter }
            updateOnChange={ onFilterChanged }
            style={{marginLeft: 10, marginRight: 10}}
          />

        <div className={style}>

            <CommonTable
              is_loading={ is_loading }
              rows={rows}
              columns={ columns }
              item_list={item_list}
              onEditRow={onEditRow}
              canDelete={canDelete}
              render_additional_filter={renderAdditionalFilter}
              useColumnsSelector={useColumnsSelector}
              storeTableName={storeTableName}
              showNumbers={showNumbers}
              noHeadings={noHeadings}
            />

          </div>

        </div>
  )
}

export default BeneficiaryCommonListTable
