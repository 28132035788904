import { ItemList } from 'orm'
import { map } from 'lodash'

class ContactDonorParcelList extends ItemList {
    getEntityKey() {
        return "contact/donor_parcel"
    }

}
export const contactDonorParcelList = new ContactDonorParcelList("contact_donor_parcel__default")


class ContactDonorParcelCheckProductsFile extends ItemList {
    getEntityKey() {
        return "contact/donor_parcel/check_parcel_file"
    }

    checkFile = ({file_info}) => {
        return this.saveNewObject({parcel_file_info: file_info})
    }

}

export const contactDonorParcelCheckProductsFile = new ContactDonorParcelCheckProductsFile("contact_donor_parcel_check_products_file__default")
