import React, { useEffect, useState } from "react"
import Alert from '@material-ui/lab/Alert'
import HelpOutlineIcon from '@material-ui/icons/HelpOutline'
import { Typography, Button } from "@material-ui/core"

export default function HelpText({title, text, severity}) {

  const [showHelp, setShowHelp] = useState(false)

  const CommonButton = ({onClick, label}) => {
    return(
      <Button
        onClick={onClick}
        variant="outlined"
        size="small"
        style={{color: "#777", marginBottom: 10}}
      >
        {label}
      </Button>
    )
  }

  const renderHelpText = () => {
    return(
      <>
      <CommonButton onClick={() => setShowHelp(false)} label="Close Help" />
      <Alert severity="info" style={{marginBottom: 10}}>
        <Typography variant="h6">{title || 'Steps'}</Typography>
        <Typography>
          {text}
        </Typography>
      </Alert>
      </>
    )
  }

  const renderHelpButton = () => {
    return (
      <CommonButton onClick={() => setShowHelp(true)} label="Need Help?" />
    )
  }

  return(
    <>
    { showHelp && renderHelpText() }
    {!showHelp && renderHelpButton()}
    </>
  )

}
