import { useState, useEffect } from 'react'
import { makeStyles, createStyles } from '@material-ui/core/styles'
import { userList } from 'actions/user'
import { filter, map } from 'lodash'
import { useHistory, useLocation } from 'react-router-dom'
import List from '@material-ui/core/List'
import IconDashboard from '@material-ui/icons/Dashboard'
import IconShoppingCart from '@material-ui/icons/ShoppingCart'
import IconBarChart from '@material-ui/icons/BarChart'
import IconAssignment from '@material-ui/icons/Assignment'
import IconBusiness from '@material-ui/icons/Business'
import IconAccountBalance from '@material-ui/icons/AccountBalance'
import IconPerson from '@material-ui/icons/Person'
import IconSettings from '@material-ui/icons/Settings'
import IconTrendingUp from '@material-ui/icons/TrendingUp'
import AppMenuItem from 'components/layout/menu/AppMenuItem'
import LocalOfferOutlinedIcon from '@material-ui/icons/LocalOfferOutlined'
import CategoryIcon from '@material-ui/icons/Category'

const drawerWidth = 240

const useStyles = makeStyles(theme =>
  createStyles({
    appMenu: {
      width: '100%',
    },
    navList: {
      width: drawerWidth,
    },
    menuItem: {
      width: drawerWidth,
    },
    menuItemIcon: {
      color: '#ccc',
    }
  })
)

const adminMenuItems = {
  dashboard: {
    name: 'Dashboard',
    link: '/admin',
    Icon: IconDashboard,
  },
  orders: {
    name: 'Orders',
    Icon: IconShoppingCart,
    items: [
      {
        id: "orders",
        name: "Orders",
        link: "/admin/parcels"
      },
      {
        id: "drivers",
        name: "Drivers",
        link: "/admin/drivers",
        admin_only: true,
      },
      {
        id: "vehicles",
        name: "Vehicles",
        link: "/admin/vehicles",
        admin_only: true,
      },
    ]
  },
  inventory: {
    name: 'Inventory',
    Icon: IconAssignment,
    items: [
      {
        id: "inventory_list",
        name: "Inventory",
        link: "/admin/inventory/list"
      },
      {
        id: "warehouses_list",
        name: "Warehouses",
        link: "/admin/warehouses",
        admin_only: true,
      },
    ]
  },
  products: {
    name: 'Products',
    Icon: LocalOfferOutlinedIcon,
    items: [
      {
        id: "products_list",
        name: "Products",
        link: "/admin/products"
      },
      {
        id: "product_categories_list",
        name: "Categories",
        admin_only: true,
        link: "/admin/product_categories"
      },
      {
        id: "brands_list",
        name: "Brands",
        link: "/admin/brands",
        admin_only: true,
      },
      {
        id: "manufacturers_list",
        name: "Manufacturers",
        link: "/admin/manufacturers",
        admin_only: true,
      },
      {
        id: "uoms",
        name: "UOMs",
        link: "/admin/uoms"
      },
    ]
  },
  companies: {
    name: 'Companies',
    Icon: IconBusiness,
    items: [
      {
        id: "food_donors",
        name: "Food Donors",
        link: "/admin/donors"
      },
      {
        id: "beneficiaries",
        name: "Beneficiaries",
        link: "/admin/beneficiaries"
      },
      {
        id: "demographics",
        name: "Demographics",
        admin_only: true,
        link: "/admin/demographics"
      },
      {
        id: "logistics_companies",
        name: "Logistics Partners",
        link: "/admin/logistics_companies"
      },
    ]
  },
  reports: {
    name: 'Reports',
    admin_only: true,
    link: '/admin/reports',
    Icon: IconBarChart,
  },
  finance: {
    name: 'Finance',
    admin_only: true,
    link: '/admin/money_donations',
    Icon: IconAccountBalance,
  },
  users: {
    name: 'Users',
    admin_only: true,
    link: '/admin/users',
    Icon: IconPerson,
  },
  kpis: {
    name: 'KPIs & Checklists',
    admin_only: true,
    Icon: IconTrendingUp,
    items: [
      {
        id: "kpi_results",
        name: "Results",
        link: "/admin/kpis/results"
      },
      {
        id: "kpi_dashboards",
        name: "Dashboards",
        link: "/admin/kpis/dashboards"
      },

      {
        id: "kpi_dashboard_users",
        name: "Users",
        link: "/admin/kpis/dashboards/users"
      },
      {
        id: "kpi_dashboard_categories",
        name: "Categories",
        link: "/admin/kpis/dashboard_categories"
      },
      {
        id: "checklist_results",
        name: "Results",
        link: "/admin/checklists/results"
      },
      {
        id: "checklists",
        name: "Checklists",
        link: "/admin/checklists"
      },
      {
        id: "checklist_schedule",
        name: "Schedule",
        link: "/admin/checklists/schedules"
      }
    ]
  },
  nutrition: {
    name: 'Nutrition',
    admin_only: true,
    Icon: CategoryIcon,
    items:[
      {
        id: "dashboard",
        name: "Dashboard",
        link: "/admin/nutrition_dashboard"
      },
      {
        id: "nutrients",
        name: "Nutrients",
        link: "/admin/nutrients"
      },
      {
        id: "food_items",
        name: "Food Items",
        link: "/admin/food_items"
      },
      {
        id: "nutrition_cards",
        name: "Nutrition Cards",
        link: "/admin/nutrition_cards"
      },
    ]
  },
  settings: {
    name: 'Settings',
    Icon: IconSettings,
    admin_only: true,
    items: [
      {
        id: "cities",
        name: "Cities",
        link: "/admin/cities"
      },
      {
        id: "notification_models",
        name: "Notification Models",
        link: "/admin/notification_models"
      },
      {
        id: "tags",
        name: "Tags",
        link: "/admin/tags"
      },
      {
        id: "editable_emails",
        name: "Emails",
        link: "/admin/editable_emails"
      },
      {
        id: "branches",
        name: "Branches",
        link: "/admin/cities"
      },
      {
        id: "contact_roles",
        name: "Contact Roles",
        link: "/admin/contact_roles"
      },
      {
        id: "core_businesses",
        name: "Core Businesses",
        link: "/admin/core_businesses"
      },
      {
        id: "starting_values",
        name: "Starting values",
        link: "/admin/starting_values"
      },
      {
        id: "bringg",
        name: "Bringg",
        link: "/admin/bringg_configuration"
      },
      {
        id: "release_notes",
        name: "Release notes",
        link: "/admin/release_notes"
      },
    ]
  },
}

const AdminMenu = () => {
  const classes = useStyles()
  const location = useLocation()
  const history = useHistory()
  const logged_in_user = userList.getUser()
  const isAdmin = logged_in_user.is_superuser

  const getOpenMenuFromUrl = () => {
    return new URLSearchParams( location.search ).get( 'openMenu' ) || ''
  }

  const setOpenMenuInUrl = ( openMenu ) => {
    const params = new URLSearchParams( location.search )
    if ( openMenu ) {
      params.set( 'openMenu', openMenu )
    } else {
      params.delete( 'openMenu' )
    }
    history.push( { search: params.toString() } )
  }

  const [ openMenu, setOpenMenu ] = useState( getOpenMenuFromUrl() )

  useEffect( () => {
    // Sync state with URL when the location changes
    setOpenMenu( getOpenMenuFromUrl() )
  }, [ location ] )

  return (
    <List component="nav" className={ classes.appMenu } disablePadding>
      { map( filter( adminMenuItems, item => !( item.admin_only && !isAdmin ) ),
        ( item, index ) =>
          <AppMenuItem
            { ...item }
            key={ index }
            openMenu={ openMenu }
            setOpenMenu={ setOpenMenuInUrl }
            isAdmin={ isAdmin }
            parentName={ item.name }
          />
      ) }
    </List>
  )
}

export default AdminMenu
