import React, { useEffect, useState } from 'react'
import { Link, useHistory } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { Typography } from '@material-ui/core'
import { isLoggedIn, loggedInUser, clearAuthentication } from 'actions/auth'
import { Button } from '@material-ui/core'
import { userList } from 'actions/user'
import { contactCompanyList } from 'actions/contact/company'
import { map, get, capitalize, head, filter, find } from 'lodash'
import { Grid, Collapse, List, ListSubheader, ListItem, ListItemIcon, ListItemText, Card, CardHeader, Divider, makeStyles, IconButton, CircularProgress } from '@material-ui/core'
import FolderIcon from '@material-ui/icons/Folder'
import BusinessIcon from '@material-ui/icons/Business'
import ExitToAppIcon from '@material-ui/icons/ExitToApp'
import MapIcon from '@material-ui/icons/Map'
import CloseIcon from '@material-ui/icons/Close'
import NavigateNextIcon from '@material-ui/icons/NavigateNext'
import LanguageIcon from '@material-ui/icons/Language'
import ExpandLess from '@material-ui/icons/ExpandLess'
import ExpandMore from '@material-ui/icons/ExpandMore'
import Alert from '@material-ui/lab/Alert'
import { getActiveCompanyId, setActiveCompany } from 'actions/active_company'
import LinearProgress from '@material-ui/core/LinearProgress'
import ContactCompaniesList from 'components/companies/ContactCompaniesList'

const useStyles=makeStyles((theme) => ({
  root: {
    background: '#FADF00',
    minHeight: '100vh',
  },
  homeBox: {
    borderRadius: 8,
    borderColor: 'transparent'
  },
  contentContainer: {
    margin: 'auto',
    width: 600,
    paddingTop: 20,
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      padding: 15
    }
  },
  menuIcon: {
    color: '#aaaaaa',
    marginRight: 15
  },
  menuCard: {
    marginTop: 15,
    color: '#777777'
  },
  nextText: {
    marginTop: 15,
    marginBottom: 15,
    color: "#777777"
  },
  alert: {
    marginBottom: 15,
    borderRadius: 8
  },
  contactName: {
    fontWeight: 500
  },
  logo: {
    marginBottom: 10,
    textAlign: 'center'
  }
}))

export default function LoggedInHome() {
    const classes = useStyles()
    const is_logged_in = isLoggedIn()
    const user = useSelector(() => userList.getUser())
    const contact_companies = useSelector(() => contactCompanyList.getVisibleObjects({can_login: true}))
    const is_loading = useSelector(() => !userList.isReady() && userList.isLoading())
    const is_staff = get(user, "is_staff")
    const is_superuser = get(user, "is_superuser")
    const dispatch = useDispatch()
    const history = useHistory()

    const [activeCompanyId, setActiveCompanyId] = useState(getActiveCompanyId())
    const [openMenu, setOpenMenu] = useState(true)
    const [openAlert, setOpenAlert] = useState(true)
    let switch_company = false

    useEffect(() => {
        const fetchStaticObjects = async() => {
            dispatch(userList.ensureUserLoaded())
            dispatch(contactCompanyList.updateListFilter({can_login: true}))
            dispatch(contactCompanyList.fetchListIfNeeded())
        }
        fetchStaticObjects()
    }, [])

    if(is_staff || is_superuser) {
      history.push("/admin")
    }
    const checkSwitchCompany = () => {
      const url_filter = new window.URLSearchParams(window.location.search)
      const url_params = {}
      url_params.switch_company = url_filter.get('switch_company') || null
      if(url_params.switch_company) { switch_company = true}
      return switch_company
    }

    const checkActiveCompany = (active_company_id) => {
      const contact_company = head(filter(contact_companies, (contact_company) => contact_company.id === activeCompanyId))
      const redirect = contact_company ? history.push(`/${get(contact_company, "company_type")}/${get(contact_company, "id")}`) : null
    }

    const checkSwitch = !checkSwitchCompany() ? checkActiveCompany(activeCompanyId) : null

    useEffect(() => {
      checkActiveCompany(activeCompanyId)
    }, [activeCompanyId])

    const onLogout = () => {
        dispatch(clearAuthentication())
        history.push("/login")
    }

    const onRedirect = (url) => {
        if(url === 'website') {
            window.location.href = 'https://saharvest.org'
        } else {
            history.push(url)
        }
    }

    const onSelectCompany = (company) => {
      setActiveCompany(company)
      setActiveCompanyId(get(company, "id"))
    }

    const handleMenuClick = () => {
      setOpenMenu(!openMenu)
    }

    return (
        <div className={classes.root}>

          <div className={classes.contentContainer}>
            { is_loading && <div style={{textAlign: 'center'}}><CircularProgress /></div> }
            <div className={classes.logo}>
              <a component={Link} onClick={() => onRedirect('/')}><img src="/static/images/logo.png" /></a>
            </div>
            <Collapse in={openAlert}>
              <Alert
                action={
                  <IconButton
                    aria-label="close"
                    color="inherit"
                    size="small"
                    onClick={() => {
                      setOpenAlert(false);
                    }}
                  >
                    <CloseIcon fontSize="inherit" />
                  </IconButton>
                }
                className={classes.alert}
              >
              <p className={classes.contactName}>Hi {capitalize(get(user, 'first_name'))}.</p>
              You are logged in. What do you want to do next?
              </Alert>
            </Collapse>

            <Card className={classes.homeBox}>
              <CardHeader title="Options" style={{color: 'black'}} />
              <Divider style={{background: '#eee'}}/>
              <List
                  component="nav"
                  aria-labelledby="nested-list-subheader"
                >
                  <ListItem button onClick={handleMenuClick} key="my_companies">
                    <ListItemIcon>
                      <BusinessIcon />
                    </ListItemIcon>
                    <ListItemText primary="My Companies" />
                    {openMenu ? <ExpandLess /> : <ExpandMore />}
                  </ListItem>
                  <Collapse in={openMenu} timeout="auto" unmountOnExit>
                    <List disablePadding>
                      <React.Suspense fallback={<CircularProgress />}>
                        <ContactCompaniesList onSelectCompany={onSelectCompany} />
                      </React.Suspense>
                    </List>
                  </Collapse>
                <ListItem button onClick={() => onRedirect('/impact')}  key="impact_map">
                  <ListItemIcon>
                    <MapIcon />
                  </ListItemIcon>
                  <ListItemText primary="Impact Map" />
                </ListItem>
                <ListItem button onClick={() => onRedirect('website')} key="website">
                  <ListItemIcon>
                    <LanguageIcon />
                  </ListItemIcon>
                  <ListItemText primary="SA Harvest Website" />
                </ListItem>
                <ListItem button onClick={onLogout} key="logout">
                  <ListItemIcon>
                    <ExitToAppIcon />
                  </ListItemIcon>
                  <ListItemText primary="Logout" />
                </ListItem>
              </List>

              { ! user && <div>Loading your details...</div> }
              { is_staff && <div>You are a staff user</div> }
              { is_superuser && <div>You are a super user</div> }



              <Typography variant="h5">
                <div style={{margin: 'auto', width: 180}}>
                  <br />

                  { (is_staff || is_superuser) &&
                    <Link to="/admin">Admin Portal</Link>
                  }
                </div>
              </Typography>
            </Card>
          </div>
        </div>
    )
}
