import { alpha } from '@material-ui/core/styles/colorManipulator';
import { withStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';

const StyledLabel = withStyles((theme) => {
  const isLight = theme.palette.type === 'light';

  return {
    root: (props) => {
      const filledVariant = props.variant === 'filled';
      const outlinedVariant = props.variant === 'outlined';
      const softVariant = props.variant === 'soft';
  
      const defaultStyle = {
        ...(props.color === 'default' && {
          // FILLED
          ...(filledVariant && {
            color: isLight ? theme.palette.common.white : theme.palette.grey[800],
            backgroundColor: theme.palette.text.primary,
          }),
          // OUTLINED
          ...(outlinedVariant && {
            backgroundColor: 'transparent',
            color: theme.palette.text.primary,
            border: `2px solid ${theme.palette.text.primary}`,
          }),
          // SOFT
          ...(softVariant && {
            color: theme.palette.text.secondary,
            backgroundColor: alpha(theme.palette.grey[500], 0.16),
          }),
        }),
      };
  
      const colorStyle = {
        ...(props.color !== 'default' && {
          // FILLED
          ...(filledVariant && {
            color: theme.palette[props.color].contrastText,
            backgroundColor: theme.palette[props.color].main,
          }),
          // OUTLINED
          ...(outlinedVariant && {
            backgroundColor: 'transparent',
            color: theme.palette[props.color].main,
            border: `2px solid ${theme.palette[props.color].main}`,
          }),
          // SOFT
          ...(softVariant && {
            color: theme.palette[props.color][isLight ? 'dark' : 'light'],
            backgroundColor: alpha(theme.palette[props.color].main, 0.16),
          }),
        }),
      };
  
      return {
        height: 24,
        minWidth: 24,
        lineHeight: 0,
        borderRadius: 6,
        cursor: 'default',
        alignItems: 'center',
        whiteSpace: 'nowrap',
        display: 'inline-flex',
        justifyContent: 'center',
        textTransform: 'capitalize',
        padding: theme.spacing(0, 0.75),
        fontSize: theme.typography.pxToRem(12),
        fontWeight: theme.typography.fontWeightBold,
        transition: theme.transitions.create('all', {
          duration: theme.transitions.duration.shorter,
        }),
        ...defaultStyle,
        ...colorStyle,
      };
    }
  };
})(Box);

export default StyledLabel;
