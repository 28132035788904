import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { clone, get } from 'lodash'
import { adminWarehouseProductList, adminWarehouseProductRetrospectiveList } from './actions/admin_warehouse_product'
import { Formik, Form, FieldArray, Field } from 'formik'
import Loading from 'components/Loading'
import { FormikProductAutoCompleteDropdown } from 'admin/components/products/form/ProductAutoCompleteDropdown'
import { FormikWarehouseAutoCompleteDropdown } from 'admin/components/warehouses/form/WarehouseAutoCompleteDropdown'
import { FormikDateTimePicker } from 'components/form/DatetimePicker'
import {showSuccess, showError} from 'actions/Error'
import FormCard from 'components/layout/FormCard'
import { handleSubmitResult } from 'actions/form'
import { FormikInputField } from 'components/form/InputField'
import { FormikTextareaField } from 'components/form/TextareaField'
import { FormikCheckboxField } from 'components/form/CheckboxField'
import { CardHeader } from 'components/layout/CardHeader'
import * as Yup from 'yup'
import { Grid, Table, TableRow, TableCell, makeStyles, Button } from '@material-ui/core'
import { FormikDonorAutoCompleteDropdown } from 'admin/components/donors/form/DonorAutoCompleteDropdown'
import { Modal } from 'components/layout/Modal'

const validationSchemaForNewInstance = Yup.object().shape({
    warehouse: Yup.string().required("Required"),
    product: Yup.string().required("Required"),
    quantity: Yup.number().required("Required"),
    reason_for_change: Yup.string().required("Required")
})

const validationSchemaForExistingInstance = Yup.object().shape({
    quantity: Yup.number().required("Quantity is required."),
    reason_for_change: Yup.string().required("Please provide a reason for this manual inventory change.")
})

const useStyles = makeStyles((theme) => ({
  th: {
    border: 'none',
    width: 180,
    fontWeight: 500
  },
  td: {
    border: 'none',
  },
}))

export const AdminWarehouseProductForm = ({onCancel, onSaved, warehouse_product}) => {
    const classes = useStyles()
    const dispatch = useDispatch()
    const initial_values = warehouse_product || {}
    const [productWeight, setProductWeight] = useState(initial_values.product_weight_kg)
    let total_weight_kg = initial_values.quantity * productWeight
    const is_new = ! get(warehouse_product, "id")
    const [overrideTotalWeightDisabled, setOverrideTotalWeightDisabled] = useState(true)
    const [showSelectDonor, setShowSelectDonor] = useState(false)
    const isSavingRetrospectiveDonor = useSelector(() => adminWarehouseProductRetrospectiveList.getIsSavingObject())

    const onSave = (values, formik_funcs) => {

        const on_ok = function(json) {
            dispatch(adminWarehouseProductList.invalidateList())
            showSuccess("Saved", "Product updated in warehouse")
            onSaved()
        }

        if ( warehouse_product ) {
            values.id = warehouse_product.id
            return dispatch(adminWarehouseProductList.saveObject(values)).then((res) => handleSubmitResult({res, formik_funcs, on_ok}))
        } else {
            return dispatch(adminWarehouseProductList.saveNewObject(values)).then((res) => handleSubmitResult({res, formik_funcs, on_ok}))
        }
    }

    const renderSelectDonor = (formik_props) => {
        return (
            <>
              { isSavingRetrospectiveDonor && <Loading /> }
              { ! isSavingRetrospectiveDonor &&
                <FormikDonorAutoCompleteDropdown name="from_company"
                                                 label="Donor"
                                                 formik_props={formik_props}
                                                 onChange={onRetrospectivelySelectDonor}
                />
              }
            </>
        )
    }

    const onRetrospectivelySelectDonor = (donor_id) => {
        if ( ! donor_id ) {
            return
        }
        if ( ! window.confirm("You sure you want to assign this donor to this inventory item?") ) {
            return
        }
        const on_ok = function(json) {
            dispatch(adminWarehouseProductList.invalidateObject(warehouse_product.id))
            dispatch(adminWarehouseProductList.ensureObjectLoaded(warehouse_product.id))
            showSuccess("Saved", "Product added to retrospective parcel")
            setShowSelectDonor(false)
        }

        const values = {donor: donor_id,
                        warehouse_product: warehouse_product.id}
        dispatch(adminWarehouseProductRetrospectiveList.saveNewObject(values)).then((res) => handleSubmitResult({res, formik_funcs: null, on_ok}))
    }

    const onSelectProduct = (product_weight) => {
        setProductWeight(product_weight)
    }

    const renderFormFields = (formik_props) => {
        return (
            <div>

              { ! is_new &&
                <Table size="small">
                    <TableRow>
                      <TableCell className={classes.th}>{!get(warehouse_product, "from_company_name") && 'No Associated '}Donor</TableCell>
                      <TableCell className={classes.td}>
                        { get(warehouse_product, "from_company_name") ?
                          get(warehouse_product, "from_company_name") :
                          <>{ !showSelectDonor ? <Button size="small" onClick={() => setShowSelectDonor(true)}>Link a donor to this inventory?</Button> : renderSelectDonor(formik_props) }</>
                        }
                    </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell className={classes.th}>Warehouse</TableCell>
                      <TableCell  className={classes.td}>{warehouse_product.warehouse_name}</TableCell>
                    </TableRow>
                </Table>
              }
              { is_new &&
                <>
                  <FormikWarehouseAutoCompleteDropdown name="warehouse"
                                                       label="Warehouse"
                                                       helperText={"* This field is required"}
                                                       formik_props={formik_props} />

                  <FormikProductAutoCompleteDropdown name="product"
                                                     label="Product"
                                                     helperText={"* This field is required"}
                                                     onSelectProduct={onSelectProduct}
                                                     formik_props={formik_props} />
                </>
              }

              <Grid container spacing={1}>

              <Grid item xs={6}>

                <FormikInputField name="quantity"
                                  label="Quantity"
                                  helperText={`Product item weight: ${productWeight}kg. * This field is required`}
                                  formik_props={formik_props} />

              </Grid>
              <Grid item xs={6}>

                <FormikInputField name="total_weight_kg"
                                  label="Total Weight (kg)"
                                  helperText={` = Quantity x Product item weight (${formik_props.values.quantity} x ${productWeight} kg)`}
                                  disabled={overrideTotalWeightDisabled}
                                  formik_props={formik_props} />

              <FormikCheckboxField name="override_total_weight"
                                   label="Override total weight?"
                                   formik_props={formik_props} />


              </Grid>

              <Grid item xs={6}>

                <FormikDateTimePicker label='Best before date'
                                      helperText="(optional)"
                                      name='best_before_date'
                                      formik_props={formik_props}
                                      include_time={false} />

              </Grid>
              <Grid item xs={6}>

                <FormikDateTimePicker label='Expiry date'
                                      helperText="(optional)"
                                      name='expiry_date'
                                      formik_props={formik_props}
                                      include_time={false} />



               </Grid>

              </Grid>

              <FormikTextareaField name="reason_for_change"
                                   label="Please give a reason for this manual change to inventory"
                                   helperText="* Required"
                                   formik_props={formik_props}
                                   rows={5} />

            </div>
        )
    }

    return (

        <div>

          <Formik initialValues={initial_values}
                  onSubmit={onSave}
                  enableReinitialize={true}
                  validationSchema={is_new ? validationSchemaForNewInstance : validationSchemaForExistingInstance}
          >
            { formik_props => {

                const { values } = formik_props

                useEffect(() => {
                  const allowEditableWeight = values.override_total_weight ? setOverrideTotalWeightDisabled(false) : setOverrideTotalWeightDisabled(true)
                  total_weight_kg = values.quantity * productWeight
                  formik_props.setFieldValue('total_weight_kg', total_weight_kg)

                }, [values.quantity, values.override_total_weight])

                return (

                    <Form>

                      <FormCard
                          isSubmitting={formik_props.isSubmitting}
                          onCancel={onCancel}
                      >

                        { renderFormFields(formik_props) }

                      </FormCard>

                    </Form>

                )
              }
            }
          </Formik>

        </div>

    )

}
