import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams, useHistory } from 'react-router-dom'
import { map, get, isEmpty } from 'lodash'
import { adminChecklistList } from '../actions/admin_checklist'
import { adminUserList } from 'admin/components/users/actions/admin_user'
import { adminChecklistScheduleList } from '../actions/admin_checklist_schedule'
import { adminChecklistQuestionList } from '../actions/admin_checklist_question'
import { adminChecklistResultList } from '../actions/admin_checklist_result'
import Loading from 'components/Loading'
import { Formik, Form } from 'formik'
import { FormikDropdownField } from 'components/form/Dropdown'
import FormCard from "components/layout/FormCard"
import { Typography, Grid } from "@material-ui/core"
import AdminChecklistAnswerForm from './AdminChecklistAnswerForm'
import { handleSubmitResult } from 'actions/form'
import {showSuccess, showError} from 'actions/Error'
import * as Yup from 'yup'
import { Card } from 'components/layout/Card'
import Timestamp from 'components/Timestamp'
import { UserName } from 'admin/components/users/UserAttributes'

export const validationSchema = Yup.object().shape({
  'checklist': Yup.string().required("Required"),
  'user': Yup.string().required("Required"),
})

const AdminChecklistResultForm = ({ onSaved, onSaveAnswer, onClose }) => {
  const dispatch = useDispatch()
  const history = useHistory()
  const disabled = false
  const { checklist_result_id } = useParams()
  const checklist_result  = useSelector(() => adminChecklistResultList.getObject(checklist_result_id))
  const checklist_schedule_id = get(checklist_result, 'checklist_schedule')
  const checklist_schedule = useSelector(() => adminChecklistScheduleList.getObject(checklist_schedule_id))
  const [checklistResultId, setChecklistResultId] = useState(checklist_result_id)
  // we need a checklist result record to post to so let's make sure that one exists else we need to create one
  let checklist_id
  let user_id
  let initial_values
  if(!isEmpty(checklist_schedule) && isEmpty(checklist_result)) {
    // if we have checklist_schedule record but a checklist_result record does not yet exist...
    checklist_id = get(checklist_schedule, "checklist")
    user_id = get(checklist_schedule, "user")
    initial_values = checklist_schedule
  } else if(!isEmpty(checklist_result)){
    // if we are editing an existing checklist_result record...
    checklist_id = get(checklist_result, "checklist")
    user_id = get(checklist_result, "user")
    initial_values = checklist_result
  }
  const checklist_options = useSelector(() => adminChecklistList.getAsOptions())
  const user_options = useSelector(() => adminUserList.getAsOptions())
  const checklist = useSelector(() => adminChecklistList.getObject(checklist_id))
  const is_loading = useSelector(() => adminChecklistList.isLoading())
  let questions
  let is_loading_questions
  if(checklist) {
    questions = useSelector(() => adminChecklistQuestionList.getObjects(get(checklist, "questions")))
    is_loading_questions = useSelector(() => adminChecklistQuestionList.isLoading())
  }

  useEffect(() => {
    dispatch(adminChecklistList.ensureObjectLoaded(checklist_id))
    dispatch(adminChecklistList.fetchListIfNeeded())
    dispatch(adminUserList.fetchListIfNeeded())
    dispatch(adminChecklistResultList.ensureObjectLoaded(checklistResultId))
  },[])

  useEffect(() => {
    dispatch(adminChecklistScheduleList.ensureObjectLoaded(checklist_schedule_id))
  },[checklist_result])

  useEffect(() => {
    dispatch(adminChecklistQuestionList.ensureObjectsLoaded(get(checklist, "questions")))
  },[checklist])

  useEffect(() => {
    dispatch(adminChecklistResultList.ensureObjectsLoaded(get(checklist, "questions")))
  },[questions])

  const onSave = (values, formik_funcs) => {
    let message = "Checklist result saved"
  
    const on_ok = function(json) {
      dispatch(adminChecklistResultList.invalidateList())
      dispatch(adminChecklistResultList.ensureObjectLoaded(get(json, "id")))
      setChecklistResultId(get(json, "id"))
      showSuccess("Saved", message)
      onSaveAnswer()
    }
  
    if (get(checklist_result, "id")) {
      return dispatch(adminChecklistResultList.saveObject(values)).then((res) =>
        handleSubmitResult({ res, formik_funcs, on_ok })
      )
    } else {
      message = "New checklist result created"
      if (checklist_schedule_id) {
        values.checklist_schedule = checklist_schedule_id
      }
      return dispatch(adminChecklistResultList.saveNewObject(values)).then((res) =>
        handleSubmitResult({ res, formik_funcs, on_ok })
      )
    }
  }

  const renderCreateResultsEntryIfNeeded = () => {
    return(
      <Formik
        initialValues={initial_values}
        enableReinitialize={true}
        onSubmit={onSave}
        validationSchema={validationSchema}
        >
        { formik_props => {
          const { values } = formik_props

          return(
            <Form>
              <FormCard isSubmitting={formik_props.isSubmitting} save_label="Start Now">
                <Grid container spacing={2}>
                  <Grid item xs={6} lg={6}>
                    <FormikDropdownField
                      name="checklist"
                      label="Checklist"
                      options={checklist_options}
                      formik_props={formik_props}
                      disabled={disabled}
                      />
                  </Grid>
                  <Grid item xs={6} lg={6}>
                    <FormikDropdownField
                      name="user"
                      label="User"
                      options={user_options}
                      formik_props={formik_props}
                      disabled={disabled}
                      />
                  </Grid>
                </Grid>
              </FormCard>
            </Form>
          )
        }}
      </Formik>
    )
  }

  const renderQuestionsForm = () => {
    return(
      <div style={{padding: 15}}>
        <Typography variant="caption" style={{marginBottom: 15}}>[ <a onClick={() => history.push('/admin/checklists/schedules')}>Back to Checklist Schedule ]</a></Typography>
        <Typography variant="h6">{ get(checklist, "name") }</Typography>
        <Typography variant="caption"><i>Responsible person: <UserName user_id={get(checklist_schedule, 'user')} /></i><br/></Typography>
        <Grid container spacing={2}>

          <Grid item xs={12} md={9}>
            <Card
              title="Questions"
              content={ map(questions, (question) => {
                return (
                  <AdminChecklistAnswerForm
                    question={ question }
                    checklist_result_id={ checklist_result_id }
                    onSaved={ onSaved }
                    is_loading_questions={ is_loading_questions }
                    checklist_completed={ get(checklist_result, 'completed_at') }
                    />
                )
              })}
              />
          </Grid>

          <Grid item xs={12} md={3}>
            <Card
              title="Score"
              content={
                <>
                <Typography variant="h5">{ get(checklist_result, 'percentage_completed')}% completed</Typography>
                <Typography >Required pass mark: {get(checklist, 'pass_percentage')}%</Typography>
                { get(checklist_result, 'completed_at') &&
                  <Typography variant="caption">Completed on <Timestamp value={get(checklist_result, 'completed_at')} format='at' /><br /></Typography>
                }
                </>
            }
            />
          </Grid>

        </Grid>
      </div>
    )
  }

  return(
    <>
    { checklistResultId ?
      is_loading_questions ? <Loading /> : renderQuestionsForm() :
      renderCreateResultsEntryIfNeeded()
    }
    </>
  )
}

export default AdminChecklistResultForm
