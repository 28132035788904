import React, { useState } from 'react'
import DonorMainLayout from '../layout/DonorMainLayout'
import { Tooltip, Typography, Grid, Paper, Hidden, Button, IconButton, Card, CardContent, TextField, TextArea, Select, MenuItem } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import AttachFileIcon from '@material-ui/icons/AttachFile'
import RefreshIcon from '@material-ui/icons/Refresh'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import ExpandLessIcon from '@material-ui/icons/ExpandLess'
import PageTitle from '../layout/PageTitle'

const useStyles = makeStyles({
  filterButton: {
    borderRadius: 20,
    background: '#dfdfdf'
  },
  br: {
    marginRight: 7
  },
  ticketContainer: {
    width: '100%',
    minHeight: '85vh',
    border: '1px solid #efefef',
    padding: 15,
    borderRadius: 0
  },
  textarea: {

  }
})

export default function DonorTicket() {
  const classes = useStyles()
  const options = [
    "Order 1234", "Order 12345"
  ]

  return(
    <DonorMainLayout
           active_key="ticket"
           breadcrumbs={[
              {name: 'donor_home'},
              {name: 'tickets', label: "Tickets", url: '/donor/tickets'},
              {name: 'ticket', label: "New Ticket", url: '/donor/tickets/new'}
          ]}
    >
    <PageTitle title={"Create a New Ticket"} />
    <Grid container spacing={1}>
      <Grid item xs={12} lg={9}>
      <Paper elevation={0} className={classes.ticketContainer}>

        <TextField label="Subject" variant="outlined" fullWidth margin="normal" />
        <Grid container spacing={1}>
          <Grid item xs={12} lg={4}>
            <TextField
              labelId="demo-customized-select-label"
              id="demo-customized-select"
              select
              fullWidth
              variant="outlined"
              label="Select an order"
            >
                <MenuItem value="">
                  <em>None</em>
                </MenuItem>
                <MenuItem value={10}>Order #12345 - 26/3/2021</MenuItem>
                <MenuItem value={20}>Order #12346 - 27/3/2021</MenuItem>
                <MenuItem value={30}>Order #12347 - 28/3/2021</MenuItem>
              </TextField>
            </Grid>
            <Grid item xs={12} lg={4}>
              <TextField
              labelId="priority"
              id="priority"
              select
              fullWidth
              variant="outlined"
              label="Priority"
            >
              <MenuItem value=""><em>None</em></MenuItem>
              <MenuItem value={'low'}>Low</MenuItem>
              <MenuItem value={'normal'}>Normal</MenuItem>
              <MenuItem value={'high'}>High</MenuItem>
              <MenuItem value={'urgent'}>Urgent</MenuItem>
            </TextField>
          </Grid>
          <Grid item xs={12} lg={4}>
            <TextField
              labelId="ticket_type"
              id="ticket_type"
              select
              fullWidth
              variant="outlined"
              label="Ticket Type"
            >
              <MenuItem value="">
                <em>None</em>
              </MenuItem>
              <MenuItem value={'question'}>Question</MenuItem>
              <MenuItem value={'incident'}>Incident</MenuItem>
              <MenuItem value={'problem'}>Problem</MenuItem>
              <MenuItem value={'task'}>Task</MenuItem>
            </TextField>
          </Grid>
        </Grid>
        <TextField
          placeholder="Description..."
          multiline
          fullWidth
          variant="outlined"
          rows={7}
          rowsMax={50}
          margin="dense"
          className={classes.textarea}
        />

      <Button variant="outlined" component="label">
          <AttachFileIcon />
          <input type="file" hidden/>

       </Button>
       <Grid container justify="flex-end">
         <Button variant="contained" component="label" size="large" color="primary">
           Submit
        </Button>
      </Grid>
      </Paper>
      </Grid>

      <Grid item xs={12} lg={3}>
        <Paper elevation={0} className={classes.ticketContainer} spacing={3}>
          <Grid container justifyContent="space-between">
            <Grid item>
              <Typography variant="h6">History</Typography>
            </Grid>
            <Grid item>
              <Tooltip title={"Refresh history"}>
                <IconButton size="small"><RefreshIcon /></IconButton>
              </Tooltip>
              <Tooltip title={"Expand history"}>
                <IconButton size="small"><ExpandMoreIcon /></IconButton>
              </Tooltip>
            </Grid>
          </Grid>
        </Paper>
      </Grid>

      </Grid>

    </ DonorMainLayout>
  )
}
