import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useParams } from 'react-router-dom'
import { get, filter } from 'lodash'
import { adminKpiDashboardList } from './actions/admin_kpi_dashboard'
import {showSuccess, showError} from 'actions/Error'
import { handleSubmitResult } from 'actions/form'
import AdminMainLayout from '../layout/AdminMainLayout'
import AdminKpiDashboardForm from './form/AdminKpiDashboardForm'
// import { AdminEmployeeRolesDropdownForm } from 'admin/components/users/form/AdminEmployeeRolesDropdownForm'
import { AdminUsersDropdownForm } from 'admin/components/users/form/AdminUsersDropdownForm'
import { validationSchema } from './form/AdminKpiDashboardForm'
// import { FormikGeneralFormErrors } from 'components/form/GeneralFormErrors'
import { Formik, Form } from 'formik'
// import FormCard from 'components/layout/FormCard'
// import { SaveButton } from 'components/form/Buttons'
// import AdminKpiTemplatesTable from './AdminKpiTemplatesTable'
import AdminDashboardKpis from './AdminDashboardKpis'
import { Card } from 'components/layout/Card'
import { Grid } from '@material-ui/core'
import Loading from 'components/Loading'

export default function AdminKpiDashboard() {
    const dispatch = useDispatch()
    const history = useHistory()
    const { kpi_dashboard_id } = useParams()
    const detailsNumCols = kpi_dashboard_id ? 6 : 12
    const kpi_dashboard = useSelector(() => adminKpiDashboardList.getObject(kpi_dashboard_id))
    const kpi_dashboard_name = get(kpi_dashboard, [0, "name"])
    const is_loading = useSelector(() => adminKpiDashboardList.isLoading())  || (kpi_dashboard_id && !get(kpi_dashboard, "id"))
    const is_busy = useSelector(() => adminKpiDashboardList.getIsSavingObject())
    const initial_values = kpi_dashboard
    const title = `${get(kpi_dashboard, "name", 'New')} Dashboard`

    useEffect(() => {
        dispatch(adminKpiDashboardList.ensureObjectLoaded(kpi_dashboard_id))
    },[])

    const onSave = (values, formik_funcs) => {
        const on_ok = function(json) {
            dispatch(adminKpiDashboardList.invalidateList())
            showSuccess("Saved", "KPI Dashboard saved")
            if ( ! kpi_dashboard_id ) {
                history.push(`/admin/kpis/dashboard/${json.id}`)
            }
        }
        if ( kpi_dashboard_id ) {
            values.id = kpi_dashboard_id
            return dispatch(adminKpiDashboardList.saveObject(values)).then((res) => handleSubmitResult({res, formik_funcs, on_ok}))
        } else {
            return dispatch(adminKpiDashboardList.saveNewObject(values)).then((res) => handleSubmitResult({res, formik_funcs, on_ok}))
        }
    }

    const getCellValue = (header_key, item, index) => {
        switch( header_key ) {
            default:
            return undefined
        }
    }

    return (
        <AdminMainLayout
            breadcrumbs={[
                {name: 'admin_home'},
                {name: 'kpi_dashboards', label: 'KPI Dashboards', url: '/admin/kpis/dashboards'},
                {name: 'kpi_dashboard',  label: title, url: `/admin/kpi/dashboard/${kpi_dashboard_id}`}
            ]}
            is_busy={is_busy}
            is_loading={is_loading}
            title={title}
            >

            <Formik
                initialValues={initial_values}
                onSubmit={onSave}
                enableReinitialize={true}
                validationSchema={validationSchema}
            >
                { formik_props => {
                    const { values } = formik_props

                    const onUpdateRoles = (roles) => {
                        values.roles = roles
                    }

                    const onUpdateUsers = (users) => {
                        values.users = users
                    }

                    return (
                        <Form>
                            <Grid container spacing={2} justify="center">

                                <Grid item xs={12} md={6} lg={detailsNumCols}>
                                    <Card
                                        title="Dashboard Details"
                                        content={ is_loading ? <Loading /> : <AdminKpiDashboardForm formik_props={formik_props} initial_values={kpi_dashboard[0]} /> }
                                    />
                                </Grid>
                                { get(kpi_dashboard, "id") &&
                                    <>
                                    <Grid item xs={12} md={6}>
                                        <Grid container spacing={2}>
                                            {/*
                                            <Grid item xs={12} md={6} lg={12}>
                                                <Card
                                                    title="Roles"
                                                    content={ is_loading ? <Loading /> : <AdminEmployeeRolesDropdownForm onUpdateRoles={onUpdateRoles} /> }
                                                />
                                            </Grid>
                                            */}

                                            <Grid item xs={12}>
                                                <Card
                                                    title="Users"
                                                    content={ is_loading ? <Loading /> : <AdminUsersDropdownForm onUpdateUsers={onUpdateUsers} dashboard_users={formik_props.values} /> }
                                                />
                                            </Grid>
                                        </Grid>
                                    </Grid>

                                    <Grid item xs={12}>
                                        <Card
                                            title="KPIs"
                                            noPadding
                                            content={ is_loading ? <Loading /> :
                                                <AdminDashboardKpis
                                                    kpi_dashboard_id={ kpi_dashboard_id }
                                                    kpi_dashboard_name={ kpi_dashboard_name }
                                                />
                                            }
                                        />
                                    </Grid>
                                    </>
                                }

                            </Grid>
                        </Form>
                    )
                }}
            </Formik>

            {/* <Card
                title="History"
                noPadding
                content={ is_loading ? <Loading /> : <AdminKpiHistory kpi_dashboard_id={kpi_dashboard_id} /> }
            /> */}

        </AdminMainLayout>
    )
}
