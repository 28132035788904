import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { head, get, map } from 'lodash'
import { Link } from 'react-router-dom'
import DonorMainLayout from '../layout/DonorMainLayout'
import BusyMask from 'components/BusyMask'
import Timestamp from 'components/Timestamp'
import { contactParcelList } from './actions/contact_parcel'
import DonorCommonListLayout from '../layout/DonorCommonListLayout'
import { ParcelStatus } from 'components/orders/ParcelStatus'
import NumberFormat from 'react-number-format'
import { Typography, Table, TableHead, TableBody, TableRow, TableCell, Button } from '@material-ui/core'
import { Modal } from 'components/layout/Modal'

export default function Donations({...props}) {
    const dispatch = useDispatch()
    const history = useHistory()
    var pathArray = window.location.pathname.split('/')
    let direction = 'beneficiary_dropoff'

    const is_loading = useSelector(() => contactParcelList.isLoading())
    const contact_parcels = useSelector(() => contactParcelList.getVisibleObjects({direction: direction}))
    const not_set = (title) => {
      return <Typography variant="caption" color="secondary">{title ? <>{title} not set</> : "Not set"}</Typography>
    }
    const [showProducts, setShowProducts] = useState(false)
    const [products, setProducts] = useState(false)

    useEffect(() => {
      dispatch(contactParcelList.updateListFilter({direction: direction}))
      dispatch(contactParcelList.fetchListIfNeeded({direction: direction}))
    }, [])


    const filterContactParcels = (filter_values) => {
        dispatch(contactParcelList.updateListFilter(filter_values))
    }

    const renderProducts = (products) => {
      setProducts(products)
      setShowProducts(true)
    }

    const closeProducts = (products) => {
      setProducts(null)
      setShowProducts(false)
    }

    const columns = [
      { field: 'short_ref',
          title: 'Ref',
          width: 115,
          sort: (direction) => onUpdateListOrdering(direction === 'asc' ? 'short_ref' : '-short_ref'),
        },
        { field: 'to_company_name',
          title: 'Beneficiary',
          render: (item) => <Link to={`/impact/beneficiary/${get(item, 'to_company')}`} target="_blank">{get(item, 'to_company_name')}</Link>,
          sort: (direction) => onUpdateListOrdering(direction === 'asc' ? 'to_company_name' : '-to_company_name'),
        },
        { field: 'arrive_at',
          title: 'Date',
          width: 220,
          render: (item) => <Timestamp format={"datetime"} value={item.arrive_at} />,
          sort: (direction) => onUpdateListOrdering(direction === 'asc' ? 'arrive_at' : '-arrive_at'),
        },
        { field: 'total_weight_kg',
          title: 'Weight',
          width: 150,
          render: (item) => get(item,["total_weight_kg"]) > 0 ? <NumberFormat value={get(item,["total_weight_kg"])} suffix={"kg"} displayType={'text'} thousandSeparator={true} decimalScale={2} /> : not_set(),
          sort: (direction) => onUpdateListOrdering(direction === 'asc' ? 'total_weight_kg' : '-total_weight_kg'),
        },
        { field: 'num_meals',
          title: 'Meals',
          width: 150,
          render: (item) => get(item,["parcel_products_meals"]) > 0 ? <NumberFormat value={get(item,["num_meals"])} displayType={'text'} thousandSeparator={true} decimalScale={0} /> : not_set(),
          sort: (direction) => onUpdateListOrdering(direction === 'asc' ? 'num_meals' : '-num_meals'),
        },
        { field: 'parcel_products',
          title: 'Products',
          render: (item) => get(item, "parcel_products").length > 0 ?
              <Button
                onClick={ () => renderProducts(get(item, "parcel_products")) }
                size="small"
                variant="outlined"
              >
                Products
              </Button> :
              "---"
        },
        { field: 'status',
          title: 'Status',
          width: 100,
          render: (item) => <ParcelStatus status={item.status} />,
          sort: (direction) => onUpdateListOrdering(direction === 'asc' ? 'status' : '-status'),
        },
    ]


    return (
      <>
      <DonorCommonListLayout active_key="donations"
                             breadcrumbs={[{name: 'donor_home'},
                                           {name: 'email', label: "Distribution", url: '/donor/distribution'}]}
                             canDelete={false}
                             title="Distribution"
                             is_loading={is_loading}
                             columns={columns}
                             item_list={contactParcelList}
                             enableAnyFieldFilter
      />
      { showProducts &&
        <Modal onClose={() => closeProducts()} title={"Products Donated"} noPadding>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Product</TableCell>
                <TableCell>Qty</TableCell>
                <TableCell>Weight</TableCell>
                <TableCell>Value</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
            { map(products, (product) => {
              return(
                <TableRow>
                  <TableCell>{product.product_name}</TableCell>
                  <TableCell>{product.actual_quantity}</TableCell>
                  <TableCell>{product.total_weight_kg} kg</TableCell>
                  <TableCell>R{product.total_value_rands}</TableCell>
                </TableRow>
              )
            })}
            </TableBody>
          </Table>
        </Modal> }
      </>
    )
}
