import React, { useState } from 'react'
import AdminContactForm from 'admin/components/contacts/form/AdminContactForm'
import { makeStyles, Grid, Paper, Card, Typography, Tooltip, Button, Dialog, FormControl, FormControlLabel, RadioGroup, Radio, Chip } from '@material-ui/core'
import AddIcon from '@material-ui/icons/Add'
import CheckIcon from '@material-ui/icons/Check'
import Obfuscate from 'react-obfuscate'

const useStyles = makeStyles({
  root: {
    marginTop : 10,
    position: 'relative',
  },
  contactName: {
    fontWeight: 500
  },
  cardHeading: {
    marginBottom: 15
  },
  cardRadio: {
    position: 'absolute',
    bottom: 0,
    right: -15
  },
  contactCard: {
    borderTop: '1px solid #cfd8dc',
    borderRadius: 0,
    paddingTop: 20,
    marginBottom: 10,
  }
})

export default function CompanyContacts({contacts}) {
  const classes = useStyles()

  return(
    <div className={classes.root}>

      {contacts.map(contact => {
        return(
          <Card elevation={0} className={classes.contactCard}>
            <Grid container spacing={2}>
              <Grid item xs={12} lg={4}>
                <img src={contact.profile_pic} alt={contact.name} width={"100%"} />
              </Grid>
              <Grid item xs={12} lg={8}>
                <Typography className={classes.contactName}>{contact.name}</Typography>
                <Typography variant="caption">
                {contact.position && <>{contact.position} | </> }
                {contact.email &&
                  <>
                  <Obfuscate
                    email={contact.email}
                    target="_blank"
                    headers={{
                      cc: '',
                      bcc: '',
                      subject: 'Contact from SA Harvest platform',
                      body: 'This is the body of the message'
                    }}
                  /> | </> }
                {contact.mobile && <><Obfuscate tel={contact.mobile} /><br /></> }
                </Typography>
                {contact.description &&   <p>{contact.description}</p>}


              </Grid>
            </Grid>
          </Card>
        )
      }
      )}
    </div>
  )
}
