import React, { useEffect } from 'react'
import { get } from 'lodash'
import { useDispatch, useSelector } from 'react-redux'
import { FormikInputField } from 'components/form/InputField'
import { FormikCheckboxField } from 'components/form/CheckboxField'
import { FormikNutritionFoodItemAutoCompleteDropdown } from 'admin/components/nutrition/form/NutritionFoodItemAutoCompleteDropdown'
import { Formik, Form, ErrorMessage } from 'formik'
import FormCard from 'components/layout/FormCard'
import { showSuccess, showError } from 'actions/Error'
import { adminProductCategoryList } from '../actions/admin_product_category'
import { handleSubmitResult } from 'actions/form'
import { ProductCategorySelectField } from './ProductCategorySelectField'
import { Typography } from '@material-ui/core'
import Alert from '@material-ui/lab/Alert'
import * as Yup from 'yup'
import { NutritionFactsFoodItem } from '../../nutrition/NutritionFactsFoodItem'
import { Separator } from 'components/layout/Separator'
import { FormikAutoCompleteDropdown } from 'components/form/AutoCompleteDropdown'

const validationSchema = Yup.object().shape( {
  'name': Yup.string().required( "Required" ),
} )

const ProductCategoryInlineForm = ( { product_category, product_category_id, from_parcel, is_new, adding_child_of_parent_product_category, onSaved, onCancel } ) => {

  const dispatch = useDispatch()
  if ( !product_category && product_category_id ) {
    product_category = useSelector( () => adminProductCategoryList.getObject( product_category_id ) )
  }

  useEffect( () => {
    dispatch( adminProductCategoryList.invalidateList() )
    dispatch( adminProductCategoryList.ensureObjectLoaded( product_category_id ) )
  }, [ product_category_id, dispatch ] )

  const initial_values = Object.assign( {}, product_category )

  const onSave = ( values, formik_funcs ) => {
    const on_ok = function( json ) {
      dispatch( adminProductCategoryList.invalidateList() )
      showSuccess( "Saved", "Product category saved" )
      onSaved( json )
    }
    if ( !values.parent && adding_child_of_parent_product_category ) {
      values.parent = get( adding_child_of_parent_product_category, "id" )
    }
    if ( product_category && product_category.id ) {
      values.id = product_category.id
      return dispatch( adminProductCategoryList.saveObject( values ) ).then( ( res ) => handleSubmitResult( { res, formik_funcs, on_ok } ) )
    } else {
      return dispatch( adminProductCategoryList.saveNewObject( values ) ).then( ( res ) => handleSubmitResult( { res, formik_funcs, on_ok } ) )
    }
  }

  return (
    <Formik
      initialValues={ initial_values }
      onSubmit={ onSave }
      enableReinitialize={ true }
      validationSchema={ validationSchema }
    >
      { formik_props => {
        const { values, errors } = formik_props

        return (
          <Form>
            <FormCard
              isSubmitting={ formik_props.isSubmitting }
              onCancel={ onCancel }
              elevation={ 0 }
            >
              {/* 
              <CardParagraphHeading>
                {!get(product_category, "id") && adding_child_of_parent_product_category &&
                  <Typography variant="h6">New Child of {adding_child_of_parent_product_category.name}</Typography>
                }
                {!get(product_category, "id") && !adding_child_of_parent_product_category &&
                  <Typography>New Top Level Product Category</Typography>
                }
                {get(product_category, "id") &&
                  <Typography variant="h6">Edit {get(values, "name")}</Typography>
                }
                <Divider style={{ margin: '15px -16px' }} />
              </CardParagraphHeading> 
              */}

              { !from_parcel &&
                <>
                  { !adding_child_of_parent_product_category &&
                    <ProductCategorySelectField
                      name="parent"
                      can_add={ false }
                      can_edit={ false }
                      formik_props={ formik_props }
                      empty_label="Select Parent category"
                      fullWidth
                    />
                  }
                  { product_category ?
                    <FormikInputField
                      name="name"
                      label="Name"
                      formik_props={ formik_props }
                    /> :
                    <FormikAutoCompleteDropdown
                      item_list={ adminProductCategoryList }
                      name="name"
                      label="Name"
                      freeSolo={ true }
                      formik_props={ formik_props }
                    />
                  }
                  <FormikInputField
                    name="alernative_names"
                    label="Alternative names"
                    helperText="Comma separated list of alternative names"
                    formik_props={ formik_props }
                    spellCheck={ true }
                  />
                </>
              }

              { !formik_props.values.nutrition_food_item &&
                <>
                  <Separator height={ 20 } />
                  <Alert severity="info">
                    <Typography>Nutrition</Typography>
                    Select a nutrition food item for this product category so that the system can generate nutrition information for all products belonging to this category.
                  </Alert>
                </>
              }
              <Separator height={ 20 } />
              <FormikNutritionFoodItemAutoCompleteDropdown
                name="nutrition_food_item"
                label="Nutrition Food Item"
                formik_props={ formik_props }
              />

              <FormikCheckboxField
                name="show_nutrition_information"
                label="Show nutrition information?"
                formik_props={ formik_props }
              />
              <br />
              { formik_props.values.show_nutrition_information &&
                <>
                  <NutritionFactsFoodItem food_item_id={ formik_props.values.nutrition_food_item } />
                </>
              }

            </FormCard>
          </Form>
        )
      }
      }
    </Formik>
  )

}

export default ProductCategoryInlineForm