import React from 'react'
import { useHistory } from 'react-router-dom'
import { connect } from 'react-redux'
import { get } from 'lodash'
import ConfirmationModal from 'components/ConfirmationModal'
import Breadcrumbs from 'components/layout/Breadcrumbs'
import {
  makeStyles,
  Typography,
  Container,
  Hidden,
  Grid,
  Avatar,
  Divider,
  CssBaseline,
  ThemeProvider
} from '@material-ui/core'
import ArrowRightIcon from '@material-ui/icons/ArrowRight'
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft'
import ChevronRightIcon from '@material-ui/icons/ChevronRight'
import MenuIcon from '@material-ui/icons/Menu'
import Header from './Header'
import Content from './Content'
import Root from './Root'
import Nav from './Nav'
import Footer from './Footer'
import presets from './layoutPresets'
import beneficiaryTheme from 'themes/beneficiaryTheme'
import { Separator } from 'components/layout/Separator'
import LoggedInUser from './header/LoggedInUser'
import config from 'config'
import logo from 'assets/images/logo.svg'
import Notifications from './header/Notifications'
import { getActiveCompanyName, getActiveCompanyLogo, getActiveCompanyId } from 'actions/active_company'
import Title from 'components/layout/Title'
import FooterCopyright from 'components/layout/FooterCopyright'
import BeneficiaryMenu from './menu/BeneficiaryMenu'

const useStyles = makeStyles((theme) => ({
  beneficiaryPortalHeading: {
    fontWeight: 500,
    padding: '10px 15px',
    borderTop: '1px solid #efefef',
  },
  logoContainer: {
    borderBottom: '1px solid #efefef',
  },
  logo: {
    margin: '8px 15px 2px 15px'
  },
  beneficiaryLogoContainer: {
    textAlign: 'center',
    height: 50,
    paddingTop: 15,
    paddingBottom: 65,
  },
  beneficiaryLogo: {
    height: 50,
  },
  userMenuBar: {
    position: 'absolute',
    bottom: 0,
    left: 0,
    width: '100%',
    background: '#cfd8dc',
  },
  companyName: {
    padding: '7px 15px',
    fontWeight: 500
  }
}))

export default function BeneficiaryMainLayout({ title, layout, children, breadcrumbs, active_key }) {

  const classes = useStyles()
  const history =useHistory()
  const company_logo = getActiveCompanyLogo()
  const company_name = getActiveCompanyName()
  if(!getActiveCompanyId()) {
    history.push('/')
  }
  const spacer = <ArrowRightIcon style={{marginBottom: -5}} />

  return (
    <ThemeProvider theme={beneficiaryTheme}>
      <Root config={config} style={{ minHeight: "100vh" }}>
        <CssBaseline />

        <Header
          menuIcon={{
              inactive: <MenuIcon />,
              active: <ChevronLeftIcon />
          }}
        >
        </Header>

        <Nav
          collapsedIcon={{
              inactive: <ChevronLeftIcon />,
              active: <ChevronRightIcon />
          }}
          header={
              ctx => null
          }
        >
          <img src={logo} width={100} className={classes.logo} />
          <Typography className={classes.beneficiaryPortalHeading}>BeneficiaryPortal</Typography>
          <Divider style={{marginBottom: 15}} />

          <BeneficiaryMenu />
          {/*
          <Divider light style={{marginTop: 10, marginBottom: 20}}/>
          <BeneficiarySubMenu />
          */}
          <div className={classes.userMenuBar}>
            { company_logo ?
              <div className={classes.beneficiaryLogoContainer} >
                <img src={company_logo} alt={company_name} title={company_name} className={classes.beneficiaryLogo} />
              </div> :
              <>
              <Typography className={classes.companyName}>{company_name}</Typography>
              <Divider />
              </>
            }
            <LoggedInUser />
          </div>
        </Nav>

        <Content>
          { title &&
            <Title portal="beneficiary">{ title }</Title>
          }
          <Container disableGutters={true} maxWidth={false} className>

            <ConfirmationModal />
            <Breadcrumbs crumbs={breadcrumbs}/>

            { children }

          </Container>

        </Content>

        <Footer>
          <FooterCopyright />
        </Footer>
      </Root>
    </ThemeProvider>
  )
}
