import { useLoadScript } from '@react-google-maps/api'

//set libraries for google maps api
const libraries = ["places"]

export const loadGoogleApi = () => {

    const res = useLoadScript({
        googleMapsApiKey: window.LOCAL_SETTINGS.GOOGLE_API_KEY,
        libraries
    })

    const { isLoaded, loadError } = res
    return  res
}
