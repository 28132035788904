import React, { useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { forEach, head, get, map, keys, split, size, capitalize } from 'lodash'
import { adminReport } from './actions/admin_report'
import Timestamp from 'components/Timestamp'
import { Link, useParams } from 'react-router-dom'
import { adminBeneficiaryParcelProductReportList } from 'admin/components/orders/actions/admin_beneficiary_parcel_product'
import { adminProductList } from 'admin/components/products/actions/admin_product'
import { LoadingModal } from 'components/layout/Modal'
import { adminParcelList } from 'admin/components/orders/actions/admin_parcel'
import * as Yup from 'yup'
import {showSuccess, showError} from 'actions/Error'
import { handleSubmitResult } from 'actions/form'
import AdminMainLayout from '../layout/AdminMainLayout'
import Typography from '@material-ui/core/Typography'
import { Paper, Table, TableHead, TableBody, TableRow, withStyles, Grid, IconButton, CircularProgress } from '@material-ui/core'
import MuiTableCell from "@material-ui/core/TableCell"
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf'
import { GenerateTrackingReportPDF } from './GenerateTrackingReportPDF'
import { PDFDownloadLink } from '@react-pdf/renderer'
import Loading from 'components/Loading'

const TableCell0 = withStyles({
    root: {
        borderBottom: "none"
    }
})(MuiTableCell)

const TableCell = withStyles({
    root: {}
})(MuiTableCell)

export const AdminBeneficiaryParcelTrackingReport = () => {

    const dispatch = useDispatch()
    const { parcel_id } = useParams()
    const parcel = useSelector(() => adminParcelList.getObject(parcel_id))
    const is_loading = useSelector(() => adminParcelList.isLoading())
    const parcel_products = useSelector(() => adminBeneficiaryParcelProductReportList.getVisibleObjects())
    const is_loading_products = useSelector(() => adminBeneficiaryParcelProductReportList.isLoading())
    const to_company_name = get(parcel, ["to_company_name"], null)
    const product_ids = map(parcel_products, (parcel_product) => parcel_product.id)
    const columns = ["Product","Donated by", "Best Before Date", "Expiry Date", "Qty", "Weight", "Arrived WH"]
    const from_warehouse = get(parcel, ["depart_warehouse_name"])
    const departed_warehouse_date = get(parcel, "arrive_at")
    const delivered_date = get(parcel, "arrive_at")
    const delivery_driver = get(parcel, ["driver_name"])
    const received_by = get(parcel, ["received_by"])

    useEffect(() => {
        async function fetchStaticObjects() {
            dispatch(adminParcelList.ensureObjectLoaded(parcel_id))
            dispatch(adminBeneficiaryParcelProductReportList.updatePaginationNumItemsPerPage(1000))
            dispatch(adminBeneficiaryParcelProductReportList.updateListFilter({parcel: parcel_id}))
            dispatch(adminBeneficiaryParcelProductReportList.fetchListIfNeeded())
            dispatch(adminProductList.ensureObjectsLoaded(product_ids))
        }
        fetchStaticObjects()
    }, [parcel_id])

    const renderTitle = () => {
        return (
            <>
                <Table size="small">
                    <TableRow>
                        <TableCell0 width={200} variant="head" style={{fontSize: 18}}>Beneficiary:</TableCell0>
                        <TableCell0 style={{fontSize: 18}}>{ to_company_name }</TableCell0>
                    </TableRow>
                    <TableRow>
                        <TableCell0 width={200} variant="head" style={{fontSize: 18}}>Order ID:</TableCell0>
                        <TableCell0 style={{fontSize: 18}}>{ parcel_id }</TableCell0>
                    </TableRow>
                    <TableRow>
                        <TableCell0 variant="head" style={{fontSize: 18}}>From warehouse:</TableCell0>
                        <TableCell0 style={{fontSize: 18}}>{ from_warehouse }</TableCell0>
                    </TableRow>
                    <TableRow>
                        <TableCell0 variant="head" style={{fontSize: 18}}>Dispatch Date</TableCell0>
                        <TableCell0 style={{fontSize: 18}}>
                            <Timestamp format="date" use_span={true} value={ departed_warehouse_date } />
                        </TableCell0>
                    </TableRow>
                    <TableRow>
                        <TableCell0 variant="head" style={{fontSize: 18}}>Delivery Date:</TableCell0>
                        <TableCell0 style={{fontSize: 18}}><Timestamp format="date" use_span={true} value={ delivered_date } /></TableCell0>
                    </TableRow>
                    <TableRow>
                        <TableCell0 variant="head" style={{fontSize: 18}}>Driver:</TableCell0>
                        <TableCell0 style={{fontSize: 18}}>{ delivery_driver }</TableCell0>
                    </TableRow>
                </Table>

                <Typography variant="h6" style={{padding: '7px 15px', background: '#dddddd', marginTop: 15}}>Products</Typography>
            </>
        )
    }

    const renderColumns = (cols) => {
        return (
            <>
                {columns.map((col) => (
                    <TableCell style={{backgroundColor: '#efefef'}}>{col}</TableCell>
                ))}
            </>
        )
    }

    const renderParcelProduct = (parcel_product) => {
        return (
            <TableRow>{}
                <TableCell>
                    <Link to={`/admin/orders/donor/${ get(parcel_product, ["original_parcel", "id"]) }/`}>
                        { capitalize(get(parcel_product, "product_name")) }
                    </Link>
                </TableCell>
                <TableCell>
                    { get(parcel_product, "original_from_company_name") }
                </TableCell>
                <TableCell>
                    <Timestamp format="date" use_span={ true } value={ get(parcel_product, "best_before_date", "Not set") } />
                </TableCell>
                <TableCell>
                    <Timestamp format="date" use_span={true} value={ get(parcel_product, "expiry_date", "Not set") } />
                </TableCell>
                <TableCell>
                    { get(parcel_product, "actual_quantity") }
                </TableCell>
                <TableCell>
                    { get(parcel_product, "total_weight_kg") }
                </TableCell>
                <TableCell>
                    <Timestamp format="date" use_span={true} value={get(parcel_product, "original_parcel", "arrive_at")} />
                </TableCell>
            </TableRow>
        )
    }

    const DownloadPdf = () => {
        const titleData = [
            { name: "Beneficiary", value: get(parcel, ["to_company_name"], null) },
            { name: "Order ID", value: parcel_id },
            { name: "From warehouse", value: from_warehouse },
            { name: "Depart warehouse", value: from_warehouse },
            { name: "Delivered", value: delivered_date },
        ]
        return(
            <PDFDownloadLink
                document={<GenerateTrackingReportPDF data={parcel_products} titleData={titleData} />}
                fileName="tracking-report.pdf"
            >
                {({ loading }) => (loading ? 'loading...' : <PictureAsPdfIcon fontSize="large" color="error"/>)}
            </PDFDownloadLink>
      )
    }

    const renderProducts = () => {
        return (
            <>
                <Table size="small">
                    <TableHead>
                        <TableRow>
                            {renderColumns(columns)}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        { map(parcel_products, (parcel_product) => renderParcelProduct(parcel_product)) }
                    </TableBody>
                </Table>
                { is_loading_products &&  <Loading message="Loading products..." />}
            </>
        )
    }

    return (
        <AdminMainLayout
            breadcrumbs={[
                { name: 'admin_home' },
                { name: 'parcels', label: 'Orders', url: '/admin/parcels'},
                { name: 'tracking_reports', label: 'Tracking Reports', url: `/admin/beneficiacy/${parcel_id}/tracking_reports`},
                { name: 'parcel',label: `Beneficiary Order: ${get(parcel, ["short_ref"], 'New')}`, url: `/admin/orders/beneficiary/${parcel_id}` },
            ]}
            title={"Beneficiary Tracking Report"}
        >
            { is_loading && <LoadingModal /> }
            <Grid container justify="flex-end" style={{marginTop: -40}}>
                <DownloadPdf />
            </Grid>
            <Paper variant="outlined">
                { renderTitle() }
                { renderProducts() }
            </Paper>

        </AdminMainLayout>
    )

}
