import React, { useEffect } from 'react'
import ConfirmationModal from 'components/ConfirmationModal'
import Breadcrumbs from 'components/layout/Breadcrumbs'
import Typography from '@material-ui/core/Typography'
import Container from '@material-ui/core/Container'
import MenuIcon from '@material-ui/icons/Menu'
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft'
import ChevronRightIcon from '@material-ui/icons/ChevronRight'
import Header from './Header'
import Content from './Content'
import Root from './Root'
import Nav from './Nav'
import Footer from './Footer'
import presets from './layoutPresets'
import { Copyright } from 'components/Copyright'
import CssBaseline from '@material-ui/core/CssBaseline'
import marketingTheme from 'themes/marketingTheme'
import { ThemeProvider } from '@material-ui/core/styles'
import { Separator } from 'components/layout/Separator'
import LoggedInUser from './header/LoggedInUser'
import LoginRegisterButtons from './header/LoginRegisterButtons'
import config from 'config'
import { MarketingMainMenu } from './menus/MarketingMenu'
import {Hidden, Grid} from '@material-ui/core'
import logo from 'assets/images/logo.svg'
import Avatar from '@material-ui/core/Avatar'
import Divider from '@material-ui/core/Divider'
import Notifications from './header/Notifications'
import { Link } from 'react-router-dom'
import { isLoggedIn } from 'actions/auth'

export default function MarketingMainLayout({ title, children, breadcrumbs, active_key, in_iframe}) {
  const is_logged_in = isLoggedIn()

  return (
    <ThemeProvider theme={marketingTheme}>
      <Root config={config} style={{ minHeight: "100vh" }}>
        <CssBaseline />
        {!in_iframe &&
        <Header>
          <Grid container justifyContent="space-between">
            <Grid item>
              <a href={"https://saharvest.org"}><img src={logo} width={180} style={{margin: 15, marginTop: 18}}/></a>
            </Grid>
            <Grid item>
              {!is_logged_in &&
                <LoginRegisterButtons register={false} />
              }
              {is_logged_in && <LoggedInUser /> }
            </Grid>
          </Grid>
        </Header>
        }
        <Content>
          <ConfirmationModal />

          <Breadcrumbs crumbs={breadcrumbs}/>
          { title &&
            <Typography variant="h6">{ title }</Typography>
          }
          { children }
        </Content>

        <Footer><Copyright /></Footer>
      </Root>
    </ThemeProvider>
  )
}
