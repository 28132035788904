import { ItemList } from 'orm'
import { get, map, head, filter } from 'lodash'
import { onEventUpdated } from 'admin/actions/admin_calendar'
import { download } from 'actions/lib'

class LogisticsCompanyList extends ItemList {
    getEntityKey() {
        return "admin/logistics_company"
    }

    getAsOptions() {
        const items = this.getVisibleObjects()
        return map(items, function(item) { return {label: item.company.name,
                                                   value: item.company.id}})
    }

    saveObject(props) {
        return super.saveObject(props)
        // return (dispatch, getState) => {
        //     dispatch(super.saveObject(props))
        //     dispatch(onEventUpdated())
        // }
    }

    saveNewObject(props) {
        return super.saveNewObject(props)
        // return (dispatch, getState) => {
        //     dispatch(super.saveNewObject(props))
        //     dispatch(onEventUpdated())
        // }
    }

    getForCompany({company_id}) {
        // Assumes the matching logistics_company is already loaded
        return head(filter(this.getAllObjects(), (x) => x.company.id === company_id))
    }

    downloadLogisticsCompanyReport(logistics_company_id) {
        return download(`admin/logistics_company/${logistics_company_id}/download/`)
    }


}

class LogisticsCompanyAddressManager extends ItemList {
    getEntityKey() {
        return "admin/logistics_company/add_address"
    }

    addAddress({logistics_company_id, values}) {
        values.logistics_company_id = logistics_company_id
        return super.saveNewObject(values)
    }

    getDefaultAddress(logistics_company) {
        const default_address = head(filter(get(logistics_company, ["company", "addresses"], []), (address) => address.address_type == 'delivery'))
        return default_address
    }
}



export const adminLogisticsCompanyList = new LogisticsCompanyList("admin_logistics_company__default")
export const adminLogisticsCompanyAutoCompleteList = new LogisticsCompanyList("admin_logistics_company_auto_complete__default")
export const adminLogisticsCompanyAddressManager = new LogisticsCompanyAddressManager("admin_logistics_company_add_address__default")
