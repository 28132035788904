import React from 'react'
import * as Yup from 'yup'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import { ProvinceSelectField } from 'components/form/ProvinceSelectField'
import { FormikInputField } from 'components/form/InputField'
import { FormikTextareaField } from 'components/form/TextareaField'
import { FormikCheckboxField } from 'components/form/CheckboxField'

export const validationSchema = Yup.object().shape({
    'name': Yup.string().required("Required"),
})

const AdminContactRoleForm = ({ formik_props }) => {

    return (
        <>
          <FormikInputField name="name"
                            label="Contact Role"
                            formik_props={formik_props}
          />

          <FormikTextareaField name="description"
                               label="Description"
                               formik_props={formik_props}
          />

          <FormControlLabel
            control={
                <FormikCheckboxField name={`published`}
                                     formik_props={formik_props}
                                     color="primary"
                />
            }
            label="Published"
            style={{marginLeft: 10}}
          />

        </>
    )
}

export default AdminContactRoleForm
