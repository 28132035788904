import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useParams } from 'react-router-dom'
import { get } from 'lodash'
import { adminNutrientList } from './actions/admin_nutrient'
import { Separator } from 'components/layout/Separator'
import { Formik, Form, Field } from 'formik'
import * as Yup from 'yup'
import {showSuccess, showError} from 'actions/Error'
import { handleSubmitResult } from 'actions/form'
import AdminCommonFormLayout from '../layout/AdminCommonFormLayout'
import { AdminNutrientForm, validationSchema} from './form/AdminNutrientForm'

export default function AdminNutrient() {
    const dispatch = useDispatch()
    const history = useHistory()
    const { nutrient_id } = useParams()
    const nutrient = useSelector(() => adminNutrientList.getObject(nutrient_id))
    const initial_values = nutrient
    const is_loading = useSelector(() => adminNutrientList.isLoading()) || (nutrient_id && !get(nutrient, "id"))
    const is_busy = useSelector(() => adminNutrientList.getIsSavingObject())
    const title = `${get(nutrient, ["name"], 'New Nutrient')}`

    useEffect(() => {
        dispatch(adminNutrientList.ensureObjectLoaded(nutrient_id))
    },[])

    const onSave = (values, formik_funcs) => {
        const on_ok = function(json) {
            dispatch(adminNutrientList.invalidateList())
            showSuccess("Saved", "Nutrient saved")

            if ( ! nutrient_id ) {
                history.push(`/admin/nutrient/${json.id}`)
            }
        }
        if ( nutrient_id ) {
            values.id = nutrient_id
            return dispatch(adminNutrientList.saveObject(values)).then((res) => handleSubmitResult({res, formik_funcs, on_ok}))
        } else {
            return dispatch(adminNutrientList.saveNewObject(values)).then((res) => handleSubmitResult({res, formik_funcs, on_ok}))
        }
    }

    const getCellValue = (header_key, item, index) => {
        switch( header_key ) {
            default:
                return undefined
        }
    }

    return (
        <AdminCommonFormLayout
            breadcrumbs={[
                {name: 'admin_home'},
                {name: 'nutrients', label: 'Nutrients', url: '/admin/nutrients'},
                {name: 'nutrient', label: get(nutrient, ["name"], 'New Nutrient'), url: `/admin/nutrient/${nutrient_id}`}
            ]}
            is_busy={is_busy}
            is_loading={is_loading}
            title={title}
            initial_values={initial_values}
            validationSchema={validationSchema}
            onSave={onSave}
        >
          {
              ({formik_props}) => <AdminNutrientForm formik_props={formik_props} />
          }
        </AdminCommonFormLayout>
    )
}
