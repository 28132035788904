import PropTypes from 'prop-types'
import React, { forwardRef } from 'react'
// Material-UI v4
import { useTheme } from '@material-ui/core/styles'
import Box from '@material-ui/core/Box'
import { withStyles } from '@material-ui/core/styles'
//
import StyledLabel from './styles' // Assume this file uses withStyles or makeStyles

// ----------------------------------------------------------------------

const infoColor = '#17a2b8'
const successColor = '#28a745'
const warningColor = '#ffc107'

export const getColor = ( color ) => {
  const theme = useTheme()

  const colorMap = {
    default: theme.palette.grey[ 300 ],
    primary: theme.palette.primary.main,
    secondary: theme.palette.secondary.main,
    info: theme.palette.info.light,
    success: theme.palette.success.light,
    warning: theme.palette.warning.light,
    error: theme.palette.error.main,
  }
  const colorSelected = colorMap[ color ]

  return colorSelected
}


const Label = forwardRef(
  ( { children, color = 'default', variant = 'soft', startIcon, endIcon, style, ...other }, ref ) => {

    const theme = useTheme()

    const iconStyle = {
      width: 16,
      height: 16,
      '& svg, img': { width: 1, height: 1, objectFit: 'cover' },
    }

    return (
      <StyledLabel
        ref={ ref }
        component="span"
        ownerState={ { color, variant } }
        style={ {
          backgroundColor: getColor( color ),
          marginLeft: 7,
          ...( startIcon && { paddingLeft: theme.spacing( 0.75 ) } ),
          ...( endIcon && { paddingRight: theme.spacing( 0.75 ) } ),
          ...style,
        } }
        theme={ theme }
        { ...other }
      >
        { startIcon && <Box style={ { marginRight: theme.spacing( 0.75 ), ...iconStyle } }> { startIcon } </Box> }

        { children }

        { endIcon && <Box style={ { marginLeft: theme.spacing( 0.75 ), ...iconStyle } }> { endIcon } </Box> }
      </StyledLabel>
    )
  }
)

Label.propTypes = {
  children: PropTypes.node,
  endIcon: PropTypes.object,
  startIcon: PropTypes.object,
  sx: PropTypes.object,
  variant: PropTypes.oneOf( [ 'filled', 'outlined', 'ghost', 'soft' ] ),
  color: PropTypes.oneOf( [
    'default',
    'primary',
    'secondary',
    'info',
    'success',
    'warning',
    'error',
  ] ),
}

export default Label
