import React from 'react'
import BeneficiaryMainLayout from '../layout/BeneficiaryMainLayout'
import SimpleTable from 'components/SimpleTable'

export default function BeneficiaryHelp() {
  return(
    <BeneficiaryMainLayout
       active_key="help"
       breadcrumbs={[
          {name: 'donor_home'},
          {name: 'dashboard', label: "Dashboard", url: '/donor'},
          {name: 'help', label: "Help", url: '/donor/help'}
      ]}
    >

    </ BeneficiaryMainLayout>
  )
}
