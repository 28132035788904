import React, { useState, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { capitalize, replace } from 'lodash'
import { Link, useParams, useHistory } from 'react-router-dom'
import { Formik, Form, FieldArray, Field } from 'formik'
import { FormikInputField } from './form/InputField'
import { FormikDropdownField } from './form/Dropdown'
import { register } from 'actions/auth'
import * as Yup from 'yup'
import { makeStyles, Grid, Button, CssBaseline, TextField, FormControlLabel, Typography, CircularProgress, InputAdornment } from '@material-ui/core'
import AuthContainer from './AuthContainer'
import { SaveButton } from 'components/form/Buttons'
import Alert from "@material-ui/lab/Alert"
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline'
import CancelOutlinedIcon from '@material-ui/icons/CancelOutlined'
import { Modal } from 'components/layout/Modal';

const useStyles = makeStyles((theme) => ({
  input: {
    fontSize: 24,
    [theme.breakpoints.down('sm')]: {
      fontSize: 16
    }
  },
}))
const validationSchema = Yup.object().shape({
    'first_name': Yup.string().required("First name is required"),
    'last_name': Yup.string().required("Last name is required"),
    'email': Yup.string().email().required("Provide a valid email address"),
})

export default function Register() {
    const classes = useStyles()
    const dispatch = useDispatch()
    const history = useHistory()
    const url_filter = new window.URLSearchParams(window.location.search)
    const initialValues = {}
    const [companyInputStatus, setCompanyInputStatus] = useState(null)
    const [companyName, setCompanyName] = useState(null)
    const [firstName, setFirstName] = useState(null)
    const [companyDoesNotExist, setCompanyDoesNotExist] = useState(null)
    const [showSuccessModal, setShowSuccessModal] = useState(null)
    const [formValues, setFormValues] = useState(null)
    const loadingIcon = <CircularProgress />
    const successIcon = <CheckCircleOutlineIcon style={{color: 'green'}} />
    const errorIcon = <CancelOutlinedIcon style={{color: 'red'}} />

    useEffect(() => {

      if(companyName) {

        const delayDebounceFn = setTimeout(() => {
          console.log(companyName)
          // Send API request here
          setCompanyInputStatus(loadingIcon)
          setTimeout(function() {
            let success = true
            // if company does not exist in db show success
            if(success) {

              setCompanyInputStatus(successIcon)
              setCompanyDoesNotExist(true)
            }
            else {
              // if company exists in db show failure
              setCompanyDoesNotExist(false)
              setCompanyInputStatus(errorIcon)
            }
          }, 1500);
        }, 500)

        return () => clearTimeout(delayDebounceFn)
      }

    }, [companyName])

    const onRegister = (values, formik_funcs) => {
        //return dispatch(register(values.first_name, values.last_name, values.email)).then(history.push("/"))
        values.company_name = companyName
        setFirstName(values.first_name)
        setFormValues(JSON.stringify(values))
        setShowSuccessModal(true)
    }

    const onClose = () => {
      setShowSuccessModal(false)
      //history.push('/login')
    }

    const company_options = [
      {label: 'Food Donor', value: 'food_donor'},
      {label: 'Logistics Partner', value: 'logistics_company'},
    ]

    const individual_options = [
      {label: 'Give Food', value: 'food_donor_individual'},
      {label: 'Give Time (volunteer)', value: 'volunteer_individual'},
      {label: 'Give Money', value: 'financial_donor_individual'},
      {label: 'Other', value: 'other_individual'},
    ]
    const entity_options = [
      {label: 'Company', value: 'company'},
      {label: 'Beneficiary Organisation', value: 'beneficiary_organisation'},
      {label: 'Individual', value: 'individual'},
    ]

    const renderForm = ({formik_props, classes}) => {
        return (
            <>
            <Typography variant="h5">
              We can't wait to have you on board.</Typography><br />
            <Typography variant="h6">Let's do this!</Typography>


            {!formik_props.values.entity_type &&
              <Typography style={{marginTop: 20}}>
                Are you registering as a company, beneficiary organisation or an individual?
              </Typography>
            }

            <div style={{textAlign: 'left'}}>

              <FormikDropdownField name="entity_type"
                                   label={!formik_props.values.entity_type ? "Select one" : null}
                                   options={entity_options}
                                   formik_props={formik_props}
              />

            {formik_props.values.entity_type && formik_props.values.entity_type == 'company' &&
                <>
                <FormikDropdownField name="company_type"
                                       label="Company Type"
                                       options={company_options}
                                       formik_props={formik_props}
                                       helperText={!formik_props.values.company_type ? "What kind of company are you?" : null}
                />
                <FormikInputField name="company_name"
                                  label={`${capitalize(replace(formik_props.values.entity_type, "_", " "))} Name`}
                                  formik_props={formik_props}
                                  InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                          {companyInputStatus}
                                        </InputAdornment>
                                    ),
                                  }}
                                  onChange={(e) => setCompanyName(e.target.value)}
                                  inputProps={
                                    {className: classes.input}
                                  }
                />
              </>
            }
            {formik_props.values.individual_donor_type &&
              <>
                {!formik_props.values.individual_donor_type &&
                  <Typography style={{marginTop: 20}}>How would you like to be involved?</Typography>
                }
                <FormikDropdownField name="individual_donor_type"
                                     options={individual_options}
                                     formik_props={formik_props}
                />
              </>
            }
            </div>
            { formik_props.values.entity_type && (companyDoesNotExist || formik_props.values.individual_donor_type) &&
              <>
                <Alert severity="success" style={{marginTop: 10}}>Fantastic. Nearly there!</Alert>
                <FormikInputField name="first_name"
                                  label="First Name"
                                  formik_props={formik_props}
                                  inputProps={
                                    {className: classes.input}
                                  }
                />
                <FormikInputField name="last_name"
                                    label="Last Name"
                                    formik_props={formik_props}
                                    inputProps={
                                      {className: classes.input}
                                    }
                  />
                  <FormikInputField name="email"
                                    type="email"
                                    label="Email Address"
                                    formik_props={formik_props}
                                    inputProps={
                                      {className: classes.input}
                                    }
                  />
                <FormikInputField name="mobile"
                                    type="mobile"
                                    label="Mobile"
                                    formik_props={formik_props}
                                    inputProps={
                                      {className: classes.input}
                                    }
                  />
                  <Button
                    fullWidth
                    variant="contained"
                    color="primary"
                    type="submit"
                    disableElevation
                    className={classes.submit}
                  >Register</Button>
                </>
              }



            </>
        )
    }

    const renderOutsideForm = () => {
      return(
        <Grid container>
          <Grid item xs>
            Already have an account? <Button component={Link} to="/login">Login</Button>
          </Grid>
        </Grid>
      )
    }

    return (
      <>
      <AuthContainer action="register"
                     renderForm={renderForm}
                     initialValues={initialValues}
                     validationSchema={validationSchema}
                     onSubmit={onRegister}
                     renderOutsideForm={renderOutsideForm()}
     />
    {showSuccessModal &&
      <Modal title={"Registration successful"} maxWidth="xl" onClose={onClose}>
        <div style={{textAlign: 'center', padding: 30}}>

          <CheckCircleOutlineIcon style={{color: 'green', fontSize: 150, marginBottom: 20}}/>
          <Typography style={{fontSize: 28}}>{firstName}, you are a superstar.</Typography>
          <Typography variant="h5" style={{marginTop: 30}}>Please check your email for next steps.</Typography>
          {formValues}

        </div>
      </Modal>
    }
    </>
  )
}
