import TrendingDownIcon from '@material-ui/icons/TrendingDown'
import TrendingUpIcon from '@material-ui/icons/TrendingUp'

export function getTrend(trend) {

    let trendColor
    let trendIcon
    let trendDirection

    const trendIconStyle = {
        marginBottom: -7,
        marginRight: 5
    }

    if (Math.sign(trend) === 1) {
        trendColor = '#4caf50'
        trendDirection = '+'
        trendIcon = <TrendingUpIcon style={trendIconStyle} />
    } else if (Math.sign(trend) === -1) {
        trendColor = '#f44336'
        trendIcon = <TrendingDownIcon style={trendIconStyle} />
    }

    return { trendColor, trendIcon, trendDirection }
}