import React, { useState, useEffect } from 'react'
import { head, filter, map, get } from 'lodash'
import FormCard from 'components/layout/FormCard'
import { useDispatch, useSelector } from 'react-redux'
import DonorMainLayout from '../layout/DonorMainLayout'
import { Separator } from 'components/layout/Separator'
import { FormikDateTimePicker } from 'components/form/DatetimePicker'
import { DropzoneArea } from 'material-ui-dropzone'
import { FormikCheckboxField } from 'components/form/CheckboxField'
import { FormikDropdownField } from 'components/form/Dropdown'
import { FormikTextareaField } from 'components/form/TextareaField'
import { FormikInputField } from 'components/form/InputField'
import MenuItem from '@material-ui/core/MenuItem'
import { Error } from 'components/layout/Error'
import {showSuccess, showError} from 'actions/Error'
import { handleSubmitResult } from 'actions/form'
import { MuiFileUploader } from 'components/form/MuiFileUploader'
import { Paper, Typography, Button, TextField, FormHelperText, Grid, Chip, InputAdornment } from '@material-ui/core'
import { FormikGeneralFormErrors } from 'components/form/GeneralFormErrors'
import DatePicker from "react-datepicker"
import { makeStyles } from '@material-ui/core/styles'
import {Formik, Form, Field} from 'formik'
import { contactDonorParcelList } from './actions/contact_donor_parcel'
import { contactParcelList } from './actions/contact_parcel'
import * as Yup from 'yup'
import { FormikFileSetField } from 'components/form/FileSetField'
import { Card } from 'components/layout/Card'
import DonationParcelProducts from './DonationParcelProducts'
import { FormSkeleton } from 'components/layout/loading/Skeleton'
import Alert from '@material-ui/lab/Alert'
import { SaveButton } from 'components/form/Buttons'
import HelpText from 'components/HelpText'
import Timestamp from 'components/Timestamp'
import { SingleFileUploadField } from 'components/form/SingleFileUploadField'
import { getActiveCompanyId } from 'actions/active_company'

const useStyles = makeStyles((theme) => ({
  statusChip: {
    color: 'white',
    borderRadius: 4,
    border: 'none',
    textTransform: 'uppercase'
  },
  completeStatus: {
    background: theme.palette.success.main,
  },
  pendingStatus: {
    background: theme.palette.info.main,
  },
  cancelledStatus: {
    background: theme.palette.error.main,
  }
}))

const validationSchema = Yup.object().shape({
    depart_at: Yup.string().required("Required").nullable(),
})

export const DonateFoodOrderForm = ({onSaved, parcel, is_loading, is_busy}) => {
    const classes = useStyles()
    const dispatch = useDispatch()
    const company_id = getActiveCompanyId()
    const direction = 'donor_pickup'
    const initial_values = direction == 'donor_pickup' ? Object.assign({status: 'pending', transport_by_warehouse_company: true}, parcel) : Object.assign({status: 'pending', transport_by_warehouse_company: false}, parcel)
    const title = get(parcel, 'id') ? `Donation ID: ${get(parcel, 'short_ref')}` : "New Food Donation"
    const [editRandValue, setEditRandValue] = useState(false)
    const statusComplete = get(parcel, "status") == 'complete' ? true : false
    const statusCancelled = get(parcel, 'status') == 'cancelled' ? true : false
    const noAddProducts = statusComplete || statusCancelled ? true : false
    const parcelDateDeliveryMessage = get(parcel, "transport_by_warehouse_company") ? 'Collected by SA Harvest' : 'Delivered to SA Harvest'

    const onSave = (values, formik_funcs) => {
        const on_ok = function(json) {
            dispatch(contactParcelList.invalidateList())
            showSuccess("Saved", "Order saved")
            onSaved(json)
        }
        if ( get(parcel, "id") ) {
            values.id = parcel.id
            values.override_total_value_rands = true
            values.override_total_weight_kg = true
            return dispatch(contactParcelList.saveObject(values)).then((res) => handleSubmitResult({res, formik_funcs, on_ok}))
        }
        else {
            values.from_company = company_id
            values.direction = direction
            return dispatch(contactParcelList.saveNewObject(values)).then((res) => handleSubmitResult({res, formik_funcs, on_ok}))
        }
    }

    const renderForm = (formik_props) => {

        return (
            <>
              {statusComplete &&
                <Alert severity="success">{parcelDateDeliveryMessage} on {<Timestamp value={formik_props.values.depart_at} />}</Alert>
              }
              {statusCancelled &&
                <Alert severity="error">Order was cancelled</Alert>
              }
              {!statusComplete && !statusCancelled &&
                <>
                  <FormikDateTimePicker name="depart_at"
                                        label="Schedule Date & Time"
                                        formik_props={formik_props} />
                  <FormikTextareaField name="description"
                                       label="Notes"
                                       helperText="Provide any details that may be useful about this order"
                                       formik_props={formik_props}
                                       rows={5}
                  />
                  <FormikCheckboxField name="transport_by_warehouse_company"
                                           label="SA Harvest to collect"
                                           formik_props={formik_props}
                  />
                  <FormHelperText>Do you want SA Harvest to collect?</FormHelperText>
                </>
              }
            </>
        )

    }

    const renderSection18Section = (formik_props) => {
        return (
            <div className={classes.infoBox} style={{background: '#ffffff'}}>

            { get(parcel, "qualifies_for_section18_certificate") &&
              <div>
                <Alert className={classes.alertMargin}>
                  This parcel qualifies for a section18A Tax exemption certificate.
                </Alert>
                <Alert severity="info" className={classes.alertMargin}>
                  It will be automatically generated and emailed to the company when you save the order.
                </Alert>


                { ! section18AReport &&
                  <Alert severity="info" className={classes.alertMargin}>If section18A report generation is enabled for this customer, then the report will be available soon, please wait a few minutes then refresh this page or go to the reports tab.</Alert>
                }
                { section18AReport &&
                  <div>
                      <Separator height={15} />
                      <Typography><b>Section 18A Report</b></Typography>
                      <Separator height={5} />
                      <Table>
                        <TableRow>
                          <TableCell>Status:</TableCell>
                          <TableCell>
                            {section18AReport.status == "success" ? <span style={{color: 'green'}}>{section18AReport.status}</span> : <span style={{color: 'red'}}>{section18AReport.status}</span> }
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>Emailed to:</TableCell>
                          <TableCell>{section18AReport.emailed_to}</TableCell>
                        </TableRow>

                        { section18AReport.report_file_info &&
                          <TableRow>
                            <TableCell>
                              Download
                            </TableCell>
                            <TableCell>
                              <a href={section18AReport.report_file_info.original_download_url} download={section18AReport.report_file_info.original_filename}>
                                {section18AReport.report_file_info.original_filename}
                              </a>
                            </TableCell>
                          </TableRow>
                        }
                    </Table>
                  </div>
                }
              </div>
            }
            <div align="center">
              <SingleFileUploadField name="company_invoice_file_info"
                                   dropzonePlaceholder = "Upload the company invoice for this order"
                                   buttonPlaceholder = "invoice"
                                   formik_props={formik_props}
                                   />
            </div>
            { get(formik_props.values, ["company_invoice_file_info", "id"]) &&
              <>
                <Separator height={15} />
                  {formik_props.values.company_invoice_is_valid &&
                    <Alert severity="success" className={classes.alertMargin}>
                      It has been confirmed that the company invoice is correct and matches the products in this order
                    </Alert>
                  }
              </>
            }
          </div>
        )
    }

    const renderTotalOverrides = (formik_props) => {

        return (
            <>
                <Typography style={{fontSize: 48}}>
                  R {formik_props.values.total_value_rands ? formik_props.values.total_value_rands : 0}
                </Typography>
                {!editRandValue && <Button onClick={() => setEditRandValue(true)}>Edit?</Button>}

              {editRandValue &&
                <>
                <FormikInputField
                    name="total_value_rands"
                    label="Rand Value of Donation"
                    formik_props={formik_props}
                    InputProps={{
                      startAdornment: <InputAdornment position="start">R</InputAdornment>,
                    }}
                />

              <Grid container spacing={2} justifyContent="space-between">

                  <Grid item>
                    <Button onClick={() => setEditRandValue(false)}>Cancel</Button>
                  </Grid>

                  <Grid item>
                    <SaveButton />
                  </Grid>

                </Grid>
                </>
              }

            </>

        )

    }

    const renderDeliveryNoteForm = (formik_props) => {
      return(
        <div align="center">
          <FormikFileSetField
              name="delivery_notes"
              label={"delivery note"}
              formik_props={formik_props}
              preview_only_one_file_per_row
          />
        </div>
      )
    }

    const renderOrderStatus = () => {
      const status = get(parcel, "status")
        switch(status) {
          case 'pending':
            return <Chip label={status} variant="outlined" className={[classes.pendingStatus, classes.statusChip]} />
            break;
          case 'complete':
            return <Chip label={status} variant="outlined" className={[classes.completeStatus, classes.statusChip]} />
            break;
          case 'cancelled':
            return <Chip label={status} variant="outlined" className={[classes.cancelledStatus, classes.statusChip]} />
            break;
          default:
            return <Chip label={"Not set"} variant="outlined" className={classes.statusChip} />
        }
    }

    const helpText = (formik_props) => {
      return (
        <Typography>
          Select a date and time {formik_props.values.transport_by_warehouse_company ? "for us to collect" : "to deliver"}.<br />
          In the Notes section provide any additional information that may be important for us to know about this donation.<br /><br />
          At the moment you have opted {formik_props.values.transport_by_warehouse_company ? "for us to collect" : "to deliver"}.<br />
          You can change that if you prefer {formik_props.values.transport_by_warehouse_company ? "to deliver" : "for us to collect"}.<br /><br />
          Once you have scheduled a date and time SAVE (button bottom right corner) the order.<br />you will be able to add products, upload an invoice for your Section18A receipt, assign a value to the donation and more.
        </Typography>
      )
    }

    return (
      <>

        <Formik
          initialValues={initial_values}
          onSubmit={onSave}
          enableReinitialize={true}
          validationSchema={validationSchema}
        >

          {formik_props => {
              const { values } = formik_props
              return (
                  <Form>
                    <FormCard
                      isSubmitting={formik_props.isSubmitting}
                      positionFixed
                    >
                      <HelpText
                        text={helpText(formik_props)}
                        severity="info"
                        title="Help"
                      />

                      <Grid container spacing={2} justify="center">

                      <Grid item xs={12} md={12} lg={6}>
                            <Card
                              title={title}
                              content={ is_loading ? <FormSkeleton /> : renderForm(formik_props) }
                            />
                          { get(parcel, 'id') &&
                            <Card
                              title={"Products"}
                              content={ <DonationParcelProducts parcel_id={ get(parcel, "id") } noAddProducts={noAddProducts} /> }
                            />
                          }
                        </Grid>

                        { get(parcel, 'id') &&
                        <>
                        <Grid item xs={12} md={6} lg={3}>

                          <Card
                            title="Donation Value"
                            content={ renderTotalOverrides(formik_props) }
                          />
                          <Card
                            title="Section 18A Invoice"
                            content={ renderSection18Section(formik_props) }
                          />

                        </Grid>

                        <Grid item xs={12} md={6} lg={3}>
                          {parcel &&
                            <>
                              <Card
                                  title="Order Status"
                                  content={ renderOrderStatus()}
                              />

                              <Card
                                  title="Delivery Note"
                                  content={ renderDeliveryNoteForm(formik_props) }
                              />

                            </>
                          }
                        </Grid>
                      </>
                      }

                      </Grid>

                    </FormCard>
                  </Form>
              )}
          }
        </Formik>
      </>
    )
}
