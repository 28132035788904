import { ItemList } from 'orm'
import { values, get, map, head, filter } from 'lodash'
import { download } from 'actions/lib'
 
class FeedingScheduleList extends ItemList {
    getEntityKey() {
        return "admin/feeding_schedule"
    }

    getAsOptions() {
        const items = this.getVisibleObjects()
        return map(items, function(item) { return {label: item.company.name,
                                                   value: item.company.id}})
    }

    getForCompany({company_id}) {
        // Assumes the matching beneficiary is already loaded
        return head(filter(values(this.getAllObjects()), (x) => get(x, ["company", "id"]) === company_id))
    }

}

export const adminFeedingScheduleList = new FeedingScheduleList("admin_feeding_schedule__default")