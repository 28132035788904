import React from 'react'
import { FormControlLabel, Radio } from '@material-ui/core'
import { Field } from 'formik'
import { RadioGroup } from 'formik-material-ui'
import { get, map } from 'lodash'

export const FormikRadioGroupField = ({ label, labelColor, type, row, name, options, formik_props, ...props }) => {

    const { isSubmitting, values } = formik_props
    const selected_option = get(values, name)

    const renderOptions = () => {
      return (
        map(options, ({label, value, children}) =>
              <>
                <FormControlLabel value={value || selected_option}
                                  control={<Radio disabled={isSubmitting} />}
                                  label={label}
                                  disabled={isSubmitting}
                                  style={{color: labelColor}}
                />
                { selected_option == value && children }
              </>
            )
      )
    }

    return (
        <Field component={RadioGroup} name={name}>
          {row ? <div style={{display: 'flex'}}>{ renderOptions() }</div> : <>{ renderOptions() }</> }
        </Field>
    )
}
