import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import BeneficiaryCommonFormLayout from '../layout/BeneficiaryCommonFormLayout'
import BeneficiaryUserForm from './form/BeneficiaryUserForm'
import {showSuccess, showError} from 'actions/Error'
import { userList } from 'actions/user'
import { contactUserList } from './actions/contact_user'
import * as Yup from 'yup'
import BusyMask from 'components/BusyMask'

const yup_shape = {
    'email': Yup.string().required("Required")
}

const validationSchema = Yup.object().shape(yup_shape)

export default function Profile({}) {
  const dispatch = useDispatch()
  const history = useHistory()
  const logged_in_user = useSelector(() => userList.getUser())
  const user_id = logged_in_user.id
  const user = useSelector(() => contactUserList.getObject(user_id))
  const initial_values = Object.assign({}, {employee:{roles: []},
                                            user_filter:{cities: [], user: user_id}
                                           }, user)
  const is_loading  = useSelector(() => contactUserList.isLoading())
  const is_busy  = useSelector(() => contactUserList.getIsSavingObject())


  useEffect(() => {
    dispatch(contactUserList.ensureObjectLoaded(user_id))
  },[])

  const onSave = (values, formik_funcs) => {
      const on_ok = function(json) {
          dispatch(userList.invalidateList())
          showSuccess("Saved", "Profile updated")

          if ( ! user_id ) {
              history.push(`/`)
          }
      }
      if ( user_id ) {
          values.id = user_id
          return dispatch(userList.saveObject(values)).then((res) => handleSubmitResult({res, formik_funcs, on_ok}))
      } else {
          return dispatch(userList.saveNewObject(values)).then((res) => handleSubmitResult({res, formik_funcs, on_ok}))
      }
  }

  return(
    <BeneficiaryCommonFormLayout
        breadcrumbs={[
          {name: 'donor_home'},
          {name: 'settings', label: 'Settings', url: '/donor/settings/'},
          {name: 'profile', label: 'Profile', url: `/donor/user/profile`}
        ]}
        is_loading={is_loading}
        title={"Profile"}
        initial_values={initial_values}
        validationSchema={validationSchema}
        onSave={onSave}
      >

        { ({formik_props}) => <BeneficiaryUserForm formik_props={formik_props} /> }

    </BeneficiaryCommonFormLayout>
  )
}
