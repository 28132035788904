import React, { useState, useEffect, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams, useHistory } from 'react-router-dom'
import { get } from 'lodash'
import { FormikInputField } from 'components/form/InputField'
import * as Yup from 'yup'
import { adminKpiDashboardCategoryList } from './actions/admin_kpi_dashboard_category'
import { handleSubmitResult, shouldShowOnDemandSaveButton } from 'actions/form'
import {showSuccess, showError} from 'actions/Error'
import AdminCommonFormLayout from '../layout/AdminCommonFormLayout'
import { capitalCamel } from 'components/CapitalCamel'
import { Card, CardContent } from "@material-ui/core"
import { Alert } from "@material-ui/lab"

export const validationSchema = Yup.object().shape({
    'name': Yup.string().required("Required"),
})

export default function AdminKpiDashboardCategory() {
    const dispatch = useDispatch()
    const history = useHistory()
    const { kpi_dashboard_category_id } = useParams()
    const [edit, setEdit] = useState(false)
    const kpi_dashboard_category = useSelector(() => adminKpiDashboardCategoryList.getObject(kpi_dashboard_category_id))
    const is_loading =  useSelector(() => adminKpiDashboardCategoryList.isLoading())
    const is_busy =  useSelector(() => adminKpiDashboardCategoryList.getIsSavingObject())
    const initial_values = kpi_dashboard_category
    const title = `${get(kpi_dashboard_category, ["display_name"], 'New Dashboard Category')}`

    useEffect(() => {
        dispatch(adminKpiDashboardCategoryList.ensureObjectLoaded(kpi_dashboard_category_id))
    },[])

    const onSave = (values, formik_funcs) => {
        const on_ok = function(json) {
            dispatch(adminKpiDashboardCategoryList.invalidateList())
            showSuccess("Saved", "Dashboard Category saved")

            if ( ! kpi_dashboard_category_id ) {
                history.push(`/admin/kpis/dashboard_categories`)
            }
        }
        if ( kpi_dashboard_category_id ) {
            values.id = kpi_dashboard_category_id
            return dispatch(adminKpiDashboardCategoryList.saveObject(values)).then((res) => handleSubmitResult({res, formik_funcs, on_ok}))
        } else {
            return dispatch(adminKpiDashboardCategoryList.saveNewObject(values)).then((res) => handleSubmitResult({res, formik_funcs, on_ok}))
        }
    }

    const getCellValue = (header_key, item, index) => {
        switch( header_key ) {
            default:
            return undefined
        }
    }

    return (
        <AdminCommonFormLayout
            breadcrumbs={[
                {name: 'admin_home'},
                {name: 'kpi_dashboard_categories', label: 'KPI Categories', url: '/admin/dashboard_categories'},
                {name: 'kpi_dashboard_category', label: get(kpi_dashboard_category, ["name"], 'New KPI Category'), url: `/admin/dashboard_category/${kpi_dashboard_category_id}`}
            ]}
            is_busy={is_busy}
            is_loading={is_loading}
            title={title}
            initial_values={initial_values}
            validationSchema={validationSchema}
            onSave={onSave}
            >
            {
                ({formik_props}) =>
                <Card variant="outlined">
                    <CardContent>
                        <Alert severity="info">These categories apply to KPIs and Checklists</Alert>
                        <FormikInputField
                            name="name"
                            label="Dashboard Category"
                            formik_props={formik_props}
                        />
                        <FormikInputField
                            name="description"
                            label="Description"
                            formik_props={formik_props}
                            rows={5}
                            multiline
                        />
                    </CardContent>
                </Card>
            }
        </AdminCommonFormLayout>
    )
}
