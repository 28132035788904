import React from 'react'
import { makeStyles, createStyles } from '@material-ui/core/styles'
import { useDispatch } from 'react-redux'
import { userList } from 'actions/user'
import { keys, map } from 'lodash'
import List from '@material-ui/core/List'
import IconDashboard from '@material-ui/icons/Dashboard'
import IconShoppingCart from '@material-ui/icons/ShoppingCart'
import IconPeople from '@material-ui/icons/People'
import IconBarChart from '@material-ui/icons/BarChart'
import IconLibraryBooks from '@material-ui/icons/LibraryBooks'
import IconAssignment from '@material-ui/icons/Assignment'
import IconBusiness from '@material-ui/icons/Business'
import IconAccountBalance from '@material-ui/icons/AccountBalance'
import IconPerson from '@material-ui/icons/Person'
import IconSettings from '@material-ui/icons/Settings'
import IconLocalShipping from '@material-ui/icons/LocalShipping'
import IconStorefront from '@material-ui/icons/Storefront'
import IconReceipt from '@material-ui/icons/Receipt'
import IconLocalGroceryStore from '@material-ui/icons/LocalGroceryStore'
import IconAssessmentOutlined from '@material-ui/icons/AssessmentOutlined'
import IconPayment from '@material-ui/icons/Payment'
import IconCategory from '@material-ui/icons/Category'
import AppMenuItem from 'components/layout/menu/AppMenuItem'

const drawerWidth = 240

const useStyles = makeStyles(theme =>
  createStyles({
    appMenu: {
      width: '100%',
    },
    navList: {
      width: drawerWidth,
    },
    menuItem: {
      width: drawerWidth,
    },
    menuItemIcon: {
      color: '#ccc',
    },
  }),
)

const beneficiaryMenuItems = {
    dashboard: {
      name: 'Dashboard',
      link: '/beneficiary',
      Icon: IconDashboard,
    },
    food_donations: {
      name: 'Parcels',
      link: '/beneficiary/parcels',
      Icon: IconShoppingCart,
    },
    branches: {
      name: 'Branches',
      link: '/beneficiary/branches',
      Icon: IconStorefront,
    },
    // reports: {
    //   name: 'Reports',
    //   link: '/beneficiary/reports',
    //   Icon: IconAssessmentOutlined,
    // },
    settings: {
      name: 'Settings',
      link: '/beneficiary/settings/company',
      Icon: IconSettings,
    },
}

const BeneficiaryMenu = () => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const logged_in_user = userList.getUser()
  const can_edit_users = logged_in_user.is_superuser

  let allowed_menu_keys
  if ( logged_in_user.is_superuser ) {
      allowed_menu_keys = ["dashboard", "food_donations", "branches", "settings"]
  } else {
      allowed_menu_keys = ["dashboard", "food_donations", "branches", "settings"]
  }
  const menu_items = map(allowed_menu_keys, (menu_key) => beneficiaryMenuItems[menu_key])


  return (
    <List component="nav" className={classes.appMenu} disablePadding>
      {/* <AppMenuItem {...appMenuItems[0]} /> */}
      {map(menu_items, (item, index) => (
        <AppMenuItem {...item} key={index} />
      ))}
    </List>
  )
}

export default BeneficiaryMenu
