import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { capitalize, get, map } from 'lodash'
import clsx from 'clsx'
import { makeStyles, Grid, Typography, Card, CardContent, CardActions, CardActionArea, Collapse, IconButton, Button, Tooltip, CircularProgress } from '@material-ui/core'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import Timestamp from 'components/Timestamp'
import FavoriteIcon from '@material-ui/icons/Favorite'
import VisibilityIcon from '@material-ui/icons/Visibility'
import ReceiptIcon from '@material-ui/icons/Receipt'
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos'
import { Link } from 'react-router-dom'
import NumberFormat from 'react-number-format'
import Switch from '@material-ui/core/Switch'
import { contactDashboardList } from '../../dashboard/actions/contact_dashboard'
import { getActiveCompanyId } from 'actions/active_company'

const useStyles = makeStyles((theme) => ({
    root: {
      paddingLeft: 15,
      paddingRight: 15
    },
    widgetSwitch: {
      position: 'absolute',
      top: 150,
      right: 20,
    },
    cardRoot: {
        //boxShadow: '0 0.2rem 0.5rem rgb(33 78 130 / 20%) !important',
        minHeight: 130,
        position: 'relative',
        [theme.breakpoints.down('sm')]: {
            minHeight: 120,
        },
    },
    redBorder: {
        borderLeft: '4px solid red'
    },
    redKPI: {
        color: 'red'
    },
    yellowBorder: {
        borderLeft: '4px solid #fbdf06'
    },
    yellowKPI: {
        color: '#fbdf06'
    },
    widgetKpi: {
        marginTop: 5,
        marginBottom: -15,
        fontWeight: 500,
        fontSize: 24,
        textAlign: 'center',
        [theme.breakpoints.down('sm')]: {
            fontSize: 18,
            marginTop: 10,
        },
    },
    iconsBottomRight: {
        [theme.breakpoints.down('sm')]: {
            marginTop: -5,
            marginBottom: -10,
        },
    },
    expandMoreIcon: {
        [theme.breakpoints.down('sm')]: {
            marginBottom: -15,
            marginTop: -5

        },
    },
    expand: {
      transform: 'rotate(0deg)',
      marginLeft: 'auto',
      transition: theme.transitions.create('transform', {
        duration: theme.transitions.duration.shortest,
      }),
    },
    expandOpen: {
      transform: 'rotate(180deg)',
    },
    title: {
      fontWeight: 500,
      color: '#637382',
      [theme.breakpoints.down('sm')]: {
          fontSize: 14,
      },
    },
    iconButton: {
      marginTop: -30,
      [theme.breakpoints.down('sm')]: {
          padding: 0,
          paddingLeft: 8,
          paddingRight: 8
      },
    },
    viewMoreButton: {
      fontWeight: 400,
      fontSize: 10,
      textTransform: 'capitalize'
    },
    expandIcon: {
      position: 'absolute',
      top: 0,
      right: 0
    }
}))

export default function DonationWidgets({hideRedirect, donationsPageClass, hide, isDonations, is_loading}) {
    const classes = useStyles()
    const dispatch = useDispatch()
    let initialWidgetState = true
    if(hide) {initialWidgetState = false}
    const [showWidgets, setShowWidgets] = React.useState(initialWidgetState)
    const company_id = getActiveCompanyId()
    const dashboardData = useSelector(() => contactDashboardList.getForCompany(company_id))
    const totalDonationsValue = useSelector(() => get(dashboardData, ["parcels_as_donor", "total_donor_parcels_value_rands"]))
    const totalWeight = useSelector(() => get(dashboardData, ["parcels_as_donor", "total_donor_parcels_weight_kg"]))
    const totalMeals = useSelector(() => get(dashboardData, ["parcels_as_donor", "total_donor_parcels_meals"]))
    const lastDonation = useSelector(() => get(dashboardData, ["parcels_as_donor", "most_recent_donor_parcel"]))
    const numOpenTickets = "-"
    const [expanded, setExpanded] = React.useState(false)

    React.useEffect(() => {
        dispatch(contactDashboardList.ensureObjectLoaded())
    }, [])

    const handleShowWidgets = (event) => {
        setShowWidgets(event.target.checked)
    }

    const handleExpandClick = () => {
        setExpanded(!expanded)
    }

    const taxCertificateExists = false // check if certificate exists

    const taxCertificateStatus = () => {
        let tcStatus = "generate"
        if(taxCertificateExists) {
            tcStatus = "view"
        }
        return tcStatus
    }

    const widget = ({ title, date, kpi, collapsedContent, actions, color }) => {
        return(
            <Card className={classes.cardRoot} variant="outlined">
              <CardContent>
                <Typography className={classes.title} align="center">{ title }</Typography>
                { is_loading ? <div align="center" style={{ marginTop: 10 }}><CircularProgress style={{ color: '#CFD8DD' }} /></div> :
                    <>
                    { date &&
                     <Typography style={{color: color}} className={[classes.widgetKpi]}>{date}</Typography>
                    }
                    { kpi &&
                     <Typography className={classes.widgetKpi} style={{color: color}}>{kpi}</Typography>
                    }
                    </>
                }

              </CardContent>
              { actions &&
               <CardActions disableSpacing>
                 <Grid container justify="center">
                   <div>
                     { map(actions, (action) => {
                       if(get(action, "button")) {
                         return <Button component={Link} to={action.link} size="small" className={classes.viewMoreButton} variant="outlined" disableElevation>{capitalize(action.title)}</Button>
                       }
                       else {
                        return(
                           <Tooltip title={capitalize(action.title)} arrow>
                             <IconButton component={Link} to={action.link} aria-label={action.title} className={classes.iconButton}>{action.icon}</IconButton>
                           </Tooltip>
                         )
                       }
                      }
                    )}

                    { collapsedContent &&
                      <div className={classes.expandIcon}>
                          <Tooltip title={ !expanded ? "Expand for more details" : "Close" } arrow>
                            <IconButton
                              className={clsx(classes.expand, {
                                [classes.expandOpen]: expanded,
                              })}
                              onClick={ handleExpandClick }
                              aria-expanded={ expanded }
                              aria-label="show more"
                            >
                              <ExpandMoreIcon />
                            </IconButton>
                          </Tooltip>
                      </div>
                     }
                   </div>
                 </Grid>
               </CardActions>
              }
              { collapsedContent &&
               <Collapse in={ expanded } timeout="auto" unmountOnExit>
                 <CardContent>
                   { collapsedContent }
                 </CardContent>
               </Collapse>
              }
            </Card>
        )
    }

    const lastDonationWidget = (lastDonation) => {
        let title = "Last Donation"
        let donation_id = get(lastDonation, ["id"])
        let date = <Timestamp value={ get(lastDonation, ["donated_at"]) } format="date" />

        let weight = get(lastDonation, ["total_weight_kg"])
        let meals = weight*window.LOCAL_SETTINGS.MEALS_CONVERSION
        let value = get(lastDonation, ["total_value_rands"])
        let taxCertificateAction = taxCertificateStatus()
        let taxCertificateLink = "/donor/tax_certificates/" + taxCertificateAction + "/" + donation_id
        let actions = [
          {title: "View Last Donation", link: "/donor/donations/order/" + donation_id, button: true}
        ]
        let color = "#3949ab"
        let collapsedContent = <Typography variant="caption">\
                                   Weight: {weight}kg<br />
                                    Meals: {meals} meals<br />
                                    Rand Value: R{value}
                               </Typography>
            return(
                widget({ title, date, collapsedContent, actions, color })
            )
    }

    const totalDonationsWidget = (kpi, hideRedirect) => {
        let title = "Total Donations"
        let color = "#e91e63"
        let actions = []
        if(!hideRedirect && !isDonations){
            actions = [
                {title: "View Donations", link: "/donor/donations", button: true}
            ]
        }
        return(
            widget({title, kpi, actions, color})
        )
    }

    const totalValueDonationsWidget = (kpi, hideRedirect) => {
        let title = "Total Value"
        let color = "#43a047"
        let actions = []
        kpi = <NumberFormat value={kpi} displayType={'text'} thousandSeparator={true} prefix={'R'} />
        if(!hideRedirect && !isDonations){
            actions = [
                {title: "View Donations", link: "/donor/donations", button: true}
            ]
        }
        return(
            widget({title, kpi, actions, color})
        )
    }

    const totalMealsWidget = (kpi, hideRedirect) => {
        let title = "Total Meals"
        let actions = []
        kpi = <NumberFormat value={kpi} displayType={'text'} thousandSeparator={true} decimalScale={0} />
        let color = '#f57f17'
        if(!hideRedirect){
            actions = [
                {title: "View Distribution", link: "/donor/donations/distribution", button: true}
            ]
        }
        return(
            widget({title, kpi, actions, color})
        )
    }

    const totalWeightWidget = (kpi, hideRedirect) => {
        let title = "Total Weight"
        let actions = []
        kpi = <NumberFormat value={kpi} displayType={'text'} thousandSeparator={true} decimalScale={0}  suffix={'kg'} />
        let color = '#7b1fa2'
        if(!hideRedirect && !isDonations){
            actions = [
                {title: "View Donations", link: "/donor/donations", button: true}
            ]
        }
        return(
            widget({title, kpi, actions, color})
        )
    }

    const openTicketsWidget = (kpi, hideRedirect) => {
        let title = "Tickets"
        let actions = []
        kpi = "Coming Soon" //<NumberFormat value={kpi} displayType={'text'} thousandSeparator={true} decimalScale={0} />
        let color = '#ccc'
        // if(!hideRedirect){
        //     actions = [
        //         {title: "View Open Tickets", tooltip: true, link: "/donor/tickets?status=open", icon: <ArrowForwardIosIcon fontSize="small" />}
        //     ]
        // }
        return(
            widget({title, kpi, actions, color})
        )
    }

    return (
        <div className={classes.root}>
          <Grid container justify="flex-end" className={classes.widgetSwitch}>
              <Switch onChange={handleShowWidgets} checked={showWidgets} name="showWidgets" size="small" color="primary"/> {showWidgets ? "Hide" : "Show"} widgets
          </Grid>

          <Collapse in={showWidgets}>

            <Grid container spacing={2}>

              <Grid item xs={6} md={4} lg={2}>
                {lastDonationWidget(lastDonation)}
              </Grid>

              <Grid item xs={6} md={4} lg={2}>
                {totalDonationsWidget(get(dashboardData, ["parcels_as_donor", "total_donor_parcels_count"]), false)}
              </Grid>

              <Grid item xs={6} md={4} lg={2}>
                {totalWeightWidget(totalWeight)}
              </Grid>

              <Grid item xs={6} md={4} lg={2}>
                {totalMealsWidget(totalMeals)}
              </Grid>

              <Grid item xs={6} md={4} lg={2}>
                {totalValueDonationsWidget(totalDonationsValue)}
              </Grid>

              <Grid item xs={6} md={4} lg={2}>
                {openTicketsWidget(numOpenTickets)}
              </Grid>

            </Grid>

          </Collapse>
        </div>
    )

}
