import { ItemList } from 'orm'
import { map } from 'lodash'

class ContactRoleList extends ItemList {
    getEntityKey() {
        return "admin/contact_role"
    }
    
}

export const adminContactRoleList = new ContactRoleList("admin_contact_role__default")
export const adminPublishedContactRoleList = new ContactRoleList("admin_published_contact_role__default")

export const invalidateContactRoleLists = () => {
    return (dispatch, getState) => {
        dispatch(adminContactRoleList.invalidateList())
        dispatch(adminPublishedContactRoleList.invalidateList())
    }
}
