import { ItemList } from 'orm'

class DropdownOptions extends ItemList {
    constructor(dropdown_name) {
        super(`options__${dropdown_name}`)
        this.url = `marketing/dropdown_options/${dropdown_name}`
    }

    getEntityKey() {
        return this.url
    }

}

export const companyTypes = new DropdownOptions('company_types')
