import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { useHistory, useParams } from 'react-router-dom'
import { head, get, map, keys, split, size } from 'lodash'
import { adminEditableEmailCustomParameterList  } from './actions/admin_editable_email_custom_parameter'
import { Separator } from 'components/layout/Separator'
import { Formik, Form, FieldArray, Field } from 'formik'
import Loading from 'components/Loading'
import { FormikTextareaField } from 'components/form/TextareaField'
import { FormikInputField } from 'components/form/InputField'
import AdminMainLayout from 'admin/components/layout/AdminMainLayout'
import BusyMask from 'components/BusyMask'
import Timestamp from 'components/Timestamp'
import * as Yup from 'yup'
import {showSuccess, showError} from 'actions/Error'
import { Button, Card, CardContent, Grid, Typography } from '@material-ui/core'
import Alert from '@material-ui/lab/Alert'
import CardInfoText from 'components/layout/CardInfoText'
import { handleSubmitResult } from 'actions/form'
import { FormikGeneralFormErrors } from 'components/form/GeneralFormErrors'
import { globalSettingsList } from 'actions/settings'
import { Error } from 'components/layout/Error'

const validationSchema = Yup.object().shape({
    'name': Yup.string().required("Required"),
    'tag': Yup.string().required("Required"),
    'html_content': Yup.string(),
    'text_content': Yup.string()
})

export default function AdminEditableEmailCustomParameter({ ...props }) {
    const dispatch = useDispatch()
    const history = useHistory()
    let { editable_email_custom_parameter_id } = useParams()
    const editable_email_custom_parameter = adminEditableEmailCustomParameterList.getObject(editable_email_custom_parameter_id)
    const initial_values = editable_email_custom_parameter
    const title = get(editable_email_custom_parameter, ["name"]) ? get(editable_email_custom_parameter, ["name"]) : 'New Custom Parameter'
    const can_edit = true
    const is_loading = adminEditableEmailCustomParameterList.isLoading()
    const is_busy = adminEditableEmailCustomParameterList.getIsSavingObject()

    useEffect(() => {
        dispatch(adminEditableEmailCustomParameterList.ensureObjectLoaded(editable_email_custom_parameter_id))
        dispatch(globalSettingsList.ensureGlobalSettingsLoaded())
    })

    const onSave = (values, formik_funcs) => {
        const on_ok = function(json) {
            dispatch(adminEditableEmailCustomParameterList.invalidateList())
            if ( ! editable_email_custom_parameter_id ) {
                history.push(`/admin/editable_email_custom_parameter/${json.id}/`)
            }
            showSuccess("Saved", "Parameter saved")
        }
        if ( editable_email_custom_parameter_id ) {
            values.id = editable_email_custom_parameter_id
            return dispatch(adminEditableEmailCustomParameterList.saveObject(values)).then((res) => handleSubmitResult({res, formik_funcs, on_ok}))
        } else {
            return dispatch(adminEditableEmailCustomParameterList.saveNewObject(values)).then((res) => handleSubmitResult({res, formik_funcs, on_ok}))
        }
    }

    const renderParameterTips = () => {
        return (
            <Card>
              <Alert severity="info">
                The tag field is a regular expression. Each occurance of the tag will be replaced by either the "html content" or "text content" fields.
              </Alert>

              <CardContent>

                <Typography variant="h6">
                  Example 1: Token replacement
                </Typography>
                <Typography>Tag</Typography>
                <pre>{"__WHITESPACE__"}</pre>
                <br/>

                <Typography>Html content</Typography>
                <pre>{"<br/>"}</pre>
                <br/>

                <Typography>Then input...</Typography>
                <pre>{"Line 1 __WHITESPACE__ Line 2"}</pre>
                <br/>

                <Typography>becomes...</Typography>
                <pre>{"Line 1 <br/> Line 2"}</pre>
                <br/>

                <hr />

                <Typography variant="h6">
                  Example 2: Custom paragraph tag
                </Typography>
                <Typography>Tag</Typography>
                <pre>{"<xoip-p>(?P<content>.*?)</xoip-p> "}</pre>
                <br/>


                <Typography>Html content</Typography>
                <pre>{"<p margin='0'>\\g<content></p>"}</pre>
                <br/>

                <Typography>Then input...</Typography>
                <pre>{"This is a <xoip-p>Paragraph</xoip-p>"}</pre>
                <br/>

                <Typography>becomes...</Typography>
                <pre>{"This is a <p margin='0'>Paragraph</p>"}</pre>

                <hr />

                <Typography variant="h6">
                  Example 3: Tokenised replacement
                </Typography>
                <Typography>Tag</Typography>
                <pre>{"XOIP-LINK: LABEL=(?P<label>.*?) LINK=(?P<link>.*?) END"}</pre>
                <br/>

                <Typography>Html content</Typography>
                <pre>{"<a href='\g<link>'>\g<label></a>"}</pre>
                <br/>

                <Typography>Then input...</Typography>
                <pre>{"blahblah XOIP-LINK: LABEL=my label LINK=app.xoip.com END blahblah"}</pre>
                <br/>

                <Typography>becomes...</Typography>
                <pre>{"blahblah <a href='app.xoip.com'>my label</a> blahblah"}</pre>
                <br/>


                <Typography variant="h6">
                  Additional hints
                </Typography>
                <a target="_blank" href="https://regex101.com/">Interactive regex tester</a>
                <br/>
                <a target="_blank" href="https://docs.python.org/3/howto/regex.html">Python3 regex doc</a>
                <br/>
                <br/>
                <Typography>New line in text content</Typography>
                <pre>{"\\n"}</pre>
                <br/>

                <Typography>A{"(?P<content>.*?)"}Z</Typography>
                <pre>Find anything between A and Z, store in variable "content", and replace for {"\g<content>"}</pre>
                <Typography variant="caption">(The ? after .* means don't be greedy)</Typography>

              </CardContent>
          </Card>
        )
    }

    const renderForm = (formik_props) => {
        return (
            <div>
              <br/>

              <FormikInputField name="name"
                                label="Name"
                                formik_props={formik_props}
              />

              <FormikTextareaField name="description"
                                   label="Description"
                                   formik_props={formik_props}
                                   rows={5}
              />

              <FormikTextareaField name="tag"
                                   label="Tag"
                                   formik_props={formik_props}
                                   rows={5}
              />

              <FormikTextareaField name="html_content"
                                   label="Html content"
                                   formik_props={formik_props}
                                   rows={5}
              />

              <FormikTextareaField name="text_content"
                                   label="Text content"
                                   formik_props={formik_props}
                                   rows={5}
              />

              <br/>
              <br/>
              { can_edit &&
                <Button type="submit" variant="contained" color="primary" size="large">Save</Button>
              }
              { ! can_edit &&
                <Error>Editing custom parameters is disabled</Error>
              }
              <br/>
            </div>
        )
    }

    return (
        <AdminMainLayout
          title={title}
          breadcrumbs={[
            {name: 'admin_home'},
            {name: 'editable_emails', label: 'Editable Emails', url: '/admin/editable_emails'},
            {name: 'editable_email_custom_parameters', label: 'Editable Email Custom Parameters', url: '/admin/editable_email_custom_parameters'},
            {name: 'editable_email_custom_parameter', label: title, url: `/admin/editable_email_custom_parameter/${editable_email_custom_parameter_id}`},
        ]}>
            { is_busy && <BusyMask /> }
            { is_loading && <Loading /> }

            <Formik
              initialValues={initial_values}
              onSubmit={onSave}
              enableReinitialize={true}
              validationSchema={validationSchema}
            >
              {formik_props => {
                  const { values } = formik_props
                  return (
                      <Form>
                        <FormikGeneralFormErrors />
                            { is_loading && <Loading /> }
                            <Grid container spacing={2}>
                              <Grid item xs={12} lg={8}>
                                <Card style={{padding: '0 15px 15px 15px'}} variant="outlined">
                                  { renderForm(formik_props) }
                                </Card>
                              </Grid>
                              <Grid item xs={12} lg={4}>
                                { renderParameterTips() }
                              </Grid>
                            </Grid>
                      </Form>
                  )}
              }
            </Formik>
        </AdminMainLayout>
    )
}
