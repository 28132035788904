import React, { Component } from 'react'
import { ErrorMessage } from 'formik'
import Alert from '@material-ui/lab/Alert'

export const FormikGeneralFormErrors = ( { name, render, ...props } ) => {

	name = name || "non_field_errors"

	render = render || function( msg ) {
		console.error( "Error message: ", msg )
		return msg
	}

	return (
		<ErrorMessage name={ name } render={ msg => render( <Alert severity="error" style={ { marginBottom: 7 } }>{ msg }</Alert> ) } />
	)

}
