import React, { useEffect } from 'react'
import { get } from 'lodash'
import { useSelector, useDispatch } from 'react-redux'
import { contactCompanyReportGeneration } from './actions/contact_company_report_generation'
import { Tooltip } from '@material-ui/core'
import GetAppIcon from '@material-ui/icons/GetApp'

export const GeneratedReportDownload = ({generated_report_id, label}) => {
  const dispatch = useDispatch()
  const is_loading = generated_report_id && contactCompanyReportGeneration.isLoading()
  const report = useSelector(() => contactCompanyReportGeneration.getObject(generated_report_id))

  useEffect(() => {
      dispatch(contactCompanyReportGeneration.ensureObjectLoaded(generated_report_id))
  }, [generated_report_id])

  return (
    <>
    { generated_report_id && report ?
      <Tooltip arrow title={`Download ${get(report, ["report_file_info", "original_filename"])}`}>
        <a href={get(report, ["report_file_info", "original_download_url"])}>
          { label ? label : <GetAppIcon fontSize="large" /> }
        </a>
      </Tooltip> :
      "---"
    }
    </>
  )

}
