import PageviewOutlinedIcon from '@material-ui/icons/PageviewOutlined'
import DeleteIcon from '@material-ui/icons/Delete'
import EditIcon from '@material-ui/icons/Edit'
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank'
import { makeStyles } from "@material-ui/core"
import { red } from '@material-ui/core/colors'
import { showSuccess, showError } from 'actions/Error'

const useStyles = makeStyles((theme) => ({
    actionIcon: {
        marginRight: 7,
        marginLeft: -5,
        fontSize: 18
    },
}))

export default function TableRowActions(props) {

    const classes = useStyles()

    const {
        canDelete,
        onViewRow,
        onEditRow,
        onDeleteRow,
        onSelectRow,
        item_list,
        dispatch
    } = props

    const onEdit = (rowData) => {
        if ( onEditRow ) {
            onEditRow(rowData.id)
        }
    }

    const onView = (rowData) => {
        if ( onViewRow ) {
            onViewRow(rowData.id)
        }
    }

    const onDelete = (rowData) => {
        if ( onDeleteRow ) {
            onDeleteRow(rowData.id)
        }
    }

    const actions = []

    if ( onViewRow ) {
        actions.push({
            icon: <PageviewOutlinedIcon className={classes.actionIcon} />,
            tooltip: 'View',
            onClick: (rowData) => { onView(rowData) }
        })
    }
    if ( onEditRow ) {
        actions.push({
            icon: <EditIcon className={classes.actionIcon} />,
            tooltip: 'Edit',
            onClick: (rowData) => { onEdit(rowData) }
        })
    }
    if ( onSelectRow ) {
        actions.push({
            icon: <CheckBoxOutlineBlankIcon color="primary" />,
            tooltip: 'Select',
            onClick: (rowData) => { onSelectRow(rowData) }
        })
    }
    if ( item_list && canDelete !== false ) {
        actions.push({
            icon: <DeleteIcon style={{ color: red[300] }} className={classes.actionIcon} />,
            tooltip: 'Delete',
            onClick: (rowData) => { onDelete(rowData) }
        })
    }

    return actions
}
