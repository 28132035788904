import React, { useState, useEffect } from 'react'
import { get, join, reverse, clone, tail } from 'lodash'
import { useDispatch, useSelector } from 'react-redux'
import { adminWarehouseProductAutoCompleteList } from 'admin/components/inventory/actions/admin_warehouse_product'
import AdminProductForm from './AdminProductForm'
import { showSuccess, showError } from 'actions/Error'
import { Timestamp } from 'components/Timestamp'
import { CommonTable } from 'components/CommonTable'
import { handleSubmitResult } from 'actions/form'
import { validationSchema } from './AdminProductForm'
import { Modal } from 'components/layout/Modal'
import { makeStyles } from '@material-ui/core/styles'
import { Formik, Form } from 'formik'
import FormikOnChange from 'components/form/FormikAutoSave'
import { FormikGeneralFormErrors } from 'components/form/GeneralFormErrors'
import { ProductCategorySelectField } from 'admin/components/product_categories/form/ProductCategorySelectField'
import { HierarchyCrumbs } from 'components/layout/HierarchyCrumbs'
import { Button, Typography, Divider, Grid } from '@material-ui/core'
import CommonInlineAddNewFormLayout from 'components/layout/CommonInlineAddNewFormLayout'
import SearchBar from 'components/layout/SearchBar'
import { formatDate } from '../../../../utils/formatDateTime'
import { BorderColor } from '@material-ui/icons'

const useStyles = makeStyles( ( theme ) => ( {
  treeContainer: {
    margin: "10px"
  },
  infoRow: {
    display: "flex"
  },
  filterRow: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    width: "100%"
  },
  filterRowItem: {
    marginRight: "10px",
    width: "100%"
  }
} ) )

export function FormikWarehouseProductAutoCompleteDropdown( { name, label, formik_props, can_edit = true, ...props } ) {

  const [ open, setOpen ] = useState( false )
  const [ adding_new, setAddNew ] = useState( false )
  const classes = useStyles()
  const dispatch = useDispatch()
  const { field, meta } = formik_props
  const touched = get( meta, "touched" )
  const errors = get( meta, "error" )
  const warehouse_product_id = get( formik_props.values, name )
  const selected_warehouse_product = warehouse_product_id && useSelector( () => adminWarehouseProductAutoCompleteList.getObject( warehouse_product_id ) )
  const is_loading = useSelector( () => adminWarehouseProductAutoCompleteList.isLoading() )

  useEffect( () => {
    async function fetchStaticObjects() {
      dispatch( adminWarehouseProductAutoCompleteList.ensureObjectLoaded( warehouse_product_id ) )
    }
    fetchStaticObjects()
  }, [] )

  const products = adminWarehouseProductAutoCompleteList.getVisibleObjects()

  const onUpdateListOrdering = ( field ) => {
    dispatch( adminWarehouseProductAutoCompleteList.updateListOrdering( field ) )
  }

  const columns = [
    {
      field: 'warehouse_name',
      title: 'Warehouse',
    },
    {
      field: 'product_name',
      title: 'Product',
    },
    {
      field: 'from_company_name',
      title: 'Donor',
    },
    {
      field: 'quantity',
      title: 'Quantity available',
      sort: ( direction ) => onUpdateListOrdering( direction === 'asc' ? 'quantity' : '-quantity' ),
    },
    {
      field: 'product_category',
      title: 'Categories',
      render: ( item ) => <HierarchyCrumbs crumbs={ get( item, "product_category_crumbs", [] ) } />,
    },
    {
      field: 'best_before_date', title: 'Best before date',
      render: ( item ) => <Timestamp value={ get( item, "best_before_date" ) } format='date' />,
      sort: ( direction ) => onUpdateListOrdering( direction === 'asc' ? 'best_before_date' : '-best_before_date' ),
    },
    {
      field: 'expiry_date', title: 'Expiry date',
      render: ( item ) => <Timestamp value={ get( item, "expiry_date" ) } format='date' />,
      sort: ( direction ) => onUpdateListOrdering( direction === 'asc' ? 'expiry_date' : '-expiry_date' ),
    },
    {
      field: 'created_at', title: 'Added at',
      render: ( item ) => <Timestamp value={ get( item, "created_at" ) } format='datetime' />,
      sort: ( direction ) => onUpdateListOrdering( direction === 'asc' ? 'created_at' : '-created_at' ),
    },
  ]

  const onClose = () => {
    setOpen( false )
  }

  const onOpen = () => {
    setOpen( true )
  }

  const onSelectItem = ( { id } ) => {
    formik_props.setFieldValue( name, id )
    onClose()
  }

  const onFilterChanged = ( values, formik_props ) => {
    dispatch( adminWarehouseProductAutoCompleteList.updateListFilter( values ) )
    dispatch( adminWarehouseProductAutoCompleteList.fetchListIfNeeded() )
  }

  const renderFilter = () => {
    return (
      <Formik
        initialValues={ {} }
        onSubmit={ onFilterChanged }
        enableReinitialize={ true }
      >
        { formik_props => {
          const { values } = formik_props
          return (
            <Form>
              <FormikOnChange onChange={ ( values ) => onFilterChanged( values, formik_props ) } />
              <FormikGeneralFormErrors render={ ( msg ) => msg } />
              <div className={ classes.filterRow }>
                <div className={ classes.filterRowItem }>
                  <ProductCategorySelectField
                    name="product_category"
                    can_add={ false }
                    can_edit={ false }
                    formik_props={ formik_props }
                  />
                </div>
                <div className={ classes.filterRowItem }>
                  <SearchBar label="Search products..." formik_props={ formik_props } />
                </div>

                <div className={ classes.filterRowItem }>
                  <Button variant="contained" onClick={ () => setAddNew( true ) }>
                    Add New Product
                  </Button>
                </div>
              </div>
            </Form>
          )
        } }
      </Formik>
    )
  }

  const onSaveNewProduct = ( values, formik_funcs ) => {
    const on_ok = function( json ) {
      dispatch( admcloneinWarehouseProductAutoCompleteList.invalidateList() )
      dispatch( adminWarehouseProductAutoCompleteList.fetchListIfNeeded() )
      showSuccess( "Saved", "New product Saved" )
      const product_id = json.id
      setAddNew( false )
      onSelectItem( { id: product_id } )
    }
    return (
      dispatch( adminWarehouseProductAutoCompleteList.saveNewObject( values ) ).then( ( res ) => handleSubmitResult( { res, formik_funcs, on_ok } ) )
    )
  }

  const renderAddNewProduct = () => {
    return (
      <CommonInlineAddNewFormLayout
        is_loading={ false }
        title={ "Add Warehouse Product" }
        initial_values={ {} }
        validationSchema={ validationSchema }
        onCancel={ () => setAddNew( false ) }
        onSave={ ( values, formik_funcs ) => onSaveNewProduct( values, formik_funcs ) }
      >
        {
          ( { formik_props } ) => <AdminProductForm formik_props={ formik_props } />
        }
      </CommonInlineAddNewFormLayout>
    )
  }

  const renderTreeSelector = () => {
    return (
      <Modal onClose={ onClose }
        fullWidth={ true }
        maxWidth='xl'
        title="Select a product from inventory"
      >
        <div className={ classes.treeContainer }>
          { renderFilter() }
          { adding_new && renderAddNewProduct() }
          <CommonTable
            is_loading={ is_loading }
            rows={ products }
            canDelete={ false }
            columns={ columns }
            onSelectRow={ ( item ) => onSelectItem( { id: item.id } ) }
            item_list={ adminWarehouseProductAutoCompleteList }
          />
        </div>
      </Modal>
    )
  }

  const renderClosedContent = () => {

    const product_name = get( selected_warehouse_product, "product_category_crumbs", [])[ 0 ]
    const crumbs = clone( tail( get( selected_warehouse_product, "product_category_crumbs", "" ) ) )
    const reversed_crumbs = reverse( crumbs )

    return (
      <>
        <Typography variant="h4">
          { product_name }
        </Typography>

        <Typography variant="caption">
          { join( reversed_crumbs, ' / ' ) }
        </Typography>
        <Divider style={ { margin: '10px 0', border: '2px solid #666' } } />
        <Typography variant="h6">Item weight</Typography>
        <Typography variant="body1">{ get( selected_warehouse_product, "product_weight_kg", "" ) }kg</Typography>
        <Divider style={ { margin: '10px 0' } } />
        <Grid container spacing={ 0 }>
          <Grid item xs={ 12 } sm={ 6 }>
            <Typography variant="h6">Best Before</Typography>       
          </Grid>
          <Grid item xs={ 12 } sm={ 6 }>
            <Typography variant="h6">Expiry</Typography>
          </Grid>
          <Grid item xs={ 12 } sm={ 6 }>
            { selected_warehouse_product.best_before_date ? formatDate(selected_warehouse_product.best_before_date) : <span style={ { color: 'red' } }>Not set</span> }
          </Grid>
          <Grid item xs={ 12 } sm={ 6 }>
            { selected_warehouse_product.expiry_date ? formatDate( selected_warehouse_product.expiry_date) : <span style={ { color: 'red' } }>Not set</span> }
          </Grid>
        </Grid>
        <Divider style={ { margin: '10px 0' } } />
        <Typography variant='h6'>Stock</Typography>
        <Typography>
          { selected_warehouse_product.quantity } left in stock
        </Typography>
        <Divider style={ { margin: '10px 0' } } />
      </>
    )
  }

  const renderClosed = () => {
    return (
      <>
        { !can_edit && renderClosedContent() }
        { can_edit &&
          <Button variant="contained" onClick={ onOpen }>
            { selected_warehouse_product &&
              <>
                { renderClosedContent() }
              </>
            }
            { !selected_warehouse_product &&
              "Select Product"
            }
          </Button>
        }
      </>
    )
  }

  return (
    <div>
      { open && renderTreeSelector() }
      { !open && renderClosed() }
    </div>
  )

}
