import React from 'react'
import Alert from '@material-ui/lab/Alert'

export function SuccessMessage({subscribed}) {
  return(
    <Alert severity="success">Success. {subscribed && 'You are already subscribed'}</Alert>
  )
}

export function ErrorMessage({error}) {
  return(
    <Alert severity="error"><div dangerouslySetInnerHTML={{__html: error}} /></Alert>
  )
}
