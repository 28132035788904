import { ItemList } from 'orm'
import { map } from 'lodash'

class ContactParcelList extends ItemList {
    getEntityKey() {
        return "contact/parcel"
    }

}

export const contactParcelList = new ContactParcelList("contact_parcel__default")
