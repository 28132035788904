import { ItemList } from 'orm'
import { map } from 'lodash'

class ChecklistList extends ItemList {
    getEntityKey() {
        return "admin/checklist"
    }

    getAsOptions() {
        const items = this.getVisibleObjects()
        return map(items, function(item) {
            return { label: item.name, value: item.id }
        })
    }

}

export const adminChecklistList = new ChecklistList("admin_checklist__default")
