import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { Modal } from 'components/layout/Modal'
import { get } from 'lodash'
import { Separator } from 'components/layout/Separator'
import { Formik, Form } from 'formik'
import FormCard from 'components/layout/FormCard'
import { FormikBeneficiaryAddressDropdownWithFormBasedAddNew } from 'admin/components/beneficiaries/form/BeneficiaryAddressDropdown'
import {showSuccess, showError} from 'actions/Error'
import { handleSubmitResult } from 'actions/form'
import { adminParcelList } from 'admin/components/orders/actions/admin_parcel'
import { FormikDateTimePicker } from 'components/form/DatetimePicker'
import { FormikCheckboxField } from 'components/form/CheckboxField'
import { FormikBeneficiaryAutoCompleteDropdown } from 'admin/components/beneficiaries/form/BeneficiaryAutoCompleteDropdown'
import { Grid, Card, CardContent, Typography, Divider, Button, makeStyles } from '@material-ui/core'
import Container from '@material-ui/core/Container'
import { adminBeneficiaryList } from 'admin/components/beneficiaries/actions/admin_beneficiary'
import * as Yup from 'yup'
import Alert from '@material-ui/lab/Alert'

const validationSchema = Yup.object().shape({
    to_company: Yup.string().required("Required"),
    // arrive_address: Yup.string().required("Required"),  // Removed because this field isn't shown if transport_by_company isn't ticked
    arrive_at: Yup.string().required("Required")
})

const useStyles = makeStyles((theme) =>({
    button: {
        marginBottom: -10,
        marginTop: 20,
    },
    fullWidthButton: {
        width: '100%',
    },
    fullWidthButtonDivLeft: {
        paddingRight: 5,
        [theme.breakpoints.down('sm')]: {
            paddingRight: 0,
        },
    },
    fullWidthButtonDivRight: {
        paddingLeft: 5,
        [theme.breakpoints.down('sm')]: {
            paddingLeft: 0,
        },
    },
    modalContent: {
        padding: 15
    }
}))

export const AdminDonorCreateBeneficiaryParcel = ({donor_parcel_id, onClose}) => {
    const classes = useStyles()
    const dispatch = useDispatch()
    const history = useHistory()
    const original_parcel = adminParcelList.getObject(donor_parcel_id)
    const [initialValues, setInitialValues] = useState(null)

    if ( initialValues === null ) {
        setInitialValues({'arrive_at': get(original_parcel, "arrive_at")})
    }

    const [postSaveAction, setPostSaveAction] = useState(null)
    const [selectedBeneficiary, setSelectedBeneficiary] = useState(null)

    const address_ids = selectedBeneficiary && get(selectedBeneficiary, ["company", "addresses"])

    useEffect(() => {
        async function fetchStaticObjects() {
            dispatch(adminParcelList.ensureObjectLoaded(donor_parcel_id))
        }
        fetchStaticObjects()
    }, [donor_parcel_id])

    const onSave = (values, formik_funcs) => {
        const on_ok = function(json) {
            dispatch(adminParcelList.invalidateList())
            showSuccess("Created", "Beneficiary order created")

            if ( postSaveAction === "view_copied_order" ) {
                viewCopiedOrder(json)
            } else if ( postSaveAction == "close" ) {
                onClose()
            } else {
                onClose()
            }
        }
        return dispatch(adminParcelList.createBeneficiaryParcelFromDonorParcel({donor_parcel_id, values})).then((res) => handleSubmitResult({res, formik_funcs, on_ok}))
    }

    const viewCopiedOrder = (copied_order) => {
        history.push(`/admin/orders/beneficiary/${copied_order.id}`)
        onClose()
    }

    const onSaveAndViewCopiedOrder = (formik_props) => {
        setPostSaveAction("view_copied_order")
        formik_props.submitForm()
    }

    const onSaveAndClose = (formik_props) => {
        setPostSaveAction("close")
        formik_props.submitForm()
    }

    const onToCompanyChanged = (company_id) => {
        if ( company_id ) {
            const beneficiary = adminBeneficiaryList.getForCompany({company_id})
            setSelectedBeneficiary(beneficiary)
        }
    }

    const renderSaveButtons = (formik_props, classes) => {
        return (
            <>
            <Grid container direction="row" justifyContent="space-between">

              <Grid>
                <Button variant="contained" color="primary" onClick={() => onSaveAndViewCopiedOrder(formik_props)}>
                  Save & view copied order
                </Button>
              </Grid>

              {/*
                <Grid>
                <Button variant="contained" size="small" color="primary" onClick={() => onSaveAndClose(formik_props)}>
                  Save & Close
                </Button>
              </Grid>
              */}

            </Grid>

            <Grid container>
              <Grid>
                <Separator height={10} />
                <Button onClick={onClose} size="small">
                  Cancel
                </Button>
              </Grid>
            </Grid>
            </>

        )
    }

    const renderCopyForm = (formik_props, classes) => {
        const transport_by_warehouse_company = get(formik_props.values, "transport_by_warehouse_company")
        return (
            <FormCard
                isSubmitting={formik_props.isSubmitting}
                renderSaveButtons={() => renderSaveButtons(formik_props, classes)}
                elevation={0}
            >
              <FormikBeneficiaryAutoCompleteDropdown name="to_company"
                                                     label="Beneficiary"
                                                     onChange={onToCompanyChanged}
                                                     formik_props={formik_props} />

              { selectedBeneficiary &&
                <>
                <FormikCheckboxField name="transport_by_warehouse_company"
                       label="SA Harvest will transport?"
                       formik_props={formik_props}
                />
              { transport_by_warehouse_company &&
                <FormikBeneficiaryAddressDropdownWithFormBasedAddNew name="arrive_address"
                                                                     beneficiary={selectedBeneficiary}
                                                                     label="Dropoff Address"
                                                                     address_ids={address_ids}
                                                                     formik_props={formik_props} />
              }
              </>
             }
             <Separator height={12} />
              <FormikDateTimePicker name="arrive_at"
                                    label="Dropoff Date"
                                    formik_props={formik_props} />

                                  <Separator height={60} />

            </FormCard>
        )

    }

    return (


        <Modal onClose={onClose} title="Create Beneficiary Order from Donor Order" noPadding>

            <Alert severity="info"><Typography>
              This will create a new beneficiary order using all products from this donor order.
              <br/>
              It is useful specifically for cases where an entire donor order is being donated to a single beneficiary.
              <br/>
              Only products with an <b>actual quantity > 0</b> in this order will result in products in the beneficiary order.
            </Typography>
            </Alert>

            <Formik
              initialValues={initialValues}
              onSubmit={onSave}
              enableReinitialize={true}
              validationSchema={validationSchema}
            >
              {formik_props => {
                  const { values } = formik_props
                  return (
                      <Form>
                        { renderCopyForm(formik_props, classes)}
                      </Form>
                  )
              }}
            </Formik>

        </Modal>

    )

}
