import { Grid, makeStyles } from "@material-ui/core"
import { get } from 'lodash'
import { renderCardFooter, renderDivider, renderCardHeader, renderCardNutrients } from './nutrition_facts/card_sections'

const useStyles = makeStyles((theme) => ({
  nutrition_facts_box: {
    border: '1px solid #000',
    padding: 10
  }
}))

export const NutritionFactsSummary = ({ data, meal_size_in_grams }) => {

  const classes = useStyles() 

  const renderNutritionCard = () => {
    return (
      <Grid container spacing={0} className={classes.nutrition_facts_box}>
        { renderCardHeader(get(data, "calories"), meal_size_in_grams, get(data, "nutrition_score")) }
        { renderCardNutrients(get(data, "macro_nutrients")) }
        { renderDivider() }
        { renderCardNutrients(get(data, "micro_nutrients")) }
        { renderDivider() }
        { renderCardFooter() }
      </Grid>
    )
  }

  return renderNutritionCard()

}