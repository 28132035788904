import React from 'react'
import { adminDonorAutoCompleteList } from '../actions/admin_donor'
import { FormikAutoCompleteDropdownWithFormBasedAddNew } from 'components/form/AutoCompleteDropdownWithFormBasedAddNew'
import { FormikAutoCompleteDropdown } from 'components/form/AutoCompleteDropdown'
import AdminDonorForm from './AdminDonorForm'
import { validationSchema } from './AdminDonorForm'

export function FormikDonorAutoCompleteDropdown( { name, label, addNew = false, formik_props, ...props } ) {

  const donorAutocompleteDropDown = (
    <FormikAutoCompleteDropdown
      item_list={ adminDonorAutoCompleteList }
      form_title="Add Donor"
      name={ name }
      validationSchema={ validationSchema }
      initial_values={ { company: { core_businesses: [], tags: [] } } }
      label={ label }
      formik_props={ formik_props }
      { ...props }
    />
  )

  const donorAutocompleteDropDownWithFormBasedAddNew = (
    <FormikAutoCompleteDropdownWithFormBasedAddNew
      item_list={ adminDonorAutoCompleteList }
      success_message="Donor added"
      form_title="Add Donor"
      name={ name }
      validationSchema={ validationSchema }
      initial_values={ { company: { core_businesses: [], tags: [] } } }
      label={ label }
      getSavedObjectId={ ( value ) => value.company.id }
      renderForm={ ( { formik_props } ) =>
        <AdminDonorForm formik_props={ formik_props } in_drawer />
      }
      formik_props={ formik_props }
      { ...props }
    />
  )

  return addNew ? donorAutocompleteDropDownWithFormBasedAddNew : donorAutocompleteDropDown

}
