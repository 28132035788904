import { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { get, filter, head } from 'lodash'
import { adminChecklistResultList } from './actions/admin_checklist_result'
import { adminChecklistScheduleList } from './actions/admin_checklist_schedule'
import AdminCommonListLayout from '../layout/AdminCommonListLayout'
import { AddButton } from 'components/form/Buttons'
import { Modal } from 'components/layout/Modal'
import AdminChecklistResultForm from './form/AdminChecklistResultForm'
import AdminChecklistAnswerForm from './form/AdminChecklistAnswerForm'
import { ShortId } from 'components/ShortId'
import { ChecklistName } from './ChecklistAttributes'
import { UserName } from 'admin/components/users/UserAttributes'
import AddIcon from '@material-ui/icons/Add'
import { Grid, Button } from '@material-ui/core'
import Timestamp from 'components/Timestamp'
import { MomentAsFrequency, weekDayValueToString } from 'components/form/Frequency'

export default function  AdminChecklistResults({checklist_id}) {
    const dispatch = useDispatch()
    const history = useHistory()
    const checklist_results = useSelector(() => adminChecklistResultList.getVisibleObjects())
    const checklist_schedules = useSelector(() => adminChecklistScheduleList.getVisibleObjects())
    const is_loading = useSelector(() => adminChecklistResultList.isLoading())
    const [editingChecklistResult, setEditingChecklistResult] = useState(null)
    const [addingChecklistResult, setAddingChecklistResult] = useState(null)
    const [checklistResultId, setChecklistResultId] = useState(null)

    useEffect(() => {
        dispatch(adminChecklistResultList.updateListFilter({ checklist: checklist_id }))
        dispatch(adminChecklistResultList.fetchListIfNeeded())
        dispatch(adminChecklistScheduleList.fetchListIfNeeded())
    },[])

    useEffect(() => {
        dispatch(adminChecklistResultList.invalidateList())
        dispatch(adminChecklistResultList.fetchListIfNeeded())
    },[editingChecklistResult])

    const renderSchedule = (checklist_schedule_id) => {
        const item_schedule = head(filter(checklist_schedules, function(x) {
            if(x.id == checklist_schedule_id) {
                return x
            }
        }))
        const frequency = <MomentAsFrequency moment_type={get(item_schedule, ['complete_checklist_on', 'moment_type'])} />
        const weekday = weekDayValueToString(get(item_schedule, ['complete_checklist_on', 'value']))
        return (
            <>
                {frequency} on {weekday}
            </>
        )
    }

    const renderStatus = (item) => {
        if(item.percentage_completed >= item.score_percentage) {
            return <span style={{color: 'green'}}>Pass</span>
        }
        else return <span style={{color: 'red'}}>Fail</span>
    }

    const columns = [
        {
            field: 'id',
            title: 'ID',
            width: 100,
            render: (item) => <ShortId value={item.id} />
        },
        {
            field: 'checklist',
            title: 'Checklist',
            render: (item) => <ChecklistName checklist_id={item.checklist} />,
            sort: (direction) => onUpdateListOrdering(direction === 'asc' ? 'checklist' : '-checklist')
        },
        {
            field: 'user',
            title: 'User',
            render: (item) => <UserName user_id={item.user} />,
            sort: (direction) => onUpdateListOrdering(direction === 'asc' ? 'user' : '-user')
        },
        {
            field: 'checklist_schedule',
            title: 'Schedule Date',
            render: (item) => renderSchedule(get(item, 'checklist_schedule')),
        },
        {
            field: 'percentage_completed',
            title: '% Completed',
            align: 'center',
            render: (item) => Math.round(item.percentage_completed) + '%'
        },
        {
            field: 'score_percentage',
            title: 'Pass mark',
            align: 'center',
            render: (item) => Math.round(item.score_percentage) + '%'
        },
        {
            field: 'completed_at',
            title: 'Completed at',
            align: 'center',
            render: (item) => <Timestamp value={get(item, 'completed_at')} format="at" />
        },
        {
            field: 'status',
            title: 'Status',
            align: 'center',
            render: (item) => renderStatus(item)
        }
    ]

    const onAddChecklistResult = () => {
        setEditingChecklistResult(true)
    }

    const onEditChecklistResult = (result_id) => {
        setChecklistResultId(result_id)
        setEditingChecklistResult(true)
    }

    const onSaved = () => {
        setEditingChecklistResult(false)
        setChecklistResultId(null)
    }

    const onSaveAnswer = () => {
        dispatch(adminChecklistResultList.invalidateList())
        dispatch(adminChecklistResultList.fetchListIfNeeded())
    }

    const renderActions = () => {
        return(
            <Grid container spacing={2}>
                <Grid item>
                    <AddButton label="Checklists" color="primary" variant="outlined" onClick={() => history.push('/admin/checklists')} />
                </Grid>
                <Grid item>
                    <AddButton label="Schedule" color="primary" variant="outlined" onClick={() => history.push('/admin/checklists/schedules')} />
                </Grid>
            </Grid>
        )
    }

    return(
        <>
            <AdminCommonListLayout
                active_key="checklist_results"
                breadcrumbs={[
                    {name: 'admin_home'},
                    {name: 'checklists', label: "Checklists", url: '/admin/checklists'},
                    {name: 'checklist_results', label: "Results", url: '/admin/checklists/results'}
                ]}
                add_label={<AddIcon />}
                onAddRow={onAddChecklistResult}
                onEditRow={onEditChecklistResult}
                is_loading={is_loading}
                columns={columns}
                item_list={adminChecklistResultList}
                rows={checklist_results}
                title="Checklist Results"
                renderAdditionalActions={renderActions}
            />

        { editingChecklistResult &&
                <Modal onClose={onSaved} fullWidth maxWidth="md" title="Checklist Results" noPadding>
                    <AdminChecklistResultForm
                        checklist_result_id={checklistResultId}
                        onSaved={onSaved}
                        onSaveAnswer={onSaveAnswer}
                    />
                </Modal>
            }
        </>
    )
}
