import { FormikDonorAutoCompleteDropdown } from './DonorAutoCompleteDropdown'
import AdminCommonCompanyForm from 'admin/components/companies/form/AdminCommonCompanyForm'
import * as Yup from 'yup'


export const validationSchema = Yup.object().shape( {
	'company': Yup.object().shape( {
		'name': Yup.string().required( "Required" )
	} )
} )

export default function AdminDonorForm( { formik_props, in_drawer, company_id, state_history } ) {

	const renderParentField = ( formik_props ) => <FormikDonorAutoCompleteDropdown name="company.parent" label="Parent company" formik_props={ formik_props } />

	// Gtp added, because this was broke in a recent Manu commit
	const props = []
	// end of hack

	return (
		<AdminCommonCompanyForm
			formik_props={ formik_props }
			renderParentField={ renderParentField }
			in_drawer={ in_drawer }
			company_id={ company_id }
			state_history={ state_history }
		/>
	)

}