import React, { Component, useState } from 'react'
import { connect } from 'react-redux'
import { head, get } from 'lodash'
import { CommonTable } from 'components/CommonTable'
import Timestamp from 'components/Timestamp'
import Hidden from '@material-ui/core/Hidden'
import { Button } from '@material-ui/core'
import { Modal } from 'components/layout/Modal'
import HistoryIcon from '@material-ui/icons/History'

class AdminStateHistoryList extends Component {

    constructor(props) {
        super(props)
        this.state = {show: false}
    }

    componentDidMount() {
        const { dispatch, item_list } = this.props
        dispatch(item_list.invalidateList())
        dispatch(item_list.fetchListIfNeeded())
    }

    componentDidUpdate() {
        const { dispatch, item_list } = this.props
        const { show } = this.state
        if ( show ) {
            dispatch(item_list.fetchListIfNeeded())
        }
    }

    onShow = () => {
        this.setState({show: true})
    }

    onHide = () => {
        this.setState({show: false})
    }

    render() {
        const { item_list, is_loading, headers, columns, items, active_key, title, show_as_table } = this.props
        const { show } = this.state
        const that = this

        const default_columns = [
            {
                field: 'created_at',
                title: 'Changed at',
                render: (item) => <Timestamp value={item.created_at} format='datetime' />
            },
            {
                field: 'new_status_name',
                title: 'Status'},
            {
                field: 'changed_by_user_display_name',
                title: 'User'
            },
        ]

        return (
            <>
                {show_as_table ?
                    <CommonTable
                        is_loading={ is_loading }
                        rows={items}
                        columns={ columns || default_columns }
                        item_list={item_list}
                        canDelete={false}
                    /> :
                    <>
                        { ! show &&
                          <Button onClick={that.onShow} size="small" startIcon={<HistoryIcon />}>
                            {title || "History"}
                          </Button>
                        }
                        { show &&
                          <Modal onClose={that.onHide} title={title || "History"} noPadding>
                              <CommonTable
                                  is_loading={ is_loading }
                                  rows={items}
                                  columns={ columns || default_columns }
                                  item_list={item_list}
                                  canDelete={false}
                              />
                          </Modal>
                        }
                    </>
                }

            </>
        )
    }
}

function mapStateToProps(state, props) {
    const { item_list } = props
    const items = item_list.getVisibleObjects()

    return {
        items,
        is_loading: item_list.isLoading()
    }
}

export default connect(mapStateToProps)(AdminStateHistoryList)
