import { ItemList } from 'orm'
// import { EnhancedFilter } from 'admin/actions/enhanced_filter'

class DonorParcelProductList extends ItemList {
    getEntityKey() {
        return "contact/donor_parcel_product"
    }

    // getEnhancedFilter() {
    //     if ( ! this.enhanced_filter ) {
    //         this.enhanced_filter = new EnhancedFilter(this)
    //     }
    //     return this.enhanced_filter
    // }
}

export const contactDonorParcelProductList = new DonorParcelProductList("contact_donor_parcel_product__default")
