import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { get ,startCase, camelCase } from 'lodash'
import AdminCommonListLayout from '../layout/AdminCommonListLayout'
import { adminDonorList } from './actions/admin_donor'
import { ShortId } from 'components/ShortId'
import { countryList } from 'actions/country'
import { provinceList } from 'actions/province'
import { getContentStatus } from 'components/companies/RenderTableCellContent'
import { Avatar, Chip, Typography } from '@material-ui/core'

export default function Donors() {
    const dispatch = useDispatch()
    const history = useHistory()
    const is_loading = useSelector(() => adminDonorList.isLoading())
    const donors = useSelector(() => adminDonorList.getVisibleObjects())
    const country_options = useSelector(() => countryList.getAsOptions())
    const province_options = useSelector(() => provinceList.getAsOptions())

    useEffect(() => {
        dispatch(adminDonorList.fetchListIfNeeded())
        dispatch(countryList.updatePaginationNumItemsPerPage(1000))
        dispatch(provinceList.updatePaginationNumItemsPerPage(1000))
        dispatch(countryList.fetchListIfNeeded())
        dispatch(provinceList.fetchListIfNeeded())
    }, [])

    const onCountryChanged = (country_id) => {
      dispatch(provinceList.filterOnCountry(country_id))
    }

    const onUpdateListOrdering = (field) => {
        dispatch(adminDonorList.updateListOrdering(field))
    }

    const onEditDonor = (donor_id) => {
        history.push({
            pathname: '/admin/donor/'+donor_id
        })
    }

    const onAddDonor = () => {
        history.push('/admin/donor')
    }
  
  const renderStatus = ( status ) => {
    // return status in a mui chip where active is green and inactive is red
    if ( status === 'active' ) {
      return <Chip label="Active" style={ { background: '#388e3c', color: 'white'}} />
    } else if ( status === 'inactive' ) {
      return <Chip label="Inactive" style={ {background: '#d32f2f', color: 'white' }} />
    }
  }


    const columns = [
        { field: 'logo',
          title: 'Logo',
          width: 100,
          render: (item) => <Avatar
                              alt={`${get(item, ['company', 'name'])}`}
                              src={get(item, ['company', 'logo_image', 'original_download_url'])}
                              style={{width: 100, cursor: 'pointer'}}
                              variant="square"
                              onClick={() => onEditDonor(get(item, 'id'))}
                            >
                              {get(item, ['company', 'name']).split(/\s/).reduce((response,word)=> response+=word.slice(0,1),'')}
                            </Avatar>,
        },
        { field: 'id',
          title: 'ID',
          width: 100, 
          render: (item) => <a onClick={() => onEditDonor(item.id)}><ShortId value={item.id} /></a>,
        },
        { field: 'name',
          title: 'Donor',
          visible: true,
          render: ( item ) => <Typography variant="h5" onClick={ () => onEditDonor( item.id ) } style={{ cursor: 'pointer'}}>{ startCase( camelCase( get( item, [ "company", "name" ] ) ) ) }</Typography>,
          sort: (direction) => onUpdateListOrdering(direction === 'asc' ? 'company__name' : '-company__name')
        },
        { field: 'parent', title: 'Parent Company',
          render: (item) => get(item, ["company", "parent_name"]),
          sort:(direction) => onUpdateListOrdering(direction === 'asc' ? 'company__parent__name' : '-company__parent__name')
        },
        { field: 'company.city_name',
          title: 'City',
          sort:(direction) => onUpdateListOrdering(direction === 'asc' ? 'company__addresses__city__name' : '-company__addresses__city__name')
        },
        // { field: 'company.total_parcels_donated_count',
        //   title: 'Total Donations',
        //   visible: true,
        //   align: 'center',
        //   render: (item) => get(item, ["company", "total_parcels_donated_count"]),
        // },
        // { field: 'company.total_parcels_donated_weight_kg',
        //   title: 'Total Weight',
        //   visible: true,
        //   align: 'right',
        //   render: (item) => get(item, ["company", "total_parcels_donated_weight_kg"]) ? `${round(get(item, ["company", "total_parcels_donated_weight_kg"]), 2).toLocaleString()} kg` : "---",
        //   // sort:(direction) => onUpdateListOrdering(direction === 'asc' ? 'company__total_parcels_donated_weight_kg' : '-company__total_parcels_donated_weight_kg')
        // },
        // { field: 'company.total_parcels_donated_meals',
        //   title: 'Total Meals',
        //   visible: true,
        //   align: 'right',
        //   render: (item) => get(item , ["company", "total_parcels_donated_meals"]) ? round(get(item , ["company", "total_parcels_donated_meals"]), 0).toLocaleString() : "---",
        //   // sort: (direction) => onUpdateListOrdering(direction === 'asc' ? 'company__total_parcels_donated_meals' : '-company__total_parcels_donated_meals')
        // },
        // { field: 'company.total_parcels_donated_value_rands',
        //   title: 'Total Value',
        //   visible: true,
        //   align: 'right',
        //   render: (item) => get(item , ["company", "total_parcels_donated_value_rands"]) ? `R ${round(get(item , ["company", "total_parcels_donated_value_rands"]), 0).toLocaleString()}` : "---",
        //   // sort: (direction) => onUpdateListOrdering(direction === 'asc' ? 'company__total_parcels_donated_value_rands' : '-company__total_parcels_donated_value_rands')
        // },
        // { field: 'company.last_parcel_donated_date',
        //   title: 'Last Donation Date',
        //   align: 'center',
        //   render: (item) => get(item , ["company", "last_parcel_donated_date"])? <Timestamp value={get(item, ["company", "last_parcel_donated_date"])} format='datetime' /> : "---",
        //   // sort:(direction) => onUpdateListOrdering(direction === 'asc' ? 'company__last_parcel_donated_date' : '-company__last_parcel_donated_date')
        // },
        // { field: 'company.last_parcel_donated_weight_kg',
        //   title: 'Last Donation Weight',
        //   align: 'right',
        //   render: (item) => get(item,  ["company", "last_parcel_donated_weight_kg"]) ? `${round(get(item,  ["company", "last_parcel_donated_weight_kg"]), 2).toLocaleString()} kg`: "---",
        //   // sort:(direction) => onUpdateListOrdering(direction === 'asc' ? 'company__last_parcel_donated_weight_kg' : '-company__last_parcel_donated_weight_kg')
        // },
        // { field: 'company.last_parcel_donated_meals',
        //   title: 'Last Donation Meals',
        //   align: 'right',
        //   render: (item) => get(item,  ["company", "last_parcel_donated_meals"])? round(get(item,  ["company", "last_parcel_donated_meals"]), 0).toLocaleString() : "---",
        //   // sort: (direction) => onUpdateListOrdering(direction === 'asc' ? 'company__last_parcel_donated_meals' : '-company__last_parcel_donated_meals')
        // },
        // { field: 'company.last_parcel_donated_value_rands',
        //   title: 'Last Donation Value',
        //   align: 'right',
        //   render: (item) => get(item,  ["company", "last_parcel_donated_value_rands"])? `R ${round(get(item,  ["company", "last_parcel_donated_value_rands"]), 0).toLocaleString()}` : "---",
        //   // sort: (direction) => onUpdateListOrdering(direction === 'asc' ? 'company__last_parcel_donated_value_rands' : '-company__last_parcel_donated_value_rands')
        // },
        { field: 'company.microsite_enabled',
          title: 'Microsite',
          width: 180,
          render: (item) => getContentStatus(item, 'donor'),
          // sort:(direction) =>  onUpdateListOrdering(direction === 'asc' ? 'company__microsite_enabled' : '-company__microsite_enabled'),
        },
        { field: 'status',
          title: 'Status',
          render: ( item ) => renderStatus(get( item, "status")),
          sort: ( direction ) => onUpdateListOrdering( direction === 'asc' ? 'status' : '-status' ),
          visible: true
        },
    ]

    return (
        <AdminCommonListLayout active_key="donors"
                               breadcrumbs={[{name: 'admin_home'},
                                             {name: 'donors', label: "Donors", url: '/donors'}]}
                               add_label="Add Donor"
                               title="Donors"
                               onAddRow={onAddDonor}
                               onEditRow={onEditDonor}
                               is_loading={is_loading}
                               handleRequestSort={columns}
                               enableAnyFieldFilter={true}
                               columns={columns}
                               item_list={adminDonorList}
                               useColumnsSelector={true}
                               storeTableName={"adminDonorList"}
        >

        </AdminCommonListLayout>
    )
}
