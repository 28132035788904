import React from "react"
import { Typography } from "@material-ui/core"
import InboxOutlinedIcon from '@material-ui/icons/InboxOutlined'
import { useTheme } from '@material-ui/core/styles'


export default function EmptyRows( { message = " No data to display" } ) {
  const theme = useTheme()
  return (
    <div style={ { textAlign: 'center', paddingTop: 30, color: theme.palette.grey[ 400 ] } }>
      <InboxOutlinedIcon style={ { fontSize: 64 } } />
      <Typography variant="h6" align="center">{ message }</Typography>
    </div>
  )
}