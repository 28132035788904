import React, { Component } from 'react'
import { connect } from 'react-redux'
import { forEach, head, get, map, keys, split, size } from 'lodash'
import { adminCoreBusinessList } from './actions/admin_core_business'
import { Separator } from 'components/layout/Separator'
import { Formik, Form, Field } from 'formik'
import * as Yup from 'yup'
import {showSuccess, showError} from 'actions/Error'
import { handleSubmitResult } from 'actions/form'
import AdminCommonFormLayout from '../layout/AdminCommonFormLayout'
import { FormikInputField } from 'components/form/InputField'
import AdminCoreBusinessForm from './form/AdminCoreBusinessForm'
import { validationSchema } from './form/AdminCoreBusinessForm'

class AdminCoreBusiness extends Component {

    componentDidMount() {
        const { dispatch, core_business_id } = this.props
        dispatch(adminCoreBusinessList.ensureObjectLoaded(core_business_id))
    }

    componentDidUpdate(prev_props) {
        const { dispatch, core_business_id } = this.props
        dispatch(adminCoreBusinessList.ensureObjectLoaded(core_business_id))
    }

    onSave = (values, formik_funcs) => {
        const { history, onSubmit, dispatch, core_business_id } = this.props

        const on_ok = function(json) {
            dispatch(adminCoreBusinessList.invalidateList())
            showSuccess("Saved", "Core Business saved")

            if ( ! core_business_id ) {
                history.push(`/admin/core_business/${json.id}`)
            }
        }
        if ( core_business_id ) {
            values.id = core_business_id
            return dispatch(adminCoreBusinessList.saveObject(values)).then((res) => handleSubmitResult({res, formik_funcs, on_ok}))
        } else {
            return dispatch(adminCoreBusinessList.saveNewObject(values)).then((res) => handleSubmitResult({res, formik_funcs, on_ok}))
        }
    }

    getCellValue = (header_key, item, index) => {
        switch( header_key ) {
            default:
                return undefined
        }
    }

    render() {
        const { initial_values, is_loading, is_busy, core_business, core_business_id } = this.props
        const that = this
        const title = `${get(core_business, "name", 'New core business')}`

        return (
            <AdminCommonFormLayout breadcrumbs={[{name: 'admin_home'},

                                                 {name: 'core_businesses',
                                                  label: 'Core Businesses',
                                                  url: '/admin/core_businesses'},

                                                 {name: 'core_business',
                                                  label: get(core_business, "name", 'New Core Business'),
                                                  url: `/admin/core_business/${core_business_id}`
                                                 }
                                                ]}
                                   is_busy={is_busy}
                                   is_loading={is_loading}
                                   title={title}
                                   initial_values={initial_values}
                                   validationSchema={validationSchema}
                                   onSave={this.onSave}>

              {
                  ({formik_props}) => <AdminCoreBusinessForm formik_props={formik_props} />
              }
            </AdminCommonFormLayout>
        )
    }

}

function mapStateToProps(state, props) {
    const core_business_id = get(props, ["match", "params", "core_business_id"], null)
    const core_business = adminCoreBusinessList.getObject(core_business_id)

    return {
        core_business_id,
        core_business,
        is_loading: adminCoreBusinessList.isLoading() || (core_business_id && !get(core_business, "id")),
        is_busy: adminCoreBusinessList.getIsSavingObject(),
        initial_values: core_business
    }
}

export default connect(mapStateToProps)(AdminCoreBusiness)
