import React, { useEffect, useState } from 'react'
import * as Yup from 'yup'
import { get } from 'lodash'
import { useDispatch, useSelector } from 'react-redux'
import { Grid, FormControlLabel, Checkbox } from '@material-ui/core'
import { adminDaysOfWeek } from '../../../actions/admin_dropdown_options'
import { adminMealtimeList } from '../actions/admin_mealtime'
import { adminFeedingFrequencyList } from '../actions/admin_feeding_frequency'
import { adminBeneficiaryList } from '../../beneficiaries/actions/admin_beneficiary'
import { FormikAutoCompleteDropdown } from 'components/form/AutoCompleteDropdown'
import { FormikDropdownField } from 'components/form/Dropdown'
import { Field } from 'formik'
import { getOrdinalSuffix } from 'utils/ordinalSuffix'

export const validationSchema = Yup.object().shape( {
  'company': Yup.string().required( "Company is required" ),
  'meal_name': Yup.string().required( "Meal is required" ),
  'time': Yup.string().required( "Time is required" ),
  'number_of_recipients': Yup.string().required( "Number of recipients is required" ),
} )

export const AdminFeedingScheduleForm = ( { formik_props, company_id, in_modal } ) => {

  const dispatch = useDispatch()
  const beneficiaries = useSelector( () => adminBeneficiaryList.getAsOptions() )
  const mealtime_options = useSelector( () => adminMealtimeList.getAsOptions() )
  const frequency_options = useSelector( () => adminFeedingFrequencyList.getAsOptions() )
  const daysOfWeekOptions = useSelector( () => adminDaysOfWeek.getAsOptions() )
  const daysOfMonthOptions = Array.from( { length: 31 }, ( _, i ) => i + 1 )

  const [ frequencyOptions, setFrequencyOptions ] = useState( frequency_options )

  useEffect( () => {
    // Fetch the options and store them in state.
    const options = adminFeedingFrequencyList.getAsOptions()
    setFrequencyOptions( options )
  }, [] )

  useEffect( () => {
    // Find the selected option object from the options array.
    const selectedOption = frequencyOptions.find( option => option.value === formik_props.values.feeding_frequency )

    // Extract the label from the selected option.
    const selectedLabel = selectedOption ? selectedOption.label.toLowerCase() : ''

    // Determine the selected option based on the label.
    if ( selectedLabel.includes( 'daily' ) ) {
      setSelectedOption( 'daysOfWeek' )
      setDaysOfMonth( [] )
    } else if ( selectedLabel.includes( 'weekly' ) ) {
      setSelectedOption( 'daysOfWeek' )
      setDaysOfMonth( [] )
    } else if ( selectedLabel.includes( 'monthly' ) ) {
      setSelectedOption( 'daysOfMonth' )
      setDaysOfWeek( [] )
    } else {
      setSelectedOption( 'daysOfMonth' )
    }
  }, [ formik_props.values.feeding_frequency, frequencyOptions ] )

  // fields = ('id', 'company_id', 'company_name', 'feeding_frequency', 'mealtime', 'days_of_week', 'days_of_month')

  const [ daysOfWeek, setDaysOfWeek ] = useState( [] )
  const [ daysOfMonth, setDaysOfMonth ] = useState( [] )
  const [ selectedOption, setSelectedOption ] = useState( 'daysOfWeek' )

  useEffect( () => {
    dispatch( adminMealtimeList.fetchListIfNeeded() )
    dispatch( adminFeedingFrequencyList.fetchListIfNeeded() )
    dispatch( adminBeneficiaryList.fetchListIfNeeded() )
    dispatch( adminDaysOfWeek.fetchListIfNeeded() )
  }, [] )

  const handleCheckboxChange = ( event, setState, value, fieldName ) => {
    const newValues = [ ...new Set( [ ...( event.target.checked ? [ ...value, event.target.name ] : value.filter( v => v !== event.target.name ) ) ] ) ]
    setState( newValues )
    formik_props.setFieldValue( fieldName, newValues )
  }

  // const handleRadioChange = (event) => {
  //   setSelectedOption(event.target.value);
  //   // Reset the states when switch between daysOfWeek and daysOfMonth
  //   if (event.target.value === 'daysOfWeek') {
  //     //  Reset daysOfMonth
  //     setDaysOfMonth([]);
  //   } else if (event.target.value === 'daysOfMonth') {
  //     //  Reset daysOfWeek
  //     setDaysOfWeek([])
  //   }
  // }

  const renderDaysOfWeek = () => {
    return (
      <fieldset>
        <Grid container spacing={ 2 }>
          { daysOfWeekOptions.map( ( day, index ) => (
            <Grid item key={ index }>
              <FormControlLabel
                control={
                  <Checkbox
                    name={ get( day, "value" ) }
                    onChange={ ( e ) => handleCheckboxChange( e, setDaysOfWeek, daysOfWeek, 'days_of_week' ) }
                  />
                }
                label={ get( day, "label" ) }
              />
            </Grid>
          ) ) }
        </Grid>
      </fieldset>
    )
  }

  const renderDaysOfMonth = () => {
    return (
      <fieldset>
        <Grid container spacing={ 2 }>
          { daysOfMonthOptions.map( ( day, index ) => (
            <Grid item xs={ 12 } lg={ in_modal ? 6 : 3 } key={ index }>
              <FormControlLabel
                control={
                  <Checkbox
                    name={ day }
                    onChange={ ( e ) => handleCheckboxChange( e, setDaysOfMonth, daysOfMonth, 'days_of_month' ) }
                  />
                }
                label={ `${ getOrdinalSuffix( day ) } day of the month` }
              />
            </Grid>
          ) ) }
        </Grid>
      </fieldset>
    )
  }

  return (
    <Grid container spacing={ 2 }>
      <Grid item xs={ 12 }>
        { !company_id &&
          <FormikAutoCompleteDropdown
            item_list={ adminBeneficiaryList }
            name="company"
            label="Beneficiary"
            formik_props={ formik_props }
          />
        }
        <FormikDropdownField
          name="mealtime"
          formik_props={ formik_props }
          options={ mealtime_options }
          label="Mealtime"
        />
        <FormikDropdownField
          name="feeding_frequency"
          formik_props={ formik_props }
          options={ frequencyOptions }
          label="Frequency"
        />
        {/* <FormControl component="fieldset">
          <RadioGroup
            aria-label="days"
            name="days"
            value={selectedOption} // this state should hold the current selected value
            onChange={handleRadioChange} // this function should handle the radio value change
          >
            <FormControlLabel value="daysOfWeek" control={<Radio color="primary" />} label="Days of Week" />
            
            <FormControlLabel value="daysOfMonth" control={<Radio color="primary" />} label="Days of Month" />
            
          </RadioGroup>
        </FormControl> */}
        <Field type="hidden" name="days_of_week" />
        <Field type="hidden" name="days_of_month" />
        { selectedOption === 'daysOfWeek' && formik_props.values.feeding_frequency && renderDaysOfWeek() }
        { selectedOption === 'daysOfMonth' && formik_props.values.feeding_frequency && renderDaysOfMonth() }
      </Grid>
    </Grid>
  )
}

