import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useParams } from 'react-router-dom'
import { get, filter } from 'lodash'
import { adminChecklistList } from './actions/admin_checklist'
import {showSuccess, showError} from 'actions/Error'
import { handleSubmitResult } from 'actions/form'
import AdminMainLayout from '../layout/AdminMainLayout'
import AdminChecklistForm from './form/AdminChecklistForm'
import { validationSchema } from './form/AdminChecklistForm'
import { FormikGeneralFormErrors } from 'components/form/GeneralFormErrors'
import { Formik, Form } from 'formik'
import FormCard from 'components/layout/FormCard'
import { SaveButton } from 'components/form/Buttons'
import AdminChecklistQuestions from './AdminChecklistQuestions'
import AdminChecklistHistory from './AdminChecklistHistory'
import { Card } from 'components/layout/Card'
import { Grid, Typography } from '@material-ui/core'

export default function AdminChecklist() {
    const dispatch = useDispatch()
    const history = useHistory()
    const { checklist_id } = useParams()
    const checklist = useSelector(() => adminChecklistList.getObject(checklist_id))
    const checklist_name = get(checklist, ["name"])
    const is_loading = useSelector(() => adminChecklistList.isLoading())  || (checklist_id && !get(checklist, "id"))
    const is_busy = useSelector(() => adminChecklistList.getIsSavingObject())
    const initial_values = checklist
    const title = `${get(checklist, "name", 'New checklist template')}`
    const justify = checklist_id ? '' : 'center'

    useEffect(() => {
        dispatch(adminChecklistList.ensureObjectLoaded(checklist_id))
    },[])

    const onSave = (values, formik_funcs) => {
        const on_ok = function(json) {
            dispatch(adminChecklistList.invalidateList())
            showSuccess("Saved", "Checklist saved")

            if ( ! checklist_id ) {
                history.push(`/admin/checklists`)
            }
        }
        if ( checklist_id ) {
            values.id = checklist_id
            return dispatch(adminChecklistList.saveObject(values)).then((res) => handleSubmitResult({res, formik_funcs, on_ok}))
        } else {
            return dispatch(adminChecklistList.saveNewObject(values)).then((res) => handleSubmitResult({res, formik_funcs, on_ok}))
        }
    }

    const getCellValue = (header_key, item, index) => {
        switch( header_key ) {
            default:
                return undefined
        }
    }

    return (
        <AdminMainLayout
            breadcrumbs={[
                {name: 'admin_home'},
                {name: 'checklists', label: 'Checklists', url: '/admin/checklists'},
                {name: 'checklists',  label: get(checklist, "name", 'New Checklist Template'), url: `/admin/checklist/${checklist_id}`}
            ]}
            is_busy={is_busy}
            is_loading={is_loading}
            title={title}
        >
            <Formik
                initialValues={initial_values}
                onSubmit={onSave}
                enableReinitialize={true}
                validationSchema={validationSchema}
            >
                { formik_props => {
                    const { values } = formik_props
                    return (
                        <Grid container spacing={2} justify={justify}>

                            <Grid item xs={6} lg={6}>
                                <Form>
                                    <FormCard variant="outlined">
                                        <AdminChecklistForm
                                            formik_props={formik_props}
                                            sampleValues={checklist}
                                        />
                                    </FormCard>
                                </Form>
                            </Grid>

                            { checklist_id &&

                                <Grid item xs={6} lg={6}>
                                    <Card
                                        title="Questions"
                                        content={
                                            <AdminChecklistQuestions
                                                checklist_id={checklist_id}
                                                checklist_name={checklist_name}
                                            />
                                        }
                                    />
                                </Grid>

                            }
                        </Grid>
                    )
                }}
            </Formik>

            {/*
            { checklist_id &&
                <Card
                    title="History"
                    content={<AdminChecklistHistory checklist_id={checklist_id} />}
                />
            }
            */}

      </AdminMainLayout>
    )
}
