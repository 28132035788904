import { ItemList } from 'orm'
import { EnhancedFilter } from 'admin/actions/enhanced_filter'

class DonorParcelProductList extends ItemList {
    getEntityKey() {
        return "admin/donor_parcel_product"
    }

    getEnhancedFilter() {
        if ( ! this.enhanced_filter ) {
            this.enhanced_filter = new EnhancedFilter(this)
        }
        return this.enhanced_filter
    }
}

export const adminDonorParcelProductList = new DonorParcelProductList("admin_donor_parcel_product__default")
export const adminDonorParcelProductForCartList = new DonorParcelProductList("admin_donor_parcel_product_for_cart__default")
