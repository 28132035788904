import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Trans, Translation } from 'react-i18next'
import { withRouter } from 'react-router-dom'
import { get } from 'lodash'
import NewPasswordForm from './NewPasswordForm'
import {showSuccess, showError} from 'actions/Error'
import { setPassword, loggedInUser } from 'actions/auth'
import Alert from '@material-ui/lab/Alert'

class Login extends Component {

    componentDidMount() {
        const { user, history, next } = this.props
        if (user.has_usable_password) {
            history.push(next || "/")
        }
    }

    onSave = (values) => {
        const { dispatch, history } = this.props
        const on_done = function() {
            showSuccess("Updated", "Password set")
            window.location = "/"
        }
        if (values.password !== values.confirm_password) {
            window.alert("Passwords do not match")
            return null
        } else {
            return dispatch(setPassword(values, on_done))
        }
    }

    render() {
        return (
          <div>
            <Alert severity="info"><Trans>You don't have a valid password, please choose one now</Trans>.</Alert>
            <NewPasswordForm onSave={ this.onSave } />
          </div>
        )
    }
}
function mapStateToProps(state, props) {
    const user = loggedInUser()
    return {
        user
    }
}
export default withRouter(connect(mapStateToProps)(Login))
