import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { get } from 'lodash'
import AdminCommonListLayout from '../layout/AdminCommonListLayout'
import { adminKpiDashboardCategoryList } from './actions/admin_kpi_dashboard_category'
import { ShortId } from 'components/ShortId'

export default function AdminKpiDashboardCategories({}) {
    const dispatch = useDispatch()
    const history = useHistory()
    const dashboards = useSelector(() => adminKpiDashboardCategoryList.getVisibleObjects())
    const is_loading = useSelector(() => adminKpiDashboardCategoryList.isLoading())

    useEffect(() => {
        dispatch(adminKpiDashboardCategoryList.fetchListIfNeeded())
    },[])

    const onAddDashboardCategory = () => {
        history.push('/admin/kpis/dashboard_category')
    }

    const onEditDashboardCategory = (dashboard_category_id) => {
        history.push({
            pathname: '/admin/kpis/dashboard_category/' + dashboard_category_id
        })
    }

    const onUpdateListOrdering = (field) => {
        dispatch(adminKpiDashboardCategoryList.updateListOrdering(field))
    }

    const columns = [
        {
            field: 'id',
            title: 'ID',
            width: 100, 
            render: (item) => <a onClick={() => onEditDashboard(get(item, "id"))}><ShortId value={get(item, "id")} /></a>,
        },
        {
            field: 'name',
            title: 'Name',
            sort: (direction) => onUpdateListOrdering(direction === 'asc' ? 'name' : '-name')
        }
    ]

    return(
        <AdminCommonListLayout
            active_key="admin_kpi_dashboard_categories"
            breadcrumbs={[
                {name: 'admin_home'},
                {name: 'dashboard_categories', label: "Dashboard Categories", url: '/admin/kpis/dashboard_categories'}
            ]}
            add_label="New Dashboard Category"
            title="KPI Dashboard Categories"
            onAddRow={onAddDashboardCategory}
            onEditRow={onEditDashboardCategory}
            is_loading={is_loading}
            columns={columns}
            item_list={adminKpiDashboardCategoryList}
        />
    )

}
