import { useEffect } from 'react'
import * as Yup from 'yup'
import { useDispatch, useSelector } from 'react-redux'
import { FormikInputField } from 'components/form/InputField'
import { FormikTextareaField } from 'components/form/TextareaField'
import { FormikDropdownField } from 'components/form/Dropdown'
import { Separator } from 'components/layout/Separator'
import { FormikCheckboxField } from 'components/form/CheckboxField'
import { adminNutritionFoodCategories } from 'admin/actions/admin_dropdown_options'
import { Grid } from "@material-ui/core"


export const validationSchema = Yup.object().shape({
  'name': Yup.string().required("Required"),
})

export const AdminFoodItemForm = ({ formik_props }) => {
  const dispatch = useDispatch()
  const category_options = useSelector(() => adminNutritionFoodCategories.getAsOptions())

  useEffect(() => {
    dispatch(adminNutritionFoodCategories.fetchListIfNeeded())
  }, [])

  return (
    <>
      <FormikInputField
        name="name"
        label="Name"
        formik_props={formik_props}
      />
      <FormikInputField
        name="wafct_code"
        label="WAFCT Code"
        formik_props={formik_props}
      />
      <FormikDropdownField
        name="category"
        label="Category"
        options={category_options}
        formik_props={formik_props}
      />
      <FormikCheckboxField
        name="contains_fruit"
        label="Contains Fruit?"
        formik_props={formik_props}
      />
      {formik_props.values.contains_fruit &&
        <>
          <br />
          <FormikInputField
            name="fruit_percentage"
            label="% Fruit"
            style={{ width: 130 }}
            formik_props={formik_props}
          />
          <br />
        </>
      }
      <FormikCheckboxField
        name="contains_vegetables"
        label="Contains Vegetables?"
        formik_props={formik_props}
      />
      {formik_props.values.contains_vegetables &&
        <>
          <br />
          <FormikInputField
            name="vegetables_percentage"
            label="% Vegetables"
            style={{ width: 130 }}
            formik_props={formik_props}
          />
          <br />
        </>
      }
      <FormikCheckboxField
        name="contains_nuts"
        label="Contains Nuts?"
        formik_props={formik_props}
      />
      {formik_props.values.contains_nuts &&
        <>
          <br />
          <FormikInputField
            name="nuts_percentage"
            label="% Nuts"
            style={{ width: 130 }}
            formik_props={formik_props}
          />
          <br />
        </>
      }
      <FormikInputField
        name="scientific_name"
        label="Scientific Name"
        formik_props={formik_props}
      />
      <FormikInputField
        name="source"
        label="Source"
        formik_props={formik_props}
      />
      <FormikTextareaField
        name="description"
        label="Description"
        rows={5}
        formik_props={formik_props}
      />

    </>
  )
}
