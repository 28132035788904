import { ItemList } from 'orm'
import { map } from 'lodash'

class WarehouseList extends ItemList {
    getEntityKey() {
        return "admin/warehouse"
    }

    getAsOptions() {
        const items = this.getVisibleObjects()
        return map( items, function( item ) {
            return {
                label: item.name,
                value: item.id
            }
        } )
    }
}

export const adminWarehouseList = new WarehouseList( "admin_warehouse__default" )
export const adminWarehouseAutoCompleteList = new WarehouseList( "admin_warehouse_auto_complete__default" )
