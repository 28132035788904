import React, { Component } from 'react';
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { Route, Switch } from 'react-router-dom'
// import { Trans, Translation } from 'react-i18next'
import { includes, get, filter, head } from 'lodash'
// import WrappingBusyMask from './WrappingBusyMask'
import NewPassword from './NewPassword'
import Login from './Login'
import { Modal } from 'components/layout/Modal'
import AdminRouter from 'admin/components/routes/AdminRouter'
import BeneficiaryRouter from 'portals/beneficiary/components/routes/BeneficiaryRouter'
import DonorRouter from 'portals/donor/components/routes/DonorRouter'
import MarketingRouter from 'portals/marketing/components/routes/MarketingRouter'
import WidgetsRouter from 'portals/widgets/components/routes/WidgetsRouter'
// import MealsRouter from 'portals/synthesis/meals/components/routes/MealsRouter'
// import CampaignsRouter from 'portals/campaigns/components/routes/CampaignsRouter'
// import Home from './Home'
// import UnionAgainstHunger from 'components/pages/UnionAgainstHunger'
import LoggedInHome from './LoggedInHome'
import ProfileEdit from './ProfileEdit'
import ForgotPassword from './ForgotPassword'
import Register from './Register'
import { Typography } from '@material-ui/core'
// import { Link } from 'react-router-dom'
import { isLoggedIn, auto_login } from 'actions/auth'
import { userList } from 'actions/user'
import { globalSettingsList } from 'actions/settings'
import { getGeneralApiError, hasGeneralApiError } from 'orm/orm_general_actions'
import MoodBadIcon from '@material-ui/icons/MoodBad'

class AuthRouter extends Component {

    constructor(props) {
        super(props)
        this.state = {
            auto_logging_in: false,
            show_getting_ready: false
        }
    }

    componentDidMount() {
        const { dispatch, is_logged_in } = this.props
        if ( is_logged_in ) {
            dispatch(globalSettingsList.ensureGlobalSettingsLoaded())
        }
        this.check_for_auto_login()
        this.checkRouting()
        dispatch(userList.ensureUserLoaded())
    }

    componentDidUpdate(prevProps) {
        const { dispatch, is_logged_in, history, is_staff, is_superuser } = this.props
        if (prevProps.is_logged_in && !is_logged_in) {
            history.push('/')
        } else if ( (is_staff || is_superuser) && window.location.pathname === "/" ) {
            history.push('/admin/dashboard')
        }
        this.check_for_auto_login()
        this.checkRouting()
        dispatch(userList.ensureUserLoaded())
    }

    check_for_auto_login() {
        const { history, dispatch, is_logged_in } = this.props
        const { auto_logging_in } = this.state
        const that = this
        const params = new window.URLSearchParams(window.location.search);
        const user_id = params.has("u") && params.get("u")
        const otp = params.has("o") && params.get("o")

        const on_done = (res) => {
            that.setState({auto_logging_in: false})
            if ( res.errors ) {
                that.setState({auto_login_error_message: res.errors})
            }
            params.delete("u")
            params.delete("o")
            history.push(window.location.pathname + "?" + params.toString())
        }

        if ( user_id && otp && !auto_logging_in ) {
            this.setState({auto_logging_in: true})
            dispatch(auto_login(user_id, otp)).then(on_done)
        }
    }

    checkRouting() {
        const { history, is_superuser, is_staff } = this.props
        if ( (is_staff || is_superuser) &&
        ! window.location.pathname.startsWith("/donate") &&
        ! window.location.pathname.startsWith("/payment") &&
        ! window.location.pathname.startsWith("/marketing") &&
        ! window.location.pathname.startsWith("/impact") &&
        ! window.location.pathname.startsWith("/beneficiary") &&
        ! window.location.pathname.startsWith("/marketing") &&
        ! window.location.pathname.startsWith("/donor") &&
        ! window.location.pathname.startsWith("/admin") &&
        ! window.location.pathname.startsWith("/profile") ) {

            history.push('/admin/dashboard')
        }
    }

    renderDefaultRoutes = () => {
        const { is_prod } = this.props
        return (
            <Switch>
                <Route path="/marketing" exact={ false } component={ MarketingRouter } />
                <Route path="/impact" exact={ false } component={ MarketingRouter } />
                <Route path="/donate" exact={ false } component={ WidgetsRouter } />
                <Route path="/payment" exact={ false } component={ WidgetsRouter } />
                <Route path="/forgot_password" exact={ true } component={ ForgotPassword } />
            </Switch>
        )
    }

    renderLoggedInRoutes = () => {
        return (
            <Switch>
                <Route path="/admin" exact={ false } component={ AdminRouter } />
                <Route path="/beneficiary" exact={ false } component={ BeneficiaryRouter } />
                <Route path="/donor" exact={ false } component={ DonorRouter } />
                <Route path="/profile" exact={ true } component={ ProfileEdit } />
                <Route path="/" exact={ true } component={ LoggedInHome } />
            </Switch>
        )
    }

    renderNotLoggedInRoutes = () => {
        return (
            <Switch>
                <Route path="/admin" exact={ false } component={ Login } />
                <Route path="/donor" exact={ false } component={ Login } />
                <Route path="/beneficiary" exact={ false } component={ Login } />
                <Route path="/login" exact={ true } component={ Login } />
                <Route path="/register" exact={ true } component={ Register } />
                <Route path="/" exact={ true } component={ Login } />
            </Switch>
        )
    }

    renderNewPassword = () => {
        return (
            <Modal title="Set new password" noPadding><NewPassword /></Modal>
        )
    }

    renderGettingReady = () => {
        const { show_getting_ready } = this.state
        return (
            <>
                { show_getting_ready &&
                    <Modal onClose={() => this.setState({show_getting_ready: false})} title="Getting Ready">
                        Just a sec while we get things ready for you!
                    </Modal>
                }
            </>
        )
    }

    renderGeneralApiError = () => {
        return (
            <Modal>
                <div style={{textAlign: 'center'}}>
                    <MoodBadIcon fontSize="large" />

                    <Typography variant="h5">
                        Check your internet connection and try again
                    </Typography>
                    <Typography style={{marginTop: 15, marginBottom :15}}>We couldn't connect to the server.</Typography>
                    <p style={{marginBottom: 15}}><a onClick={() => window.location.reload()}>Refresh page?</a></p>
                </div>
            </Modal>
        )
    }

    render() {
        const { is_logged_in, is_prod, user, has_usable_password, general_api_error } = this.props

        return (
            <>

            { is_logged_in && get(user, "id") && ! has_usable_password && this.renderNewPassword() }
            { is_logged_in && get(user, "id") && userList.isLoading() && this.renderGettingReady()}
            { this.renderDefaultRoutes() }
            { is_logged_in && get(user, "id") && this.checkActiveCompany}
            { !is_logged_in && this.renderNotLoggedInRoutes() }
            { is_logged_in && this.renderLoggedInRoutes() }
            { general_api_error && this.renderGeneralApiError() }

            </>
    )

}
}

function mapStateToProps(state) {
    const is_logged_in = isLoggedIn()
    let is_staff = false
    let is_superuser = false
    const user = userList.getUser()
    if (is_logged_in) {
        is_staff = user && user.is_staff
        is_superuser = user && user.is_superuser
    }

    const general_api_error = getGeneralApiError(state)

    const is_prod = !includes(window.location.host, "localhost") && !includes(window.location.host, "staging")
    const has_usable_password = is_logged_in && user && user.has_usable_password
    return {
        user,
        is_logged_in,
        is_staff,
        is_superuser,
        is_prod,
        has_usable_password,
        general_api_error
    }
}

export default withRouter(connect(mapStateToProps)(AuthRouter))
