import { ItemList } from 'orm'
import { values, get, map, head, filter, startCase } from 'lodash'
import { download } from 'actions/lib'
import { formatTime } from 'utils/formatDateTime'
 
class MealtimeList extends ItemList {
    getEntityKey() {
        return "admin/mealtime"
    }

    getAsOptions() {
        const items = this.getVisibleObjects()
        return map(items, function(item) { return {label: `${startCase(item.meal_name)} (${formatTime(item.time)})`, value: item.id}})
    }

    getForCompany({company_id}) {
        // Assumes the matching beneficiary is already loaded
        return head(filter(values(this.getAllObjects()), (x) => get(x, ["company", "id"]) === company_id))
    }

}

export const adminMealtimeList = new MealtimeList("admin_mealtime__default")