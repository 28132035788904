import React, { useEffect, useState } from "react"
import { Button, CircularProgress, makeStyles, Fab, Tooltip } from "@material-ui/core"
import AddIcon from '@material-ui/icons/Add'
import PublishIcon from '@material-ui/icons/Publish'

const useStyles = makeStyles((theme) => ({
    progress: {
        color: 'white',
        margin: '0px 9px'
    },
    default: {
        backgroundColor: '#3949ab',
        color: 'white',
        '&:hover': {
            backgroundColor: '#5c6bc0'
        }
    },
    yellow: {
        backgroundColor: '#ffca28',
        color: 'black',
        '&:hover': {
            backgroundColor: '#ffc107'
        }
    }
}))

const CommonButton = ({onClick, label, newLabel, icon, as_icon, with_icon, color, buttonGroup, position, size, variant, margin}) => {
    const classes = useStyles()
    size = size ? size : "large"
    let borderRadius = 4
    let borderRight
    let button_style = classes.default
    if(icon && as_icon) { label = icon }
    if(icon && with_icon) { label = (
      <>
        {icon} {label}
      </>
    ) }
    if(buttonGroup) {
        switch(position) {
            case 'center':
                borderRadius = 0
                break
            case 'left':
                borderRadius = '4px 0 0 4px'
                borderRight = '1px solid #ffc107'
                break
            case 'right':
                borderRadius = '0 4px 4px 0'
                break
            default:
                borderRadius = 4
    }
  }

  return (
    <Button
      onClick={onClick}
      size={size}
      variant={variant ? variant : "contained"}
      disableElevation
      color={color}
      style={{marginBottom: 10, borderRadius: borderRadius, borderRight: borderRight, margin: margin}}
    >
      { label }
    </Button>
  )
}

export const AddButton = ({onClick, label, type, variant, color, as_icon, with_icon, buttonGroup, position, align, size, use_plus, margin}) => {
  const classes = useStyles()
  label = label ? label : 'New'
  let icon = <AddIcon />

  if(type === 'import') {
    label = label ? label : 'Import'
    icon = <PublishIcon />
  }

  if(use_plus) {
    return(
      <Fab
        color={color}
        aria-label="add"
        size={'small'}
        onClick={onClick}
        elevation={0}
        style={{margin: '10px 0 0 15px'}}
      >
        <AddIcon />
      </Fab>
    )

  } 
  else {
    return (
      <CommonButton
        onClick={onClick}
        color={color}
        as_icon={as_icon}
        with_icon={with_icon}
        label={label}
        icon={icon}
        variant={variant}
        size={size}
        margin={margin}
      />
    )
  }

}

export const SaveButton = ({onClick, label, isSubmitting, float}) => {
    const classes = useStyles()

    return(
        <Button
          onClick={onClick}
          type="submit"
          size="large"
          variant="contained"
          color="primary"
          disabled={isSubmitting}
          disableElevation
          style={{marginBottom: 10, float: float, textAlign: 'center'}}
        >
            { isSubmitting ?
                <CircularProgress className={classes.progress} size={24} />
                :
                <>{ label ? label : "Save"}</>
            }
        </Button>

  )
}
