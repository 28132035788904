import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { capitalize, get, replace } from 'lodash'
import { adminEditableEmailList } from './actions/admin_editable_email'
import AdminCommonListLayout from 'admin/components/layout/AdminCommonListLayout'
import { Button, Typography } from '@material-ui/core'

export default function AdminEditableEmails({...props}) {
    const dispatch = useDispatch()
    const history = useHistory()

    const is_loading = useSelector(() => adminEditableEmailList.isLoading())
    const editable_emails = useSelector(() => adminEditableEmailList.getVisibleObjects())

    useEffect(() => {
        dispatch(adminEditableEmailList.fetchListIfNeeded())
    })

    const onEditEditableEmail = (editable_email_id) => {
        history.push({
            pathname: '/admin/editable_email/'+ editable_email_id
        })
    }

    const filterEditableEmails = (filter_values) => {
        dispatch(adminEditableEmailList.updateListFilter(filter_values))
    }

    const columns = [
      { field: 'name', title: 'Name', render: ( item ) => <Typography>{ capitalize( replace( replace( get( item, "name" ), /__/g, " - " ), /_/g, " " ) )  }</Typography> },
      { field: 'description', title: 'Description',},
    ]

    const renderAdditionalActions = () => {
      return(
        <Button
          onClick={() => history.push("/admin/editable_email_custom_parameters")}
          variant="contained"
          color="primary"
          size="large"
        >
          Custom Parameters
        </Button>
      )
    }

    return (
        <AdminCommonListLayout active_key="emails"
                               breadcrumbs={[{name: 'admin_home'},
                                             {name: 'email', label: "Emails", url: '/editable_emails'}]}
                               onEditRow={onEditEditableEmail}
                               title="Editable Emails"
                               is_loading={is_loading}
                               columns={columns}
                               item_list={adminEditableEmailList}
                               renderAdditionalActions={renderAdditionalActions}
        />
    )
}
