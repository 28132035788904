import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { get } from 'lodash'
import AdminCommonListLayout from '../layout/AdminCommonListLayout'
import { adminNutritionCardList } from './actions/admin_nutrition_card'
import { ShortId } from 'components/ShortId'
import { FoodItemField, NutrientField } from './NutritionAttributes'

export default function AdminNutritionCards() {

  const dispatch = useDispatch()
  const history = useHistory()

  const nutrition_cards = useSelector(() => adminNutritionCardList.getVisibleObjects())
  const is_loading = useSelector(() => adminNutritionCardList.isLoading())
  const filter = useSelector(() => adminNutritionCardList.getFilter())

  useEffect(() => {
    dispatch(adminNutritionCardList.fetchListIfNeeded())
  },[])

  const onEditNutritionCard = (nutrition_card_id) => {
    history.push({
      pathname: '/admin/nutrition_card/'+nutrition_card_id
    })
  }

  const onAddNutritionCard = () => {
    history.push({
      pathname: '/admin/nutrition_card/'
    })
  }

  const onUpdateListOrdering = (field) => {
    dispatch(adminNutritionCardList.updateListOrdering(field))
  }

  const columns = [
    { field: 'id',
      title: 'ID',
      render: (item) => <ShortId value={item.id} />,
      width: 100
    },
    { field: 'food_item',
      title: 'Food Item',
      render: (item) => <FoodItemField food_item_id={ get(item, 'food_item')} field="name" />,
      sort:(direction) => onUpdateListOrdering(direction === 'asc' ? 'food_item' : '-food_item')
    },
    { field: 'nutrient',
      title: 'NutritionCard',
      render: (item) => <NutrientField nutrient_id={ get(item, 'nutrient')} field="name" />,
      sort:(direction) => onUpdateListOrdering(direction === 'asc' ? 'nutrient' : '-nutrient')
    },
    { field: 'nutrient_value',
      title: 'Value',
      align: "right",
      render: (item) => <>{ get(item, "nutrient_value") }<NutrientField nutrient_id={ get(item, 'nutrient')} field="unit" /></>,
      sort:(direction) => onUpdateListOrdering(direction === 'asc' ? 'nutrient_value' : '-nutrient_value')
    },
    { field: 'percentage_of_rda',
      title: '% RDA',
      sort:(direction) => onUpdateListOrdering(direction === 'asc' ? 'percentage_of_rda' : '-percentage_of_rda')
    },
  ]

  return (
    <AdminCommonListLayout
      active_key="nutrition_cards"
      breadcrumbs={[
        {name: 'admin_home'},
        {name: 'nutrition_cards', label: "Nutrition Cards", url: '/admin/nutrition_cards'}
      ]}
      add_label="Add Nutrition Card"
      title={"Nutrition Cards"}
      onAddRow={onAddNutritionCard}
      onEditRow={onEditNutritionCard}
      is_loading={is_loading}
      columns={columns}
      item_list={adminNutritionCardList}
      enableAnyFieldFilter={true}
    />
  )
}
