import { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { get } from 'lodash'
import { Typography,Table, TableRow, TableCell, makeStyles } from "@material-ui/core"
import { adminCompanyReportGeneration } from 'admin/components/reports/actions/admin_company_report_generation'
import { SingleFileUploadField } from 'components/form/SingleFileUploadField'
import { FormikCheckboxField } from 'components/form/CheckboxField'
import { Separator } from 'components/layout/Separator'
import { Alert } from "@material-ui/lab"

const useStyles = makeStyles((theme) => ({
    infoBox: {
      border: '1px solid #cccccc',
      borderRadius: 8,
      padding: 15,
      marginBottom: 15
    },
    alertMargin: {
      marginBottom: 10
    },
}))

const OrderSection18A = ({ formik_props, parcel }) => {
    const classes = useStyles()
    const section18AReport = useSelector(() => adminCompanyReportGeneration.getObject(get(parcel, "section18_certificate_report_generation")))

    useEffect(() => {
        if ( parcel.section18_certificate_report_generation ) {
            dispatch(adminCompanyReportGeneration.ensureObjectLoaded(parcel.section18_certificate_report_generation))
        }
    }, [get(parcel, "section18_certificate_report_generation")])

    return (
        <>
            { ! get(parcel, "qualifies_for_section18_certificate") &&
                 <Alert severity="info" style={{marginBottom: 15}}>
                     <Typography style={{fontWeight: 500}}>
                         Section 18A Requirements
                     </Typography>
                     <ol>
                         <li>Status is complete</li>
                         <li>Company invoice has been uploaded</li>
                         <li>Company invoice has been confirmed</li>
                         <li>Parcel's Rand value is > 0</li>
                     </ol>
                </Alert>
            }

            { get(parcel, "qualifies_for_section18_certificate") &&
                <div>
                    <Alert className={classes.alertMargin}>
                        <Typography style={{fontWeight: 500}}>
                            This parcel qualifies for a section18A Tax exemption receipt!
                        </Typography>
                        It will be automatically generated and emailed to the company when you save the order.
                    </Alert>

                    { ! section18AReport &&
                        <Alert severity="info" className={classes.alertMargin}>If Section 18A report generation is enabled for this customer, then the report will be available soon, please wait a few minutes then refresh this page or go to the reports tab.</Alert>
                    }
                    { section18AReport &&
                        <div>
                            <Separator height={15} />
                            <Typography><b>Section 18A Report</b></Typography>
                            <Separator height={5} />
                            <Table>
                                <TableRow>
                                    <TableCell width={220}>
                                        Status:
                                    </TableCell>
                                    <TableCell>
                                        {section18AReport.status == "success" ? <span style={{color: 'green'}}>{section18AReport.status}</span> : <span style={{color: 'red'}}>{section18AReport.status}</span> }
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell width={220}>
                                        Emailed to:
                                    </TableCell>
                                    <TableCell>
                                        {section18AReport.emailed_to}
                                    </TableCell>
                                </TableRow>

                                { section18AReport.report_file_info &&
                                    <TableRow>
                                        <TableCell width={220}>
                                            Download
                                        </TableCell>
                                        <TableCell>
                                            <a href={section18AReport.report_file_info.original_download_url} download={section18AReport.report_file_info.original_filename}>
                                               {section18AReport.report_file_info.original_filename}
                                           </a>
                                       </TableCell>
                                   </TableRow>
                               }
                           </Table>
                       </div>
                   }
               </div>
           }
           <SingleFileUploadField
               name="company_invoice_file_info"
               dropzonePlaceholder = "Upload the company invoice for this order"
               buttonPlaceholder = "company invoice for this order"
               formik_props={formik_props}
          />
          <Separator height={15} />
          { get(formik_props.values, ["company_invoice_file_info", "id"]) &&
              <>
                  { formik_props.values.company_invoice_is_valid &&
                    <Alert severity="success" className={classes.alertMargin}>
                        It has been confirmed that the company invoice is correct and matches the products in this order.
                    </Alert>
                  }
                  <FormikCheckboxField
                      name="company_invoice_is_valid"
                      label={formik_props.values.company_invoice_is_valid == true ? "Unconfirm that information is valid?" : "By checking this box I verify that the company invoice is correct and matches the products in this order"}
                      formik_props={formik_props}
                      color="primary"
                  />
              </>
            }
        </>
    )
}

export default OrderSection18A
