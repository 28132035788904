import React from 'react'
import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { get, split } from 'lodash'
import { useDispatch } from 'react-redux'
import { makeStyles, Typography, Tooltip } from "@material-ui/core"
import {showSuccess, showError} from 'actions/Error'
import { handleSubmitResult } from 'actions/form'
import { FormikDropdownField } from 'components/form/Dropdown'
import { FormikGeneralFormErrors } from 'components/form/GeneralFormErrors'
import { Formik, Form, FieldArray, Field } from 'formik'
import { FormikInputField } from 'components/form/InputField'
import { FormikTextareaField } from 'components/form/TextareaField'
import { FormikCheckboxField } from 'components/form/CheckboxField'
import { contactContactRoles } from 'actions/contact/dropdown_options'
//import { SingleFileUploadField } from 'components/form/SingleFileUploadField'
import { FormikFileSetField } from 'components/form/FileSetField'
import { Grid, Switch, Button } from '@material-ui/core'
import { Separator } from 'components/layout/Separator'
import { contactContactList, invalidateAllContactLists } from 'actions/contact/contact'
import Alert from '@material-ui/lab/Alert'

const useStyles = makeStyles((theme) => ({
  contactForm: {
    marginBottom: 20
  }
}))

export default function AdminContactForm({company_id, contact_id, onDone, setEditingContact}) {
    const classes = useStyles()
    const dispatch = useDispatch()
    const contact = contact_id ? useSelector(() => contactContactList.getObject(contact_id)) : {company: company_id}
    const initialValues = contact
    const contact_role_options = contactContactRoles.getAsOptions()

    useEffect(() => {
        async function fetchStaticObjects() {
            dispatch(contactContactRoles.fetchListIfNeeded())
        }
        fetchStaticObjects()
    }, [])

    useEffect(() => {
        dispatch(contactContactList.ensureObjectLoaded(contact_id))
    }, [contact_id])

    const onSave = (values, formik_funcs) => {
        const on_ok = function(json) {
            showSuccess("Saved", "Contact saved")
            dispatch(invalidateAllContactLists())
            setEditingContact(false)
            //onDone()
        }
        if ( contact_id ) {
            values.id = contact_id
            return dispatch(contactContactList.saveObject(values)).then((res) => handleSubmitResult({res, formik_funcs, on_ok}))
        } else {
            return dispatch(contactContactList.saveNewObject(values)).then((res) => handleSubmitResult({res, formik_funcs, on_ok}))
        }
    }

    return (

      <Formik initialValues={initialValues}
              onSubmit={onSave}
              enableReinitialize={true}
      >
        {formik_props => {
            const { values } = formik_props
            const login_enabled_alert = values.can_login ? "success" : "warning"
            const enable_user_login_label = values.can_login ? "User can login. Uncheck to disable login." : "User Login is disabled. Check the box to enable."
            return (
                <Form>
                  <FormikGeneralFormErrors render={(msg) => msg} />

                    {/*Company_id: {company_id}<br />
                  Contact_id: {contact_id}<br />*/}




                    <FormikDropdownField name={`role`}
                                         formik_props={formik_props}
                                         options={contact_role_options}
                                         label="Contact role"
                                         helperText={"Please select this contact's role"}
                    />

                  <Alert severity={login_enabled_alert} style={{margin: '10px 0'}}>
                      <FormikCheckboxField name={`can_login`}
                                       formik_props={formik_props}
                                       color="primary"
                                       label={ enable_user_login_label }
                                       tooltip={"An email will be sent with password setup instructions"}
                                       />
                    </Alert>

                    <FormikInputField name={`first_name`}
                                      type="text"
                                      label="First name"
                                      formik_props={formik_props}
                    />

                    <FormikInputField name={`last_name`}
                                      type="text"
                                      label="Last name"
                                      placeholder="Last name"
                                      formik_props={formik_props}
                    />

                    <FormikInputField name={`email`}
                                      label="Email"
                                      placeholder="Email"
                                      formik_props={formik_props}
                    />

                    <FormikInputField name={`Phone`}
                                      type="text"
                                      label="Phone"
                                      placeholder="Phone"
                                      formik_props={formik_props}
                    />

                    <FormikInputField name={`mobile`}
                                      type="text"
                                      label="Mobile"
                                      placeholder="Mobile"
                                      formik_props={formik_props}
                    />

                    <FormikTextareaField name={`notes`}
                                         label="Notes"
                                         placeHolder="Notes"
                                         formik_props={formik_props}
                                         rows={5}
                    />

                    <Separator height={15} />

                    <FormikFileSetField max_num_files={1}
                                         label="profile picture"
                                         name={`profile_images`}
                                         formik_props={formik_props} />

                    <Separator height={25} />

                   <Button
                     variant="contained"
                     type="submit"
                     size="large"
                     color="primary">
                     Save Contact
                   </Button>

                   <Separator height={15} />


                </Form>
            )
        }}

      </Formik>

    )
}
