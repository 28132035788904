import { useEffect } from 'react'
import * as Yup from 'yup'
import { useSelector, useDispatch } from 'react-redux'
import { FormikInputField } from 'components/form/InputField'
import { adminUserList } from 'admin/components/users/actions/admin_user'
import { Grid, IconButton, Button, Tooltip } from '@material-ui/core'
import { FormikDropdownField } from 'components/form/Dropdown'
import { adminNotificationModelList } from '../actions/admin_notification_model'
import { FormikAutoCompleteDropdown } from 'components/form/AutoCompleteDropdown'
import { Separator } from 'components/layout/Separator'
import { adminSeverities } from 'admin/actions/admin_dropdown_options'

export const validationSchema = Yup.object().shape( {
  'user': Yup.string().required( "User is required" ),
  'message': Yup.string().required( "Message is required" ),
} )

export const AdminNotificationForm = ( { in_drawer, formik_props } ) => {
  const dispatch = useDispatch()
  const severity_options = useSelector( () => adminSeverities.getAsOptions() )

  useEffect(() => {
    dispatch(adminSeverities.fetchListIfNeeded())
  }, [dispatch])
  

  const renderFormFields = () => {
    return (
      <>
        <FormikDropdownField
          name="severity"
          formik_props={ formik_props }
          options={ severity_options }
          label="Severity"
        />
        <Separator height={ 15 } />
        <FormikAutoCompleteDropdown
          name="user"
          formik_props={ formik_props }
          item_list={ adminUserList }
          label="Select user"
        />
        <Separator height={15} />
        <FormikAutoCompleteDropdown
          name="notification_model"
          formik_props={ formik_props }
          item_list={ adminNotificationModelList }
          label="Select content type"
        />
        <FormikInputField
          name="message"
          formik_props={ formik_props }
          label="Message"
          rows={ 3 }
        />
        <FormikInputField
          name="link"
          formik_props={ formik_props }
          label="Enter a link if you have one"
        />
      </>
    )
  }

  return renderFormFields()

}
