import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { adminKpiDashboardCategoryList } from './actions/admin_kpi_dashboard_category'
import { adminKpiList } from './actions/admin_kpi'
import { adminKpiTemplateList } from './actions/admin_kpi_template'
import { adminKpiDashboardList } from './actions/admin_kpi_dashboard'
import { get, isEmpty, size } from 'lodash'
import Loading from 'components/Loading'
import { capitalCamel } from 'components/CapitalCamel'

export const KpiCategoryName = ({ kpi_category_id, kpi_id }) => {
    const dispatch = useDispatch()
    const kpi = useSelector(() => adminKpiList.getObject(kpi_id))
    console.log(kpi)
    kpi_category_id = !isEmpty(kpi) ? get(kpi, "category") : kpi_category_id
    const category = useSelector(() => adminKpiDashboardCategoryList.getObject(kpi_category_id))
    const is_loading_category = useSelector(() => adminKpiDashboardCategoryList.isLoading())
    const is_loading_kpi = useSelector(() => adminKpiList.isLoading())

    useEffect(() => {
        dispatch(adminKpiList.ensureObjectLoaded(kpi_id))
        dispatch(adminKpiDashboardCategoryList.ensureObjectLoaded(kpi_category_id))
    },[])

    useEffect(() => {
        dispatch(adminKpiDashboardCategoryList.ensureObjectLoaded(kpi_category_id))
    },[kpi])

    return get(category, "name", is_loading_category ? "Fetching category name..." : '--')

}

export const KpiTemplateName = ({ kpi_id, kpi_template_id }) => {
    const dispatch = useDispatch()
    const kpi = useSelector(() => adminKpiList.getObject(kpi_id))

    if(!kpi_template_id && !isEmpty(kpi)) {
        kpi_template_id = get(kpi, "kpi_template")
    }
    const kpi_template = useSelector(() => adminKpiTemplateList.getObject(kpi_template_id))
    const is_loading_kpi_template = useSelector(() => adminKpiTemplateList.isLoading())

    useEffect(() => {
        dispatch(adminKpiList.ensureObjectLoaded(kpi_id))
    },[])

    useEffect(() => {
        dispatch(adminKpiTemplateList.ensureObjectLoaded(kpi_template_id))
    },[kpi])

    return capitalCamel(get(kpi_template, "name", is_loading_kpi_template ? "Fetching KPI name..." : '--'))
}

export function KpiDashboardAttributes({ dashboard_id, get_kpi_ids, get_kpi_count, get_name }) {
    const dispatch = useDispatch()
    const dashboard = useSelector(() => adminKpiDashboardList.getObject(dashboard_id))
    const is_loading = useSelector(() => adminKpiDashboardList.isLoading())

    useEffect(() => {
        dispatch(adminKpiDashboardList.ensureObjectLoaded(dashboard_id))
    },[])

    if(get_kpi_count) {
        return size(get(dashboard, "kpis"))
    }
    else {
        return capitalCamel(get(dashboard, "name", is_loading ? "Fetching Dashboard name..." : '--'))
    }
}
