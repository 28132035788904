import React, { useState, useEffect } from 'react'
import { get, map, mapValues, capitalize } from 'lodash'
import { FormikCheckboxField } from 'components/form/CheckboxField'
import { FieldArray } from "formik";
import { Table, TableHead, TableBody, TableRow, TableCell, makeStyles, Typography } from '@material-ui/core'
import { LoadingModal } from 'components/layout/Modal'

const useStyles = makeStyles((theme) => ({
  cellHeading: {
    background: '#efefef',
    padding: 15
  },
  dayText: {
    fontSize: 14,
    fontWeight: 500
  }
}))
export const BeneficiaryMealsFrequencyGrid = ({formik_props}) => {
  const classes = useStyles()
  const days_of_the_week = ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday']
  const [state, setState] = useState({
    meals: [
      {
        name: 'breakfast',
        allDays: false
      },
      {
        name: 'lunch',
        allDays: false
      },
      {
        name: 'dinner',
        allDays: false
      },
    ]
  })
  const [isLoading, setIsLoading] = useState(false)

  const toggleAlldays = (meal) => {
    // loop over the meals list and find the provided meals.
    setIsLoading(true)
    let updatedMealsList = map(state.meals, function(item) {
        if(item.name == meal) {
          return {...item, allDays: !item.allDays}; //gets everything that was already in item, and updates "allDays"
        }
        return item // else return unmodified item
      })
      setState({meals: updatedMealsList})

      map(state.meals, function(item) {
        if(item.name == meal) {
          map(days_of_the_week, function(day) {
              formik_props.setFieldValue(`meals_frequency.${day}.${meal}`, !item.allDays)
          })
        }
      })
      setIsLoading(false)
  }

  return(
    <>
    { isLoading && <LoadingModal /> }
    <Table size='small'>
      <FieldArray
        name="meals_frequency"
        render={arrayHelpers => (
          <>
        <TableRow>
          <TableCell className={classes.cellHeading}></TableCell>
          {map(state.meals, function(meal) {
            return(
              <TableCell style={{textAlign: 'center'}} className={classes.cellHeading}>
                <Typography className={classes.dayText}>
                  { capitalize(meal.name) } &nbsp;
                  <FormikCheckboxField
                    name={`meals_frequency.${meal.name}.all_days`}
                    color="primary"
                    onClick = {() => toggleAlldays(meal.name)}
                    formik_props={formik_props}
                  />
                </Typography>
              </TableCell>
            )
          })}

        </TableRow>
        {map(days_of_the_week, function(day) {
          return(
            <TableRow>
              <TableCell className={classes.cellHeading} width={120}>
                <Typography className={classes.dayText}>
                  { capitalize(day) }
                </Typography>
              </TableCell>
                  {map(state.meals, function(meal) {
                    return(
                      <TableCell style={{textAlign: 'center', padding: 0}}>
                        <FormikCheckboxField
                          name={`meals_frequency.${day}.${meal.name}`}
                          color="primary"
                          formik_props={formik_props}
                        />
                      </TableCell>
                    )
                  })}


            </TableRow>
          )
        })}
        </>
      )}
    />
    </Table>
    </>
  )

}
