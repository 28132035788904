import { ItemList } from 'orm'
import { map } from 'lodash'

class UOMList extends ItemList {
    getEntityKey() {
        return "admin/uom"
    }
    getAsOptions() {
        const items = this.getVisibleObjects()
        return map(items, function(item) { return { label: item.name, value: item.id }})
    }
}

export const adminUOMList = new UOMList("admin_uom__default")
