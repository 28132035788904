import React, { useRef } from 'react'
import { useDispatch } from 'react-redux'
import { CommonTable } from 'components/CommonTable'
import AdminMainLayout from 'admin/components/layout/AdminMainLayout'
import AdminTableFilter from 'admin/components/common/AdminTableFilter'
import { Paper, Grid, makeStyles, LinearProgress } from '@material-ui/core'
import SearchBar from 'components/layout/SearchBar'
import { AddButton } from 'components/form/Buttons'

const useStyles = makeStyles( ( theme ) => ( {
  root: {
    [ theme.breakpoints.down( 'sm' ) ]: {
      padding: 15
    }
  },
  labelRoot: {
    paddingLeft: 15,
    fontSize: 24,
  },
  labelShinked: {
    marginLeft: 5,
    fontSize: 16
  },
  addLabel: {
    marginRight: 10
  },
  actions: {
    [ theme.breakpoints.down( 'sm' ) ]: {
      padding: 0,
      marginTop: -20,
      marginBottom: -15,
      marginLeft: -10,
      '& button': {
        width: 280,
        fontSize: 12
      },
    }
  },
  actionButtons: {
    marginBottom: 10,
    [ theme.breakpoints.down( 'sm' ) ]: {
      margin: '20px 10px',
    }
  }
} ) )

const AdminCommonListLayout = ( {
  title,
  active_key,
  add_label,
  canDelete,
  onAddRow,
  showNumbers,
  noHeadings,
  useColumnsSelector,
  storeTableName,
  renderAdditionalFilter,
  enableAnyFieldFilter,
  renderAdditionalActions,
  renderAdditionalLeftActions,
  onEditRow,
  is_loading,
  breadcrumbs,
  columns,
  item_list,
  dummyData,
  rowClickRedirect,
  renderSelect,
  selectedRowIds,
  updateSelectedRowIds,
  extra_content_top,
  as_table,
  children
} ) => {
  const classes = useStyles()
  const rows = !dummyData ? item_list.getVisibleObjects() : item_list
  const dispatch = useDispatch()
  const thereAreActions = add_label || renderAdditionalActions

  const onFilterChanged = ( filter_values ) => {
    if ( !dummyData ) {
      dispatch( item_list.updateListFilter( filter_values ) )
      dispatch( item_list.fetchListIfNeeded() )
    }
  }

  const renderFilter = ( formik_props ) => {
    return (
      <div>
        { renderAdditionalFilter && <div style={ { margin: '2px 15px 15px 15px' } }>{ renderAdditionalFilter( formik_props ) }</div> }
        { enableAnyFieldFilter && <SearchBar formik_props={ formik_props } /> }
      </div>
    )
  }

  const renderContent = ( in_table ) => {
    return (
      <>
        { extra_content_top && <div>{ extra_content_top }</div> }
        { thereAreActions &&
          <div style={ { position: 'relative', padding: in_table ? '7px 15px' : 0 } }>
            <Grid container justifyContent="space-between" className={ classes.actions } alignContent="center">

              { onAddRow &&
                <Grid item>
                  <div className={ classes.actionButtons }>
                    <AddButton onClick={ onAddRow } label={ add_label } color="primary" />
                  </div>
                </Grid>
              }
              { renderAdditionalActions &&
                <Grid item>
                  <div className={ classes.actionButtons }>{ renderAdditionalActions() }</div>
                </Grid>
              }

            </Grid>
          </div>
        }
        <Paper variant="outlined">
          <AdminTableFilter
            label=""
            placeholder="Search..."
            renderFilter={ renderFilter }
            updateOnChange={ onFilterChanged }
          />
          { is_loading && <LinearProgress style={ { height: 1 } } /> }
          <CommonTable
            is_loading={ is_loading }
            rows={ rows }
            columns={ columns }
            item_list={ item_list }
            onEditRow={ onEditRow }
            selectedRowIds={ selectedRowIds }
            updateSelectedRowIds={ updateSelectedRowIds }
            canDelete={ canDelete }
            render_additional_filter={ renderAdditionalFilter }
            useColumnsSelector={ useColumnsSelector }
            storeTableName={ storeTableName }
            showNumbers={ showNumbers }
            noHeadings={ noHeadings }
            dummyData={ dummyData }
            rowClickRedirect={ rowClickRedirect }
            renderSelect={ renderSelect }
          />
        </Paper>
        { children }
      </>
    )
  }

  if ( as_table ) {
    return renderContent( as_table )
  } else {
    return (
      <AdminMainLayout
        active_key={ active_key }
        breadcrumbs={ breadcrumbs }
        title={ title }
      >
        { renderContent() }
      </AdminMainLayout>
    )
  }
}

export default AdminCommonListLayout
