import React from 'react'
import { Link } from 'react-router-dom'
import { makeStyles, Grid, Typography, Card, CardContent, CardActions, CardActionArea, Button } from '@material-ui/core'
import { getActiveCompanySlug } from 'actions/active_company'

const useStyles = makeStyles((theme) => ({
  root: {
    paddingLeft: 15,
    paddingRight: 15,
    paddingBottom: 15
  },
  donateButton: {
    fontSize: 18,
    fontWeight: 500,
    paddingTop: 7
  },
  donateFoodButton: {
    marginTop: 10,
    backgroundColor: '#fbdf06',
    '&:hover': {
      background: '#e9ce01'
    },
    [theme.breakpoints.down('sm')]: {
      margin: 0,
    },
  },
  donateMoneyButton: {
    marginTop: 10,
    [theme.breakpoints.down('sm')]: {
      margin: 0,
    },
  },
  donateCardActionArea: {
    paddingTop: 5,
    paddingBottom: 5,
    [theme.breakpoints.down('sm')]: {
      paddingTop: 20,
      paddingBottom: 20,
    },
  },
  donateLink: {
    color: '#222222'
  }
}))

export default function DonateButtonsWidget({isMoneyDonor, isFoodDonor}) {
  const classes = useStyles()
  isMoneyDonor = true
  isFoodDonor = true
  const company_slug = getActiveCompanySlug()
  const buttonSpacing = isMoneyDonor && isFoodDonor ? 15 : 0

  return(
    <Grid container className={classes.root} spacing={2}>
      {isFoodDonor &&
        <Grid item xs={12} md={3} lg={2}>
        <Button
          variant="outlined"
          fullWidth
          className={[classes.donateButton, classes.donateFoodButton]}
          component={Link}
          to={"/donor/donate/food/new"}
          style={{marginRight: buttonSpacing}}
          disableElevation
        >
          Donate Food
        </Button>
        </Grid>
      }

      {isMoneyDonor &&
        <Grid item xs={12} md={3} lg={2}>
            <Button
              variant="contained"
              disableElevation
              fullWidth
              component={Link}
              to={'/donate?source=' + company_slug}
              color="primary"
              className={[classes.donateButton, classes.donateMoneyButton]}
            >
              Donate Money
            </Button>
        </Grid>
      }
    </Grid>
  )
}
