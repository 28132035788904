import React from 'react'
import { Link } from 'react-router-dom'
import { Button } from '@material-ui/core'

export function DonorRegisterButton({variant, size}) {
  return(
    <Button component={Link} to={"/impact/register/beneficiary"} variant={variant} size={size}>Beneficiary</Button>
  )
}

export function BeneficiaryRegisterButton({variant, size}) {
  return(
    <Button component={Link} to={"/impact/register/donor"} variant={variant} size={size}>Food Donor</Button>
  )
}

export function LogisticsRegisterButton({variant, size}) {
  return(
    <Button component={Link} to={"/impact/register/logistics"} variant={variant} size={size}>Logistics Partner</Button>
  )
}
