import React from 'react'
import { get } from 'lodash'
import { Typography } from '@material-ui/core'
import CheckIcon from '@material-ui/icons/Check'

export function GenericCompanyDesciption({company}) {
 const city = get(company, ["city_name"]) ? get(company, ["city_name"]) : false
 const province = get(company, ["province"]) ? get(company, ["province"]) : false
 const donorDescription = <Typography>
   <p>{get(company, ["name"])} is an SA Harvest food donor.<br />
      They provide food donations in {city && city}{ city && province && ', '}{ province && `the ${province}` }{province && ', '}South Africa.</p>
    <p>SA Harvest would not be able to do what we do without the incredible support and generosity of companies like {get(company, ["name"])}.</p>
    <p>All food provided by {get(company, ["name"])} is donated before the expiry date and is perfectly fit for human consumption.</p>
    <p>SA Harvest is committed to working with our food donor partners to help them reduce their food loss and waste by rescuing their quality surplus food before it goes to waste and distributing it to our network of vetted beneficiary organisations.</p>
  </Typography>
 const beneficiaryDescription =
     <Typography>
       <p>{get(company, ["name"])} is an SA Harvest vetted beneficiary.<br />
          They provide food relief to people in {city && city}{ city && province && ', '}{ province && `the ${province}` }{province && ', '}South Africa.</p>
        <p>A vetted beneficiary has proved that they make a significant impact to food insecure communities and follow processes which are aligned with SA Harvest.</p>
        <p>This includes{':'}
          <ul style={{margin: '15px 30px', fontWeight: 500}}>
            <li><CheckIcon style ={{color: 'green'}} /> Safe Food Handling</li>
            <li><CheckIcon style ={{color: 'green'}} /> Ethical Conduct</li>
            <li><CheckIcon style ={{color: 'green'}} /> Demographic</li>
            <li><CheckIcon style ={{color: 'green'}} /> Transparency</li>
            <li><CheckIcon style ={{color: 'green'}} /> Reporting</li>
          </ul>
        </p>
        <p>SA Harvest is committed to working with partners who help to ensure that food insecure people have access to healthy, nutritious food. {get(company, ["name"])} has a track record which proves that they are a suitable partner to ensure that these goals are met.</p>
      </Typography>

  const description = get(company, ["company_type"]) == 'beneficiary' ? beneficiaryDescription : donorDescription

  return description


}
