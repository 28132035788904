import React, { useState, useEffect } from 'react'
import { map, get, last, join } from 'lodash'
import { makeStyles } from '@material-ui/core'
import TreeView from '@material-ui/lab/TreeView'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import ChevronRightIcon from '@material-ui/icons/ChevronRight'
import TreeItem from '@material-ui/lab/TreeItem'
import AddIcon from '@material-ui/icons/Add'
import EditIcon from '@material-ui/icons/Edit'
import DeleteIcon from '@material-ui/icons/Delete'
import { Button, IconButton, Tooltip, Grid } from '@material-ui/core'
import CheckIcon from '@material-ui/icons/Check'

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
      },
    extendedIcon: {
        marginRight: theme.spacing(2),
    },
    node_row: {
        padding: 10,
        marginLeft: -5,
        background: "#ffffff",
        borderBottom: "1px solid #efefef",
        display: "flex",
        justifyContent: "space-between",
        borderBottom: '1px solid E0E0E0',
        '&:hover': {
            backgroundColor: "#fafafa"
        }
    },
    node_label: {
        color: "#1c313a",
        paddingTop: 3,
    },
    node_label_filtered: {
        fontWeight: "500",
        paddingLeft: 10,
        paddingTop: 3,
        background: "transparent"
    },
    margin: {
        marginLeft: 0
    },
    node_actions: {
    },
    add_button: {
    },
    select_button: {
        [theme.breakpoints.down('md')]: {
            float: 'right'
        },
        [theme.breakpoints.up('md')]: {
            marginRight: 10
        },
    },
    treeViewRelativeDiv: {
        position: "relative"
    },
    newTopLevelProductCategoryButton: {
        position: "absolute",
        top: -110,
        left: 0,
        [theme.breakpoints.down('sm')]: {
            top: -105,
            left: 0,
        },
    },
    deleteIcon: {
        color: "#DD2C00"
    },
    checkIcon: {
        color: '#4caf50',
        marginBottom: -5
    },
    deleteIconButton: {
        color: "#ef9a9a",
        borderLeft: "1px solid #eceff1",
        borderRadius: 0,
        marginLeft: 5,
        paddingLeft: 5
    },
    addIcon: {
        color: "#00C853",
    },
    editIcon: {
        color: "#1A237E"
    },
    actions: {
        textAlign: "right"
    }
}))

export default function TabularTree({
    root_nodes,
    label_field_name,
    label_field_extra,
    onAddItem, onEditItem,
    onDeleteItem,
    onSelectItem,
    filterFunc,
    forceExpandedIds,
    onNodeToggle
}) {
    const classes = useStyles()
    const [selected_node, setSelectedNode] = useState(null)
    const [expandedNodes, setExpandedNodes] = useState(forceExpandedIds)

    useEffect(() => {
        setExpandedNodes(forceExpandedIds)
    }, [forceExpandedIds])

    const localOnAddItem = (kwargs) => {
        onAddItem(kwargs)
    }

    const renderAddButton = (parent_node) => {
        let color = "action"
        let label = "New Subcategory"
        let button = <IconButton size="small" aria-label="add" onClick={() => localOnAddItem({ parent_item: parent_node })}>
            <Tooltip title={label} arrow>
                <AddIcon className={classes.addIcon} />
            </Tooltip>
        </IconButton>
        if (parent_node == null) {
            button = <Button
                size="large"
                variant="contained"
                color="primary"
                className={classes.newTopLevelProductCategoryButton}
                onClick={() => localOnAddItem({ parent_item: parent_node })}
            >
                New Product Category
            </Button>
        }
        return button
    }

    const renderSelectButton = (node) => {
        return (
            <Button
                size="small"
                variant="contained"
                elevation={0}
                color="secondary"
                aria-label="select"
                onClick={() => onSelectItem(node)}
                className={classes.select_button}
            >
                Select
            </Button>
        )
    }

    const renderEditButton = (node) => {
        return (
            <span onClick={() => onEditItem({ item: node })}>
                <IconButton size="small" aria-label="edit">
                    <Tooltip title={"Edit category"} arrow><EditIcon fontSize="small" className={classes.editIcon} /></Tooltip>
                </IconButton>
            </span>
        )
    }

    const renderDeleteButton = (node) => {
        return (
            <span onClick={() => onDeleteItem({ item: node })}>
                <IconButton size="small" aria-label="delete" className={classes.deleteIconButton}>
                    <Tooltip title={"Delete category"} arrow><DeleteIcon className={classes.deleteIcon} /></Tooltip>
                </IconButton>
            </span>
        )
    }

    const recursivelyRenderNode = (node) => {

        const filtered = filterFunc && filterFunc(node)

        const label = (
            <Grid container spacing={2} justifyContent="space-between">
                <Grid item xs={6} lg={9}>
                    <div className={filtered ? classes.node_label_filtered : classes.node_label}>
                        {onSelectItem && renderSelectButton(node)}
                        {get(node, label_field_name)} {get(node, label_field_extra) && <CheckIcon className={classes.checkIcon} />}
                    </div>
                </Grid>

                <Grid item xs={6} lg={3}>
                    <div className={classes.actions}>
                        {onAddItem && renderAddButton(node)}
                        {onEditItem && renderEditButton(node)}
                        {onDeleteItem && renderDeleteButton(node)}
                    </div>
                </Grid>
                {!last}

            </Grid>
        )

        return (
            <>
                <TreeItem
                    key={ node.id }
                    nodeId={ node.id }
                    label={ label }
                    style={{ background: '#ffffff', borderBottom: '1px solid #E0E0E0', padding: '5px 0 3px 0' }}
                >
                    { map(get(node, "children"), (child) => recursivelyRenderNode(child)) }
                </TreeItem>
            </>
        )
    }

    return (
        <TreeView
            key={join(expandedNodes, ",")}
            className={classes.root}
            defaultCollapseIcon={<ExpandMoreIcon />}
            defaultExpandIcon={<ChevronRightIcon />}
            expanded={expandedNodes}
            onNodeToggle={onNodeToggle}           
        >
            <div className={classes.treeViewRelativeDiv}>
                <Grid
                container
                direction="row"
                justifyContent="space-between"
                alignItems="flex-end"
            >

                <Grid item xs={12}>
                    { map(root_nodes, (node) => recursivelyRenderNode(node)) }
                </Grid>
                <Grid item xs={1}>
                    { onAddItem && renderAddButton(null) }
                </Grid>
            </Grid>
            </div>

        </TreeView>
    )
}
