import { useState } from 'react'
import axios from 'axios'
import { getAuthHeaders } from 'lib/http'

const useAxiosPost = () => {
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(null)
  const baseURL = window.LOCAL_SETTINGS.API_BASE

  axios.defaults.headers.common = getAuthHeaders()

  const post = async(url, data, config) => {
    url = baseURL + url
    setLoading(true)
    try {
      const response = await axios.post(url, data, config)
      setLoading(false)
      return response
    } catch (err) {
      setError(err)
      setLoading(false)
      throw err
    }
  }

  return [post, { loading, error }]
}

export default useAxiosPost
