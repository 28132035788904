import { ItemList } from 'orm'

class Section18AList extends ItemList {
    getEntityKey() {
        return "contact/section18a"
    }

}

export const contactSection18AList = new Section18AList("contact_section18a__default")
