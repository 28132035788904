import React from 'react'
import { map, get } from 'lodash'
import {
  makeStyles,
  Menu,
  MenuItem,
  IconButton,
  Divider
} from '@material-ui/core'
import MoreVertIcon from '@material-ui/icons/MoreVert'

const useStyles = makeStyles((theme) => ({
    action: {
        cursor: "pointer",
        color: '#333333',
        fontSize: 14
    },
    menuStyle: {
      padding: 0,
      boxShadow: '0px 1px 3px rgb(204 204 204 / 0.1)',
      borderRadius: 4
    },
    contextMenuIcon: {
        marginRight: -15
    }
}))

export default function TableRowContextMenu({index, row, actions}) {
    const classes = useStyles()
    const [visible, setVisible] = React.useState(false)
    const [menuAnchorEl, setMenuAnchorEl] = React.useState(null)

    const onShow = (event) => {
        setMenuAnchorEl(event.currentTarget)
        setVisible(true)
    }

    const onHide = (event) => {
        setVisible(false)
    }

    return (
        <div className={classes.contextMenuIcon}>
            <IconButton aria-describedby={index} onClick={onShow}>
                <MoreVertIcon />
            </IconButton>
            <Menu
                anchorEl={menuAnchorEl}
                open={visible}
                onClose={onHide}
                onClick={onHide}
                PaperProps={{
                    elevation: 0,
                }}
                classes={{
                    paper: classes.menuStyle,
                    list: classes.menuListStyle
                }}
                transformOrigin={{ horizontal: 'right', vertical: 'top' }}
            >
                {map(actions, (action, index) => {
                    return (
                        <div>
                            {get(action, 'tooltip') === "Delete" && <Divider />}
                            <MenuItem
                                key={index}
                                className={classes.action}
                                onClick={() => action.onClick(row)} >
                                { get(action, 'icon')} {get(action, 'tooltip') }
                            </MenuItem>
                        </div>
                    )
                })}
            </Menu>
        </div>
    )
}
