import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useParams } from 'react-router-dom'
import { get, filter } from 'lodash'
import { adminChecklistScheduleList } from './actions/admin_checklist_schedule'
import {showSuccess, showError} from 'actions/Error'
import { handleSubmitResult } from 'actions/form'
import AdminMainLayout from '../layout/AdminMainLayout'
import AdminChecklistScheduleForm from './form/AdminChecklistScheduleForm'
import { FormikGeneralFormErrors } from 'components/form/GeneralFormErrors'
import { Formik, Form } from 'formik'
import FormCard from 'components/layout/FormCard'
import { SaveButton } from 'components/form/Buttons'
import { Card } from 'components/layout/Card'
import { Grid, Typography } from '@material-ui/core'

export default function AdminChecklistSchedule(onSaved) {
    const dispatch = useDispatch()
    const history = useHistory()
    const { checklist_schedule_id } = useParams()
    const checklist_schedule = useSelector(() => adminChecklistScheduleList.getObject(checklist_schedule_id))
    const checklist_name = get(checklist_schedule, ["name"])
    const is_loading = useSelector(() => adminChecklistScheduleList.isLoading())  || (checklist_schedule_id && !get(checklist_schedule, "id"))
    const is_busy = useSelector(() => adminChecklistScheduleList.getIsSavingObject())
    const title = `${get(checklist_schedule, "name", 'New checklist schedule')}`
    const justify = checklist_schedule_id ? '' : 'center'

    const onSavedChecklistSchedule = () => {
        history.push('/admin/checklists/schedules')
    }

    return (
        <AdminMainLayout
            breadcrumbs={[
                {name: 'admin_home'},
                {name: 'checklists', label: 'Checklist Schedule', url: '/admin/checklists/schedules'},
                {name: 'checklists',  label: get(checklist_schedule, "name", 'Schedule a Checklist'), url: `/admin/checklists/schedule/${checklist_schedule_id}`}
            ]}
            is_busy={is_busy}
            is_loading={is_loading}
            title={title}
        >
            <AdminChecklistScheduleForm checklist_schedule_id={checklist_schedule_id} onSaved={onSavedChecklistSchedule} />

      </AdminMainLayout>
    )
}
