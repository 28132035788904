import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { get } from 'lodash'
import { adminNutritionCardList } from './actions/admin_nutrition_card'
import { ShortId } from 'components/ShortId'
import { NutrientField } from './NutritionAttributes'
import AdminCommonListTable from 'admin/components/layout/AdminCommonListTable'

export const AdminNutritionCardTable = ({ food_item_id, onAddNutritionCard, onEditNutritionCard }) => {

  const dispatch = useDispatch()
  const history = useHistory()
  const nutrition_cards = useSelector(() => adminNutritionCardList.getVisibleObjects())
  const is_loading = useSelector(() => adminNutritionCardList.isLoading())
  const filter = useSelector(() => adminNutritionCardList.getFilter())

  useEffect(() => {
      dispatch(adminNutritionCardList.updateListFilter({ food_item: food_item_id }))
      dispatch(adminNutritionCardList.fetchListIfNeeded())
  },[])

  if( ! onAddNutritionCard ) {
    onEditNutritionCard = (nutrition_card_id) => {
      history.push({
        pathname: '/admin/nutrition_card/'+nutrition_card_id
      })
    }

    onAddNutritionCard = () => {
      history.push({
        pathname: '/admin/nutrition_card/'
      })
    }
  }

  const onUpdateListOrdering = (field) => {
    dispatch(adminNutritionCardList.updateListOrdering(field))
  }

  const columns = [
    { field: 'name',
      title: 'Nutrient',
      render: (item) => <NutrientField nutrient_id={ get(item, 'nutrient')} field="name" />,
    sort:(direction) => onUpdateListOrdering(direction === 'asc' ? 'nutrient__name' : '-nutrient__name')
    },
    { field: 'nutrient.infoods_tagname',
      title: 'INFOODS TAG',
      render: (item) => <NutrientField nutrient_id={ get(item, 'nutrient')} field="infoods_tagname" />,
      sort:(direction) => onUpdateListOrdering(direction === 'asc' ? 'nutrient__infoods_tagname' : '-nutrient__infoods_tagname')
    },
    { field: 'nutrient_value',
      title: 'Value',
      align: "right",
      render: (item) => <>{get(item, "nutrient_value")}<NutrientField nutrient_id={ get(item, 'nutrient')} field="unit" /></>,
      sort:(direction) => onUpdateListOrdering(direction === 'asc' ? 'nutrient_value' : '-nutrient_value')
    }
  ]

  return (
    <AdminCommonListTable
      title={"Nutrition Cards"}
      onAddRow={onAddNutritionCard}
      onEditRow={onEditNutritionCard}
      is_loading={is_loading}
      columns={columns}
      item_list={adminNutritionCardList}
      rows={nutrition_cards}
      enableAnyFieldFilter={true}
      use_plus
      noPadding
    />
  )
}
