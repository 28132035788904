import { makeStyles, Typography, InputAdornment } from "@material-ui/core"
import { FormikCheckboxField } from 'components/form/CheckboxField'
import { FormikInputField } from 'components/form/InputField'

const useStyles = makeStyles((theme) => ({
  bolder: {
      fontWeight: 500
  }
}))

const OrderTotalOverrides = ({ formik_props }) => {
    const classes = useStyles()
    return (
        <>
            { formik_props.values.total_value_rands &&
                <Typography>
                    Value: <span className={classes.bolder}>R {formik_props.values.total_value_rands}</span>
                </Typography>
            }

            <FormikCheckboxField
                name="override_total_value_rands"
                label="Override Total Rand Value?"
                formik_props={formik_props}
                color="primary"
            /><br />

            {formik_props.values.override_total_value_rands == true &&

                <>
                    <FormikInputField
                        name="total_value_rands"
                        label="Total Rand Value"
                        formik_props={formik_props}
                        helperText="Only saved if override is ticked"
                        fullWidth={false}
                        InputProps={{
                           startAdornment: <InputAdornment position="start">R</InputAdornment>,
                        }}
                    /><br />
                </>
            }

            { formik_props.values.total_weight_kg &&
                <Typography>
                    Weight: <span className={classes.bolder}>{formik_props.values.total_weight_kg} kg</span>
                </Typography>
            }
            <FormikCheckboxField
                name="override_total_weight_kg"
                label="Override Total Weight?"
                formik_props={formik_props}
                color="primary"
            /><br />

            { formik_props.values.override_total_weight_kg == true &&
                <FormikInputField
                    name="total_weight_kg"
                    label="Total Weight"
                    formik_props={formik_props}
                    helperText="Only saved if override is ticked"
                    fullWidth={false}
                    InputProps={{
                        endAdornment: <InputAdornment position="start">kg</InputAdornment>,
                    }}
                />
            }
        </>
    )
}

export default OrderTotalOverrides
