import React from 'react'
import PropTypes from 'prop-types'
import { Typography, makeStyles } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
    adminTitle: {
        color: '#000',
        padding: '15px 15px 15px 55px',
        margin: '0px -15px',
        background: '#fff',
        boxShadow: '0px 10px 10px -18px #111',
        position: 'fixed',
        zIndex: 9,
        width: '100%',
        [theme.breakpoints.down('sm')]: {
            fontSize: 16
        }
    },
    donorTitle: {
        color: '#1c313a',
        padding: '18px 15px 16px 60px',
        margin: '0px -15px',
        background: '#efefef',
        [theme.breakpoints.down('sm')]: {
            fontSize: 16
        }
    }
}))

export default function Title({ portal, style, children }) {
    const classes = useStyles()
    const titleClass = portal == 'donor' ? classes.donorTitle : classes.adminTitle

    return (
        <Typography variant="h5" className={titleClass} style={style}>
            {children}
        </Typography>
    )
}

Title.propTypes = {
  children: PropTypes.node,
}
