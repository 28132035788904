import { ItemList } from 'orm'
import { get, map, head, filter } from 'lodash'

class AddressList extends ItemList {
    getEntityKey() {
        return "contact/address"
    }

}

export const contactAddressList = new AddressList("contact_address__default")
export const contactAddressForDropdownList = new AddressList("contact_address_for_dropdown__default")


export const invalidateAllAddressLists = () => {
    return (dispatch, getState) => {
        dispatch(contactAddressList.invalidateList())
        dispatch(contactAddressList.fetchListIfNeeded())
        dispatch(contactAddressForDropdownList.invalidateList())
        dispatch(contactAddressForDropdownList.fetchListIfNeeded())
    }
}
