import { makeStyles, Chip } from "@material-ui/core"
import NumberFormat from 'react-number-format'

const useStyles = makeStyles((theme) => ({
    parcel_total_weight: {
        marginTop: 10,
        marginRight: 10,
        float: 'right',
        fontSize: 24,
        background: 'black',
        color: 'white',
    }
}))

const AdminParcelTotalWeight = ({ total_parcel_weight }) => {
    const classes = useStyles()

    return(
        <Chip
            className={classes.parcel_total_weight}
            label={
                <NumberFormat
                    value={total_parcel_weight}
                    displayType={'text'}
                    thousandSeparator={true}
                    suffix={'kg'}
                    decimalScale={2}
                />
            }
        />
    )
}

export default AdminParcelTotalWeight
