import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { get, size, map, join } from 'lodash'
import AdminCommonListLayout from '../layout/AdminCommonListLayout'
import { adminKpiDashboardList } from './actions/admin_kpi_dashboard'
import { ShortId } from 'components/ShortId'
import { UserName } from 'admin/components/users/UserAttributes'

export default function AdminKpiDashboards({}) {
    const dispatch = useDispatch()
    const history = useHistory()
    const dashboards = useSelector(() => adminKpiDashboardList.getVisibleObjects())
    const is_loading = useSelector(() => adminKpiDashboardList.isLoading())
    const filter = useSelector(() => adminKpiDashboardList.getFilter())

    useEffect(() => {
        dispatch(adminKpiDashboardList.fetchListIfNeeded())
    },[])

    const onEditDashboard = (dashboard_id) => {
        history.push({
            pathname: '/admin/kpis/dashboard/' + dashboard_id
        })
    }

    const onAddDashboard = () => {
        history.push('/admin/kpis/dashboard')
    }

    const onUpdateListOrdering = (field) => {
      dispatch(adminKpiDashboardList.updateListOrdering(field))
    }

    const columns = [
        {
            field: 'id',
            title: 'ID',
            render: (item) => <a onClick={() => onEditDashboard(get(item, "id"))}><ShortId value={get(item, "id")} /></a>,
        },
        {
            field: 'name',
            title: 'Name',
            sort: (direction) => onUpdateListOrdering(direction === 'asc' ? 'name' : '-name')
        },
        {
            field: 'kpi_count',
            title: 'KPI Count',
            render: (item) => size(get(item, ["kpis"])),
            sort: (direction) => onUpdateListOrdering(direction === 'asc' ? 'kpi_count' : '-kpi_count')
        },
        {
            field: 'assigned_users',
            title: 'Assigned Users',
            render: (item) => map(item.users, (user, i) => ( <><UserName user_id={user} />{ i < size(get(item, 'users')) - 1 ? ', ' : '' }</> )),
            sort: (direction) => onUpdateListOrdering(direction === 'asc' ? 'assigned_users' : '-assigned_users')
        },
        {
            field: 'actions'
        }
    ]

    return (
        <AdminCommonListLayout
            active_key="kpi_dashboards"
            breadcrumbs={[
                {name: 'admin_home'},
                {name: 'dashboards', label: "Dashboards", url: '/admin/kpis/dashboards'}
            ]}
            add_label="New Dashboard"
            title="KPI Dashboards"
            onAddRow={onAddDashboard}
            onEditRow={onEditDashboard}
            is_loading={is_loading}
            columns={columns}
            item_list={adminKpiDashboardList}
        />
    )

}
