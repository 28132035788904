import { ItemList } from 'orm'

class MarketingCompanyList extends ItemList {
    getEntityKey() {
        return "marketing/company"
    }
}

export const marketingCompanyList = new MarketingCompanyList("marketing_company__default")
export const marketingCompanyForMarketingList = new MarketingCompanyList("marketing_company_for_marketing__default")
export const marketingCompanyListForMap = new MarketingCompanyList("marketing_company_for_map__default")
