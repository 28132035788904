import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import AdminCommonListLayout from '../layout/AdminCommonListLayout'
import { adminNutrientList } from './actions/admin_nutrient'
import { StatusIndicator } from 'components/layout/StatusIndicator'
import { get } from 'lodash'

export default function AdminNutrients() {

  const dispatch = useDispatch()
  const history = useHistory()

  const nutrients = useSelector(() => adminNutrientList.getVisibleObjects())
  const is_loading = useSelector(() => adminNutrientList.isLoading())
  const filter = useSelector(() => adminNutrientList.getFilter())

  useEffect(() => {
    dispatch(adminNutrientList.fetchListIfNeeded())
  },[])

  const onEditNutrient = (nutrient_id) => {
    history.push({
      pathname: '/admin/nutrient/'+nutrient_id
    })
  }

  const onAddNutrient = () => {
    history.push({
      pathname: '/admin/nutrient/'
    })
  }

  const columns = [
    { field: 'infoods_tagname',
      title: 'Code',
      sort: (direction) => onUpdateListOrdering(direction === 'asc' ? 'infoods_tagname' : '-infoods_tagname'),
    },
    { field: 'short_name',
      title: 'Name',
      sort: (direction) => onUpdateListOrdering(direction === 'asc' ? 'short_name' : '-short_name'),
    },
    { field: 'nutrient_type',
      title: 'Nutrient Type',
      sort:(direction) => onUpdateListOrdering(direction === 'asc' ? 'nutrient_type' : '-nutrient_type')
    },
    { field: 'rda',
      title: 'RDA',
      align: 'right',
      sort:(direction) => onUpdateListOrdering(direction === 'asc' ? 'rda' : '-rda')
    },
    { field: 'unit',
      title: 'Unit',
      align: 'left',
      sort:(direction) => onUpdateListOrdering(direction === 'asc' ? 'unit' : '-unit')
    },
    { field: 'denominator',
      title: 'Denominator',
      sort:(direction) => onUpdateListOrdering(direction === 'asc' ? 'denominator' : '-denominator')
    },
    // { field: 'is_necessary_for_adequate_nutrition',
    //   title: 'Necessary',
    //   render: (item) => <StatusIndicator status={get(item, "is_necessary_for_adequate_nutrition")} type="check" />,
    //   sort:(direction) => onUpdateListOrdering(direction === 'asc' ? 'is_necessary_for_adequate_nutrition' : '-is_necessary_for_adequate_nutrition')
    // },
    { field: 'use_in_nutrition_facts_card',
      title: 'Facts Card',
      render: (item) => <StatusIndicator status={get(item, "use_in_nutrition_facts_card")} type="check" />,
      sort:(direction) => onUpdateListOrdering(direction === 'asc' ? 'use_in_nutrition_facts_card' : '-use_in_nutrition_facts_card')
    },
    { field: 'calculation_weight',
      title: 'Calculation Weight',
      sort:(direction) => onUpdateListOrdering(direction === 'asc' ? 'calculation_weight' : '-calculation_weight')
    },
  ]

  const onUpdateListOrdering = (field) => {
    dispatch(adminNutrientList.updateListOrdering(field))
  }

  return (
    <AdminCommonListLayout
      active_key="nutrients"
      breadcrumbs={[
        {name: 'admin_home'},
        {name: 'nutrients', label: "Nutrients", url: '/admin/nutrients'}
      ]}
      add_label="Add Nutrient"
      title={"Nutrients"}
      onAddRow={onAddNutrient}
      onEditRow={onEditNutrient}
      is_loading={is_loading}
      columns={columns}
      item_list={adminNutrientList}
      enableAnyFieldFilter={true}
    />
  )
}
