import React from 'react'
import { makeStyles, withStyles, Typography, Grid, Slider } from '@material-ui/core'
import johannesburg from 'assets/images/cities/johannesburg.png'
import 'themes/css/slider.css'

const useStyles = makeStyles( ( theme ) => ( {
  root: {
    width: '100%',
    background: '#fadf00',
    fontSize: 36,
    fontWeight: 100,
    minHeight: '100vh',
    padding: 15
  },
  inputProps: {
    borderRadius: 32,
    border: '2px solid black'
  },
  donate_heading: {
    fontFamily: "Frankfurter Std Regular",
    fontSize: 72,
    textAlign: 'center',
    textTransform: 'uppercase',
    color: 'rgb(15 23 42)'
  },
  title: {
    fontSize: 36,
    color: 'black',
    marginBottom: 30,
    [ theme.breakpoints.down( "xs" ) ]: {
      fontSize: 18,
      textAlign: 'center'
    },
  },
  sliderArea: {
    backgroundImage: `url(${ johannesburg })`,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center bottom',
    height: 295,
    [ theme.breakpoints.down( "sm" ) ]: {
      backgroundSize: '100% 100%',
      height: 150
    }
  },
  slider: {
    marginTop: 280,
    width: '98%',
    [ theme.breakpoints.down( "sm" ) ]: {
      marginTop: 135,
      width: '90%',
    },
  },
  calculator: {
    fontFamily: "Frankfurter Std Regular",
    fontSize: 36,
    textTransform: 'uppercase',
    paddingTop: 50,
    paddingBottom: 20,
    fontWeight: 400,
    color: 'rgb(30 41 59)',
    [ theme.breakpoints.down( "xs" ) ]: {
      fontSize: "1.2rem",
      paddingTop: 60,
      paddingBottom: 20
    },
  },

} ) )

const ImageSlider = withStyles( ( theme ) => ( {
  root: {
    padding: "13px 0"
  },
  thumb: {
    height: 71,
    width: 150,
    borderRadius: 8,
    backgroundImage: `url("/static/images/logo.png")`,
    background: 'transparent',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    backgroundSize: 'contain',
    marginBottom: 71,
    marginTop: -71,
    marginLeft: -75
  },
  track: {
    //styles the line between thumbs
    height: 3,
    color: "#000000"
  },
  rail: {
    //styles line outside of thumbs
    color: "#000000",
    opacity: 1,
    height: 3
  }
} ) )( Slider )

const donationMarks = [
  {
    value: 0,
    scaledValue: 100,
    label: "R100"
  },
  {
    value: 25,
    scaledValue: 500,
    label: "R500"
  },
  {
    value: 50,
    scaledValue: 1000,
    label: "R1k"
  },
  {
    value: 75,
    scaledValue: 5000,
    label: "R5k"
  },
  {
    value: 100,
    scaledValue: 10000,
    label: "R10k"
  },
  {
    value: 125,
    scaledValue: 25000,
    label: "R25k"
  },
  {
    value: 150,
    scaledValue: 50000,
    label: "R50k"
  },
  {
    value: 175,
    scaledValue: 100000,
    label: "R100k"
  },
  {
    value: 200,
    scaledValue: 1000000,
    label: "1M"
  }
]

const scale = value => {
  const previousMarkIndex = Math.floor( value / 25 )
  const previousMark = donationMarks[ previousMarkIndex ]
  const remainder = value % 25
  if ( remainder === 0 ) {
    return previousMark.scaledValue
  }
  const nextMark = donationMarks[ previousMarkIndex + 1 ]
  const increment = ( nextMark.scaledValue - previousMark.scaledValue ) / 25
  return remainder * increment + previousMark.scaledValue
}

function numFormatter( num ) {
  if ( num > 999 && num < 1000000 ) {
    return ( num / 1000 ).toFixed( 0 ) + "K" // convert to K for number from > 1000 < 1 million
  } else if ( num >= 1000000 ) {
    return ( num / 1000000 ).toFixed( 0 ) + "M" // convert to M for number from > 1 million
  } else if ( num < 900 ) {
    return num // if value < 1000, nothing to do
  }
}

export default function DriveTruckSlider( { cost_per_meal } ) {
  const classes = useStyles()
  const [ value, setValue ] = React.useState( 0 )
  const money_to_meal_conversion_factor = cost_per_meal

  React.useEffect( () => {
    setTimeout( () => {
      setValue( 100 )
    }, 500 )
  }, [ setInitialInputValue ] )

  const setInitialInputValue = () => {
    setValue( 0 )
  }

  const handleSliderChange = ( event, newValue ) => {
    setValue( newValue )
  }

  return (
    <>
      <div className={ classes.p50 }>
        <div className={ classes.donate_heading }>Donate</div>
        <Typography variant="h2" align="center" className={ classes.title }>Drive our truck to see your impact</Typography>
      </div>
      <Grid container justifyContent="center" alignItems="center">
        <Grid item xs className={ classes.sliderArea }>
          <ImageSlider
            value={ typeof value === 'number' ? value : 0 }
            min={ 0 }
            step={ 1 }
            max={ 200 }
            valueLabelFormat={ numFormatter }
            marks={ donationMarks }
            scale={ scale }
            onChange={ handleSliderChange }
            aria-labelledby="input-slider"
            className={ classes.slider }
          />
        </Grid>
      </Grid>
      <Grid container spacing={ 2 } align="center" className={ classes.calculator }>
        <Grid item xs>
          R{ numFormatter( scale( value ) ) }
          <Typography variant="p" align="center"> rescues & delivers<br />
            <span className={ classes.numMeals }>{ Math.round( scale( value ) / money_to_meal_conversion_factor ) } meals</span>
          </Typography>
        </Grid>
      </Grid>
    </>
  )
}
