import React, { Component } from 'react'
import { connect } from 'react-redux'
import { CONFIRMATION_MODAL_KEY } from 'actions/ui'
import { Modal } from './layout/Modal'
import { clearActiveModal, getActiveModal, getModalParams } from 'actions/ui'
import { Separator } from './layout/Separator'
import { Grid, Button } from '@material-ui/core'

class ConfirmationModal extends Component {
  onClose = () => {
    const { dispatch, is_active } = this.props
    if (is_active) {
      dispatch(dispatch(clearActiveModal()))
    }
  }

  onConfirm = () => {
    const { onConfirmed } = this.props
    this.onClose()
    if (onConfirmed) {
      onConfirmed()
    }
  }

  render() {
    const { is_active, text, title, can_cancel } = this.props
    if (!is_active) {
      return null
    }

    return (
      <Modal title={title}
        onCloseModal={this.onClose}
      >
        <div>
          {text}
          <Separator height={20} />
          <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="flex-end"
          >

            {can_cancel &&
              <Grid item>
                <Button onClick={this.onClose}>
                  Cancel
                </Button>
              </Grid>
            }
            <Grid item>
              <Button variant="contained" color="primary" onClick={this.onConfirm}>
                Confirm
              </Button>
            </Grid>
          </Grid>
        </div>
      </Modal>
    )
  }
}

function mapStateToProps(state) {
  const { text, onConfirmed, heading, can_cancel } = getModalParams(state)
  const is_active = getActiveModal(state) === CONFIRMATION_MODAL_KEY
  return {
    text,
    onConfirmed,
    is_active,
    can_cancel: can_cancel !== false,
    heading: heading || "Confirm"
  }
}

export default connect(mapStateToProps)(ConfirmationModal)

