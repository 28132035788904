import { useEffect } from 'react'
import { adminNutrientList } from '../actions/admin_nutrient'
import { FormikDropdownField } from 'components/form/Dropdown'
import { useDispatch, useSelector } from 'react-redux'

export function FormikNutrientAutoCompleteDropdown({name, label, formik_props, ...props}) {
  const dispatch = useDispatch()
  const nutrient_options = useSelector(() => adminNutrientList.getAsOptions())

  useEffect(() => {
    dispatch(adminNutrientList.fetchListIfNeeded())
  },[])

  return(
    <FormikDropdownField
      name="nutrient"
      label="Nutrient"
      options={nutrient_options}
      formik_props={formik_props}
    />
  )

  // return (
  //   <FormikAutoCompleteDropdown
  //     item_list={adminNutrientAutoCompleteList}
  //     name={name}
  //     label={label}
  //     formik_props={formik_props}
  //   />
  // )
}
