import { useState } from 'react'
import { get, capitalize } from 'lodash'
import { Grid, FormHelperText, Typography, Button } from '@material-ui/core'
import { adminBeneficiaryList } from 'admin/components/beneficiaries/actions/admin_beneficiary'
import { Separator } from 'components/layout/Separator'
import { FormikBeneficiaryAutoCompleteDropdown } from 'admin/components/beneficiaries/form/BeneficiaryAutoCompleteDropdown'
import { FormikWarehouseAutoCompleteDropdown } from 'admin/components/warehouses/form/WarehouseAutoCompleteDropdown'
import { FormikBeneficiaryAddressDropdownWithFormBasedAddNew } from 'admin/components/beneficiaries/form/BeneficiaryAddressDropdown'
import { FormikDateTimePicker } from 'components/form/DatetimePicker'
import { FormikCheckboxField } from 'components/form/CheckboxField'
import { FormikInputField } from 'components/form/InputField'
import { Alert } from '@material-ui/lab'
import OrderTags from './OrderTags'
import React from 'react'

const BeneficiaryGeneralOrderForm = ( { formik_props, transport_action } ) => {
	
	const beneficiary = adminBeneficiaryList.getForCompany( { company_id: get( formik_props, [ "values", "to_company" ] ) } )
	const companyType = 'beneficiary'
	const address_ids = get( beneficiary, [ "company", "addresses" ] )
	const transport_by_warehouse_company = get( formik_props.values, "transport_by_warehouse_company" )
	const warehouse_transport_label = transport_by_warehouse_company ? 'Depart' : 'Collect'
	
	const schedule_helper_action = transport_by_warehouse_company ? 'we be collecting' : 'they be delivering'
	const [ showTransportCheckbox, setShowTransportCheckbox ] = useState( false )
	const newOrder = true

	return (
		<Grid container spacing={ 0 }>

			<Grid item xs={ 12 }>
				<FormikBeneficiaryAutoCompleteDropdown
					name="to_company"
					label={ capitalize( companyType ) }
					formik_props={ formik_props }
				/>
			</Grid>

			<Grid item>
				<Separator height={ 15 } />
				<FormikDateTimePicker
					name="arrive_at"
					label="Schedule"
					formik_props={ formik_props }
					fullWidth={ false }
				/>
				<Separator height={ 7 } />
				<FormHelperText>
					What date and time will { schedule_helper_action } this order?
				</FormHelperText>
			</Grid>

			<Grid item item xs={ 12 }>
				<Separator height={ 7 } />
				<FormikWarehouseAutoCompleteDropdown
					name='depart_warehouse'
					label={ `${ warehouse_transport_label } from warehouse` }
					formik_props={ formik_props }
				/>
				<Separator height={ 15 } />
				<Alert severity="info">
					<Typography>
						{ formik_props.values.transport_by_warehouse_company ?
							"SA Harvest Dropoff" :
							`${ formik_props.values.to_company_name ? formik_props.values.to_company_name : "Bereficiary" } will Collect`
						}
					</Typography>
					<Separator height={ 5 } />
					<Button onClick={ () => setShowTransportCheckbox( !showTransportCheckbox ) } size="small" variant="outlined">Change?</Button>
				</Alert>
				<Separator height={ 10 } />
				{ showTransportCheckbox &&
					<>
						<FormikCheckboxField
							name="transport_by_warehouse_company"
							label="SA Harvest transport"
							formik_props={ formik_props }
							color="primary"
							onClick={ () => setShowTransportCheckbox( !showTransportCheckbox ) }
						/>
						<FormHelperText>
							{ formik_props.values.transport_by_warehouse_company ?
								"Uncheck for donor to deliver" :
								"Check for SA Harvest to provide transport"
							}
						</FormHelperText>
					</>
				}
				{ get( beneficiary, "id" ) && transport_by_warehouse_company &&
					<>
						<FormikBeneficiaryAddressDropdownWithFormBasedAddNew
							name="arrive_address"
							beneficiary={ beneficiary }
							label={ `${ capitalize( transport_action ) } Address` }
							address_ids={ address_ids }
							formik_props={ formik_props }
						/>
						<FormHelperText>
							Where is this going?<br />
							Address is needed for the driver to navigate with the Bringg Driver App
						</FormHelperText>
					</>
				}
			</Grid>

			<Grid item xs={ 12 }>
				<FormikInputField
					name="description"
					label="Order notes..."
					multiline={ true }
					rows={ 4 }
					formik_props={ formik_props }
				/>
				<FormHelperText>Any relevant notes about this order</FormHelperText>
				<Separator height={ 15 } />
				<OrderTags formik_props={ formik_props } />
			</Grid>

		</Grid>
	)
}

export default BeneficiaryGeneralOrderForm
