import React, { useEffect } from 'react'
import * as Yup from 'yup'
import { useDispatch, useSelector } from 'react-redux'
import { FormikInputField } from 'components/form/InputField'
import { FormikDropdownField } from 'components/form/Dropdown'
import { Grid } from '@material-ui/core'
import { adminAgeGroups, adminSexes, adminRaces } from '../../../actions/admin_dropdown_options'
import { adminBeneficiaryList } from '../../beneficiaries/actions/admin_beneficiary'
import { FormikAutoCompleteDropdown } from 'components/form/AutoCompleteDropdown'

export const validationSchema = Yup.object().shape({
  'company': Yup.string().required("Company is required"),
  'age_group': Yup.string().required("Age group is required"),
  'sex': Yup.string().required("Sex is required"),
  'race': Yup.string().required("Race is required"),
  'number_of_people': Yup.string().required("Number of people is required"),
})

const addAllOption = (list) => {
  const allOption = {
    label: "All",
    value: "all",
    original_item: {
        id: "all",
        name: "All"
    }
  };

  return [...list, allOption];
}

export const AdminDemographicForm = ({ formik_props, company_id, is_filter }) => {
  const dispatch = useDispatch()
  const rawBeneficiaries = useSelector(() => adminBeneficiaryList.getAsOptions())
  const rawAge_group_options = useSelector(() => adminAgeGroups.getAsOptions())
  const rawSex_options = useSelector(() => adminSexes.getAsOptions())
  const rawRace_options = useSelector(() => adminRaces.getAsOptions())
  const sm = is_filter ? 3 : 12;

  const beneficiaries = is_filter ? addAllOption(rawBeneficiaries) : rawBeneficiaries;
  const age_group_options = is_filter ? addAllOption(rawAge_group_options) : rawAge_group_options;
  const sex_options = is_filter ? addAllOption(rawSex_options) : rawSex_options;
  const race_options = is_filter ? addAllOption(rawRace_options) : rawRace_options;

  useEffect(() => {
    dispatch(adminBeneficiaryList.fetchListIfNeeded())
    dispatch(adminAgeGroups.fetchListIfNeeded())
    dispatch(adminSexes.fetchListIfNeeded())
    dispatch(adminRaces.fetchListIfNeeded())
  }, [])

  return (
    <Grid container spacing={1}>
      {!company_id &&
        <Grid item xs={12} sm={sm}>
          <FormikAutoCompleteDropdown
            item_list={adminBeneficiaryList}
            name="company"
            label="Beneficiary"
            formik_props={formik_props}
          />
        </Grid>
      }
      <Grid item xs={12} sm={sm}>
        <FormikDropdownField
          name="race"
          label="Race"
          formik_props={formik_props}
          options={race_options}
        />
      </Grid>
      <Grid item xs={12} sm={sm}>
        <FormikDropdownField
          name="sex"
          label="Sex"
          formik_props={formik_props}
          options={sex_options}
        />
      </Grid>
      <Grid item xs={12} sm={sm}>
        <FormikDropdownField
          name="age_group"
          label="Age Group"
          formik_props={formik_props}
          options={age_group_options}
        />
      </Grid>
      {!is_filter &&
        <Grid item xs={12} sm={sm}>
          <FormikInputField
            name="number_of_people"
            label="Number of People"
            formik_props={formik_props}
          />
        </Grid>
      }
    </Grid>
  )
}
