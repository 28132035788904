import React from 'react';
import Skeleton from '@material-ui/lab/Skeleton'
import { makeStyles, Typography } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    padding: 15,
    [theme.breakpoints.down('sm')]: {
      marginTop: 0,
      marginBottom: 20,
    }
  },
  row: {
    marginTop: -15,
    marginBottom: -10,
  },
  loadingText: {
    color: '#cccccc'
  }
}))

export function TableSkeleton({tableName}) {
  const classes = useStyles()
  tableName = tableName ? tableName : 'data'
  return (
    <div className={classes.root}>
      <Typography variant="caption" className={classes.loadingText}>Loading {tableName}...</Typography>
      <Skeleton animation="pulse" height={60} className={classes.row} />
      <Skeleton animation="pulse" height={60} className={classes.row} />
      <Skeleton animation="pulse" height={60} className={classes.row} />
    </div>
  );
}

export function FormSkeleton() {
  const classes = useStyles()
  return (
    <>
      <Skeleton animation="pulse" height={60} className={classes.row} />
      <Skeleton animation="pulse" height={60} className={classes.row} />
      <Skeleton animation="pulse" height={60} className={classes.row} />
      <Skeleton animation="pulse" height={60} className={classes.row} />
      <Skeleton animation="pulse" height={60} className={classes.row} />
    </>
  );
}
