import React from 'react'
import { TextField } from 'formik-material-ui'
import { Field } from 'formik'
import { unpackFormikPropsForField } from 'actions/form'
import { GrammarlyEditorPlugin } from '@grammarly/editor-sdk-react'
import { startCase, split, map, join } from 'lodash'

export const FormikInputField = ( { label, value, placeholder, InputProps, InputLabelProps, spellCheck = true, fullWidth = true, type, name, formik_props, touch_on_edit, ...props } ) => {

	const { errors, touched } = unpackFormikPropsForField( { formik_props, name } )

	const setTouched = () => {
		formik_props.setFieldTouched( name )
	}

	const renderField = ( passed_value ) => {
		let value = formik_props.values[ name ] || passed_value
		if ( type === 'text' ) {
			const words = split( value, ' ' )
			const capitalizedWords = map( words, ( word ) => startCase( word ) )
			value = join( capitalizedWords, ' ' )
		}

		return (
			<>
				<Field
					component={ TextField }
					name={ name }
					type={ type || "text" }
					label={ label || placeholder }
					placeholder={ placeholder || label }
					id={ name }
					helperText={ touched ? errors : "" }
					error={ Boolean( errors ) }
					onInput={ setTouched }
					margin="normal"
					variant="outlined"
					fullWidth={ fullWidth }
					InputLabelProps={ InputLabelProps }
					InputProps={ {
						...InputProps,
						value: value,
					} }
					{ ...props }
				/>
				{ formik_props.isSubmitting && formik_props.errors && formik_props.errors[ name ] }
			</>
		)
	}

	const renderFieldWithSpellcheck = () => {
		// the client id should be stored in an environment variable
		return (
			<GrammarlyEditorPlugin clientId="client_6W9aTudzcZP7Bm1cnoc9PC">
				{ renderField( value ) }
				{ formik_props.values.name }
			</GrammarlyEditorPlugin>
		)
	}

	return spellCheck && type === 'text' ? renderFieldWithSpellcheck() : renderField( value )
}
