import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { get } from 'lodash'
import { adminProductList } from './actions/admin_product'
import NumberFormat from 'react-number-format'
import { Grid } from '@material-ui/core'
import { UOMName } from 'admin/components/uom/UOMAttributes'
import { FormControlLabel, Radio, RadioGroup, Button } from '@material-ui/core'
import { Modal } from 'components/layout/Modal'
import MergeTypeIcon from '@material-ui/icons/MergeType'
import { CommonTable } from 'components/CommonTable'
import { showSuccess } from 'actions/Error'
import useAxiosPost from 'hooks/api/useAxiosPost'

export default function AdminProductVariations({ product_category_id }) {
  const dispatch = useDispatch()
  const history = useHistory()
  const products = useSelector(() => adminProductList.getVisibleObjects())
  const is_loading = useSelector(() => adminProductList.isLoading())
  const filter = useSelector(() => adminProductList.getFilter())
  const [selectedRowIds, setSelectedRowIds] = useState([])
  const [masterProductId, setMasterProductId] = useState(null)
  const [modalOpen, setModalOpen] = useState(false)
  const [showSelect, setShowSelect] = useState(false)
  const [post, { loading, error }] = useAxiosPost()
  const [response, setResponse] = useState(null)
  const [submitting, setSubmitting] = useState(false)

  useEffect(() => {
    dispatch(adminProductList.updateListFilter({ product_category: product_category_id }))
    dispatch(adminProductList.fetchListIfNeeded())
  }, [ dispatch, product_category_id ])


  const onEditProduct = (product_id) => {
    history.push({
      pathname: '/admin/product/' + product_id
    })
  }

  const onMergeProducts = () => {
    if (selectedRowIds.length <= 1) {
      alert('Please select more than 1 row to merge.')
      return
    }
    const productIds = selectedRowIds.join(',')
    setModalOpen(true)
  }

  const onAddProduct = () => {
    history.push('/admin/product?product_category=' + product_category_id)
  }

  const onUpdateListOrdering = (field) => {
    dispatch(adminProductList.updateListOrdering(field))
  }

  const renderUOM = (item) => {
    return <UOMName uom_id={get(item, "uom")} />
  }

  const columns = [
    { 
        field: 'name', 
        title: 'Old Name', 
        sort: (direction) => onUpdateListOrdering(direction === 'asc' ? 'name' : '-name'), 
        redirect: (item) => `admin/product/${item.id}`,
        visible: false
    },
    { 
      field: 'product_category_name', 
      title: 'Product Category',
      sort: (direction) => onUpdateListOrdering(direction === 'asc' ? 'product_category_name' : '-product_category_name'), 
      visible: false
    },
    {
      field: 'uom',
      title: 'UOM',
      render: (item) => renderUOM(item),
      sort: (direction) => onUpdateListOrdering(direction === 'asc' ? 'uom' : '-uom'),
      visible: true
    },
    {
      field: 'merged_product_count',
      title: 'Merged Products',
      align: 'right',
      sort: (direction) => onUpdateListOrdering(direction === 'asc' ? 'merged_product_count' : '-merged_product_count'),
      visible: false
    },
    {
      field: 'weight_kg',
      title: 'Weight',
      align: 'right',
      render: (item) => <NumberFormat value={ get(item, ["weight_kg"]) } suffix={"kg"} displayType={'text'} thousandSeparator={true} decimalScale={2} />,
      sort: (direction) => onUpdateListOrdering(direction === 'asc' ? 'weight_kg' : '-weight_kg'),
      visible: true
    },
  ]

  const renderAdditionalActions = () => {
    return (
      <Button aria-label="merge products" onClick={onMergeProducts}>
        <MergeTypeIcon /> Merge
      </Button>
    )
  }

  const handleMasterProductSelection = (event) => {
    setMasterProductId(event.target.value);
  }

  const onSave = async(values, { setSubmitting }) => {
    values.products = selectedRowIds
    values.master = masterProductId

    try {
      const formData = new FormData()
      formData.append('objects', values.products)
      formData.append('master', values.master)
      const postResponse = await post('admin/product/merge_products/', formData)
      setSubmitting(false)
      setResponse(postResponse)
      showSuccess("Success", "products merged successfully")
      dispatch(adminProductList.invalidateList())
      dispatch(adminProductList.fetchListIfNeeded())
    } catch (error) {
      console.error(error)
      setSubmitting(false)
    }
  }

  const handleMergeConfirmation = () => {
    // Check if master product is selected
    if (!masterProductId) {
      alert(
        `Please select a master product.`
      )
    }
    // Else we have a selected master product and we can proceed with the merge
    else {
      const selectedProduct = products.find((obj) => obj.id === masterProductId);
      const proceed = confirm(`You have selected ${selectedProduct.name} as the master product. Are you sure you want to merge the selected products?`)
      // Close the modal
      if (proceed) {
        onSave({}, { setSubmitting: () => {} })
        setModalOpen(false)
      }
    }

  }

  const renderSelectMasterProductForm = () => {
    const filteredProducts = products.filter((product) => selectedRowIds.includes(product.id));
    return (
      <Modal open={modalOpen} onClose={() => setModalOpen(false)} title="Select Master Product" noPadding>
        <div style={{ padding: 20 }}>
          <RadioGroup value={masterProductId} onChange={handleMasterProductSelection}>
            {filteredProducts.map((product) => (
              <FormControlLabel
                key={product.id}
                value={product.id}
                control={<Radio />}
                label={product.name}
              />
            ))}
          </RadioGroup>
        </div>
        <Button variant="contained" size="large" color="primary" onClick={handleMergeConfirmation} style={{ borderRadius: 0 }}>
          Merge
        </Button>
      </Modal>
    )
  }

  const renderAddButton = () => {
    return (
      <Button aria-label="add" color="primary" variant='contained' onClick={onAddProduct}>
        Add
      </Button>
    )
  }


  return (
    <>
      <Grid container spacing={ 2 } justifyContent="space-between" style={ { padding: '5px 10px' } } alignItems="center">
        <Grid item>{ renderAddButton() }</Grid>
        <Grid item style={ { marginRight: 50 } }>
          { !showSelect && <Button onClick={ () => setShowSelect( true ) } size="small" color="primary">Select rows</Button> }
          { showSelect && selectedRowIds.length > 0 && renderAdditionalActions() }
        </Grid>
      </Grid>
      <CommonTable
        onAddRow={ onAddProduct }
        onEditRow={ onEditProduct }
        selectedRowIds={ selectedRowIds }
        updateSelectedRowIds={ setSelectedRowIds }
        is_loading={ is_loading }
        columns={ columns }
        rows={ products }
        item_list={ adminProductList }
        enableAnyFieldFilter={ true }
        rowClickRedirect
        renderSelect={ showSelect }
        useColumnsSelector={ true }
        storeTableName={ "adminProductList" }
      />
      { modalOpen && renderSelectMasterProductForm() }
    </>
  )
}