import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useHistory, useParams } from 'react-router-dom'
import clsx from 'clsx'
import { useSelector } from 'react-redux'
import { get, capitalize } from 'lodash'
import { adminParcelList } from './actions/admin_parcel'
import Icon from '@mdi/react'
import FormCard from 'components/layout/FormCard'
import { mdiContentDuplicate } from '@mdi/js'
import { Formik, Form } from 'formik'
import AdminMainLayout from '../layout/AdminMainLayout'
import AdminDonorParcelProducts from './AdminDonorParcelProducts'
import BusyMask from 'components/BusyMask'
import { AdminParcelStateHistory } from './AdminParcelStateHistory'
import { AdminParcelDriverHistory } from './AdminParcelDriverHistory'
import { AdminDriverAppLog } from './AdminDriverAppLog'
import * as Yup from 'yup'
import { showSuccess } from 'actions/Error'
import { handleSubmitResult } from 'actions/form'
import { Grid, Typography, Button, makeStyles } from '@material-ui/core'
import { Card } from 'components/layout/Card'
import { provinceList } from 'actions/province'
import { BasicMapWithMarker } from 'components/map/MapMarkers'
import Signature from 'components/Signature'
import {
	OrderStateForm,
	OrderDeliveryNoteForm,
	DonorGeneralOrderForm,
	OrderDriverForm,
	OrderBringgForm,
	OrderSection18A,
	OrderTotalOverrides
} from './form'
import AdminParcelTotalWeight from './AdminParcelTotalWeight'
import { AdminDonorCreateBeneficiaryParcel } from './AdminDonorCreateBeneficiaryParcel'
import moment from 'moment'

// Base schema
const baseValidationSchema = Yup.object().shape( {
	from_company: Yup.string().required( "Required" ),
	arrive_warehouse: Yup.string().required( "Required" ),
	depart_at: Yup.date()
		.typeError( 'Departure date is not valid' )
		.required( "Required" ),
	transport_by_warehouse_company: Yup.boolean(),
} )

// Function to dynamically extend the base schema based on form values
const getDynamicValidationSchema = ( values ) => {
	let schema = baseValidationSchema
	if ( values.transport_by_warehouse_company ) {
		schema = schema.concat( Yup.object().shape( {
			depart_address: Yup.string().required( "Required" ),
		} ) )
	}
	return schema
}

const useStyles = makeStyles( ( theme ) => ( {
	root: {
		display: 'flex',
	},
	card: {
		display: 'flex',
		overflow: 'auto',
		flexDirection: 'column',
	},
	fixedHeight: {
		height: 240,
	},
	cardHeading: {
		padding: "5px 10px",
	},
	titleSubHeading: {
		textTransform: 'capitalize'
	},
	customerWillTransportHeading: {
		textAlign: 'center',
		color: theme.palette.secondary.main,
		padding: '30px 0',
		fontSize: 24,
		textTransform: 'capitalize'
	},
	infoBox: {
		border: '1px solid #cccccc',
		borderRadius: 8,
		padding: 15,
		marginBottom: 15
	},
	alertMargin: {
		marginBottom: 10
	},
	alert: {
		marginBottom: 10
	},
} ) )

export default function AdminDonorParcel( { match, newOrder } ) {
	const classes = useStyles()
	const dispatch = useDispatch()
	let { parcel_id } = useParams()
	let { transport_action } = useParams()
	const companyType = 'donor'
	let customer = companyType
	const direction = companyType + '_' + transport_action
	const parcel = useSelector( () => adminParcelList.getObject( parcel_id ) )
	if ( parcel_id ) {
		transport_action = get( parcel, [ "transport_by_warehouse_company" ] ) ? 'pickup' : 'delivery'
	}
	const is_loading = parcel_id && !get( parcel, "id" )
	const is_busy = useSelector( () => adminParcelList.getIsSavingObject() )
	const is_new = !parcel_id && newOrder
	const initial_values = direction == 'donor_pickup' ? Object.assign( { status: 'pending', transport_by_warehouse_company: true }, parcel ) : Object.assign( { status: 'pending', transport_by_warehouse_company: false }, parcel )
	let mapLat = ''
	let mapLng = ''
	let parcel_weight = get( parcel, "total_weight_kg" )
	const history = useHistory()
	const [ createBeneficiaryParcelActive, setCreateBeneficiaryParcelActive ] = useState( false )
	const [ totalParcelWeight, setTotalParcelWeight ] = useState( parcel_weight )

	useEffect( () => {
		dispatch( adminParcelList.ensureObjectLoaded( parcel_id ) )
		dispatch( adminParcelList.fetchListIfNeeded() )
		dispatch( provinceList.updatePaginationNumItemsPerPage( 1000 ) )
		dispatch( provinceList.fetchListIfNeeded() )
	}, [ parcel_id ] )

	useEffect( () => {
		dispatch( adminParcelList.ensureObjectLoaded( parcel_id ) )
		dispatch( adminParcelList.fetchListIfNeeded() )
	}, [ parcel ] )

	const onSave = ( values, formik_funcs ) => {
		const touchedFields = Object.keys( values ).reduce( ( acc, key ) => {
			acc[ key ] = true
			return acc
		}, {} )
		formik_funcs.setTouched( touchedFields )
		const on_ok = function( json ) {
			dispatch( adminParcelList.invalidateList() )
			showSuccess( "Saved", "Donor " + transport_action + " saved" )

			if ( !parcel_id ) {
				history.push( `/admin/orders/donor/${ json.id }` )
			}
		}
		values.direction = 'donor_pickup'
		if ( parcel_id ) {
			values.id = parcel_id
			return dispatch( adminParcelList.saveObject( values ) ).then( ( res ) => handleSubmitResult( { res, formik_funcs, on_ok } ) )
		} else {
			setTotalParcelWeight
			return dispatch( adminParcelList.saveNewObject( values ) ).then( ( res ) => handleSubmitResult( { res, formik_funcs, on_ok } ) )
		}
	}

	const updateCartTotalWeight = ( updated_parcel_weight ) => {
		setTotalParcelWeight( updated_parcel_weight )
	}

	const onStartCreateBeneficiaryParcel = () => {
		setCreateBeneficiaryParcelActive( true )
	}

	const onStopCreateBeneficiaryParcel = () => {
		setCreateBeneficiaryParcelActive( false )
	}

	const renderCreateBeneficiaryParcel = () => {
		return (
			<AdminDonorCreateBeneficiaryParcel
				donor_parcel_id={ parcel_id }
				onClose={ onStopCreateBeneficiaryParcel }
			/>
		)
	}

	const renderCreateBeneficiaryParcelLink = () => {
		return ( totalParcelWeight > 0 &&
			<Grid container>
				<Grid item>
					<Button onClick={ onStartCreateBeneficiaryParcel } size="small">
						<Icon path={ mdiContentDuplicate } size={ 1 } color="#777777" style={ { marginRight: 7 } } /> Create Beneficiary Order
					</Button>
				</Grid>
			</Grid>
		)
	}

	const renderDonorGeneralForm = ( formik_props ) => {
		const is_open = !get( parcel, "id" )
		const order_title = formik_props.values.from_company_name ? `${ formik_props.values.from_company_name }: ${ moment( formik_props.values.depart_at ).format( 'dddd, D MMMM YYYY, h:mm a' ) } ` : `New Donor ${ capitalize( transport_action ) }`
		return (
			<Card
				title={ order_title }
				is_open={ is_open }
				content={ <DonorGeneralOrderForm formik_props={ formik_props } /> }
			/>
		)
	}

	const renderParcelProducts = () => {
		return (
			<Card
				title="Products"
				content={
					<>
						<AdminParcelTotalWeight total_parcel_weight={ totalParcelWeight } />
						<AdminDonorParcelProducts parcel_id={ parcel_id } updateCartTotalWeight={ updateCartTotalWeight } />
					</>
				}
				noPadding
			/>
		)
	}

	const renderTotalOverrides = ( formik_props ) => {
		return (
			<Card
				title="Override Totals"
				content={ <OrderTotalOverrides formik_props={ formik_props } /> }
				is_open={ false }
			/>
		)
	}

	const renderSection18A = ( formik_props ) => {
		return (
			<Card
				title="Section 18A"
				content={ <OrderSection18A formik_props={ formik_props } parcel={ parcel } /> }
				is_open={ false }
			/>
		)
	}

	const renderTransportAction = ( formik_props ) => {
		return (
			<Card
				title="Transport"
				content={
					<Typography
						variant="h6"
						className={ classes.customerWillTransportHeading }
					>
						{ customer } { transport_action }
					</Typography>
				}
				noPadding
			/>
		)
	}

	const renderOrderStatus = ( formik_props ) => {
		let order_status
		if ( formik_props.values.status == 'complete' ) { order_status = 'success' }
		if ( formik_props.values.status == 'order_cancelled' ) { order_status = 'error' }
		return (
			<Card
				title="Status"
				status={ order_status }
				content={ <OrderStateForm formik_props={ formik_props } parcel={ parcel } /> }
				card_actions={ <AdminParcelStateHistory parcel_id={ parcel_id } title="Status History" /> }
			/>
		)
	}

	const renderAddressMap = ( formik_props ) => {
		return (
			<>
				{ mapLat && mapLng &&
					<Card
						title="Address on Map"
						content={ <BasicMapWithMarker lat={ mapLat } lng={ mapLng } height={ 300 } showDefaultUI /> }
					/>
				}
			</>
		)
	}

	const renderDriverForm = ( formik_props ) => {
		const driver_status = formik_props.values.driver_name ? 'success' : 'error'
		return (
			<Card
				title="Driver"
				status={ driver_status }
				content={ <OrderDriverForm formik_props={ formik_props } /> }
				card_actions={ <><AdminParcelDriverHistory parcel_id={ parcel_id } title="Driver History" /> <AdminDriverAppLog parcel_id={ parcel_id } /></> }
			/>
		)
	}

	const renderBringgForm = ( formik_props ) => {
		return (
			<OrderBringgForm formik_props={ formik_props } />
		)
	}

	const renderDriverAppLog = ( formik_props ) => {
		return (
			<Card
				title="Driver App Log"
				content={ <AdminDriverAppLog parcel_id={ parcel_id } show_as_table /> }
				noPadding
				is_open={ false }
			/>
		)
	}

	const renderDeliveryNoteForm = ( formik_props ) => {
		const delivery_notes_status = get( formik_props.values.delivery_notes, [ 'id' ] ) ? 'success' : 'error'
		return (
			<Card
				title="Delivery Note"
				status={ delivery_notes_status }
				content={ <OrderDeliveryNoteForm formik_props={ formik_props } /> }
			/>
		)
	}

	const renderDonorSignature = ( formik_props ) => {
		const from_signature_status = formik_props.values.from_signature ? 'success' : 'error'
		return (
			<Card
				title="Donor Signature"
				status={ from_signature_status }
				content={ <Signature name="from_signature" formik_props={ formik_props } /> }
			/>
		)
	}

	const dynamicValidate = ( values ) => {
		const schema = values.transport_by_warehouse_company ? getDynamicValidationSchema( values ) : baseValidationSchema

		return schema.validate( values, { abortEarly: false } )
			.then( () => ( {} ) ) // Return an empty object for valid form
			.catch( ( err ) => {
				let errors = {}
				err.inner.forEach( ( { path, message } ) => {
					errors[ path ] = message
				} )
				return errors // Return errors object for Formik
			} )
	}

	return (
		<AdminMainLayout
			title={ `${ capitalize( customer ) } ${ capitalize( transport_action ) } / Order ID: ${ get( parcel, [ "short_ref" ], 'New order' ) }` }
			breadcrumbs={ [
				{ name: 'admin_home' },
				{ name: 'prenderBringgFormarcels', label: 'Orders', url: '/admin/parcels' },
				{ name: 'parcel', label: `${ capitalize( customer ) } ${ capitalize( transport_action ) }: ${ get( parcel, [ "short_ref" ], 'New' ) }`, url: `/admin/orders/donor/${ parcel_id }` }
			] }
		>
			{ parcel_id && createBeneficiaryParcelActive && renderCreateBeneficiaryParcel() }

			{ !is_loading &&
				<Formik
					initialValues={ initial_values }
					onSubmit={ onSave }
					enableReinitialize={ true }
					validate={ dynamicValidate }
				>
					{ formik_props => {
						const { values } = formik_props
						const transport_by_warehouse_company = get( formik_props.values, "transport_by_warehouse_company" )

						return (
							<Form>
								<FormCard
									isSubmitting={ formik_props.isSubmitting }
									variant="outlined"
									positionFixed
									noCard
								>
									<Grid container spacing={ 1 } justify="center">

										<Grid item xs={ 12 } md={ 12 } lg={ 12 }>
											{ is_busy && <BusyMask /> }
											{ is_loading && <BusyMask /> }
											{ parcel_id && renderCreateBeneficiaryParcelLink() }
										</Grid>

										<Grid item xs={ 12 } md={ 7 } lg={ 8 }>
											{ !is_loading &&
												renderDonorGeneralForm( formik_props )
											}
											{ !is_new &&
												<>
													{ renderParcelProducts() }
													{ renderDriverAppLog( formik_props ) }
													{ renderTotalOverrides( formik_props ) }
													{ renderSection18A( formik_props ) }
												</>
											}
										</Grid>

										{ !is_new &&

											<Grid item xs={ 12 } md={ 5 } lg={ 4 }>
												{ renderOrderStatus( formik_props ) }
												{ !transport_by_warehouse_company &&
													renderTransportAction( formik_props )
												}
												{ renderAddressMap( formik_props ) }
												{ transport_by_warehouse_company &&
													<>
														{ renderDriverForm( formik_props ) }
														{ renderBringgForm( formik_props ) }

													</>
												}
												{ renderDeliveryNoteForm( formik_props ) }
												{ renderDonorSignature( formik_props ) }
											</Grid>

										}

									</Grid>
								</FormCard>
							</Form>
						)
					} }
				</Formik>
			}
		</AdminMainLayout>
	)
}
