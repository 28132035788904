import { get } from 'lodash'

export const setStoredVisibleTableColumns = (table_name, values) => {
  if(table_name && values) {
    localStorage.setItem(table_name, JSON.stringify(values))
  }

}

export const getStoredVisibleTableColumns = (table_name) => {
  let storedTable = null
  if(table_name) {
    storedTable = JSON.parse(localStorage.getItem(table_name))
  }
  return storedTable
}
