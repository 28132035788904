import { createTheme }  from '@material-ui/core/styles'
const marketingTheme = createTheme({
    palette: {
        primary: { main: '#1e88e5' },
        secondary: { main: '#FADF01' },
        info: { main: '#1890FF' },
        success: { main: '#00AB55' },
        warning: { main: '#FFC107' },
        error: { main: '#FE0000' },
    },
    overrides: {
        MuiCard: {
            root: {
                marginBottom: '10px'
            },
            elevation: 0
        },
    },
    props: {
        MuiTextField: {
            variant: 'outlined',
        },
    },
})
export default marketingTheme

export const PARCEL_STATUS_COLOURS = {
    ready_to_go: "#fff9c4",
    picking: "#eceff1",
    in_transit: "#bbdefb",
    complete: "#c8e6c9",
    cancelled: "#ef9a9a",
    pending: "#bbdefb",
}

export const TICKET_STATUS_COLOURS = {
    new: '#ffeb3b',
    unsolved: '#d50000',
    pending: '#64b5f6',
    solved: '#32cb00',
    suspended: '#ec407a',
    deleted: '#cccccc',
}
