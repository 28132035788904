import useSWR, { mutate } from 'swr'
import axios, { fetcher, convertObjectToQueryParams } from 'utils/axios'

export function useGetWarehouseProductsSummary( query ) {
  // console.log(query)
  const params = convertObjectToQueryParams( query )
  const { data, error, mutate } = useSWR( `/admin/warehouse_product/summary/?${ params }`, fetcher )
  return {
    data: data || null,
    is_loading: !data && !error,
    error,
    mutate
  }
}