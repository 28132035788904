import React, { useEffect, useState } from 'react'
import { Field, useField, FieldArray } from 'formik'
import { useDispatch, useSelector } from 'react-redux'
import { map, get, size, split } from 'lodash'
import AdminCommonListTable from '../layout/AdminCommonListTable'
import AdminAddressForm from './form/AdminAddressForm'
import { Separator } from 'components/layout/Separator'
import {showSuccess, showError} from 'actions/Error'
import { handleSubmitResult } from 'actions/form'
import { IconButton, Button, makeStyles, Tooltip, Grid, Typography, Chip } from '@material-ui/core'
import { GrayLinkButton } from 'components/layout/GrayLinkButton'
import DeleteIcon from '@material-ui/icons/Delete'
import AddIcon from '@material-ui/icons/Add'
import { adminAddressList } from 'admin/components/addresses/actions/admin_address'
import { Modal } from 'components/layout/Modal'
import { BasicMapWithMarker } from  'components/map/MapMarkers'

const NEW_ADDRESS_ID = "__new_address__"

const useStyles = makeStyles((theme) => ({
  addButton: {
    marginBottom: 15,
  },
  noAddresssButton: {
    marginTop: 10
  },
  deleteButton: {
    float:'right',
  },
  addEditAddress: {
    color: '#999999'
  }
}))

export const AdminAddresses = ({formik_props, newAddressInitialValues, name, inTabs}) => {
    const classes = useStyles()
    const dispatch = useDispatch()
    const addresses = useSelector(() => adminAddressList.getVisibleObjects())
    const is_loading = useSelector(() => !adminAddressList.isReady() && adminAddressList.isLoading())
    const [editingAddressId, setEditingAddressId] = useState(null)
    const [addEditLabel, setAddEditLabel] = useState("New Address")

    useEffect(() => {
        dispatch(adminAddressList.updateListFilter(newAddressInitialValues))
        dispatch(adminAddressList.fetchListIfNeeded())
    }, [JSON.stringify(newAddressInitialValues)])

    const onAddAddress = () => {
        setEditingAddressId(NEW_ADDRESS_ID)
    }

    const onEditAddress = (address_id) => {
        setAddEditLabel(`Editing ${address_id}`)
        setEditingAddressId(address_id)
    }

    const onStopEditingAddress = () => {
        dispatch(adminAddressList.invalidateList())
        dispatch(adminAddressList.fetchListIfNeeded())
        setEditingAddressId(null)
    }

    const onRemoveAddress = (address) => {

        if (! window.confirm("Are you sure you want to delete this address?") ) {
            return
        }

        const onDone = (res) => {
            if ( res.errors ) {
                showError("Failed", "Address is in use and could not be deleted")
            } else {
                showSuccess("Deleted", "Address deleted")
                dispatch(adminAddressList.invalidateList())
                dispatch(adminAddressList.fetchListIfNeeded())
            }
        }

        dispatch(adminAddressList.deleteObject(address.id)).then(onDone)
    }

    const onMakeDefaultAddress = (address_id) => {
      alert("This address must be saved as the only default address. All other addresses should be saved as is_default: false")
    }

    const columns = [
      {
        field: 'formatted_address',
        title: 'Address',
        render: (item) =>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <Typography>
              <Typography variant="h5">{get(item, "formatted_address")}</Typography>
              <div style={{marginBottom: 10, marginTop: 10}}>
              {get(item, "is_default") ?
                <Chip label="Default Address" style={{background: 'green', color: 'white'}} /> :
                <Button
                  onClick={() => onMakeDefaultAddress(item.id)}
                  size="small"
                  variant="outlined">Make Default Address</Button>
              }</div>

              City: {get(item, "city_name")}<br />
              Province: {get(item, "province_name")}<br />
              Latitude: {get(item, "latitude")}<br />
              Longitude: {get(item, "longitude")}<br />

             </Typography>
          </Grid>
          <Grid item xs={12} md={6}>
            <BasicMapWithMarker lat={get(item, "latitude")} lng={get(item, "longitude")} width={'100%'} height={300} showDefaultUI />
          </Grid>

        </Grid>

      },
    ]

    return (
        <>
            <AdminCommonListTable
                add_label="Add Address"
                title="Address"
                onAddRow={onAddAddress}
                onEditRow={onEditAddress}
                is_loading={is_loading}
                handleRequestSort={columns}
                columns={columns}
                item_list={adminAddressList}
                noHeadings
                inTabs
            />
            { editingAddressId &&
                <Modal title={addEditLabel} onClose={onStopEditingAddress}>
                    <AdminAddressForm
                        newAddressInitialValues={newAddressInitialValues}
                        address_id={editingAddressId === NEW_ADDRESS_ID ? null : editingAddressId}
                        onDone={onStopEditingAddress}
                    />
                </Modal>
            }
        </>
    )

}
