import React, { Component, useEffect } from 'react'
import { get } from 'lodash'
import { useDispatch } from 'react-redux'
import { adminParcelStates } from 'admin/actions/admin_dropdown_options'
import { PARCEL_STATUS_COLOURS } from 'muiTheme'
import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
  label: {
      padding: "5px 7px",
      borderRadius: "4px",
      color: 'white',
      textTransform: 'uppercase',
      fontSize: 12,
      width: '100px',
      textAlign: 'center'
  },
}))

export const ParcelStatus = ({ status, ...props }) => {
    const classes = useStyles()

    const dispatch = useDispatch()

    useEffect(() => {
        async function fetchStaticObjects() {
            dispatch(adminParcelStates.ensureObjectLoaded(status))
        }
        fetchStaticObjects()
    }, [])

    const status_obj = adminParcelStates.getObject(status)
    const label = get(status_obj, "name", "")
    const colour = get(PARCEL_STATUS_COLOURS, get(status_obj, "id"), "#ffffff")

    return (
        <div className={classes.label} style={{backgroundColor: colour}}>
          {label}
        </div>
    )

}
