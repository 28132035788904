import { ItemList } from 'orm'
import { get, map, head, filter } from 'lodash'
import { download } from 'actions/lib'

class KpiCalculationMomentTypesList extends ItemList {
    getEntityKey() {
        return "admin/dropdown_options/kpi_calculation_moment_types"
    }

    getAsOptions() {
        const items = this.getVisibleObjects()
        return map(items, function(item) { return {label: item.name,
                                                   value: item.id}})
    }
}

export const admiKpiCalculationMomentTypeList = new KpiCalculationMomentTypesList("admin_kpi_calculation_moment_types__default")
export const adminKpiCalculationMomentTypesForDropdownList = new KpiCalculationMomentTypesList("admin_kpi_calculation_moment_types_for_dropdown__default")
