import * as Yup from 'yup'
import { get } from 'lodash'
import { FormikInputField } from 'components/form/InputField'
import { FormikTextareaField } from 'components/form/TextareaField'
import { FormikFileSetField } from 'components/form/FileSetField'
import { Separator } from 'components/layout/Separator'
import { FormikNutritionFoodItemAutoCompleteDropdown } from 'admin/components/nutrition/form/NutritionFoodItemAutoCompleteDropdown'
import { FormikNutrientAutoCompleteDropdown } from 'admin/components/nutrition/form/NutrientAutoCompleteDropdown'
import { Typography } from "@material-ui/core"

export const validationSchema = Yup.object().shape({
    'food_item': Yup.string().required("Required"),
    'nutrient': Yup.string().required("Required"),
})

export const AdminNutritionCardForm = ({ formik_props, food_item }) => {

  console.log(formik_props.values);

  return (
    <>
      {! get(food_item, "id") ?
        <FormikNutritionFoodItemAutoCompleteDropdown
          name="food_item"
          label="Food Item"
          formik_props={formik_props}
        /> :
        <Typography style={{color: '#666'}} variant="caption">{ get(food_item, "name") }</Typography>
      }
      <FormikNutrientAutoCompleteDropdown
        name="nutrient"
        label="Nutrient"
        formik_props={formik_props}
      />
      <FormikInputField
        name="nutrient_value"
        label="Nutrient value/100g serving"
        formik_props={formik_props}
      />
    </>
  )

}
