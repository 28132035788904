import React, { useState, useEffect } from 'react'
import { map, get, capitalize, size, union, values } from 'lodash'
import { useDispatch, useSelector } from 'react-redux'
import MarketingMainLayout from './layout/MarketingMainLayout'
import { CommonTable } from 'components/CommonTable'
import {
  makeStyles,
  Switch,
  Grid,
  IconButton,
  Typography,
  FormControlLabel,
  Tooltip
}
  from '@material-ui/core'
import HelpOutlineIcon from '@material-ui/icons/HelpOutline'
import Tour from 'reactour'
import { MapWithMarkerClusterer, MapWithMarkers } from 'components/map/MapMarkers'
import { featured_organisations } from 'sampleData/sampleData'
import { getShowTourCookie, setTourCookie } from 'portals/marketing/actions/marketing_cookies'
import { FormikMultiSelectDropdownField } from 'components/form/Dropdown'
import { companyTypes } from 'actions/marketing_dropdown_options'
import { Formik, Form } from 'formik'
import FormikOnChange from 'components/form/FormikAutoSave'
import { FormikGeneralFormErrors } from 'components/form/GeneralFormErrors'
import { FormikInputField } from 'components/form/InputField'
import { CompaniesListFilterAndResultsDrawer, MapSettingsAndLegendDrawer } from './drawers/MapDrawers'
import { marketingProvinceList } from './actions/marketing_province'
import { marketingCoreBusinessList } from 'actions/marketing_core_business'
import { contactWarehouseList } from 'portals/donor/components/actions/contact_warehouse'
import { marketingCompanyForMarketingList, marketingCompanyListForMap } from 'portals/marketing/actions/marketing_companies'

const useStyles = makeStyles( ( theme ) => ( {
  container: {
    padding: 7,
  },
  mapContainer: {
    position: 'relative'
  },
  searchInput: {
    textIndent: 15,
    marginBottom: 12,
  },
  selectInput: {
    marginBottom: 12,
    textIndent: 15
  },
  beneficiariesList: {
    height: '85vh',
    borderRadius: 0,
    overflow: 'auto'
  },
  loadingDiv: {
    color: '#999999',
    textAlign: 'center'
  },
  listItem: {
    minHeight: 100,
    padding: 10,
    '&:hover': {
      background: '#fafafa',
      cursor: 'pointer',
    },
    borderBottom: '1px solid #efefef'
  },
  listNumber: {
    border: '1px solid #cfd8dc',
    borderRadius: '50%',
    width: 20,
    color: '#cfd8dc',
    textAlign: 'center',
    fontSize: 14,
    float: 'right',
    marginRight: 7
  },
  input: {
    background: '#ffffff',
  },
  searchButton: {
    width: '100%',
    height: '100%'
  },
  loadMoreButton: {
    marginTop: 15,
    marginBottom: 15,
    textAlign: 'center'
  },
  numRecords: {
    textAlign: 'center',
    padding: 12,
    color: '#999999',
    fontSize: 12
  },
  settingsPaper: {
    paddingLeft: 15,
    position: 'absolute',
    top: 125,
    zIndex: 999
  },
  filterBox: {
    padding: 15,
  },
  legendTitle: {
    fontSize: 24,
    padding: 15,
    marginBottom: 10,
    marginTop: 10,
    borderTop: '1px solid #000000',
    borderBottom: '1px solid #000000',
  },
  legendIcon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'left',
    fontSize: 18,
    '& img': {
      marginRight: 15,
      marginBottom: 7
    }
  },
  featuredOrgHeading: {
    padding: '7px 15px',
    background: '#546e7a',
    width: '100%',
    color: '#ffffff',
  },
  media: {
    height: 250,
  },
  menuSectionHeading: {
    marginLeft: 10,
    marginTop: 7
  },
  companyName: {
    textTransform: 'capitalize',
    fontWeight: 500
  },
  companyText: {
    textTransform: 'capitalize',
    display: 'block'
  },
  mapDrawerButtons: {
    position: 'absolute',
    backgroundColor: 'rgba(255,255,255,1)',
    zIndex: 1,
    background: '#ffffff',
    color: "#222222",
    right: 9,
    borderRadius: 4,
    padding: 4,
    boxShadow: '0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24)',
    transition: 'all 0.3s cubic-bezier(.25,.8,.25,1)',
    '&:hover': {
      backgroundColor: 'rgba(255,255,255,0.5)',
    }
  },
  helpButton: {
    top: 240,
  },
} ) )

const MAP_MARKER_OPTIONS = [
  { company_type: 'beneficiary', icon: '/static/images/map/icons/beneficiary-icon.png' },
  { company_type: 'donor', icon: '/static/images/map/icons/donor-icon.png' },
  { company_type: 'warehouse', icon: '/static/images/map/icons/warehouse-icon.png' },
]

const tourSteps = [
  {
    selector: '[data-tour="intro"]',
    content: 'Welcome to the SA Harvest impact map. Here you will find all the donor partners and beneficiary organisations that we work with. You can filter the organisations using the filter on the left. Click on a map icon to show information about that organisation and click through to their page for even more information, images, videos and more.'
  },
  {
    selector: '[data-tour="first-step"]',
    content: 'Click the Search button to filter organisations or search for a specific one'
  },
  {
    selector: '[data-tour="second-step"]',
    content: 'Click the Legend and Settings button to view the map legend or change map settings.'
  },
  {
    selector: '[data-tour="third-step"]',
    content: 'Click the help button to start this tour at any time.'
  },
  {
    selector: '[data-tour="fourth-step"]',
    content: 'Click any icon on the map to find out more about that organisation.'
  },
]

export default function ImpactMap() {
  const classes = useStyles()
  const dispatch = useDispatch()
  const tourName = 'impact-map-tour'
  const showTour = getShowTourCookie( tourName )
  const [ isTourOpen, setIsTourOpen ] = useState( showTour )
  const featuredOrgs = featured_organisations
  const [ clusterMarkers, setClusterMarkers ] = useState( true )
  const [ checked, setChecked ] = React.useState( false )
  const companies = useSelector( () => marketingCompanyForMarketingList.getVisibleObjects() )
  const warehouses = useSelector( () => contactWarehouseList.getVisibleObjects() )
  const companies_for_map = useSelector( () => marketingCompanyListForMap.getVisibleObjects() )
  const [ markers, setMarkers ] = useState( null )
  const is_loading = useSelector( () => marketingCompanyForMarketingList.isLoading() )
  const map_is_loading = useSelector( () => marketingCompanyListForMap.isLoading() )
  const filter = useSelector( () => marketingCompanyForMarketingList.getFilter() )
  const initial_values = Object.assign( {},
    {
      provinces: [],
      company_types: [],
      core_businesses: []
    },
    filter )

  const provinceOptions = useSelector( () => marketingProvinceList.getAsOptions() )
  const companyTypeOptions = useSelector( () => companyTypes.getAsOptions() )
  const coreBusinessOptions = useSelector( () => marketingCoreBusinessList.getAsOptions() )
  let in_iframe = false

  useEffect( () => {
    async function fetchStaticObjects() {
      dispatch( marketingCompanyForMarketingList.updatePaginationNumItemsPerPage( 10 ) )
      dispatch( marketingCompanyListForMap.updatePaginationNumItemsPerPage( 1000 ) )
      dispatch( marketingCompanyForMarketingList.fetchListIfNeeded() )
      dispatch( marketingCompanyListForMap.fetchListIfNeeded() )
      dispatch( marketingProvinceList.fetchListIfNeeded() )
      dispatch( companyTypes.fetchListIfNeeded() )
      dispatch( contactWarehouseList.fetchListIfNeeded() )
      dispatch( marketingCoreBusinessList.updatePaginationNumItemsPerPage( 100 ) )
      dispatch( marketingCoreBusinessList.fetchListIfNeeded() )
    }
    fetchStaticObjects()
  }, [] )

  useEffect( () => {
    setMarkers( union(
      map( values( companies_for_map ), function( company ) {
        return {
          marker_type: company.company_type,
          ...company
        }
      } ),
      map( values( warehouses ), function( warehouse ) {
        return {
          marker_type: "warehouse",
          ...warehouse
        }
      } )
    ) )
  }, [ size( companies_for_map ), size( warehouses ) ] )

  const checkUrlParams = () => {

    const url_filter = new window.URLSearchParams( window.location.search )
    const url_params = {}
    url_params.iframe = url_filter.get( 'iframe' ) || null
    if ( url_params.iframe ) { in_iframe = true }
    return in_iframe
  }

  const handleSettingsSliderChange = () => {
    setChecked( ( prev ) => !prev )
  }

  const handleClusterMarkersChange = ( event ) => {
    setClusterMarkers( event.target.checked )
  }

  const onFilterChanged = ( values, formik_props ) => {
    dispatch( marketingCompanyForMarketingList.updateListFilter( values ) )
    dispatch( marketingCompanyForMarketingList.fetchListIfNeeded() )
    dispatch( marketingCompanyListForMap.updateListFilter( values ) )
    dispatch( marketingCompanyListForMap.fetchListIfNeeded() )
  }

  const columns = [
    {
      field: 'name',
      title: '',
      render: ( item ) => <Typography className={ classes.companyName }>{ item.name }</Typography>,
      redirect: ( item ) => `/impact/${ item.company_type }/${ item.id }`,
      sort: ( direction ) => onUpdateListOrdering( direction === 'asc' ? 'name' : '-name' ),
    },
    {
      field: 'company_type',
      title: '',
      render: ( item ) => <Typography variant="caption" className={ classes.companyText }>{ item.company_type } Organisation</Typography>,
      sort: ( direction ) => onUpdateListOrdering( direction === 'asc' ? 'company_type' : '-company_type' ),
    },
    {
      field: 'city_name',
      title: 'City',
      render: ( item ) => <Typography variant="caption" className={ classes.companyText }>{ item.city_name }</Typography>,
      sort: ( direction ) => onUpdateListOrdering( direction === 'asc' ? 'addresses__city__name' : '-addresses__city__name' )
    },
    {
      field: 'province',
      title: 'Province',
      render: ( item ) => <Typography variant="caption" className={ classes.companyText }>{ item.province }</Typography>,
      sort: ( direction ) => onUpdateListOrdering( direction === 'asc' ? 'province' : '-province' ),
    },
  ]

  const renderFilter = () => {
    return (
      <Formik
        initialValues={ initial_values }
        onSubmit={ onFilterChanged }
        enableReinitialize={ true }
      >
        { formik_props => {
          const { values } = formik_props
          return (
            <Form>
              <FormikOnChange onChange={ ( values ) => onFilterChanged( values, formik_props ) } />
              <FormikGeneralFormErrors render={ ( msg ) => msg } />
              <FormikInputField name="name"
                label="Search by organisation name..."
                show_search_icon={ true }
                formik_props={ formik_props }
                variant="standard"
                className={ classes.searchInput }
                InputProps={ {
                  style: { paddingLeft: 15 },
                } }
              />
              <FormikMultiSelectDropdownField label="Provinces"
                name="provinces"
                options={ provinceOptions }
                formik_props={ formik_props }
                className={ classes.selectInput }
                variant="standard"
              />
              <FormikMultiSelectDropdownField label="Company type"
                name="company_types"
                options={ companyTypeOptions }
                formik_props={ formik_props }
                className={ classes.selectInput }
                variant="standard"
              />
              <FormikMultiSelectDropdownField label="Core business"
                name="core_businesses"
                options={ coreBusinessOptions }
                formik_props={ formik_props }
                className={ classes.selectInput }
                variant="standard"
              />
            </Form>
          )
        } }
      </Formik>
    )
  }

  const renderMap = ( in_iframe ) => {
    let height = '90vh'
    height = in_iframe ? '100vh' : height
    return (
      <>
        <Grid item xs={ 12 } className={ classes.mapContainer }>
          { clusterMarkers ?
            <MapWithMarkerClusterer markers={ markers } width={ '100%' } height={ height } zoom={ 6 } is_loading={ map_is_loading } /> :
            <MapWithMarkers markers={ markers } width={ '100%' } height={ height } zoom={ 6 } is_loading={ map_is_loading } />
          }
        </Grid>
        <Tooltip title={ "Show help tour" } arrow placement="left">
          <IconButton
            aria-label="show tour"
            onClick={ () => setIsTourOpen( true ) }
            edge="start"
            variant="contained"
            size="large"
            data-tour="third-step"
            className={ [ classes.helpButton, classes.mapDrawerButtons ] }
          >
            <HelpOutlineIcon fontSize="large" />
          </IconButton>
        </Tooltip>
      </>
    )
  }

  const renderSettings = () => {
    return (
      <>
        <div className={ classes.filterBox }>
          { map( MAP_MARKER_OPTIONS, function( option ) {
            return (
              <div class={ classes.legendIcon }><img src={ get( option, [ "icon" ] ) } />{ capitalize( get( option, [ "company_type" ] ) ) }</div>
            )
          } ) }
        </div>
        <Typography variant="h6" className={ classes.legendTitle }>Settings</Typography>
        <div className={ classes.filterBox }>
          <FormControlLabel
            control={ <Switch
              checked={ clusterMarkers }
              onChange={ handleClusterMarkersChange }
              name="clusterMarkers"
              inputProps={ { 'aria-label': 'Cluster Markers Switch' } }
            /> }
            label="Cluster Markers"
          />
        </div>
      </>
    )
  }

  const renderFilterAndResultsList = () => {
    return (
      <>
        <>
          <div className={ classes.filterBox }>
            { renderFilter() }
          </div>
          <div className={ classes.filterBox }>
            <Typography variant="h5" color="primary">Organisations</Typography>
          </div>
          <CommonTable
            rows={ companies }
            columns={ columns }
            item_list={ marketingCompanyForMarketingList }
            mobile={ true }
            rowClickRedirect={ true }
            showActions={ false }
            is_loading={ is_loading }
          />

        </>
      </>
    )
  }

  const renderFeaturedStories = () => {
    // return (
    //   <>
    //     <Typography variant="h4" className={ classes.featuredOrgHeading }>Featured Organisations</Typography>
    //     <Grid container spacing={ 1 } className={ classes.container }>

    //       { featuredOrgs &&
    //         <>
    //           { featuredOrgs.map( story => (
    //             <Grid item xs={ 12 } lg={ 3 }><FeaturedStory story={ story } /></Grid>
    //           ) ) }
    //         </>
    //       }
    //     </Grid>
    //   </>
    // )
  }

  return (
    <>
      <MarketingMainLayout
        active_key="beneficiaries_map"
        in_iframe={ checkUrlParams() }
        title="Impact Map"
        breadcrumbs={ undefined }
      >

        <CompaniesListFilterAndResultsDrawer content={ renderFilterAndResultsList() } title={ "Filter Organisations" } />
        <MapSettingsAndLegendDrawer content={ renderSettings() } title={ "Map Legend" } />

        <Grid container>

          { renderMap( checkUrlParams() ) }

          { false && renderFeaturedStories() }

        </Grid>

        <Tour
          steps={ tourSteps }
          isOpen={ isTourOpen }
          onRequestClose={ () => setIsTourOpen( false ) }
          closeWithMask
          onAfterOpen={ () => setTourCookie( tourName ) }
          showCloseButton
        />

      </MarketingMainLayout>
    </>
  )
}
