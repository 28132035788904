import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { get } from 'lodash'
import AdminCommonListLayout from '../layout/AdminCommonListLayout'
import { adminManufacturerList } from './actions/admin_manufacturer'

import Avatar from '@material-ui/core/Avatar'

export default function AdminManufacturers() {

  const dispatch = useDispatch()
  const history = useHistory()

  const manufacturers = useSelector(() => adminManufacturerList.getVisibleObjects())
  const is_loading = useSelector(() => adminManufacturerList.isLoading())
  const filter = useSelector(() => adminManufacturerList.getFilter())

  useEffect(() => {
    dispatch(adminManufacturerList.fetchListIfNeeded())
  },[])

  const onEditManufacturer = (manufacturer_id) => {
    history.push({
      pathname: '/admin/manufacturer/'+manufacturer_id
    })
  }

  const onAddManufacturer = () => {
    history.push({
      pathname: '/admin/manufacturer/'
    })
  }

  const onUpdateListOrdering = (field) => {
    dispatch(adminManufacturerList.updateListOrdering(field))
  }

  const columns = [
    { field: 'logo',
      title: 'Logo',
      width: 120,
      render: (item) => <Avatar
                          alt={`${get(item, 'name')}`}
                          src={get(item, ['logos', 'files', 0, 'original_download_url'])}
                          style={{width: 100, height: 100}}
                          variant="square"
                        />,
    },
    { field: 'name',
      title: 'Name',
      sort:(direction) => onUpdateListOrdering(direction === 'asc' ? 'name' : '-name')
    },
    { field: 'url',
      title: 'URL',
      sort:(direction) => onUpdateListOrdering(direction === 'asc' ? 'url' : '-url')
    },
  ]

  return (
    <AdminCommonListLayout
      active_key="manufacturers"
      breadcrumbs={[
        {name: 'admin_home'},
        {name: 'manufacturers', label: "Manufacturers", url: '/admin/manufacturers'}
      ]}
      add_label="Add Manufacturer"
      title={"Manufacturers"}
      onAddRow={onAddManufacturer}
      onEditRow={onEditManufacturer}
      is_loading={is_loading}
      columns={columns}
      item_list={adminManufacturerList}
      enableAnyFieldFilter={true}
    />
  )
}
