import React, { useEffect } from 'react'
import { get, startCase, round } from 'lodash'
import { Link, useHistory } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { CommonTable } from 'components/CommonTable'
import Button from '@material-ui/core/Button'
import DonorMainLayout from '../layout/DonorMainLayout'
import { makeStyles, Grid } from '@material-ui/core'
import { contactCompanyProductList } from './actions/company_products'
import DonorCommonListLayout from '../layout/DonorCommonListLayout'
import { ShortId } from 'components/ShortId'

export const CompanyProducts = () => {

    const history = useHistory()
    const dispatch = useDispatch()
    const is_loading = useSelector(() => contactCompanyProductList.isLoading())
    const company_products = useSelector(() => contactCompanyProductList.getVisibleObjects())

    useEffect(() => {
        async function fetchStaticObjects() {
            dispatch(contactCompanyProductList.fetchListIfNeeded())
        }
        fetchStaticObjects()
    }, [])

    const onEdit = (id) => {
        history.push(`/donor/settings/company_product/${id}`)
    }

    const onAdd = (item) => {
        history.push("/donor/settings/company_product")
    }

    const onImport = () => {
        history.push("/donor/settings/company_products/import")
    }

    const columns = [
        { field: 'id',
          title: 'ID',
          width: 80,
          render: (item) => <ShortId value={item.id} />,
          visible: true
        },
        {
            field: 'custom_product_code',
            title: 'Code',
            width: 160,
            render: (item) => round(get(item ,"custom_product_code"), 0),
            sort: (direction) => this.onUpdateListOrdering(direction === 'asc' ? 'custom_product_code' : '-custom_product_code'),
        },
        {
            field: 'product_name',
            title: 'Product Name',
            render: (item) => <a onClick={() => onEdit(item.id)}>{startCase(get(item, "product_name"))}</a>,
            sort: (direction) => this.onUpdateListOrdering(direction === 'asc' ? 'product__product_name' : '-product__product_name'),
        },
        {
            field: 'product_category_crumbs',
            title: 'Category',
            render: (item) => get(item, "product_category_crumbs", [])[get(item, "product_category_crumbs", []).length-1],
            sort: (direction) => this.onUpdateListOrdering(direction === 'asc' ? 'product__product_category__name' : '-product__product_category__name'),
        },
    ]

    return (
        <DonorCommonListLayout active_key="company_products"
                               breadcrumbs={[{name: 'donor_home'}, {name: 'company_products', label: "Company products", url: '/donor/settings/company_products'}]}
                               is_loading={ is_loading }
                               title="Company Products"
                               columns={ columns }
                               item_list={contactCompanyProductList}
                               onAddRow={onAdd}
                               onImportRows={onImport}
                               onEditRow={onEdit}
                               enableAnyFieldFilter={true}
                               add_label="Add Product"
                               import_label="Import Products"
        />

    )

}
