import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { head, get } from 'lodash'
import { Trans, Translation } from 'react-i18next'
import { FormikDropdownField } from 'components/form/Dropdown'
import AdminCommonListLayout from '../layout/AdminCommonListLayout'
import Timestamp from 'components/Timestamp'
import { FormikInputField } from 'components/form/InputField'
import { adminDriverList } from './actions/admin_driver'
import { Separator } from 'components/layout/Separator'
import { ShortId } from 'components/ShortId'
import { Button } from 'components/layout/Button'
import { countryList } from 'actions/country'
import { provinceList } from 'actions/province'
import Avatar from '@material-ui/core/Avatar'

export default function Drivers() {

  const dispatch = useDispatch()
  const history = useHistory()

  const drivers = useSelector( () => adminDriverList.getVisibleObjects() )
  const is_loading = useSelector( () => adminDriverList.isLoading() )
  const filter = useSelector( () => adminDriverList.getFilter() )
  const country_options = useSelector( () => countryList.getAsOptions() )
  const province_options = useSelector( () => provinceList.getAsOptions() )

  useEffect( () => {
    dispatch( adminDriverList.fetchListIfNeeded() )
    dispatch( countryList.updatePaginationNumItemsPerPage( 1000 ) )
    dispatch( provinceList.updatePaginationNumItemsPerPage( 1000 ) )
    dispatch( countryList.fetchListIfNeeded() )
    dispatch( provinceList.fetchListIfNeeded() )
  }, [] )


  const onCountryChanged = ( country_id ) => {
    dispatch( provinceList.filterOnCountry( country_id ) )
  }

  const onEditDriver = ( driver_id ) => {
    history.push( {
      pathname: '/admin/driver/' + driver_id
    } )
  }

  const onAddDriver = () => {
    window.alert( "To add a driver, create a new user and assign them the Driver role" )
  }

  const onUpdateListOrdering = ( field ) => {
    dispatch( adminDriverList.updateListOrdering( field ) )
  }

  const columns = [
    {
      field: 'profile_image',
      title: 'Image',
      render: ( item ) => <Avatar
        alt={ `${ get( item, 'first_name' ) } ${ get( item, 'last_name' ) }` }
        src={ get( item, [ 'profile_image', 'original_download_url' ] ) }
        style={ { width: 60, height: 60, cursor: 'pointer' } }
        onClick={ () => onEditUser( get( item, 'id' ) ) }
      />,
    },
    {
      field: 'first_name',
      title: 'Name',
      render: ( item ) => <a onClick={ () => onEditDriver( get( item, 'id' ) ) }>{ get( item, 'first_name' ) } { get( item, 'last_name' ) }</a>,
      sort: ( direction ) => onUpdateListOrdering( direction === 'asc' ? 'user__first_name' : '-user__first_name' )
    },
    {
      field: 'City',
      title: 'City',
      render: ( item ) => get( item, [ "operating_city_name" ] ),
      sort: ( direction ) => onUpdateListOrdering( direction === 'asc' ? 'operating_city__name' : '-operating_city__name' )
    },
    {
      field: 'Vehicle',
      title: 'Vehicle',
      render: ( item ) => get( item, [ "preferred_vehicle_name" ] ),
      sort: ( direction ) => onUpdateListOrdering( direction === 'asc' ? 'preferred_vehicle__name' : '-preferred_vehicle__name' )
    },
    {
      field: 'Driver licence', title: 'Driver Licence',
      render: ( item ) => get( item, [ "driver_licence" ] ),
      sort: ( direction ) => onUpdateListOrdering( direction === 'asc' ? 'driver_licence' : '-driver_licence' )
    },
    {
      field: 'Email', title: 'Email',
      render: ( item ) => get( item, [ "user_email" ] ),
      sort: ( direction ) => onUpdateListOrdering( direction === 'asc' ? 'user__email' : '-user__email' )
    },
    {
      field: 'bringg_ref',
      title: 'Bringg Ref',
      sort: ( direction ) => onUpdateListOrdering( direction === 'asc' ? 'bringg_ref' : '-bringg_ref' )
    },
    {
      field: 'created_at',
      title: 'Created at',
      render: ( item ) => <Timestamp value={ item.created_at } format='datetime' />,
      sort: ( direction ) => onUpdateListOrdering( direction === 'asc' ? 'created_at' : '-created_at' ),
    },
  ]

  return (
    <AdminCommonListLayout
      active_key="drivers"
      breadcrumbs={ [
        { name: 'admin_home' },
        { name: 'drivers', label: "Drivers", url: '/admin/drivers' }
      ] }
      add_label="Add Driver"
      title={ "Drivers" }
      onAddRow={ onAddDriver }
      onEditRow={ onEditDriver }
      is_loading={ is_loading }
      columns={ columns }
      item_list={ adminDriverList }
      enableAnyFieldFilter={ true }
    />
  )
}
