import { ItemList } from 'orm'
import { map } from 'lodash'
import { onEventUpdated } from 'admin/actions/admin_calendar'

class ParcelLogList extends ItemList {
    getEntityKey() {
        return "admin/parcel_log"
    }
}

export const adminParcelLogList = new ParcelLogList("admin_parcel_log__default")
