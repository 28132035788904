import React from 'react'
import { map, size } from 'lodash'

// Hack to fix broken params after removing emotion
const style = {}
const item_style = {}
// ///

export const ButtonBar = ({children, style_extra, item_style_extra, ...props }) => {
    return (
        <div>
          { !children.length && <div>{children}</div> }
          { children.length > 1 && map(children, (child, index) => <div key={index}>{child}</div>) }
        </div>
    )

}
