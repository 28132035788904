import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import AdminCommonListLayout from '../layout/AdminCommonListLayout'
import { adminProductCategoryLeafNodesList } from '../product_categories/actions/admin_product_category_leaf_node'
import { ProductCategorySelectField } from 'admin/components/product_categories/form/ProductCategorySelectField'
import { Formik, Form } from 'formik'
import { ShortId } from 'components/ShortId'
import FormikOnChange from 'components/form/FormikAutoSave'
import { FormikGeneralFormErrors } from 'components/form/GeneralFormErrors'
import { FormControlLabel, Radio, RadioGroup, Button, Grid, CircularProgress, IconButton, Tooltip } from '@material-ui/core'
import { Modal } from 'components/layout/Modal'
import MergeTypeIcon from '@material-ui/icons/MergeType'
import { showSuccess, showError } from 'actions/Error'
import useAxiosPost from 'hooks/api/useAxiosPost'
import axios, { fetcher } from 'utils/axios'
import CollectionsBookmarkIcon from '@material-ui/icons/CollectionsBookmark'
import SelectAllIcon from '@material-ui/icons/SelectAll'
import ClearIcon from '@material-ui/icons/Clear'


export default function Products() {
  const dispatch = useDispatch()
  const history = useHistory()
  const products = useSelector( () => adminProductCategoryLeafNodesList.getVisibleObjects() )
  const is_loading = useSelector( () => adminProductCategoryLeafNodesList.isLoading() )
  const filter = useSelector( () => adminProductCategoryLeafNodesList.getFilter() )
  const [ selectedRowIds, setSelectedRowIds ] = useState( [] )
  const [ masterProductId, setMasterProductId ] = useState( null )
  const [ modalOpen, setModalOpen ] = useState( false )
  const [ showSelect, setShowSelect ] = useState( false )
  const [ isLoadingMergeAllDuplicates, setIsLoadingMergeAllDuplicates ] = useState( false )
  const [ post, { loading, error } ] = useAxiosPost()

  useEffect( () => {
    dispatch( adminProductCategoryLeafNodesList.fetchListIfNeeded() )
  }, [] )


  const onEditProduct = ( product_id ) => {
    history.push( {
      pathname: '/admin/product_category/' + product_id
    } )
  }

  const onMergeProductCategories = () => {
    if ( selectedRowIds.length <= 1 ) {
      alert( 'Please select more than 1 row to merge.' )
      return
    }
    const productIds = selectedRowIds.join( ',' )
    setModalOpen( true )
  }

  const onSave = async ( values, { setSubmitting } ) => {
    values.products = selectedRowIds
    values.master = masterProductId

    try {
      const formData = new FormData()
      formData.append( 'objects', values.products )
      formData.append( 'master', values.master )
      const postResponse = await post( 'admin/product_category/merge_product_categories/', formData )
      setSubmitting( false )
      setModalOpen( false )
      setResponse( postResponse )
      showSuccess( "Success", "Product categories merged successfully!" )
      dispatch( adminProductCategoryLeafNodesList.invalidateList() )
      dispatch( adminProductCategoryLeafNodesList.fetchListIfNeeded() )
    } catch ( error ) {
      console.error( error )
      setSubmitting( false )
    }
  }

  const handleMergeConfirmation = () => {
    // Check if master product is selected
    if ( !masterProductId ) {
      alert(
        `Please select a master product.`
      )
    }
    // Else we have a selected master product and we can proceed with the merge
    else {
      const selectedProduct = products.find( ( obj ) => obj.id === masterProductId )
      const proceed = confirm( `You have selected ${ selectedProduct.name } as the master product category. Are you sure you want to merge the selected product categories?` )
      // Close the modal
      if ( proceed ) {
        onSave( {}, { setSubmitting: () => { } } )
      }
    }
  }

  const onAddProduct = () => {
    history.push( '/admin/product_category' )
  }

  const onUpdateListOrdering = ( field ) => {
    dispatch( adminProductCategoryLeafNodesList.updateListOrdering( field ) )
  }

  const onClearFilter = () => {
    const values = {}
    onFilterChanged( values )
  }

  const renderFilter = () => {
    return (
      <Formik
        initialValues={ {} }
        onSubmit={ onFilterChanged }
        enableReinitialize={ true }
      >
        { formik_props => {
          const { values } = formik_props
          return (
            <Form>
              <FormikOnChange onChange={ ( values ) => onFilterChanged( values, formik_props ) } />
              <FormikGeneralFormErrors render={ ( msg ) => msg } />
              <Grid
                container
                direction="row"
                justifyContent="space-between"
                alignItems="flex-end"
                spacing={ 2 }
                style={ { marginBottom: 0 } }
              >
                <Grid item xs={ 12 } sm={ 6 } md={ 3 }>
                  <ProductCategorySelectField
                    name="product_category"
                    label="Product Category"
                    can_add={ false }
                    can_edit={ false }
                    formik_props={ formik_props }
                    onClearFilter={ onClearFilter }
                  />
                </Grid>
              </Grid>
            </Form>
          )
        } }
      </Formik>
    )
  }

  const onFilterChanged = ( values, formik_props ) => {
    dispatch( adminProductCategoryLeafNodesList.updateListFilter( values ) )
    dispatch( adminProductCategoryLeafNodesList.fetchListIfNeeded() )
  }

  const onMergeAllDuplicates = async () => {
    const proceed = confirm( `Are you sure you want to merge all duplicate product categories?` )
    if ( proceed ) {
      try {
        setIsLoadingMergeAllDuplicates( true )
        const response = await axios.get( '/admin/product/merge_all_duplicates/' )
        if ( response.data ) {
          showSuccess( "Success", "Duplicate product variations merged successfully!" )
          // Invalidate and refetch list logic
          dispatch( adminProductCategoryLeafNodesList.invalidateList() )
          dispatch( adminProductCategoryLeafNodesList.fetchListIfNeeded() )
        } else {
          // Handle the case where response does not contain the expected data
        }
      } catch ( error ) {
        console.error( error )
        showError( "Error", "Failed to merge duplicate product variations!" )
      }
      setIsLoadingMergeAllDuplicates( false )
    }
  }

  const renderAdditionalActions = () => {
    return (
      <>
        { !showSelect &&
          <>
            <IconButton aria-label="add" onClick={ () => setShowSelect( true ) }>
              { <Tooltip title="Select Rows to Merge" arrow><SelectAllIcon /></Tooltip> }
            </IconButton>
            <IconButton aria-label="add" onClick={ onMergeAllDuplicates }>
              { isLoadingMergeAllDuplicates ?
                <CircularProgress size={ 20 } /> :
                <Tooltip title="Merge all duplicate product variations" arrow>
                  <CollectionsBookmarkIcon />
                </Tooltip>
              }
            </IconButton>
          </>
        }
        { showSelect &&
          <IconButton aria-label="add" onClick={ () => setShowSelect( false ) }>
            { <Tooltip title="Cancel Merge Operation" arrow><ClearIcon /></Tooltip> }
          </IconButton>
        }
        { showSelect && selectedRowIds.length > 1 &&
          <Button aria-label="merge products" onClick={ onMergeProductCategories }>
            <MergeTypeIcon /> Merge
          </Button>
        }
      </>
    )
  }


  const columns = [
    // {
    //   field: 'product_image',
    //   title: 'Image',
    //   width: 100,
    //   render: ( item ) => <Avatar
    //     alt={ `${ get( item, 'product_name' ) }` }
    //     title={ `${ get( item, 'product_name' ) }` }
    //     src={ get( item, [ 'images', 'files', 0, 'original_download_url' ] ) }
    //     style={ { width: 100, height: 100, cursor: 'pointer' } }
    //     onClick={ () => onEditProduct( get( item, 'id' ) ) }
    //     variant="square"
    //   >
    //     <AddPhotoAlternateIcon fontSize="large" />
    //   </Avatar>

    // },
    {
      field: 'id',
      title: 'Id',
      render: ( item ) => <ShortId value={ item.id } />,
      sort: ( direction ) => onUpdateListOrdering( direction === 'asc' ? 'id' : '-id' )
    },
    {
      field: 'name',
      title: 'Name',
      sort: ( direction ) => onUpdateListOrdering( direction === 'asc' ? 'name' : '-name' ),
      redirect: ( item ) => `admin/product/${ item.id }`,
      visible: true
    },
    {
      field: 'parent_category_name',
      title: 'Parent Category',
      sort: ( direction ) => onUpdateListOrdering( direction === 'asc' ? 'parent_category_name' : '-parent_category_name' )
    },
    {
      field: 'top_level_category_name',
      title: 'Root Category',
      sort: ( direction ) => onUpdateListOrdering( direction === 'asc' ? 'top_level_category_name' : '-top_level_category_name' ),
      visible: true
    },
    {
      field: 'alternative_names',
      title: 'Alternative Names',
      sort: ( direction ) => onUpdateListOrdering( direction === 'asc' ? 'alternative_names' : '-alternative_names' ),
      visible: true
    },
    {
      field: 'product_count',
      title: 'Product Variations',
      align: 'right',
      sort: ( direction ) => onUpdateListOrdering( direction === 'asc' ? 'product_count' : '-product_count' ),
      visible: true
    },
  ]

  const handleMasterSelection = ( event ) => {
    setMasterProductId( event.target.value )
  }

  const renderSelectMasterProductForm = () => {
    const filteredProducts = products.filter( ( product ) => selectedRowIds.includes( product.id ) )
    return (
      <Modal open={ modalOpen } onClose={ () => setModalOpen( false ) } title="Select Master Product Category" noPadding>
        <div style={ { padding: 20 } }>
          <RadioGroup value={ masterProductId } onChange={ handleMasterSelection }>
            { filteredProducts.map( ( product ) => (
              <FormControlLabel
                key={ product.id }
                value={ product.id }
                control={ <Radio /> }
                label={ product.name }
              />
            ) ) }
          </RadioGroup>
        </div>
        <Button variant="contained" size="large" color="primary" onClick={ handleMergeConfirmation }>
          Merge
        </Button>
      </Modal>
    )
  }


  return (
    <>
      <AdminCommonListLayout
        active_key="products"
        breadcrumbs={ [
          { name: 'admin_home' },
          { name: 'products', label: "Products", url: '/products' }
        ] }
        add_label="Add Product"
        onAddRow={ onAddProduct }
        onEditRow={ onEditProduct }
        storeTableName={ "adminProductCategoryLeafNodesList" }
        selectedRowIds={ selectedRowIds }
        updateSelectedRowIds={ setSelectedRowIds }
        is_loading={ is_loading }
        columns={ columns }
        item_list={ adminProductCategoryLeafNodesList }
        enableAnyFieldFilter={ true }
        renderAdditionalFilter={ renderFilter }
        renderAdditionalActions={ renderAdditionalActions }
        title={ "Products" }
        rowClickRedirect
        renderSelect={ showSelect }
        useColumnsSelector
      />
      { modalOpen && renderSelectMasterProductForm() }
    </>
  )
}
