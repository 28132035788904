import { useState } from 'react'
import { Grid, Button, Typography } from '@material-ui/core'
import useAxiosPost from '../../../../hooks/api/useAxiosPost'
import * as Yup from 'yup'
import { Formik, Form, Field } from 'formik'
import Loading from 'components/Loading'
import { map } from 'lodash'
import Alert from '@material-ui/lab/Alert'

export default function ImportNewFoodItemsCsvForm() {
    const [post, { loading, error }] = useAxiosPost()
    const [response, setResponse] = useState(null)
    const [submitting, setsubmitting] = useState(false)

    const handleUpload = async(values, { setSubmitting }) => {
        try {
            const formData = new FormData()
            formData.append('csv_file', values.csv_file)

            const postResponse = await post('admin/food_item/import_food_items_from_csv/', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            })
            setSubmitting(false)
            setResponse(postResponse)
        } catch (err) {
            console.error(error)
            setSubmitting(false)
        }
    }

    const validationSchema = Yup.object().shape({
        file_info: Yup.mixed().required('File is required'),
    })

    const renderErrorMessages = (data) => {
        return (
            map(data, (item, index) => (
                <Alert severity="error" key={index}>{item.food_item} {item.error}</Alert>
            ))
        )
    }

    const renderSuccessMessages = (data) => {
        return (
            map(data, (item, index) => (
                <Alert severity="success" key={index} style={{ marginBottom: 2 }}>{item}</Alert>
            ))
        )

    }

    return (
        <>
            <Formik
                initialValues={{ csv_file: null }}
                onSubmit={handleUpload}
            >
                {({ setFieldValue, isSubmitting }) => (
                    <Form>
                        <Grid container spacing={2} style={{margin: '20px 0'}}>
                            <Grid item xs={12}>
                                <input
                                    id="csv_file"
                                    name="csv_file"
                                    type="file"
                                    accept=".csv"
                                    onChange={(event) => {
                                        setFieldValue('csv_file', event.currentTarget.files[0])
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Button type="submit" variant="contained" disabled={isSubmitting || loading}>
                                    Upload
                                </Button>
                            </Grid>
                        </Grid>

                    </Form>
                )}
            </Formik>

            {loading && <Loading />}
            {response && renderSuccessMessages(response.data.success)}
            {response && <p>Errors: {renderErrorMessages(response.data.errors)}</p>}

        </>
    )
}
