import { ItemList } from 'orm'
import { map } from 'lodash'

class UserList extends ItemList {
    getEntityKey() {
        return "admin/user"
    }

    getAsOptions() {
        const items = this.getVisibleObjects()
        return map(items, function(item) { return {label: item.display_name,
                                                   value: item.id}})
    }
}

export const contactUserList = new UserList("admin_user__default")
export const contactPickerAutoCompleteList = new UserList("admin_picker_user__default")
