import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { adminUserList } from './actions/admin_user'
import { get } from 'lodash'
import Loading from 'components/Loading'

export const UserName = ({ user_id }) => {
    const dispatch = useDispatch()
    const user = useSelector(() => adminUserList.getObject(user_id))
    const is_loading = useSelector(() => adminUserList.isLoading())

    useEffect(() => {
        dispatch(adminUserList.ensureObjectLoaded(user_id))
    },[])

    return is_loading ? <Loading size={20} /> : get(user, "display_name", "User name not loading...")
}
