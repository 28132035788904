import React from 'react'
import { useHistory } from 'react-router-dom'
import { connect } from 'react-redux'
import { get } from 'lodash'
import ConfirmationModal from 'components/ConfirmationModal'
import Breadcrumbs from 'components/layout/Breadcrumbs'
import {
  makeStyles,
  Typography,
  Container,
  Hidden,
  Grid,
  Avatar,
  Divider,
  CssBaseline,
  ThemeProvider
} from '@material-ui/core'
import ArrowRightIcon from '@material-ui/icons/ArrowRight'
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft'
import ChevronRightIcon from '@material-ui/icons/ChevronRight'
import MenuIcon from '@material-ui/icons/Menu'
import Header from './Header'
import Content from './Content'
import Root from './Root'
import Nav from './Nav'
import Footer from './Footer'
import presets from './layoutPresets'
import donorTheme from 'themes/donorTheme'
import { Separator } from 'components/layout/Separator'
import LoggedInUser from './header/LoggedInUser'
import config from 'config'
import logo from 'assets/images/logo.svg'
import Notifications from './header/Notifications'
import { getActiveCompanyName, getActiveCompanyLogo, getActiveCompanyId, getActiveCompany } from 'actions/active_company'
import Title from 'components/layout/Title'
import FooterCopyright from 'components/layout/FooterCopyright'
import DonorMenu from './menu/DonorMenu'

const useStyles = makeStyles((theme) => ({
  donorPortalHeading: {
    fontWeight: 500,
    marginLeft: 15,
    marginTop: 35
  },
  logoContainer: {
    borderBottom: '1px solid #efefef',
    marginBottom: 15
  },
  donorLogoContainer: {
    textAlign: 'center',
    maxheight: 100,
    paddingTop: 0,
    paddingBottom: 0,
  },
  donorLogo: {
    maxWidth: '100%',
    maxheight: 100
  },
  userMenuBar: {
    position: 'absolute',
    bottom: 0,
    left: 0,
    width: '100%',
    background: '#cfd8dc',
  }
}))

export default function DonorMainLayout({ title, layout, children, breadcrumbs, active_key, notice }) {

  const classes = useStyles()
  const history =useHistory()
  const active_company = getActiveCompany()
  let company_logo = getActiveCompanyLogo()
  let company_name = getActiveCompanyName()
  if(!getActiveCompanyId()) {
    history.push('/')
  }
  const spacer = <ArrowRightIcon style={{marginBottom: -5}} />

  React.useEffect(() => {
    company_logo = getActiveCompanyLogo()
    company_name = getActiveCompanyName()
  },[active_company])

  return (
    <ThemeProvider theme={donorTheme}>
      <Root config={config} style={{ minHeight: "100vh" }}>
        <CssBaseline />

        <Header
          menuIcon={{
              inactive: <MenuIcon />,
              active: <ChevronLeftIcon />
          }}
        >
        </Header>

        <Nav
          collapsedIcon={{
              inactive: <ChevronLeftIcon />,
              active: <ChevronRightIcon />
          }}
          header={
              ctx => null
          }
        >

            <Grid container justify="flex-start" className={classes.logoContainer}>
              <img src={logo} width={100} style={{margin: '10px 0 7px 15px'}}/>
              <Typography className={classes.donorPortalHeading}>Donor Portal</Typography>
            </Grid>

          <DonorMenu />
          {/*
          <Divider light style={{marginTop: 10, marginBottom: 20}}/>
          <DonorSubMenu />
          */}
          <div className={classes.userMenuBar}>
            { company_logo ?
              <div className={classes.donorLogoContainer} >
                <img src={company_logo} alt={company_name} title={company_name} className={classes.donorLogo} />
              </div> :
              <Typography variant="h6">{company_name}</Typography>
            }
            <LoggedInUser />
          </div>
        </Nav>

        <Content>

          { title &&
            <Title portal="donor">{ title }</Title>
          }
          {notice}
          <Container disableGutters={true} maxWidth={false} className>

            <ConfirmationModal />
            <Breadcrumbs crumbs={breadcrumbs}/>

            { children }

          </Container>

        </Content>

        <Footer>
          <FooterCopyright />
        </Footer>
      </Root>
    </ThemeProvider>
  )
}
