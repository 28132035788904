import { Trans, Translation } from 'react-i18next'
import { Error } from '../layout/Error'

export default function FormError({ error }) {

        if ( !error ) {
            return null
        }

        return (
            <Error><Trans>{error}</Trans></Error>
        )

}

