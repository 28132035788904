import { ItemList } from 'orm'

class ChecklistAnswerList extends ItemList {
    getEntityKey() {
        return "admin/checklist_answer"
    }

}

export const adminChecklistAnswerList = new ChecklistAnswerList("admin_checklist_answer__default")
