import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { get } from 'lodash'
import * as Yup from 'yup'
import { FormikInputField } from 'components/form/InputField'
import { FormikCheckboxField } from 'components/form/CheckboxField'
import { FormikMultiSelectDropdownField } from 'components/form/Dropdown'
import { adminEmployeeRoles } from 'admin/actions/admin_dropdown_options'
import { AdminUserFilterForm } from './AdminUserFilterForm'
import { FormikFileSetField } from 'components/form/FileSetField'
import { Grid } from '@material-ui/core'
import { Card } from 'components/layout/Card'
import { UserNotificationSubscriptionsForm } from './UserNotificationSubscriptionsForm'
import { loggedInUser } from 'actions/auth'


export const validationSchema = Yup.object().shape( {
  'email': Yup.string().required( "Required" ),
  'first_name': Yup.string().required( "Required" ),
} )

export const AdminUserForm = ( { formik_props, user_is_admin } ) => {
  const dispatch = useDispatch()
  const employee_role_options = useSelector( () => adminEmployeeRoles.getAsOptions() )
  const logged_in_user_is_admin = get( loggedInUser(), "is_superuser" )

  useEffect( () => {
    dispatch( adminEmployeeRoles.fetchListIfNeeded() )
  }, [ dispatch ] )

  return (
    <Grid container spacing={ 2 }>
      <Grid item xs={ 12 } lg={ 4 }>
        <Card title="Details" content={
          <>
            <FormikInputField name="first_name" type="text" label="First name" formik_props={ formik_props } />
            <FormikInputField name="last_name" type="text" label="Last name" formik_props={ formik_props } />
            <FormikInputField name="email" type="email" label="Email" formik_props={ formik_props } />
            <FormikInputField name="phone_number" label="Mobile phone number" formik_props={ formik_props } />
          </>
        } />
      </Grid>

      <Grid item xs={ 12 } lg={ 4 }>
        { ( user_is_admin || logged_in_user_is_admin ) &&
          <>

            <Card
              title="Permissions"
              content={
                <>
                  <FormikCheckboxField name="is_active" label="Can Login" formik_props={ formik_props } />
                  <br />
                  <FormikCheckboxField name="is_staff" label="Is Staff" formik_props={ formik_props } />
                  <br />
                  <FormikCheckboxField name="is_superuser" label="Is Admin" formik_props={ formik_props } />
                </>
              }
          />
          
          { get( formik_props, [ "values", "id" ] ) &&

            <Card
              title="Subscribe to Notifications"
              content={ <UserNotificationSubscriptionsForm user_id={ formik_props.values.id } /> }
              noPadding
            />
          
            }

          </>
        }
      </Grid>

      <Grid item xs={ 12 } lg={ 4 }>
        { ( user_is_admin || logged_in_user_is_admin ) &&
          <>
            <Card
              title="Branch"
              content={ <AdminUserFilterForm field_name_prefix="user_filter" formik_props={ formik_props } /> }
            />

            { get( formik_props, [ "values", "employee" ] ) &&
              <Card
                title="Roles"
                content={
                  <FormikMultiSelectDropdownField
                    name="employee.roles"
                    label="Roles"
                    options={ employee_role_options }
                    formik_props={ formik_props }
                  />
                }
              />
            }
          </>
        }
        <Card
          title="Profile image"
          content={
            <FormikFileSetField
              max_num_files={ 1 }
              name="profile_images"
              formik_props={ formik_props }
            />
          }
        />
      </Grid>
    </Grid>
  )
}