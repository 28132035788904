import React from 'react'
import { Chip } from '@material-ui/core'
import CancelIcon from '@material-ui/icons/Cancel'


const FilterChip = ( { name, label, onDelete } ) => {
  return (
    <Chip
      label={ `${ name }: ${ label }` }
      onDelete={ () => onDelete( name ) }
      deleteIcon={ <CancelIcon /> }
      variant="outlined"
    />
  )
}

export default FilterChip
