import { ItemList } from 'orm'
import { EnhancedFilter } from 'admin/actions/enhanced_filter'

class BeneficiaryParcelProductList extends ItemList {
    getEntityKey() {
        return "admin/beneficiary_parcel_product"
    }

    getEnhancedFilter() {
        if ( ! this.enhanced_filter ) {
            this.enhanced_filter = new EnhancedFilter(this)
        }
        return this.enhanced_filter
    }
}

const invalidateAll = () => {
    return (dispatch, getState) => {
        dispatch(adminBeneficiaryParcelProductList.invalidateList())
        dispatch(adminBeneficiaryParcelProductReportList.invalidateList())
        dispatch(adminBeneficiaryParcelProductForCartList.invalidateList())
    }
}

export const adminBeneficiaryParcelProductList = new BeneficiaryParcelProductList("admin_beneficiary_parcel_product__default")
export const adminBeneficiaryParcelProductReportList = new BeneficiaryParcelProductList("admin_beneficiary_parcel_product_report__default")
export const adminBeneficiaryParcelProductForCartList = new BeneficiaryParcelProductList("admin_beneficiary_parcel_product_for_cart__default")
