import React, {Component, useState} from 'react'
import {connect} from 'react-redux'
import DropzoneComponent from 'react-dropzone-component'
import ReactDOMServer from 'react-dom/server'
import { Link } from 'react-router-dom'
import { isEmpty, includes, split } from 'lodash'
import { useField } from 'formik'
import FormError from './FormError'
import Loading from '../Loading'
import FormWarning from './FormWarning'
import { authToken } from '../../actions/auth'
import {showSuccess, showError} from '../../actions/Error'
import 'react-dropzone-component/styles/filepicker.css'
import 'dropzone/dist/min/dropzone.min.css'
import loading_icon from 'assets/images/loading.gif'
import * as Sentry from '@sentry/browser'
import { Typography, makeStyles } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
    file_uploader: {
        '& .filepicker': {
            background: 'white',
            borderRadius: 12,
            border: '3px dashed #ccc',
            fontWeight: 700,
            color: '#ccc'
        },

    }
}))

export const FormikFileUploadField = ({ label, file_number, onFileUploaded, renderPlaceHolder, file_types, upload_relative_url, upload_params, ...props }) => {
    // useField() returns [formik.getFieldProps(), formik.getFieldMeta()]
    // which we can spread on <input> and also replace ErrorMessage entirely.
    const classes = useStyles()
    const [field, meta] = useField(props);
    const { value } = field

    const showSuccess = (status, data, evt) => {
        const { file_info } = data
        const { setFieldValue } = props.formik_props
        const { name } = field
        setFieldValue(name, file_info)
        if ( onFileUploaded ) {
            onFileUploaded(file_info)
        }
    }

    const onFailure = (ex) => {
        showError("Error", "Failed to upload file, please check it is a valid pdf", ex)
    }

    const request_headers = {'X-FOODSPACE-AUTHENTICATION-TOKEN': 'Token ' + authToken()}
    const upload_url = window.LOCAL_SETTINGS.API_BASE + upload_relative_url
    const args = {}

    const componentConfig = {
        showFiletypeIcon: true,
        postUrl: upload_url
    }

    let upload_msg

    if ( renderPlaceHolder ) {
        upload_msg = renderPlaceHolder()
    } else {
        if ( value && value.id ) {
            upload_msg = "Click or drag here to replace this file"
        } else {
            upload_msg = (
                <div>
                    <Typography>Click or drag here to upload a file</Typography>
                </div>
            )
        }
    }

    const djsConfig = {
        params: upload_params,
        headers: request_headers,
        dictDefaultMessage: ReactDOMServer.renderToStaticMarkup(upload_msg),
        acceptedFiles: file_types,
        maxFiles: 1,
        timeout: 180000,
        previewTemplate: ReactDOMServer.renderToStaticMarkup(
            <div className="dz-preview dz-file-preview">
                <div className="dz-image"><img data-dz-thumbnail /></div>
                <div className="dz-details">
                    <div className="dz-size"><span data-dz-size></span></div>
                    <div className="dz-filename"><span data-dz-name></span></div>
                </div>
                <div className="dz-progress">
                    <span className="dz-upload" data-dz-uploadprogress></span>
                    <div className="dz-upload-spinner">
                        <img alt="loading" src={ loading_icon } />
                    </div>
                </div>
                <div className="dz-error-message"><span data-dz-errormessage></span></div>
                <div className="dz-success-mark">
                    <svg width="54px" height="54px" viewBox="0 0 54 54" version="1.1">
                        <title>Check</title>
                        <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                            <path d="M23.5,31.8431458 L17.5852419,25.9283877 C16.0248253,24.3679711 13.4910294,24.366835 11.9289322,25.9289322 C10.3700136,27.4878508 10.3665912,30.0234455 11.9283877,31.5852419 L20.4147581,40.0716123 C20.5133999,40.1702541 20.6159315,40.2626649 20.7218615,40.3488435 C22.2835669,41.8725651 24.794234,41.8626202 26.3461564,40.3106978 L43.3106978,23.3461564 C44.8771021,21.7797521 44.8758057,19.2483887 43.3137085,17.6862915 C41.7547899,16.1273729 39.2176035,16.1255422 37.6538436,17.6893022 L23.5,31.8431458 Z M27,53 C41.3594035,53 53,41.3594035 53,27 C53,12.6405965 41.3594035,1 27,1 C12.6405965,1 1,12.6405965 1,27 C1,41.3594035 12.6405965,53 27,53 Z" strokeOpacity="0.198794158" stroke="#747474" fillOpacity="0.816519475" fill="#FFFFFF"></path>
                        </g>
                    </svg>
                </div>
                <div className="dz-error-mark">
                    <svg width="54px" height="54px" viewBox="0 0 54 54" version="1.1">
                        <title>Error</title>
                        <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                            <g stroke="#747474" strokeOpacity="0.198794158" fill="#FFFFFF" fillOpacity="0.816519475">
                                <path d="M32.6568542,29 L38.3106978,23.3461564 C39.8771021,21.7797521 39.8758057,19.2483887 38.3137085,17.6862915 C36.7547899,16.1273729 34.2176035,16.1255422 32.6538436,17.6893022 L27,23.3431458 L21.3461564,17.6893022 C19.7823965,16.1255422 17.2452101,16.1273729 15.6862915,17.6862915 C14.1241943,19.2483887 14.1228979,21.7797521 15.6893022,23.3461564 L21.3431458,29 L15.6893022,34.6538436 C14.1228979,36.2202479 14.1241943,38.7516113 15.6862915,40.3137085 C17.2452101,41.8726271 19.7823965,41.8744578 21.3461564,40.3106978 L27,34.6568542 L32.6538436,40.3106978 C34.2176035,41.8744578 36.7547899,41.8726271 38.3137085,40.3137085 C39.8758057,38.7516113 39.8771021,36.2202479 38.3106978,34.6538436 L32.6568542,29 Z M27,53 C41.3594035,53 53,41.3594035 53,27 C53,12.6405965 41.3594035,1 27,1 C12.6405965,1 1,12.6405965 1,27 C1,41.3594035 12.6405965,53 27,53 Z"></path>
                            </g>
                        </g>
                    </svg>
                </div>
            </div>
        )
    }

    const eventHandlers = {
        init: (dropzone) => { args.dropzone = dropzone },
        error: onFailure,
        success: showSuccess,
        complete: (f) => args.dropzone.removeFile(f)
    }

    return (
        <div className={classes.file_uploader}>
            <DropzoneComponent config={componentConfig}
                eventHandlers={eventHandlers}
                djsConfig={djsConfig}
                />
            <FormError meta={meta} />
        </div>
    )

}

class FileUploader extends Component {

    render() {

        const { upload_url, showSuccess, onFailure, upload_params, request_headers, file_types, label, class_name } = this.props

        const componentConfig = {
            showFiletypeIcon: true,
            postUrl: upload_url
        }

        var djsConfig = {
            params: upload_params,
            headers: request_headers,
            dictDefaultMessage: label,
            acceptedFiles: file_types,
            timeout: 180000,
            previewTemplate: ReactDOMServer.renderToStaticMarkup(
                <div className="dz-preview dz-file-preview">
                    <div className="dz-progress">
                        <span className="dz-upload" data-dz-uploadprogress="true">
                            <div>
                                <div id="mask" className="mask">
                                </div>
                                <div id="msg_container" className="msg_container">
                                    <div id="msg" className="msg">
                                        <Loading />
                                    </div>
                                </div>
                            </div>
                        </span>
                    </div>
                </div>
            )
        }

        const eventHandlers = {
            init: (dropzone) => { this.dropzone = dropzone },
            error: onFailure,
            success: showSuccess,
            complete: (f) => this.dropzone.removeFile(f)
        }

        return (
            <div >
                <DropzoneComponent
                    config={componentConfig}
                    eventHandlers={eventHandlers}
                    djsConfig={djsConfig}
                    />
            </div>
        )
    }
}

function mapStateToProps(state, props) {
    const { upload_relative_url, upload_params, showSuccess, onFailure, file_types, label, class_name } = props

    let request_headers = {}
    if (authToken()) {
        request_headers['X-FOODSPACE-AUTHENTICATION-TOKEN'] = 'Token ' + authToken()
    }

    const API_BASE = state.settings.configured && state.settings.API_BASE
    const upload_url = API_BASE + upload_relative_url

    return {
        upload_url,
        upload_params,
        request_headers,
        showSuccess,
        onFailure,
        file_types,
        label,
        class_name,
    }
}

export default connect(mapStateToProps)(FileUploader)
