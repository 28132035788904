import React from "react"
import { makeStyles, Typography, Box, Card, CardContent, CardActions, Button, Divider } from "@material-ui/core"
import { spacing } from '@material-ui/system'
import { FormikGeneralFormErrors } from '../form/GeneralFormErrors'
import { SaveButton } from 'components/form/Buttons'

const useStyles = makeStyles( () => ( {
	alert: {
		marginBottom: '7px'
	},
	card: {
		maxWidth: '100%',
		marginTop: 0,
		width: "100%",
	},
	titleNoPadding: {
		margin: " -15px -24px 0 -24px",
		textIndent: 24
	},
	title: {
		textIndent: 15,
		margin: '10px 0'
	},
	container: {
		justifyContent: "center",
		width: "100%",
		paddingBottom: 40,
		position: 'relative'
	},
	containerInDrawer: {
		maxWidth: 455,
		marginBottom: 60
	},
	actions: {
		width: "100%",
		float: "right",
		padding: "0 15px 15px 15px"
	},
	positionFixed: {
		position: 'fixed'
	},
	positionAbsolute: {
		position: 'absolute'
	},
	actionsInDrawer: {
		bottom: 0,
		width: "100%",
		padding: 0
	},
	drawerInnnerActionsDiv: {
		width: "455px",
		bottom: 0,
		right: 0,
		padding: '12px 15px 0px 15px',
		background: '#eceff1',
	},
	buttons: {
		bottom: 0,
		right: 0,
		width: '100%',
		padding: '12px 15px 0px 15px',
		background: '#eceff1',
		zIndex: 99
	}
} ) )

const FormCard = ( props ) => {
	let {
		title,
		children,
		isSubmitting,
		onCancel,
		cardIsInDrawer,
		elevation,
		variant,
		save_label,
		renderSaveButtons,
		positionFixed = false,
		noCard,
		noPadding
	} = props
	const classes = useStyles()
	const position = positionFixed ? 'fixed' : 'absolute'
	let actions = classes.actions
	let buttons = classes.buttons
	let container = classes.container
	if ( !elevation ) { elevation = 0 }
	if ( cardIsInDrawer ) {
		elevation = 0
		actions = classes.actionsInDrawer
		buttons = classes.drawerInnnerActionsDiv
		container = classes.containerInDrawer
	}

	const titleClass = noPadding ? classes.titleNoPadding : classes.title

	const renderActionButtons = () => {
		return (
			<div className={ buttons } style={ { position: position } }>
				{ onCancel &&
					<Button onClick={ onCancel }>
						CANCEL
					</Button>
				}
				{ renderSaveButtons && renderSaveButtons() }
				{ !renderSaveButtons &&
					<SaveButton isSubmitting={ isSubmitting } float="right" label={ save_label } />
				}
			</div>
		)
	}

	if ( noCard ) {
		return (
			<>
				<FormikGeneralFormErrors />
				{ children }
				{ renderActionButtons() }
			</>
		)
	} else {
		return (
			<div className={ container }>
				{ title &&
					<>
						<div className={ titleClass }>
							<Typography variant="h6">{ title }</Typography>
						</div>
						<Divider />
					</>
				}
				<Card elevation={ elevation } variant={ variant }>

					<FormikGeneralFormErrors />
					{ noPadding ? children : <CardContent>{ children }</CardContent> }

					<CardActions className={ actions }>
						{ renderActionButtons() }
					</CardActions>
				</Card>
			</div>
		)
	}

}

export default FormCard
