import { ItemList } from 'orm'
import { map } from 'lodash'

class ContactBranchList extends ItemList {
    getEntityKey() {
        return "contact/company"
    }

    getAsOptions() {
        const items = this.getVisibleObjects()
        return map(items, function(item) { return {label: item.company.name,
                                                   value: item.company.id}})
    }
}

export const contactBranchList = new ContactBranchList("contact_company__default")
