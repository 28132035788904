import React from 'react'
import { Link, useHistory } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { get } from 'lodash'
import {
  makeStyles,
  IconButton,
  Popover,
  MenuItem,
  Divider,
  Avatar
} from '@material-ui/core'
import MenuIcon from '@material-ui/icons/Menu'
import AccountCircle from '@material-ui/icons/AccountCircle'
import Notifications from './Notifications'
import { clearAuthentication } from 'actions/auth'
import { userList } from 'actions/user'

const useStyles = makeStyles((theme) => ({
    toolbarButtons: {
        marginLeft: 0,
    },
}))

export default function LoggedInUser() {
    const classes = useStyles()
    const logged_in_user = userList.getUser()
    const [auth, setAuth] = React.useState(true)
    const [anchorEl, setAnchorEl] = React.useState(null)
    const open = Boolean(anchorEl)
    const id = open ? 'profile-popover' : undefined
    const dispatch = useDispatch()
    const history = useHistory()
    const hideNotifications = true

    const handleChange = (event) => {
        setAuth(event.target.checked)
    }

    const handleMenu = (event) => {
        setAnchorEl(event.currentTarget)
    }

    const handleClose = () => {
        setAnchorEl(null)
    }

    const onLogout = () => {
        clearAuthentication()
        window.location="/"
    }

    return (
        <div className={classes.toolbarButtons}>

          <IconButton
            aria-label="account of current user"
            aria-controls="menu-appbar"
            aria-haspopup="true"
            onClick={handleMenu}
            color="inherit"
          >
            <Avatar
              alt={`${get(logged_in_user, 'first_name')} ${get(logged_in_user, 'last_name')}`}
              src={get(logged_in_user, ['profile_image', 'original_download_url'])}
              style={{color: '#37474f', background: '#b0bec5'}}
              onClick={handleMenu}
            />
          </IconButton>
          <Notifications />
          <Popover
            id={id}
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'center',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'center',
            }}
          >
            <MenuItem onClick={() => history.push('/donor/user/profile')}>Profile</MenuItem>
            <MenuItem onClick={onLogout}>Logout</MenuItem>
            <Divider />
            <MenuItem><a href={"/?switch_company=true"}>Switch Company?</a></MenuItem>
          </Popover>
        </div>
    )
}
