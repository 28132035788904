import { createTheme }  from '@material-ui/core/styles'

import Frankfurter from 'assets/fonts/Frankfurter.ttf'

const raleway = {
  fontFamily: 'Frankfurter',
  fontStyle: 'normal',
  fontDisplay: 'swap',
  fontWeight: 400,
  src: `
    url(${Frankfurter}) format('truetype')
  `,
}

const theme = createTheme({
  palette: {
        primary: {
          main: '#000000'
        },
        secondary: {
          main: '#8dc83e'
        },
  },
  overrides: {
    MuiCard: {
      root: {
        marginBottom: '10px'
      },
      elevation: 0
    },
    MuiCssBaseline: {
      '@global': {
        '@font-face': [Frankfurter],
      },
    },
  },
  typography: {
    fontFamily: [
      'Frankfurter',
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
  },
})

const { breakpoints, typography: { pxToRem } } = theme
const websiteTheme = {
  ...theme,
  overrides: {
    MuiTypography: {
      h2: {
        [breakpoints.down("xs")]: {
          fontSize: "2rem"
        }
      }
    }
  }
}


export default websiteTheme
