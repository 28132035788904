import React, {useState, useEffect} from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Button from '@material-ui/core/Button'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import FormGroup from '@material-ui/core/FormGroup'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Checkbox from '@material-ui/core/Checkbox'
import { FormikCheckboxField } from 'components/form/CheckboxField'
import { adminParcelStates } from 'admin/actions/admin_dropdown_options'
import { map, filter } from 'lodash'

export default function ParcelStateFilter({formik_props}) {
    const dispatch = useDispatch()
    const [anchorEl, setAnchorEl] = useState(null)

    useEffect(() => {
        async function fetchStaticObjects() {
            dispatch(adminParcelStates.updatePaginationNumItemsPerPage(1000))
            dispatch(adminParcelStates.fetchListIfNeeded())
        }
        fetchStaticObjects()
    }, [])
    const states = useSelector(() => adminParcelStates.getAsOptions())

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget)
    }

    const handleClose = () => {
        setAnchorEl(null)
    }

    return (
        <div>
          <FormGroup list style={{paddingLeft:10}}>
            { map(states, (state) =>
                <FormControlLabel
                  control={
                      <FormikCheckboxField name={`parcel_states.${state.value}`}
                                           formik_props={formik_props}
                                           color="primary"
                      />
                  }
                  label={state.label}
                />
            )}
          </FormGroup>
        </div>
    )
}
