import React, { Component } from 'react';
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { Route, Switch } from 'react-router-dom'
import AdminReleaseNotes from '../configuration/AdminReleaseNotes'
import AdminUsers from '../users/AdminUsers'
import AdminDonors from '../donors/AdminDonors'
import AdminDonor from '../donors/AdminDonor'
import AdminLogisticsCompanies from '../logistics/AdminLogisticsCompanies'
import AdminLogisticsCompany from '../logistics/AdminLogisticsCompany'
import AdminParcels from '../orders/AdminParcels'
import AdminMoneyDonations from 'admin/components/money_donations/AdminMoneyDonations'
import AdminMoneyDonation from 'admin/components/money_donations/AdminMoneyDonation'
import { AdminCompanyReports } from 'admin/components/reports/AdminCompanyReports'
import { AdminCompanyReport } from 'admin/components/reports/AdminCompanyReport'
import AdminParcel from '../orders/AdminParcel'
import AdminDonorParcel from '../orders/AdminDonorParcel'
import AdminBeneficiaryParcel from '../orders/AdminBeneficiaryParcel'
import AdminBeneficiary from '../beneficiaries/AdminBeneficiary'
import AdminBeneficiaries from '../beneficiaries/AdminBeneficiaries'
import AdminCalendar from 'admin/views/AdminCalendar'
import AdminCity from '../cities/AdminCity'
import AdminCities from '../cities/AdminCities'
import AdminTag from '../tags/AdminTag'
import AdminTags from '../tags/AdminTags'
import AdminContactRole from '../contact_roles/AdminContactRole'
import AdminContactRoles from '../contact_roles/AdminContactRoles'
import AdminCoreBusiness from '../core_businesses/AdminCoreBusiness'
import AdminCoreBusinesses from '../core_businesses/AdminCoreBusinesses'
import AdminStartingValue from 'admin/components/configuration/starting_values/AdminStartingValue.js'
import AdminStartingValues from 'admin/components/configuration/starting_values/AdminStartingValues.js'
import AdminDriver from '../drivers/AdminDriver'
import AdminDrivers from '../drivers/AdminDrivers'
import AdminProduct from '../products/AdminProduct'
import AdminProducts from '../products/AdminProducts'
import AdminProductCategories from '../product_categories/AdminProductCategories'
import AdminProductCategory from '../product_categories/AdminProductCategory'
import AdminBrands from '../product_brands/AdminBrands'
import AdminBrand from '../product_brands/AdminBrand'
import AdminManufacturers from '../product_manufacturers/AdminManufacturers'
import AdminManufacturer from '../product_manufacturers/AdminManufacturer'
import AdminUOMs from '../uom/AdminUOMs'
import AdminUOM from '../uom/AdminUOM'
import AdminNutrients from '../nutrition/AdminNutrients'
import AdminNutrient from '../nutrition/AdminNutrient'
import AdminFoodItems from '../nutrition/AdminFoodItems'
import AdminFoodItem from '../nutrition/AdminFoodItem'
import AdminNutritionDashboard from '../nutrition/AdminNutritionDashboard'
import AdminNutritionCards from '../nutrition/AdminNutritionCards'
import AdminNutritionCard from '../nutrition/AdminNutritionCard'
import AdminVehicle from '../vehicles/AdminVehicle'
import AdminVehicles from '../vehicles/AdminVehicles'
import AdminTicket from '../tickets/AdminTicket'
import AdminTickets from '../tickets/AdminTickets'
import AdminWarehouse from '../warehouses/AdminWarehouse'
import AdminWarehouses from '../warehouses/AdminWarehouses'
import AdminWarehouseProducts from '../inventory/AdminWarehouseProducts'
import AdminUser from '../users/AdminUser'
import AdminDashboard from '../dashboard/AdminDashboard'
import AdminEditableEmails from 'admin/components/emails/AdminEditableEmails'
import AdminEditableEmail from 'admin/components/emails/AdminEditableEmail'
import AdminEditableEmailPreview from 'admin/components/emails/AdminEditableEmailPreview'
import AdminEditableEmailCustomParameters from 'admin/components/emails/AdminEditableEmailCustomParameters'
import AdminEmailEditor from 'admin/components/emails/AdminEmailEditor'
import AdminEditableEmailCustomParameter from 'admin/components/emails/AdminEditableEmailCustomParameter'
import AdminBeneficiaryParcelReport from '../reports/AdminBeneficiaryParcelReport'
import { AdminBeneficiaryParcelTrackingReport } from '../reports/AdminBeneficiaryParcelTrackingReport'
import AdminBringgConfiguration from 'admin/components/integrations/bringg/AdminBringgConfiguration'
import AdminRoadMap from 'admin/components/configuration/AdminRoadMap'
import AdminChecklists from 'admin/components/checklists/AdminChecklists'
import AdminChecklist from 'admin/components/checklists/AdminChecklist'
import AdminChecklistSchedule from 'admin/components/checklists/AdminChecklistSchedule'
import AdminChecklistSchedules from 'admin/components/checklists/AdminChecklistSchedules'
import AdminChecklistResults from 'admin/components/checklists/AdminChecklistResults'
import AdminChecklistQuestions from 'admin/components/checklists/AdminChecklistQuestions'
import AdminChecklistResultForm from 'admin/components/checklists/form/AdminChecklistResultForm'
import AdminChecklistQuestion from 'admin/components/checklists/AdminChecklistQuestion'
import AdminKpiDashboards from 'admin/components/kpis/AdminKpiDashboards'
import AdminKpiDashboard from 'admin/components/kpis/AdminKpiDashboard'
import AdminKpiDashboardCategories from 'admin/components/kpis/AdminKpiDashboardCategories'
import AdminKpiDashboardCategory from 'admin/components/kpis/AdminKpiDashboardCategory'
import AdminKpiTemplate from 'admin/components/kpis/AdminKpiTemplate'
import AdminKpiDashboardUsers from 'admin/components/kpis/AdminKpiDashboardUsers'
import AdminKpiDashboardUser from 'admin/components/kpis/AdminKpiDashboardUser'
import AdminKpiResults from 'admin/components/kpis/AdminKpiResults'
import AdminReports from 'admin/components/reports/AdminReports'
import AdminNotificationModels from 'admin/components/notifications/AdminNotificationModels'
import AdminNotifications from 'admin/components/notifications/AdminNotifications'
import AdminNotification from 'admin/components/notifications/AdminNotification'
import AdminDemographics from 'admin/components/demographics/AdminDemographics'
import AdminDemographic from 'admin/components/demographics/AdminDemographic'
import AdminMealtimes from 'admin/components/feeding/AdminMealtimes'
import AdminMealtime from 'admin/components/feeding/AdminMealtime'
import AdminFeedingSchedules from 'admin/components/feeding/AdminFeedingSchedules'
import AdminFeedingSchedule from 'admin/components/feeding/AdminFeedingSchedule'
import AdminFeedingFrequency from 'admin/components/feeding/AdminFeedingFrequency'
import AdminFeedingFrequencies from 'admin/components/feeding/AdminFeedingFrequencies'
import { isLoggedIn, loggedInUser } from 'actions/auth'
import AdminBeneficiaryDynamicReport from '../reports/AdminBenificiaryDynamicReport';
import PrintableReport from '../../../components/charts/dynamic_reporting/PrintableReport';


class AdminRouter extends Component {

    componentDidUpdate(prevProps) {
        const { is_logged_in, history, has_usable_password, is_superuser } = this.props
        if (!prevProps.is_logged_in && is_logged_in) {
            if (is_superuser) {
                history.push('/admin/dashboard')
            } else {
                history.push('/')
            }
        }
        if (prevProps.is_logged_in && !is_logged_in) {
            history.push('/')
        }
    }

    render() {

        const { is_logged_in, is_superuser, is_staff, history } = this.props
        if ( ! is_logged_in ) {
            return (
                <div>
                  Not logged in
                </div>
            )
        }

        return (
            <div>
              <Switch>
                <Route path="/admin/users" exact={ true } component={ AdminUsers } />
                <Route path="/admin/user/:user_id" component={ AdminUser } />
                <Route path="/admin/profile/:user_id" component={ AdminUser } />
                <Route path="/admin/user" exact={ true } component={ AdminUser } />

                <Route path="/admin/release_notes" exact={ true } component={ AdminReleaseNotes } />

                <Route path="/admin/logistics_companies" exact={ true } component={ AdminLogisticsCompanies } />
                <Route path="/admin/logistics_company/:logistics_company_id" exact={ true } component={ AdminLogisticsCompany } />
                <Route path="/admin/logistics_company" exact={ true } component={ AdminLogisticsCompany } />

                <Route path="/admin/donors" exact={ true } component={ AdminDonors } />
                <Route path="/admin/donor/:donor_id" exact={ true } component={ AdminDonor } />
                <Route path="/admin/donor" exact={ true } component={ AdminDonor } />

                <Route path="/admin/beneficiaries" exact={ true } component={ AdminBeneficiaries } />
                <Route path="/admin/beneficiary/:beneficiary_id" exact={ true } component={ AdminBeneficiary } />
                <Route path="/admin/beneficiary" exact={ true } component={ AdminBeneficiary } />

                <Route path="/admin/email/editor" exact={ true } component={ AdminEmailEditor } />
                <Route path="/admin/editable_emails" exact={ true } component={ AdminEditableEmails } />
                <Route path="/admin/editable_email/preview/:editable_email_id" exact={ true } component={ AdminEditableEmailPreview } />
                <Route path="/admin/editable_email/:editable_email_id" exact={ true } component={ AdminEditableEmail } />
                <Route path="/admin/editable_email" exact={ true } component={ AdminEditableEmail } />

                <Route path="/admin/editable_email_custom_parameters" exact={ true } component={ AdminEditableEmailCustomParameters } />
                <Route path="/admin/editable_email_custom_parameter/" exact={ true } component={ AdminEditableEmailCustomParameter } />
                <Route path="/admin/editable_email_custom_parameter/:editable_email_custom_parameter_id" exact={ true } component={ AdminEditableEmailCustomParameter } />

                <Route path="/admin/calendar" exact={ true } component={ AdminCalendar } />

                <Route path="/admin/parcels" exact={ true } component={ AdminParcels } />
                <Route path="/admin/parcel/:parcel_id" exact={ true } component={ AdminParcel } />
                <Route path="/admin/orders/donor/new/:transport_action" children={ <AdminDonorParcel companyType={'donor'} newOrder={true} /> } />
                <Route path="/admin/orders/beneficiary/new/:transport_action"  children={ <AdminBeneficiaryParcel companyType={'beneficiary'} newOrder={true} /> } />
                <Route path="/admin/orders/beneficiary/:parcel_id/tracking_report" children={ <AdminBeneficiaryParcelTrackingReport /> } />
                <Route path="/admin/orders/donor/:parcel_id" children={ <AdminDonorParcel companyType={'donor'} /> } />
                <Route path="/admin/orders/beneficiary/:parcel_id" children={ <AdminBeneficiaryParcel companyType={'beneficiary'} /> } />

                <Route path="/admin/money_donations" exact={ true } component={ AdminMoneyDonations } />
                <Route path="/admin/money_donation/:money_donation_id" exact={ true } component={ AdminMoneyDonation } />
                <Route path="/admin/money_donation" exact={ true } component={ AdminMoneyDonation } />

                <Route path="/admin/checklists" exact={ true } component={ AdminChecklists } />
                <Route path="/admin/checklists/schedules" exact={ true } component={ AdminChecklistSchedules } />
                <Route path="/admin/checklists/schedule/:checklist_schedule_id" exact={ true } component={ AdminChecklistSchedule } />
                <Route path="/admin/checklists/schedule" exact={ true } component={ AdminChecklistSchedule } />
                <Route path="/admin/checklists/results/:checklist_result_id" exact={ true } component={ AdminChecklistResultForm } />
                <Route path="/admin/checklist/:checklist_id" exact={ true } component={ AdminChecklist } />
                <Route path="/admin/checklist" exact={ true } component={ AdminChecklist } />

                <Route path="/admin/checklist/questions" exact={ true } component={ AdminChecklistQuestions } />
                <Route path="/admin/checklist/question/:checklist_question_id" exact={ true } component={ AdminChecklistQuestion } />
                <Route path="/admin/checklist/question" exact={ true } component={ AdminChecklistQuestion } />

                <Route path="/admin/checklists/results" exact={ true } component={ AdminChecklistResults } />
                <Route path="/admin/checklists/results/:checklist_schedule_id" exact={ true } component={ AdminChecklistResults } />

                <Route path="/admin/kpis/dashboards" exact={ true } component={ AdminKpiDashboards } />
                <Route path="/admin/kpis/dashboard/:kpi_dashboard_id" exact={ true } component={ AdminKpiDashboard } />
                <Route path="/admin/kpis/dashboard" exact={ true } component={ AdminKpiDashboard } />

                <Route path="/admin/kpis/dashboard_categories" exact={ true } component={ AdminKpiDashboardCategories } />
                <Route path="/admin/kpis/dashboard_category/:kpi_dashboard_category_id" exact={ true } component={ AdminKpiDashboardCategory } />
                <Route path="/admin/kpis/dashboard_category" exact={ true } component={ AdminKpiDashboardCategory } />

                <Route path="/admin/kpis/dashboards/users" exact={ true } component={ AdminKpiDashboardUsers } />
                <Route path="/admin/kpis/dashboards/user/:user_id" exact={ true } component={ AdminKpiDashboardUser } />

                <Route path="/admin/kpis/results" exact={ true } component={ AdminKpiResults } />

                <Route path="/admin/kpis/kpi_template/:kpi_template_id" exact={ true } component={ AdminKpiTemplate } />
                <Route path="/admin/kpis/kpi_template" exact={ true } component={ AdminKpiTemplate } />

                <Route path="/admin/:company_id/company_reports" exact={ true } component={ AdminCompanyReports } />
                <Route path="/admin/:company_id/company_report/:company_report_id" exact={ true } component={ AdminCompanyReport } />
                <Route path="/admin/:company_id/company_report" exact={ true } component={ AdminCompanyReport } />

                <Route path="/admin/drivers" exact={ true } component={ AdminDrivers } />
                <Route path="/admin/driver/:driver_id" exact={ true } component={ AdminDriver } />
                <Route path="/admin/driver" exact={ true } component={ AdminDriver } />

                <Route path="/admin/cities" exact={ true } component={ AdminCities } />
                <Route path="/admin/city/:city_id" exact={ true } component={ AdminCity } />
                <Route path="/admin/city" exact={ true } component={ AdminCity } />

                <Route path="/admin/tags" exact={ true } component={ AdminTags } />
                <Route path="/admin/tag/:tag_id" exact={ true } component={ AdminTag } />
                <Route path="/admin/tag" exact={ true } component={ AdminTag } />

                <Route path="/admin/contact_roles" exact={ true } component={ AdminContactRoles } />
                <Route path="/admin/contact_role/:contact_role_id" exact={ true } component={ AdminContactRole } />
                <Route path="/admin/contact_role" exact={ true } component={ AdminContactRole } />

                <Route path="/admin/core_businesses" exact={ true } component={ AdminCoreBusinesses } />
                <Route path="/admin/core_business/:core_business_id" exact={ true } component={ AdminCoreBusiness } />
                <Route path="/admin/core_business" exact={ true } component={ AdminCoreBusiness } />

                <Route path="/admin/starting_values" exact={ true } component={ AdminStartingValues } />
                <Route path="/admin/starting_value/:starting_value_id" exact={ true } component={ AdminStartingValue } />
                <Route path="/admin/starting_value" exact={ true } component={ AdminStartingValue } />

                <Route path="/admin/products" exact={ true } component={ AdminProducts } />
                <Route path="/admin/product/:product_id" exact={ true } component={ AdminProduct } />
                <Route path="/admin/product" exact={ true } component={ AdminProduct } />

                <Route path="/admin/manufacturers" exact={ true } component={ AdminManufacturers } />
                <Route path="/admin/manufacturer/:manufacturer_id" exact={ true } component={ AdminManufacturer } />
                <Route path="/admin/manufacturer" exact={ true } component={ AdminManufacturer } />

                <Route path="/admin/brands" exact={ true } component={ AdminBrands } />
                <Route path="/admin/brand/:brand_id" exact={ true } component={ AdminBrand } />
                <Route path="/admin/brand" exact={ true } component={ AdminBrand } />

                <Route path="/admin/uoms" exact={ true } component={ AdminUOMs } />
                <Route path="/admin/uom/:uom_id" exact={ true } component={ AdminUOM } />
                <Route path="/admin/uom" exact={ true } component={ AdminUOM } />

                <Route path="/admin/nutrients" exact={ true } component={ AdminNutrients } />
                <Route path="/admin/nutrient/:nutrient_id" exact={ true } component={ AdminNutrient } />
                <Route path="/admin/nutrient" exact={ true } component={ AdminNutrient } />

                <Route path="/admin/demographics" exact={ true } component={ AdminDemographics } />
                <Route path="/admin/demographic/:demographic_id" exact={ true } component={ AdminDemographic } />
                <Route path="/admin/demographic" exact={ true } component={ AdminDemographic } />

                <Route path="/admin/mealtimes" exact={ true } component={ AdminMealtimes } />
                <Route path="/admin/mealtime/:mealtime_id" exact={ true } component={ AdminMealtime } />
                <Route path="/admin/mealtime" exact={ true } component={ AdminMealtime } />

                <Route path="/admin/feeding_schedules" exact={ true } component={ AdminFeedingSchedules } />
                <Route path="/admin/feeding_schedule/:feeding_schedule_id" exact={ true } component={ AdminFeedingSchedule } />
                <Route path="/admin/feeding_schedule" exact={ true } component={ AdminFeedingSchedule } />

                <Route path="/admin/feeding_frequencies" exact={ true } component={ AdminFeedingFrequencies } />
                <Route path="/admin/feeding_frequency/:feeding_frequency_id" exact={ true } component={ AdminFeedingFrequency } />
                <Route path="/admin/feeding_frequency" exact={ true } component={ AdminFeedingFrequency } />

                <Route path="/admin/food_items" exact={ true } component={ AdminFoodItems } />
                <Route path="/admin/food_item/:food_item_id" exact={ true } component={ AdminFoodItem } />
                <Route path="/admin/food_item" exact={ true } component={ AdminFoodItem } />

                {/* Nutrition */}
                <Route path="/admin/nutrition_dashboard" exact={ true } component={ AdminNutritionDashboard } />
                <Route path="/admin/nutrition_cards" exact={ true } component={ AdminNutritionCards } />
                <Route path="/admin/nutrition_card/:nutrition_card_id" exact={ true } component={ AdminNutritionCard } />
                <Route path="/admin/nutrition_card" exact={ true } component={ AdminNutritionCard } />

                <Route path="/admin/product_categories" exact={ true } component={ AdminProductCategories } />
                <Route path="/admin/product_category/:product_category_id" exact={ true } component={ AdminProductCategory } />
                <Route path="/admin/product_category" exact={ true } component={ AdminProductCategory } />

                <Route path="/admin/vehicles" exact={ true } component={ AdminVehicles } />
                <Route path="/admin/vehicle/:vehicle_id" exact={ true } component={ AdminVehicle } />
                <Route path="/admin/vehicle" exact={ true } component={ AdminVehicle } />

                <Route path="/admin/tickets" exact={ true } component={ AdminTickets } />
                <Route path="/admin/ticket/:ticket_id" exact={ true } component={ AdminTicket } />
                <Route path="/admin/ticket" exact={ true } component={ AdminTicket } />

                <Route path="/admin/warehouses" exact={ true } component={ AdminWarehouses } />
                <Route path="/admin/warehouse/:warehouse_id" exact={ true } component={ AdminWarehouse } />
                <Route path="/admin/warehouse" exact={ true } component={ AdminWarehouse } />

                <Route path="/admin/inventory/list" exact={ true } component={ AdminWarehouseProducts } />

                <Route path="/admin/reports/beneficiary_parcels" exact={ true } component={ AdminBeneficiaryParcelReport } />
                <Route path="/admin/reports/dynamic_reporting" exact={ true } component={ AdminBeneficiaryDynamicReport } />
                <Route path="/admin/reports/dynamic_report" exact={ true } component={ PrintableReport } />
                <Route path="/admin/reports" exact={ true } component={ AdminReports } />

                <Route path="/admin/bringg_configuration" exact={ true } component={ AdminBringgConfiguration } />
                <Route path="/admin/roadmap" exact={ true } component={ AdminRoadMap } />

                <Route path="/admin/notification_models" exact={ true } component={ AdminNotificationModels } />

                <Route path="/admin/notifications" exact={ true } component={ AdminNotifications } />
                <Route path="/admin/notification/:notification_id" exact={ true } component={ AdminNotification } />
                <Route path="/admin/notification" exact={ true } component={ AdminNotification } />


                <Route path="/admin" component={ AdminDashboard } />
              </Switch>
            </div>
        )
    }
}

function mapStateToProps(state) {
    const is_logged_in = isLoggedIn()
    let has_usable_password = true
    let is_superuser = false
    let is_staff = false
    if (is_logged_in) {
        const user = loggedInUser()
        has_usable_password = user && user['has_usable_password']
        is_superuser = user && user['is_superuser']
        is_staff = user && user['is_staff']
    }
    return {
        is_logged_in: is_logged_in,
        has_usable_password: has_usable_password,
        is_superuser: is_superuser,
        is_staff: is_staff
    }
}
export default withRouter(connect(mapStateToProps)(AdminRouter))
