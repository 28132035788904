import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { forEach, head, get, map, keys, split, size } from 'lodash'
import { Separator } from 'components/layout/Separator'
import { Formik, Form, Field } from 'formik'
import { FormikDateTimePicker } from 'components/form/DatetimePicker'
import { FormikDropdownField } from 'components/form/Dropdown'
import * as Yup from 'yup'
import {showSuccess, showError} from 'actions/Error'
import { handleSubmitResult } from 'actions/form'
import Timestamp from 'components/Timestamp'
import AdminCommonUserForm from 'admin/components/users/form/AdminCommonUserForm'
import { adminDriverLicenceOptions } from 'admin/actions/admin_dropdown_options'
import { FormikVehicleAutoCompleteDropdown } from 'admin/components/vehicles/form/VehicleAutoCompleteDropdown'
import { FormikInputField } from 'components/form/InputField'
import { FormikBringgStatus } from 'admin/components/integrations/bringg/BringgStatus'
import { CitySelectField } from 'components/form/CitySelectField'
import { Grid } from '@material-ui/core'
import { Card } from 'components/layout/Card'

export const validationSchema = Yup.object().shape({})

export const AdminDriverForm = ({formik_props}) => {

    const dispatch = useDispatch()
    const drivers_licence_options = useSelector(() => adminDriverLicenceOptions.getAsOptions())

    useEffect(() => {
        async function fetchStaticObjects() {
            dispatch(adminDriverLicenceOptions.fetchListIfNeeded())
        }
        fetchStaticObjects()
    }, [])

    return (
        <Grid container spacing={2}>
          <Grid item xs={12} md={6} lg={8}>
            <Card content={<><FormikInputField name="driver_licence"
                                label="Drivers licence"
                                formik_props={formik_props}
                                />

                              <FormikDropdownField name="drivers_licence_code"
                                   formik_props={formik_props}
                                   options={drivers_licence_options}
                                   empty_selection_label="No code"
                                   placeholder="Licence code" />

                              <CitySelectField name="operating_city"
                                               label="Operating city"
                                               formik_props={formik_props}
                              />

                              <FormikVehicleAutoCompleteDropdown name="preferred_vehicle"
                                                                 label="Vehicle"
                                                                 formik_props={formik_props}
                              />
                            </>
                          } />
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
              <Card title={"Licence Expiry Dates"}
                    content={<>
                              <FormikDateTimePicker label='Drivers licence'
                                    name='driver_licence_expires_at'
                                    helperText="Select expiry date"
                                    formik_props={formik_props}
                                    include_time={false} />

                                  <FormikDateTimePicker label='PDP licence'
                                    name='pdp_licence_expiry_date'
                                    helperText="Select expiry date"
                                    formik_props={formik_props}
                                    include_time={false} />
                              </>
                          }
              />

              <FormikBringgStatus formik_props={formik_props} can_edit_bringg_ref={true} />
            </Grid>
        </Grid>
    )
}
