import React from 'react'
import { FormikGeneralFormErrors } from 'components/form/GeneralFormErrors'
import AdminMainLayout from 'admin/components/layout/AdminMainLayout'
import { CircularProgress, Button, Paper } from '@material-ui/core'
import Loading from 'components/Loading'
import { Formik, Form } from 'formik'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: 15,
    border: '1px solid #efefef',
    background: '#ffffff',
    [theme.breakpoints.down('sm')]: {
      border: 'none',
      padding: 0,
      background: 'transparent'
    },
},
  noCard: {
    padding: 0,
    border: 'none',
    background: 'transparent'
  },
  saveBar: {
    position: 'fixed',
    bottom: 0,
    right: 0,
    width: '100%',
    padding: 15,
    zIndex: 99,
    background: '#ffffff',
    boxShadow: '0px -4px 3px rgba(239, 239, 239, 0.5)'
  },
  buttonProgress: {
    color: '#ffffff',
    marginRight: 10,
  }
}))

const AdminCommonFormLayout = ({
  breadcrumbs,
  actions,
  is_busy,
  is_loading,
  isSubmitting,
  title,
  initial_values,
  onSave,
  validationSchema,
  save_label,
  children,
  noCard,
  afterFormChildren,
  in_drawer,
}) => {
    const classes = useStyles()
    const paperClass = noCard ? classes.noCard : classes.paper

    const renderForm = () => {
      return(
        <>
        <Formik
          initialValues={ initial_values }
          onSubmit={ onSave }
          enableReinitialize={true}
          validationSchema={ validationSchema }
        >
          { formik_props => {
              const { values } = formik_props
              return (
                <Form>
                  <FormikGeneralFormErrors render={(msg) => msg} />
                    { children({formik_props: formik_props}) }
                    <div className={classes.saveBar}>
                      <Button variant="contained" size="large" type="submit" color="primary" disabled={isSubmitting} style={{float: "right"}}>

                        { is_busy ?
                          <><CircularProgress size={28}  className={classes.buttonProgress} /> Saving...</> :
                          <>{save_label || "SAVE"}</>
                        }
                      </Button>
                    </div>
                </Form>
              )}
          }
        </Formik>
        { afterFormChildren }
        </>
      )
    }

    const renderLayout = () => {
      return(
        <AdminMainLayout breadcrumbs={breadcrumbs} actions={actions} title={title}>
          <Paper className={paperClass} square variant="outlined">
            { renderForm() }
          </Paper>
        </AdminMainLayout>
      )
    }

    return in_drawer ? renderForm() : renderLayout()

}

export default AdminCommonFormLayout
