import { ItemList } from 'orm'
import { get, map, head, filter } from 'lodash'
import { download } from 'actions/lib'

class DonorList extends ItemList {
	getEntityKey() {
		return "admin/donor"
	}

	getAsOptions() {
		const items = this.getVisibleObjects()
		return map( items, function( item ) {
			return {
				label: item.company.name,
				value: item.company.id
			}
		} )
	}

	getForCompany( { company_id } ) {
		// Assumes the matching donor is already loaded
		return head( filter( this.getAllObjects(), ( x ) => x.company.id === company_id ) )
	}

	downloadDonorReport( donor_id ) {
		return download( `admin/donor/${ donor_id }/download/` )
	}

	getDefaultAddress( donor ) {
		const default_address = head( filter( get( donor, [ "company", "addresses" ], [] ), ( address ) => address.address_type == 'delivery' ) )
		return default_address
	}

}

export const adminDonorList = new DonorList( "admin_donor__default" )
export const adminDonorAutoCompleteList = new DonorList( "admin_donor_auto_complete__default" )
