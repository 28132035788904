import React from 'react'

export const Nav = ({children}) => {

    return (
        <div>
          {children}
        </div>
    )

}

export const NavItem = ({children}) => {

    return (
        <div>
          {children}
        </div>
    )

}
