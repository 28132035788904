import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import ReactTimeout from 'react-timeout'
import { Separator } from 'components/layout/Separator'
import { Button } from 'components/layout/Button'
import { Formik, Form, FieldArray, Field } from 'formik'
import { FormikInputField } from 'components/form/InputField'
import { FormikGeneralFormErrors } from 'components/form/GeneralFormErrors'
import { handleSubmitResult } from 'actions/form'
import FormikOnChange from 'components/form/FormikAutoSave'
import { makeStyles } from '@material-ui/core'

const SEARCH_DELAY_MILLISECONDS = 1000

const useStyles = makeStyles((theme) => ({
  labelRoot: {
    paddingLeft: 15,
    fontSize: 24,
  },
  labelShinked: {
    marginLeft: 5,
    fontSize: 16
  }
}))

const BeneficiaryTableFilter = ({ handleSubmit, is_loading, label, placeholder, updateOnChange, renderFilter, initial_values, style }) => {
    const classes = useStyles
    const onFilterChanged = (filter_values, formik_props) => {
        updateOnChange(filter_values)
    }

    return (
        <div style={style}>

          <Formik
            initialValues={initial_values}
            onSubmit={onFilterChanged}
            enableReinitialize={true}
          >
            {formik_props => {
                const { values } = formik_props
                return (
                    <Form>
                      <FormikOnChange onChange={(values) => onFilterChanged(values, formik_props)} />
                      <FormikGeneralFormErrors render={(msg) => msg} />

                          { renderFilter && renderFilter(formik_props) }
                          { ! renderFilter &&
                            <FormikInputField name="any_field"
                                              label={label || "Search..."}
                                              placeholder={placeholder || "Search..."}
                                              InputLabelProps={{
                                                classes: {
                                                  root: classes.labelRoot,
                                                  shrink: classes.labelShinked
                                                }
                                              }}
                                              InputProps={{
                                                style:{
                                                  borderRadius: '40px',
                                                  paddingLeft: 15,
                                                  fontSize: 24
                                                }
                                              }}
                                              show_search_icon={true}
                            />
                          }

                          { !updateOnChange &&
                              <Button type="submit">Apply</Button>
                          }
                    </Form>
                )
            }}
          </Formik>
        </div>
    )
}

function mapStateToProps(state, props) {
    const { onSubmit, label, updateOnChange, filter } = props
    return {
        onSubmit,
        label,
        updateOnChange,
        initial_values: filter || {}
    }
}
export default withRouter(connect(mapStateToProps)(ReactTimeout(BeneficiaryTableFilter)))
