import React, { Component, useEffect } from 'react'
import { get, capitalize } from 'lodash'
import { PAYMENT_STATUS_COLOURS } from 'muiTheme'
import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
  label: {
      padding: "5px 7px",
      borderRadius: "4px",
      color: 'white',
      textTransform: 'uppercase',
      fontSize: 12
  },
}))

const PAYMENT_STATUS_OPTIONS = [
  {id: 'paid', label: 'Paid'},
  {id: 'pending', label: 'Pending'},
]

export const PaymentStatus = ({ status, ...props }) => {
    const classes = useStyles()
    const label = capitalize(status)
    const colour = get(PAYMENT_STATUS_COLOURS, status, "#ffffff")

    return (
        <span className={classes.label} style={{backgroundColor: colour}}>
          {label}
        </span>
    )

}
