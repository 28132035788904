import React, { useEffect, useState } from 'react'
import { Field, useField, FieldArray } from 'formik'
import { useDispatch, useSelector } from 'react-redux'
import { map, get, size, split } from 'lodash'
import AdminCommonListTable from '../layout/AdminCommonListTable'
import AdminContactForm from './form/AdminContactForm'
import { Separator } from 'components/layout/Separator'
import {showSuccess, showError} from 'actions/Error'
import { handleSubmitResult } from 'actions/form'
import { IconButton, Button, makeStyles, Tooltip, Grid, Typography, Avatar } from '@material-ui/core'
import { GrayLinkButton } from 'components/layout/GrayLinkButton'
import DeleteIcon from '@material-ui/icons/Delete'
import AddIcon from '@material-ui/icons/Add'
import { adminContactList } from 'admin/components/contacts/actions/admin_contact'
import { Modal } from 'components/layout/Modal'

const useStyles = makeStyles((theme) => ({
  addButton: {
    marginBottom: 15,
  },
  noContactsButton: {
    marginTop: 10
  },
  deleteButton: {
    float:'right',
  },
  addEditContact: {
    color: '#999999'
  }
}))

export const AdminContacts = ({formik_props, company_id, name}) => {
    const classes = useStyles()
    const dispatch = useDispatch()
    const contacts = useSelector(() => adminContactList.getVisibleObjects())
    const is_loading = useSelector(() => !adminContactList.isReady() && adminContactList.isLoading())
    const [editingContact, setEditingContact] = useState(null)
    const [contactId, setContactId] = useState(null)
    const [addEditLabel, setAddEditLabel] = useState(null)

    useEffect(() => {
        dispatch(adminContactList.updateListFilter({company: company_id}))
        dispatch(adminContactList.fetchListIfNeeded({company: company_id}))
    }, [company_id])

    const onAddContact = () => {
        setContactId(false)
        setEditingContact({company: company_id})
    }

    const onEditContact = (contact_id) => {
        setContactId(contact_id)
        setAddEditLabel(`Editing ${contact_id}`)
        setEditingContact({company: company_id})
    }

    const onStopEditingContact = () => {
        dispatch(adminContactList.invalidateList())
        dispatch(adminContactList.fetchListIfNeeded())
        setEditingContact(null)
    }

    const onRemoveContact = (contact) => {

        if (! window.confirm("Are you sure you want to delete this contact?") ) {
            return
        }

        const onDone = (res) => {
            if ( res.errors ) {
                showError("Failed", "Contact is in use and could not be deleted")
            } else {
                showSuccess("Deleted", "Contact deleted")
                dispatch(adminContactList.invalidateList())
                dispatch(adminContactList.fetchListIfNeeded())
            }
        }

        dispatch(adminContactList.deleteObject(contact.id)).then(onDone)
    }

    const columns = [
      {
        field: 'profile_images',
        title: 'Image',
        width: 60,
        align: 'center',
        render: (item) => <Avatar
                              alt={`${get(item, 'first_name')} ${get(item, 'last_name')}`}
                              src={get(item, ["profile_images", "files", 0, "original_download_url"])}
                              style={{width: 60, height: 60, cursor: 'pointer'}}
                              onClick={() => onEditContact(get(item, 'id'))}
                          />
      },
      {
        field: 'last_name',
        title: 'Name',
        render: (item) => <a onClick={() => onEditContact(get(item, 'id'))}>{get(item, "first_name")} {get(item, "last_name")}</a>
      },
      {
        field: 'role',
        title: 'Position',
      },
      {
        field: 'email',
        title: 'Email',
      },
      {
        field: 'mobile',
        title: 'Mobile',
      }
    ]

    return (
        <>
            <AdminCommonListTable
                add_label="Add Contact"
                title="Contact"
                onAddRow={onAddContact}
                onEditRow={onEditContact}
                is_loading={is_loading}
                handleRequestSort={columns}
                // enableAnyFieldFilter={true}
                // useColumnsSelector={true}
                columns={columns}
                item_list={adminContactList}
                inTabs
                // storeTableName={"adminDonorList"}
            />

            { editingContact &&
                <Modal title={addEditLabel} onClose={() => setEditingContact(false)}>
                    <AdminContactForm company_id={company_id} contact_id={contactId} setEditingContact={setEditingContact} />
                </Modal>
            }

        </>
    )

}
