import { upperCase } from "lodash"

export const ShortId = ( { value, ...props } ) => {
    if ( ! value ) {
        return ""
    }
    return (
        upperCase(value.slice(value, 6))
    )
}
