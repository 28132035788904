import { useState } from 'react'
import { get } from 'lodash'
import { makeStyles, Typography, CardContent, CardActions, Grid, Divider, IconButton } from '@material-ui/core'
import clsx from 'clsx'
import MuiCard from '@material-ui/core/Card'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import Collapse from '@material-ui/core/Collapse'
import { StatusIndicator } from 'components/layout/StatusIndicator'

const useStyles = makeStyles((theme) => ({
    root: {
        position: 'relative',
    },
    success: {
        background: "#00c853",
    },
    fail: {
        background: "#ef5350"
    },
    icon: {
        color: 'white',
        cursor: 'pointer'
    },
    sentimentIcon: {
        fontSize: 32,
    },
    cardHeading: {
        padding: "5px 10px",
        background: '#eceff1'
    },
    cardSubHeading: {
        color: '#777777',
        fontSize: 14,
    },
    expand: {
        transform: 'rotate(0deg)',
        transition: theme.transitions.create('transform', {
            duration: theme.transitions.duration.shortest,
        }),
    },
    expandOpen: {
        transform: 'rotate(180deg)',
    },
    collapseIcon: {
        position: 'absolute',
        top: 0,
        right: 0
    },
    noPadding: {
      [theme.breakpoints.down('sm')]: {
        padding: 0,
        margin: 0,
      },
      [theme.breakpoints.up('md')]: {

      },
      [theme.breakpoints.up('lg')]: {

      },
    }
}))


export function Card({title, status, subtitle, content, variant, notSquare, noPadding, is_open=true, card_actions}) {
    const classes = useStyles()
    const [expanded, setExpanded] = useState(is_open)
    variant = variant ? variant : 'outlined'
    notSquare = notSquare ? false : true

    const handleExpandClick = () => {
        setExpanded(!expanded);
    }

    return (
        <MuiCard variant={variant} square={notSquare} className={classes.root}>
            { title &&
                <>
                <Grid container className={classes.cardHeading} justifyContent="space-between" alignItems="center">
                    <Grid item>
                        <Typography variant="h6">
                            {status && <StatusIndicator status={status} style={{ marginBottom: -4, marginRight: 7 }} /> }{title}
                        </Typography>
                        <div className={classes.collapseIcon}>
                            <IconButton
                                className={clsx(classes.expand, {
                                    [classes.expandOpen]: expanded,
                                })}
                                onClick={handleExpandClick}
                                aria-expanded={expanded}
                                aria-label="shoSentimentVeryDissatisfiedIconw more"
                                >
                                <ExpandMoreIcon />
                            </IconButton>
                        </div>
                    </Grid>
                    { subtitle &&
                        <Grid item>
                            <Typography className={classes.cardSubHeading}>{subtitle}</Typography>
                        </Grid>
                    }
                </Grid>
                <Divider />
                </>
            }
            <Collapse in={expanded} timeout="auto" unmountOnExit>
                { noPadding ?
                    <div className={classes.noPadding}>
                    { content }
                    </div> :
                    <CardContent>
                        {content}
                    </CardContent>
                }
                { card_actions &&
                    <CardActions>
                        { card_actions }
                    </CardActions>
                }
            </Collapse>

        </MuiCard>
    )
}
