import { CircularProgress, Grid } from '@material-ui/core'

export default function Loading({ message, size, color, margin }) {
    color = color ? color : '#78909c'
    margin = margin ? margin : 15
    return (
        <Grid
            container
            spacing={2}
            direction="column"
            justifyContent="center"
            alignItems="center"
        >
            
            <Grid item>
                <CircularProgress style={{color: color, margin: margin}} size={size} />
            </Grid>
            { message &&
                <Grid item xs={12} style={{margin: 15}}>
                    <span style={{color: color}}>{ message }</span>
                </Grid>
            }

        </Grid>
    )
}
