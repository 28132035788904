import * as Yup from 'yup'
import { FormikInputField } from 'components/form/InputField'
import { FormikTextareaField } from 'components/form/TextareaField'
import { FormikFileSetField } from 'components/form/FileSetField'
import { Separator } from 'components/layout/Separator'

export const validationSchema = Yup.object().shape({
    'name': Yup.string().required("Required"),
})

export const AdminUOMForm = ({ formik_props }) => {

    return (
        <>
          <FormikInputField
            name="name"
            label="Name"
            formik_props={formik_props}
          />
          <FormikInputField
            name="code"
            label="Code"
            formik_props={formik_props}
          />
          <FormikInputField
            name="quantity"
            label="Quantity"
            formik_props={formik_props}
          />
          <FormikTextareaField
              name="description"
              label="Description"
              rows={5}
              formik_props={formik_props}
          />
        </>
    )
}
