import { ItemList } from 'orm'
import { map, head, filter } from 'lodash'
import moment from 'moment'

class AdminCompanyReportGeneration extends ItemList {
    getEntityKey() {
        return "admin/company_report_generation"
    }

}

export const adminCompanyReportGeneration = new AdminCompanyReportGeneration("admin_company_report_generation__default")

