import React from "react"
import { map, round, get } from 'lodash'
import { makeStyles, Drawer, IconButton, Divider, Table, TableRow, TableCell, TableHead, Typography, Grid, LinearProgress } from "@material-ui/core"
import HighlightOffIcon from '@material-ui/icons/HighlightOff'
import NumberFormat from 'react-number-format'
import { getCartTotalWeightKg } from 'components/orders/Cart'

const useStyles = makeStyles((theme) => ({
    title: {
        flexGrow: 1,
    },
    hide: {
        display: 'none',
    },
    drawer: {
        width: drawerWidth,
        flexShrink: 0,
    },
    drawerPaper: {
        width: drawerWidth,
    },
    drawerHeaderTitle: {
        padding: '15px'
    },
    cart_total_weight: {
        padding: 15,
        fontSize: 36,
        textAlign: 'center',
        background: '#FADF01'
    }
}));

const drawerWidth = 320

export default function AdminBeneficiaryParcelCart({cartItems, open, handleDrawerClose, is_loading}) {
    const classes = useStyles()
    const cart_total_weight_kg = round(getCartTotalWeightKg(cartItems), 2)

    return(
        <Drawer
            className={classes.drawer}
            variant="persistent"
            anchor="left"
            open={open}
            classes={{
                paper: classes.drawerPaper,
            }}
            >
            <Grid container justifyContent="space-between">
                <Grid item className={classes.drawerHeaderTitle}>
                    <Typography variant="h6">Parcel Products</Typography>
                </Grid>
                <Grid item>
                    <IconButton onClick={handleDrawerClose}>
                        <HighlightOffIcon fontSize="large" />
                    </IconButton>
                </Grid>
            </Grid>
            <Divider />
            <div className={classes.cart_total_weight}>
                <Typography>Total Parcel Weight</Typography>
                {cart_total_weight_kg}kg
            </div>
            <Divider />
            { is_loading && <LinearProgress /> }
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>Product</TableCell>
                        <TableCell>Weight</TableCell>
                    </TableRow>
                </TableHead>
                { map( cartItems, function( item ) {
                    console.log( item.warehouse_product )
                    return(
                        <TableRow>
                            <TableCell>
                                <Typography style={ { fontWeight: 500 } }>{ get( item, [ "warehouse_product", "product_category_name" ], item.warehouse_product.product_name )}</Typography>
                                <Typography variant="caption">{item.quantity} x {item.warehouse_product.product_weight_kg}kg</Typography>
                            </TableCell>
                            <TableCell style={{ verticalAlign: 'top' }}>
                                <NumberFormat value={item.quantity * item.warehouse_product.product_weight_kg} displayType={'text'} thousandSeparator={true} suffix={'kg'} decimalScale={2} />
                            </TableCell>
                        </TableRow>
                    )
                })}
            </Table>
        </Drawer>
    )
}
