import React from "react";
import { BranchMetricsChartOptions } from "./ChartOptions";
import { Bar } from "react-chartjs-2";

const BranchMetricChart = React.forwardRef(({ bars }, ref) =>{
  return (
      <Bar className="chartjsbar" options={BranchMetricsChartOptions} data={bars} ref={ref}/>
  )
});

export default BranchMetricChart;
