import React, { Component } from 'react'
import { connect } from 'react-redux'
import { head, get } from 'lodash'
import { Trans, Translation } from 'react-i18next'
import { FormikDropdownField } from 'components/form/Dropdown'
import AdminCommonListLayout from '../layout/AdminCommonListLayout'
import Timestamp from 'components/Timestamp'
import { FormikInputField } from 'components/form/InputField'
import { adminContactRoleList } from './actions/admin_contact_role'
import { Separator } from 'components/layout/Separator'
import { ShortId } from 'components/ShortId'
import { Button } from 'components/layout/Button'
import { provinceList } from 'actions/province'
import CheckCircleIcon from '@material-ui/icons/CheckCircle'
import CancelIcon from '@material-ui/icons/Cancel'

class ContactRoles extends Component {

    componentDidMount() {
        const { dispatch } = this.props
        dispatch(adminContactRoleList.fetchListIfNeeded())
        dispatch(provinceList.updatePaginationNumItemsPerPage(1000))
        dispatch(provinceList.fetchListIfNeeded())
    }

    componentDidUpdate() {
        const { dispatch, filter } = this.props
        dispatch(adminContactRoleList.fetchListIfNeeded())
        dispatch(provinceList.fetchListIfNeeded())
    }

    onEditContactRole = (contact_role_id) => {
        const { history } = this.props
        history.push({
            pathname: '/admin/contact_role/'+contact_role_id
        })
    }

    onAddContactRole = () => {
        const { history } = this.props
        history.push('/admin/contact_role')
    }

    onUpdateListOrdering = (field) => {
      const { dispatch } = this.props
      dispatch(adminContactRoleList.updateListOrdering(field))
    }

    render() {
        const { is_loading, contact_roles } = this.props
        const that = this

        const columns = [
            { field: 'name', title: 'Contact Role',
            },
            { field: 'description', title: 'Description',
            },
            { field: 'published', title: 'Published',
              render: (item) => item.published ? <CheckCircleIcon style={{color: 'green'}} /> : <CancelIcon style={{color: 'red'}} />
            },
        ]

        return (
            <AdminCommonListLayout active_key="contact_roles"
                                   breadcrumbs={[{name: 'admin_home'},
                                                 {name: 'contact_roles', label: "Contact Roles", url: '/admin/contact_roles'}]}
                                   add_label="Add Contact Role"
                                   onAddRow={that.onAddContactRole}
                                   onEditRow={that.onEditContactRole}
                                   is_loading={is_loading}
                                   columns={columns}
                                   item_list={adminContactRoleList}
            />
        )
    }
}

function mapStateToProps(state, props) {
    const contact_roles = adminContactRoleList.getVisibleObjects()

    return {
        contact_roles,
        is_loading: adminContactRoleList.isLoading(),
        filter: adminContactRoleList.getFilter()
    }
}

export default connect(mapStateToProps)(ContactRoles)
