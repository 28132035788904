import React, { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { showSuccess, showError } from 'actions/Error'
import { map, get, size, includes, filter, join, isArray, toArray, update } from 'lodash'
import { FormikInputBase } from 'components/form/InputBase'
import { Formik, Form } from 'formik'
import { Button } from 'components/layout/Button'
import FormikOnChange from 'components/form/FormikAutoSave'
import {
  Checkbox,
  FormControl,
  Grid,
  IconButton,
  lighten,
  Input,
  ListItemText,
  makeStyles,
  withStyles,
  MenuItem,
  Paper,
  Select,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TablePagination,
  TableSortLabel,
  AppBar,
  Toolbar,
  Typography,
  LinearProgress,
} from '@material-ui/core'
import MuiTableCell from "@material-ui/core/TableCell"
import MuiTableRow from "@material-ui/core/TableRow"
import SearchIcon from '@material-ui/icons/Search'
import { useDispatch } from 'react-redux'
import { updateListPagination, invalidateList, getPagination } from '../orm/orm_list_actions'
import SyncIcon from '@material-ui/icons/Sync'
import FilterChips from 'admin/components/filters/FilterChips'
import PopupFilter from 'admin/components/filters/PopupFilter.js'
import ViewWeekSharpIcon from '@material-ui/icons/ViewWeekSharp'
import { getStoredVisibleTableColumns, setStoredVisibleTableColumns } from 'actions/stored_table_columns'
import { TableRowContextMenu, TableRowActions, TablePaginationActions } from 'components/layout/table'
import EmptyRows from './EmptyRows'
import Loading from './Loading'

const useToolbarStyles = makeStyles( ( theme ) => ( {
  root: {
    flexGrow: 1,
    width: '100%'
  },
  highlight:
    theme.palette.type === 'light'
      ? {
        color: theme.palette.secondary.main,
        backgroundColor: lighten( theme.palette.secondary.light, 0.85 )
      }
      : {
        color: theme.palette.text.primary,
        backgroundColor: theme.palette.secondary.dark
      },
  title: {
    flex: '1 1 100%'
  },
  filterRow: {
    marginLeft: 70,
    marginRight: 15,
    marginTop: 5,
    marginBottom: 5
  }
} ) )

const useStyles = makeStyles( ( theme ) => ( {
  root: {
    flexGrow: 1,
  },
  tableHead: {
    background: 'black'
  },
  menuButton: {
    marginRight: theme.spacing( 2 ),
  },
  title: {
    flexGrow: 1,
    display: 'none',
    [ theme.breakpoints.up( 'sm' ) ]: {
      display: 'block',
    },
  },
  appBar: {
    background: 'white',
  },
  toolBar: {
    boxShadow: '0px 5px 5px -5px lightgrey inset',
    marginTop: 0,
    padding: 0,
    paddingLeft: 0,
    paddingRight: 0
  },
  search: {
    position: 'relative',
    marginLeft: 0,
    width: '100%',
    [ theme.breakpoints.up( 'sm' ) ]: {
      marginLeft: theme.spacing( 1 ),
      width: 'auto',
    },
  },
  searchIcon: {
    padding: theme.spacing( 0, 2 ),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: '#BDBDBD',
  },
  inputRoot: {
    color: 'inherit',
  },
  inputInput: {
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${ theme.spacing( 4 ) }px)`,
    transition: theme.transitions.create( 'width' ),
    color: '#666',
    width: '100%',
    fontSize: 24,
    fontWeight: 300,
    [ theme.breakpoints.up( 'sm' ) ]: {
      width: '15ch',
      '&:focus': {
        width: '30ch',
      },
    },
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing( 2 )
  },
  table: {
    minWidth: 750
  },
  filterButton: {
    paddingTop: 25,
    marginLeft: 7,
    float: 'left'
  },
  filterChips: {
    marginLeft: 10,
    overflow: 'auto',
    marginBottom: -5
  },
  selectView: {
    marginTop: 10
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1
  },
  actionBar: {
    display: "flex",
    float: 'right',
  },
  mobileCell: {
    border: 'none',
    padding: '10px 15px',
    borderTop: '1px solid #efefef',
    margin: 0,
    cursor: 'pointer'
  },
  mobileRow: {
    margin: 0,
    padding: 0
  },
  labelRoot: {
    paddingLeft: 15,
    fontSize: 24,
  },
  labelShinked: {
    marginLeft: 5,
    fontSize: 16
  },
  formControl: {
    marginTop: -10,
    marginLeft: -30
  },
  noRows: {
    padding: 30,
    textAlign: 'center'
  },
  filtersAppBar: {
    background: '#ffffff',
    border: 'none',
  },
  searchToolbar: {
    paddingLeft: 10,
    marginBottom: 5
  }
} ) )

const TableCell = withStyles( {
  root: {
    borderBottom: "none"
  }
} )( MuiTableCell )

const TableRow = withStyles( ( theme ) => ( {
  root: {
    borderBottom: "1px solid rgba(224, 224, 224, 1)",
    '&:nth-of-type(even)': {
      backgroundColor: '#f9f9f9',
    },
  },
} ) )( MuiTableRow )

const TableHeadCell = withStyles( ( theme ) => ( {
  head: {
    backgroundColor: '#f5f6f8',
    color: theme.palette.common.black,
  },
  body: {
    fontSize: 14,
  },
} ) )( MuiTableCell )

const Filter = ( props ) => {
  const {
    enhanced_filter,
    initial_filter_values,
    render_additional_filter,
    popup_filter,
    title
  } = props
  const classes = useStyles()
  const dispatch = useDispatch()

  const onFilter = ( values, formik_funcs ) => {
    dispatch( enhanced_filter.setAnyFieldFilter( values.any_field ) )
    formik_funcs.setSubmitting( false )
  }

  return (
    <Formik
      initialValues={ initial_filter_values || {} }
      onSubmit={ onFilter }
      enableReinitialize={ true }
      validationSchema={ null }
    >
      { formik_props => {
        const { values } = formik_props
        return (
          <Form>
            <FormikOnChange onChange={ ( values ) => onFilter( values, formik_props ) } />
            <div className={ classes.root }>
              <AppBar position="static" className={ classes.filtersAppBar } elevation={ 0 }>
                <Toolbar className={ classes.toolBar } elevation={ 0 }>
                  <div className={ classes.search }>
                    <div className={ classes.searchIcon }>
                      <SearchIcon />
                    </div>
                    <FormikInputBase
                      key="any_field"
                      name="any_field"
                      shrink={ true }
                      placeholder="Search..."
                      autoComplete="off"
                      classes={ {
                        root: classes.inputRoot,
                        input: classes.inputInput,
                      } }
                      inputProps={ { 'aria-label': 'search' } }
                      formik_props={ formik_props }
                    />
                  </div>
                  <Typography className={ classes.title } variant="h6" noWrap>{ title }</Typography>
                  { popup_filter }
                  { render_additional_filter && render_additional_filter( formik_props ) }

                </Toolbar>
              </AppBar>
            </div>

          </Form>
        )
      }
      }
    </Formik>
  )
}

const EnhancedTableToolbar = ( props ) => {
  const {
    canDelete,
    canFilter,
    enhanced_filter,
    initial_filter_values,
    popup_filter,
    render_additional_filter,
    title
  } = props
  const classes = useToolbarStyles()
  return (
    <>
      { canFilter !== false &&
        <Filter
          enhanced_filter={ enhanced_filter }
          initial_filter_values={ initial_filter_values }
          popup_filter={ popup_filter }
          title={ title }
        />
      }
    </>
  )
}

const MobileTableView = ( { rows, columns, actions, showActions, isSelected, handleRowClick } ) => {
  const classes = useStyles()
  return (
    <TableBody>
      { map( rows, ( row, index ) => {
        const isItemSelected = isSelected( row.name )
        const labelId = `enhanced-table-checkbox-${ index }`

        return (
          <TableRow
            hover
            role="checkbox"
            tabIndex={ -1 }
            key={ row.id }
            onClick={ () => ( handleRowClick( row, columns ) ) }
          >
            <TableCell className={ classes.mobileCell } width={ '90%' }>
              { map( columns, function( column, index ) {
                let value = null
                value = column.render ? column.render( row ) : get( row, column.field )
                return value
              } ) }
            </TableCell>
            { size( actions ) > 0 && showActions &&
              <TableCell className={ classes.actionBar } scope="row" align="right" width={ 10 }>
                <TableRowContextMenu
                  index={ index }
                  row={ row }
                  actions={ actions }
                />
              </TableCell>
            }
          </TableRow>
        )
      } ) }
    </TableBody>
  )
}

export function CommonTable( {
  columns,
  rows,
  is_loading,
  showActions = true,
  showNumbers,
  useColumnsSelector = false,
  storeTableName,
  noHeadings,
  sync = true,
  noPagination,
  bottomPagination = true,
  topPagination = false,
  rowClickRedirect,
  onSelectRow,
  canDelete,
  onEditRow,
  onViewRow,
  selectedRowIds,
  updateSelectedRowIds,
  mobile,
  item_list,
  initial_filter_values,
  showFilters = true,
  filters,
  render_additional_filter,
  initialRowsPerPage,
  title,
  statusComplete,
  dummyData,
  renderSelect,
  loadingVariant
} ) {
  const classes = useStyles()
  const history = useHistory()
  const [ order, setOrder ] = React.useState( 'asc' )
  const [ orderBy, setOrderBy ] = React.useState( '' )
  const [ selected, setSelected ] = React.useState( [] )
  const [ showColumnOptions, setShowColumnOptions ] = useState( false )
  const [ page, setPage ] = React.useState( 1 )
  const [ rowsPerPage, setRowsPerPage ] = React.useState( initialRowsPerPage )
  const dispatch = useDispatch()
  let enhanced_filter = showFilters && item_list.getEnhancedFilter && item_list.getEnhancedFilter()
  const [ openColumns, setOpenColumns ] = React.useState( false )
  const allColumns = columns

  // Columns selector functionality

  let passed_visible_columns = useColumnsSelector ? map( filter( columns, { 'visible': true } ), 'field' ) : columns
  const stored_visible_columns = getStoredVisibleTableColumns( storeTableName )
  let start_visible_columns = stored_visible_columns ? stored_visible_columns : passed_visible_columns
  start_visible_columns = isArray( start_visible_columns ) ? start_visible_columns : toArray( start_visible_columns )
  const [ visibleColumns, setVisibleColumns ] = React.useState( start_visible_columns )

  if ( useColumnsSelector ) {
    columns = filter( allColumns, function( c ) {
      return includes( visibleColumns, c.field )
    } )
  }

  useEffect( () => {
    if ( !initialRowsPerPage ) {
      setRowsPerPage( 50 )
    }
  }, [ initialRowsPerPage ] )

  useEffect( () => {
    setStoredVisibleTableColumns( storeTableName, visibleColumns )
  }, [ visibleColumns ] )

  useEffect( () => {
    if ( selectedRowIds && updateSelectedRowIds ) {
      updateSelectedRowIds( selected )
    }
  }, [ selected ] )

  const onDeleteRow = ( row_id ) => {
    if ( !window.confirm( "Are you sure you want to delete this row?" ) ) {
      return
    }
    const on_ok = ( json ) => {
      if ( json.status === 'success' ) {
        showSuccess( "Deleted" )
      } else {
        showError( "Delete failed " % json.error_msg )
      }
    }
    dispatch( item_list.deleteObject( row_id ) ).then( on_ok )
  }

  const action_props = {
    onViewRow: onViewRow,
    onEditRow: onEditRow,
    selectedRowIds: selectedRowIds,
    onSelectRow: onSelectRow,
    canDelete: canDelete,
    onDeleteRow: onDeleteRow,
    item_list: item_list,
    dispatch: dispatch,
    showSuccess: showSuccess,
    showError: showError
  }

  const actions = TableRowActions( action_props )

  const onColumnsChanged = ( event ) => {
    var result = []
    setVisibleColumns( event.target.value )
  }

  const renderColumnOptions = () => {

    const MenuProps = {
      PaperProps: {
        style: {
          width: 250
        },
      },
    }

    return (
      <FormControl className={ classes.formControl }>
        <Select
          labelId="column-options"
          id="column-options"
          label="Select Columns"
          multiple
          value={ visibleColumns }
          onChange={ onColumnsChanged }
          open={ openColumns }
          onClose={ () => setOpenColumns( false ) }
          onOpen={ () => setOpenColumns( true ) }
          input={ <Input /> }
          renderValue={ ( selected ) => join( selected, ', ' ) }
          MenuProps={ MenuProps }
          style={ { width: 200 } }
          name="columns"
        >
          { map( allColumns, function( column ) {
            return (
              <MenuItem key={ column.title } value={ column.field }>
                <Checkbox color="primary" checked={ visibleColumns.indexOf( column.field ) > -1 } />
                <ListItemText primary={ column.title } />
              </MenuItem>
            )
          } ) }
        </Select>
      </FormControl>
    )
  }

  const renderColumnsSelector = ( showFilters, enhanced_filter ) => {
    const marginRight = filters ? 75 : 5
    return (
      <div style={ { float: 'right', marginTop: -40 } }>
        { openColumns && renderColumnOptions() }
        <IconButton
          onClick={ () => setOpenColumns( true ) }
          style={ { marginTop: -5, marginRight: marginRight } }
        >
          <ViewWeekSharpIcon />
        </IconButton>
      </div>
    )
  }

  const handleSort = ( column ) => {
    if ( !column.sort ) {
      return
    }
    const isAsc = order === 'asc'
    setOrder( isAsc ? 'desc' : 'asc' )
    setOrderBy( column.field )
    column.sort( order )
    dispatch( item_list.invalidateList() )
    dispatch( item_list.fetchListIfNeeded() )

  }

  const handleRequestSort = ( event, property ) => {
    const isAsc = orderBy === property && order === 'asc'
    setOrder( isAsc ? 'desc' : 'asc' )
    setOrderBy( property )
  }

  const onEdit = ( rowData ) => {
    if ( onEditRow ) {
      onEditRow( rowData.id )
    }
  }

  const onView = ( rowData ) => {
    if ( onViewRow ) {
      onViewRow( rowData.id )
    }
  }

  const onDelete = ( rowData ) => {
    if ( !window.confirm( "Are you sure you want to delete this row?" ) ) {
      return
    }

    const on_ok = ( json ) => {
      if ( json.status === 'success' ) {
        showSuccess( "Deleted" )
      } else {
        showSuccess( "Delete failed " % json.error_msg )
      }
    }

    if ( !dummyData ) {
      dispatch( item_list.deleteObject( rowData.id ) ).then( on_ok )
    }
  }

  const handleSelectAllClick = ( event ) => {
    if ( event.target.checked ) {
      const newSelectedRowIds = rows.map( ( n ) => n.id )
      setSelected( newSelectedRowIds )
      const newSelecteds = rows.map( ( row ) => ( {
        ...row,
        checked: true,
      } ) )
      return
    } else {
      setSelected( [] )
    }
  }

  const handleSelectRow = ( event, id ) => {
    event.stopPropagation()
    const is_selected = event.target.checked
    const newSelectedRowIds = [ ...selectedRowIds ]

    if ( is_selected ) {
      newSelectedRowIds.push( id )
    } else {
      const index = newSelectedRowIds.indexOf( id )
      if ( index !== -1 ) {
        newSelectedRowIds.splice( index, 1 )
      }
    }

    setSelected( newSelectedRowIds )
  }

  const handleChangePage = ( event, newPage ) => {
    if ( !dummyData ) {
      dispatch( updateListPagination( item_list.listKey, { page: newPage + 1 } ) )
      dispatch( item_list.invalidateList() )
      dispatch( item_list.fetchListIfNeeded() )
    }
  }

  const handleChangeRowsPerPage = ( event ) => {
    if ( !dummyData ) {
      dispatch( updateListPagination( item_list.listKey, { items_per_page: parseInt( event.target.value, 10 ), page: 1 } ) )
      dispatch( item_list.invalidateList() )
      dispatch( item_list.fetchListIfNeeded() )
    }
  }

  const onRefresh = () => {
    if ( !dummyData ) {
      dispatch( item_list.invalidateList() )
      dispatch( item_list.fetchListIfNeeded() )
    }
  }

  const isSelected = id => selected.indexOf( id ) !== -1

  // filters are shown in a popup filter so check to see if filters have been passed so we don't have a blank popup cluttering the UI
  const popup_filter = filters ? <PopupFilter enhanced_filter={ enhanced_filter } enabled_filters={ filters } /> : false

  const renderFilter = () => {
    return (
      <>
        <EnhancedTableToolbar
          enhanced_filter={ enhanced_filter }
          numSelected={ selected.length }
          canDelete={ canDelete !== false }
          item_list={ item_list }
          initial_filter_values={ initial_filter_values }
          canFilter={ true }
          render_additional_filter={ render_additional_filter }
          title={ title }
          popup_filter={ popup_filter }
        />

        <div className={ classes.filterChips }>
          <FilterChips enhanced_filter={ enhanced_filter } marginBottom={ 15 } />
        </div>

      </>
    )
  }

  const renderPagination = () => {
    return (
      <TablePagination
        rowsPerPageOptions={ [ 5, 10, 25, 50, 100 ] }
        component="div"
        count={ !dummyData ? get( item_list.getPagination(), "num_items", 0 ) : 0 }
        rowsPerPage={ !dummyData ? get( item_list.getPagination(), "items_per_page", rowsPerPage ) : rowsPerPage }
        page={ !dummyData ? get( item_list.getPagination(), "current_page", 1 ) - 1 : 1 }
        onChangePage={ handleChangePage }
        onChangeRowsPerPage={ handleChangeRowsPerPage }
        ActionsComponent={ TablePaginationActions }
      />
    )
  }

  const handleRowClick = ( row, column ) => {
    if ( rowClickRedirect ) {
      map( columns, function( column, index ) {
        if ( column.redirect ) {
          history.push( column.redirect( row ) )
        }
      } )
    }
  }

  const renderSelectCell = ( row ) => {
    const isSelected = selectedRowIds.includes( row.id )

    return (
      <TableCell padding="checkbox">
        <Checkbox onClick={ ( event ) => handleSelectRow( event, row.id ) } color="primary" checked={ isSelected } />
      </TableCell>
    )
  }


  return (
    <Grid container className={ classes.root }>
      { showFilters && enhanced_filter &&
        <Grid item xs={ 12 }>{ renderFilter() }</Grid>
      }

      <Grid item xs={ 12 }>
        { useColumnsSelector && renderColumnsSelector( showFilters, enhanced_filter ) }
        { topPagination && renderPagination() }
        { is_loading && !loadingVariant ? <Loading /> :
          <TableContainer>
            { is_loading && loadingVariant && loadingVariant == "linear" && <LinearProgress /> }
            <Table stickyHeader aria-label="sticky table" size="small">

              { mobile ?

                <MobileTableView
                  rows={ rows } Loading
                  columns={ columns }
                  actions={ actions }
                  showActions={ showActions }
                  isSelected={ isSelected }
                  handleRowClick={ handleRowClick }
                /> :

                <>
                  { !noHeadings &&

                    <TableHead className={ classes.tableHead }>

                      <TableRow>
                        { renderSelect &&
                          <TableHeadCell padding="checkbox">
                            <Checkbox onClick={ handleSelectAllClick } color="primary" />
                          </TableHeadCell>
                        }

                        { map( columns, function( column ) {

                          const can_sort = column.sort !== null && column.sort !== undefined
                          return (
                            <TableHeadCell
                              key={ `heading_${ column.field }` }
                              style={ { minWidth: column.minWidth, textAlign: column.align } }
                              onClick={ () => handleSort( column ) }
                              width={ column.width }
                            >
                              <div style={ { textAlign: column.align } }>
                                { can_sort &&
                                  <TableSortLabel active={ orderBy === column.field }
                                    hideSortIcon={ !column.sort }
                                    direction={ order }
                                  >
                                    { column.title }
                                  </TableSortLabel>
                                }
                                { !column.sort &&
                                  <span>
                                    { column.title }
                                  </span>
                                }
                              </div>
                            </TableHeadCell>
                          )
                        } ) }

                        { size( actions ) > 0 && showActions &&
                          <TableHeadCell key={ `heading_actions` } />
                        }

                      </TableRow>
                    </TableHead>
                  }

                  <TableBody>

                    { map( rows, ( row, index ) => {
                      const isItemSelected = isSelected( row.id )
                      const labelId = `enhanced-table-checkbox-${ index }`

                      return (
                        <TableRow
                          hover
                          role="checkbox"
                          aria-checked={ isItemSelected }
                          tabIndex={ -1 }
                          key={ row.id }
                          selected={ isItemSelected }
                          onClick={ onSelectRow && handleRowClick( row ) }
                        >

                          { renderSelect && renderSelectCell( row ) }

                          { map( columns, function( column, index ) {
                            let value = null
                            if ( column.render ) {
                              value = column.render( row )
                            } else {
                              value = get( row, column.field )
                            }
                            return (
                              <TableCell key={ index } scope="row" width={ column.width } align={ column.align }>
                                <div style={ { textAlign: column.align } }>
                                  { value }
                                </div>
                              </TableCell>
                            )
                          } ) }

                          { size( actions ) > 0 && showActions && !statusComplete &&
                            <TableCell className={ classes.actionBar } scope="row" align="right" width={ 10 }>
                              <TableRowContextMenu index={ index } row={ row } actions={ actions } />
                            </TableCell>
                          }
                        </TableRow>
                      )
                    } ) }

                    { size( rows ) === 0 &&
                      <TableRow>
                        <TableCell colSpan={ columns.length + 1 }>
                          <div className={ classes.noRows }>
                            <EmptyRows />
                          </div>
                        </TableCell>
                      </TableRow>
                    }
                  </TableBody>
                </>
              }
            </Table>
          </TableContainer>
        }

        <div>
          { sync && <Button onClick={ onRefresh }><SyncIcon /></Button> }
          { bottomPagination && !noPagination && renderPagination() }
        </div>

      </Grid>

    </Grid>
  )
}

export const CommonPaperTable = props => <Paper elevation={ 2 } square><CommonTable { ...props } /></Paper>