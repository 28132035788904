import React, { Component } from 'react'
import { connect } from 'react-redux'
import { forEach, head, get, map, keys, split, size } from 'lodash'
import { Separator } from 'components/layout/Separator'
import { Formik, Form, Field } from 'formik'
import * as Yup from 'yup'
import {showSuccess, showError} from 'actions/Error'
import { handleSubmitResult } from 'actions/form'
import { ProvinceSelectField } from 'components/form/ProvinceSelectField'
import { FormikInputField } from 'components/form/InputField'
import { FormikTextareaField } from 'components/form/TextareaField'
import { FormikDropdownField } from 'components/form/Dropdown'
import urlSlug from 'url-slug'

export const validationSchema = Yup.object().shape({
    'name': Yup.string().required("Required"),
})

export default function AdminCoreBusinessForm({formik_props}) {

    const core_business_category_options = [
      {value: 'donor', label: 'Donor'},
      {value: 'beneficiary', label: 'Beneficiary'},
    ]

    const setUrlSlug = (e) => {
      const { value } = e.target
      formik_props.setFieldValue('slug', urlSlug(value))
    }

    return (
        <>
          <FormikDropdownField name="category"
                             label="Category"
                             validate_on_select={true}
                             options={core_business_category_options}
                             formik_props={formik_props}

          />
          <FormikInputField name="name"
                            label="Name"
                            formik_props={formik_props}
                            onChange={setUrlSlug}
          />
          <FormikInputField name="slug"
                            label="Slug"
                            formik_props={formik_props}
          />

          <FormikTextareaField name="description"
                            label="Description"
                            rows={5}
                            formik_props={formik_props}
          />


        </>
    )

}
