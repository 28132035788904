import React, { useState, useEffect, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { map, size, get, filter, startCase } from 'lodash'
import {
	Badge,
	Button,
	IconButton,
	Drawer,
	List,
	ListItem,
	Tooltip,
	makeStyles,
	Typography,
	Card,
	LinearProgress,
	Grid,
	Tabs,
	Tab,
	Box,
} from '@material-ui/core'

import { useTheme } from '@material-ui/core/styles'
// mui icons
import { Notifications as NotificationsIcon } from '@material-ui/icons'
import DoneAllIcon from '@material-ui/icons/DoneAll'
import ArrowForwardOutlinedIcon from '@material-ui/icons/ArrowForwardOutlined'
import MarkunreadOutlinedIcon from '@material-ui/icons/MarkunreadOutlined'
import DraftsOutlinedIcon from '@material-ui/icons/DraftsOutlined'

// api calls
import { adminUserNotificationList } from './actions/admin_user_notification'
import { showSuccess, showError } from 'actions/Error'
import { handleSubmitResult } from 'actions/form'
import { getTimeAgo } from 'utils/formatDateTime'
import Label from 'components/label'
// hooks
import { useBoolean } from 'hooks/use-boolean'
import { useResponsive } from 'hooks/use-responsive'
import useAxiosPost from 'hooks/api/useAxiosPost'
import EmptyRows from 'components/EmptyRows'
import { getColor } from '../../../components/label/label'

const drawerWidth = 420

const TABS = [
	{
    value: 'unread',
    label: 'Unread',
  },
  {
    value: 'all',
    label: 'All',
  },
  // {
  //   value: 'archived',
  //   label: 'Archived',
  // },
]

const useStyles = makeStyles( ( theme ) => ( {
	drawer: {
		width: drawerWidth,
		[ theme.breakpoints.down( 'sm' ) ]: {
			width: '100%',
		},
		height: '100vh',
		overflowX: 'hidden',
	},
	alert: {
		width: '100%',
		margin: 0,
		borderRadius: 0
	},
} ) )

const AdminUserNotifications = ( { user_id } ) => {
	const drawer = useBoolean()
	const theme = useTheme()
	const [ showDrawer, setShowDrawer ] = useState( false )
	const dispatch = useDispatch()
	const history = useHistory()
	const classes = useStyles()
	const smUp = useResponsive('up', 'sm')
	const notifications = useSelector( () => adminUserNotificationList.getVisibleObjects() )
	const user_all_notifications = filter( notifications, ( item ) => item.user === user_id )
	const user_unread_notifications = filter(notifications, (item) => item.is_read === false && item.user === user_id)
	const is_loading = useSelector( () => adminUserNotificationList.isLoading() )
	const [currentTab, setCurrentTab] = useState('unread')
	const activeTabNotifications = currentTab === 'unread' ? user_unread_notifications : user_all_notifications
	const [ post, { loading, error } ] = useAxiosPost()
	
	const user_unread_notifications_count = size( user_unread_notifications )
	const user_all_notifications_count = size( user_all_notifications )

  const handleChangeTab = useCallback((event, newValue) => {
    setCurrentTab(newValue);
  }, [])

  const unread_notifications_count = size(filter(user_unread_notifications, (item) => item.is_read === false))
	// console.log('unread_notifications_count', unread_notifications_count)
	const all_notifications_count = size(notifications)
	// console.log('all_notifications_count', all_notifications_count)

	useEffect( () => {
		const fetchNotifications = () => {
			dispatch( adminUserNotificationList.invalidateList() )
			dispatch( adminUserNotificationList.updateListFilter( { user: user_id } ) )
			dispatch( adminUserNotificationList.fetchListIfNeeded() )
		}
		// Fetch notifications initially
		fetchNotifications()
		const intervalId = setInterval( fetchNotifications, 20000 )
		return () => {
			clearInterval( intervalId )
		}
	}, [ dispatch ] )


	const handleClick = ( open ) => {
		setShowDrawer( ( open ) => !open )
	}

	const updateNotification = ( notification, redirect = true ) => {

		const notification_id = get( notification, "id" )
		const values = notification
		let mark_read_message = values.is_read ? "unread" : "read"
		// If the notification is already read then we are just redirecting the user to the notification page
		console.log( notification )
		if ( redirect && values.is_read === true) {
			history.push( '/admin' + notification.link)
			return null
		}
		const on_ok = function( json ) {
			dispatch( adminUserNotificationList.invalidateList() )
			dispatch( adminUserNotificationList.fetchListIfNeeded() )
			showSuccess( "Saved", `Notification marked as ${mark_read_message}` )
			if ( redirect ) {
				history.push( '/admin' + notification.link )
			}
		}
		if ( notification_id ) {
			values.id = notification_id
			// if redirect is false then the user is changing the read status of the notification so we need to toggle it
			if ( !redirect ) {
				values.is_read = notification.is_read ? false : true
			}
			return dispatch( adminUserNotificationList.saveObject( values ) ).then( ( res ) => handleSubmitResult( { res, on_ok } ) )
		}
		else return null
	}

	const handleMarkAllAsRead = async() => {
		try {
			const formData = new FormData()
			formData.append( 'mark_all_read', true )

			const postResponse = await post( 'admin/notification/mark_all_read/', formData )
			showSuccess( "Saved", `All notifications marked as read` )
			dispatch( adminUserNotificationList.invalidateList() )
			dispatch( adminUserNotificationList.updateListFilter( { user: user_id } ) )
			dispatch( adminUserNotificationList.fetchListIfNeeded() )
		} catch ( err ) {
			console.error( error )
			showError( "Error", `500 Internal Server Error` )
		}
	}

	const renderIconButton = () => {
		return (
			<IconButton
				aria-label="notifications"
				color="inherit"
				onClick={ () => handleClick( showDrawer ) }
			>
				<Badge badgeContent={ user_unread_notifications_count } color="secondary">
					<NotificationsIcon />
				</Badge>
			</IconButton>
		)
	}

	const renderHeader = (
		<Grid container direction="row" alignItems="center" spacing={ 2 } justifyContent="space-between">
			<Grid item>
				<Typography gutterBottom variant="h6" component="div" style={ { padding: '7px 15px 0 15px' } }>
					Notifications
				</Typography>
			</Grid>
			

			{ !!unread_notifications_count && (
				<Grid item>
					<Tooltip title="Mark all as read" arrow>
						<IconButton color="primary" onClick={ handleMarkAllAsRead }>
							<DoneAllIcon />
						</IconButton>
					</Tooltip>
				</Grid>
			) }

			{/* { !smUp && (
				<Grid item>
					<IconButton onClick={ drawer.onFalse }>
						<Iconify icon="mingcute:close-line" />
					</IconButton>
				</Grid>
			) } */}
		</Grid>
	)

	const renderFooter = (
		<Grid container direction="row" alignItems="center" spacing={ 2 } justifyContent="center" >
			<Grid item>
				<Button onClick={() => history.push('/admin/notifications')} variant="outlined">View all</Button>
			</Grid>
		</Grid>
	)

	const renderTabs = (
		<Tabs value={currentTab} onChange={handleChangeTab}>
			{TABS.map((tab) => (
				<Tab
					key={tab.value}
					value={tab.value}
					label={
						<Box display="flex" alignItems="center" justifyContent="space-between">
							{tab.label}
							<Label
								variant={((tab.value === 'all' || tab.value === currentTab) && 'filled') || 'soft'}
								color={
									(tab.value === 'unread' && 'info') ||
									(tab.value === 'archived' && 'success') ||
									'default'
								}
							>
								{ tab.value === 'unread' ? user_unread_notifications_count : user_all_notifications_count }
							</Label>
						</Box>
					}
					style={{
						width: '50%'
					}}
				/>
			))}
		</Tabs>
	)

	const renderNotificationActions = ( notification ) => {
		
		const is_read = get( notification, "is_read" )
		const is_read_text = is_read ? "Mark as unread" : "Mark as read"
		const is_read_icon = is_read ? <DraftsOutlinedIcon style={ { color: theme.palette.grey[ 400 ] } } /> : <MarkunreadOutlinedIcon style={ { color: getColor( get( notification, "severity" ) ) }} />
		const view_object_icon = <ArrowForwardOutlinedIcon style={ { color: is_read ? theme.palette.grey[ 400 ] : getColor(get(notification, "severity" )) } } />
		return (
			<Grid container direction="row" alignItems="center" spacing={ 2 } justifyContent="space-between">
				<Grid item>
					<IconButton onClick={ () => updateNotification( notification, false ) }>
						<Tooltip title={ is_read_text } arrow>
							{ is_read_icon }
						</Tooltip>
					</IconButton>
				</Grid>
				{/* If there is no link then  hide the iconbutton */ }
				{ notification.link &&
					<Grid item>
						<IconButton onClick={ () => updateNotification( notification, true ) }>
							<Tooltip title={ `View ${ notification.model_verbose_name }` } arrow>
								{ view_object_icon }
							</Tooltip>
						</IconButton>
					</Grid>
				}
			</Grid>
		)
	}

	const renderNotificationsList = (
		<List>
			{ is_loading && <LinearProgress /> }
			{ map( activeTabNotifications, ( notification ) => {
				return (
					<>
						<ListItem key={ notification.id } style={ { margin: 0, padding: 0 } }>
							<Card style={ { flexGrow: 1, background: notification.is_read ? theme.palette.grey[ 50 ] : "", borderBottom: notification.is_read ? '5px solid ' + theme.palette.grey[ 300 ] : '5px solid ' + getColor(get(notification, "severity")) } } >
								<div style={ { padding: '10px 15px' } }>
									<Typography color="textSecondary" align="right" style={ { fontSize: '90%' } }>{ getTimeAgo( notification.created_at ) }</Typography>
									<Typography color="textSecondary" align="right" style={ { fontSize: '90%' } }>for { notification.username }</Typography>
									<Typography color={ notification.is_read ? "textSecondary" : "textPrimary" }>New { startCase( get( notification, 'model_verbose_name')) }</Typography>
									<Typography color={ notification.is_read ? "textSecondary" : "textPrimary" } style={ { fontWeight: 500, fontSize: '125%' } }>{ get( notification, "object_name" ) ? get( notification, "object_name" ) : get( notification, "message" ) }</Typography>

									<Typography variant="caption" color="textSecondary">
										Created by: { get( notification, "object_created_by" ) ? get( notification, "object_created_by" ) : get( notification, "created_by" ) }
									</Typography>

								</div>
								{ renderNotificationActions( notification ) }
							</Card>
						</ListItem>
						
					</>
				)
			}

			) }
		</List>
	)

	const renderEmptyNotifications = (
		<EmptyRows message="You are all caught up!!" />
	)

	return ( 
		<>
			{ renderIconButton() }

			<Drawer 
				anchor="right" 
				open={ showDrawer } 
				onClose={ () => setShowDrawer( false ) } 
				classes={ { paper: classes.drawer } }
				style={{ display: 'flex', flexDirection: 'column' }}
			>
				<div style={ { position: 'sticky', top: 0, zIndex: 1, backgroundColor: '#fff' } }>
				{ renderHeader }
				{ renderTabs }
				</div>
				<div style={{ flexGrow: 1 }}>
				{ size(activeTabNotifications) > 0 ? renderNotificationsList : renderEmptyNotifications }
				</div>
				<div style={ { position: 'sticky', bottom: 0, zIndex: 1, backgroundColor: '#fff', padding: '10px 15px', marginTop: 20, borderTop: '1px solid ' + theme.palette.grey[300] } }>
				{ renderFooter }
				</div>
			</Drawer>
		</>
	)
}

export default AdminUserNotifications
