import { createTheme }  from '@material-ui/core/styles'
const muiTheme = createTheme({
    palette: {
      primary: { main: '#3949ab' },
      secondary: { main: '#FADF01' },
      info: { main: '#1890FF' },
      success: { main: '#00AB55' },
      warning: { main: '#FFC107' },
      error: { main: '#FE0000' },
    },
    shadows: ["none"],
    overrides: {
        MuiCard: {
            root: {
                marginBottom: '10px',
                borderRadius: 0
            },
            elevation: 0,
            variant: 'outlined'
        },
        MuiTextField: {
            root: {
                '& .MuiInputBase-root': {
                    borderRadius: 4
                }
            }
        },
    },
    props: {
        MuiTextField: {
            variant: 'outlined',
        },
    },
})
export default muiTheme

export const PARCEL_STATUS_COLOURS = {
    ready_to_go: "#1565c0",
    picking: "#eceff1",
    in_transit: "#bbdefb",
    complete: "#00AB55",
    order_cancelled: "#FF4842",
    pending: "#1890FF",
}

export const PAYMENT_STATUS_COLOURS = {
    paid: "#00AB55",
    cancelled: "#FF4842",
    pending: "#1890FF",
}
