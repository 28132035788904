import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { get } from 'lodash'
import AdminCommonListLayout from '../layout/AdminCommonListLayout'
import { adminCityList } from './actions/admin_city'
import { provinceList } from 'actions/province'
import { FiberManualRecord } from '@material-ui/icons'

export default function Cities({}) {
    const dispatch = useDispatch()
    const history = useHistory()
    const cities = useSelector(() => adminCityList.getVisibleObjects())
    const is_loading = useSelector(() => adminCityList.isLoading())
    const filter = useSelector(() => adminCityList.getFilter())

    useEffect(() => {
        dispatch(adminCityList.fetchListIfNeeded())
        dispatch(provinceList.updatePaginationNumItemsPerPage(1000))
        dispatch(provinceList.fetchListIfNeeded())
    },[])

    const onEditCity = (city_id) => {
        history.push({
            pathname: '/admin/city/'+city_id
        })
    }

    const onAddCity = () => {
        history.push('/admin/city')
    }

    const onUpdateListOrdering = (field) => {
      dispatch(adminCityList.updateListOrdering(field))
    }


    const columns = [
        { field: 'Name', title: ' Name',
          render: (item) => get(item, "name"),
          sort: (direction) => onUpdateListOrdering(direction === 'asc' ? 'name' : '-name')
        },
        { field: 'Province', title: 'Province',
          render: (item) => get(item, ["province_name"]),
          sort: (direction) => onUpdateListOrdering(direction === 'asc' ? 'province__name' : '-province__name')
        },
        { field: 'Bringg Team Ref', title: 'Bringg Team Ref',
          render: (item) => get(item, ["bringg_team_ref"]),
          sort: (direction) => onUpdateListOrdering(direction === 'asc' ? 'bringg_team_ref' : '-bringg_team_ref')
        },
        { field: 'color',
          title: 'Colour',
          render: (item) => <FiberManualRecord style={{ color: get(item, ["color"]), marginRight: '8px', backgroundColor: get(item, ["color"]) }} />

        }
    ]

    return (
        <AdminCommonListLayout
            active_key="cities"
            breadcrumbs={[
                {name: 'admin_home'},
                {name: 'cities', label: "Cities", url: '/admin/cities'}
            ]}
            add_label="Add City"
            onAddRow={onAddCity}
            onEditRow={onEditCity}
            is_loading={is_loading}
            columns={columns}
            item_list={adminCityList}
        />
    )

}
