import React, { useState, useEffect, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams, useHistory } from 'react-router-dom'
import { get, map } from 'lodash'
import { makeStyles, withStyles } from '@material-ui/core/styles'
import { Typography, ListItem, ListItemText, Table, TableRow, TableCell } from '@material-ui/core'
import { FormikGeneralFormErrors } from 'components/form/GeneralFormErrors'
import { Formik, Form, FieldArray, Field } from 'formik'
import { FormikInputField } from 'components/form/InputField'
import { FormikCheckboxField } from 'components/form/CheckboxField'
import * as Yup from 'yup'
import { adminKpiTemplateList } from './actions/admin_kpi_template'
import { handleSubmitResult, shouldShowOnDemandSaveButton } from 'actions/form'
import {showSuccess, showError} from 'actions/Error'
import AdminMainLayout from '../layout/AdminMainLayout'
import { capitalCamel } from 'components/CapitalCamel'
import { Card } from 'components/layout/Card'

export const validationSchema = Yup.object().shape({
    //'name': Yup.string().required("Required"),
})

export default function AdminKpiTemplate() {
    const dispatch = useDispatch()
    const history = useHistory()
    const { kpi_template_id } = useParams()
    const [edit, setEdit] = useState(false)
    const kpi_template = useSelector(() => adminKpiTemplateList.getObject(kpi_template_id))
    const is_loading =  useSelector(() => adminKpiTemplateList.isLoading())
    const is_busy =  useSelector(() => adminKpiTemplateList.getIsSavingObject())
    const initial_values = kpi_template
    const title = kpi_template ? capitalCamel(get(kpi_template, "name")) : "Woops"

    useEffect(() => {
        async function fetchStaticObjects() {
            dispatch(adminKpiTemplateList.ensureObjectLoaded(kpi_template_id))
        }
        fetchStaticObjects()
    }, [kpi_template_id])

    const onSave = (values, formik_funcs) => {

        const on_ok = function(json) {
            setEdit(false)
            dispatch(adminKpiTemplateList.invalidateList())
            showSuccess("Saved", "KPI Template saved")

            if ( ! kpi_template_id ) {
                history.push(`/donate/${json.id}`)
            }
        }
        if ( kpi_template_id ) {
            values.id = kpi_template_id
            return dispatch(adminKpiTemplateList.saveObject(values)).then((res) => handleSubmitResult({res, formik_funcs, on_ok}))
        } else {
            return dispatch(adminKpiTemplateList.saveNewObject(values)).then((res) => handleSubmitResult({res, formik_funcs, on_ok}))
        }
    }

    const renderKpiContent = () => {
        return(
            <>
                <Table>
                        <TableRow>
                            <TableCell width="150">Category</TableCell>
                            <TableCell>{kpi_template.category}</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>Code</TableCell>
                            <TableCell>{kpi_template.code}</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>Description</TableCell>
                            <TableCell>{kpi_template.description}</TableCell>
                        </TableRow>
                </Table>

                <Typography variant="h6" style={{marginTop: 15}}>Thresholds</Typography>
                <ul>
                    { map(get(kpi_template, "kpi_threshold_templates"), (kpitt) => {
                    return(
                        <ListItem>
                            <ListItemText
                                primary={capitalCamel(kpitt.label)}
                                secondary={"ID: " + kpitt.id}
                            />
                        </ListItem>
                    )
                })}
                </ul>
            </>
        )
    }

    return(
        <AdminMainLayout
            breadcrumbs={[
                {name: 'admin_home'},
                {name: 'kpi_templates', label: 'Kpi Templates', url: '/admin/kpis/kpi_templates'},
                {name: 'kpi_templates', label: get(kpi_template, ["id"], 'New KPI Template'), url: `/admin/kpis/kpi_template/${kpi_template_id}`}
            ]}
            is_busy={is_busy}
            is_loading={is_loading}
            title={title}
        >

        <Card
            title={title}
            content={renderKpiContent()}
        />
        </AdminMainLayout>
    )

}
