import axios from 'axios'
// config
import { getHeaders } from '../lib/http'

// Initialize base URL
const baseURL = window.LOCAL_SETTINGS.API_BASE
const axiosInstance = axios.create( { baseURL: baseURL } )

// Set default headers
axiosInstance.defaults.headers.common = getHeaders()

// Interceptor for responses
axiosInstance.interceptors.response.use(
  ( res ) => res,
  ( error ) => Promise.reject( ( error.response && error.response.data ) || 'Something went wrong' )
)

export default axiosInstance

// Fetcher function
// args can either be a URL string or an array [URL, config]
export const fetcher = async ( args ) => {
  const [ url, config ] = Array.isArray( args ) ? args : [ args ]

  const res = await axiosInstance.get( url, { ...config } )

  return res.data
}

export function convertObjectToQueryParams( filters ) {
  const params = new URLSearchParams()

  for ( const key in filters ) {
    const value = filters[ key ]
    if ( value !== null && value !== undefined ) {
      params.append( key, value )
    }
  }

  return params.toString()
}