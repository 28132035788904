import { ItemList } from 'orm'
import { get, size } from 'lodash'

class MarketingProvinceList extends ItemList {
    getEntityKey() {
        return "marketing/province"
    }

    filterOnCountry(country_id) {
        return this.updateListFilter({country:country_id})
    }
    
}

export const marketingProvinceList = new MarketingProvinceList("marketing_province__default")
