import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { makeStyles, TextField, Grid, Button } from '@material-ui/core'
import { FormikInputField } from 'components/form/InputField'
import { FormikEditorField } from 'components/form/EditorField'
import { FormikCheckboxField } from 'components/form/CheckboxField'
import { FormikMultiSelectDropdownField } from 'components/form/Dropdown'
import { FormikFileSetField } from 'components/form/FileSetField'
import { Separator } from 'components/layout/Separator'
import { contactCoreBusinessList } from 'actions/contact/core_business'

const useStyles = makeStyles({
  root: {

  }
})
export default function CompanyForm({formik_props, renderParentField}) {
  const classes = useStyles()
  const dispatch = useDispatch()
  const coreBusinessOptions = useSelector(() => contactCoreBusinessList.getAsOptions())

  React.useEffect(() => {
      async function fetchStaticObjects() {
          dispatch(contactCoreBusinessList.updatePaginationNumItemsPerPage(100))
          dispatch(contactCoreBusinessList.fetchListIfNeeded())
      }
      fetchStaticObjects()
  }, [])

  return(
      <>
          <FormikInputField name="name"
                            type="text"
                            placeholder="Company Name"
                            formik_props={formik_props}
          />

          { renderParentField && renderParentField(formik_props) }

          <FormikInputField name="website"
                            type="text"
                            label="Company Website"
                            formik_props={formik_props}

          />

          <FormikInputField name="email"
                            type="email"
                            label="Company Email"
                            formik_props={formik_props}
          />

          <FormikInputField name="phone"
                            type="tel"
                            label="Company Phone"
                            formik_props={formik_props}
          />

    <FormikEditorField name="description"
                        placeholder="Description"
                        formik_props={formik_props}
      />

    <Separator height={15} />
    <FormikMultiSelectDropdownField label="Core Businesses"
                                    label="Select Core Business Areas"
                                    name="core_businesses"
                                    options={coreBusinessOptions}
                                    formik_props={formik_props}
    />

    </>
  )
}
