import { ItemList } from 'orm'
import { map, filter, values } from 'lodash'

class MarketingCoreBusinessList extends ItemList {
    getEntityKey() {
        return "marketing/core_business"
    }

}

export const marketingCoreBusinessList = new MarketingCoreBusinessList("marketing_core_business__default")
