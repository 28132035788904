import useSWR, { mutate } from 'swr'
import axios, { fetcher } from 'utils/axios'


export function useGetProductCategories( searchQuery ) {
  const { data, error } = useSWR( `/admin/admin/product_category/?any_field=${ searchQuery }`, fetcher )
  const product_categories = data?.results || []
  return {
    product_categories,
    is_loading: !data && !error,
    error
  }
}

export function useGetProductCategoryById( id ) {
  const { data, error } = useSWR( `/admin/product_category/${ id }`, fetcher )
  return {
    product_category: data || null,
    is_loading: !data && !error,
    error
  }
}

export async function createProductCategory( newProductCategory ) {
  const response = await axios.post( '/admin/product_category/', newProductCategory )
  const productCategory = response.data.result
  mutate( '/admin/product_category/', ( currentData ) => ( {
    ...currentData,
    product_category: [ ...currentData.product_category, productCategory ]
  } ), false )
  return productCategory
}

export async function updateProductCategory( productCategoryId, productCategory ) {
  const response = await axios.patch( `/admin/product_category/${ productCategoryId }/`, productCategory )
  const updatedProductCategory = response.data.result
  mutate( '/admin/product_category/', ( currentData ) => ( {
    ...currentData,
    product_category: currentData.product_category.map( ( e ) => e.id === productCategoryId ? updatedProductCategory : e )
  } ), false )
  return updatedProductCategory
}

export async function deleteProductCategory( productCategoryId ) {
  await axios.delete( `/admin/product_category/${ productCategoryId }/` )
  mutate( '/admin/product_category/', ( currentData ) => ( {
    ...currentData,
    product_category: currentData.product_category.filter( ( e ) => e.id !== productCategoryId )
  } ), false )
}