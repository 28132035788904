import React from 'react'
import clsx from 'clsx'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import Drawer from '@material-ui/core/Drawer'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import CssBaseline from '@material-ui/core/CssBaseline'
import List from '@material-ui/core/List'
import Typography from '@material-ui/core/Typography'
import Divider from '@material-ui/core/Divider'
import Tooltip from '@material-ui/core/Tooltip'
import IconButton from '@material-ui/core/IconButton'
import Button from '@material-ui/core/Button'
import MenuIcon from '@material-ui/icons/Menu'
import HighlightOffIcon from '@material-ui/icons/HighlightOff'
import SettingsIcon from '@material-ui/icons/Settings'
import SearchIcon from '@material-ui/icons/Search'

let drawerWidth =400
let mobileDrawerWidth = 320

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  appBar: {
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  drawerTitle: {
    fontWeight: 500,
    [theme.breakpoints.down('sm')]: {
      fontSize: 21
    },
  },
  mapDrawerButtons: {
    position: 'absolute',
    backgroundColor: 'rgba(255,255,255,1)',
    zIndex: 1,
    background: '#ffffff',
    color: "#222222",
    right: 9,
    borderRadius: 4,
    padding: 4,
    boxShadow: '0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24)',
    transition: 'all 0.3s cubic-bezier(.25,.8,.25,1)',
    '&:hover': {
      backgroundColor: 'rgba(255,255,255,0.5)',
    }
  },
  filterResultsButton: {
    top: 130,
  },
  mapSettingsButton: {
    top: 185,
  },
  appBarShift: {
    width: `calc(100% - ${drawerWidth}px)`,
    [theme.breakpoints.down('sm')]: {
      width: `calc(100% - ${mobileDrawerWidth}px)`,
      marginRight: mobileDrawerWidth,
    },
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginRight: drawerWidth,
  },
  title: {
    flexGrow: 1,
  },
  hide: {
    display: 'none',
  },
  drawer: {
    width: drawerWidth,
    [theme.breakpoints.down('sm')]: {
      width: mobileDrawerWidth
    },
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
    [theme.breakpoints.down('sm')]: {
      width: mobileDrawerWidth
    },
  },
  drawerHeader: {
    padding: 15,
    borderBottom: '1px solid #000000',
    marginBottom: 15
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginRight: -drawerWidth,
    [theme.breakpoints.down('sm')]: {
      marginRight: -mobileDrawerWidth
    },
  },
  contentShift: {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginRight: 0,
  },
  closeButton: {
    position: 'absolute',
    top: 0,
    right: 0,
  }
}))

export function CompaniesListFilterAndResultsDrawer({ title, content }) {
  const classes = useStyles()
  const theme = useTheme()
  const [open, setOpen] = React.useState(false)

  const handleDrawerOpen = () => {
    setOpen(true)
  }

  const handleDrawerClose = () => {
    setOpen(false)
  }

  return (
    <>
      <Tooltip title={"Filter organisations"} arrow placement="left">
        <IconButton
          aria-label="open drawer"
          onClick={handleDrawerOpen}
          edge="start"
          variant="contained"
          size="large"
          data-tour="first-step"
          className={[classes.filterResultsButton, classes.mapDrawerButtons]}>
          <SearchIcon fontSize="large" />
        </IconButton>
      </Tooltip>
      <Drawer
        className={classes.drawer}
        variant="persistent"
        elevation={1}
        anchor="left"
        open={open}
        classes={{
          paper: classes.drawerPaper,
        }}
      >
        <div className={classes.drawerHeader}>
          <Typography variant="h4" className={classes.drawerTitle}>{title}</Typography>
          <Tooltip title={"Close panel"} arrow>
            <IconButton onClick={handleDrawerClose} className={classes.closeButton}>
              <HighlightOffIcon />
            </IconButton>
          </Tooltip>
        </div>
        { content }
      </Drawer>
    </>
  )
}

export function MapSettingsAndLegendDrawer({ title, content }) {
  const classes = useStyles()
  const theme = useTheme()
  const [open, setOpen] = React.useState(false)

  const handleDrawerOpen = () => {
    setOpen(true)
  }

  const handleDrawerClose = () => {
    setOpen(false)
  }

  return (
    <>
      <Tooltip title={"Show map legend and settings"} arrow placement="left">
        <IconButton
          aria-label="open drawer"
          onClick={handleDrawerOpen}
          edge="start"
          variant="contained"
          size="large"
          data-tour="second-step"
          className={[classes.mapSettingsButton, classes.mapDrawerButtons]}>
          <SettingsIcon fontSize="large" />
        </IconButton>
      </Tooltip>
      <Drawer
        className={classes.drawer}
        variant="persistent"
        anchor="left"
        open={open}
        classes={{
          paper: classes.drawerPaper,
        }}
      >
        <div className={classes.drawerHeader}>
          <Typography variant="h4" className={classes.drawerTitle}>{title}</Typography>
          <Tooltip title={"Close panel"} arrow>
            <IconButton onClick={handleDrawerClose} className={classes.closeButton}>
              <HighlightOffIcon/>
            </IconButton>
          </Tooltip>
        </div>
        { content }
      </Drawer>
    </>
  )
}
