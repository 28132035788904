import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import { get, filter, map, each } from 'lodash'
import { FormikDropdownField } from 'components/form/Dropdown'
import { FormikInputField } from 'components/form/InputField'
import { FormikEditorField } from 'components/form/EditorField'
import { FormikTextareaField } from 'components/form/TextareaField'
import { FormikCheckboxField } from 'components/form/CheckboxField'
import { CountrySelectField } from 'components/form/CountrySelectField'
import { ProvinceSelectField } from 'components/form/ProvinceSelectField'
import { FormikMultiSelectDropdownField } from 'components/form/Dropdown'
import { CitySelectField } from 'components/form/CitySelectField'
import { FormikFileSetField } from 'components/form/FileSetField'
import { FormikBringgStatus } from 'admin/components/integrations/bringg/BringgStatus'
import {
  Grid,
  Typography,
  makeStyles,
  InputLabel,
  Accordion,
  AccordionDetails,
  AccordionSummary
} from '@material-ui/core'
import { Card } from 'components/layout/Card'
import { adminCoreBusinessList } from 'admin/components/core_businesses/actions/admin_core_business'
import { adminPublishedTagList } from 'admin/components/tags/actions/admin_tag'
import { Separator } from 'components/layout/Separator'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import urlSlug from 'url-slug'
import Alert from '@material-ui/lab/Alert'

const useStyles = makeStyles( ( theme ) => ( {
  logo: {
    padding: '7px 0px',
    marginTop: 15
  },
  heading: {
    fontSize: 18,
    fontWeight: 500
  },
  companyAccordion: {
    marginTop: 15
  }
} ) )

const DEFAULT_STATUS_OPTIONS = [
  { value: 'active', label: 'Active' },
  { value: 'inactive', label: 'Inactive' }
]

const AdminCommonCompanyForm = ( {
  formik_props,
  renderParentField,
  renderExtraFieldsPosition1,
  renderExtraFieldsPosition2,
  renderExtraFieldsPosition3,
  in_drawer,
  status_options,
  state_history,
  company_id
} ) => {
  const dispatch = useDispatch()
  const values = formik_props.values
  const classes = useStyles()
  const [ expanded, setExpanded ] = React.useState( 'panel1' )
  status_options = status_options ? status_options : DEFAULT_STATUS_OPTIONS
  const position1 = renderExtraFieldsPosition1 && renderExtraFieldsPosition1( formik_props )
  const position2 = renderExtraFieldsPosition2 && renderExtraFieldsPosition2( formik_props )
  const position3 = renderExtraFieldsPosition3 && renderExtraFieldsPosition3( formik_props )

  const handleChange = ( panel ) => ( event, isExpanded ) => {
    setExpanded( isExpanded ? panel : false )
  }

  const coreBusinessOptions = useSelector( () => adminCoreBusinessList.getAsOptions() )
  const tag_options = useSelector( () => adminPublishedTagList.getAsOptions() )

  React.useEffect( () => {
    async function fetchStaticObjects() {
      dispatch( adminCoreBusinessList.updatePaginationNumItemsPerPage( 100 ) )
      dispatch( adminCoreBusinessList.fetchListIfNeeded() )
      dispatch( adminPublishedTagList.updatePaginationNumItemsPerPage( 100 ) )
      dispatch( adminPublishedTagList.updateListFilter( { published: true } ) )
      dispatch( adminPublishedTagList.fetchListIfNeeded() )
    }
    fetchStaticObjects()
  }, [] )

  const setUrlSlug = ( e ) => {
    const { value } = e.target
    formik_props.setFieldValue( 'company.name', value )
    formik_props.setFieldValue( 'company.slug', urlSlug( value ) )
  }

  const renderCompanyForm = () => {
    return (
      <Grid container spacing={ 2 }>
        <Grid item xs={ 12 }>
          <FormikInputField
            name="company.name"
            type="text"
            placeholder="Name"
            value={ values.company.name }
            formik_props={ formik_props }
            onChange={ setUrlSlug }
          />
          <FormikInputField
            name="company.slug"
            value={ values.company.slug }
            placeholder="Slug"
            formik_props={ formik_props }
          />
          { renderParentField &&
            <>
              <Separator height={ 17 } />
              { renderParentField( formik_props ) }
            </>
          }
          <FormikInputField
            name="company.website"
            type="text"
            label="Website"
            formik_props={ formik_props }
          />
          <FormikInputField
            name="company.email"
            type="email"
            label="Email"
            formik_props={ formik_props }
          />
          <FormikInputField
            name="company.phone"
            type="tel"
            label="Phone"
            formik_props={ formik_props }
          />
          <FormikEditorField
            name="company.description"
            label="Description"
            formik_props={ formik_props }
          />
          <Separator height={ 15 } />
          <FormikMultiSelectDropdownField
            label="Core Business"
            name="company.core_businesses"
            options={ coreBusinessOptions }
            formik_props={ formik_props }
          />
        </Grid>
      </Grid>
    )
  }

  const microsite_publish_label = get( formik_props, [ "values", "company", "microsite_enabled" ] ) ? <Alert>Microsite Published</Alert> : <Alert severity="warning">Microsite Disabled</Alert>

  const renderCompanyFormFullOptions = () => {
    return (
      <Grid container spacing={ 2 }>
        {/* Company Form */ }
        <Grid item xs={ 12 } md={ 8 } className={ classes.companyAccordion }>
          <Accordion expanded={ expanded === 'panel1' } onChange={ handleChange( 'panel1' ) } variant="outlined" square>
            <AccordionSummary
              expandIcon={ <ExpandMoreIcon /> }
              aria-controls="panel1bh-content"
              id="panel1bh-header"
            >
              <Typography className={ classes.heading }>Details</Typography>
            </AccordionSummary>
            <AccordionDetails>
              { renderCompanyForm() }
            </AccordionDetails>
          </Accordion>
          <Accordion expanded={ expanded === 'panel2' } onChange={ handleChange( 'panel2' ) } variant="outlined" square>
            <AccordionSummary expandIcon={ <ExpandMoreIcon /> } aria-controls="panel2bh-content" id="panel2bh-header">
              <Typography className={ classes.heading }>Vehicle Access & Delivery Instructions</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <FormikInputField name="company.delivery_instructions"
                type="text"
                label="Delivery instructions"
                formik_props={ formik_props }
                multiline
                rows={ 5 }
              />

            </AccordionDetails>
          </Accordion>
          { renderExtraFieldsPosition1 &&
            <Accordion expanded={ expanded === 'panel3' } onChange={ handleChange( 'panel3' ) } variant="outlined" square>
              <AccordionSummary expandIcon={ <ExpandMoreIcon /> } aria-controls="panel3bh-content" id="panel3bh-header">
                <Typography className={ classes.heading }>{ position1[ 0 ] }</Typography>
              </AccordionSummary>
              <AccordionDetails>
                { position1[ 1 ] }
              </AccordionDetails>
            </Accordion>
          }
          { renderExtraFieldsPosition2 &&
            <Accordion expanded={ expanded === 'panel4' } onChange={ handleChange( 'panel4' ) } variant="outlined" square>
              <AccordionSummary expandIcon={ <ExpandMoreIcon /> } aria-controls="panel4bh-content" id="panel4bh-header">
                <Typography className={ classes.heading }>{ position2[ 0 ] }</Typography>
              </AccordionSummary>
              <AccordionDetails>
                { position2[ 1 ] }
              </AccordionDetails>
            </Accordion>
          }
          { renderExtraFieldsPosition3 &&
            <Accordion expanded={ expanded === 'panel5' } onChange={ handleChange( 'panel5' ) } variant="outlined" square>
              <AccordionSummary expandIcon={ <ExpandMoreIcon /> } aria-controls="panel5bh-content" id="panel56bh-header">
                <Typography className={ classes.heading }>{ position3[ 0 ] }</Typography>
              </AccordionSummary>
              <AccordionDetails>
                { position3[ 1 ] }
              </AccordionDetails>
            </Accordion>
          }

        </Grid>

        {/* Status, Tags & Logo */ }
        <Grid item xs={ 12 } md={ 4 } className={ classes.logo }>
          {/* Status */ }
          <Card
            title="Status"
            content={
              <>
                <FormikDropdownField
                  name="status"
                  options={ status_options }
                  formik_props={ formik_props }
                  label={ "Status" }
                />
                { state_history }<br />

                <Separator height={ 15 } />
                <FormikCheckboxField name="company.microsite_enabled"
                  label={ microsite_publish_label }
                  color="primary"
                  formik_props={ formik_props }
                />
              </>
            }
          />

          {/* Tags */ }
          <Card
            title="Tags"
            content={
              <FormikMultiSelectDropdownField
                name="company.tags"
                label="Tags"
                options={ tag_options }
                formik_props={ formik_props }
              />
            }
          />

          {/* Logo */ }
          <Card
            title="Company Logo"
            content={
              <FormikFileSetField
                name="company.logo_images"
                formik_props={ formik_props }
                label={ "logo" }
                max_num_files={ 1 }
              />
            }
          />

          {/* Bringg Status */ }
          { company_id && <FormikBringgStatus formik_props={ formik_props } field_prefix='company' /> }

        </Grid>

        {/* Banner Image */ }
        <Grid item xs={ 12 } lg={ 12 }>
          <Card
            title="Banner Image"
            content={ <FormikFileSetField max_num_files={ 1 } name="company.banner_images" formik_props={ formik_props } label={ "banner" } /> }
          />
        </Grid>

        {/* Gallery Images */ }
        <Grid item xs={ 12 } lg={ 12 }>
          <Card
            title="Gallery Images"
            content={ <FormikFileSetField name="company.images" formik_props={ formik_props } label={ "images" } /> }
          />
        </Grid>

      </Grid>
    )
  }

  return (
    in_drawer ? renderCompanyForm() : renderCompanyFormFullOptions()
  )
}

export default AdminCommonCompanyForm
