import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { startCase, get, join, reverse, tail, clone } from 'lodash'
import { adminDonorParcelProductList } from './actions/admin_donor_parcel_product'
import { adminParcelList } from './actions/admin_parcel'
import FormCard from 'components/layout/FormCard'
import { FormikProductCategoryLeafNodeAutoCompleteDropdown } from '../product_categories/form/ProductCategoryLeafNodeAutoCompleteDropdown'
import { FormikAutoCompleteDropdownWithFormBasedAddNew } from 'components/form/AutoCompleteDropdownWithFormBasedAddNew'
import { FormikDonorAutoCompleteDropdown } from 'admin/components/donors/form/DonorAutoCompleteDropdown'
import { FormikDateTimePicker } from 'components/form/DatetimePicker'
import { Formik, Form, FieldArray, Field } from 'formik'
import Loading from 'components/Loading'
import { FormikInputField } from 'components/form/InputField'
import * as Yup from 'yup'
import { showSuccess, showError } from 'actions/Error'
import { Typography, InputAdornment, Grid, FormHelperText, useMediaQuery, Divider, Checkbox, FormControlLabel } from '@material-ui/core'
import { handleSubmitResult } from 'actions/form'
import { adminProductList } from '../products/actions/admin_product'
import { adminProductCategoryLeafNodeAutoCompleteList } from '../product_categories/actions/admin_product_category_leaf_node'
import { FormikAutoCompleteDropdown } from '../../../components/form/AutoCompleteDropdown'
import { IconButton } from '@material-ui/core'
import EditIcon from '@material-ui/icons/Edit'
import { FormikDropdownWithFormBasedAddNew } from '../../../components/form/DropdownWithFormBasedAddNew'
import AdminProductForm from '../products/form/AdminProductForm'
import { validationSchema as productVariationValidationSchema } from '../products/form/AdminProductForm'

const validationSchema = Yup.object().shape( {
  // 'product_category': Yup.string().required( "Required" ),
  // 'provisional_quantity': Yup.number(),
  'actual_quantity': Yup.number().nullable(),
  'total_weight_kg': Yup.number(),
  'best_before_date': Yup.date().typeError( 'Best before date required' ).required(),
  'expiry_date': Yup.date().typeError( 'Expiry date is required' ).required()
} )

export default function AdminDonorParcelProduct( { parcel_id, parcel_product_id, onCancel, onSaved } ) {
  const dispatch = useDispatch()
  const isMobile = useMediaQuery( '(max-width:600px)' )
  const gridSpacing = isMobile ? 0 : 2
  const history = useHistory()
  const parcel_product = useSelector( () => adminDonorParcelProductList.getObject( parcel_product_id ) )
  const parcel = useSelector( () => adminParcelList.getObject( parcel_id ) )
  const is_loading = useSelector( () => adminDonorParcelProductList.isLoading() || ( parcel_product_id && !get( parcel_product, "id" ) ) )
  const is_busy = useSelector( () => adminDonorParcelProductList.getIsSavingObject() || adminDonorParcelProductList.getIsUpdatingObject( parcel_id ) )
  const initial_values = Object.assign( {}, parcel_product )
  const [ productWeight, setProductWeight ] = useState( get( parcel_product, 'product_weight_kg' ) )
  const is_new = !parcel_product.product
  const [ showTotalWeightInput, setShowTotalWeightInput ] = useState( false )
  const product_variations = useSelector( () => adminProductList.getAsOptions() )
  const [ productCategory, setProductCategory ] = useState( get( parcel_product, 'product_category' ) )
  const [ productVariationId, setProductVariationId ] = useState( get( parcel_product, 'product' ) )
  const [ editingCategory, setEditingCategory ] = useState( true )
  const product_category = useSelector( () => adminProductCategoryLeafNodeAutoCompleteList.getObject( productCategory ) )
  const product_variation = useSelector( () => adminProductList.getObject( productVariationId ) )
  const isEditingParcelProduct = Boolean( parcel_product_id )

  const title = get( parcel_product, "id" ) ?
    <>
      <Typography variant="h4">{ startCase( get( parcel_product, "product_category_crumbs", [ 0 ] )[ 0 ] ) }</Typography>
      <Typography>{ join( reverse( tail( clone( get( parcel_product, "product_category_crumbs" ) ) ) ), ' / ' ) }</Typography>
      <Typography color="textSecondary">{ productWeight } kg each </Typography>
    </> :
    ''

  const handleChange = ( event ) => {
    setShowTotalWeightInput( event.target.checked )
  }

  useEffect( () => {
    dispatch( adminDonorParcelProductList.ensureObjectLoaded( parcel_product_id ) )
    dispatch( adminParcelList.ensureObjectLoaded( parcel_id ) )
    dispatch( adminProductList.fetchListIfNeeded() )
  }, [] )

  useEffect( () => {
    if ( productCategory ) {
      dispatch( adminProductCategoryLeafNodeAutoCompleteList.ensureObjectLoaded( productCategory ) )
      setEditingCategory( false )
      dispatch( adminProductList.updateListFilter( { product_category: productCategory } ) )
      dispatch( adminProductList.fetchListIfNeeded() )
    }
  }, [ productCategory ] )

  useEffect( () => {
    if ( productVariationId ) {
      dispatch( adminProductList.ensureObjectLoaded( productVariationId ) )
    }
  }, [ productVariationId ] )

  const onSave = ( values, formik_funcs ) => {
    const on_ok = function( json ) {
      dispatch( adminParcelList.invalidateList() )
      dispatch( adminDonorParcelProductList.invalidateList() )
      showSuccess( "Saved", "Product saved" )
      if ( onSaved ) {
        onSaved( json )
      } else if ( !parcel_product_id ) {
        history.push( `/admin/parcel/${ parcel_id }/product/${ json.id }` )
      }
    }
    values.parcel = parcel_id
    if ( parcel_product_id ) {
      values.id = parcel_product_id
      if ( productCategory ) {
        values.product_category = productCategory
      }
      return dispatch( adminDonorParcelProductList.saveObject( values ) ).then( ( res ) => handleSubmitResult( { res, formik_funcs, on_ok } ) )
    } else {
      return dispatch( adminDonorParcelProductList.saveNewObject( values ) ).then( ( res ) => handleSubmitResult( { res, formik_funcs, on_ok } ) )
    }
  }

  const onSaveNewProductvariation = () => {
    dispatch( adminProductList.invalidateList() )
    dispatch( adminProductList.fetchListIfNeeded() )
  }


  const onSelectProduct = ( product_variation_id ) => {
    setProductVariationId( product_variation_id )
  }

  const renderWeightCalculation = ( product_qty, formik_props ) => (
    <Grid item xs={ 12 }>
      <Typography variant="caption" color="textSecondary">
        { product_qty } x { get( product_variation, "weight_kg" ) } kg
      </Typography> :
      { formik_props.values.product && get( product_variation, "weight_kg" ) ?
        <>
          <FormHelperText>Quantity x Product weight</FormHelperText>
          <FormHelperText>Leave empty to autocalculate</FormHelperText>
        </> :
        <>
          { formik_props.values.product && !get( product_variation, "weight_kg" ) &&
            <FormHelperText>
              This product does not have a weight so you need to complete the weight field
            </FormHelperText>
          }
        </>
      }
    </Grid>
  )

  const renderTotalWeightSection = ( formik_props ) => (
    <>
      <Grid item xs={ 12 }>
        <Typography variant="h6">Total Weight</Typography>
      </Grid>
      <Grid item xs={ 12 }>
        { formik_props.values.actual_quantity && get( product_variation, "weight_kg" ) &&
          <Typography variant="h3">{ formik_props.values.actual_quantity * get( product_variation, "weight_kg" ) } kg</Typography>
        }
      </Grid>
      <Grid item xs={ 12 }>
        <FormControlLabel
          control={
            <Checkbox
              checked={ showTotalWeightInput }
              onChange={ handleChange }
              name="show_total_weight_input"
              color="primary"
            />
          }
          label="Override total weight"
        />
      </Grid>
    </>
  )

  const renderQuantityFields = ( formik_props ) => (
    <>
      <Grid item xs={ 12 }>
        <Typography variant="h6">Quantity</Typography>
      </Grid>
      <Grid item xs={ 12 } md={ 6 }>
        <FormikInputField
          name="actual_quantity"
          label="Actual Quantity"
          formik_props={ formik_props }
          onChange={ changeEvent => {
            formik_props.setFieldValue( "total_weight_kg", "" )
            formik_props.handleChange( changeEvent )
          } }
        />
        { !formik_props.values.actual_quantity &&
          <Typography style={ { color: 'red' } }>Update when receiving</Typography>
        }
      </Grid>
      <Grid item xs={ 12 } md={ 6 }>
        { !formik_props.values.actual_quantity &&
          <FormikInputField
            name="provisional_quantity"
            label="Provisional Quantity"
            formik_props={ formik_props }
          />
        }
      </Grid>
    </>
  )

  const renderDateFields = ( formik_props ) => (
    <>
      <Grid item xs={ 12 }><Divider /></Grid>
      <Grid item xs={ 6 }>
        <Typography variant="h6">Best Before</Typography>
      </Grid>
      <Grid item xs={ 6 }>
        <Typography variant="h6">Expiry</Typography>
      </Grid>
      <Grid item xs={ 6 }>
        <FormikDateTimePicker
          name='best_before_date'
          label='Best before date'
          formik_props={ formik_props }
          include_time={ false }
        />
      </Grid>
      <Grid item xs={ 6 }>
        <FormikDateTimePicker
          name='expiry_date'
          label='Expiry date'
          formik_props={ formik_props }
          include_time={ false }
        />
      </Grid>
      <Grid item xs={ 12 }>
        <Divider style={ { margin: '10px 0 20px 0' } } />
      </Grid>
    </>
  )

  const renderForm = ( formik_props, is_new ) => {
    const product_qty = get( formik_props, [ 'values', 'actual_quantity' ] ) ? get( formik_props, [ 'values', 'actual_quantity' ] ) : get( formik_props, [ 'values', 'provisional_quantity' ] )
    let total_weight_kg = product_qty * productWeight
    if ( isNaN( total_weight_kg ) ) {
      total_weight_kg = null
    }
    return (
      <Grid container spacing={ gridSpacing }>
        <Grid item xs={ 12 }>
          { ( editingCategory && !isEditingParcelProduct ) ?
            <FormikAutoCompleteDropdown
              name="product_category"
              label="Search for a product..."
              formik_props={ formik_props }
              item_list={ adminProductCategoryLeafNodeAutoCompleteList }
              onChange={ ( product_category ) => { setProductCategory( product_category ) } }
            />
            : !isEditingParcelProduct && <Typography variant="h5"><IconButton size="small" style={ { color: '#2196f3', float: 'right' } } onClick={ () => setEditingCategory( true ) }><EditIcon /></IconButton>{ get( product_category, "name" ) }</Typography> }
        </Grid>
        { formik_props.values.product_category && !editingCategory &&
          <Grid item xs={ 12 }>
            {/* <FormikDropdownWithFormBasedAddNew
              options={ product_variations }
              name="product"
              label="Product variation"
              formik_props={ formik_props }
              onChange={ onSelectProduct }
              item_list={ adminProductList }
              validationSchema={ productVariationValidationSchema }
              onSave={ onSaveNewProductvariation }
              renderForm={ ( formik_props ) =>
                <AdminProductForm product_category={ productCategory } formik_props={ formik_props } inDrawer />
              }
            /> */}
            <FormikAutoCompleteDropdownWithFormBasedAddNew
              item_list={ adminProductList }
              success_message="Product Variation added"
              form_title="New Product Variation"
              name="product"
              validationSchema={ productVariationValidationSchema }
              label="Product variation"
              initial_values={ {
                product_category: productCategory,
                weight_kg: '', // Initialize `weight_kg` even if it has no initial value
                uom: '', // Initialize all other fields similarly
              } }
              renderForm={ ( { formik_props } ) =>
                <AdminProductForm product_category={ productCategory } formik_props={ formik_props } inDrawer />
              }
              formik_props={ formik_props }
            />
          </Grid>
        }
        { ( ( formik_props.values.product_category && formik_props.values.product ) || isEditingParcelProduct ) &&
          <>
            { renderQuantityFields( formik_props ) }
            { ( ( formik_props.values.provisional_quantity || formik_props.values.actual_quantity ) || isEditingParcelProduct ) && renderDateFields( formik_props ) }
            { ( formik_props.values.actual_quantity || isEditingParcelProduct ) && get( product_variation, "weight_kg" ) && renderTotalWeightSection( formik_props ) }
            { showTotalWeightInput &&
              <Grid item xs={ 12 }>
                <FormikInputField
                  name="total_weight_kg"
                  label={ "Weight kg" }
                  formik_props={ formik_props }
                  fullWidth={ false }
                  value={ formik_props.values.actual_quantity * get( product_variation, "weight_kg" ) }
                  InputProps={ {
                    endAdornment: <InputAdornment position="end">kg</InputAdornment>
                  } }
                />
              </Grid>
            }
            { ( formik_props.values.product || isEditingParcelProduct ) && get( product_variation, "weight_kg" ) && renderWeightCalculation( product_qty, formik_props ) }
            { parcel && parcel.direction === 'beneficiary_dropoff' &&
              <Grid item xs={ 12 }>
                <FormikDonorAutoCompleteDropdown
                  name="original_from_company"
                  label="Original donor"
                  formik_props={ formik_props }
                />
              </Grid>
            }
          </>
        }
      </Grid>
    )

  }

  return (
    <>
      { is_loading ? <Loading /> :
        <Formik
          initialValues={ initial_values }
          onSubmit={ onSave }
          enableReinitialize={ true }
          validationSchema={ validationSchema }
        >
          { formik_props => {
            const { values } = formik_props
            if ( productCategory ) {
              values.product_category = productCategory
            }
            return (
              <Form>
                <FormCard
                  isSubmitting={ formik_props.isSubmitting }
                  title={ title }
                  onCancel={ onCancel }
                >
                  { renderForm( formik_props, is_new ) }
                </FormCard>
              </Form>
            )
          }
          }
        </Formik>
      }
    </>
  )
}