import React from 'react'
import { DropzoneDialog } from 'material-ui-dropzone'
import { makeStyles, Grid, Button } from '@material-ui/core'
import CloudUploadIcon from '@material-ui/icons/CloudUpload'
import SyncIcon from '@material-ui/icons/Sync'

const useStyles = makeStyles({
  uploadIcon: {
    marginRight: 10
  }
})

export default function UploadFiles(props) {
  const classes = useStyles()
  const [openFileDialog, setOpenFileDialog] = React.useState(false)
  return(
    <>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Button color="primary" size="small" variant="outlined" className={classes.uploadButton} onClick={() => setOpenFileDialog(true)}>{props.file ? <SyncIcon className={classes.uploadIcon} /> : <CloudUploadIcon className={classes.uploadIcon} /> } {props.title}</Button>
        </Grid>
      </Grid>

      <DropzoneDialog
        acceptedFiles={['image/*']}
        cancelButtonText={"cancel"}
        submitButtonText={"submit"}
        maxFileSize={5000000}
        open={openFileDialog}
        onClose={() => setOpenFileDialog(false)}
        onSave={(files) => {
          setOpenLFileDialog(false)
        }}
        showPreviews={true}
        showFileNamesInPreview={true}
      />
    </>
  )
}
