import React, { useEffect } from 'react'
import * as Yup from 'yup'
import { useDispatch, useSelector } from 'react-redux'
import { FormikInputField } from 'components/form/InputField'
import { FormikDropdownField } from 'components/form/Dropdown'
import { FormikTimePicker } from 'components/form/DatetimePicker'
import { FormControlLabel, Grid } from '@material-ui/core'
import { adminFeedingFrequencies } from '../../../actions/admin_dropdown_options'
import { adminBeneficiaryList } from '../../beneficiaries/actions/admin_beneficiary'
import { FormikAutoCompleteDropdown } from 'components/form/AutoCompleteDropdown'

export const validationSchema = Yup.object().shape( {
  'company': Yup.string().required( "Company is required" ),
  'frequency': Yup.string().required( "frequency is required" ),
  'number_of_times': Yup.string().required( "Number of times is required" ),
} )

export const AdminFeedingFrequencyForm = ( { formik_props, company_id, in_modal } ) => {

  const dispatch = useDispatch()
  const beneficiaries = useSelector( () => adminBeneficiaryList.getAsOptions() )
  const frequency_options = useSelector( () => adminFeedingFrequencies.getAsOptions() )

  useEffect( () => {
    dispatch( adminBeneficiaryList.fetchListIfNeeded() )
    dispatch( adminFeedingFrequencies.fetchListIfNeeded() )
  }, [] )

  return (
    <Grid container spacing={ 2 }>
      <Grid item xs={ 12 } lg={ in_modal ? 12 : 6 }>
        { !company_id &&
          <FormikAutoCompleteDropdown
            item_list={ adminBeneficiaryList }
            name="company"
            label="Beneficiary"
            formik_props={ formik_props }
          />
        }
        <FormikDropdownField
          name="frequency"
          label="Frequency"
          formik_props={ formik_props }
          options={ frequency_options }
        />
        <FormikInputField
          name="number_of_times"
          label="Number of Times"
          formik_props={ formik_props }
          type="number"
        />

      </Grid>
    </Grid>
  )
}

