import { ItemList } from 'orm'
import { EnhancedFilter } from 'admin/actions/enhanced_filter'
import { includes, get, map, head, filter } from 'lodash'
import { download } from 'actions/lib'
import moment from 'moment'

const REPORTS_WHICH_SUPPORT_FORCED_GENERATION = ['donor_impact_report']

class AdminCompanyReport extends ItemList {
    getEntityKey() {
        return "admin/company_report"
    }

    supporteGenerateNow(companyReport) {
        return includes(REPORTS_WHICH_SUPPORT_FORCED_GENERATION, get(companyReport, "report_name"))
    }

    getEnhancedFilter() {
        if ( ! this.enhanced_filter ) {
            this.enhanced_filter = new EnhancedFilter(this)
        }
        return this.enhanced_filter
    }

}

export const adminCompanyReport = new AdminCompanyReport("admin_company_report__default")

class AdminCompanyReportGenerator extends ItemList {

    getEntityKey() {
        return "admin/company_report/force_generate_now"
    }

    forceGenerateNow(company_report_id) {
        return this.saveNewObject({company_report: company_report_id})
    }

    forceGenerateNowAdhoc({company_id, report_name, start_date, end_date}) {
        return this.saveNewObject({company: company_id, report_name, start_date, end_date})
    }

}

export const adminCompanyReportGenerator = new AdminCompanyReportGenerator("admin_company_report_generator__default")
