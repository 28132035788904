import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Trans } from 'react-i18next'
import moment from 'moment'
import { Typography } from '@material-ui/core'

export class Timestamp extends Component {

    render() {
        const { format, value, use_span, className } = this.props
        const ts = moment(value)
        const tsToolTip = moment(value).format('D MMM YYYY, kk:mm')
        const not_set = <Typography variant="caption" color="error">Date not set</Typography>

        if ( use_span === true ) {
            return (
                <span className={className}>
                  { format === 'date_longmonth' && ts.format('DD MMMM YYYY')}
                  { format === 'date' && ts.format('DD MMM YYYY')}
                </span>
            )
        }

        if ( ! value ) {
            return not_set
        }

        return (
            <>
              { value &&
                <>
                  { (!format || format === 'default') &&
                    <>
                        {ts.format('DD MMM YYYY')}
                        &nbsp;-&nbsp;
                        {ts.format('HH:mm')}
                    </>
                  }
                  { (!format || format === 'at') &&
                      <>
                        {ts.format('DD MMM YYYY')}
                        &nbsp;<Trans>at</Trans>&nbsp;
                        {ts.format('HH:mm')}
                      </>
                  }
                  { format === 'extended' &&
                    <>
                        {ts.format('LLLL')}
                        {ts.fromNow()}
                    </>
                  }
                  { format === 'precise' &&
                    <>
                        {ts.format('h:mm:ss a')}
                    </>
                  }
                  { format === 'short-time' &&
                    <>
                        {ts.format('h:mm')}
                    </>
                  }
                  { format === 'short-date' &&
                    <>
                        {ts.format('D MMM')}
                    </>
                  }
                  { format === 'date' &&
                    <>
                        {ts.format('DD MMM YYYY')}
                    </>
                  }
                  { format === 'date_longmonth' &&
                    <>
                        {ts.format('DD MMMM YYYY')}
                    </>
                  }
                  { format === 'time' &&
                    <>
                        {ts.format('HH:mm')}
                    </>
                  }
                  { format === 'longtime' &&
                    <>
                        {ts.format('HH:mm:ss')}
                    </>
                  }
                  { format === 'datetime' &&
                    <>
                        {ts.format('DD MMM YYYY HH:mm:ss')}
                    </>
                  }
                  { format === 'dateshort-time' &&
                    <>
                        {ts.format('DD MMM YYYY HH:mm')}
                    </>
                  }
                  { format === 'month-year' &&
                    <>
                        {ts.format('MMM YYYY')}
                    </>
                  }
                  { format === 'from_now' &&
                    <>
                        {ts.fromNow()}
                    </>
                  }
                </>
              }
            </>
        )
    }
}

function mapStateToProps(state, props) {
    return {
        value: props.value,
        use_span: props.use_span,
        format: props.format || 'default'
    }
}

export default connect(mapStateToProps)(Timestamp)
