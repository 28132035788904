import React, { Component } from 'react';
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { Route, Switch } from 'react-router-dom'
import DonorDashboard from '../dashboard/DonorDashboard'
import Donations from '../donations/Donations'
import MoneyDonations from '../money_donations/MoneyDonations'
import DonateFoodImport from '../donations/DonateFoodImport'
import Distribution from '../donations/Distribution'
import { DonateFoodOrder } from '../donations/DonateFoodOrder'
import DonateMoney from '../donations/DonateMoney'
import DonorTickets from '../tickets/DonorTickets'
import DonorTicket from '../tickets/DonorTicket'
import DonorTaxCertificates from '../taxCertificates/DonorTaxCertificates'
import DonorBranches from '../branches/DonorBranches'
import DonorBranch from '../branches/DonorBranch'
import Section18A from '../section18a/Section18A'
import DonorHelp from '../help/DonorHelp'
import Company from '../company/Company'
import { CompanyProducts } from '../product/CompanyProducts'
import { CompanyProduct } from '../product/CompanyProduct'
import { CompanyProductsImport } from '../product/CompanyProductsImport'
import { CompanyBranchesImport } from 'portals/donor/components/branches/CompanyBranchesImport'
import Profile from '../user/Profile'
import ContactCompanyReports from '../reports/ContactCompanyReports'

import { isLoggedIn, loggedInUser } from 'actions/auth'

class DonorRouter extends Component {

    componentDidUpdate(prevProps) {
        const { is_logged_in, history, has_usable_password, is_superuser } = this.props
        if (!prevProps.is_logged_in && is_logged_in) {
            if (is_superuser) {
                history.push(`/donor/dashboard`)
            } else {
                history.push('/')
            }
        }
        if (prevProps.is_logged_in && !is_logged_in) {
            history.push('/')
        }
    }

    render() {
        const { is_logged_in, is_superuser } = this.props
        if ( ! is_logged_in ) {
            return (
                <div>
                  Not logged in
                </div>
            )
        }

        return (
            <div>
              <Switch>
                <Route path="/donor/help" component={ DonorHelp } />
                <Route path="/donor/section18a_receipts" component={ Section18A } />
                <Route path="/donor/reports" component={ ContactCompanyReports } />
                <Route exact path="/donor/branch/new" render={(props) => <Company is_new={true} {...props} /> } />
                <Route path="/donor/settings/company" component={ Company } />
                <Route path="/donor/settings/company_products/import" children={ <CompanyProductsImport /> }  />
                <Route path="/donor/settings/company_products" children={ <CompanyProducts /> }  />
                <Route path="/donor/settings/company_product/:company_product_id" children={ <CompanyProduct /> }  />
                <Route path="/donor/settings/company_product" children={ <CompanyProduct /> }  />
                <Route path="/donor/user/profile" component={ Profile } />
                <Route path="/donor/branches/import" children={ <CompanyBranchesImport /> }  />
                <Route path="/donor/branches" component={ DonorBranches } />
                <Route path="/donor/donations/order/:order_id" component={ DonateFoodOrder } />
                <Route path="/donor/donations/distribution"  component={ Distribution } />
                <Route path="/donor/donations/tax_certificates" render={(props) => <Donations {...props} page={ "tax_certificates" } /> } />
                <Route path="/donor/tickets/new" component={ DonorTicket } />
                <Route path="/donor/tickets" component={ DonorTickets } />
                <Route path="/donor/donate/money" component={ DonateMoney } />
                <Route path="/donor/donate/food/import" component={ DonateFoodImport } />
                <Route path="/donor/donate/food/new" component={ DonateFoodOrder } />
                <Route path="/donor/donate/order/:order_sheet_file_info_id" component={ DonateFoodOrder } />
                <Route path="/donor/donations/food" component={ Donations } />
                <Route path="/donor/donations/money" component={ MoneyDonations } />
                <Route path="/donor/dashboard" children={ <DonorDashboard /> }  />
                <Route path="/donor/:company_id" component={ DonorDashboard } />
                <Route path="/donor" component={ DonorDashboard } />
              </Switch>
            </div>
        )
    }
}

function mapStateToProps(state) {
    const is_logged_in = isLoggedIn()
    let has_usable_password = true
    let is_superuser = false
    if (is_logged_in) {
        const user = loggedInUser()
        has_usable_password = user && user['has_usable_password']
        is_superuser = user && user['is_superuser']
    }
    return {
        is_logged_in: is_logged_in,
        has_usable_password: has_usable_password,
        is_superuser: is_superuser
    }
}
export default withRouter(connect(mapStateToProps)(DonorRouter))
