import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import AdminCommonListLayout from '../layout/AdminCommonListLayout'
import { adminDemographicList } from './actions/admin_demographic'
import { adminDemographicTotalsMatrixList } from './actions/admin_demographic_totals_matrix'
import DemographicsTotal from './DemographicsTotal'
import { get, capitalize, head } from 'lodash'
import { AdminDemographicForm } from './form/AdminDemographicForm'
import { Formik, Form } from 'formik'
import FormikOnChange from 'components/form/FormikAutoSave'
import AdminDemographic from './AdminDemographic'
import { Modal } from 'components/layout/Modal'
import { Separator } from 'components/layout/Separator'

export default function AdminDemographics({ company_id, inTabs = false }) {

  const dispatch = useDispatch()
  const history = useHistory()

  const demographics = useSelector(() => adminDemographicList.getVisibleObjects())
  const demographics_totals = useSelector(() => adminDemographicTotalsMatrixList.getData())
  const is_loading_demographics_totals = useSelector(() => adminDemographicTotalsMatrixList.isLoading())

  // const demographics_matrix = get(demographics_matrix_list, '[0].id')
  const is_loading = useSelector(() => adminDemographicList.isLoading())
  const as_table = inTabs ? true : false
  const initial_filter = company_id !== undefined ? { company: company_id } : {}
  const [filter, setFilter] = useState(initial_filter)
  const [openModal, setOpenModal] = useState(false)
  const [editingDemographicId, setEditingDemographicId] = useState(null)

  const openEditModal = (demographic_id) => {
    setEditingDemographicId(demographic_id)
    setOpenModal(true)
  }

  const closeEditModal = () => {
    setEditingDemographicId(null)
    dispatch(adminDemographicList.invalidateList())
    dispatch(adminDemographicList.fetchListIfNeeded())
    setOpenModal(false) 
  }

  const onEditDemographic = (demographic_id) => {
    openEditModal(demographic_id)
  }

  const onAddDemographic = () => {
    openEditModal(null) // pass null to indicate we're creating a new demographic
  }

  const params = {
    ...filter,
    // add other parameters here if needed
  }

  useEffect(() => {  
    // invalidate the list when the filter changes
    dispatch(adminDemographicList.invalidateList())
    dispatch(adminDemographicTotalsMatrixList.invalidateList())
    // update the filter state
    dispatch(adminDemographicList.updateListFilter(filter))
    dispatch(adminDemographicTotalsMatrixList.updateListFilter(filter))
    // fetch the list
    dispatch(adminDemographicList.fetchListIfNeeded())
    dispatch(adminDemographicTotalsMatrixList.loadData())
  }, [filter])

  const columns = []

  if (!company_id) {
    columns.push({
      field: 'company_name',
      title: 'Company',
      sort: (direction) => onUpdateListOrdering(direction === 'asc' ? 'company__name' : '-company__name'),
    })
  }

  columns.push(
    {
      field: 'race',
      title: 'Race',
      render: (item) => capitalize(get(item, 'race', '')),
      sort: (direction) => onUpdateListOrdering(direction === 'asc' ? 'race' : '-race')
    },
    {
      field: 'sex',
      title: 'Sex',
      render: (item) => capitalize(get(item, 'sex', '')),
      sort: (direction) => onUpdateListOrdering(direction === 'asc' ? 'sex' : '-sex')
    },
    {
      field: 'age_group',
      title: 'Age Group',
      render: (item) => capitalize(get(item, 'age_group', '')),
      sort: (direction) => onUpdateListOrdering(direction === 'asc' ? 'age_group' : '-age_group'),
    },
    {
      field: 'number_of_people',
      title: 'Number of People',
      sort: (direction) => onUpdateListOrdering(direction === 'asc' ? 'number_of_people' : '-number_of_people')
    }
  )

  const onUpdateListOrdering = (field) => {
    dispatch(adminDemographicList.updateListOrdering(field))
  }

  const onFilterChanged = (values, formik_props) => {
    // only update the filter state if it has actually changed
    if (JSON.stringify(filter) !== JSON.stringify(values)) {
      Object.keys(values).forEach((key) => {
        // If the value of a key is 'all', remove the key from the object
        if (values[key] === 'all') {
          delete (values[key])
        }
      })
      const formattedValues = {
        company: values.company,
        race: values.race,
        sex: values.sex,
        age_group: values.age_group,

      }
      setFilter(values)
      dispatch(adminDemographicList.updateListFilter(formattedValues))
      dispatch(adminDemographicList.fetchListIfNeeded())
    }
  }


  const renderFilter = () => {
    if (openModal) { return false }
    else {
      return (
        <Formik
          initialValues={filter}
          onSubmit={onFilterChanged}
          enableReinitialize={true}
        >
          {formik_props => {
            const { values } = formik_props
            return (
              <Form>
                <FormikOnChange onChange={(values) => onFilterChanged(values, formik_props)} />
                {/* <FormikGeneralFormErrors render={(msg) => msg} /> */}
                <AdminDemographicForm formik_props={formik_props} is_filter />
              </Form>
            )
          }}
        </Formik>
      )
    } 
  }

  return (
    <>
      <AdminCommonListLayout
        active_key="demographics"
        breadcrumbs={[
          { name: 'admin_home' },
          { name: 'demographics', label: "Demographics", url: '/admin/demographics' }
        ]}
        add_label="Add Demographic"
        title={"Demographics"}
        onAddRow={onAddDemographic}
        onEditRow={onEditDemographic}
        is_loading={is_loading}
        columns={columns}
        item_list={adminDemographicList}
        enableAnyFieldFilter={true}
        renderAdditionalFilter={renderFilter}
        as_table={as_table}
        extra_content_top={<DemographicsTotal data={demographics} is_loading={is_loading_demographics_totals} filters={filter} />}
      >
      { openModal && 
        <Modal title="Add/Edit Demographic" onClose={closeEditModal}>
          <Separator height={15} />
          <AdminDemographic in_modal={true} onClose={closeEditModal} passed_demographic_id={editingDemographicId} company_id={company_id} />
        </Modal>
      }
      </AdminCommonListLayout>
      

    </>
  )
}
