import React, { useEffect, useState, useMemo } from 'react'
import { Grid } from '@material-ui/core'
import AdminMainLayout from '../layout/AdminMainLayout'
import { useAxios } from 'hooks/api/useAxios'
import NutritionDashboardKpis from './dashboard_sections/NutritionDashboardKpis'
import NutrientBarChart from './dashboard_sections/NutrientBarChart'
import NutritionDashboardFilterForm from './form/NutritionDashboardFilterForm'
import { NutritionFactsSummary } from './NutritionFactsSummary'
import { Formik } from 'formik'
import FormikOnChange from 'components/form/FormikAutoSave'
import * as Yup from 'yup'
import { map, get, filter } from 'lodash'
import Loading from 'components/Loading'
import CitiesNutritionTable from './dashboard_sections/CitiesNutritionTable'

export const validationSchema = Yup.object().shape({
    // 'to_company': Yup.object().shape({
    //     'name': Yup.string().required("Required")
    // })
})

const formatDate = (date) => {
    const year = date.getFullYear();
    const month = date.getMonth() + 1;
    const day = date.getDate();
    return `${year}-${month.toString().padStart(2, '0')}-${day.toString().padStart(2, '0')}`;
}

export default function AdminNutritionDashboard() {
    const title = "Nutrition Dashboard"
    const [filterValues, setFilterValues] = useState({
        start_at: formatDate(new Date(new Date().getTime() - (30 * 24 * 60 * 60 * 1000))),
        end_at: formatDate(new Date()),
        cities: '',
        to_company: '',
    })
    const params = useMemo(() => filterValues, [filterValues])
    const { response, errors, loading } = useAxios({
        url: 'admin/parcel_nutrition_card/nutrition_summary/',
        params: params
    })
    const [cityMacroSeries, setCityMacroSeries] = useState([])
    const [cityMicroSeries, setCityMicroSeries] = useState([])

    const searchParams = new URLSearchParams(location.search)
    let end_at = new Date();
    end_at.setHours(0, 0, 0, 0);
    let start_at = new Date(end_at.getTime() - (30 * 24 * 60 * 60 * 1000))
    start_at.setHours(0, 0, 0, 0)

    // Update the filterValues state variable based on the URL search params
    useEffect(() => {
        const startAt = searchParams.get("start_at");
        const endAt = searchParams.get("end_at");
    
        if (startAt && endAt) {
        setFilterValues({
            start_at: startAt,
            end_at: endAt,
            cities: searchParams.get("cities") || "",
            to_company: searchParams.get("to_company") || "",
        });
        } else {
        setFilterValues({
            start_at: formatDate(start_at),
            end_at: formatDate(end_at),
            cities: "",
            to_company: "",
        });
        }
    }, [location.search])

    const initial_values = Object.assign({
        'start_at': filterValues.start_at,
        'end_at': filterValues.end_at,
        'cities': '',
        'to_company': ''
    })

    const onFilterChanged = (values) => {
        // Format date values before setting them
        const formattedValues = {
            start_at: formatDate(new Date(values.start_at)),
            end_at: formatDate(new Date(values.end_at)),
            cities: values.cities,
            to_company: values.to_company,
        }
        // Update filter values state variable
        setFilterValues(formattedValues)
    }

    useEffect(() => {
        if (response) {

            const cities_data = get(response, ["cities_data"])

            const city_data_macro = map(cities_data, (cityData, cityName) => ({
                name: cityData.city_name,
                type: 'bar',
                data: map(cityData.macro_nutrients, nutrient => nutrient.nutrient_percentage),
                color: cityData.color ? cityData.color : undefined
            }));
            setCityMacroSeries(city_data_macro)

            const city_data_micro = map(cities_data, (cityData, cityName) => ({
                name: cityData.city_name,
                type: 'bar',
                data: map(cityData.micro_nutrients, (nutrient) => nutrient.nutrient_percentage),
                color: cityData.color ? cityData.color : undefined
            }))
            setCityMicroSeries(city_data_micro)

        }

    }, [response])


    return (
        <AdminMainLayout
            breadcrumbs={[
                { name: 'admin_home' },
                { name: 'nutrition_dashboard', label: title, url: `/admin/nutrition_dashboard` }
            ]}
            is_loading={loading}
            title={title}
        >

            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <button className="button" onClick={() => window.print()}>
                        Export to PDF
                    </button>
                </Grid>
                <Grid item xs={12}>
                    <NutritionDashboardKpis data={get(response, ["cities_data", "all"])} is_loading={loading} filters={filterValues} days_in_range={get(response, "days_in_range")} />
                </Grid>
                <Grid item xs={12}>
                    <Formik
                        initialValues={initial_values}
                        onSubmit={onFilterChanged}
                        enableReinitialize={true}
                        validationSchema={validationSchema}
                        validateOnBlur={false}
                        validateOnChange={true}
                    >
                        {(formik_props) => {
                            const { values } = formik_props

                            return (
                                <>
                                    <FormikOnChange onChange={(values) => onFilterChanged(values, formik_props)} />
                                    <NutritionDashboardFilterForm
                                        value={values.value}
                                        type={values.type}
                                        formik_props={formik_props}
                                    />
                                </>
                            )
                        }}
                    </Formik>
                </Grid>

                <Grid item xs={12} md={8}>
                    { loading ? <Loading /> : <CitiesNutritionTable data={response} />}
                </Grid>
                <Grid item xs={12} md={4}>
                    { loading ? <Loading /> : <NutritionFactsSummary data={get(response, ["cities_data", "all"])} meal_size_in_grams={get(response, "meal_size_in_grams")} /> }
                </Grid>
                <Grid item xs={12} md={6}>
                    { loading ? <Loading /> :
                        <NutrientBarChart
                            title="Macro Nutrients by City"
                            subheader="Values are shown per meal"
                            chart={{
                                labels: map(get(response, ["cities_data", "all", "macro_nutrients"]), (nutrient) => nutrient.nutrient.short_name),
                                series: cityMacroSeries,
                            }}
                        />
                    }
                </Grid>
                <Grid item xs={12} md={6}>
                    { loading ? <Loading /> :
                        <NutrientBarChart
                            title="Micro Nutrients by City"
                            subheader="Values are shown per meal"
                            chart={{
                                labels: map(get(response, ["cities_data", "all", "micro_nutrients"]), (nutrient) => nutrient.nutrient.short_name),
                                series: cityMicroSeries,
                            }}
                        />
                    }
                </Grid>

            </Grid>

        </AdminMainLayout >
    )
}