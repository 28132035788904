import { ItemList } from 'orm'
import { map } from 'lodash'

class TagList extends ItemList {
    getEntityKey() {
        return "admin/tag"
    }
    
}

export const adminTagList = new TagList("admin_tag__default")
export const adminPublishedTagList = new TagList("admin_published_tag__default")

export const invalidateTagLists = () => {
    return (dispatch, getState) => {
        dispatch(adminTagList.invalidateList())
        dispatch(adminPublishedTagList.invalidateList())
    }
}
