import { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { FormikDriverAutoCompleteDropdown } from 'admin/components/drivers/form/DriverAutoCompleteDropdown'
import { FormikDropdownField } from 'components/form/Dropdown'
import { adminDriverStates } from 'admin/actions/admin_dropdown_options'
import { shouldShowOnDemandSaveButton } from 'actions/form'
import { SaveButton } from 'components/form/Buttons'

const OrderDriverForm = ({formik_props}) => {
    const dispatch = useDispatch()
    const driver_status_options =  useSelector(() => adminDriverStates.getAsOptions())

    useEffect(() => {
        dispatch(adminDriverStates.fetchListIfNeeded())
    },[])

    return(
        <>
            <FormikDriverAutoCompleteDropdown
                name="driver"
                label="Driver"
                formik_props={formik_props}
            />
            <FormikDropdownField
                name="driver_status"
                label="Status"
                validate_on_select={true}
                options={driver_status_options}
                formik_props={formik_props}
            />

            { shouldShowOnDemandSaveButton(formik_props, ["driver", "driver_status"]) &&
                <SaveButton isSubmitting={formik_props.isSubmitting} />
            }
        </>
    )
}

export default OrderDriverForm
