import React, { Component } from 'react'
import { connect } from 'react-redux'


import { forEach, head, get, map, keys, split, size } from 'lodash'
import { Separator } from 'components/layout/Separator'
import { Formik, Form, Field } from 'formik'
import * as Yup from 'yup'
import {showSuccess, showError} from 'actions/Error'
import { handleSubmitResult } from 'actions/form'
import { CitySelectField } from 'components/form/CitySelectField'
import { FormikInputField } from 'components/form/InputField'

export const validationSchema = Yup.object().shape({
    'city': Yup.string().required("Required"),
})

class AdminStartingValueForm extends Component {

    render() {
        const { formik_props } = this.props

        return (
            <>
              <CitySelectField name="city"
                                   label="City"
                                   formik_props={formik_props}
              />
              
              <FormikInputField name="num_meals"
                                label="Meals"
                                formik_props={formik_props}
              />

              <FormikInputField name="num_orders"
                                label="Number of orders"
                                formik_props={formik_props}
              />

              <FormikInputField name="weight_kg"
                                label="Weight (kg)"
                                formik_props={formik_props}
              />

            </>
        )
    }

}

export default AdminStartingValueForm
