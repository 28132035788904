import React from 'react'
import { map, get } from 'lodash'
import { Grid, makeStyles, Avatar,Typography } from '@material-ui/core'
import moment from 'moment'
import KpiWidget from 'components/layout/KpiWidget'
import { PARCEL_STATUS_COLOURS } from 'muiTheme'
import Skeleton from '@material-ui/lab/Skeleton'

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    blackChip: {
        color: '#ffffff',
        backgroundColor: '#000000',
        marginLeft: 10,
    },
}))

export default function OrderStatusWidgets({data, is_loading}) {
    const classes = useStyles()
    const states = [
        { status:'pending', title: 'Pending', color: '#fb8c00' },
        { status:'ready_to_go', title: 'Ready to Go', color: '#4fc3f7' },
        { status:'in_transit', title: 'In Transit', color: '#1976d2' },
        { status:'complete', title: 'Completed', color: '#087f23' },
        { status:'order_cancelled', title: 'Cancelled', color: '#d32f2f' },
    ]
    const today = moment()
    data = get(data, "parcels_by_parcel_status")

    let countAllTodaysOrders = data.reduce((a,v) =>  a = a + v.orderCount , 0 )

    const kpi_params = new window.URLSearchParams()
    kpi_params.set('scheduled_at', today.format())

    return (
        <div className={classes.root}>

          <Grid container spacing={0}>

            <Grid item xs={6} md={4} lg={2}>
              { is_loading ? <Skeleton animation="wave" variant="rect" height={100} /> :
              <KpiWidget title = {"All"}
                         kpi= {countAllTodaysOrders}
                         link = {`/admin/parcels?${kpi_params.toString()}`}
                         square = {true}
                         color = {'#cfd8dc'}
              />
              }
            </Grid>

            { states.map(state => {
                let kpi = 0
                data.map(status => {
                    if(status.status == state.status && status.orderCount > 0) {
                        kpi = status.orderCount
                    }
                })

                kpi_params.set('status', state.status)
                return(
                    <Grid item xs={6} md={4} lg={2}>
                      { is_loading ? <Skeleton animation="wave" variant="rect" height={100} /> :
                      <KpiWidget
                        title={state.title}
                        kpi={kpi}
                        link = {`/admin/parcels?${kpi_params.toString()}`}
                        square={true}
                        color = {state.color}
                      />
                      }
                    </Grid>
                )
            })}


          </Grid>
        </div>
    )
}
