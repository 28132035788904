import React, { Component } from 'react'
import { get, split } from 'lodash'
import { useField } from 'formik'
import FormError from './FormError'
import FormWarning from './FormWarning'
import { InputBase } from 'formik-material-ui'
import { Formik, Form, FieldArray, Field } from 'formik'
import { unpackFormikPropsForField } from 'actions/form'

export const FormikInputBase = ({ label, placeholder, InputProps, InputLabelProps, fullWidth=true, type, name, formik_props, touch_on_edit, ...props }) => {

    const { errors, touched } = unpackFormikPropsForField({formik_props, name})

    const setTouched = () => {
        formik_props.setFieldTouched(name)
    }

    return (
        <Field component={InputBase}
               name={name}
               type={type || "text"}
               label={label || placeholder}
               placeholder={placeholder || label}
               id={name}
               helperText={touched ? errors : ""}
               error={Boolean(errors)}
               onInput={setTouched}
               margin="normal"
               variant="outlined"
               fullWidth={fullWidth}
               InputLabelProps={InputLabelProps}
               InputProps={InputProps}
               {...props}
        />
    )
}
