import { useState } from 'react'
import { Grid, makeStyles } from "@material-ui/core"
import { get, map } from 'lodash'
import Loading from 'components/Loading'
import Alert from '@material-ui/lab/Alert'
import ProductCategoryInlineForm from 'admin/components/product_categories/form/ProductCategoryInlineForm'
import { Modal } from 'components/layout/Modal'
import { renderCardFooter, renderDivider, renderCardHeader, renderCardNutrients } from './nutrition_facts/card_sections'

const useStyles = makeStyles((theme) => ({
  nutrition_facts_box: {
    border: '1px solid #000',
    padding: 10
  },
  errors_list: {
    margin: 15,
    marginLeft: 40,
    fontSize: 21,
    textTransform: 'capitalize'
  }
}))

export const NutritionFactsParcel = ({ data, errors, loading, onUpdate }) => {  
  const classes = useStyles()
  const [productCategoryId, setProductCategoryId] = useState(false)
  const [refreshNutritionCard, setRefreshNutritionCard ] = useState(false)

  const renderErrorProductsNutrition = () => {
    return (
      <>
        <Alert severity="warning" className={classes.errors_heading}>
          In order to generate a nutrition facts card for this parcel please ensure that all of the products below have their food category attached to a nutrition food item.
        </Alert>

        <ol className={classes.errors_list}>
          {map(errors, (error) => {
            return (
              <li><a onClick={ () => setProductCategoryId( get( error, "product_category" ) ) }>{ get( error, "product_category_name")}</a></li>
            )
          })}
        </ol>
      </>
    )
  }

  const renderParcelNutritionCard = () => {
    return (
      <Grid container spacing={0} className={classes.nutrition_facts_box}>
        {renderCardHeader(data['calories'], data['meal_size_in_grams'], data['nutrition_score'])}
        {renderCardNutrients(data['macro_nutrients'])}
        {renderDivider()}
        {renderCardNutrients(data['micro_nutrients'])}
        {renderDivider()}
        {renderCardFooter()}
      </Grid>
    )
  }

  const onSaved = () => {
    setProductCategoryId(null)
    if (onUpdate) {
      onUpdate()
    }
  }

  const onCancel = () => {
    setProductCategoryId(null)
  }

  return (
    <>   
      { loading && <Loading /> }
      { data && renderParcelNutritionCard(data) }
      { !data && errors && renderErrorProductsNutrition() }
      {productCategoryId && (
        <Modal noPadding>
          <ProductCategoryInlineForm
            product_category_id={productCategoryId}
            onSaved={onSaved}
            onCancel={onCancel}
            from_parcel
          />
        </Modal>
      )}
    </>
  )
}
