import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { get } from 'lodash'
import AdminCommonListLayout from '../layout/AdminCommonListLayout'
import { adminUOMList } from './actions/admin_uom'
import { ShortId } from 'components/ShortId'

export default function AdminUOMs() {

  const dispatch = useDispatch()
  const history = useHistory()

  const uoms = useSelector(() => adminUOMList.getVisibleObjects())
  const is_loading = useSelector(() => adminUOMList.isLoading())
  const filter = useSelector(() => adminUOMList.getFilter())

  useEffect(() => {
    dispatch(adminUOMList.fetchListIfNeeded())
  },[])

  const onEditUOM = (uom_id) => {
    history.push({
      pathname: '/admin/uom/'+uom_id
    })
  }

  const onAddUOM = () => {
    history.push({
      pathname: '/admin/uom/'
    })
  }

  const onUpdateListOrdering = (field) => {
    dispatch(adminUOMList.updateListOrdering(field))
  }

  const columns = [
    // { field: 'id',
    //   title: 'ID',
    //   render: (item) => <ShortId value={item.id} />,
    //   width: 100
    // },
    { field: 'name',
      title: 'Name',
      sort:(direction) => onUpdateListOrdering(direction === 'asc' ? 'name' : '-name')
    },
    { field: 'code',
      title: 'Code',
      sort:(direction) => onUpdateListOrdering(direction === 'asc' ? 'code' : '-code')
    },
    { field: 'quantity',
      title: 'Quantity',
      sort:(direction) => onUpdateListOrdering(direction === 'asc' ? 'quantity' : '-quantity')
    },
  ]

  return (
    <AdminCommonListLayout
      active_key="uoms"
      breadcrumbs={[
        {name: 'admin_home'},
        {name: 'uoms', label: "UOMs", url: '/admin/uoms'}
      ]}
      add_label="Add UOM"
      title={"UOMs"}
      onAddRow={onAddUOM}
      onEditRow={onEditUOM}
      is_loading={is_loading}
      columns={columns}
      item_list={adminUOMList}
      enableAnyFieldFilter={true}
    />
  )
}
