import { ItemList } from 'orm'
import { onEventUpdated } from 'admin/actions/admin_calendar'
import { EnhancedFilter } from 'admin/actions/enhanced_filter'

class ParcelList extends ItemList {
    getEntityKey() {
        return "admin/parcel"
    }

    cloneParcel({original_parcel_id, values}) {
        values.clone_from_parcel_id = original_parcel_id
        return super.saveNewObject(values)
    }

    createBeneficiaryParcelFromDonorParcel({donor_parcel_id, values}) {
        values.create_beneficiary_parcel_from_parcel_id = donor_parcel_id
        return super.saveNewObject(values)
    }

    getEnhancedFilter() {
        if ( ! this.enhanced_filter ) {
            this.enhanced_filter = new EnhancedFilter(this)
            this.enhanced_filter = new EnhancedFilter(this)
        }
        return this.enhanced_filter
    }

}

export const adminParcelList = new ParcelList("admin_parcel__default")
export const adminParcelListForDashboard = new ParcelList("admin_parcel_for_dashboard__default")
