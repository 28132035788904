import React, { Component } from 'react'
import { get } from 'lodash'
import { Formik, Form, Field } from 'formik'
import * as Yup from 'yup'
import {showSuccess, showError} from 'actions/Error'
import { FormikInputField } from 'components/form/InputField'
import AdminAddressForm from 'admin/components/addresses/form/AdminAddressForm'
import { Grid } from '@material-ui/core'
import { Card } from 'components/layout/Card'
import { AdminAddresses } from 'admin/components/addresses/form/AdminAddressForm'
import { BasicMapWithMarker } from 'components/map/MapMarkers'

export const validationSchema = Yup.object().shape({
    'name': Yup.string().required("Required"),
})

export default function AdminWarehouseForm({ warehouse_id, formik_props, in_drawer }) {

    const address_id = get(formik_props, ['values', 'address', 'id'])
    const newAddressInitialValues = get(formik_props, ['values', 'address'])
    const cols = in_drawer ? 12 : 6

    let mapLat = get(newAddressInitialValues, "latitude")
    let mapLng = get(newAddressInitialValues, "longitude")

    return (
        <Grid container spacing={2}>

            <Grid item xs={12} md={cols}>
                <Card
                  title="Warehouse Details"
                  content={
                    <FormikInputField
                      name="name"
                      label="Name"
                      formik_props={formik_props}
                    />
                  }
                />
                {mapLat && mapLng &&
                    <Card
                        title="Warehouse on Map"
                        content={<BasicMapWithMarker lat={mapLat} lng={mapLng} height={300} showDefaultUI />}
                    />
                }
            </Grid>

            <Grid item xs={12} md={cols}>
                <Card
                    title="Warehouse Address"
                    content={
                        <AdminAddressForm
                            warehouse={warehouse_id}
                            is_warehouse
                            formik_props={formik_props}
                            newAddressInitialValues={newAddressInitialValues}
                            address_id={address_id}
                        />
                    }
                />
            </Grid>

        </Grid>
    )
}
