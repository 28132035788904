import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { adminManufacturerList } from './actions/admin_manufacturer'
import { get } from 'lodash'
import Loading from 'components/Loading'

export const ManufacturerName = ({ manufacturer_id }) => {
    const dispatch = useDispatch()
    const manufacturer = useSelector(() => adminManufacturerList.getObject(manufacturer_id))
    const is_loading = useSelector(() => adminManufacturerList.isLoading())

    useEffect(() => {
        dispatch(adminManufacturerList.ensureObjectLoaded(manufacturer_id))
    },[])

    return is_loading ? <Loading size={20} /> : get(manufacturer, "display_name", "Manufacturer name not loading...")
}
