import React, {useState} from 'react'
import { useFormik } from 'formik'
import * as yup from 'yup'
import { salutations } from 'sampleData/sampleData'
import UploadFiles from './elements/UploadFiles'
import CheckboxGroup from './elements/CheckboxGroup'
import { Field, useField, FieldArray } from 'formik'
import Accordion from 'components/layout/Accordion'
import {
  makeStyles,
  Typography,
  Button,
} from '@material-ui/core'
import {
  BeneficiaryDetails,
  BeneficiaryContactDetails,
  BeneficiaryAddress,
  BeneficiaryPrimaryContact,
  BeneficiaryNpoStatus,
  BeneficiaryFoodRequirements,
  BeneficiaryVehicles,
  BeneficiaryRefrigeration,
  BeneficiaryCookingFacilities,
  BeneficiaryReferences
 } from './beneficiary_panels/BeneficiaryPanels'
 import ReferralSource from './elements/ReferralSource'

const useStyles = makeStyles({
  root:{
    paddingTop: 20
  },
  divider: {
    marginTop: 10,
    marginBottom: 10,
    borderTop: '1px solid #000000',
  },
  headingDivider: {
    borderTop: '1px solid #000000',
  },
  formName: {
    paddingLeft: 15
  },
  submitButton: {
    paddingTop: 20,
    paddingBottom: 20,
    float: 'right'
  }
})

export default function BeneficiaryRegister({formik_props}) {
  const classes = useStyles()
  const foodRequirements = [
    {name: 'uncooked', label: 'Uncooked food'},
    {name: 'cooked', label: 'Cooked food'},
    {name: 'fresh', label: 'Fresh Produce'}
  ]
  const [state, setState] = React.useState({
    registeredNpo: false,
    registeredDsd: false,
    hasVehicles: false,
    hasRefrigeration: false,
    hasCookingFacilities: false,
    foodRequirements: false
  })

  const handleFormChange = () => {
    setState({ ...state, [event.target.name]: event.target.checked });
  }

  const panels = [
      {
        name: 'beneficiary_details',
        title: 'Organisation Details',
        content:  <BeneficiaryDetails />
      },
      {
        name: 'beneficiary_contact_details',
        title: 'Organisation Contact Details',
        content: <BeneficiaryContactDetails />
      },
      {
        name: 'beneficiary_address',
        title: 'Organisation Address',
        content: <BeneficiaryAddress />
      },
      {
        name: 'beneficiary-registration-step-',
        title: 'Primary Contact',
        content: <BeneficiaryPrimaryContact />
      },
      {
        name: 'beneficiary-registration-step-',
        title: 'NPO Status',
        content: <BeneficiaryNpoStatus />
      },
      {
        name: 'beneficiary-registration-step-',
        title: 'Food Requirements',
        content: <BeneficiaryFoodRequirements />
      },
      {
        name: 'beneficiary-registration-step-',
        title: 'Vehicles',
        content: <BeneficiaryVehicles />
      },
      {
        name: 'beneficiary-registration-step-',
        title: 'Refrigeration',
        content: <BeneficiaryRefrigeration />
      },
      {
        name: 'beneficiary-registration-step-',
        title: 'Cooking Facilities',
        content: <BeneficiaryCookingFacilities />
      },
      {
        name: 'beneficiary-registration-step-',
        title: 'References',
        content: <BeneficiaryReferences />
      },
      {
        name: 'beneficiary-registration-step-',
        title: 'How did you hear about us?',
        content: <ReferralSource />
      }
  ]

  return(
    <div className={classes.root}>
      <Typography variant="h4" className={classes.formName}>Beneficiary Registration</Typography>
      <Accordion panels={panels} defaultPanel={panels[0].name + 0} />
      <div className={classes.submitButton}><Button variant="contained" size="large" color="primary">Submit</Button></div>
    </div>
  )
}

{/*
  Short description about your organisation *
  Core business
  Asylum seeker/refugee program
  Church Group
  Homeless Support
  Food relief
  Youth support
  Women's support
  Disability support
  Orphanage
  School

  Number of People Served? *
  Physical Address (organisation) *
  Area/Suburb *
  City *
  Cape Town
  Johannesburg
  Pretoria
  Organisation Email *
  Organisation Telephone *
  Organisation Website
  Organisation's Responsible Contact Person *
  Contact Person Email *
  Contact Person Mobile Telephone *
  Is your organisation a registered NGO/NPC in good standing? *
  Yes
  No
  If Yes, Please provide your NGO/NPC Reg. #
  Upload your NPO documents
  Registered with Department of Social Services?
  Yes
  No
  Do you receive funding or food from any other person organisations? *
  Yes funding
  Yes food
  No
  Other:
  If yes, who and how much?
  Requirements *
  Uncooked food
  Cooked food
  Fresh produce
  Do you have refrigeration and/or freezing facilities? *
  Refrigerator
  Freezer
  Nothing
  If you answered yes to the last question, what size?
  S
  M
  L
  XL
  XXL
  How did you hear about SA HARVEST? *
  Google
  Facebook
  Instagram
  Newsletter
  Other Organisation
  Person
  Other:
  Name and contact detail of two references *
  Any special access instructions for our trucks?</div>
*/}
