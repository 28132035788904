import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import * as Yup from 'yup'
import AdminCommonCompanyForm from 'admin/components/companies/form/AdminCommonCompanyForm'
import { adminBeneficiaryStates } from 'admin/actions/admin_dropdown_options'
import { FormikBeneficiaryAutoCompleteDropdown } from './BeneficiaryAutoCompleteDropdown'
import { BeneficiaryNpoDssFields } from 'components/companies/beneficiaries/BeneficiaryNpoDssFields'
import { FormikInputField } from 'components/form/InputField'

export const validationSchema = Yup.object().shape({
    'company': Yup.object().shape({
        'name': Yup.string().required("Required")
    })
})

export default function AdminBeneficiaryForm(props) {

    const { formik_props, company_id, in_drawer=false } = props
    const dispatch = useDispatch()

    const beneficiary_status_options = useSelector(() => adminBeneficiaryStates.getAsOptions())

    useEffect(() => {
        dispatch(adminBeneficiaryStates.fetchListIfNeeded())
    }, [])

    const renderParentField = (formik_props) => {
        return (
            <FormikBeneficiaryAutoCompleteDropdown
                name="company.parent"
                label="Parent company"
                formik_props={formik_props}
            />
        )
    }

    const renderExtraFieldsPosition1 = (formik_props) => {
        const title="Non Profit & Department of Social Services"
        const content = <BeneficiaryNpoDssFields formik_props={formik_props} />
        return  [title, content]
    }

    // const renderExtraFieldsPosition2 = (formik_props) => {
    //     const title = "Meals Frequency"
    //     const content = <BeneficiaryMealsFrequencyGrid formik_props={formik_props} />
    //     return [ title, content]
    // }

    const renderExtraFieldsPosition3 = (formik_props) => {
        const title="Number of People Served"
        const content = <FormikInputField 
                            name="reported_number_of_people_served"
                            type="number"
                            label="Number of People Served"
                            formik_props={formik_props}
                            fullWidth={false}
                            style={{marginRight: 10}}
                         />
        return  [title, content]
    }

    return (
        <AdminCommonCompanyForm
            formik_props={formik_props}
            renderParentField={renderParentField}
            renderExtraFieldsPosition1={renderExtraFieldsPosition1}
            // renderExtraFieldsPosition2={renderExtraFieldsPosition2}
            renderExtraFieldsPosition3={renderExtraFieldsPosition3}
            company_id={company_id}
            status_options={beneficiary_status_options}
            state_history={props.state_history}
            in_drawer={in_drawer}
        />
    )

}
