import React, { useState } from 'react'
import {
  makeStyles,
  FormControl,
  FormGroup,
  FormLabel,
  FormControlLabel,
  Checkbox,
  FormHelperText
} from '@material-ui/core'

const useStyles = makeStyles({

})

export default function CheckboxGroup({options, formLabel, helpText}) {
  const classes = useStyles()
  const[checked, setChecked] = useState()
  const handleChange = (event) => {
    setChecked(event.target.name)
  }
  const getCheckbox = (option) => {
    return(
      <FormControlLabel
        control={<Checkbox checked={checked} onChange={handleChange} name={option.name} />}
        label={option.label}
      />
    )
  }

  return (
    <FormControl component="fieldset" className={classes.formControl}>
      {formLabel && <FormLabel component="legend">{formLabel}</FormLabel>}
      <FormGroup>
        {options.map(option => (
          getCheckbox(option)
        ))}
      </FormGroup>
      {helpText && <FormHelperText>{helpText}</FormHelperText>}
    </FormControl>
  )
}
