import React from 'react'
import * as Yup from 'yup'
import { FormikInputField } from 'components/form/InputField'
import FormCard from 'components/layout/FormCard'

export const validationSchema = Yup.object().shape({
    'name': Yup.string().required("Required"),
})

const AdminKpiDashboardForm = ({formik_props}) => {

    const renderForm = (formik_props) => {
        return(
            <>
                <FormikInputField
                    name="name"
                    label="Dashboard Name"
                    formik_props={formik_props}
                />

                <FormikInputField
                    name="description"
                    label="Description"
                    multiline
                    rows={5}
                    formik_props={formik_props}
                />

            </>
        )
    }

    return (
        <FormCard noPadding positionFixed>
            {renderForm(formik_props)}
        </FormCard>
    )
}

export default AdminKpiDashboardForm
