import React from 'react'
import AdminMainLayout from '../layout/AdminMainLayout'
import AdminTicketsTable from './AdminTicketsTable'

export default function AdminTickets() {
  const is_loading = ''
  const columns = []
  const adminTicketList = []

  const onAddTicket = () => {

  }

  const onEditTicket = () => {

  }

  return(
    <AdminMainLayout active_key="tickets"
                     breadcrumbs={[{name: 'admin_home'},
                                         {name: 'tickets', label: "Tickets", url: '/admin/tickets'}]}
                     add_label="Add Ticket"
                     onAddRow={onAddTicket}
                     onEditRow={onEditTicket}
                     is_loading={is_loading}
                     columns={columns}
                     item_list={adminTicketList}
    >

      <AdminTicketsTable />

    </AdminMainLayout>
  )
}
