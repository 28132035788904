import { ItemList } from 'orm'
import { download } from 'actions/lib'
import { EnhancedFilter } from 'admin/actions/enhanced_filter'

class AdminWarehouseProductList extends ItemList {
    getEntityKey() {
        return "admin/warehouse_product"
    }

    download() {
        return download("admin/warehouse_product/download/", this.getFilter())
    }

    getEnhancedFilter() {
        if ( ! this.enhanced_filter ) {
            this.enhanced_filter = new EnhancedFilter(this)
        }
        return this.enhanced_filter
    }
}

class AdminWarehouseProductRetrospectiveList extends ItemList {
    getEntityKey() {
        return "admin/warehouse_product/retrospectively_assign_donor"
    }
}


export const adminWarehouseProductList = new AdminWarehouseProductList("admin_warehouse_product__default")
export const adminWarehouseProductAutoCompleteList = new AdminWarehouseProductList("admin_warehouse_product_auto_complete__default")
export const adminWarehouseProductRetrospectiveList = new AdminWarehouseProductRetrospectiveList("admin_warehouse_poroduct_retrospective_list__default")
