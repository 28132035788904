import { FormikDateTimePicker } from 'components/form/DatetimePicker'
import { Grid } from "@material-ui/core"

export const FormikDateRange = ({formik_props, generateNowButton, validationSchema}) => {

  return (
    <Grid container spacing={1}>

      <Grid item>
        <FormikDateTimePicker name="start_date"
                              label="Start Date"
                              formik_props={formik_props}
                              include_time={false}
                              format={'yyyy-MM-dd'}
        />
      </Grid>

      <Grid item>
        <FormikDateTimePicker name="end_date"
                              label="End date"
                              formik_props={formik_props}
                              include_time={false}
                              format={'yyyy-MM-dd'}
        />
      </Grid>

      {generateNowButton &&
        <Grid item>
          <div style={{marginTop: 12}}>{generateNowButton}</div>
        </Grid>
      }

    </Grid>

  )
}
