import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useParams } from 'react-router-dom'
import { get, capitalize } from 'lodash'
import { adminDemographicList } from './actions/admin_demographic'
import { showSuccess, showError } from 'actions/Error'
import { handleSubmitResult } from 'actions/form'
import AdminCommonFormLayout from '../layout/AdminCommonFormLayout'
import { AdminDemographicForm, validationSchema } from './form/AdminDemographicForm'
import AdminCommonForm from '../layout/AdminCommonForm'
import { FormikGeneralFormErrors } from 'components/form/GeneralFormErrors'

export default function AdminDemographic({ in_modal = false, onClose = () => { }, passed_demographic_id = null, company_id = null }) {
    const dispatch = useDispatch()
    const history = useHistory()
    const { demographic_id: params_demographic_id } = useParams()
    const demographic_id = passed_demographic_id || params_demographic_id
    const demographic = useSelector(() => adminDemographicList.getObject(demographic_id))
    const initial_values = demographic
    const is_loading = useSelector(() => adminDemographicList.isLoading()) || (demographic_id && !get(demographic, "id"))
    const is_busy = useSelector(() => adminDemographicList.getIsSavingObject())
    const title = get(demographic, "company_name") ? `${get(demographic, "company_name")} - ${capitalize(get(demographic, ["race"]))}, ${capitalize(get(demographic, ["sex"]))}, ${capitalize(get(demographic, ["age_group"]))}` : "New Demographic"

    useEffect(() => {
        dispatch(adminDemographicList.ensureObjectLoaded(demographic_id))
    }, [])

    const onSave = (values, formik_funcs) => {
        if (company_id) {
            values.company = company_id
        }
        const on_ok = function(json) {
            dispatch(adminDemographicList.invalidateList())
            showSuccess("Saved", "Demographic saved")

            if (!demographic_id) {
                if (in_modal) {
                    onClose();
                } else {
                    history.push(`/admin/demographic/${json.id}`)
                }
            } else if (in_modal) {
                onClose();
            }
        }
        if (demographic_id) {
            values.id = demographic_id
            return dispatch(adminDemographicList.saveObject(values)).then((res) => handleSubmitResult({ res, formik_funcs, on_ok }))
        } else {
            return dispatch(adminDemographicList.saveNewObject(values)).then((res) => handleSubmitResult({ res, formik_funcs, on_ok }))
        }
    }

    const getCellValue = (header_key, item, index) => {
        switch (header_key) {
            default:
                return undefined
        }
    }

    if (in_modal) {
        return (
            <AdminCommonForm
                is_loading={is_loading}
                is_busy={is_busy}
                initial_values={initial_values}
                inModal={true}
                onSave={onSave}>
                {
                    ({ formik_props }) => {
                        return (
                            <>
                                <FormikGeneralFormErrors render={(msg) => msg} name={undefined} />
                                <AdminDemographicForm formik_props={formik_props} company_id={company_id} is_filter={undefined} />
                            </>
                        )
                    }
                }
            </AdminCommonForm>
        )
    } else {
        return (
            <AdminCommonFormLayout
                breadcrumbs={[
                    { name: 'admin_home' },
                    { name: 'demographics', label: 'Demographics', url: '/admin/demographics' },
                    { name: 'demographic', label: title, url: `/admin/demographic/${demographic_id}` }
                ]}
                is_busy={is_busy}
                is_loading={is_loading}
                title={title}
                initial_values={initial_values}
                validationSchema={validationSchema}
                onSave={onSave}
            >
                {
                    ({ formik_props }) =>
                        <>
                            <FormikGeneralFormErrors render={(msg) => msg} name={undefined} />
                            <AdminDemographicForm formik_props={formik_props} company_id={undefined} is_filter={undefined} />
                        </>
                }
            </AdminCommonFormLayout>
        )
    }
}
