import React from 'react'
import { useDispatch } from 'react-redux'
import { handleSubmitResult } from 'actions/form'
import {showSuccess, showError} from 'actions/Error'
import { get } from 'lodash'
import * as Yup from 'yup'
import { adminKpiResultList } from '../actions/admin_kpi_result'
import { FormikInputField } from 'components/form/InputField'
import { FormikCheckboxField } from 'components/form/CheckboxField'
import { FormikGeneralFormErrors } from 'components/form/GeneralFormErrors'
import { Formik, Form } from 'formik'
import FormCard from 'components/layout/FormCard'

export const validationSchema = Yup.object().shape({
    'closed': Yup.bool().oneOf([true],'Required'),
})

const AdminResolveKpiResultForm = ({ kpi_result, onSaved }) => {
    const dispatch = useDispatch()
    const initial_values = kpi_result
    const kpi_result_id = get(kpi_result, "id")

    const renderForm = (formik_props) => {
        return(
            <>
            <FormikCheckboxField
                name="closed"
                label="Mark this KPI Result as Resolved?"
                formik_props={formik_props}
            />
            <FormikInputField
                name="closed_message"
                label="How did you resolve this issue?"
                multiline
                rows={5}
                formik_props={formik_props}
            />
            </>
        )
    }

    const onSave = (values, formik_funcs) => {
        const on_ok = function(json) {
            dispatch(adminKpiResultList.invalidateList())
            showSuccess("Saved", "Resolution KPI Result Saved")
            onSaved()
        }
        if ( kpi_result_id ) {
            values.id = kpi_result_id
            return dispatch(adminKpiResultList.saveObject(values)).then((res) => handleSubmitResult({res, formik_funcs, on_ok}))
        } else {
            showError("Failed", "You can only resolve an existing KPI result record")
            onSaved()
        }
    }

    return (
        <Formik
        initialValues={ initial_values }
        onSubmit={ onSave }
        enableReinitialize={true}
        validationSchema={ validationSchema }
        >
        { formik_props => {
            const { values } = formik_props
            return (
                <Form>
                    <FormCard noPadding isSubmitting={formik_props.isSubmitting}>
                        { renderForm(formik_props) }
                    </FormCard>
                </Form>
            )
        }}

        </Formik>

    )
}

export default AdminResolveKpiResultForm
