import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Route, useHistory } from 'react-router-dom'
import clsx from 'clsx'
import moment from 'moment'
import { head, get, filter, size, map } from 'lodash'
import { adminParcelList } from './actions/admin_parcel'
import { adminDashboardListForToday } from 'admin/components/dashboard/actions/admin_dashboard'
import AdminMainLayout from '../layout/AdminMainLayout'
import AdminParcelsTable from './AdminParcelsTable'
import { ParcelStatus } from 'components/orders/ParcelStatus'
import { Separator } from 'components/layout/Separator'
import { ShortId } from 'components/ShortId'
import { DatePicker } from 'components/form/DatetimePicker'
import ListDescriptionTableField from '../layout/ListDescriptionTableField'
import { provinceList } from 'actions/province'
import { cityList } from 'actions/city'
import { makeStyles } from '@material-ui/core/styles'
import {
    Grid,
    Paper,
    Hidden,
    Button,
    Dialog,
    List,
    ListItem,
    ListSubheader,
    ListItemText,
    Divider,
    Card,
    CardActionArea,
    CardContent,
    Typography,
    Fab,
    Collapse
} from '@material-ui/core'
import OrderStatusWidgets from 'admin/components/orders/widgets/OrderStatusWidgets'
import Icon from '@mdi/react'
import AddIcon from '@material-ui/icons/Add'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import AdminCalendar from 'admin/views/AdminCalendar'
import CalendarTodayIcon from '@material-ui/icons/CalendarToday'
import MapIcon from '@material-ui/icons/Map'
import { MapWithMarkers } from 'components/map/MapMarkers'
import { adminParcelListForDashboard } from 'admin/components/orders/actions/admin_parcel'
import { AddButton } from 'components/form/Buttons'
import { DIRECTION_OPTIONS } from './OrderDirectionOptions'
import ExpandLess from '@material-ui/icons/ExpandLess'
import ExpandMore from '@material-ui/icons/ExpandMore'

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    nested: {
        paddingLeft: theme.spacing(4),
        cursor: 'pointer'
    },
    blackChip: {
        color: '#ffffff',
        backgroundColor: '#000000',
        marginLeft: 10,
    },
    orderTypeIcon: {
        marginBottom: -3,
        marginRight: 7
    },
    mobileAddFab: {
        position: 'fixed',
        bottom: 15,
        right: 10,
        zIndex: 999
    },
    heading: {
        color: '#34515e',
        textAlign: 'right'
    },
    todaysOrders: {
        float: 'right'
    },
    expand: {
        transform: 'rotate(0deg)',
        marginLeft: 'auto',
        transition: theme.transitions.create('transform', {
            duration: theme.transitions.duration.shortest,
        }),
    },
    expandOpen: {
        transform: 'rotate(180deg)',
    },
    numOrders: {
        float: 'right',
        fontSize: '120%',
        border: '1px solid #eeeeee',
        borderRadius: '50%',
        width: 30,
        height: 30,
        textAlign: 'center',
        marginTop: -2,
        fontWeight: 500
    }
}))

export default function AdminParcels() {
    const classes = useStyles()
    const dispatch = useDispatch()
    const history = useHistory()
    const parcelItemList = adminParcelList
    const parcels = useSelector(() => parcelItemList.getVisibleObjects())
    const parcels_today = useSelector(() => adminParcelListForDashboard.getVisibleObjects())
    const item_list = parcelItemList
    const active_filter = useSelector(() => item_list.getFilter() || {})
    const parcel_filter = useSelector(() => parcelItemList.getFilter())
    const is_loading = useSelector(() => parcelItemList.isLoading())
    const data_for_today = useSelector(() => adminDashboardListForToday.getData())
    const data_for_today_totals_by_state = get(data_for_today, "totals_by_state")
    const data_for_today_totals_by_transport = get(data_for_today, "totals_by_transport")
    const today = moment().format()

    let customer_variation = "Customer"
    let warehouse_column_prefix = ""

    const [openBeneficiaries, setOpenBeneficiaries] = useState(true);
    const [openDonors, setOpenDonors] = useState(false);

    const handleClick = () => {
        setOpenBeneficiaries(!openBeneficiaries)
        setOpenDonors(!openDonors)
    }

    if (active_filter.direction == ('donor_pickup' || 'donor_delivery')) {
        customer_variation = "Donor"
        warehouse_column_prefix = "To "
    }
    else if (active_filter.direction == ('beneficiary_dropoff' || 'beneficiary_collection')) {
        customer_variation = "Beneficiary"
        warehouse_column_prefix = "From "
    }

    useEffect(() => {
        async function fetchStaticObjects() {
            dispatch(parcelItemList.fetchListIfNeeded())
            dispatch(cityList.updatePaginationNumItemsPerPage(1000))
            dispatch(provinceList.updatePaginationNumItemsPerPage(1000))
            dispatch(cityList.fetchListIfNeeded())
            dispatch(provinceList.fetchListIfNeeded())
            dispatch(adminDashboardListForToday.updateListFilter({
                from_date: moment().format(),
                to_date: moment().format()
            }))
            dispatch(adminDashboardListForToday.loadData())
            dispatch(adminParcelListForDashboard.updateListFilter({
                from_date: moment().format(),
                to_date: moment().format()
            }))
            dispatch(adminParcelListForDashboard.fetchListIfNeeded())
        }
        fetchStaticObjects()
    }, [])

    useEffect(() => {
        const updateFilter = async() => {
            const url_filter = new window.URLSearchParams(window.location.search)
            const new_filter = {}
            new_filter.direction = url_filter.get('direction') || null
            new_filter.scheduled_at = url_filter.get('scheduled_at') ? moment(url_filter.get('scheduled_at')).format() : null
            new_filter.status = url_filter.get('status') || null
            new_filter.transport_by_warehouse_company = url_filter.get('transport_by_warehouse_company') || null
            dispatch(item_list.updateListFilter(new_filter))
            dispatch(item_list.fetchListIfNeeded(new_filter))
        }
        updateFilter()
    }, [window.location.search])

    const onProvinceChanged = (province_id) => {
        dispatch(cityList.filterOnProvince(province_id))
    }

    const onEditParcel = (parcel_id) => {
        const parcel = head(filter(parcels, (parcel) => parcel.id === parcel.id))
        if (parcel.direction === 'donor_pickup') {
            history.push({ pathname: '/admin/orders/donor/' + parcel_id })
        } else if (parcel.direction === 'beneficiary_dropoff') {
            history.push({ pathname: '/admin/orders/beneficiary/' + parcel_id })
        } else {
            history.push({ pathname: '/admin/parcel/' + parcel_id })
        }
    }

    const onAddDonorPickup = () => {
        history.push('/admin/orders/donor/new/pickup')
    }

    const onAddDonorDelivery = () => {
        history.push('/admin/orders/donor/new/delivery')
    }

    const onAddBeneficiaryDropoff = () => {
        history.push('/admin/orders/beneficiary/new/dropoff')
    }

    const onAddBeneficiaryCollection = () => {
        history.push('/admin/orders/beneficiary/new/collection')
    }

    const onUpdateListOrdering = (field) => {
        dispatch(parcelItemList.updateListOrdering(field))
    }

    const [openNewOrder, setOpenNewOrder] = useState(false)

    const handleNewOrderClick = () => {
        setOpenNewOrder(true)
    }
    const handleNewOrderClose = () => {
        setOpenNewOrder(false)
    }

    const getOrderTypeTodayCard = (history, option, data) => {
        let tbwc = option.transport_by_warehouse_company
        let icon = <Icon size={0.9} path={option.icon} color={'#999999'} className={classes.orderTypeIcon} />
        const numOrders = get(head(filter(data, (d) => d.direction == option.direction && d.transport_by_warehouse_company == option.transport_by_warehouse_company)), "count", 0)
        const today = moment()
        const kpi_params = new window.URLSearchParams()
        kpi_params.set('scheduled_at', today.format())
        kpi_params.set('direction', option.direction)
        kpi_params.set('transport_by_warehouse_company', option.transport_by_warehouse_company)

        return (
            <Grid item xs={12} md={6} lg={3}>
                <Route render={({ history }) => (
                    <Card variant="outlined" square>
                        <CardActionArea onClick={() => history.push(`/admin/parcels?${kpi_params.toString()}`)}>
                            <CardContent>
                                <Typography>
                                    {icon} {option.label}
                                    <span className={classes.numOrders}>
                                        {numOrders}
                                    </span>
                                </Typography>
                            </CardContent>
                        </CardActionArea>
                    </Card>
                )}
                />
            </Grid>
        )
    }

    const [expandedTodaysOrder, setExpandedTodaysOrders] = useState(true)
    const [expandedCalendar, setExpandedCalendar] = useState(false)
    const [expandedMap, setExpandedMap] = useState(false)

    const handleExpandTodaysOrdersClick = () => {
        setExpandedTodaysOrders(!expandedTodaysOrder)
    }

    const handleExpandCalendarClick = () => {
        setExpandedTodaysOrders(false)
        setExpandedMap(false)
        setExpandedCalendar(!expandedCalendar)
    }

    const handleExpandMapClick = () => {
        setExpandedTodaysOrders(false)
        setExpandedCalendar(false)
        setExpandedMap(!expandedMap)
    }

    const renderAddButton = () => {
        return (
            <>
                <Hidden xsDown>
                    <Grid item>
                        <AddButton
                            onClick={handleNewOrderClick}
                            label="New Order"
                            color="secondary"
                        />
                    </Grid>
                </Hidden>
                <Hidden mdUp>
                    <Fab
                        onClick={handleNewOrderClick}
                        color="secondary"
                        className={classes.mobileAddFab}
                        disableElevation
                    >
                        <AddIcon />
                    </Fab>
                </Hidden>
            </>
        )
    }

    const renderKpiDataForToday = () => {
        return (
            <>
                <Grid item>
                    {/* <Button
                        onClick={handleExpandCalendarClick}
                        aria-expanded={expandedCalendar}
                        aria-label="show more"
                        size="small"
                    >
                        <CalendarTodayIcon style={{ marginRight: 10 }} />
                        <Hidden xsDown>Schedule View</Hidden>
                        <ExpandMoreIcon className={clsx(classes.expand, { [classes.expandOpen]: expandedCalendar })} />
                    </Button>
                    <Button
                        onClick={handleExpandMapClick}
                        aria-expanded={expandedMap}
                        aria-label="show more"
                        size="small"
                    >
                        <MapIcon style={{ marginRight: 10 }} />
                        <Hidden xsDown>Map View</Hidden>
                        <ExpandMoreIcon className={clsx(classes.expand, { [classes.expandOpen]: expandedMap })} />
                    </Button> */}
                    <Button
                        size="small"
                        onClick={handleExpandTodaysOrdersClick}
                        aria-expanded={expandedTodaysOrder}
                        aria-label="show more"
                    >
                        Today's Orders
                        <ExpandMoreIcon className={clsx(classes.expand, { [classes.expandOpen]: expandedTodaysOrder })} />
                    </Button>
                </Grid>
                <Grid item xs={12}>
                    <Collapse in={expandedTodaysOrder} timeout="auto" unmountOnExit>
                        <OrderStatusWidgets data={data_for_today_totals_by_state} />
                        {data_for_today_totals_by_transport &&
                            <Grid container spacing={1}>
                                {map(DIRECTION_OPTIONS, option => {
                                    return (
                                        <>{getOrderTypeTodayCard(history, option, data_for_today_totals_by_transport)}</>
                                    )
                                })}
                            </Grid>
                        }
                    </Collapse>
                </Grid>
                <Grid item xs={12}>
                    <Collapse in={expandedCalendar} timeout="auto" unmountOnExit>
                        <AdminCalendar initialView={"timeGridDay"} />
                        <Separator height={15} />
                    </Collapse>
                </Grid>
                <Grid item xs={12}>
                    <Collapse in={expandedMap} timeout="auto" unmountOnExit>
                        <MapWithMarkers height={'600px'} width={"100%"} markers={parcels_today} />
                        <Separator height={15} />
                    </Collapse>
                </Grid>
            </>
        )
    }

    const renderOrderTypeOptions = () => {
        return (
            <Dialog
                onClose={handleNewOrderClose}
                aria-labelledby="new-order-type"
                open={openNewOrder}
                maxWidth={'xs'}
                fullWidth={true}
            >
                    <List
                        component="nav"
                        aria-labelledby="nested-list-subheader"
                        subheader={
                            <ListSubheader component="div" id="nested-list-subheader">
                                Select an order type
                            </ListSubheader>
                        }
                        className={classes.root}
                    >
                        <ListItem button onClick={() => handleClick('beneficiary')}>
                            <ListItemText primary="Beneficiary" />
                            {openBeneficiaries ? <ExpandLess /> : <ExpandMore />}
                        </ListItem>
                        <Collapse in={openBeneficiaries} timeout="auto" unmountOnExit>
                            <List component="div" disablePadding>
                                <ListItem className={classes.nested} onClick={onAddBeneficiaryDropoff}> Dropoff</ListItem>
                                <ListItem className={classes.nested} onClick={onAddBeneficiaryCollection}> Collection</ListItem>
                            </List>
                        </Collapse>
                        <Divider />
                        <ListItem button onClick={() => handleClick('donor')}>
                            <ListItemText primary="Donor" />
                            {openDonors ? <ExpandLess /> : <ExpandMore />}
                        </ListItem>
                        <Collapse in={openDonors} timeout="auto" unmountOnExit>
                            <ListItem className={classes.nested} onClick={onAddDonorPickup}>Pickup</ListItem>
                            <ListItem className={classes.nested} onClick={onAddDonorDelivery}>Delivery</ListItem>
                        </Collapse>
                    </List>
            </Dialog>
        )
    }

    return (
        <AdminMainLayout
            active_key="parcels"
            title={"Orders"}
            breadcrumbs={[
                { name: 'admin_home' },
                { name: 'parcels', label: "Orders", url: '/parcels' }
            ]}
        >
            <Grid container justifyContent="space-between">
                {renderAddButton()}
                {data_for_today_totals_by_state && renderKpiDataForToday()}
            </Grid>

            <AdminParcelsTable parcelItemList={adminParcelList} />

            {renderOrderTypeOptions()}

        </AdminMainLayout>
    )
}
