import React, { useState } from 'react'
import NotificationsNoneIcon from '@material-ui/icons/NotificationsNone'
import { IconButton } from '@material-ui/core'

export default function Notifications({}) {
  const [notifications, setNotifications] = useState(false)
  return (
      <>
        { notifications &&
          <IconButton>
            <NotificationsNoneIcon style={{fontSize: 36}} />
          </IconButton>
        }
      </>
  )
}
