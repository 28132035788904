import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { get } from 'lodash'
import AdminCommonListLayout from '../layout/AdminCommonListLayout'
import { adminNotificationList } from './actions/admin_notification'
import { ShortId } from 'components/ShortId'
import { getTimeAgo } from 'utils/formatDateTime'
import MarkunreadOutlinedIcon from '@material-ui/icons/MarkunreadOutlined'
import DraftsOutlinedIcon from '@material-ui/icons/DraftsOutlined'
import { FormikAutoCompleteDropdown } from 'components/form/AutoCompleteDropdown'
import { adminUserList } from '../users/actions/admin_user'
import { Form, Formik } from 'formik'
import FormikOnChange from 'components/form/FormikAutoSave'
import { FormikGeneralFormErrors } from 'components/form/GeneralFormErrors'
import { Grid, IconButton, Button, Tooltip, Link } from '@material-ui/core'
import { FormikCheckboxField } from 'components/form/CheckboxField'
import { adminNotificationModelList } from './actions/admin_notification_model'
import useAxiosPost from 'hooks/api/useAxiosPost'
import { showSuccess, showError } from 'actions/Error'
import { handleSubmitResult } from 'actions/form'
import { Modal } from 'components/layout/Modal'
import { Separator } from 'components/layout/Separator'
import AdminNotification from './AdminNotification'
import { getColor } from 'components/label/label'


export default function AdminNotifications() {

  const dispatch = useDispatch()
  const history = useHistory()

  const notifications = useSelector( () => adminNotificationList.getVisibleObjects() )
  const is_loading = useSelector( () => adminNotificationList.isLoading() )
  const [ showSelect, setShowSelect ] = useState( false )
  const [ selectedRowIds, setSelectedRowIds ] = useState( [] )
  const [post, { loading, error }] = useAxiosPost()
	const [response, setResponse] = useState(null)
  const [ submitting, setSubmitting ] = useState( false )
  const [ openModal, setOpenModal ] = useState( false )
  const [ editingNotificationId, setEditingNotificationId ] = useState( null )

  const closeEditModal = () => {
    setEditingNotificationId( null )
    dispatch( adminNotificationList.invalidateList() )
    dispatch( adminNotificationList.fetchListIfNeeded() )
    setOpenModal( false )
  }

  useEffect( () => {
    dispatch( adminNotificationList.invalidateList() )
    dispatch( adminNotificationList.updateListFilter() )
    dispatch( adminNotificationList.fetchListIfNeeded() )
    dispatch( adminNotificationModelList.updateListFilter({ create_notification: true }) )
    dispatch( adminNotificationModelList.fetchListIfNeeded() )
  }, [] )

  const updateNotification = ( notification, redirect = true, mark_all_read ) => {

    const notification_id = get( notification, "id" )
    const values = notification
    const mark_read_message = notification.is_read ? "unread" : "read"

    const on_ok = function( json ) {
      dispatch( adminNotificationList.invalidateList() )
      dispatch( adminNotificationList.fetchListIfNeeded() )
      showSuccess( "Saved", `Notification marked as ${ mark_read_message }` )
      if ( redirect ) {
        history.push( '/admin' + notification.link )
      }
    }
    if ( notification_id ) {
      values.id = notification_id
      values.is_read = notification.is_read ? false : true
      return dispatch( adminNotificationList.saveObject( values ) ).then( ( res ) => handleSubmitResult( { res, on_ok } ) )
    }
  }

  const onEditNotification = ( notification_id ) => {
    setEditingNotificationId( notification_id )
    setOpenModal( true )
  }

  const onAddNotification = () => {
    setEditingNotificationId( null )
    setOpenModal( true )
  }

  const onUpdateListOrdering = ( field ) => {
    dispatch( adminNotificationList.updateListOrdering( field ) )
  }

  const onClearFilter = () => {
    const values = {}
    onFilterChanged( values )
  }

  const handleMarkAllAsRead = async(is_read) => {
		try {
			const formData = new FormData()
			formData.append( 'is_read', is_read )
      formData.append( 'selected_row_ids', JSON.stringify(selectedRowIds) )
      const read_status = is_read ? 'unread' : 'read'

			const postResponse = await post( 'admin/notification/mark_all_read/', formData )
			setSubmitting( false )
			setResponse( postResponse )
			showSuccess( "Saved", `All notifications marked as ${read_status}` )
      dispatch( adminNotificationList.invalidateList() )
      dispatch( adminNotificationList.updateListFilter() )
      dispatch( adminNotificationList.fetchListIfNeeded() )
		} catch ( err ) {
			console.error( error )
			setSubmitting( false )
			showError( "Error", `500 Internal Server Error` )
		}
	}

  const renderFilter = () => {
    return (
      <Formik
        initialValues={{ is_read: false }}
        onSubmit={ onFilterChanged }
        enableReinitialize={ true }
      >
        { formik_props => {
          const { values } = formik_props
          return (
            <Form>
              <FormikOnChange onChange={ ( values ) => onFilterChanged( values, formik_props ) } />
              <FormikGeneralFormErrors render={ ( msg ) => msg } />
              <Grid
                container
                direction="row"
                alignItems="center"
                spacing={ 2 }
                style={ { marginBottom: -10 } }
              >
                <Grid item xs={ 12 } sm={ 6 } lg={ 2 }>
                  <FormikAutoCompleteDropdown
                    name="user"
                    formik_props={ formik_props }
                    item_list={ adminUserList }
                    label="User"
                  />
                </Grid>
                <Grid item xs={ 12 } sm={ 6 } lg={ 2 }>
                  <FormikAutoCompleteDropdown
                    name="notification_model"
                    formik_props={ formik_props }
                    item_list={ adminNotificationModelList }
                    label="Model"
                  />
                </Grid>
                <Grid item xs={ 12 } sm={ 6 } lg={ 2 }>
                  <FormikCheckboxField
                    name="is_read"
                    label="Is read"
                    formik_props={ formik_props }
                  />
                </Grid>
              </Grid>
            </Form>
          )
        } }
      </Formik>
    )
  }

  const onFilterChanged = ( values, formik_props ) => {
    dispatch( adminNotificationList.invalidateList( values ) )
    dispatch( adminNotificationList.updateListFilter( values ) )
    dispatch( adminNotificationList.fetchListIfNeeded() )
  }

  const renderAdditionalActions = () => {

    return (
      <>
        { !showSelect && <Button onClick={ () => setShowSelect( true ) } size="small" color="primary">Select rows</Button> }
        { showSelect && selectedRowIds.length > 0 &&
          <>
            <Tooltip arrow title="Mark as Read">
              <IconButton aria-label="mark as read" onClick={ () => handleMarkAllAsRead(true) }>
                <DraftsOutlinedIcon />
              </IconButton>
            </Tooltip>
            <Tooltip arrow title="Mark as Unread">
              <IconButton aria-label="mark as read" onClick={ () => handleMarkAllAsRead(false) }>
                <MarkunreadOutlinedIcon />
              </IconButton>
            </Tooltip>
          </>
        }
      </>
    )
  }


  const columns = [
    // { field: 'id',
    //   title: 'ID',
    //   render: (item) => <ShortId value={item.id} />,
    //   width: 100
    // },
    {
      field: 'is_read',
      title: '',
      width: 40,
      render: ( item ) => get( item, "is_read" ) ? <DraftsOutlinedIcon style={ { color: '#999' } } /> : <MarkunreadOutlinedIcon style={{ color: getColor(get(item, "severity"))}} />,
    },
   
    {
      field: 'model_verbose_name',
      title: 'Model',
      render: ( item ) => <span style={ { fontWeight: get( item, "is_read" ) ? '' : '500', textTransform: 'capitalize'} }>{ get( item, "model_verbose_name" ) }</span>,
      sort: ( direction ) => onUpdateListOrdering( direction === 'asc' ? 'model_verbose_name' : '-model_verbose_name' )
    },
    {
      field: 'username',
      title: 'Notify User',
      render: ( item ) => <span style={ { fontWeight: get( item, "is_read" ) ? '' : '500' } }>{ get( item, "username" ) }</span>,
    },
    {
      field: 'message',
      title: 'Message',
      render: ( item ) => <span style={ { fontWeight: get( item, "is_read" ) ? '' : '500' } }>
        { get( item, "link" ) ? <Link onClick={() => updateNotification( item, true ) }>{ get( item, "message" ) }</Link> : get( item, "message" ) }
      </span>,
      sort: ( direction ) => onUpdateListOrdering( direction === 'asc' ? 'message' : '-message' )
    },
    {
      field: 'object_created_by',
      title: 'Created by',
      render: ( item ) => <span style={ { fontWeight: get( item, "is_read" ) ? '' : '500' } }>{ get( item, "object_created_by" ) ? get( item, "object_created_by" ) : get( item, "created_by" ) }</span>,
      sort: ( direction ) => onUpdateListOrdering( direction === 'asc' ? 'object_created_by' : '-object_created_by' )
    },
    {
      field: 'created_at',
      title: 'Created',
      render: ( item ) => <span style={ { fontWeight: get( item, "is_read" ) ? '' : '500' } }>{ getTimeAgo( get( item, "created_at" ) ) }</span>,
      sort: ( direction ) => onUpdateListOrdering( direction === 'asc' ? 'created_at' : '-created_at' )
    }
  ]

  return (
    <AdminCommonListLayout
      active_key="notifications"
      breadcrumbs={ [
        { name: 'admin_home' },
        { name: 'notifications', label: "Notifications", url: '/admin/notifications' }
      ] }
      add_label="New Notification"
      title={ "Notifications" }
      onAddRow={ onAddNotification }
      onEditRow={ onEditNotification }
      is_loading={ is_loading }
      columns={ columns }
      item_list={ adminNotificationList }
      enableAnyFieldFilter={ true }
      renderAdditionalFilter={ renderFilter }
      renderAdditionalActions={ renderAdditionalActions }
      selectedRowIds={ selectedRowIds }
      updateSelectedRowIds={ setSelectedRowIds }
      renderSelect={ showSelect }
      rowClickRedirect
    >
      { openModal &&
        <Modal title="New Notification" onClose={ closeEditModal } maxWidth="sm" fullWidth={true} >
          <Separator height={ 15 } />
          <AdminNotification in_modal={ true } onClose={ closeEditModal } passed_notification_id={ editingNotificationId } />
        </Modal>
      }
      </AdminCommonListLayout>
  )
}
