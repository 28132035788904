import React from "react";
import { Bar } from "react-chartjs-2";
import { mealsDeliveredOptions } from "./ChartOptions";

const MealsDeliveredBarChart = React.forwardRef(({ data }, ref) => {
  return (
    <>
      <Bar data={data} options={mealsDeliveredOptions} className="chartjsbar"  ref={ref}/>
    </>
  );
});
export default MealsDeliveredBarChart;
