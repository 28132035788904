import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { get, map, head } from 'lodash'
import AdminCommonListLayout from '../layout/AdminCommonListLayout'
import { adminChecklistScheduleList } from './actions/admin_checklist_schedule'
import { adminChecklistResultList } from './actions/admin_checklist_result'
import AdminChecklistResultForm from './form/AdminChecklistResultForm'
import AdminChecklistScheduleForm from './form/AdminChecklistScheduleForm'
import { ShortId } from 'components/ShortId'
import { weekDayValueToString } from 'components/form/Frequency'
import { capitalCamel} from 'components/CapitalCamel'
import { Button, Grid, Tooltip } from "@material-ui/core"
import { UserName } from '../users/UserAttributes'
import { ChecklistName } from './ChecklistAttributes'
import AddIcon from '@material-ui/icons/Add'
import { AddButton } from 'components/form/Buttons'
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline'
import PlayArrowIcon from '@material-ui/icons/PlayArrow'
import Timestamp from 'components/Timestamp'
import Loading from 'components/Loading'
import ImportContactsIcon from '@material-ui/icons/ImportContacts'

export default function AdminChecklistSchedules() {
    const dispatch = useDispatch()
    const history = useHistory()
    const checklist_schedule = useSelector(() => adminChecklistScheduleList.getVisibleObjects())
    const checklist_ids = map(checklist_schedule, (x) => x.checklist_result)
    const checklist_results = useSelector(() => adminChecklistResultList.getObjects(checklist_ids))
    const is_loading_checklist_results =  useSelector(() => adminChecklistResultList.isLoading())
    const is_loading = useSelector(() => adminChecklistScheduleList.isLoading())
    const filter = useSelector(() => adminChecklistScheduleList.getFilter())
    const [checklistScheduleId, setChecklistScheduleId] = useState(null)
    const [editingChecklistSchedule, setEditingChecklistSchedule] = useState(null)
    const [userId, setUserId] = useState(null)
    const [checklistId, setChecklistId] = useState(null)
    const [checklistResultId, setChecklistResultId] = useState(null)

    useEffect(() => {
        dispatch(adminChecklistScheduleList.invalidateList())
        dispatch(adminChecklistScheduleList.fetchListIfNeeded())
    },[])

    useEffect(() => {
        dispatch(adminChecklistResultList.ensureObjectsLoaded(checklist_ids))
    },[checklist_schedule])

    const onUpdateListOrdering = (field) => {
      dispatch(adminChecklistScheduleList.updateListOrdering(field))
    }

    const onAddSchedule = () => {
        history.push('/admin/checklists/schedule')
    }

    const onEditSchedule = (checklist_schedule_id) => {
        history.push(`/admin/checklists/schedule/` + checklist_schedule_id)
    }

    const onSaved = () => {
        dispatch(adminChecklistScheduleList.invalidateList())
        dispatch(adminChecklistScheduleList.fetchListIfNeeded())
        setChecklistScheduleId(null)
        setEditingChecklistSchedule(false)
    }

    const renderMomentTypeAsFrequency = (moment_type) => {
        if(moment_type == 'day_of_week') {
            return "Weekly"
        } else if(moment_type == 'day_of_month') {
            return "Monthly"
        }
    }

    const renderReadableSchedule = (item) => {
        var today = new Date()
        var today_value = today.getDay() - 1 // -1 so that first day is 0 like schedule values
        var moment_type = get(item, ["complete_checklist_on", "moment_type"])
        var schedule_value = get(item, ["complete_checklist_on", "value"])
        var schedule_value_as_day = moment_type == 'day_of_week' ? weekDayValueToString(schedule_value) : schedule_value
        var this_or_next = today_value > schedule_value ? "Next" : "This"
        var today = today_value === schedule_value
        var tomorrow = today_value === schedule_value - 1
        var week_or_month = moment_type == 'day_of_week' ? "Week" : "Month"
        if(today) {
            return "Today"
        }
        else if(tomorrow) {
            return "Tomorrow"
        }
        else {
            return this_or_next + " " + week_or_month + " " + schedule_value_as_day
        }

    }

    const getChecklistResults = (checklist_result_id) => {
        history.push('/admin/checklists/results/' + checklist_result_id)
    }

    const renderChecklistStatus = (item) => {
        setChecklistScheduleId(item.id)
        setUserId(item.user)
        var checklist_result = head(map(checklist_results, (x) => {
            if(get(item, "checklist_result") === get(x, "id")) {
                return x
            }
        }))
        const completed_at = <>Completed <Timestamp value={get(checklist_result, "completed_at")} format="at" /></>
        if(get(checklist_result, 'completed_at')) {
            return (
                is_loading_checklist_results ? <Loading size={20} /> :
                <>
                <Tooltip title={completed_at} arrow>
                    <CheckCircleOutlineIcon style={{color: 'green', marginBottom: -5, marginRight: 10}} />
                </Tooltip>
                <a onClick={() => history.push('/admin/checklists/results/' + get(item, "checklist_result"))}>View Results</a>
                </>
            )
        }
        else if(get(item, "checklist_result")) {
            return(
                is_loading_checklist_results ? <Loading size={20} /> :
                <Tooltip title="Continue" arrow>
                    <ImportContactsIcon style={{cursor: 'pointer'}} color="primary" onClick={() => getChecklistResults(get(item, "checklist_result"))} />
                </Tooltip>
            )
        }
        else {
            return (
                <Tooltip title="Start" arrow>
                    <PlayArrowIcon color="primary" style={{cursor: 'pointer'}} onClick={() => getChecklistResults(checklist_result)} />
                </Tooltip>
            )
        }

    }

    const columns = [
        {
            field: 'id',
            title: 'ID',
            render: (item) => <a onClick={() => onEditChecklist(get(item, "id"))}><ShortId value={get(item, "id")} /></a>,
        },
        {
            field: 'user',
            title: 'User',
            render: (item) => <UserName user_id={item.user} />
        },
        {
            field: 'checklist',
            title: 'Checklist',
            render: (item) => <ChecklistName checklist_id={item.checklist} />,
            sort: (direction) => onUpdateListOrdering(direction === 'asc' ? 'checklist' : '-checklist')
        },
        {
            field: 'moment_type',
            title: 'Frequency',
            render: (item) => renderMomentTypeAsFrequency(item.complete_checklist_on.moment_type),
            sort: (direction) => onUpdateListOrdering(direction === 'asc' ? 'frequency' : '-frequency')
        },
        {
            field: 'complete_checklist_on',
            title: 'Next Check',
            render: (item) => renderReadableSchedule(item),
            sort: (direction) => onUpdateListOrdering(direction === 'asc' ? 'checklist_checks_count' : '-checklist_checks_count')
        },
        {
            field: '',
            title: 'Status',
            align: 'center',
            render: (item) => renderChecklistStatus(item)
        }
    ]

    const renderActions = () => {
        return(
            <Grid container spacing={2}>
                <Grid item>
                    <AddButton label="Checklists" color="primary" variant="outlined" onClick={() => history.push('/admin/checklists')} />
                </Grid>
                <Grid item>
                    <AddButton label="Results" color="primary" variant="outlined" onClick={() => history.push('/admin/checklists/results')} />
                </Grid>
            </Grid>
        )
    }

    return (
      <AdminCommonListLayout
          active_key="checklist_schedule"
          breadcrumbs={[
              {name: 'admin_home'},
              {name: 'checklists', label: "Checklists", url: '/admin/checklists'},
              {name: 'checklist_schedule', label: "Schedule", url: '/admin/checklists/schedules'}
          ]}
          add_label={<AddIcon />}
          onAddRow={onAddSchedule}
          onEditRow={onEditSchedule}
          can_delete={false}
          is_loading={is_loading}
          columns={columns}
          item_list={adminChecklistScheduleList}
          rows={checklist_schedule}
          title="Checklist Schedule"
          renderAdditionalActions={renderActions}
      />

    )

}
