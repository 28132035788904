import React, { Component } from 'react';
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { Route, Switch } from 'react-router-dom'
import ImpactMap from '../ImpactMap'
import Company from '../Company'
import CompanyRegister from '../register/CompanyRegister'

import { isLoggedIn, loggedInUser } from 'actions/auth'

class MarketingRouter extends Component {

    componentDidUpdate(prevProps) {
        const { is_logged_in, history, has_usable_password, is_superuser } = this.props
        if (!prevProps.is_logged_in && is_logged_in) {
            if (is_superuser) {
                history.push('/donor/dashboard')
            } else {
                history.push('/')
            }
        }
        if (prevProps.is_logged_in && !is_logged_in) {
            history.push('/')
        }
    }

    render() {
        const { is_logged_in, is_superuser } = this.props
        if ( ! is_logged_in ) {
            return (
                <div>
                  <Switch>
                    <Route path="/impact/register/beneficiary" render={(props) => <CompanyRegister companyType="beneficiary" {...props} />} />
                    <Route path="/impact/register/donor" render={(props) => <CompanyRegister companyType="donor" {...props} />} />
                    <Route path="/impact/register/logistics" render={(props) => <CompanyRegister companyType="logistics" {...props} />} />
                    <Route path="/impact/beneficiary/:company_id" render={(props) => <Company companyType="beneficiary" {...props} />} />
                    <Route path="/impact/donor/:company_id" render={(props) => <Company companyType="donor" {...props} />} />
                    <Route path="/impact/logistics/:company_id" render={(props) => <Company companyType="logistics" {...props} />} />
                    <Route path="/impact" component={ ImpactMap } />
                    <Route path="/" component={ ImpactMap } />
                  </Switch>
                </div>
            )
        }

        return (
            <div>
              <Switch>
                <Route path="/impact/register/beneficiary" render={(props) => <CompanyRegister companyType="beneficiary" {...props} />} />
                <Route path="/impact/register/donor" render={(props) => <CompanyRegister companyType="donor" {...props} />} />
                <Route path="/impact/register/logistics" render={(props) => <CompanyRegister companyType="logistics" {...props} />} />
                <Route path="/impact/beneficiary/:company_id" render={(props) => <Company companyType="beneficiary" {...props} />} />
                <Route path="/impact/donor/:company_id" render={(props) => <Company companyType="donor" {...props} />} />
                <Route path="/impact/logistics/:company_id" render={(props) => <Company companyType="logistics" {...props} />} />
                <Route path="/impact/" component={ ImpactMap } />
                <Route path="/" component={ ImpactMap } />
              </Switch>
            </div>
        )
    }
}

function mapStateToProps(state) {
    const is_logged_in = isLoggedIn()
    let has_usable_password = true
    let is_superuser = false
    if (is_logged_in) {
        const user = loggedInUser()
        has_usable_password = user && user['has_usable_password']
        is_superuser = user && user['is_superuser']
    }
    return {
        is_logged_in: is_logged_in,
        has_usable_password: has_usable_password,
        is_superuser: is_superuser
    }
}
export default withRouter(connect(mapStateToProps)(MarketingRouter))
