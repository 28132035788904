import { FormikInputField } from 'components/form/InputField'

const AdminChecklistQuestionForm = ({formik_props}) => {

    return(
        <>
            <FormikInputField
                name="text"
                label="Question"
                formik_props={formik_props}
            />

            <FormikInputField
                name="description"
                label="Description"
                multiline
                rows={5}
                formik_props={formik_props}
            />

      </>
    )
}


export default AdminChecklistQuestionForm
