import React, { useEffect, useRef } from 'react'
import { get } from 'lodash'
import { FormikDropdownField } from 'components/form/Dropdown'
import { FormikInputField } from 'components/form/InputField'
import { FormikTextareaField } from 'components/form/TextareaField'
import { FormikCheckboxField } from 'components/form/CheckboxField'
import { useDispatch } from 'react-redux'
import { countryList } from 'actions/country'
import { provinceList } from 'actions/province'
import { CommonTable } from 'components/CommonTable'
import BeneficiaryMainLayout from './BeneficiaryMainLayout'
import BeneficiaryTableFilter from './BeneficiaryTableFilter'
import { Separator } from 'components/layout/Separator'
import { Button, Paper, Grid, makeStyles } from '@material-ui/core'
import SearchBar from 'components/layout/SearchBar'

const useStyles = makeStyles((theme) => ({
  root: {
    padding: 15,
  },
  labelRoot: {
    paddingLeft: 15,
    fontSize: 24,
  },
  labelShinked: {
    marginLeft: 5,
    fontSize: 16
  }
}))

const BeneficiaryCommonListLayout = ({
  title,
  active_key,
  add_label,
  import_label,
  canDelete,
  onAddRow,
  onViewRow,
  onImportRows,
  renderAdditionalFilter,
  enableAnyFieldFilter,
  renderAdditionalActions,
  useColumnsSelector,
  storeTableName,
  onEditRow,
  is_loading,
  breadcrumbs,
  columns,
  item_list,
  showNumbers
}) => {
    const classes = useStyles()
    const rows = item_list.getVisibleObjects()
    const dispatch = useDispatch()
    const componentRef = useRef(null)
    const buttonSpacer = import_label || add_label ? <Separator height={10} /> : null

    const onFilterChanged = (filter_values) => {
        dispatch(item_list.updateListFilter(filter_values))
        dispatch(item_list.fetchListIfNeeded())
    }

    const renderFilter = (formik_props) => {
        return (
            <div style={{marginLeft: -15, marginRight: -15}}>
              { renderAdditionalFilter && renderAdditionalFilter(formik_props) }

              { enableAnyFieldFilter &&
                <SearchBar formik_props={formik_props} />
              }
            </div>
        )
    }

    return (
        <BeneficiaryMainLayout active_key={active_key}
                         breadcrumbs={breadcrumbs}
                         title={title}>
          <Grid container justifyContent="space-between" className={classes.root}>
            <Grid item>
            { add_label &&
              <Button variant="contained" color="primary" onClick={ onAddRow } size="large" style={{marginRight: 7}}>{add_label}</Button>
            }

            { import_label &&
                <Button variant="contained" color="primary" onClick={ onImportRows } size="large">{import_label}</Button>
            }
            { buttonSpacer }
            </Grid>
            <Grid item>
              { renderAdditionalActions && renderAdditionalActions() }
            </Grid>
          </Grid>

          <Paper variant="outlined" square>
            <BeneficiaryTableFilter
              label=""
              placeholder="Search"
              renderFilter={ renderFilter }
              updateOnChange={ onFilterChanged }
              style={{marginLeft: 10, marginRight: 10}}
            />

          <CommonTable
            is_loading={ is_loading }
            rows={rows}
            columns={ columns }
            item_list={ item_list }
            onImportRows={ onImportRows }
            onEditRow={ onEditRow }
            onViewRow={ onViewRow }
            canDelete={ canDelete }
            add_label={ add_label }
            render_additional_filter={ renderAdditionalFilter }
            showNumbers={showNumbers}
            useColumnsSelector={useColumnsSelector}
            storeTableName={storeTableName}
          />
        </Paper>
    </BeneficiaryMainLayout>
  )
}

export default BeneficiaryCommonListLayout
