import { Grid, makeStyles } from "@material-ui/core"
import { get } from 'lodash'
import { useAxios } from 'hooks/api/useAxios'
import { renderCardFooter, renderDivider, renderCardHeader, renderCardNutrients } from './nutrition_facts/card_sections'
import Loading from 'components/Loading'

const useStyles = makeStyles((theme) => ({
  nutrition_facts_box: {
    border: '1px solid #000',
    padding: 10
  },
}))

export const NutritionFactsFoodItem = ({ food_item_id }) => {
  const classes = useStyles()
  const { response, errors, loading } = useAxios({
    url: `admin/nutrition_card/nutrition_facts_food_item/`,
    params: { 'ref': food_item_id }
  })

  return (
    <Grid container spacing={0} className={classes.nutrition_facts_box}>
        { loading ? <Loading /> :
        <>
          {renderCardHeader(get(response, "calories"), get(response, "meal_size_in_grams"), get(response, "nutrition_score"))}
          {renderCardNutrients(get(response, "macro_nutrients"))}
          {renderDivider()}
          {renderCardNutrients(get(response, "micro_nutrients"))}
          {renderDivider()}
          {renderCardFooter()}
        </>
        }
      </Grid>
    )

}

