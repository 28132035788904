import React, { useState, useEffect } from 'react'
import { get, tail, startCase } from 'lodash'
import { useDispatch, useSelector } from 'react-redux'
import { adminProductAutoCompleteList } from '../actions/admin_product'
import AdminProductForm from './AdminProductForm'
import { showSuccess, showError } from 'actions/Error'
import { Formik, Form } from 'formik'
import { CommonTable } from 'components/CommonTable'
import { handleSubmitResult } from 'actions/form'
import { validationSchema } from './AdminProductForm'
import { Modal } from 'components/layout/Modal'
import { makeStyles, Typography, TextField, Link } from '@material-ui/core'
import FormikOnChange from 'components/form/FormikAutoSave'
import { FormikGeneralFormErrors } from 'components/form/GeneralFormErrors'
import { ProductCategorySelectField } from 'admin/components/product_categories/form/ProductCategorySelectField'
import { HierarchyCrumbs } from 'components/layout/HierarchyCrumbs'
import { Button, Grid, Hidden, Chip } from '@material-ui/core'
import CommonInlineAddNewFormLayout from 'components/layout/CommonInlineAddNewFormLayout'
import SearchBar from 'components/layout/SearchBar'
import { AddButton } from 'components/form/Buttons'

const useStyles = makeStyles( ( theme ) => ( {
	treeContainer: {
		margin: 0
	},
	crumbRow: {
		display: "flex"
	},
	filterRow: {
		display: "flex",
		alignItems: "center",
		justifyContent: "space-between",
		width: "100%"
	},
	filterRowItem: {
		marginRight: "10px",
		width: "100%"
	},
	search_bar: {
		marginLeft: -18,
		[ theme.breakpoints.down( 'sm' ) ]: {
			marginLeft: -9,
			marginTop: 5
		}
	},
	actions_and_filters: {
		padding: 10
	}
} ) )

export function FormikProductAutoCompleteDropdown( { name, label, formik_props, onSelectProduct, ...props } ) {
	const [ open, setOpen ] = useState( false )
	const [ adding_new, setAddNew ] = useState( false )
	const classes = useStyles()
	const dispatch = useDispatch()
	const { field, meta } = formik_props
	const touched = get( meta, "touched" )
	const errors = get( meta, "error" )
	const value = get( formik_props.values, name )
	const is_loading = useSelector( () => adminProductAutoCompleteList.isLoading() )
	const products = useSelector( () => adminProductAutoCompleteList.getVisibleObjects() )

	useEffect( () => {
		dispatch( adminProductAutoCompleteList.fetchListIfNeeded() )
	}, [] )

	useEffect( () => {
		if ( value ) {
			dispatch( adminProductAutoCompleteList.ensureObjectLoaded( value ) )
		}
	}, [ value ] )

	const renderSelectProductButton = ( item ) => {
		return (
			<Button
				onClick={ () => onSelectItem( { id: item.id } ) }
				variant="outlined"
				style={ { color: "#c2185b", borderColor: "#c2185b" } }
			>
				ADD TO PARCEL
			</Button>
		)
	}

	const columns = [
		{
			field: 'product_category_name',
			title: 'Product',
			visible: true,
			render: ( item ) => <Link onClick={ () => onSelectItem( { id: item.id } ) }><Typography>{ startCase( get( item, "product_category_crumbs", [ 0 ] )[ 0 ] ) }</Typography></Link>
		},
		{
			field: 'old_product_name',
			title: 'Old Product Name',
			visible: false,
			render: ( item ) => <Typography onClick={ () => onSelectItem( { id: item.id } ) }>{ item.name }</Typography>
		},
		{
			field: 'category',
			title: 'Categories',
			visible: false,
			render: ( item ) => <HierarchyCrumbs crumbs={ tail( get( item, "product_category_crumbs", [] ) ) } />
		},
		{
			field: 'weight_kg',
			title: 'Weight',
			visible: true,
		},
		{
			field: 'select_product',
			title: 'Select',
			width: '180',
			visible: true,
			render: ( item ) => renderSelectProductButton( item )
		}
	]

	const mobile_columns = [
		{
			field: 'name',
			title: 'Product',
			visible: true,
			render: ( item ) => <Typography onClick={ () => onSelectItem( { id: item.id } ) }>{ startCase( get( item, "product_category_crumbs", [ 0 ] )[ 0 ] ) }</Typography>
		},
		{
			field: 'name',
			title: 'Old Product Product',
			visible: false,
			render: ( item ) => <a onClick={ () => onSelectItem( { id: item.id } ) }>{ item.name }</a>
		},
		{
			field: 'category', title: 'Categories',
			render: ( item ) => <div style={ { float: "right" } }>
				<HierarchyCrumbs
					crumbs={ tail( get( item, "product_category_crumbs", [] ) ) }
					top_level
					font_size="caption"
					in_chip
				/>
			</div>
		},
		{
			field: 'weight_kg',
			title: 'Weight',
			visible: true,
			render: ( item ) => <div style={ { float: "right", clear: "both", marginTop: 5 } }>
				<Chip label={ `${ item.weight_kg }kg` } size="small" />
			</div>
		},
		{
			field: '',
			title: '',
			width: '100',
			visible: true,
			render: ( item ) => <div style={ { marginTop: 7 } }>{ renderSelectProductButton( item ) }</div>
		}
	]

	const onClose = () => {
		setOpen( false )
	}

	const onOpen = () => {
		setOpen( true )
	}

	const onSelectItem = ( { id } ) => {
		formik_props.setFieldValue( name, id )
		onClose()
	}

	const onFilterChanged = ( values, formik_props ) => {
		dispatch( adminProductAutoCompleteList.updateListFilter( values ) )
		dispatch( adminProductAutoCompleteList.fetchListIfNeeded() )
	}

	const renderFilter = () => {
		return (
			<Formik
				initialValues={ {} }
				onSubmit={ onFilterChanged }
				enableReinitialize={ true }
			>
				{ formik_props => {
					const { values } = formik_props
					return (
						<Form>
							<FormikOnChange onChange={ ( values ) => onFilterChanged( values, formik_props ) } />
							<FormikGeneralFormErrors render={ ( msg ) => msg } />

							<Grid container justifyContent="space-between" className={ classes.actions_and_filters }>

								<Grid item xs={ 12 }>
									<AddButton onClick={ () => setAddNew( true ) } label="Add New Product" color="secondary" />
								</Grid>

								<Grid item xs={ 12 }>
									<ProductCategorySelectField
										name="product_category"
										can_add={ false }
										can_edit={ false }
										formik_props={ formik_props }
									/>
								</Grid>

								<Grid item xs={ 12 }>
									<div className={ classes.search_bar }>
										<SearchBar formik_props={ formik_props } />
									</div>
								</Grid>

							</Grid>
						</Form>
					)
				} }
			</Formik>
		)
	}

	const onSaveNewProduct = ( values, formik_funcs ) => {

		values.name = values.product_category_name
		const { setErrors } = formik_funcs

		const on_ok = function( json ) {
			dispatch( adminProductAutoCompleteList.invalidateList() )
			dispatch( adminProductAutoCompleteList.fetchListIfNeeded() )
			showSuccess( "Saved", "New product Saved" )
			const product_id = json.id
			setAddNew( false )
			onSelectItem( { id: product_id } )
		}

		return dispatch( adminProductAutoCompleteList.saveNewObject( values ) )
			.then( ( res ) => handleSubmitResult( { res, formik_funcs, on_ok } ) )
			.catch( ( error ) => {
				if ( error.response && error.response.status === 400 ) {
					const errorMessage = error.response.data.error
					setErrors( { non_field_errors: errorMessage } )  // Set the Formik error state
				}
			}
		)
	}

	const renderAddNewProduct = () => {
		return (
			<CommonInlineAddNewFormLayout
				is_loading={ false }
				title={ "Add Product" }
				initial_values={ {} }
				validationSchema={ validationSchema }
				onCancel={ () => setAddNew( false ) }
				onSave={ ( values, formik_funcs ) => onSaveNewProduct( values, formik_funcs ) }
			>
				{
					( { formik_props } ) => <AdminProductForm formik_props={ formik_props } inDrawer={ true } />
				}
			</CommonInlineAddNewFormLayout>
		)
	}

	const renderTreeSelector = () => {
		return (
			<Modal
				onClose={ onClose }
				fullWidth={ true }
				maxWidth='lg'
				title="Select a product"
				noPadding
			>
				<div className={ classes.treeContainer }>
					{ renderFilter() }
					{ adding_new && renderAddNewProduct() }
					<Hidden smDown>
						<CommonTable
							is_loading={ is_loading }
							rows={ products }
							canDelete={ false }
							columns={ columns }
							item_list={ adminProductAutoCompleteList }
							useColumnsSelector={ true }
							storeTableName={ "adminProductAutoCompleteList" }
						/>
					</Hidden>
					<Hidden mdUp>
						<CommonTable
							is_loading={ is_loading }
							rows={ products }
							canDelete={ false }
							columns={ mobile_columns }
							item_list={ adminProductAutoCompleteList }
							mobile
						/>
					</Hidden>

				</div>
			</Modal>
		)
	}

	const renderClosed = () => {
		const selected_product = value && adminProductAutoCompleteList.getObject( value )
		if ( selected_product ) {
			onSelectProduct( get( selected_product, "weight_kg", "" ) )
		}

		return (
			<>
				<TextField
					value={ get( selected_product, "name", "" ) }
					name={ name }
					label={ label }
					id={ name }
					helperText={ touched ? get( formik_props, [ "errors", name ] ) : "" }
					error={ touched && Boolean( get( formik_props, [ "errors", name ] ) ) }
					margin="normal"
					variant="outlined"
					fullWidth
					loading={ is_loading }
					onFocus={ onOpen }
				/>
				{ selected_product &&
					<Typography variant="caption">
						Weight: { get( selected_product, "weight_kg", "" ) }kg/item
					</Typography>
				}
			</>
		)
	}

	return (
		<div>
			{ open && renderTreeSelector() }
			{ !open && renderClosed() }
		</div>
	)

}
