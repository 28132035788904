import { adminFoodItemAutoCompleteList } from '../actions/admin_food_item'
import { FormikAutoCompleteDropdown } from 'components/form/AutoCompleteDropdown'

export function FormikNutritionFoodItemAutoCompleteDropdown({name, label, formik_props, ...props}) {

    return (
      <FormikAutoCompleteDropdown
        item_list={adminFoodItemAutoCompleteList}
        name={name}
        label={label}
        formik_props={formik_props}
      />
    )
}
