import { ItemList } from 'orm'
import { map } from 'lodash'

class KpiDashboardCategoryList extends ItemList {
    getEntityKey() {
        return "admin/kpi_dashboard_category"
    }

    getAsOptions() {
        const items = this.getVisibleObjects()
        return map(items, function(item) {
            return { label: item.name, value: item.id }
        })
    }
}

export const adminKpiDashboardCategoryList = new KpiDashboardCategoryList("admin_kpi_dashboard_category__default")
