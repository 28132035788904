import React from 'react'
import { get } from 'lodash'
import {
  makeStyles,
  Typography,
  Table,
  TableRow,
  TableCell,
} from '@material-ui/core'
import Alert from '@material-ui/lab/Alert'
import { Separator } from 'components/layout/Separator'
import { SingleFileUploadField } from 'components/form/SingleFileUploadField'
import { FormikInputField } from 'components/form/InputField'
import { FormikCheckboxField } from 'components/form/CheckboxField'

const useStyles = makeStyles((theme) => ({
  infoBox: {
    border: '1px solid #cccccc',
    borderRadius: 8,
    padding: 15,
    marginBottom: 15
  },
  alertMargin: {
    marginBottom: 10
  },
  alert: {
    marginBottom: 10
  },
}))

export const Section18ASection = ({parcel, formik_props, section18AReport}) => {
    var classes = useStyles()

    return (
        <div className={classes.infoBox} style={{background: '#ffffff'}}>

        { get(parcel, "qualifies_for_section18_certificate") &&
          <div>
            <Alert className={classes.alertMargin}>
              This parcel qualifies for a section18A Tax exemption certificate.
            </Alert>
            <Alert severity="info" className={classes.alertMargin}>
              It will be automatically generated and emailed to the company when you save the order.
            </Alert>


            { ! section18AReport &&
              <Alert severity="info" className={classes.alertMargin}>If section18A report generation is enabled for this customer, then the report will be available soon, please wait a few minutes then refresh this page or go to the reports tab.</Alert>
            }
            { section18AReport &&
              <div>
                  <Separator height={15} />
                  <Typography><b>Section18A Report</b></Typography>
                  <Separator height={5} />
                  <Table>
                    <TableRow>
                      <TableCell>Status:</TableCell>
                      <TableCell align="left">
                        {section18AReport.status == "success" ? <span style={{color: 'green'}}>{section18AReport.status}</span> : <span style={{color: 'red'}}>{section18AReport.status}</span> }
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>Emailed to:</TableCell>
                      <TableCell>{section18AReport.emailed_to}</TableCell>
                    </TableRow>

                    { section18AReport.report_file_info &&
                      <TableRow>
                        <TableCell>
                          Download
                        </TableCell>
                        <TableCell>
                          <a href={section18AReport.report_file_info.original_download_url} download={section18AReport.report_file_info.original_filename}>
                            {section18AReport.report_file_info.original_filename}
                          </a>
                        </TableCell>
                      </TableRow>
                    }
                </Table>
              </div>
            }
          </div>
        }
        <SingleFileUploadField name="company_invoice_file_info"
                               dropzonePlaceholder = "Upload the company invoice for this order"
                               buttonPlaceholder = "company invoice for this order"
                               formik_props={formik_props}
        />
        <Separator height={15} />
        { get(formik_props.values, ["company_invoice_file_info", "id"]) &&
          <>
              <FormikCheckboxField name="company_invoice_is_valid"
                                   label={formik_props.values.company_invoice_is_valid == true ? "Unconfirm information is valid?" : "By checking this box I verify that the company invoice is correct and matches the products in this order"}
                                   formik_props={formik_props}
              />
              {formik_props.values.company_invoice_is_valid &&
                <Alert severity="success" className={classes.alertMargin}>
                  It has been confirmed that the company invoice is correct and matches the products in this order
                </Alert>
              }
          </>
        }
      </div>
    )
}

export const TotalOverrides = ({formik_props}) => {
    var classes = useStyles()

    return (
        <div className={classes.infoBox}>
          <Typography> Value: <b>R {formik_props.values.total_value_rands}</b></Typography>

          <FormikCheckboxField name="override_total_value_rands"
                               label="Override Total Value (R)?"
                               formik_props={formik_props}
          /><br />

        {formik_props.values.override_total_value_rands == true &&

          <FormikInputField name="total_value_rands"
                            label="Total value rands?"
                            formik_props={formik_props}
                            helperText="Only saved if override is ticked"
          />
        }

          <Typography> Weight: <b>{formik_props.values.total_weight_kg} kg</b></Typography>
          <FormikCheckboxField name="override_total_weight_kg"
                               label="Override Total Weight (kg)?"
                               formik_props={formik_props}
          /><br />
        {formik_props.values.override_total_weight_kg == true &&
          <FormikInputField name="total_weight_kg"
                            label="Total weight kg"
                            formik_props={formik_props}
                            helperText="Only saved if override is ticked"
          />
      }
    </div>
    )

}
