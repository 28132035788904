import React, { Component } from 'react'
import { connect } from 'react-redux'

import { head, get } from 'lodash'
import { Trans, Translation } from 'react-i18next'
import { FormikDropdownField } from 'components/form/Dropdown'
import AdminCommonListLayout from 'admin/components/layout/AdminCommonListLayout'
import Timestamp from 'components/Timestamp'
import { FormikInputField } from 'components/form/InputField'
import { adminStartingValueList } from './actions/admin_starting_value'
import { Separator } from 'components/layout/Separator'
import { ShortId } from 'components/ShortId'
import { Button } from 'components/layout/Button'
import { provinceList } from 'actions/province'

class AdminStartingValues extends Component {

    componentDidMount() {
        const { dispatch } = this.props
        dispatch(adminStartingValueList.fetchListIfNeeded())
        dispatch(provinceList.updatePaginationNumItemsPerPage(1000))
        dispatch(provinceList.fetchListIfNeeded())
    }

    componentDidUpdate() {
        const { dispatch, filter } = this.props
        dispatch(adminStartingValueList.fetchListIfNeeded())
        dispatch(provinceList.fetchListIfNeeded())
    }

    onEditStartingValue = (starting_value_id) => {
        const { history } = this.props
        history.push({
            pathname: '/admin/starting_value/'+starting_value_id
        })
    }

    onAddStartingValue = () => {
        const { history } = this.props
        history.push('/admin/starting_value')
    }

    onUpdateListOrdering = (field) => {
      const { dispatch } = this.props
      dispatch(adminStartingValueList.updateListOrdering(field))
    }

    render() {
        const { is_loading, cities } = this.props
        const that = this

        const columns = [
            { field: 'city_name', title: 'City',
              sort: (direction) => this.onUpdateListOrdering(direction === 'asc' ? 'city__name' : '-city__name')
            },
            { field: 'num_meals', title: 'Meals'},
            { field: 'num_orders', title: 'Number of orders'},
            { field: 'weight_kg', title: 'Weight (kg)'}
        ]

        return (
            <AdminCommonListLayout active_key="cities"
                                   breadcrumbs={[{name: 'admin_home'},
                                                 {name: 'starting_values', label: "Starting values", url: '/admin/starting_values'}]}
                                   add_label="Add Starting Value"
                                   onAddRow={that.onAddStartingValue}
                                   onEditRow={that.onEditStartingValue}
                                   is_loading={is_loading}
                                   columns={columns}
                                   item_list={adminStartingValueList}
            />
        )
    }
}

function mapStateToProps(state, props) {
    const cities = adminStartingValueList.getVisibleObjects()

    return {
        cities,
        is_loading: adminStartingValueList.isLoading(),
        filter: adminStartingValueList.getFilter()
    }
}

export default connect(mapStateToProps)(AdminStartingValues)
