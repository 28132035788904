import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { head, get } from 'lodash'
import { Trans, Translation } from 'react-i18next'
import AdminCommonListLayout from '../layout/AdminCommonListLayout'
import Timestamp from 'components/Timestamp'
import { FormikInputField } from 'components/form/InputField'
import { adminWarehouseList } from './actions/admin_warehouse'
import { Separator } from 'components/layout/Separator'
import { ShortId } from 'components/ShortId'

export default function Warehouses() {

  const dispatch = useDispatch()
  const history = useHistory()
  const warehouses = useSelector(() => adminWarehouseList.getVisibleObjects())
  const is_loading = useSelector(() => adminWarehouseList.isLoading())
  const filter = useSelector(() => adminWarehouseList.getFilter())

  useEffect(() => {
    dispatch(adminWarehouseList.fetchListIfNeeded())
  }, [])

  const onEditWarehouse = (warehouse_id) => {
    history.push({
      pathname: '/admin/warehouse/' + warehouse_id
    })
  }

  const onAddWarehouse = () => {
    history.push('/admin/warehouse')
  }

  const onUpdateListOrdering = (field) => {
    dispatch(adminWarehouseList.updateListOrdering(field))
  }

  const columns = [
    // { field: 'id',
    //   title: 'ID',
    //   width: 120,
    //   render: (item) => <ShortId value={item.id} />,
    //   sort: (direction) => this.onUpdateListOrdering(direction === 'asc' ? 'id' : '-id'),
    // },
    {
      field: 'city',
      title: 'City',
      render: (item) => get(item, ["address", "city_name"]),
      sort: (direction) => onUpdateListOrdering(direction === 'asc' ? 'address__city__name' : '-address__city__name')
    },
    {
      field: 'name',
      title: 'Warehouse',
      render: (item) => get(item, ["name"]),
      sort: (direction) => onUpdateListOrdering(direction === 'asc' ? 'name' : '-name'),
    },

    // { field: 'current_inventory_weight_kg',
    // title: 'Current Inventory (kg)',
    // render: (item) => get(item, ["current_inventory_weight_kg"]) ? get(item, ["current_inventory_weight_kg"]) : 'Coming soon',
    // sort: (direction) => this.onUpdateListOrdering(direction === 'asc' ? 'current_inventory_weight_kg' : '-current_inventory_weight_kg')
    // },
  ]

  return (
    <AdminCommonListLayout active_key="warehouses"
      breadcrumbs={[{ name: 'admin_home' },
      { name: 'warehouses', label: "Warehouses", url: '/admin/warehouses' }]}
      title={"Warehouses"}
      add_label="Add Warehouse"
      onAddRow={onAddWarehouse}
      onEditRow={onEditWarehouse}
      is_loading={is_loading}
      columns={columns}
      item_list={adminWarehouseList}
    />
  )
}
