import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import AdminCommonListLayout from '../layout/AdminCommonListLayout'
import AdminCommonListTable from '../layout/AdminCommonListTable'
import { adminMealtimeList } from './actions/admin_mealtime'
import { get, capitalize } from 'lodash'
import { formatTime } from 'utils/formatDateTime'
import AdminMealtime from './AdminMealtime'
import { Modal } from 'components/layout/Modal'
import { Separator } from 'components/layout/Separator'

export default function AdminMealtimes( { inTabs = false, company_id } ) {

  const dispatch = useDispatch()
  const history = useHistory()

  const mealtimes = useSelector( () => adminMealtimeList.getVisibleObjects() )
  const is_loading = useSelector( () => adminMealtimeList.isLoading() )
  const as_table = inTabs ? true : false
  const [ openModal, setOpenModal ] = useState( false )
  const [ editingMealtimeId, setEditingMealtimeId ] = useState( null )

  const openEditModal = ( mealtime_id ) => {
    setEditingMealtimeId( mealtime_id )
    setOpenModal( true )
  }

  const closeEditModal = () => {
    setEditingMealtimeId( null )
    dispatch( adminMealtimeList.invalidateList() )
    dispatch( adminMealtimeList.fetchListIfNeeded() )
    setOpenModal( false )
  }

  const onEditMealtime = ( mealtime_id ) => {
    openEditModal( mealtime_id )
  }

  const onAddMealtime = () => {
    openEditModal( null ) // pass null to indicate we're creating a new demographic
  }

  useEffect( () => {
    if ( company_id ) {
      dispatch( adminMealtimeList.updateListFilter( { company: company_id } ) )
    }
    dispatch( adminMealtimeList.fetchListIfNeeded() )
  }, [] )

  const columns = []

  if ( !company_id ) {
    columns.push(
      {
        field: 'company_name',
        title: 'Company',
        sort: ( direction ) => onUpdateListOrdering( direction === 'asc' ? 'company__name' : '-company__name' ),
      }
    )
  }

  columns.push(
    {
      field: 'meal_name',
      title: 'Meal',
      render: ( item ) => capitalize( get( item, 'meal_name', '' ) ),
      sort: ( direction ) => onUpdateListOrdering( direction === 'asc' ? 'meal_name' : '-meal_name' ),
    },
    {
      field: 'time',
      title: 'Time',
      render: ( item ) => formatTime( get( item, 'time', '' ) ),
      sort: ( direction ) => onUpdateListOrdering( direction === 'asc' ? 'time' : '-time' )
    },
    {
      field: 'number_of_recipients',
      title: '# Recipients',
      sort: ( direction ) => onUpdateListOrdering( direction === 'asc' ? 'number_of_recipients' : '-number_of_recipients' )
    },
  )

  const onUpdateListOrdering = ( field ) => {
    dispatch( adminMealtimeList.updateListOrdering( field ) )
  }

  const renderModal = () => {
    return (
      <Modal title="Add/Edit Mealtime" onClose={ closeEditModal }>
        <Separator height={ 15 } />
        <AdminMealtime in_modal={ true } onClose={ closeEditModal } passed_mealtime_id={ editingMealtimeId } company_id={ company_id } />
      </Modal>
    )
  }

  if ( inTabs ) {
    return (
      <>
        <AdminCommonListTable
          add_label="Add"
          title="Mealtimes"
          onAddRow={ onAddMealtime }
          onEditRow={ onEditMealtime }
          is_loading={ is_loading }
          handleRequestSort={ columns }
          columns={ columns }
          item_list={ adminMealtimeList }
          inTabs
        />
        { openModal && renderModal() }
      </>
    )
  } else {
    return (
      <AdminCommonListLayout
        active_key="mealtimes"
        breadcrumbs={ [
          { name: 'admin_home' },
          { name: 'mealtimes', label: "Mealtimes", url: '/admin/mealtimes' }
        ] }
        add_label="Add Mealtime"
        title={ "Mealtimes" }
        onAddRow={ onAddMealtime }
        onEditRow={ onEditMealtime }
        is_loading={ is_loading }
        columns={ columns }
        item_list={ adminMealtimeList }
        enableAnyFieldFilter={ true }
      >
        { openModal && renderModal() }
      </AdminCommonListLayout>
    )
  }
}
