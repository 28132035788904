import { ItemList } from 'orm'
import { map } from 'lodash'

class AdminProductList extends ItemList {
	getEntityKey() {
		return "admin/product"
	}

	getAsOptions() {
		const items = this.getVisibleObjects()
		let res = map( items, function( item ) {
			return {
				label: `${ item.weight_kg }kg ${ item.uom_name ? item.uom_name : '' }`,
				value: item.id,
				original_item: item
			}
		} )
		return res
	}

}

export const adminProductList = new AdminProductList( "admin_product__default" )
export const adminProductAutoCompleteList = new AdminProductList( "admin_product_auto_complete__default" )

