import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { union, map } from 'lodash'
import { FormikDropdownField } from 'components/form/Dropdown'
import CommonInlineAddNewFormLayout from 'components/layout/CommonInlineAddNewFormLayout'
import AdminAddressForm from './AdminAddressForm'
import { getAddressOptions } from 'admin/components/layout/InlineAddress'
import { validationSchema } from './AdminAddressForm'
import { adminAddressForDropdownList, invalidateAllAddressLists } from '../actions/admin_address'

const ADD_NEW_VALUE = '__add_new__'

export function FormikAddressDropdownWithFormBasedAddNew( { name, label, newAddressInitialValues, address_ids, onSave, formik_props } ) {

	const addresses = useSelector( () => adminAddressForDropdownList.getObjects( address_ids ) )
	const [ fullOptions, setFullOptions ] = useState( [] )
	const [ adding_new, setAddNew ] = useState( false )
	const dispatch = useDispatch()

	useEffect( () => {
		dispatch( adminAddressForDropdownList.ensureObjectsLoaded( address_ids ) )
	}, [ address_ids ] )

	useEffect( () => {
		const address_options = getAddressOptions( addresses )
		setFullOptions( union( address_options, [ { value: ADD_NEW_VALUE, label: '+ New Address' } ] ) )
	}, [ JSON.stringify( map( addresses, ( address ) => address.loaded ) ) ] )

	const localOnChange = ( value ) => {
		if ( value === ADD_NEW_VALUE ) {
			setAddNew( true )
		} else {
			formik_props.setFieldValue( name, value )
		}
	}

	const localOnSave = ( values, formik_funcs ) => {
		onSave( values, formik_funcs, onAdded )
		dispatch( invalidateAllAddressLists() )
	}

	const onAdded = ( value ) => {
		localOnChange( value )
		setAddNew( false )
	}

	const onAddNewCancelled = () => {
		setAddNew( false )
		formik_props.setFieldValue( name, null )
	}

	const renderAddNew = () => {
		return (
			<CommonInlineAddNewFormLayout
				is_loading={ false }
				title={ "Add Address" }
				initial_values={ {} }
				validationSchema={ validationSchema }
				onCancel={ onAddNewCancelled }
				onSave={ localOnSave }
			>
				{
					( { formik_props } ) =>
						<AdminAddressForm
							formik_props={ formik_props }
							newAddressInitialValues={ newAddressInitialValues }
							fullWidth
						/>
				}
			</CommonInlineAddNewFormLayout>
		)
	}

	return (
		<>
			{ adding_new && renderAddNew() }
			<FormikDropdownField
				name={ name }
				label={ label }
				options={ fullOptions }
				onChange={ localOnChange }
				formik_props={ formik_props }
			/>
		</>
	)
}
