import React, { useState } from 'react'
import { get, map, startCase, toLower, replace, add } from 'lodash'
import { FormikInputField } from 'components/form/InputField'
import { FieldArray } from "formik";
import { Table, TableRow, TableCell, makeStyles, Typography, Grid, InputAdornment, Card, CardContent } from '@material-ui/core'
import { LoadingModal } from 'components/layout/Modal'

const useStyles = makeStyles((theme) => ({
  input: {
    width: 150,
    marginRight: 10,
    [theme.breakpoints.down('sm')]: {
      width: '45%',
      marginTop: 10
    },
  },
  cellHeading: {
    background: '#fafafa',
    fontWeight: 500,
    minWidth: 170
  },
  raceHeading: {
    marginTop: 30
  },
  grandTotal: {
    fontSize: 24
  },
  blackCard: {
    background: 'black',
    color: 'white'
  },
  colouredCard: {
    background: '#9c27b0',
    color: 'white'
  },
  indian_asianCard: {
    background: '#827717',
    color: 'white'
  },
  metricsInCard: {
    background: 'white',
    color: 'black'
  },
  percentTotalRace: {
    textAlign: 'center'
  },
  center: {
    textAlign: 'center'
  },
  metricHeading: {
    background: '#efefef'
  }
}))

export const BeneficiaryRaceAgeSexGrid = ({formik_props}) => {
  const classes = useStyles()
  const age_groups = ['under_5', '5_to_18', '19_to_35', '36_to_60', 'over_60', 'all']
  const races = ['black', 'coloured', 'white', 'indian_asian']
  const sexes = ['male', 'female']
  const [state, setState] = useState({
    age_group: null,
    race: null,
    sex: null
  })

  const [isLoading, setIsLoading] = useState(false)

  const setTotals = (el, age_group, race, sex) => {
    setState({age_group: age_group, race: race, sex: sex})
    // age_group Total
    let age_group_total = add(el.currentTarget.value, get(formik_props, ['values', age_group, race, 'female']))
    formik_props.setFieldValue(age_group + '.' + race + '.all', age_group_total)
  }

  const renderInputField = (age_group, race, sex, endAdornment, disabled) => {
    let name = ""
    name =  age_group ? age_group + '.' : 'all.'
    name += race ? race + '.' : 'all.'
    name += sex ? sex : 'all'
    return (
      <FormikInputField formik_props={formik_props}
                        name={name}
                        size="small"
                        disabled={disabled}
                        onChange={(el) => setTotals(el, age_group, race, sex)}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment>
                              { endAdornment }
                            </InputAdornment>
                          )
                        }}
      />
    )
  }

  const renderAgeInputs = (age_group, race) => {
    if (!race) { race = 'all' }
    if (!age_group) { age_group = 'all' }
    return (
      <>
      {map(sexes, function(sex) {
        return (
          <TableCell>
            { renderInputField(age_group, race, sex) }
          </TableCell>
        )
      })}
      <TableCell>
        { renderInputField(age_group, race, 'all') }
      </TableCell>
      </>
    )
  }

  const getTitleCase = (item, replaceTerm, replaceWith) => {
    item = startCase(toLower(replace(item,/_/g," ")))
    if(replaceTerm) {
      item = replace(item, replaceTerm, replaceWith)
    }
    return item
  }

  const getLowerCase = (item, replaceTerm, replaceWith) => {
    item = toLower(replace(item,/_/g," "))
    if(replaceTerm) {
      item = replace(item, replaceTerm, replaceWith)
    }
    return item
  }

  const renderRaceMetricCards = () => {
    return (
      <>
      {map(races, function(race) {
          return(
            <Grid item xs={6} lg={3}>
            <Card className={classes[race + 'Card'] }>
              <CardContent>
                <Typography gutterBottom variant="h5" component="div" align="center">
                  { getTitleCase(race) }
                </Typography>
                <Typography variant="h3" component="div" align="center">
                  190
                </Typography>
                <Typography  className={classes.percentTotalRace}>
                  25%
                </Typography>
              </CardContent>
            </Card>
            </Grid>
          )
        })}
      </>
    )
  }

  return(
    <Grid container spacing={2}>

      <Grid item xs={12}>
        <FormikInputField name="reported_number_of_people_served"
                         type="number"
                         label="Number of People Served"
                         formik_props={formik_props}
                         fullWidth={false}
                         style={{marginRight: 10}}
        />
      </Grid>

      <Grid container spacing={2}>

      {renderRaceMetricCards()}

      </Grid>

      <Grid item xs={12} >
        <>
        { isLoading && <LoadingModal /> }
        <FieldArray
          name="age_race_sex"
          render={arrayHelpers => (
            <>
              {map(races, function(race) {
                  return (
                    <>
                    <Typography variant="h6" className={classes.raceHeading}>{ getTitleCase(race) }</Typography>
                    <Table size='small'>

                      <TableRow>
                          <TableCell></TableCell>
                          {map(sexes, function(sex) {
                              return(
                                  <TableCell className={classes.cellHeading}>
                                      { getTitleCase(race) } { getTitleCase(sex) }
                                  </TableCell>
                              )
                            })}
                            <TableCell className={classes.cellHeading}>
                                { getTitleCase(race) } Total
                            </TableCell>
                      </TableRow>

                      {map(age_groups, function(age_group) {
                        return(
                          <TableRow>

                            <TableCell className={classes.cellHeading} style={{textAlign: 'center'}}>
                                 { age_group == 'all' ? `${getLowerCase(age_group)} ages` : `${getLowerCase(age_group, ' to ', '-')} years old` }
                            </TableCell>
                            { renderAgeInputs(age_group, race) }
                          </TableRow>
                        )
                      })}

                    </Table>
                    </>
                  )
                }
              )
            }
            </>
          )}
        />
        </>
      </Grid>

    </Grid>

  )

}
