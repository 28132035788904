import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useParams } from 'react-router-dom'
import { get } from 'lodash'
import { adminBeneficiaryList } from './actions/admin_beneficiary'
import AdminCommonFormLayout from '../layout/AdminCommonFormLayout'
import { AdminBeneficiaryStateHistory } from './AdminBeneficiaryStateHistory'
import { showSuccess, showError } from 'actions/Error'
import { adminBeneficiaryStates } from 'admin/actions/admin_dropdown_options'
import AdminCommonCompany from '../companies/AdminCommonCompany'
import { validationSchema } from './form/AdminBeneficiaryForm'
import { handleSubmitResult } from 'actions/form'

export default function AdminBeneficiary() {

    const history = useHistory()
    const dispatch = useDispatch()
    const { beneficiary_id } = useParams()
    const beneficiary = useSelector(() => adminBeneficiaryList.getObject(beneficiary_id))
    const beneficiary_state_options = useSelector(() => adminBeneficiaryStates.getAsOptions())
    const is_loading = useSelector(() => adminBeneficiaryList.isLoading()) || (beneficiary_id && !get(beneficiary, 'id'))
    const is_busy = useSelector(() => adminBeneficiaryList.getIsSavingObject())
    const title = `${get(beneficiary, ["company", "name"], 'New beneficiary')}`
    const initial_values = Object.assign({}, { company: {core_businesses: [], tags: []} }, beneficiary)

    useEffect(() => {
        dispatch(adminBeneficiaryList.ensureObjectLoaded(beneficiary_id))
        dispatch(adminBeneficiaryStates.fetchListIfNeeded())
        dispatch(adminBeneficiaryList.ensureObjectLoaded(beneficiary_id))
    }, [])

    const onSave = (values, formik_funcs) => {
        const on_ok = function(json) {
            dispatch(adminBeneficiaryList.invalidateList())
            showSuccess("Saved", "Beneficiary saved")

            if (! beneficiary_id) {
                history.push(`/admin/beneficiary/${json.id}`)
            }
        }
        if ( beneficiary_id ) {
            values.id = beneficiary_id
            return dispatch(adminBeneficiaryList.saveObject(values)).then((res) => handleSubmitResult({ res, formik_funcs, on_ok }))
        } else {
            return dispatch(adminBeneficiaryList.saveNewObject(values)).then((res) => handleSubmitResult({ res, formik_funcs, on_ok }))
        }
    }

    return (

        <AdminCommonFormLayout
            breadcrumbs={[
                { name: 'admin_home' },
                { name: 'beneficiaries', label: 'Beneficiaries', url: '/admin/beneficiaries' },
                { name: 'beneficiary', label: get(beneficiary, ["company", "name"], 'New Beneficiary'), url: `/admin/beneficiary/${beneficiary_id}` }
            ]}
            is_busy={is_busy}
            is_loading={is_loading}
            title={title}
            initial_values={initial_values}
            validationSchema={validationSchema}
            onSave={onSave}>

            {
                ({formik_props}) =>
                <AdminCommonCompany
                    company_id={ get(beneficiary, ["company", "id"]) }
                    formik_props={ formik_props }
                    form_variation="beneficiary"
                    is_loading={ is_loading }
                    state_history={ <AdminBeneficiaryStateHistory beneficiary_id={ beneficiary_id } /> }
                />
            }
        </AdminCommonFormLayout>

    )
}
