import { ItemList } from 'orm'
import { map } from 'lodash'

class ContactCompanyProductList extends ItemList {
    getEntityKey() {
        return "contact/company_product"
    }

}

export const contactCompanyProductList = new ContactCompanyProductList("contact_company_product__default")

class ContactDonorProductCheckProductsFile extends ItemList {
    getEntityKey() {
        return "contact/company_product/check_products_file"
    }

    checkFile = ({file_info}) => {
        return this.saveNewObject({products_file_info: file_info})
    }

}

export const contactDonorProductCheckProductsFile = new ContactDonorProductCheckProductsFile("contact_donor_product_check_products_file__default")

