import React, { useEffect } from 'react'
import * as Yup from 'yup'
import { useDispatch, useSelector } from 'react-redux'
import { FormikInputField } from 'components/form/InputField'
import { FormikDropdownField } from 'components/form/Dropdown'
import { FormikTimePicker } from 'components/form/DatetimePicker'
import { FormControlLabel, Grid } from '@material-ui/core'
import { adminMealtimes } from '../../../actions/admin_dropdown_options'
import { adminBeneficiaryList } from '../../beneficiaries/actions/admin_beneficiary'
import { FormikAutoCompleteDropdown } from 'components/form/AutoCompleteDropdown'

export const validationSchema = Yup.object().shape({
  'company': Yup.string().required("Company is required"),
  'meal_name': Yup.string().required("Meal is required"),
  'time': Yup.string().required("Time is required"),
  'number_of_recipients': Yup.string().required("Number of recipients is required"),
})

export const AdminMealtimeForm = ({ formik_props, company_id, in_modal }) => {

  const dispatch = useDispatch()
  const beneficiaries =  useSelector(() => adminBeneficiaryList.getAsOptions())
  const meal_options =  useSelector(() => adminMealtimes.getAsOptions())

  useEffect(() => {
    dispatch(adminBeneficiaryList.fetchListIfNeeded())
    dispatch(adminMealtimes.fetchListIfNeeded())
},[])

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} lg={in_modal ? 12 : 6}>
        { !company_id &&
        <FormikAutoCompleteDropdown
          item_list={adminBeneficiaryList}
          name="company"
          label="Beneficiary"
          formik_props={formik_props}
        />
        }
        <FormikDropdownField
          name="meal_name"
          label="Meal"
          formik_props={formik_props}
          options={meal_options}
        />
        <FormikTimePicker
          name="time"
          label="Time"
          formik_props={formik_props}
        />
        <FormikInputField
          name="number_of_recipients"
          label="Number of Recipients"
          formik_props={formik_props}
        />
        
      </Grid>
    </Grid>
  )
}

