import { filter,sumBy } from 'lodash'

export const getCartTotalWeightKg = (cartItems) => {
  const filtered_cart_items = filter(
    cartItems, function(c) {
       return !isNaN(c.warehouse_product.product_weight_kg)
    }
  )
  const cart_total_weight_kg = sumBy(filtered_cart_items, function(c) { return c.quantity * c.warehouse_product.product_weight_kg })
  return cart_total_weight_kg
}
