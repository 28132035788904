import { ItemList } from 'orm'
import { map } from 'lodash'

class NotificationList extends ItemList {
    getEntityKey() {
        return "admin/notification"
    }

    getAsOptions() {
        const items = this.getVisibleObjects()
        return map(items, function(item) {
          return { label: item.name, value: item.id }
        }
      )
    }
}

export const adminNotificationList = new NotificationList("admin_notification__default")
