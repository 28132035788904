import { ItemList } from 'orm'

class KpiResultComment extends ItemList {
    getEntityKey() {
        return "admin/kpi_result_comment"
    }

}

export const adminKpiResultCommentList = new KpiResultComment("admin_kpi_result_comment__default")
