import React, { useEffect, useState, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { get, tail, isEqual, startCase } from 'lodash'
import { Modal } from 'components/layout/Modal'
import AdminCommonListLayout from 'admin/components/layout/AdminCommonListLayout'
import { WarehouseProductQuantityTypeFilter } from 'admin/components/filters/WarehouseProductQuantityTypeFilter'
import Timestamp from 'components/Timestamp'
import { ReportDownloadLink } from 'components/layout/ReportDownloadLink'
import { adminWarehouseProductList } from './actions/admin_warehouse_product'
import { HierarchyCrumbs } from 'components/layout/HierarchyCrumbs'
import IconButton from '@material-ui/core/IconButton'
import { makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import { AdminWarehouseProductForm } from './AdminWarehouseProductForm'
import { AdminWarehouseProductHistoryList } from './AdminWarehouseProductHistoryList'
import { adminWarehouseList } from '../warehouses/actions/admin_warehouse'
import NumberFormat from 'react-number-format'
import HistoryIcon from '@material-ui/icons/History'
import { Formik, Form } from 'formik'
import FormikOnChange from 'components/form/FormikAutoSave'
import { FormikDropdownField } from 'components/form/Dropdown'
import { ProductCategorySelectField } from 'admin/components/product_categories/form/ProductCategorySelectField'
import { Button, Typography } from '@material-ui/core'
import { Separator } from 'components/layout/Separator'
import { FormikDateTimePicker } from 'components/form/DatetimePicker'
import { formatDateForBackend } from 'utils/formatDateTime'
import AdminWarehouseProductsSummary from './AdminWarehouseProductsSummary'
import FilterChip from 'components/layout/FilterChip'


const useStyles = makeStyles( ( theme ) => ( {
  root: {
    flexGrow: 1,
  },
  product_modal: {
    minWidth: "50%"
  },
  negative_quantity: {
    color: "red"
  },
  positive_quantity: {
    color: "#388e3c"
  },
  downloadIcon: {
    [ theme.breakpoints.down( 'sm' ) ]: {
      position: 'absolute',
      top: -30,
      right: -120
    }
  }
} ) )

const NEW_PRODUCT_TOKEN = "__new__"

export default function AdminWarehouseProducts() {
  const classes = useStyles()
  const dispatch = useDispatch()
  const history = useHistory()
  const [ editingWarehouseProductId, setEditingWarehouseProductId ] = useState( null )
  const [ showingHistoryForWarehouseProduct, setShowHistoryForWarehouseProduct ] = useState( null )
  const is_creating_report = useSelector( () => adminWarehouseProductList.getIsSavingObject() )
  const warehouse_products = useSelector( () => adminWarehouseProductList.getVisibleObjects() )
  const warehouse_options = useSelector( () => adminWarehouseList.getAsOptions() )
  const is_loading = useSelector( () => adminWarehouseProductList.isLoading() )
  const [ filters, setFilters ] = useState( {} )
  const previousFiltersRef = useRef()
  const [ selectedProductCategory, setSelectedProductCategory ] = useState( null )

  useEffect( () => {
    async function fetchStaticObjects() {
      dispatch( adminWarehouseProductList.fetchListIfNeeded() )
      dispatch( adminWarehouseList.fetchListIfNeeded() )
    }
    fetchStaticObjects()
  }, [] )

  useEffect( () => {
    if ( !isEqual( previousFiltersRef.current, filters ) ) {
      dispatch( adminWarehouseProductList.invalidateList() )
      dispatch( adminWarehouseProductList.updateListFilter( filters ) )
      dispatch( adminWarehouseProductList.fetchListIfNeeded() )
      dispatch( adminWarehouseList.fetchListIfNeeded() )

      // Update the ref to the current filters
      previousFiltersRef.current = filters
    }
  }, [ filters, dispatch ] )

  const handleFieldClick = ( filterName, filterValue ) => {
    setFilters( prevFilters => ( {
      ...prevFilters,
      [ filterName ]: filterValue,
    } ) )
  }

  const columnsDesktop = [
    {
      field: 'product_category_name',
      title: 'Product',
      visible: true,
      render: ( item ) => <Typography style={ { cursor: 'pointer' } } onClick={ () => handleFieldClick( 'product_category', item.product_category ) }>{ get( item, "product_category_name" ) }</Typography>,
      sort: ( direction ) => onUpdateListOrdering( direction === 'asc' ? 'product_category_name' : '-product_category_name' ),
    },
    // {
    //   field: 'product_image',
    //   title: 'Image',
    //   width: 100,
    //   visible: false,
    //   render: ( item ) =>
    //     <Link to={ `/admin/product/${ get( item, "product" ) }` }>
    //       <Avatar
    //         alt={ `${ get( item, 'product_name' ) }` }
    //         title={ `${ get( item, 'product_name' ) }` }
    //         src={ get( item, [ 'product_image', 'original_download_url' ] ) }
    //         style={ { width: 100, height: 100, cursor: 'pointer' } }
    //         variant="square"
    //       >
    //         <AddPhotoAlternateIcon fontSize="large" />
    //       </Avatar>
    //     </Link>
    // },
    {
      field: 'product_name',
      title: 'Old Product Name',
      visible: false,
      render: ( item ) => <Typography>{ get( item, "product_name" ) }</Typography>,
      sort: ( direction ) => onUpdateListOrdering( direction === 'asc' ? 'product__name' : '-product__name' ),
    },
    {
      field: 'product_category_crumbs',
      title: 'Category',
      render: ( item ) => <HierarchyCrumbs crumbs={ tail( get( item, "product_category_crumbs", [] ) ) } />
    },
    {
      field: 'from_company_name',
      title: 'Donor',
      visible: true,
      render: ( item ) => <Typography variant="body2" style={ { cursor: 'pointer' } } onClick={ () => handleFieldClick( 'from_company', get( item, "from_company" ) ) } >{ get( item, "from_company_name" ) }</Typography>,
      sort: ( direction ) => onUpdateListOrdering( direction === 'asc' ? 'from_parcel_products__parcel__from_company__name' : '-from_parcel_products__parcel__from_company__name' ),
    },
    {
      field: 'warehouse_name',
      title: 'Warehouse',
      render: ( item ) => <Typography variant="body2" style={ { cursor: 'pointer' } } onClick={ () => handleFieldClick( 'warehouse', get( item, "warehouse" ) ) }>{ get( item, "warehouse_name" ) }</Typography>,
      sort: ( direction ) => onUpdateListOrdering( direction === 'asc' ? 'warehouse__name' : '-warehouse__name' ),
    },

    {
      field: 'created_at',
      title: 'Received',
      visible: true,
      render: ( item ) => <Timestamp value={ item.created_at } />,
      sort: ( direction ) => onUpdateListOrdering( direction === 'asc' ? 'donation__arrive_at' : '-donation__arrive_at' ),
    },
    {
      field: 'best_before_date',
      title: "BB",
      visible: true,
      render: ( item ) => <Typography variant="body2" style={ { cursor: 'pointer' } } onClick={ () => handleFieldClick( 'best_before_date', get( item, "best_before_date" ) ) }>{ get( item, "best_before_date" ) }</Typography>,
      sort: ( direction ) => onUpdateListOrdering( direction === 'asc' ? 'best_before_date' : '-best_before_date' ),
    },
    {
      field: 'expiry_date',
      title: "Expiry",
      visible: true,
      render: ( item ) => <Typography variant="body2" style={ { cursor: 'pointer' } } onClick={ () => handleFieldClick( 'expiry_date', get( item, "expiry_date" ) ) }>{ get( item, "expiry_date" ) }</Typography>,
      sort: ( direction ) => onUpdateListOrdering( direction === 'asc' ? 'expiry_date' : '-expiry_date' ),
    },
    {
      field: 'product_weight_kg',
      title: "Item Weight",
      visible: true,
      align: 'right',
      width: 150,
      sort: ( direction ) => onUpdateListOrdering( direction === 'asc' ? 'product_weight_kg' : '-product_weight_kg' ),
      render: ( item ) => <NumberFormat value={ get( item, [ "product_weight_kg" ] ) } suffix={ "kg" } displayType={ 'text' } thousandSeparator={ true } decimalScale={ 2 } />
    },
    {
      field: 'total_weight_kg',
      title: "Total Weight",
      visible: true,
      align: 'right',
      width: 150,
      render: ( item ) => <Typography variant="h4" className={ item.quantity <= 0 ? classes.negative_quantity : classes.positive_quantity }><NumberFormat value={ get( item, [ "total_weight_kg" ] ) } suffix={ "kg" } displayType={ 'text' } thousandSeparator={ true } decimalScale={ 2 } /></Typography>,
      sort: ( direction ) => onUpdateListOrdering( direction === 'asc' ? 'total_weight_kg' : '-total_weight_kg' ),
    },
    {
      field: 'history',
      title: 'History',
      width: 100,
      visible: false,
      align: 'right',
      render: function( item ) {
        if ( get( showingHistoryForWarehouseProduct, "id" ) === item.id ) {
          return <AdminWarehouseProductHistoryList onClose={ onHideWarehouseProductHistory }
            warehouse_product={ showingHistoryForWarehouseProduct } />
        } else {
          return (
            <IconButton onClick={ () => onShowWarehouseProductHistory( item ) } size="small">
              <HistoryIcon />
            </IconButton>
          )
        }
      }
    },
    {
      field: 'quantity',
      title: "Stock",
      visible: true,
      align: 'right',
      width: 100,
      sort: ( direction ) => onUpdateListOrdering( direction === 'asc' ? 'quantity' : '-quantity' ),
      render: ( item ) => (
        <Typography variant="h4">
          <div className={ item.quantity <= 0 ? classes.negative_quantity : classes.positive_quantity }>{ item.quantity }</div>
        </Typography>
      )
    },
  ]

  const onDownload = () => {
    adminWarehouseProductList.download()
  }

  const onAddWarehouseProduct = () => {
    setEditingWarehouseProductId( NEW_PRODUCT_TOKEN )
  }

  const onEditWarehouseProduct = ( warehouse_product_id ) => {
    setEditingWarehouseProductId( warehouse_product_id )
  }

  const onStopEditingWarehouseProduct = () => {
    setEditingWarehouseProductId( null )
  }

  const onSavedWarehouseProduct = () => {
    dispatch( adminWarehouseProductList.invalidateList() )
    dispatch( adminWarehouseProductList.fetchListIfNeeded() )
    onStopEditingWarehouseProduct()
  }

  const onShowWarehouseProductHistory = ( warehouse_product ) => {
    setShowHistoryForWarehouseProduct( warehouse_product )
  }

  const onHideWarehouseProductHistory = () => {
    setShowHistoryForWarehouseProduct( null )
  }

  const onUpdateListOrdering = ( field ) => {
    dispatch( adminWarehouseProductList.updateListOrdering( field ) )
  }

  const renderEditProductForm = ( warehouse_product_id ) => {
    const is_new = warehouse_product_id === NEW_PRODUCT_TOKEN
    const warehouse_product = is_new ? null : adminWarehouseProductList.getObject( warehouse_product_id )
    return (
      <Modal onClose={ onStopEditingWarehouseProduct }
        className={ classes.product_modal }
        fullWidth={ true }
        maxWidth='md'
        title={ is_new ? "Add product to warehouse" : warehouse_product.product_name }
      >
        <AdminWarehouseProductForm onCancel={ onStopEditingWarehouseProduct }
          onSaved={ onSavedWarehouseProduct }
          warehouse_product={ is_new ? null : warehouse_product }
        />
      </Modal>
    )
  }

  const onFilterChanged = ( values, formik_props ) => {
    // Check and format expiry_date if it's a valid date
    if ( values.expiry_date && values.expiry_date instanceof Date ) {
      values.expiry_date = formatDateForBackend( values.expiry_date )
    }

    setFilters( prevFilters => {
      // Create a new object to store updated filters
      const updatedFilters = { ...prevFilters }
      // Iterate over each key in values and update the filters
      for ( const key in values ) {
        // Add or update the filter only if it's not null
        if ( values[ key ] !== null && values[ key ] !== undefined && values[ key ] !== "" ) {
          updatedFilters[ key ] = values[ key ]
        } else {
          // Reset the filter value if it's null or undefined
          delete updatedFilters[ key ]
        }
      }
      return updatedFilters
    } )

  }

  const renderFilter = () => {
    return (
      <Formik
        initialValues={ filters }
        onSubmit={ onFilterChanged }
        enableReinitialize={ true }
      >
        { formik_props => {
          const { values } = formik_props
          return (
            <Form>
              <FormikOnChange onChange={ ( values ) => onFilterChanged( values, formik_props ) } />
              {/* <FormikGeneralFormErrors render={(msg) => msg} /> */ }
              <Grid container spacing={ 2 }>
                <Grid item xs={ 12 } md={ 6 } lg={ 3 }>
                  <Separator height={ 15 } />
                  <FormikDropdownField
                    name="warehouse"
                    formik_props={ formik_props }
                    options={ warehouse_options }
                    size="small"
                    placeholder="Warehouse"
                  />
                </Grid>
                <Grid item xs={ 12 } md={ 6 } lg={ 3 }>
                  <div className={ classes.filter_stock_availability }>
                    <WarehouseProductQuantityTypeFilter
                      name="quantity_type"
                      formik_props={ formik_props }
                      label="Stock Availability"
                    />
                  </div>
                </Grid>
                <Grid item xs={ 12 } md={ 6 } lg={ 3 }>
                  <ProductCategorySelectField
                    name="product_category"
                    can_add={ false }
                    can_edit={ false }
                    formik_props={ formik_props }
                    label="Product Category"
                    onSelected={ ( product_category ) => setSelectedProductCategory( product_category.name ) }
                  />
                </Grid>
                <Grid item xs={ 12 } md={ 6 } lg={ 3 }>
                  <Separator height={ 15 } />
                  <FormikDateTimePicker
                    name="expiry_date"
                    formik_props={ formik_props }
                    size="small"
                    placeholder="Expiry Date"
                    include_time={ false }
                  />
                </Grid>
              </Grid>
              { renderFilterChips( formik_props ) }
              { Object.keys( filters ).length > 0 &&
                <Button onClick={ () => {
                  handleClearFilters()
                  formik_props.resetForm()
                } } size="small" style={ { float: 'right' } }>Clear Filters</Button>
              }
            </Form>
          )
        } }
      </Formik>
    )
  }

  const renderActions = () => {
    return (
      <div className={ classes.downloadIcon }>
        <ReportDownloadLink
          onClick={ onDownload }
          tooltip="Download inventory list"
          is_creating_report={ is_creating_report }
        />
      </div>
    )
  }

  const handleFilterDelete = ( filterName, formik_props ) => {
    setFilters( prevFilters => {
      const newFilters = { ...prevFilters }
      delete newFilters[ filterName ]
      return newFilters
    } )
  }

  const handleClearFilters = () => {
    setFilters( {} )
    dispatch( adminWarehouseProductList.invalidateList() )
    dispatch( adminWarehouseProductList.updateListFilter( {} ) )
    dispatch( adminWarehouseProductList.fetchListIfNeeded() )
  }

  const renderFilterChips = ( formik_props ) => {
    return Object.entries( filters ).map( ( [ key, value ] ) => {
      const label = getLabelForFilter( key, value ) // get label for filter value
      return (
        <FilterChip
          key={ key }
          name={ startCase( key ) }
          label={ startCase( label ) }
          onDelete={ () => handleFilterDelete( key, formik_props ) }
        />
      )
    } )
  }

  const getLabelForFilter = ( key, value ) => {
    // Implement logic to return a user-friendly label for the filter value
    // Example:
    if ( key === 'warehouse' ) {
      const option = warehouse_options.find( option => option.value === value )
      return option ? option.label : value
    }
    if ( key === 'product_category' ) {
      return selectedProductCategory ? selectedProductCategory : value
    }
    return value
  }

  return (

    <>
      { editingWarehouseProductId !== null && renderEditProductForm( editingWarehouseProductId ) }
      <AdminCommonListLayout
        active_key="warehouse_products"
        breadcrumbs={ [ { name: 'admin_home' },
        { name: 'warehouse_products', label: "Inventory", url: '/inventory' } ] }
        add_label="Add Inventory"
        title={ "Inventory" }
        onAddRow={ onAddWarehouseProduct }
        onEditRow={ onEditWarehouseProduct }
        is_loading={ is_loading }
        extra_content_top={ <AdminWarehouseProductsSummary filters={ filters } is_loading={ is_loading } /> }
        columns={ columnsDesktop }
        enableAnyFieldFilter={ false }
        renderAdditionalFilter={ renderFilter }
        item_list={ adminWarehouseProductList }
        renderAdditionalActions={ renderActions }
        canDelete={ null }
        storeTableName={ "adminWarehouseProductList" }
        useColumnsSelector={ true }
      />

    </>
  )
}
