import React from 'react'
import { withStyles, makeStyles } from '@material-ui/core/styles'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import Paper from '@material-ui/core/Paper'
import { map, get } from 'lodash'
import { getTrend } from 'components/layout/Trend'
import { getNutritionScore } from './NutritionDashboardKpis'

const StyledTableCell = withStyles((theme) => ({
    head: {
        backgroundColor: '#263238',
        color: theme.palette.common.white,
    },
    body: {
        fontSize: 14,
    },
}))(TableCell)

const StyledTableRow = withStyles((theme) => ({
    root: {
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.action.hover,
        },
    },
}))(TableRow)

const useStyles = makeStyles({
    table: {
        minWidth: 700,
    },
})

export default function CitiesNutritionTable({ data }) {
    const classes = useStyles()

    return (
        <TableContainer component={Paper}>
            <Table className={classes.table} aria-label="customized table">
                <TableHead>
                    <TableRow>
                        <StyledTableCell>City</StyledTableCell>
                        <StyledTableCell align="right"></StyledTableCell>
                        <StyledTableCell align="right">Parcels</StyledTableCell>
                        <StyledTableCell align="right">Beneficiaries</StyledTableCell>
                        <StyledTableCell align="right">Nutrition Score</StyledTableCell>
                        <StyledTableCell align="right">Trend</StyledTableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {map(get(data, "cities_data"), (city) => {
                        const { trendColor, trendIcon, trendDirection } = getTrend(get(city, "nutrition_score_trend"))
                        const { nutrition_score_alert, nutrition_score_color } = getNutritionScore(get(city, "nutrition_score"))
                        return (
                            <StyledTableRow key={get(city, "city_name")}>
                                <StyledTableCell style={{ background: get(city, "color") }}></StyledTableCell>
                                <StyledTableCell component="th" scope="row">{get(city, "city_name")}</StyledTableCell>
                                <StyledTableCell align="right"> {get(city, "parcel_count")}</StyledTableCell>
                                <StyledTableCell align="right">{get(city, "beneficiary_count")}</StyledTableCell>
                                <StyledTableCell align="right" style={{ fontWeight: 500, color: nutrition_score_color }}>{ get(city, "nutrition_score") }</StyledTableCell>
                                <StyledTableCell align="right"style={{ fontWeight: 500, color: trendColor }}>{ trendIcon } { trendDirection }{get(city, "nutrition_score_trend")}</StyledTableCell>
                            </StyledTableRow>
                        )
                    }

                    )}
                </TableBody>
            </Table>
        </TableContainer>
    )
}
