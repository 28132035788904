import React, { Component } from 'react'
import { connect } from 'react-redux'
import { head, get } from 'lodash'
import { Trans, Translation } from 'react-i18next'
import { FormikDropdownField } from 'components/form/Dropdown'
import AdminCommonListLayout from '../layout/AdminCommonListLayout'
import Timestamp from 'components/Timestamp'
import { FormikInputField } from 'components/form/InputField'
import { adminVehicleList } from './actions/admin_vehicle'
import { Separator } from 'components/layout/Separator'
import { ShortId } from 'components/ShortId'
import { Button } from 'components/layout/Button'
import { countryList } from 'actions/country'
import { provinceList } from 'actions/province'
import Avatar from '@material-ui/core/Avatar'
import LocalShippingIcon from '@material-ui/icons/LocalShipping'
import ford_ranger from 'assets/images/ford_ranger.jpg'

class Vehicles extends Component {

    componentDidMount() {
        const { dispatch } = this.props
        dispatch(adminVehicleList.fetchListIfNeeded())
        dispatch(countryList.updatePaginationNumItemsPerPage(1000))
        dispatch(provinceList.updatePaginationNumItemsPerPage(1000))
        dispatch(countryList.fetchListIfNeeded())
        dispatch(provinceList.fetchListIfNeeded())
    }

    componentDidUpdate() {
        const { dispatch, filter } = this.props
        dispatch(adminVehicleList.fetchListIfNeeded())
        dispatch(countryList.fetchListIfNeeded())
        dispatch(provinceList.fetchListIfNeeded())
    }

    onCountryChanged = (country_id) => {
        const { dispatch } = this.props
        dispatch(provinceList.filterOnCountry(country_id))
    }

    onEditVehicle = (vehicle_id) => {
        const { history } = this.props
        history.push({
            pathname: '/admin/vehicle/'+vehicle_id
        })
    }

    onAddVehicle = () => {
        const { history } = this.props
        history.push('/admin/vehicle')
    }

    onUpdateListOrdering = (field) => {
        const { dispatch } = this.props
        dispatch(adminVehicleList.updateListOrdering(field))
    }

    render() {
        const { is_loading, vehicles } = this.props
        const that = this

        const columns = [
            { field: 'vehicle_image',
              title: 'Image',
              width: 120,
              render: (item) => <Avatar
                                  alt={`${get(item, 'name')} ${get(item, 'model')}`}
                                  title={`${get(item, 'name')} ${get(item, 'model')}`}
                                  src={get(item, ['vehicle_image', 'original_download_url'], ford_ranger)}
                                  style={{width: 120, height: 90, cursor: 'pointer'}}
                                  onClick={() => this.onEditUser(get(item, 'id'))}
                                  variant="square"
                                >
                                  <LocalShippingIcon fontSize="large" />
                                </Avatar>
            },
            {field: 'name', title: 'Name',sort: (direction) => this.onUpdateListOrdering(direction === 'asc' ? 'name' : '-name'), },
            {field: 'model', title: 'Model',sort: (direction) =>  this.onUpdateListOrdering(direction === 'asc' ? 'model' : '-model')},
            {field: 'registration_number', title: 'Reg #',sort: (direction) =>  this.onUpdateListOrdering(direction === 'asc' ? 'registration_number' : '-registration_number')},
            {field: 'registration_expiration_date', title: 'Reg Expiry',sort: (direction) =>  this.onUpdateListOrdering(direction === 'asc' ? 'registration_expiration_date' : '-registration_expiration_date')},
            {field: 'City', title: 'City',
              render: (item) => get(item, ["operating_city_name"]),
              sort: (direction) =>  this.onUpdateListOrdering(direction === 'asc' ? 'operating_city__name' : '-operating_city__name')
            },
            {field: 'bringg_ref', title: 'Bringg Ref', sort: (direction) =>  this.onUpdateListOrdering(direction === 'asc' ? 'bringg_ref' : '-bringg_ref')},
        ]

        return (
            <AdminCommonListLayout active_key="vehicles"
                                   breadcrumbs={[{name: 'admin_home'},
                                                 {name: 'vehicles', label: "Vehicles", url: '/admin/vehicles'}]}
                                   add_label="Add Vehicle"
                                   title={"Vehicles"}
                                   onAddRow={that.onAddVehicle}
                                   onEditRow={that.onEditVehicle}
                                   is_loading={is_loading}
                                   columns={columns}
                                   item_list={adminVehicleList}
                                   enableAnyFieldFilter={true}
            />
        )
    }
}

function mapStateToProps(state, props) {
    const vehicles = adminVehicleList.getVisibleObjects()
    const country_options = countryList.getAsOptions()
    const province_options = provinceList.getAsOptions()

    return {
        vehicles,
        is_loading: adminVehicleList.isLoading(),
        filter: adminVehicleList.getFilter(),
        country_options,
        province_options
    }
}

export default connect(mapStateToProps)(Vehicles)
