import { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Link } from 'react-router-dom'
import { adminKpiList } from './actions/admin_kpi'
import { adminKpiTemplateList } from './actions/admin_kpi_template'
import { filter, get, startCase, toLower, replace, head, map } from 'lodash'
import { CommonTable } from 'components/CommonTable'
import { AddButton } from 'components/form/Buttons'
import { Modal } from 'components/layout/Modal'
import AdminAssignKpi from './AdminAssignKpi'
import { Checkbox, Button } from '@material-ui/core'
import AdminKpiSettingsForm from './form/AdminKpiSettingsForm'
import Loading from 'components/Loading'
import { capitalCamel } from 'components/CapitalCamel'
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline'
import { KpiCategoryName } from 'admin/components/kpis/KpiAttributes'
import { ShortId } from 'components/ShortId'

export default function  AdminDashboardKpis({ kpi_dashboard_id, kpi_dashboard_name }) {
    const dispatch = useDispatch()
    const kpis = useSelector(() => adminKpiList.getVisibleObjects())
    const dashboard_kpis = []
    const kpi_templates = useSelector(() => adminKpiTemplateList.getVisibleObjects())
    const is_loading = useSelector(() => adminKpiList.isLoading())
    const [showAssignModal, setShowAssignModal] = useState(null)
    const title = kpi_dashboard_id ? `Assign KPIs to ${kpi_dashboard_name}` : "KPI Templates"
    const [kpiTemplate, setKpiTemplate] = useState(null)
    const [kpiId, setKpiId] = useState(null)

    useEffect(() => {
        dispatch(adminKpiList.updateListFilter({kpi_dashboard: kpi_dashboard_id}))
        dispatch(adminKpiList.fetchListIfNeeded())
        dispatch(adminKpiTemplateList.fetchListIfNeeded())
        map(kpis, (kpi) => {
            dashboard_kpis.push(kpi.id)
        })
    },[])

    const onAssignKpiTemplate = (kpi_template) => {
        setKpiTemplate(kpi_template)
        setShowAssignModal(false)
    }

    const getKpiDetails = (item, field) => {
        var kpi = head(filter(kpi_templates, {id: item.kpi_template}))
        return capitalCamel(get(kpi, field))
    }

    const columns_kpis = [
        {
            field: 'id',
            title: 'Code',
            render: (item) => <Link to={`/admin/kpis/kpi_template/${get(item, ["kpi_template"])}`}><ShortId value={ getKpiDetails(item, "code") } /></Link>,
        },
        {
            field: 'category',
            title: 'Category',
            render: (item) => <KpiCategoryName kpi_category_id={get(item, "category")} />,
            sort: (direction) => onUpdateListOrdering(direction === 'asc' ? 'category' : '-category')
        },
        {
            field: 'name',
            title: 'Name',
            render: (item) => getKpiDetails(item, "name"),
            sort: (direction) => onUpdateListOrdering(direction === 'asc' ? 'name' : '-name')
        }
    ]

    const getAssignedStatus = (item) => {
        const kpiExists = kpis.find((kpi) => kpi.kpi_template === item.id)
        return kpiExists ? <CheckCircleOutlineIcon style={{color: 'green' }} /> : <Button onClick={() => assignKpi(item)}>Assign</Button>
    }

    const assignKpi = (item) => {
        setKpiTemplate(item)
    }

    const columns_kpi_templates = [
        {
            field: 'id',
            title: 'ID',
            width: 50,
            render: (item) => <ShortId value={ get(item, ["id"]) } />,
        },
        {
            field: 'kpi_category',
            title: 'Category',
            width: 150,
            render: (item) => get(item, ["kpi_category"]),
            sort: (direction) => onUpdateListOrdering(direction === 'asc' ? 'kpi_category' : '-kpi_category')
        },
        {
            field: 'name',
            title: 'Name',
            render: (item) => startCase(toLower(replace(get(item, ["name"]), new RegExp("_","g"), ' '))),
            sort: (direction) => onUpdateListOrdering(direction === 'asc' ? 'name' : '-name')
        },

        {
            field: 'select_kpi',
            title: 'Assigned',
            width: 50,
            align: 'center',
            render: (item) => getAssignedStatus(item)
        }
    ]

    const onKpiSettingsSaved = () => {
        dispatch(adminKpiList.invalidateList())
        dispatch(adminKpiList.fetchListIfNeeded())
        setKpiTemplate(null)
    }

    const onEditKpi = (kpi_id) => {
        setKpiId(kpi_id)
        setKpiTemplate(true)
    }

    return(
        <>
            <div style={{ margin: '10px 10px 0 10px'}}>
                <AddButton label="Assign KPIs" onClick={ () => setShowAssignModal(true) } color="secondary" />
            </div>

            {is_loading ? <Loading /> :
                <CommonTable
                    item_list={adminKpiList}
                    rows={kpis}
                    columns={columns_kpis}
                    onEditRow={onEditKpi}
                    is_loading={is_loading}
                />
            }

            { showAssignModal &&
                <Modal onClose={() => setShowAssignModal(false)} fullWidth maxWidth="md" title={title} noPadding>
                    <CommonTable
                        item_list={adminKpiTemplateList}
                        rows={kpi_templates}
                        columns={columns_kpi_templates}
                        canDelete={false}
                        is_loading={is_loading}
                    />
                </Modal>
            }

            { kpiTemplate &&
                <Modal onClose={() => setKpiTemplate(false)} fullWidth maxWidth="md" title="Settings" noPadding>
                    <AdminKpiSettingsForm
                        kpi_id={kpiId}
                        kpi_dashboard_id={ kpi_dashboard_id}
                        kpi_template={ kpiTemplate }
                        onSaved={ onKpiSettingsSaved }
                    />
                </Modal>
            }
        </>
    )
}
